import { useEffect } from 'react';
import { Card } from '@components';
import { loadPayoutAudits, resetAudits } from '@slices/payouts.slice';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '@utils';

const PayoutTimeline = ({ payout_id }) => {
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();
    const errors = PayoutsSlice.audits_errors;

    const loading = PayoutsSlice.audits_loading;
    const audits = PayoutsSlice.audits;

    const fetchAudits = () => {
        dispatch(resetAudits());
        dispatch(loadPayoutAudits(payout_id));
    };

    useEffect(fetchAudits, [payout_id]);

    return (
        <Card title="Payout Timeline and Updates">
            {loading && <div className="p-3">Loading...</div>}

            {!!Object.keys(errors).length && (
                <div className="m-3 alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}

            {!loading && !Object.keys(errors).length && (
                <div className="timeline" style={{ height: 280, overflowY: 'auto' }}>
                    <ul className="timeline__list">
                        {audits.map((audit) => (
                            <li key={audit.id}>
                                <span>{formatDate(audit.date)}</span>
                                <h4>{`${audit.event} by ${audit.user_type} ${
                                    audit.user ? `(${audit.user})` : ''
                                }`}</h4>
                                <p>{audit.message}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </Card>
    );
};

export default PayoutTimeline;
