import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import { Helmet } from 'react-helmet';
import VendorProductRequests from './MerchantProductRequests';
import VendorProductRequest from './MerchantProductRequest';

export default function VendorProductRequestsRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/vendor-product-requests">
                <Helmet>
                    <title>Merchant-product-requests | Disty Admin Dashboard</title>
                </Helmet>
                <VendorProductRequests />
            </ProtectedRoute>
            <ProtectedRoute exact path="/vendor-product-requests/:id">
                <Helmet>
                    <title>Merchant-product-request | Disty Admin Dashboard</title>
                </Helmet>
                <VendorProductRequest />
            </ProtectedRoute>
        </Switch>
    );
}