import React from 'react';
import { ViewHeader, ConfirmationModal, Button, ShowErrors } from '@components';
import OnboardingScreenModal from './OnboardingScreenModal';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import {
    loadOnboardingScreens,
    deleteOnboardingScreen
} from '@slices/onboardingScreens.slice';
import OnboardingScreensLivePreviewModal from './OnboardingScreensLivePreviewModal';
import SortScreensModal from './SortScreensModal';
import ScreenCard from './ScreenCard';

export default function OnboardingScreensList() {
    const dispatch = useDispatch();
    const OnboardingScreensSlice = useSelector((state) => state.OnboardingScreensSlice);
    const [errors, set_errors] = React.useState(false);

    React.useEffect(() => {
        dispatch(loadOnboardingScreens());
    }, []);

    React.useEffect(() => {
        set_errors(OnboardingScreensSlice.screens_list_errors);
    }, [OnboardingScreensSlice.screens_list_errors]);

    return (
        <div>
            <ViewHeader title="Onboarding Screens">
                <Button variant="light" onClick={() => {
                    dispatch(
                        setModalStatus({
                            modal: 'onboarding_screens_live_preview_modal',
                            status: true
                        })
                    );
                }}>
                    Live preview
                </Button>
                <Button variant="light" onClick={() => {
                    dispatch(
                        setModalStatus({
                            modal: 'sort_onboarding_screens_modal',
                            status: true
                        })
                    );
                }}>
                    Sort screens
                </Button>
                <Button variant="primary" onClick={() => {
                    dispatch(
                        setModalStatus({
                            modal: 'onboarding_screen_modal',
                            status: true,
                        })
                    );
                }}>
                    Create new screen
                </Button>
            </ViewHeader>
            {OnboardingScreensSlice?.screens_list_loading ? (
                <p>loading...</p>
            ) : (
                <>
                    {errors ? (
                        <>
                            {typeof errors === 'string' ? (
                                <div className="alert alert-danger">{errors}</div>
                            ) : (
                                <ShowErrors errors={errors} />
                            )}
                        </>
                    ) : (
                        <div className='onboarding-screens-grid'>
                            {OnboardingScreensSlice?.screens_list?.map(screen => (
                                <ScreenCard key={screen?.id} screen={screen} />
                            ))}
                        </div>
                    )}
                </>
            )}
            <OnboardingScreensLivePreviewModal />
            <SortScreensModal />
            <OnboardingScreenModal />
            <ConfirmationModal
                title="Are you sure that you want to delete this screen ?"
                onSubmit={() => dispatch(deleteOnboardingScreen())}
                loading={OnboardingScreensSlice?.selected_screen_loading}
            />
        </div>
    );
}