import { useInterval } from './useInterval';
import { GET } from '@utils';
import { useState } from 'react';

export default function useWarehouseQuery(options = { stopRefetching: false }) {
    const [grid_data, set_grid_data] = useState([]);
    const [loading, set_loading] = useState(true);

    const fetchData = async () => {
        const res = await GET({
            endpoint: '/warehouse/locations'
        });
        set_loading(false);

        if (res?.status_code === 200) {
            set_grid_data(res?.data || []);
        }
    };

    useInterval(fetchData, 1000 * 30, { stop: !options.stopRefetching });

    return { data: grid_data, loading, refetch: fetchData };
}
