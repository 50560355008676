import React from 'react';
import { ViewHeader, Card, DataTable, Link, WhatsApp, Dialer, Sop, Filters } from '@components';
import { formatDate } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { loadRatedOrdersData } from '@slices/ratings.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function RatedOrdersListView() {
    const RatingSlice = useSelector((state) => state.RatingSlice);
    const dispatch = useDispatch();
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    const [filters, set_filters] = React.useState({});
    useDidMountEffect(() => {
        dispatch(loadRatedOrdersData({ page, ...filters, records_per_page }));
    }, [page, filters, records_per_page]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            center: true,
            width: '100px',
            cell: (row) => (
                <Link target="_blank" href={`/orders/${row?.id}`}>
                    {row?.id}
                </Link>
            ),
        },
        {
            name: 'Rating Timestamp',
            selector: 'rating_timestamp',
            cell: (row) => formatDate(row?.rating?.created_at),
        },
        {
            name: 'Retailer ID',
            selector: 'customer_id',
            cell: (row) => row?.customer?.id,
        },
        {
            name: 'Retailer Name',
            selector: 'customer.name',
            cell: (row) => row?.customer?.name,
        },
        {
            name: 'Mobile',
            selector: 'customer_mobile_number',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.customer?.mobile} />,
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.customer?.mobile} />,
        },
        {
            name: 'Email',
            selector: 'customer_email',
            minWidth: '180px',
            cell: (row) => <a href={`mailto:${row?.customer?.email}`}>{row?.customer?.email}</a>,
        },

        {
            name: 'Retailer Comments',
            selector: 'customer_email',
            cell: (row) => row?.rating?.comments,
        },
        {
            name: 'Average Rating',
            selector: 'average_rating',
            cell: (row) => (Math.round(row?.rating?.average * 100) / 100).toFixed(2),
        },
        {
            name: 'Questions & Rating',
            selector: 'questions_and_rating',
            width: '700px',
            style: {
                gap: '10px',
            },
            cell: (row) => (
                <div className="flex__fd-column__ai-start">
                    {row?.rating?.ratings.map((qt) => {
                        return (
                            <div className="py-1" key={qt?.id}>
                                <div className="tr-price">
                                    <span>{qt?.question_en}</span>
                                    <div>{qt?.rate?.description}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ),
        },
    ];

    return (
        <div className="rated-orders-list-view">
            <ViewHeader title="Rated Orders">
                <Sop id="25363" />
            </ViewHeader>
            <Card>
                <Filters
                    queryPlaceholder="Search by ( Retailer ID/Name , Order ID )"
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    sort_options={[
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Average (Biggest first)',
                            value: 'average__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Average (Smallest first)',
                            value: 'average__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={RatingSlice.ratedOrders}
                    pages={RatingSlice.ratedOrders_pages}
                    page={page}
                    records={RatingSlice.ratedOrders_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    loading={RatingSlice.ratedOrders_loading}
                    searchable={false}
                />
            </Card>
        </div>
    );
}
