import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@components';

export default function Textarea({
    setValue,
    value,
    name,
    label = '',
    placeholder = '',
    dir,
    note = '',
    disabled = false,
    required = false,
}) {
    return (
        <Form.Group>
            {label && <Form.Label>{label}</Form.Label>}
            
            <Form.Control
                value={value}
                onChange={({ target }) => {
                    setValue(target.value, target.name);
                }}
                placeholder={placeholder}
                as="textarea"
                name={name}
                required={required}
                rows={5}
                disabled={disabled}
                dir={dir}
            />

            {note && <Form.Text className="text-muted">{note}</Form.Text>}
        </Form.Group>
    );
}

Textarea.propTypes = {
    setValue: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    note: PropTypes.string,
};
