import React from 'react';
import {
    DataTable,
    WhatsApp,
    Dialer,
    Card,
    ButtonGroup,
    DropdownButton,
    Radio,
    Dropdown,
    ViewHeader,
    Modal,
    Filters,
    Image,
    Sop
} from '@components';
import { GET, formatMoney, POST, formatStringWithUnderscores } from '@utils';
import { stringify } from 'query-string';
import defaultLogo from '@assets/img/default.jpg';
import ImageZoom from 'react-image-zooom';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@store/slices/ui.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

function RequestedProductsList() {
    const dispatch = useDispatch();
    const [requested_products, set_requested_products] = React.useState([]);
    const [is_loading, set_is_loading] = React.useState(false);

    const [page, set_page] = React.useState(1);
    const [pages, set_pages] = React.useState(null);
    const [product, set_product] = React.useState('');
    const STATUS_OPTIONS = [
        'successfully_fulfilled',
        'contacting_the_customer',
        'product_found',
        'vendor_found',
        'under_planning',
        'request_received',
        'request_rejected',
    ];
    const [filters, set_filters] = React.useState({});

    const handleFilters = (args) => {
        const params = {
            ...args,
            product_name: args.q,
            customer_name: args.q,
        };

        if (params.search_by?.includes('customer_name')) {
            delete params.product_name;
        } else {
            delete params.customer_name;
        }

        delete params.q;

        set_filters(params);
    };

    const fetchData = async () => {
        set_is_loading(true);
        const res = await GET({
            endpoint: `/product-request?${stringify(
                { ...filters, page, search_by: null },
                {
                    skipNull: true,
                    skipEmptyString: true,
                }
            )}`,
        });
        set_is_loading(false);

        if (res?.status_code === 200) {
            set_requested_products(res?.data);
            set_pages(res?.meta?.last_page);
        }
    };
    
    useDidMountEffect(() => {
        fetchData();
    }, [filters, page]);

    const changeStatus = async (value, id) => {
        const res = await POST({
            endpoint: `/product-request/${id}/status`,
            data: { status: value },
        });
        if (res.status_code === 200) {
            fetchData();
        }
    };

    const columns = [
        {
            name: 'Request ID',
            selector: 'id',
            width: '100px',
            center: true,
        },
        {
            name: 'First Name',
            selector: 'first_name',
            width: '120px',
            cell: (row) => row?.first_name,
        },
        {
            name: 'Last Name',
            selector: 'last_name',
            width: '120px',
            cell: (row) => row?.last_name,
        },
        {
            name: 'Product name ',
            selector: 'product_name',
            width: '150px',
            cell: (row) => row?.product_name,
        },
        {
            name: 'Status',
            selector: 'status',
            width: '150px',
            cell: (row) => formatStringWithUnderscores(row?.status),
        },
        {
            name: 'Expected Price',
            selector: 'expected_price',
            width: '120px',
            cell: (row) => formatMoney(row?.expected_price),
        },
        {
            name: 'Quantity needed ',
            selector: 'quantity_needed',
            width: '120px',
        },
        // {
        //     name: 'Picture of the product',
        //     selector: 'image',
        //     width: '120px',
        //     cell: (row) => (
        //         <img
        //             style={{ cursor: 'pointer' }}
        //             width="60"
        //             onClick={() => {
        //                 dispatch(
        //                     setModalStatus({
        //                         modal: 'product_request_zoom_modal',
        //                         status: true,
        //                     })
        //                 );
        //                 set_product(row);
        //             }}
        //             src={row?.image || defaultLogo}
        //             alt="Product picture"
        //         />
        //     ),
        // },
        {
            name: 'Picture of the product',
            width: '120px',
            cell: (row) => (
                <Image
                    src={row?.image_conversions?.original || defaultLogo}
                    alt="Product picture"
                    showPreview
                    width="60"
                />
            ),
        },
        {
            name: 'Additional details ',
            selector: 'detail',
            width: '170px',
            cell: (row) =>
                row.detail && (
                    <div
                        role="button"
                        className="text-primary"
                        onClick={() => {
                            dispatch(
                                setModalStatus({
                                    modal: 'product_request_details_modal',
                                    status: true,
                                })
                            );
                            set_product(row);
                        }}
                    >
                        {' '}
						Read details{' '}
                    </div>
                ),
        },

        {
            name: 'Mobile',
            selector: 'mobile',
            width: '150px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Whatsapp',
            selector: 'mobile',
            width: '100px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />,
        },
        {
            name: 'Action',
            selector: 'status',
            center: true,
            cell: (row) => {
                return (
                    <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title={formatStringWithUnderscores(row?.status)}
                            id="bg-nested-dropdown"
                            variant="light"
                            className="requested_product-dropdown"
                        >
                            {STATUS_OPTIONS.map((action, i) => (
                                <Dropdown.Item as="div" key={i} eventKey={i}>
                                    <Radio
                                        onChange={(value) => changeStatus(value, row.id)}
                                        option={row?.status}
                                        label={formatStringWithUnderscores(action)}
                                        value={action}
                                        name={`selected_products_status${row.id}`}
                                    />
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </ButtonGroup>
                );
            },
        },
    ];
    /** TODO: missing filters
    const tableFilters = (
        <>
            <ButtonGroup key={1}>
                <div className="table-search-box">
                    <Input
                        placeholder={'search by customer name'}
                        setValue={(val) => set_customer_name(val)}
                        value={customer_name}
                        icon={searchIcon}
                        required={false}
                    />
                    {!!customer_name.length && (
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => set_customer_name('')}
                        >
                        X
                        </button>)}
                </div>
                <div className="table-search-box">
                    <Input
                        placeholder={'search by product name'}
                        setValue={(val) => set_product_name(val)}
                        value={product_name}
                        icon={searchIcon}
                        required={false}
                    />
                    {!!product_name.length && (
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => set_product_name('')}
                        >
                        X
                        </button>)}
                </div>
                <DropdownButton
                    variant="light"
                    as={ButtonGroup}
                    title={
                        <span>
                            Status
                            {filters.status && ` (${formatStringWithUnderscores(filters.status)})`}
                        </span>
                    }
                    id={`bg-nested-dropdown-${randomId()}`}
                >
                    {STATUS_OPTIONS.map((status, i) => (
                        <Dropdown.Item
                            eventKey={i}
                            key={i}
                            onClick={() =>
                                set_filters({
                                    ...filters,
                                    status,
                                })
                            }
                        >
                            {formatStringWithUnderscores(status)}
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Item
                        eventKey={STATUS_OPTIONS.length + 1}
                        onClick={() => filters?.status && set_filters({ ...filters, status: null })}
                    >
						Clear
                    </Dropdown.Item>
                </DropdownButton>

                <DropdownButton
                    variant="light"
                    as={ButtonGroup}
                    title={
                        <span>
                            Has picture
                            {filters.has_picture === '1' && ' (Active)'}
                            {filters.has_picture === '0' && ' (Inactive)'}
                        </span>
                    }
                    id={`bg-nested-dropdown-${randomId()}`}
                >
                    <Dropdown.Item
                        eventKey="1"
                        onClick={() =>
                            set_filters({
                                ...filters,
                                has_picture: '1',
                            })
                        }
                    >
                        Has picture
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="1"
                        onClick={() =>
                            set_filters({
                                ...filters,
                                has_picture: '0',
                            })
                        }
                    >
                        Has no picture
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="3"
                        onClick={() => filters?.has_picture && 
							set_filters({ ...filters, has_picture: null })}
                    >
						Clear
                    </Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>
        </>
    );
     */

    const searchPlaceholder = filters.search_by?.includes('customer_name')
        ? 'Search by customer name'
        : 'Search by product name';

    return (
        <>
            <ViewHeader title="Requested Products list">
                <Sop id="25203"/>
            </ViewHeader>
            <Card>
                <Filters
                    filters={['search_by', 'request_statuses', 'has_picture']}
                    onFilterChange={(filters_values) => {
                        handleFilters(filters_values);
                    }}
                    queryPlaceholder={searchPlaceholder}
                />
                <DataTable
                    data={requested_products}
                    columns={columns}
                    searchable={false}
                    loading={is_loading}
                    pages={pages}
                    page={page}
                    handlePageChange={set_page}
                />
            </Card>
            <Modal
                title={`Read detail for request product #${product.id}`}
                confirmBtn={false}
                name="product_request_details_modal"
            >
                <div>{product.detail}</div>
            </Modal>
            <Modal name="product_request_zoom_modal" title="Zoom Modal">
                <ImageZoom
                    className="w-100 h-100"
                    src={product?.image_conversions?.original || defaultLogo}
                    alt="Product picture"
                    zoom="200"
                />
            </Modal>
        </>
    );
}

export default RequestedProductsList;
