import React, { useCallback } from 'react';
import { ChoiceList, Icon, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

const getLabels = (options, value) => {
    const results = [];
    options.map(o => {
        if (value.includes(o.value)) {
            results.push(o.label);
        }
    });
    
    return results.toString();
};

export default function ListOptions({
    value = null,
    set_value = () => {},
    options,
    isMulti = false,
    searchable = true,
}) {
    const [q, set_q] = React.useState('');

    const onChange = useCallback(
        (value) => set_value(value, getLabels(options, value)),
        [],
    );

    return (
        <>
            {searchable &&
                <>
                    <TextField
                        value={q}
                        onChange={set_q}
                        autoComplete="off"
                        placeholder='Search'
                        prefix={<Icon source={SearchMinor} />}
                    />
                    <br />
                </>
            }
            <ChoiceList
                choices={options}
                selected={value || []}
                onChange={onChange}
                allowMultiple={isMulti}
            />

        </>
    );
}
