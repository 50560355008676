import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';

import { Helmet } from 'react-helmet';
import ForceUpdate from './ForceUpdate';

export default function ForceUpdateModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/force-update">
                <Helmet>
                    <title>force update | Disty Admin Dashboard</title>
                </Helmet>
                <ForceUpdate />
            </ProtectedRoute>   
        
        </Switch>
    );
}
