import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import BrandsListView from './BrandsListView';
import BrandsAddView from './BrandsAddView';
import BrandsUpdateView from './BrandsUpdateView';
import { Helmet } from 'react-helmet';

export default function BrandsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/brands/list">
                <Helmet>
                    <title>Brands | Disty Admin Dashboard</title>
                </Helmet>
                <BrandsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/brands/add">
                <Helmet>
                    <title>Create Brand | Disty Admin Dashboard</title>
                </Helmet>
                <BrandsAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/brands/:id">
                <Helmet>
                    <title>Edit Brand | Disty Admin Dashboard</title>
                </Helmet>
                <BrandsUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
