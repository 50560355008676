var obj2formdata = function (obj, options = { acceptEmptyString: false }) {
    var fd = new FormData();
    var formKey;

    for (var property in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(property)) {
            formKey = property;

            // if the property is an object, but not a File,
            // use recursivity.
            if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                obj2formdata(obj[property], fd, property);
            } else {
                // if it's a string or a File object
                if (obj[property] !== '') {
                    fd.append(formKey, obj[property]);
                } else {
                    if (options.acceptEmptyString) {
                        fd.append(formKey, obj[property]);
                    }
                }
            }
        }
    }

    return fd;
};
export default obj2formdata;
