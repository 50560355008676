import React from 'react';
// import { Col, Row } from 'react-bootstrap';
import { formatStringWithUnderscores, renderValue } from '@utils';

export const imagesKeys = ['logo', 'images', 'image', 'main_image'];
export const videosKeys = ['video'];

const CreateRequest = ({ data }) => {
    const renderKeys = (values) => {
        const list = [];

        const grapKeys = (d) => {
            Object.entries(d).map(([key, value]) => {
                if (key === 'variants') {
                    return d[key].map((v, _i) => {
                        list.push(
                            <tr key={`v-${_i}`}>
                                <td colSpan={2} style={{ background: '#ededed' }}>
                                    Variants
                                </td>
                            </tr>,
                        );

                        grapKeys(v);
                    });
                }
                if (key === 'sample') {
                    {
                        list.push(
                            <tr key={'s'}>
                                <td colSpan={2} style={{ background: '#ededed' }}>
                                    Sample
                                </td>
                            </tr>,
                        );

                        grapKeys(d[key]);
                        return;
                    }
                }
                return list.push(
                    <tr key={key}>
                        <td className="key">
                            {formatStringWithUnderscores(key === 'is_enabled' ? 'Enabled' : key)}
                        </td>
                        <td>
                            {renderValue(value, {
                                isImg: imagesKeys.includes(key),
                                imgAlt: key,
                                isVideo: videosKeys.includes(key),
                                isAttribute: ['attributes'].includes(key),
                            })}
                        </td>
                    </tr>,
                );
            });
        };

        grapKeys(values);

        return list;
    };

    return (
        <div className="vendor-request-single">
            <table>
                <tr className="table-head">
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                {renderKeys(data)}
            </table>
        </div>
    );
};

export default CreateRequest;
