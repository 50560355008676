import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import ReGenerateFinancialDocuments from './ReGenerateFinancialDocuments';
import { Helmet } from 'react-helmet';

export default function VendorPayoutsRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/re-generate-financial-documents">
                <Helmet>
                    <title>Re-Generate Financial Documents</title>
                </Helmet>
                <ReGenerateFinancialDocuments />
            </ProtectedRoute>
        </Switch>
    );
}
