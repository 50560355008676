/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ApiAsyncSelect,
    CriticalActionButton,
    Checkbox,
    CustomPrompt,
    Input,
    Select,
    ShowErrors,
    Textarea,
} from '@components';
import useUpdateProduct from '../../hooks/useUpdateProduct';
import { initBaseForm } from '../../product-utils';

const defaultData = {
    name_ar: '',
    name_en: '',
    description_ar: '',
    description_en: '',
    categories: [],
    tags: [],
    has_taxes: true,
    allow_backorder: false,
    is_returnable: false,
};

const GeneralTab = () => {
    const [values, setValues] = useState(defaultData);

    const { brands, categories_flat } = useSelector((state) => state.GlobalsSlice);
    const product = useSelector((state) => state.ProductsSlice.product);
    const cleanPayload = (payload) => {
        if (Array.isArray(payload)) {
            return payload.map((item) => cleanPayload(item));
        } else if (typeof payload === 'object' && payload !== null) {
            const cleanedObject = {};
            Object.keys(payload).forEach((key) => {
                if (key !== 'range_percentage') {
                    cleanedObject[key] = cleanPayload(payload[key]);
                }
            });
            return cleanedObject;
        }
        return payload;
    };
    const cleanedValues = cleanPayload(product);
    const { mutate, data, isLoading } = useUpdateProduct(cleanedValues, values, {
        view_name: 'general',
    });

    /**
     *
     * @param {keyof typeof values} field
     * @param {any} value
     */
    const [loading, set_loading] = useState(false);
    const [validation_errors, set_validation_errors] = useState({});
    const validate = () => {
        const newErrors = {};
        if (!values.name_ar || !/^[0-9\u0600-\u06FF\s]*$/.test(values.name_ar))
            newErrors.name_ar =
                'The name  (arabic) field is required and must  contain only arabic letters.';
        if (!values.name_en || !/^[a-zA-Z0-9\s]*$/.test(values.name_en))
            newErrors.name_en =
                'The name (english) field is required and must  contain only english letters.';
        if (values.categories.length == 0) newErrors.categories = 'categories field is required';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    useEffect(() => {
        validate();
    }, [values]);
    const onChange = (field, value) => {
        setValues((prev) => {
            const newValues = { ...prev, [field]: value };
            return newValues;
        });
    };

    /** @param {keyof typeof values} key an alias of onChange */
    const onChangeByKey = (key) => (val) => onChange(key, val);

    const handleSubmit = () => {
        set_loading(true);
        if (!validate()) return;
        mutate();
    };

    useEffect(() => {
        if (product) {
            setValues((prev) => ({
                ...prev,
                ...initBaseForm(product),
            }));
        }
    }, [product]);
    return (
        <div className="products-update-view">
            <CustomPrompt>
                {data?.data?.errors && !!Object.keys(data?.data?.errors).length && (
                    <ShowErrors errors={data?.data?.errors} />
                )}

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Input
                            value={values.name_ar}
                            name="name_ar"
                            label="Name (arabic)"
                            tooltip='Please make the product title concise and clean. Follow this guideline "Brand (name of the brand), Product type (chocolate, drink), Product Specifics (e.g., Flavour), size (e.g., 1Lt, 1Kg)"'
                            setValue={onChangeByKey('name_ar')}
                        />
                        {loading && validation_errors.name_ar && (
                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                {validation_errors.name_ar}
                            </p>
                        )}
                    </div>
                    <div>
                        <Input
                            value={values.name_en}
                            name="name_en"
                            label="Name (english)"
                            tooltip='Please make the product title concise and clean. Follow this guideline "Brand (name of the brand), Product type (chocolate, drink), Product Specifics (e.g., Flavour), size (e.g., 1Lt, 1Kg)"'
                            setValue={onChangeByKey('name_en')}
                        />
                        {loading && validation_errors.name_en && (
                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                {validation_errors.name_en}
                            </p>
                        )}
                    </div>
                    <Textarea
                        name="description_ar"
                        label="Description (arabic)"
                        tooltip="Please proofread the description before you submit it"
                        value={values.description_ar ?? ''}
                        setValue={onChangeByKey('description_ar')}
                    />
                    <Textarea
                        name="description_en"
                        label="Description (english)"
                        tooltip="Please proofread the description before you submit it"
                        value={values.description_en ?? ''}
                        setValue={onChangeByKey('description_en')}
                    />
                </div>

                <div className="grid grid-cols-3 gap-4 mt-4">
                    <Select
                        label="Brand"
                        value={values.brand ?? ''}
                        options={brands}
                        getOptionValue={(option) => option.id}
                        onChange={onChangeByKey('brand')}
                    />
                    <div>
                        <Select
                            isMulti
                            label="Categories"
                            value={values.categories}
                            options={categories_flat}
                            getOptionValue={(option) => option.id}
                            onChange={onChangeByKey('categories')}
                        />
                        {loading && validation_errors.categories && (
                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                {validation_errors.categories}
                            </p>
                        )}
                    </div>
                    <ApiAsyncSelect
                        label="Merchant"
                        api="/vendors"
                        onlyFetchActiveRecords={false}
                        placeholder="Type to Add Merchant..."
                        labelProperty={(r) => {
                            return (
                                <div className="custom-option-with-thumbnail">
                                    <img src={r.logo_ar} />
                                    <div className="content">
                                        <h4>{r.company_name_ar}</h4>
                                        <p>
                                            {r.email}
                                            {r.mobile ? ` | ${r.mobile}` : ''}
                                        </p>
                                    </div>
                                </div>
                            );
                        }}
                        onChange={onChangeByKey('vendor')}
                        value={values.vendor}
                    />
                </div>

                <div className="grid grid-cols-4 mt-4">
                    <Checkbox
                        name="has_taxes"
                        checked={values.has_taxes}
                        onChange={onChangeByKey('has_taxes')}
                        label="Has taxes"
                    />
                    <Checkbox
                        name="allow_backorder"
                        checked={values.allow_backorder}
                        onChange={onChangeByKey('allow_backorder')}
                        label="Allow backorder"
                    />
                    <Checkbox
                        name="is_returnable"
                        checked={values.is_returnable}
                        onChange={onChangeByKey('is_returnable')}
                        label="Returnable"
                    />
                </div>
                <div className="flex__jc-end">
                    <CriticalActionButton loading={isLoading} onSubmit={handleSubmit}>
                        Submit
                    </CriticalActionButton>
                </div>
            </CustomPrompt>
        </div>
    );
};

export default GeneralTab;
