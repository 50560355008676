import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/_styles.scss';
import { Sidebar, ImagePreviewModal } from '@components';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { useDispatch } from 'react-redux';
import { loadGlobalsData } from '@slices/globals.slice';
import { loadUserData } from '@slices/auth.slice';
import { loadOrderStaticData } from '@slices/orders.slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';

function App() {
    const dispatch = useDispatch();
    const AuthSlice = useSelector((state) => state.AuthSlice);
    const UISlice = useSelector((state) => state.UISlice);
    const query = new URLSearchParams(window.location.search);

    useEffect(() => {
        // load global data that will be used across the app
        if (AuthSlice.token && !query.get('redirect')) {
            dispatch(loadGlobalsData());
            dispatch(loadUserData());
            dispatch(loadOrderStaticData());
        }
    }, []);

    return (
        <AppProvider i18n={enTranslations}>
            <Router>
                <div
                    className={`app ${
                        UISlice.is_full_screen_view ? 'is-full-screen-view' : ''
                    }`}
                >
                    {!UISlice.is_full_screen_view && (
                        <>
                            <ToastContainer />
                            <Sidebar />
                        </>
                    )}
                    <ImagePreviewModal/>
                    <main>
                        <Routes />
                    </main>
                </div>
            </Router>
        </AppProvider>
    );
}

export default App;
