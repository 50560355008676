import React, { useEffect } from 'react';
import { Modal, Input, Select, Textarea } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from '@components';
import { PUT } from '@utils';
import { updateShippingAddress } from '@slices/orders.slice';

const EditShippingAddressModal = ({ id }) => {
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const dispatch = useDispatch();

    const [google_maps_address, set_google_maps_address] = React.useState(null);
    const center_of_SA = {
        lat: 24.804219,
        lng: 45.8601,
    };
    const [marker, set_marker] = React.useState(center_of_SA);
      const [coords, set_coords] = React.useState({});
    const [submitting, set_submitting] = React.useState(false);

    const [data, setData] = React.useState({
        contact_name: '',
        contact_mobile: '',
        special_mark: '',

        details: '',
        name: '',
    });

    const [suitable_delivery_time_id, set_suitable_delivery_time_id] =
		React.useState({
		    label: 'Suitable delivery time',
            getOptionValue: (option) => option.label,
		    value: '',
            onChange: (val) => set_suitable_delivery_time_id({
                ...suitable_delivery_time_id,
                value: val
            })
         
});

    useEffect(() => {
        setData({
            contact_name: OrdersSlice.order.shipping_address?.contact_name,
            contact_mobile: OrdersSlice.order.shipping_address?.contact_mobile,
            special_mark: OrdersSlice.order.shipping_address?.special_mark,

            details: OrdersSlice.order.shipping_address?.details,
            name: OrdersSlice.order.shipping_address?.name,
        });
        let dt = '';
        if (OrdersSlice.order.shipping_address) {
             dt={ value:OrdersSlice.order.shipping_address.suitable_delivery_time_id,
                 label:OrdersSlice.order.shipping_address.suitable_delivery_time } ;
                 
             suitable_delivery_time_id.onChange(dt);}
        
        if (OrdersSlice.order.shipping_address) {
            set_coords({
                lat: OrdersSlice.order.shipping_address.latitude || 0,
                lng: OrdersSlice.order.shipping_address.longitude || 0,
            });
        }
    }, [OrdersSlice.order, GlobalsSlice.delivery_times ]);

    const [errors, setErrors] = React.useState([]);
    const validate = () => {
        const newErrors = {};
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}-\u{1F0CF}]/u;

        if (!data.name || emojiRegex.test(!data.name)) newErrors.name = 'The name field is required.';
        if (!data.contact_name || emojiRegex.test(!data.contact_name))
         newErrors.contact_name = 'The contact name field is required.';
        if (!suitable_delivery_time_id.value.value)
            newErrors.suitable_delivery_time_id = 'The suitable delivery time field is required.';
        if (!data.contact_mobile || !/^5[0-9]{1}[0-9]{7}$/i.test(data.contact_mobile)) {
            newErrors.contact_mobile ='The mobile field is required. It should start with 5 followed by 8 digits.';
        }
        if (!coords.lat) newErrors.latitude = 'The latitude field is required.';
        if (!coords.lng) newErrors.longitude = 'The longitude field is required.';
        if (!data.special_mark || typeof data.special_mark !== 'string' || /^[0-9]+$/.test(data.special_mark)) 
            newErrors.special_mark = 'The special mark must be a non-empty string.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [data,suitable_delivery_time_id]);
    const onSubmit = async (close) => {
        if (!validate()) return;
        set_submitting(true);
        const res = await PUT({
            endpoint: `/order/${id}/shipping-address`,
            data: {
                contact_name: data.contact_name,
                contact_mobile: data.contact_mobile,
                special_mark: data.special_mark,
                latitude: coords.lat,
                longitude: coords.lng,
            
                suitable_delivery_time_id: suitable_delivery_time_id.value?.value,

                details: data.details,
                name: data.name,
            },
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            close();
            dispatch(
                updateShippingAddress({
                    ...data,
                    latitude: coords.lat,
                    longitude: coords.lng,
                    suitable_delivery_time: suitable_delivery_time_id.value?.label,
                })
            );
        }
    };

    return (
        <Modal
            title="Edit Shipping Address"
            name="edit_shipping_address_modal"
            onSubmit={onSubmit}
            loading={submitting}
        >
            <label>Location</label>
            <Map
                onCoordsChange={set_coords}
                coords={coords}
                center={coords}
                set_marker={set_marker}
                marker={marker}
                google_maps_address={google_maps_address}
                set_google_maps_address={set_google_maps_address}
                allow_search={true}
                height="600px"
            />
            <hr />

            <Input
                label="Address name"
                setValue={(val) => {
                    setData({ ...data, name: val });
                }}
                value={data.name}
            />
            <Input
                label="Contact name"
                setValue={(val) => {
                    setData({ ...data, contact_name: val });
                }}
                value={data.contact_name}
            />
            <Input
                label="Contact mobile"
                type="number"
                prefix="+966"
                setValue={(val) => {
                    setData({ ...data, contact_mobile: val });
                }}
                value={data.contact_mobile}
            />
            <Input
                label="Special mark"
                setValue={(val) => {
                    setData({ ...data, special_mark: val });
                }}
                value={data.special_mark}
            />
            <Select
                options={GlobalsSlice.delivery_times?.map((i) => ({
                    value: i.id,
                    label: i.label,
                }))}
                {...suitable_delivery_time_id}
            />
            <Textarea
                label="Details"
                setValue={(val) => {
                    setData({ ...data, details: val });
                }}
                value={data.details}
            />

            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default EditShippingAddressModal;
