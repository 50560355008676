import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export function EditorStateToRawHTML(editorState) {
    if(!editorState) return '';
    return draftToHtml(convertToRaw(editorState?.getCurrentContent?.()));
}

export function RawHTMLToEditorState(raw_html){
    return EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML(raw_html)
        )
    );
}


