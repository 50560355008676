import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const VendorProductRequestsSlice = createSlice({
    name: 'vendorProductRequests',
    initialState: {
        vendor_product_requests: [],
        vendor_product_requests_pages: null,
        vendor_product_requests_records: null,
        vendor_product_request: {},
        vendor_product_requests_loading: true,
        vendor_product_request_loading: true,
    },
    reducers: {
        setVendorProductRequests(state, { payload }) {
            state.vendor_product_requests = payload;
        },
        setVendorProductRequest(state, { payload }) {
            state.vendor_product_request = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVendorProductRequestsTotalPages(state, { payload }) {
            state.vendor_product_requests_pages = payload;
        },
        setVendorProductRequestsRecords(state, { payload }) {
            state.vendor_product_requests_records = payload;
        },
    },
});

// export actions
export const {
    setVendorProductRequest,
    setVendorProductRequests,
    setLoading,
    setVendorProductRequestsTotalPages,
    setVendorProductRequestsRecords,
} = VendorProductRequestsSlice.actions;

export const loadVendorProductRequestsData =
    (params = {}) =>
    async (dispatch) => {
        dispatch(
            setLoading({
                key: 'vendor_product_requests_loading',
                value: true,
            }),
        );

        const res = await GET({
            endpoint: `/vendorUsers/vendor-product-request?${stringify(params, {
                skipNull: true,
                skipEmptyString: true,
                arrayFormat: 'comma',
            })}`,
        });

        dispatch(setVendorProductRequests(res?.data || []));

        if (res?.meta) {
            console.log('res?.meta', res?.meta);
            dispatch(setVendorProductRequestsTotalPages(res?.meta?.last_page || 0));
            dispatch(
                setVendorProductRequestsRecords({
                    from: res?.meta?.from,
                    to: res?.meta?.to,
                    total: res?.meta?.total,
                }),
            );
        }

        dispatch(
            setLoading({
                key: 'vendor_product_requests_loading',
                value: false,
            }),
        );
    };

export const loadVendorProductRequestData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendor_product_request_loading',
            value: true,
        }),
    );

    const res = await GET({
        endpoint: `/vendorUsers/vendor-product-request/${id}`,
    });

    dispatch(setVendorProductRequest(res?.data || {}));

    dispatch(
        setLoading({
            key: 'vendor_product_request_loading',
            value: false,
        }),
    );
};

export default VendorProductRequestsSlice.reducer;
