import { createSlice } from '@reduxjs/toolkit';

export const UISlice = createSlice({
    name: 'ui',
    initialState: {
        modals_status: {},
        modal_payload: {},
        image_preview_src: '',
        sidebar_status: window.innerWidth > 1200,
        is_full_screen_view: false,
    },
    reducers: {
        setModalStatus(state, { payload }) {
            state.modals_status[payload.modal] = payload.status;
            state.modal_payload[payload.modal] = payload.payload;
        },
        setImagePreviewSrc(state, { payload }) {
            state.image_preview_src = payload;
        },
        setSidebarStatus(state, { payload }) {
            state.sidebar_status = payload;
        },
        set_is_full_screen_view(state, { payload }) {
            state.is_full_screen_view = payload;
        },
    },
});

// export actions
export const { setModalStatus, setSidebarStatus, set_is_full_screen_view, setImagePreviewSrc } =
    UISlice.actions;

export default UISlice.reducer;
