/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Map, Spinner, Col, Row, ListComponent } from '@components';
import vendors from '../../data/static-views/merchants.json';
import customerIcon from '@assets/icons/camel.png';
import customerBlackIcon from '@assets/icons/camel-black.png';
import vendorIcon from '@assets/icons/factory.png';
import callIcon from '@assets/icons/call.svg';
import locationIcon from '@assets/icons/location-gray.svg';
import ordersIcon from '@assets/icons/check_circle.svg';
import branchesIcon from '@assets/icons/pulse.svg';
import clockIcon from '@assets/icons/clock.svg';
import growth from '@assets/icons/growth.svg';
import { center_of_SA } from '../../components/stateless/Map';
import { formatDate, formatMoney } from '@utils';

export default function MapStaticView() {
    const [loading, set_loading] = React.useState(true);
    const [show_tiers_widget, set_show_tiers_widget] = React.useState(true);
    const [customers, set_customers] = React.useState([]);
    const [cities, set_cities] = React.useState([]);
    const [selected_city, set_selected_city] = React.useState('all');
    const [selected_ordered_date, set_selected_ordered_date] = React.useState('all');
    const [selected_fulfilled_date, set_selected_fulfilled_date] = React.useState('all');
    const [selected_orders_count, set_selected_orders_count] = React.useState('all');

    const [selected_marker_coords, set_selected_marker_coords] =
		React.useState(null);
    const [zoom, set_zoom] =
		React.useState(6);
    const [selected_filter, set_selected_filter] = React.useState('customers');
    const [q, set_q] = React.useState('');

    const formatRawData = (arr) => {
        return arr.map((item) => {
            const [lat, lng] =
				typeof item?.coords === 'string'
				    ? item?.coords?.split?.(',')?.map((coord) => +coord?.trim()) || []
				    : [
				        item?.coords?.lat,
				        item?.coords?.lng,
				    ];
            return {
                ...item,
                id: item.id,
                mobile: item.mobile,
                has_coords: !!lat && !!lng,
                coords: {
                    lat,
                    lng,
                },
                name: item.store_name || item.vendor_name || 'No Name',
                customer_name: item.customer_name,
                type: 'store_name' in item ? 'customer' : 'vendor',
            };
        });
    };

    const data = React.useMemo(() => {
        let raw_data = [];
        if (selected_filter === 'customers') {
            raw_data = customers;
        }
        if (selected_filter === 'vendors') {
            raw_data = vendors;
        }
        if (selected_filter === 'all') {
            raw_data = [...customers, ...vendors];
        }

        return formatRawData(raw_data);
    }, [selected_filter, customers]);

    const customersStats = data.reduce(
        (acc, item) => {
            if (item.type === 'customer') {
                if (item.total_orders > 0) {
                    acc.total_customers_how_placed_orders++;
                    acc.total_orders += item.total_orders;
                    // customers fulfilled in last 7 days
                    if (item.latest_fulfilled_order_date) {
                        const latest_date = new Date(item.latest_fulfilled_order_date);
                        const today = new Date();
                        const past_7_days = new Date(today.setDate(today.getDate() - 7));
                        const past_30_days = new Date(today.setDate(today.getDate() - 30));
                        const past_3_months = new Date(
                            today.setMonth(today.getMonth() - 3)
                        );

                        if (latest_date >= past_7_days) {
                            acc.customers_ordered_in_last_7_days++;
                        }
                        if (latest_date >= past_30_days) {
                            acc.customers_ordered_in_last_30_days++;
                        }
                        if (latest_date >= past_3_months) {
                            acc.customers_ordered_in_last_3_months++;
                        }
                    }
                } else {
                    acc.total_customers_how_didnt_placed_orders++;
                }

                if(item.is_verified) {
                    acc.total_verified_customers++;
                }
            }
            return acc;
        },
        {
            total_orders: 0,
            total_customers_how_placed_orders: 0,
            total_customers_how_didnt_placed_orders: 0,
            customers_ordered_in_last_7_days: 0,
            customers_ordered_in_last_30_days: 0,
            customers_ordered_in_last_3_months: 0,
            total_verified_customers: 0,
        }
    );

    const data_filtered = data
    // remove items without coords
        .filter((item) => item.has_coords)
    // filter by search query
        .filter((item) => item.name.toLowerCase().includes(q.toLowerCase()))
    // filter by city
        .filter((item) => {
            if (selected_city !== 'all') {
                return item.city === selected_city;
            }
            return true;
        })
    // filter by orders count
        .filter((item) => {
            if (selected_orders_count === 'all') return true;
            if (selected_orders_count === 'none'){
                return item.total_orders === 0;
            }
            if (selected_orders_count === '1'){
                return item.total_orders === 1;
            }
            if (selected_orders_count === '5'){
                return item.total_orders <= 5 && item.total_orders > 1;
            }
            if (selected_orders_count === '10'){
                return item.total_orders <= 10 && item.total_orders > 1;
            }
            if (selected_orders_count === '20'){
                return item.total_orders <= 20 && item.total_orders > 1;
            }
            if (selected_orders_count === '50'){
                return item.total_orders <= 50 && item.total_orders > 1;
            }
            return true;
        })
    // filter by dates
        .filter((item) => {
            if (selected_ordered_date === 'all') return true;
            const latest_ordered_date = new Date(item.latest_order_date);
            const today = new Date();

            if (selected_ordered_date === 'today') {
                return latest_ordered_date.getDate() === today.getDate() && latest_ordered_date.getMonth() === today.getMonth() && latest_ordered_date.getFullYear() === today.getFullYear();
            }
            if (selected_ordered_date === 'past_7_days') {
                const past_7_days = new Date(today.setDate(today.getDate() - 7));
                return latest_ordered_date >= past_7_days;
            }
            if (selected_ordered_date === 'past_30_days') {
                const past_30_days = new Date(today.setDate(today.getDate() - 30));
                return latest_ordered_date >= past_30_days;
            }
            if (selected_ordered_date === 'past_3_months') {
                const past_3_months = new Date(today.setMonth(today.getMonth() - 3));
                return latest_ordered_date >= past_3_months;
            }
            if (selected_ordered_date === 'past_6_months') {
                const past_6_months = new Date(today.setMonth(today.getMonth() - 6));
                return latest_ordered_date >= past_6_months;
            }
            if (selected_ordered_date === 'past_12_months') {
                const past_12_months = new Date(today.setMonth(today.getMonth() - 12));
                return latest_ordered_date >= past_12_months;
            }
            return true;
        })
        .filter((item) => {
            if (selected_fulfilled_date === 'all') return true;
            const latest_fulfilled_order_date = new Date(item.latest_fulfilled_order_date);
            const today = new Date();

            if (selected_fulfilled_date === 'today') {
                return latest_fulfilled_order_date.getDate() === today.getDate() && latest_fulfilled_order_date.getMonth() === today.getMonth() && latest_fulfilled_order_date.getFullYear() === today.getFullYear();
            }
            if (selected_fulfilled_date === 'past_7_days') {
                const past_7_days = new Date(today.setDate(today.getDate() - 7));
                return latest_fulfilled_order_date >= past_7_days;
            }
            if (selected_fulfilled_date === 'past_30_days') {
                const past_30_days = new Date(today.setDate(today.getDate() - 30));
                return latest_fulfilled_order_date >= past_30_days;
            }
            if (selected_fulfilled_date === 'past_3_months') {
                const past_3_months = new Date(today.setMonth(today.getMonth() - 3));
                return latest_fulfilled_order_date >= past_3_months;
            }
            if (selected_fulfilled_date === 'past_6_months') {
                const past_6_months = new Date(today.setMonth(today.getMonth() - 6));
                return latest_fulfilled_order_date >= past_6_months;
            }
            if (selected_fulfilled_date === 'past_12_months') {
                const past_12_months = new Date(today.setMonth(today.getMonth() - 12));
                return latest_fulfilled_order_date >= past_12_months;
            }
            return true;
        });

    // lazy load customers because it's a big file
    React.useEffect(() => {
        const fetchCustomers = async () => {
            const customers_raw = await import(
                '../../data/static-views/customers-grouped.json'
            );
            const cities_accumulated = {};

            const c = Object.values(customers_raw)
            // remove customer that has no coords equal to ","
                .filter((customer) => customer.coords !== ',')
            // sort by total_orders
                .sort((a, b) => b.total_orders - a.total_orders);
                // .slice(0, 500);

            c.forEach((customer) => {
                const city = customer.city || 'المدينة غير محددة';
                if (!cities_accumulated[city]) {
                    cities_accumulated[city] = 1;
                } else {
                    cities_accumulated[city]++;
                }
            });

            const sortedAccumulatedCities = Object.keys(cities_accumulated)
                .sort((a, b) => cities_accumulated[b] - cities_accumulated[a])
                .map((city) => {
                    return {
                        city,
                        count: cities_accumulated[city],
                    };
                });

            set_customers(formatRawData(c));
            set_cities(sortedAccumulatedCities);
            set_loading(false);
        };

        setTimeout(() => {
            fetchCustomers();
        }, 1000);
    }, []);

    return (
        <>
            <div className="alert alert-warning text-center">
                ⚠ برجاء الملاحظة ان هذة البيانات يتم تحديثها اسبوعياًَ ⚠
            </div>
            <div className="stats" style={{ display: 'none' }}>
                <h1>نظرة سريعة</h1>
                <Row>
                    <Col>
                        <div className="stat-card">
                            <img src={growth} />
                            <div>
                                <b>
                                    {formatMoney(
                                        customersStats.total_customers_how_didnt_placed_orders
                                    )}
                                    <small>عميل</small>
                                </b>
                                <span>عملاء لم يطلبوا ابداً</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="stat-card">
                            <img src={ordersIcon} />
                            <div>
                                <b>
                                    {formatMoney(
                                        customersStats.total_customers_how_placed_orders
                                    )}
                                    <small>عميل</small>
                                </b>
                                <span>عملاء لديهم طلب واحد مكتمل على الأقل</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="stat-card">
                            <img src={ordersIcon} />
                            <div>
                                <b>
                                    {formatMoney(customersStats.total_orders)}
                                    <small>طلب</small>
                                </b>
                                <span>إجمالي عدد الطلبات المكتملة</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="stat-card">
                            <img src={ordersIcon} />
                            <div>
                                <b>
                                    {customersStats.total_verified_customers ? 
                                        Math.round((customersStats.total_verified_customers / customers.length) * 100) : 0
                                    }
                                    <small>%</small>
                                </b>
                                <span>نسبة العملاء الموثقين</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="stat-card">
                            <img src={clockIcon} style={{ width: '20px' }} />
                            <div>
                                <b>
                                    {formatMoney(customersStats.customers_ordered_in_last_7_days)}
                                    <small>عميل</small>
                                </b>
                                <span>عدد العملاء الذي تم توصيل طلبات إليهم في آخر 7 أيام</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="stat-card">
                            <img src={clockIcon} style={{ width: '20px' }} />
                            <div>
                                <b>
                                    {formatMoney(
                                        customersStats.customers_ordered_in_last_30_days
                                    )}
                                    <small>عميل</small>
                                </b>
                                <span>عدد العملاء الذي تم توصيل طلبات إليهم في آخر 30 يوم</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="stat-card">
                            <img src={clockIcon} style={{ width: '20px' }} />
                            <div>
                                <b>
                                    {formatMoney(
                                        customersStats.customers_ordered_in_last_3_months
                                    )}
                                    <small>عميل</small>
                                </b>
                                <span>عدد العملاء الذي تم توصيل طلبات إليهم في آخر 3 أشهر</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <h1>خريطة دستي</h1>

            <div className="map-static-view">
                <Map
                    markers={data_filtered
                        .map((item) => ({
                            ...item.coords,
                            icon: item.type === 'customer' ? (
                                selected_orders_count === 'none' ? customerBlackIcon  : customerIcon
                            ) : vendorIcon,
                        }))}
                    center={selected_marker_coords || center_of_SA}
                    zoom={zoom}
                />

                <div className="search-input">
                    <input
                        type="text"
                        // eslint-disable-next-line max-len
                        placeholder={`إبحث في ${data_filtered.length} ${
                            selected_filter === 'customers' ? 'نقطة بيع' : 'مورد'
                        }`}
                        value={q}
                        onChange={(e) => set_q(e.target.value)}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                    >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                </div>

                {selected_filter === 'customers' && (
                    <ListComponent
                        data={customers}
                        loading={loading}
                        show_tiers_widget={show_tiers_widget}
                        set_show_tiers_widget={set_show_tiers_widget}
                        title="عملاء مميزين"
                    />
                )}

                {/* city selector */}
                {selected_filter === 'customers' && (
                    <div className="map-filters">
                        <div className="city-selector">
                            <label>فرز حسب المدينة</label>
                            <select
                                value={selected_city}
                                onChange={(e) => set_selected_city(e.target.value)}
                            >
                                <option value="all">جميع المدن</option>
                                {cities.map((city) => (
                                    <option key={city.city} value={city.city}>
                                        {city.city} ({city.count})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="date-selector">
                            <label>فرز حسب تاريخ الطلب</label>
                            <select
                                value={selected_ordered_date}
                                onChange={(e) => set_selected_ordered_date(e.target.value)}
                            >
                                <option value="all">الكل</option>
                                <option value="today">اليوم</option>
                                <option value="past_7_days">آخر 7 أيام</option>
                                <option value="past_30_days">آخر 30 يوم</option>
                                <option value="past_3_months">آخر 3 شهور</option>
                                <option value="past_6_months">آخر 6 شهور</option>
                                <option value="past_12_months">آخر سنة</option>
                            </select>
                        </div>
                        <div className="date-selector">
                            <label>فرز حسب تاريخ التوصيل</label>
                            <select
                                value={selected_fulfilled_date}
                                onChange={(e) => set_selected_fulfilled_date(e.target.value)}
                            >
                                <option value="all">الكل</option>
                                <option value="today">اليوم</option>
                                <option value="past_7_days">آخر 7 أيام</option>
                                <option value="past_30_days">آخر 30 يوم</option>
                                <option value="past_3_months">آخر 3 شهور</option>
                                <option value="past_6_months">آخر 6 شهور</option>
                                <option value="past_12_months">آخر سنة</option>
                            </select>
                        </div>
                        <div className="date-selector">
                            <label>فرز حسب عدد الطلبات المكتملة</label>
                            <select
                                value={selected_orders_count}
                                onChange={(e) => set_selected_orders_count(e.target.value)}
                            >
                                <option value="all">الكل</option>
                                <option value="none">ليس لديه أي طلبات مكتملة</option>
                                <option value="1">طلب واحد فقط</option>
                                <option value="5">أقل من 5 طلبات</option>
                                <option value="10">أقل من 10 طلبات</option>
                                <option value="20">أقل من 20 طلبات</option>
                                <option value="50">أقل من 50 طلبات</option>
                            </select>
                        </div>
                    </div>
                )}

                <div className="info-cards">
                    {loading ? (
                        <Spinner size="sm" />
                    ) : (
                        data_filtered
                            ?.map((l, i) => (
                                <div
                                    className="location-info-card"
                                    key={i}
                                    onClick={() => {
                                        set_selected_marker_coords(l.coords);
                                        set_zoom(15);
                                    }}
                                >
                                    <h5>
                                        <img src={l.icon} /> {l.name}
                                    </h5>
                                    <div className="location-info">
                                        <div className="content">
                                            <img src={callIcon} alt="call" style={{ width: '15px' }} />
                                            <p>{l.mobile || 'لا يوجد هاتف'}</p>
                                        </div>
                                        {l.type === 'customer' && (
                                            <>
                                                <div className="content">
                                                    <img
                                                        src={locationIcon}
                                                        alt="call"
                                                        style={{ width: '15px' }}
                                                    />
                                                    <p>{l.city}</p>
                                                </div>
                                                <div className="content">
                                                    <img
                                                        src={ordersIcon}
                                                        alt="call"
                                                        style={{ width: '15px' }}
                                                    />
                                                    <p>{l.total_orders} طلب مكتمل</p>
                                                </div>
                                                <div className="content">
                                                    <img
                                                        src={branchesIcon}
                                                        alt="call"
                                                        style={{ width: '15px' }}
                                                    />
                                                    <p>لديه {l.addresses?.length || 1} عنوان</p>
                                                </div>
                                                <div className="content">
                                                    <img
                                                        src={clockIcon}
                                                        alt="call"
                                                        style={{ width: '15px' }}
                                                    />
                                                    <p>اخر طلب {formatDate(l.latest_order_date)}</p>
                                                </div>
                                                <div className="content">
                                                    <img
                                                        src={clockIcon}
                                                        alt="call"
                                                        style={{ width: '15px' }}
                                                    />
                                                    <p>اخر توصيل {formatDate(l.latest_fulfilled_order_date)}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="btns">
                                        <a
                                            href={'https://admin.disty.sa/' + l.type + 's/' + l.id}
                                            target="_blank"
                                            className={
                                            // eslint-disable-next-line max-len
                                                'btn btn-' + (l.type === 'vendor' ? 'golden' : 'primary')
                                            }
                                            rel="noreferrer"
                                        >
										فتح ملف {l.type === 'vendor' ? 'المورد' : 'المتجر'} في لوحة
										تحكم دستي
                                        </a>
                                    </div>
                                </div>
                            ))
                    )}
                </div>

                <div className="type-togglers">
                    <div
                        className={`type-togglers__item ${
                            selected_filter === 'all' ? 'active' : ''
                        }`}
                        onClick={() => set_selected_filter('all')}
                    >
						الكل
                    </div>
                    <div
                        className={`type-togglers__item ${
                            selected_filter === 'vendors' ? 'active' : ''
                        }`}
                        onClick={() => set_selected_filter('vendors')}
                    >
						الموردين
                        <img src={vendorIcon} style={{ width: 25 }} />
                    </div>
                    <div
                        className={`type-togglers__item ${
                            selected_filter === 'customers' ? 'active' : ''
                        }`}
                        onClick={() => set_selected_filter('customers')}
                    >
						نقط البيع
                        <img src={customerIcon} style={{ width: 30 }} />
                    </div>
                </div>
            </div>
        </>
    );
}
