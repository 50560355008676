import { Checkbox, Toggler } from '@components';
import { DEFAULT_DELIVERY_OPTIONS } from '@components/shared/Filters/constants';
import React, { useEffect } from 'react';

const ShippingFulfillmentSettingsField = ({ onChange, initialOptions }) => {
    const [enableDeliveryOptions, setEnableDeliveryOptions] = React.useState(true);
    const [deliveryOptions, setDeliveryOptions] = React.useState([]);

    const availableDeliveryOptions = [
        {
            code: 'DBV',
            description: 'Deliver By My Fleet',
        },
        {
            code: 'DBQ',
            description: 'Deliver By Disty',
        },
    ];

    useEffect(() => {
        if (initialOptions?.length) {
            setEnableDeliveryOptions(true);
            setDeliveryOptions(initialOptions);
        }
    }, [initialOptions]);

    return (
        <div>
            <div style={{ maxWidth: 300 }}>
                <Toggler
                    checked={enableDeliveryOptions}
                    onChange={() => {
                        setEnableDeliveryOptions((prev) => {
                            const newDeliveryOptions = prev ? null : DEFAULT_DELIVERY_OPTIONS;
                            onChange(newDeliveryOptions);
                            setDeliveryOptions(newDeliveryOptions || []);

                            return !prev;
                        });
                    }}
                    name="ShippingFulfillmentSettings"
                    label="Enable Delivery Options"
                />
            </div>
            <div>
                {enableDeliveryOptions &&
                    availableDeliveryOptions.map((option) => {
                        const checked = deliveryOptions.includes(option.code);

                        return (
                            <Checkbox
                                key={option.code}
                                checked={checked}
                                label={option.description}
                                onChange={() =>
                                    setDeliveryOptions((prev) => {
                                        const newOptions = checked
                                            ? prev.filter((code) => !(code === option.code))
                                            : [...prev, option.code];
                                        onChange(newOptions.length ? newOptions : null);
                                        return newOptions;
                                    })
                                }
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default ShippingFulfillmentSettingsField;
