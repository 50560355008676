import React from 'react';
import { PUT } from '@utils';
import { Row, Col, CriticalActionButton } from '@components';
import { useParams } from 'react-router';
import { loadProductData } from '../../../../store/slices/products.slice';
import { useDispatch, useSelector } from 'react-redux';
import SelectVariantOrBase from '../../components/SelectVariantOrBase';
import useQuery from '@hooks/useQuery';

function ProductCustomAttributesTab() {
    return (
        <SelectVariantOrBase>
            {({ selected }) => <UpdateProductCustomAttributes selectedId={selected} />}
        </SelectVariantOrBase>
    );
}

function UpdateProductCustomAttributes({ selectedId }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [custom_attributes, set_custom_attributes] = React.useState([]);
    const [errors, set_errors] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const product = useSelector((state) => state.ProductsSlice.product);
    const { data: { data } = { data: [] } } = useQuery('/product-custom-attribute', {
        is_active: true,
    });

    React.useEffect(() => {
        if (product?.id && data?.length) {
            const all_attributes = data.map((a) => {
                return {
                    id: a?.id,
                    name_en: a.name_en,
                    name_ar: a.name_ar,
                    value_ar: '',
                    value_en: '',
                    unit_ar: '',
                    unit_en: '',
                };
            });
            const variant_custom_attributes =
                product.variants.find(({ id }) => id === selectedId).custom_attributes || [];

            set_custom_attributes([
                ...variant_custom_attributes,
                ...filterEmptyAttribute(all_attributes, variant_custom_attributes),
            ]);
        }
    }, [data, product, selectedId]);

    const filterEmptyAttribute = (parent, sub) => {
        return parent.filter((parentObj) => {
            return !sub.some((subObj) => {
                return parentObj.id === subObj.id;
            });
        });
    };
    const handleProductAttribute = async () => {
        const attr = custom_attributes
            ?.filter((a) => a.value_ar && a.value_en)
            .map((x) => {
                return {
                    id: x?.id,
                    value_ar: x.value_ar,
                    value_en: x.value_en,
                    unit_ar: x.unit_ar,
                    unit_en: x.unit_en,
                };
            });
        set_loading(true);
        const res = await PUT({
            endpoint: `/product/${selectedId}/custom-attribute`,
            data: { custom_attributes: attr },
        });
        set_loading(false);
        if (res?.success === true) {
            dispatch(loadProductData(id));
            set_errors([]);
            set_updated('Success');
            setTimeout(() => {
                set_updated(null);
            }, 2000);
        } else {
            set_errors(res?.data.errors);
        }
    };

    return (
        <>
            <div className="product-attributes-tab">
                {updated && <div className="alert alert-success">{updated}</div>}
                {!!Object.keys(errors).length && (
                    <div className="alert alert-danger">
                        <ul>
                            {Object.keys(errors).map((key) => {
                                return <li key={key}>{errors[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <div className="section-custom-attribute">
                    <div className="flex__jc-end mb-3">
                        <CriticalActionButton
                            disabled={loading}
                            className="px-4"
                            onSubmit={() => {
                                handleProductAttribute();
                            }}
                        >
                            Update
                        </CriticalActionButton>
                    </div>

                    <h3 className="divider-title">Product Custom attributes </h3>
                    {custom_attributes?.map((a) => (
                        <div className="attribute-form" key={a.id}>
                            <h4>
                                {a.name_ar} &nbsp; - &nbsp; {a.name_en}
                            </h4>
                            <Row>
                                <Col lg={6}>
                                    <div className="flex__ai-center__jc-between">
                                        <label htmlFor="value_ar"> value ar</label>
                         
                                    </div>
                                    <input
                                        value={a.value_ar}
                                        id="value_ar"
                                        type="text"
                                        onChange={(e) => {
                                            const value_ar = e.target.value;
                                            set_custom_attributes((prev) =>
                                                prev?.map((x) =>
                                                    x.id === a.id ? { ...x, value_ar } : x,
                                                ),
                                            );
                                        }}
                                    />
                                     {!a.value_ar && a.value_en && (
                                            <p style={{ color:"red" ,margin:'-10px 0 15px'  } } >
                                                The value ar field is required when value en is
                                                present.
                                            </p>
                                        )}
                                    <div className="flex__ai-center__jc-between">
                                        <label htmlFor="unit_ar"> unit ar</label>
                                        
                                    </div>
                                    <input
                                        value={a.unit_ar}
                                        id="unit_ar"
                                        type="text"
                                        onChange={(e) => {
                                            const unit_ar = e.target.value;
                                            set_custom_attributes((prev) =>
                                                prev?.map((x) =>
                                                    x.id === a.id ? { ...x, unit_ar } : x,
                                                ),
                                            );
                                        }}
                                    />
                                    {!a.unit_ar && a.unit_en && (
                                            <span className="text-danger">
                                                The unit ar field is required when unit en is
                                                present.
                                            </span>
                                        )}
                                </Col>
                                <Col lg={6}>
                                    <div className="flex__ai-center__jc-between">
                                        <label htmlFor="value_en"> value en</label>
                                        
                                    </div>
                                    <input
                                        value={a.value_en}
                                        id="value_en"
                                        type="text"
                                        onChange={(e) => {
                                            const value_en = e.target.value;
                                            set_custom_attributes((prev) =>
                                                prev?.map((x) =>
                                                    x.id === a.id ? { ...x, value_en } : x,
                                                ),
                                            );
                                        }}
                                    />
                                    {!a.value_en && a.value_ar && (
                                             <span style={{ color:"red" ,margin:'-10px 0 15px'  } } >
                                                The value en field is required when value ar is
                                                present.
                                            </span>
                                        )}
                                    <div className="flex__ai-center__jc-between">
                                        <label htmlFor="unit_en"> unit en</label>
                                        {!a.unit_en && a.unit_ar && (
                                            <span className="text-danger">
                                                The unit en field is required when unit ar is
                                                present.
                                            </span>
                                        )}
                                    </div>
                                    <input
                                        value={a?.unit_en}
                                        id="unit_en"
                                        type="text"
                                        onChange={(e) => {
                                            const unit_en = e.target.value;
                                            set_custom_attributes((prev) =>
                                                prev?.map((x) =>
                                                    x.id === a.id ? { ...x, unit_en } : x,
                                                ),
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ProductCustomAttributesTab;
