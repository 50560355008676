import { Button, Input, Select, ShowErrors } from '@components';
import useMutation from '@hooks/useMutation';
import useQuery from '@hooks/useQuery';
import { loadVendorData } from '@slices/merchant.slice';
import { POST } from '@utils';
import serialize from '@utils/objectSerializer';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const BankDetailsTab = () => {
    const vendor = useSelector((state) => state.VendorsSlice.vendor);
    const dispatch = useDispatch();

    const [bank_name, set_bank_name] = React.useState({
        label: 'Bank name',
        options: [],
        value: {},
        getOptionValue: (option) => option.id,
        onChange: (val) => set_bank_name((prev) => ({ ...prev, value: val })),
    });
    const [holder_name, set_holder_name] = React.useState({
        label: 'Holder name',
        type: 'text',
        value: '',
        setValue: (val) => set_holder_name({ ...holder_name, value: val }),
    });
    const [account_number, set_account_number] = React.useState({
        label: 'Account number',
        type: 'number',
        value: '',
        setValue: (val) => set_account_number({ ...account_number, value: val }),
    });
    const [iban, set_iban] = React.useState({
        label: 'IBAN',
        type: 'text',
        value: '',
        setValue: (val) => set_iban({ ...iban, value: val }),
    });
    const [loading, set_loading] = React.useState(false);

    const [validation_errors, set_validation_errors] = React.useState({});
    const validateIBAN = (iban) => {
        iban = iban.replace(/\s/g, '').toUpperCase();
        if (iban.length < 4 || iban.length > 34) {
            return false;
        }
        iban = iban.substring(4) + iban.substring(0, 4);
        let numericIBAN = '';
        for (let i = 0; i < iban.length; i++) {
            const char = iban.charAt(i);
            if (char >= 'A' && char <= 'Z') {
                numericIBAN += (char.charCodeAt(0) - 55).toString();
            } else {
                numericIBAN += char;
            }
        }
        let remainder = '';
        for (let i = 0; i < numericIBAN.length; i++) {
            const currentChar = numericIBAN.charAt(i);
            remainder = (remainder + currentChar) % 97;
        }

        return remainder === 1;
    };

    const validate = () => {
        const newErrors = {};
        if (!account_number.value)
            newErrors.account_number = 'The bank account number field is required.';
        if (
            !holder_name.value ||
            holder_name.value.length < 6 ||
            !/^\s*\S+\s+\S+/.test(holder_name.value)
        )
            newErrors.holder_name =
                'The Holder Name field is required, must be at least 6 characters long, and must contain at least a first and last name.';
        if (!iban.value) {
            newErrors.iban = 'The Iban field is required.';
        } else if (!validateIBAN(iban.value)) {
            newErrors.iban = 'Invalid IBAN format.';
        }     
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Validate on value change
    React.useEffect(() => {
        validate();
    }, [
        iban,
        account_number,
        holder_name,
    ]);
    const { data } = useQuery('/payment/banks');

    const banks =
        data?.data?.map((b) => ({
            label: b.name_en,
            id: b.id,
        })) || [];

    const {  mutate, error } = useMutation(
        () =>
            POST({
                endpoint: `/vendors/${vendor.id}`,
                data: serialize(
                    {
                        _method: 'PATCH',
                        bank_account: {
                            bank_id: bank_name.value.id,
                            holder_name: holder_name.value,
                            account_number: account_number.value,
                            iban: iban.value,
                        },
                    },
                    { booleansAsIntegers: true, nullsAsUndefineds: true },
                ),
            }),
        {
            onSuccess: (res) => {
                dispatch(loadVendorData(vendor?.id));
                toast.success(res.message);
            },
            onError: (_, res) => {
                toast.error(res.message || 'Something went wrong!');
            },
        },
    );

    useEffect(() => {
        if (vendor?.bank_account?.bank)
            bank_name.onChange({
                label: vendor?.bank_account?.bank?.name,
                id: vendor?.bank_account?.bank?.id,
            });
        holder_name.setValue(vendor?.bank_account?.holder_name);
        account_number.setValue(vendor?.bank_account?.account_number);
        iban.setValue(vendor?.bank_account?.iban);

        return () => {};
    }, [vendor]);

    return (
        <div>
            {/* Bank details fields  */}
            <h3 className="divider-title">Bank details</h3>
            <ShowErrors errors={error} />
            <Row>
                <Col lg={6}>
                    <Select {...bank_name} options={banks} />

                    <Input {...account_number} />
                    {loading && validation_errors.account_number && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.account_number}
                                        </p>
                                    )}
                </Col>
                <Col lg={6}>
                    <Input {...holder_name} />
                    {loading && validation_errors.holder_name && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.holder_name}
                                        </p>
                                    )}
                    <Input {...iban} />
                    {loading && validation_errors.iban && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.iban}
                                        </p>
                                    )}
                </Col>
            </Row>

            <Button  onClick={()=>{
                set_loading(true) ;
                if(!validate()) return ;
                mutate();
            }}>
                Save
            </Button>
        </div>
    );
};

export default BankDetailsTab;
