import React, { useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import ordersIcon from '@assets/icons/sidebar/orders.svg';
import childOrdersIcon from '@assets/icons/sidebar/child-orders.svg';
import liveCartsIcon from '@assets/icons/sidebar/cart.svg';
// HIDDEN BY ASALEM import warehouseIcon from '@assets/icons/sidebar/warehouse.svg';
import customersIcon from '@assets/icons/sidebar/customers.svg';
import merchantsIcon from '@assets/icons/sidebar/merchants.svg';
import merchantsLeadsIcon from '@assets/icons/sidebar/merchants-leads.svg';
import productsIcon from '@assets/icons/sidebar/products.svg';
import categoriesIcon from '@assets/icons/sidebar/categories.svg';
import collectionsIcon from '@assets/icons/sidebar/collections.svg';
import brandsIcon from '@assets/icons/sidebar/brands.svg';
import bannersIcon from '@assets/icons/sidebar/banners.svg';
import discountsIcon from '@assets/icons/sidebar/discounts.svg';
// HIDDEN BY ASALEM import notificationsIcon from '@assets/icons/sidebar/notifications.svg';
// HIDDEN BY ASALEM import sendNotificationsIcon from '@assets/icons/sidebar/send.svg';
import configIcon from '@assets/icons/sidebar/config.svg';
import logsIcon from '@assets/icons/sidebar/logs.svg';
import rolesIcon from '@assets/icons/sidebar/roles.svg';
import adminsIcon from '@assets/icons/sidebar/admins.svg';
// import statsIcon from '@assets/icons/sidebar/stats.svg';
// HIDDEN BY ASALEM import locationIcon from '@assets/icons/sidebar/location.svg';
// HIDDEN BY ASALEM import ratingIcon from '@assets/icons/sidebar/rating.svg';
// HIDDEN BY ASALEM import mobileUpdateIcon from '@assets/icons/sidebar/mobile-update.svg';
// HIDDEN BY ASALEM import instructionsIcon from '@assets/icons/sidebar/instructions.svg';
// HIDDEN BY ASALEM import invoicesIcon from '@assets/icons/sidebar/invoices.svg';
// HIDDEN BY ASALEM import appFeedbackIcon from '@assets/icons/sidebar/app-feedback.svg';
// HIDDEN BY ASALEM import bankIcon from '@assets/icons/sidebar/bank.svg';
// active icons
import ordersIcon_active from '@assets/icons/sidebar/active/orders.svg';
import childOrdersIcon_active from '@assets/icons/sidebar/active/child-orders.svg';
import liveCartsIcon_active from '@assets/icons/sidebar/active/cart.svg';
// HIDDEN BY ASALEM import warehouseIcon_active from '@assets/icons/sidebar/active/warehouse.svg';
import customersIcon_active from '@assets/icons/sidebar/active/customers.svg';
import merchantsIcon_active from '@assets/icons/sidebar/active/merchants.svg';
import merchantsLeadsIcon_active from '@assets/icons/sidebar/active/merchants-leads.svg';
import productsIcon_active from '@assets/icons/sidebar/active/products.svg';
import categoriesIcon_active from '@assets/icons/sidebar/active/categories.svg';
import collectionsIcon_active from '@assets/icons/sidebar/active/collections.svg';
import brandsIcon_active from '@assets/icons/sidebar/active/brands.svg';
import bannersIcon_active from '@assets/icons/sidebar/active/banners.svg';
import discountsIcon_active from '@assets/icons/sidebar/active/discounts.svg';
// HIDDEN BY ASALEM import notificationsIcon_active from '@assets/icons/sidebar/active/notifications.svg';
import configIcon_active from '@assets/icons/sidebar/active/config.svg';
import logsIcon_active from '@assets/icons/sidebar/active/logs.svg';
import rolesIcon_active from '@assets/icons/sidebar/active/roles.svg';
import adminsIcon_active from '@assets/icons/sidebar/active/admins.svg';
// import statsIcon_active from '@assets/icons/sidebar/active/stats.svg';
// HIDDEN BY ASALEM  import locationIcon_active from '@assets/icons/sidebar/active/location.svg';
// HIDDEN BY ASALEM import ratingIcon_active from '@assets/icons/sidebar/active/rating.svg';
// HIDDEN BY ASALEM import mobileUpdateIcon_active from '@assets/icons/sidebar/active/mobile-update.svg';
// HIDDEN BY ASALEM import instructionsIcon_active from '@assets/icons/sidebar/active/instructions.svg';
// HIDDEN BY ASALEM import invoicesIcon_active from '@assets/icons/sidebar/active/invoices.svg';
// HIDDEN BY ASALEM import appFeedbackIcon_active from '@assets/icons/sidebar/active/app-feedback.svg';
// HIDDEN BY ASALEM import bankIcon_active from '@assets/icons/sidebar/active/bank.svg';
import verifiedIcon from '@assets/icons/sidebar/verified_customer.svg';
import verifiedIcon_active from '@assets/icons/sidebar/active/verified_customer.svg';

import { useSelector, useDispatch } from 'react-redux';
import { setSidebarStatus } from '../../store/slices/ui.slice';
import logo from '@assets/img/logo.svg';
import logoutIcon from '@assets/icons/logout.svg';

const Sidebar = ({ location }) => {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    const dispatch = useDispatch();
    const [size, set_size] = React.useState(window.innerWidth);
    const [is_collbased, set_is_collbased] = React.useState(true);
    const [is_sidebar_open, set_is_sidebar_open] = React.useState(false);
    const auth_slice = useSelector((state) => state.AuthSlice);
    const user = useSelector((state) => state.AuthSlice.user);
    const [collapsed_label_data, set_collapsed_label_data] = React.useState(null);
    const [currentRoute, setCurrentRoute] = useState(location.pathname);

    useEffect(() => {
        setCurrentRoute(location.pathname);
    }, [location]);

    if (user.name) {
        var initials = [...user.name.matchAll(rgx)] || [];
        initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
    }
    const checkSize = () => {
        set_size(window.innerWidth);
        if (window.innerWidth < 600) {
            set_is_collbased(false);
            set_is_sidebar_open(false);
        }
    };
    React.useEffect(() => {
        window.addEventListener('resize', checkSize);
        return () => {
            window.removeEventListener('resize', checkSize);
        };
    });
    const baseSidebarContent = [
        // {
        //     label: 'statistics',
        //     links: [
        //         {
        //             icon: statsIcon,
        //             activeIcon: statsIcon_active,
        //             txt: 'Statistics',
        //             activeRoute: '/statistics',
        //             href: '/statistics',
        //             permission: 'statistics'
        //         }
        //     ]
        // },
        {
            label: 'orders',
            links: [
                {
                    icon: ordersIcon,
                    activeIcon: ordersIcon_active,
                    txt: 'Orders Birds Eye View',
                    activeRoute: '/orders/',
                    href: '/orders/list',
                    permission: 'orders',
                },
                {
                    icon: childOrdersIcon,
                    activeIcon: childOrdersIcon_active,
                    txt: 'Child Orders',
                    activeRoute: '/child-orders/',
                    href: '/child-orders/list',
                    permission: 'suborders',
                },
                {
                    icon: childOrdersIcon,
                    activeIcon: childOrdersIcon_active,
                    txt: 'Invoice Financing Orders',
                    activeRoute: '/invoice-financing-orders',
                    href: '/invoice-financing-orders',
                    permission: 'suborders',
                },
                {
                    icon: liveCartsIcon,
                    activeIcon: liveCartsIcon_active,
                    txt: 'Live carts',
                    activeRoute: '/live-carts/',
                    href: '/live-carts/list',
                    permission: 'cart',
                },
            ],
        },
        /*{
            label: 'Operation',
            links: [
                {
                    icon: warehouseIcon,
                    activeIcon: warehouseIcon_active,
                    txt: 'Warehouse grid',
                    activeRoute: '/warehouse/',
                    href: '/warehouse/list',
                    permission: 'Warehouses',
                },
                {
                    icon: locationIcon,
                    activeIcon: locationIcon_active,
                    txt: 'Orders Map',
                    activeRoute: '/orders-map/',
                    href: '/orders-map',
                    permission: 'orders',
                },
            ],
        },*/
        {
            label: 'Retail & Requests',
            links: [
                {
                    icon: customersIcon,
                    activeIcon: customersIcon_active,
                    txt: 'Retails',
                    activeRoute: '/customers/',
                    href: '/customers/list',
                    permission: 'customers',
                },
                {
                    icon: merchantsLeadsIcon,
                    activeIcon: merchantsLeadsIcon_active,
                    txt: 'Retailers Users',
                    activeRoute: '/retailers-users/',
                    href: '/retailers-users/list',
                    permission: 'customers',
                },
                /*{
                    icon: vendorsLeadsIcon,
                    activeIcon: vendorsLeadsIcon_active,
                    txt: 'Merging Accounts',
                    activeRoute: '/merging-accounts/',
                    href: '/merging-accounts/',
                    permission: 'customers.merge',
                },*/
                {
                    icon: verifiedIcon,
                    activeIcon: verifiedIcon_active,
                    txt: 'Verification Center',
                    activeRoute: '/verification-center/',
                    href: '/verification-center/list',
                    permission: 'verification_requests.read',
                },
                
                /*{
                    icon: vendorsLeadsIcon,
                    activeIcon: vendorsLeadsIcon_active,
                    txt: 'Requested Products',
                    activeRoute: '/requested-products/',
                    href: '/requested-products/list',
                    permission: 'productRequest',
                },
                // Requested vendors position
                {
                    icon: appFeedbackIcon,
                    activeIcon: appFeedbackIcon_active,
                    txt: 'App Feedback',
                    activeRoute: '/app-feedback/',
                    href: '/app-feedback/list',
                    permission: 'feedback',
                },*/
            ],
        },
        {
            label: 'Merchants',
            links: [
                {
                    icon: categoriesIcon,
                    activeIcon: categoriesIcon_active,
                    txt: 'Merchant Categories',
                    activeRoute: '/merchant-categories/',
                    href: '/merchant-categories/list',
                    permission: 'vendor_categories',
                },
                {
                    icon: merchantsIcon,
                    activeIcon: merchantsIcon_active,
                    txt: 'Merchants',
                    activeRoute: '/merchants/',
                    href: '/merchants/list',
                    permission: 'vendors',
                },
                {
                    icon: merchantsLeadsIcon,
                    activeIcon: merchantsLeadsIcon_active,
                    txt: 'Merchant Users',
                    activeRoute: '/merchant-users/',
                    href: '/merchant-users/list',
                    permission: 'vendorUser',
                },
                {
                    icon: merchantsLeadsIcon,
                    activeIcon: merchantsLeadsIcon_active,
                    txt: 'Requested Merchants',
                    activeRoute: '/requested-merchants/',
                    href: '/requested-merchants/list',
                    permission: 'requestVendors',
                },
                // Vendor Requests position
                /*{
                    icon: vendorsLeadsIcon,
                    activeIcon: vendorsLeadsIcon_active,
                    txt: 'Merchant leads',
                    activeRoute: '/vendors-leads/',
                    href: '/vendors-leads/list',
                    permission: 'vendorLeads',
                },*/
                {
                    icon: merchantsLeadsIcon,
                    activeIcon: merchantsLeadsIcon_active,
                    txt: 'Acquisitions',
                    activeRoute: '/acquisitions/',
                    href: '/acquisitions/list',
                    permission: 'acquisitions',
                },
                {
                    icon: merchantsLeadsIcon,
                    activeIcon: merchantsLeadsIcon_active,
                    txt: 'Merchant Requests',
                    activeRoute: '/vendors-requests/',
                    href: '/merchant-requests',
                    permission: 'vendorUser',
                },
                /*{
                    icon: vendorsLeadsIcon,
                    activeIcon: vendorsLeadsIcon_active,
                    txt: 'Merchant Product Requests',
                    activeRoute: '/vendors-requests/',
                    href: '/vendor-product-requests',
                    permission: 'vendorUser',
                },*/
            ],
        }, 
         {
            label: 'Wire Transactions',
            links: [
                {
                    icon: childOrdersIcon,
                    activeIcon: childOrdersIcon_active,
                    txt: 'Wire Transactions',
                    activeRoute: '/wire-transactions/',
                    href: '/wire-transactions/list',
                    permission: 'payment.list_transactions',
                }
            ],
        },

        /*{
            label: 'finance',
            links: [
                {
                    icon: vendorsLeadsIcon,
                    activeIcon: vendorsLeadsIcon_active,
                    txt: 'Purchase Orders',
                    activeRoute: '/purchase-orders/',
                    href: '/purchase-orders/list',
                    permission: 'orders',
                },
                {
                    icon: invoicesIcon,
                    activeIcon: invoicesIcon_active,
                    txt: 'Invoices',
                    activeRoute: '/invoices/',
                    href: '/invoices/list',
                    permission: 'self_invoices',
                },
                {
                    icon: invoicesIcon,
                    activeIcon: invoicesIcon_active,
                    txt: 'Wallet Withdrawal Requests',
                    activeRoute: '/withdrawal-requests/',
                    href: '/withdrawal-requests/list',
                    permission: 'payment.manage_withdraw_requests',
                },
                {
                    icon: bankIcon,
                    activeIcon: bankIcon_active,
                    txt: 'Virtual IBANs',
                    activeRoute: '/v-iban/',
                    href: '/v-iban/list',
                    permission: 'payment.list_virtual_ibans',
                },
                {
                    icon: invoicesIcon,
                    activeIcon: invoicesIcon,
                    txt: 'Merchant Payouts',
                    activeRoute: '/vendor-payouts/',
                    href: '/vendor-payouts',
                    permission: 'orders',
                },
                {
                    icon: invoicesIcon,
                    activeIcon: invoicesIcon,
                    txt: 'Re-Generate Financial Documents',
                    activeRoute: '/re-generate-financial-documents/',
                    href: '/re-generate-financial-documents',
                    permission: ['tax_invoices.reissue', 'manual_audit.list'],
                },
            ],
        },*/
        {
            label: 'products',
            links: [
                {
                    icon: productsIcon,
                    activeIcon: productsIcon_active,
                    txt: 'Products',
                    activeRoute: '/products/',
                    href: '/products/list',
                    permission: 'products',
                },
                {
                    icon: categoriesIcon,
                    activeIcon: categoriesIcon_active,
                    txt: 'Categories',
                    activeRoute: '/categories/',
                    href: '/categories/list',
                    permission: 'categories',
                },
                {
                    icon: collectionsIcon,
                    activeIcon: collectionsIcon_active,
                    txt: 'Collections',
                    activeRoute: '/collections/',
                    href: '/collections/list',
                    permission: 'productCollection',
                },
                {
                    icon: brandsIcon,
                    activeIcon: brandsIcon_active,
                    txt: 'Brands',
                    activeRoute: '/brands/',
                    href: '/brands/list',
                    permission: 'brands',
                },
            ],
        },
        {
            label: 'marketing & cx',
            links: [
                {
                    icon: bannersIcon,
                    activeIcon: bannersIcon_active,
                    txt: 'Banners',
                    activeRoute: '/banners/',
                    href: '/banners/list',
                    permission: 'banner',
                },
                {
                    icon: discountsIcon,
                    activeIcon: discountsIcon_active,
                    txt: 'Discounts',
                    activeRoute: '/discounts/',
                    href: '/discounts/list',
                    permission: 'discount',
                },
                /*{
                    icon: ratingIcon,
                    activeIcon: ratingIcon_active,
                    txt: 'rating',
                    activeRoute: '/rating/',
                    href: '/rating/list',
                    permission: 'ratingQuestions',
                },*/
            ],
        },
        /*{
            label: 'App customization',
            links: [
                {
                    icon: bannersIcon,
                    activeIcon: bannersIcon_active,
                    txt: 'Onboarding screens',
                    activeRoute: '/onboarding-screens/',
                    href: '/onboarding-screens/list',
                    permission: 'onboardingScreen',
                },
                {
                    icon: instructionsIcon,
                    activeIcon: instructionsIcon_active,
                    txt: 'App Instructions',
                    activeRoute: '/app-instructions/',
                    href: '/app-instructions/list',
                    permission: 'AppInstruction',
                },
                {
                    icon: mobileUpdateIcon,
                    activeIcon: mobileUpdateIcon_active,
                    txt: 'Force update',
                    activeRoute: '/force-update/',
                    href: '/force-update',
                    permission: 'admins.forceUpdate',
                },
            ],
        },*/
        /*{
            label: 'Push notifications',
            links: [
                {
                    icon: notificationsIcon,
                    activeIcon: notificationsIcon_active,
                    txt: 'Create Notifications',
                    activeRoute: '/customers-notifications/',
                    href: '/customers-notifications/list',
                    permission: 'notification',
                },
                {
                    icon: notificationsIcon,
                    activeIcon: notificationsIcon_active,
                    txt: 'Vendor notifications',
                    activeRoute: '/vendors-notifications/',
                    href: '/vendors-notifications/list',
                    permission: 'vendorNotifications',
                },
                {
                    icon: sendNotificationsIcon,
                    activeIcon: sendNotificationsIcon,
                    txt: 'Send notifications',
                    activeRoute: '/send-notifications/',
                    href: '/send-notifications/list',
                    permission: 'notification',
                },
            ],
        },*/
        {
            label: 'settings',
            links: [
                /*{
                    icon: configIcon,
                    activeIcon: configIcon_active,
                    txt: 'Help Topic Mapping',
                    activeRoute: '/help_mapping/',
                    href: '/help_mapping',
                    permission: 'packageType',
                },*/
                {
                    icon: configIcon,
                    activeIcon: configIcon_active,
                    txt: 'General Config',
                    activeRoute: '/configs/',
                    href: '/configs',
                    permission: 'packageType',
                },
                {
                    icon: logsIcon,
                    activeIcon: logsIcon_active,
                    txt: 'Audit',
                    activeRoute: '/logs/',
                    href: '/logs',
                    permission: 'logging',
                },
            ],
        },
        {
            label: 'Roles & Permissions',
            links: [
                {
                    icon: rolesIcon,
                    activeIcon: rolesIcon_active,
                    txt: 'User Groups',
                    activeRoute: '/users-groups/',
                    href: '/users-groups/list',
                    permission: 'groups',
                },
                {
                    icon: adminsIcon,
                    activeIcon: adminsIcon_active,
                    txt: 'Admins',
                    activeRoute: '/admins/',
                    href: '/admins/list',
                    permission: 'admins',
                },
            ],
        },
    ];

    const filteredSidebarContent = baseSidebarContent.filter((group) => {
        // there's no permissions for superAdmin, so return all groups
        if (auth_slice.user.permissions?.length === 0) {
            return group;
        } else {
            group.links = group.links.filter((l) =>
                auth_slice.user.permissions?.find(
                    (p) =>
                        p.split('.')[0] === l.permission ||
                        p === l.permission ||
                        l.permission?.includes(p),
                ),
            );
        }
        if (group.links.length > 0) return group;
    });

    return (
        <>
            {size < 600 && !is_sidebar_open && (
                <div className="mobile-icon-wrapper">
                    <div
                        className="mobile-icon"
                        onClick={() => {
                            set_is_sidebar_open(!is_sidebar_open);
                            set_is_collbased(false);
                        }}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>{' '}
                </div>
            )}
            <div
                className={`sidebar ${is_collbased ? 'collapsed' : ''}
             ${!is_sidebar_open && size < 600 ? 'hide-sidebar' : ''}`}
            >
                {size < 600 && is_sidebar_open && (
                    <div
                        className="layer"
                        onClick={() => {
                            set_is_sidebar_open(!is_sidebar_open);
                            set_is_collbased(false);
                        }}
                    ></div>
                )}
                <div className="sidebar-header">
                    {!is_collbased && (
                        <div className="logo">
                            <img src={logo} alt="Disty logo" className="logo" />
                        </div>
                    )}
                    {size > 600 && (
                        <div
                            className="menu-icon"
                            onClick={() => {
                                set_is_collbased(!is_collbased);
                            }}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    )}
                </div>
                {is_collbased && collapsed_label_data && (
                    <div
                        className="label-tooltip"
                        style={{
                            top: collapsed_label_data?.top,
                            color: collapsed_label_data?.is_active && '#4356fd',
                        }}
                    >
                        <p>{collapsed_label_data?.content}</p>
                    </div>
                )}
                <div className="sidebar-content">
                    <nav>
                        {filteredSidebarContent?.map((group, i) => (
                            <section key={i}>
                                {!is_collbased && <label>{group.label}</label>}
                                {group.links?.map((itm, i) => (
                                    <NavLink
                                        key={i}
                                        to={itm.href}
                                        className="nav-item"
                                        onClick={() => {
                                            dispatch(setSidebarStatus(false));
                                            set_is_sidebar_open(false);
                                        }}
                                    >
                                        <div
                                            className="icon"
                                            onMouseEnter={({ target }) => {
                                                set_collapsed_label_data({
                                                    content: itm.txt,
                                                    top: target.getBoundingClientRect().top,
                                                    // eslint-disable-next-line max-len
                                                    is_active: currentRoute.includes(
                                                        itm.activeRoute,
                                                    ),
                                                });
                                            }}
                                            onMouseLeave={() => set_collapsed_label_data(null)}
                                        >
                                            <img
                                                src={
                                                    currentRoute.includes(itm.activeRoute)
                                                        ? itm.activeIcon
                                                        : itm.icon
                                                }
                                                alt={itm.txt}
                                            />
                                        </div>
                                        <p>{itm.txt}</p>
                                    </NavLink>
                                ))}
                            </section>
                        ))}
                    </nav>
                </div>
                <div className="sidebar-footer">
                    {!is_collbased && (
                        <div className="user-badge">
                            <div className="avatar">{initials}</div>
                            <div className="name">{user.name}</div>
                        </div>
                    )}
                    <NavLink to="/logout" className="logout-btn">
                        <img src={logoutIcon} alt="logout" />
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default withRouter(Sidebar);
