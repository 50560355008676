import React from 'react';
import { DataTable , WhatsApp, Dialer } from '@components';
import { GET } from '@utils';

const AccountManagersTab = () => {
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/user/account-managers'
            });
            set_data(res?.data || []);
            set_loading(false);
        };
        fetchData();
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '50px',
        },
        {
            name: 'Name',
            selector: 'first_name',
            width: '200px',
            cell: (row) => (
                <>
                    <span>{row.first_name}</span>
                    &nbsp;
                    <span>{row.last_name}</span>
                </>
            )
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            minWidth: '130px',
            sortable: false,
            cell:(row)=> <Dialer number={row?.mobile}/>

                
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width:'70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile}/>
        },
        {
            name: 'Email',
            selector: 'email',
            minWidth: '180px',
            cell:(row)=>(<a href={`mailto:${row?.email}`}>
                {row?.email}</a> )
        },
        {
            name: 'Status',
            selector: 'is_active',
            width: '120px',
            cell: (row) => (
                <div
                    className={`status-indicator ${
                        row.is_active && 'active-indicator'
                    }`}
                >
                    {row.is_active ? 'Active' : 'Inactive'}
                </div>
            )
        },
        {
            name: 'Roles',
            selector: 'roles',
            cell: (row) => (
                <>
                    {row.roles.length > 0 ? (
                        <div>
                            {row.roles.map(r => (
                                <span 
                                    key={r.id} 
                                    className="badge badge-pill badge-light mr-2 mb-2"
                                >
                                    {r.name}
                                </span>
                            ))}
                        </div>
                    ) : (
                        <span className="badge badge-pill badge-light">No roles!</span>
                    )}
                </>
            )
        },
    ];
    return (
        <>  
            <DataTable
                data={data}
                columns={columns}
                loading={loading}
            />
        </>
    );
};

export default AccountManagersTab;