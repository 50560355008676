import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const CategoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        categories: [],
        categories_pages: null,
        category: {},
        categories_loading: true,
        category_loading: true,
    },
    reducers: {
        setCategories(state, { payload }) {
            state.categories = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.categories.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.categories[index] = {
                    ...state.categories[index],
                    ...payload,
                };
            }
        },
        setCategory(state, { payload }) {
            state.category = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setCategoriesTotalPages(state, { payload }) {
            state.categories_pages = payload;
        },
    },
});

// export actions
export const {
    setCategory,
    updateSingleRow,
    setCategories,
    setLoading,
    setCategoriesTotalPages,
} = CategoriesSlice.actions;

export const loadCategoriesData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'categories_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/category?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setCategories(res?.data || []));
    if(res?.meta){
        dispatch(setCategoriesTotalPages(res?.meta?.last_page || 0));
    }
    dispatch(
        setLoading({
            key: 'categories_loading',
            value: false,
        })
    );
};

export const loadCategoryData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'category_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/category/${id}`,
    });
    dispatch(setCategory(res?.data || {}));
    dispatch(
        setLoading({
            key: 'category_loading',
            value: false,
        })
    );
};

export default CategoriesSlice.reducer;
