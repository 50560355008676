import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import MapStaticView from './MapStaticView';
import { Helmet } from 'react-helmet';

export default function StaticViewsRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/static-views/map">
                <Helmet>
                    <title>Map | Disty Admin Dashboard</title>
                </Helmet>
                <MapStaticView />
            </ProtectedRoute>
        </Switch>
    );
}
