import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import VendorUsersListView from './MerchantUsersListView';
import VendorUsersAddView from './MerchantUsersAddView';
import VendorUsersUpdateView from './MerchantUsersUpdateView';
import { Helmet } from 'react-helmet';

export default function VendorUsersModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/merchant-users/list">
                <Helmet>
                    <title>Merchant Users | Disty Admin Dashboard</title>
                </Helmet>
                <VendorUsersListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/merchant-users/add">
                <Helmet>
                    <title>Add Merchantnt User | Disty Admin Dashboard</title>
                </Helmet>
                <VendorUsersAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/merchant-users/:id">
                <Helmet>
                    <title>Edit Merchant User | Disty Admin Dashboard</title>
                </Helmet>
                <VendorUsersUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
