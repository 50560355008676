import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET, POST, errorMessages } from '@utils';
import { toast } from 'react-toastify';

export const PayoutsSlice = createSlice({
    name: 'payouts',
    initialState: {
        payouts: [],
        payouts_loading: false,
        payouts_pages: null,
        payouts_records: null,

        // payout
        payout: {},
        payout_loading: false,
        payout_errors: {},

        // change payout
        change_payout_loading: false,
        change_payout_errors: {},

        // bulk payment
        bulk_payment_loading: false,
        bulk_payment_errors: {},

        // bulk payment with CSV
        bulk_payment_with_csv_loading: false,
        bulk_payment_with_csv_errors: {},

        // payout suborders
        payout_suborders: [],
        payout_suborders_pages: null,
        payout_suborders_records: null,
        payout_suborders_loading: false,
        payout_suborders_errors: {},

        // audits
        audits: [],
        audits_loading: false,
        audits_errors: {},

        // comments
        comments: [],
        comments_errors: {},
        comments_loading: false,

        // receipts
        receipts: [],
        receipts_errors: {},
        receipts_loading: false,
        receipt_uploading: false,
        receipt_errors: {},
    },
    reducers: {
        setPayouts(state, { payload }) {
            state.payouts = payload;
        },
        setPayoutsTotalPages(state, { payload }) {
            state.payouts_pages = payload;
        },
        setPayoutsRecords(state, { payload }) {
            state.payouts_records = payload;
        },
        setLoading(state, { payload }) {
            state.payouts_loading = payload;
        },

        // payout
        setPayout(state, { payload }) {
            state.payout = payload;
        },
        setPayoutLoading(state, { payload }) {
            state.payout_loading = payload;
        },
        setPayoutErrors(state, { payload }) {
            state.payout_errors = payload;
        },

        // change payout
        setChangePayoutLoading(state, { payload }) {
            state.change_payout_loading = payload;
        },
        setChangePayoutErrors(state, { payload }) {
            state.change_payout_errors = payload;
        },
        resetChangePayout(state) {
            state.change_payout_loading = false;
            state.change_payout_errors = {};
        },

        // bulk payment
        setBulkPaymentLoading(state, { payload }) {
            state.bulk_payment_loading = payload;
        },
        setBulkPaymentErrors(state, { payload }) {
            state.bulk_payment_errors = payload;
        },
        resetBulkPayment(state) {
            state.bulk_payment_loading = false;
            state.bulk_payment_errors = {};
        },

        // bulk payment with CSV
        setBulkPaymentWithCSVLoading(state, { payload }) {
            state.bulk_payment_with_csv_loading = payload;
        },
        setBulkPaymentWithCSVErrors(state, { payload }) {
            state.bulk_payment_with_csv_errors = payload;
        },
        resetBulkPaymentWithCSV(state) {
            state.bulk_payment_with_csv_loading = false;
            state.bulk_payment_with_csv_errors = {};
        },

        // payout suborders
        setPayoutSuborders(state, { payload }) {
            state.payout_suborders = payload;
        },
        setPayoutSubordersPages(state, { payload }) {
            state.payout_suborders_pages = payload;
        },
        setPayoutSubordersRecords(state, { payload }) {
            state.payout_suborders_records = payload;
        },
        setPayoutSubordersLoading(state, { payload }) {
            state.payout_suborders_loading = payload;
        },
        setPayoutSubordersErrors(state, { payload }) {
            state.payout_suborders_errors = payload;
        },
        resetPayoutSuborders(state) {
            state.payout_suborders = [];
            state.payout_suborders_pages = null;
            state.payout_suborders_records = null;
            state.payout_suborders_loading = false;
            state.payout_suborders_errors = {};
        },

        // audits
        setAudits(state, { payload }) {
            state.audits = payload;
        },
        setAuditsLoading(state, { payload }) {
            state.audits_loading = payload;
        },
        setAuditsErrors(state, { payload }) {
            state.audits_errors = payload;
        },
        resetAudits(state) {
            state.audits = [];
            state.audits_loading = false;
            state.audits_errors = {};
        },

        // comments
        setComments(state, { payload }) {
            state.comments = payload;
        },
        setCommentsLoading(state, { payload }) {
            state.comments_loading = payload;
        },
        setCommentsErrors(state, { payload }) {
            state.comments_errors = payload;
        },
        resetComments(state) {
            state.comments = [];
            state.comments_loading = {};
        },

        // receipts
        setReceipts(state, { payload }) {
            state.receipts = payload;
        },
        setReceiptsLoading(state, { payload }) {
            state.receipts_loading = payload;
        },
        setReceiptsErrors(state, { payload }) {
            state.receipts_errors = payload;
        },
        setReceiptUploading(state, { payload }) {
            state.receipt_uploading = payload;
        },
        setReceiptErrors(state, { payload }) {
            state.receipt_errors = payload;
        },
        resetReceipts(state) {
            state.receipts = [];
            state.receipts_loading = false;
            state.receipt_uploading = false;
            state.receipt_errors = {};
        },
    },
});

export const {
    // payouts
    setPayouts,
    setPayoutsTotalPages,
    setPayoutsRecords,
    setLoading,

    // payout
    setPayout,
    setPayoutLoading,
    setPayoutErrors,

    // change payout
    setChangePayoutLoading,
    setChangePayoutErrors,
    resetChangePayout,

    // bulk payment
    setBulkPaymentLoading,
    setBulkPaymentErrors,
    resetBulkPayment,

    // bulk payment with CSV
    setBulkPaymentWithCSVLoading,
    setBulkPaymentWithCSVErrors,

    // payout suborders
    setPayoutSuborders,
    setPayoutSubordersPages,
    setPayoutSubordersRecords,
    setPayoutSubordersLoading,
    setPayoutSubordersErrors,
    resetPayoutSuborders,

    // audits
    setAudits,
    setAuditsLoading,
    setAuditsErrors,
    resetAudits,

    // comments
    setComments,
    setCommentsLoading,
    setCommentsErrors,
    resetComments,

    // receipts
    setReceipts,
    setReceiptsLoading,
    setReceiptsErrors,
    setReceiptUploading,
    setReceiptErrors,
    resetReceipts,
} = PayoutsSlice.actions;

export const loadPayoutsData =
    (params = {}) =>
    async (dispatch) => {
        dispatch(setLoading(true));

        const res = await GET({
            endpoint: `/vendors/vendor-payouts?${stringify(params, {
                skipNull: true,
                skipEmptyString: true,
                arrayFormat: 'comma',
            })}`,
        });

        dispatch(setPayouts(res?.data || []));

        if (res?.meta) {
            dispatch(setPayoutsTotalPages(res?.meta?.last_page || 0));
            dispatch(
                setPayoutsRecords({
                    from: res?.meta?.from,
                    to: res?.meta?.to,
                    total: res?.meta?.total,
                }),
            );
        }

        dispatch(setLoading(false));
    };

export const loadPayoutData = (id) => async (dispatch) => {
    dispatch(setPayoutLoading(true));
    dispatch(setPayoutErrors({}));

    const res = await GET({
        endpoint: `/vendors/vendor-payouts/${id}`,
    });

    if (res.success) {
        dispatch(setPayout(res?.data || []));
    } else {
        const errors = errorMessages(res);
        dispatch(setPayoutErrors(errors));
    }

    dispatch(setPayoutLoading(false));
};

export const changePayout =
    ({ id, is_full_payment, amount, receipts }) =>
    async (dispatch) => {
        dispatch(setChangePayoutLoading(true));
        dispatch(setChangePayoutErrors({}));

        const data = new FormData();

        data.append('_method', 'put');

        data.append('is_full_payment', is_full_payment);

        if (!is_full_payment) {
            data.append('amount', amount);
        }

        if (receipts?.length) {
            receipts.forEach((r, i) => {
                data.append('receipts[' + i + ']', r);
            });
        }

        const res = await POST({
            endpoint: `/vendors/vendor-payouts/${id}`,
            data,
        });

        dispatch(setChangePayoutLoading(false));

        if (res?.success) {
            toast.success('Payout paid successfully');
            return true;
        } else {
            const errors = errorMessages(res);

            dispatch(setChangePayoutErrors(errors));

            return false;
        }
    };

export const bulkPayoutsPayment =
    ({ payouts, receipts }) =>
    async (dispatch) => {
        dispatch(setBulkPaymentLoading(true));
        dispatch(setBulkPaymentErrors({}));

        const data = new FormData();

        data.append('_method', 'put');

        if (payouts.length) {
            payouts.forEach((p, i) => {
                data.append('payouts[' + i + ']', p);
            });
        }

        if (receipts?.length) {
            receipts.forEach((r, i) => {
                data.append('receipts[' + i + ']', r);
            });
        }

        const res = await POST({
            endpoint: '/vendors/vendor-payouts/mark-paid',
            data,
        });

        dispatch(setBulkPaymentLoading(false));

        if (res?.success) {
            toast.success('Bulk payouts were paid successfully');
            return true;
        } else {
            const errors = errorMessages(res);

            dispatch(setBulkPaymentErrors(errors));

            return false;
        }
    };

export const bulkPaymentWithCSVRequest =
    ({ payouts_file, receipts }) =>
    async (dispatch) => {
        dispatch(setBulkPaymentWithCSVLoading(true));
        dispatch(setBulkPaymentWithCSVErrors({}));

        const data = new FormData();

        data.append('payouts_file', payouts_file);

        if (receipts?.length) {
            receipts.forEach((r, i) => {
                data.append('receipts[' + i + ']', r);
            });
        }

        const res = await POST({
            endpoint: '/vendors/vendor-payouts/csv-bulk-pay',
            data,
        });

        dispatch(setBulkPaymentWithCSVLoading(false));

        if (res?.success) {
            toast.success('Bulk payouts were paid successfully');
            return true;
        } else {
            const errors = errorMessages(res);

            dispatch(setBulkPaymentWithCSVErrors(errors));

            return false;
        }
    };

export const loadPayoutSuborders =
    (payout_id, params = {}) =>
    async (dispatch) => {
        dispatch(setPayoutSubordersLoading(true));
        dispatch(setPayoutSubordersErrors({}));

        const res = await GET({
            endpoint: `/vendors/vendor-payouts/${payout_id}/payout-suborders?${stringify(params, {
                skipNull: true,
                skipEmptyString: true,
                arrayFormat: 'comma',
            })}`,
        });

        if (res.success) {
            dispatch(setPayoutSuborders(res?.data || []));
        } else {
            const errors = errorMessages(res);
            dispatch(setPayoutSubordersErrors(errors));
        }

        if (res?.meta) {
            dispatch(setPayoutSubordersPages(res?.meta?.last_page || 0));
            dispatch(
                setPayoutSubordersRecords({
                    from: res?.meta?.from,
                    to: res?.meta?.to,
                    total: res?.meta?.total,
                }),
            );
        }

        dispatch(setPayoutSubordersLoading(false));
    };

export const loadPayoutAudits = (payout_id) => async (dispatch) => {
    dispatch(setAuditsLoading(true));
    dispatch(setAuditsErrors({}));

    const res = await GET({
        endpoint: `/vendors/vendor-payouts/${payout_id}/audits`,
    });

    if (res.success) {
        dispatch(setAudits(res?.data?.audits || []));
    } else {
        const errors = errorMessages(res);
        dispatch(setAuditsErrors(errors));
    }

    dispatch(setAuditsLoading(false));
};

export const loadPayoutComments = (payout_id) => async (dispatch) => {
    dispatch(setCommentsLoading(true));
    dispatch(setCommentsErrors({}));

    const res = await GET({
        endpoint: `/vendors/vendor-payouts/${payout_id}/comments`,
    });

    if (res.success) {
        dispatch(setComments(res?.data || []));
    } else {
        const errors = errorMessages(res);
        dispatch(setCommentsErrors(errors));
    }

    dispatch(setCommentsLoading(false));
};

export const createPayoutComment = (payout_id, comment, uuid) => async (dispatch) => {
    const res = await POST({
        endpoint: `/vendors/vendor-payouts/${payout_id}/comments`,
        data: {
            comment,
        },
        headers: {
            'Idempotency-Key': uuid,
        },
    });

    if (res?.success) {
        await dispatch(loadPayoutComments(payout_id));
    }
};

export const loadPayoutReceipts = (payout_id) => async (dispatch) => {
    dispatch(setReceiptsLoading(true));
    dispatch(setReceiptsErrors({}));

    const res = await GET({
        endpoint: `/vendors/vendor-payouts/${payout_id}/receipts`,
    });

    if (res.success) {
        dispatch(setReceipts(res?.data || []));
    } else {
        const errors = errorMessages(res);
        dispatch(setReceiptsErrors(errors));
    }

    dispatch(setReceiptsLoading(false));
};

export const uploadPayoutReceipt = (payout_id, receipts) => async (dispatch) => {
    dispatch(setReceiptUploading(true));
    dispatch(setReceiptErrors({}));

    const data = new FormData();

    if (receipts?.length) {
        receipts.forEach((r, i) => {
            data.append('receipts[' + i + ']', r);
        });
    }

    const res = await POST({
        endpoint: `/vendors/vendor-payouts/${payout_id}/receipts`,
        data,
    });

    dispatch(setReceiptUploading(false));

    if (res?.success) {
        toast.success('Receipt uploaded successfully');
        dispatch(loadPayoutReceipts(payout_id));
        return true;
    } else {
        const errors = errorMessages(res);

        dispatch(setReceiptErrors(errors));

        return false;
    }
};

export default PayoutsSlice.reducer;
