import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import OrdersListView from './OrdersListView';
import OrdersFormView from './OrdersFormView';
import { OrdersSingleView } from './OrdersSignleView';
import { Helmet } from 'react-helmet';

export default function OrdersModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/orders/list">
                <Helmet>
                    <title>Orders | Disty Admin Dashboard</title>
                </Helmet>
                <OrdersListView />
            </ProtectedRoute> 
            <ProtectedRoute exact path="/orders/add">
                <Helmet>
                    <title>Create Order | Disty Admin Dashboard</title>
                </Helmet>
                <OrdersFormView />
            </ProtectedRoute> 
            <ProtectedRoute exact path="/orders/:id/:child_id?">
                <Helmet>
                    <title>Order | Disty Admin Dashboard</title>
                </Helmet>
                <OrdersSingleView />
            </ProtectedRoute> 
        </Switch>
    );
}
