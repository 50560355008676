import React from 'react';
import { Helmet } from 'react-helmet';

import CustomersModuleRoutes from './views/Customers';
import OrdersModuleRoutes from './views/Orders';
import ChildOrdersModuleRoutes from './views/ChildOrders';
import WarehouseModuleRoutes from './views/Warehouse';
import VendorsModuleRoutes from './views/Merchants';
import VendorCategoriesModuleRoutes from './views/MerchantCategories';
import VendorUsersModuleRoutes from './views/MerchantUsers';
import LiveCartsModuleRoutes from './views/LiveCarts';
import VendorsLeadsModuleRoutes from './views/MerchantsLeads';
import ProductsModuleRoutes from './views/Products';
import CategoriesModuleRoutes from './views/Categories';
import AdminsModuleRoutes from './views/Admins';
import UsersGroupsModuleRoutes from './views/UsersGroups';
import ConfigsModuleRoutes from './views/Configs';
import CollectionsModuleRoutes from './views/Collections';
import BrandsModuleRoutes from './views/Brands';
import BannersModuleRoutes from './views/Banners';
import DiscountsModuleRoutes from './views/Discounts';
import PushNotificationsModuleRoutes from './views/PushNotifications';
import ReferralsModuleRoutes from './views/Referrals';
import LogsModuleRoutes from './views/Logs';
import AuthModuleRoutes from './views/Auth';
import PoInvoicesModuleRoutes from './views/Invoices/PurchaseOrderInvoices';
import SelfInvoicesModuleRoutes from './views/Invoices/SelfInvoices';
import VirtualIBANTransactionsRoutes from './views/VirtualIBANTransactions';
import SendNotificationsRoutes from './views/SendNotifications';
import StatsModuleRoutes from './views/Stats';
import VendorAcquisitionsModuleRoutes from './views/MerchantsAcquisitions';
import OrderMapModuleRoutes from './views/OrdersMap';
import ForceUpdateModuleRoutes from './views/ForceUpdate';
import RatingModuleRoutes from './views/RatedOrders';
import AppInstructionsModuleRoutes from './views/AppInstructions';
import InvoiceFinancingOrders from './views/InvoiceFinancingOrders';
import RequestedProducts from './views/RequestedProducts';
import RequestedVendors from './views/RequestedVendors';
import AppFeedbackModuleRoutes from './views/AppFeedback';
import GenericViews from './views/GenericViews';
import VendorRequestsRoutes from './views/MerchantRequests';
import StaticViews from './views/StaticViews';
import WalletWithdrawalRequests from './views/WalletWithdrawalRequests';
import OnboardingScreens from './views/OnboardingScreens';
import VerificationRecordsModuleRoutes from './views/VerificationRecords';
import VendorProductRequestsRoutes from './views/MerchantProductRequests';
import VendorPayoutsRoutes from './views/MerchantPayouts';
import ReGenerateFinancialDocuments from './views/ReGenerateFinancialDocuments';
import RetailersUsersModuleRoutes from './views/RetailersUsers';
import MergingAccounts from './views/MergingAccounts';
import TopicMapping from './views/HelpMapping';
import WireTransactionModuleRoutes from './views/WireTransaction';

const Routes = () => {
    return (
        <>
            <Helmet>
                <title>Disty Admin Dashboard</title>
            </Helmet>
            <MergingAccounts />
            <StatsModuleRoutes />
            <CustomersModuleRoutes />
            <OrdersModuleRoutes />
            <ChildOrdersModuleRoutes />
            <InvoiceFinancingOrders />
            <WarehouseModuleRoutes />
            <OrderMapModuleRoutes />
            <VendorsModuleRoutes />
            <VendorCategoriesModuleRoutes />
            <VendorUsersModuleRoutes />
            <LiveCartsModuleRoutes />
            <VendorsLeadsModuleRoutes />
            <WireTransactionModuleRoutes/>
            <ProductsModuleRoutes />
            <CategoriesModuleRoutes />
            <AdminsModuleRoutes />
            <UsersGroupsModuleRoutes />
            <ConfigsModuleRoutes />
            <ForceUpdateModuleRoutes />
            <CollectionsModuleRoutes />
            <BrandsModuleRoutes />
            <BannersModuleRoutes />
            <DiscountsModuleRoutes />
            <PushNotificationsModuleRoutes />
            <RatingModuleRoutes />
            <ReferralsModuleRoutes />
            <LogsModuleRoutes />
            <AuthModuleRoutes />
            <PoInvoicesModuleRoutes />
            <SelfInvoicesModuleRoutes />
            <VirtualIBANTransactionsRoutes />
            <SendNotificationsRoutes />
            <VendorAcquisitionsModuleRoutes />
            <AppInstructionsModuleRoutes />
            <RequestedProducts />
            <RequestedVendors />
            <AppFeedbackModuleRoutes />
            <GenericViews />
            <VendorRequestsRoutes />
            <StaticViews />
            <WalletWithdrawalRequests />
            <OnboardingScreens />
            <VerificationRecordsModuleRoutes />
            <VendorProductRequestsRoutes />
            <VendorPayoutsRoutes />
            <ReGenerateFinancialDocuments />
            <RetailersUsersModuleRoutes />
            <TopicMapping />
        </>
    );
};

export default Routes;
