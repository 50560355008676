import React from 'react';
import EditIcon from '@assets/icons/edit-icon.svg';

export default function EditBtn({ onClick }) {
    return (
        <div className="icon-btn edit-btn" onClick={onClick}>
            <img src={EditIcon} alt="" />
        </div>
    );
}
