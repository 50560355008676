import React from 'react';
import { useParams, useHistory } from 'react-router';
import { Card, Button, ViewHeader, Modal, ShowErrors, Select, DataTable, Link } from '@components';
import { formatDate, downloadFileFromAPI, GET, POST, DELETE } from '@utils';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { toast } from 'react-toastify';
import VerificationRecordComments from './VerificationRecordComments';

function RecordDetailView() {
    const { id } = useParams();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const [data, set_data] = React.useState({});
    const [loading, set_loading] = React.useState(false);
    const [action, set_action] = React.useState('');
    const [errors, set_errors] = React.useState([]);
    const [submitting, set_submitting] = React.useState(false);
    const [reason, set_reason] = React.useState({
        label: 'Select the reason',
        options: [],
        value: {},
        getOptionValue: (option) => option.id,
        onChange: (val) => set_reason((prev) => ({ ...prev, value: val })),
    });

    const getRecordData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/customers/verifications/${id}`,
        });
        set_loading(false);
        if (res?.status_code == 200) {
            set_data(res?.data);
            set_reason({
                ...reason,
                options: res?.data?.meta?.reasons?.map((x) => ({ id: x.code, label: x.reason })),
            });
        }
    };

    React.useEffect(() => {
        getRecordData();
    }, []);

    const columns = [
        { name: 'Shop Name', selector: 'store_name' },
        {
            name: 'Retailer Name',
            cell: (row) => (
                <h5>
                    {row?.first_name} {row?.last_name}
                </h5>
            ),
        },
        { name: 'Mobile', selector: 'mobile' },
        { name: 'CR Number', selector: 'commercial_registration_number' },
        { name: 'VAT Number', selector: 'customer_vat_number' },
        { name: 'Created Date', cell: (row) => <>{formatDate(row?.created_date)}</> },
        { name: 'Total Orders', selector: 'total_orders' },
    ];

    const vendorDuplicateColumns = [
        { name: '', cell: ({ id }) => <>#{id}</> },
        { name: 'Legal company name', selector: 'legal_company_name_en' },
        { name: 'Legal company name (Arabic)', selector: 'legal_company_name_ar' },
        { name: 'Company name', selector: 'company_name_en' },
        { name: 'Company name (Arabic)', selector: 'company_name_ar' },
        { name: 'commercial_registration_number', selector: 'commercial_registration_number' },
        { name: 'vat_account_number', selector: 'vat_account_number' },
        { name: 'mobile', selector: 'mobile' },
    ];

    const handleVerified = async (closeModal) => {
        set_submitting(true);
        const res = await POST({ endpoint: `/customers/verifications/${id}` });
        set_submitting(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_errors([]);
            getRecordData();
            closeModal('confirm_verification_record__modal');
            toast.success('Verification Accepted Successfully');
        } else {
            set_errors(res?.data.errors || { message: [res?.data.message] } || {});
        }
    };

    const handleRejected = async (closeModal) => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/customers/verifications/${id}`,
            data: { reason: reason?.value?.id },
        });
        set_submitting(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_errors([]);
            getRecordData();
            closeModal('rejected_verification_reason__modal');
            toast.success('Verification Rejected Successfully');
        } else {
            set_errors(res?.data.errors || { message: [res?.data.message] } || {});
        }
    };

    const onConfirmationSubmit = (closeModal) => {
        if (action === 'verified') {
            handleVerified(closeModal);
        } else {
            closeModal('confirm_verification_record__modal');
            dispatch(
                setModalStatus({
                    modal: 'rejected_verification_reason__modal',
                    status: true,
                }),
            );
        }
    };
    if (loading) return <div>loading...</div>;
    return (
        <div>
            <ViewHeader title="Verification Record" renderBackArrow>
                <div>
                    <small>Created at</small>
                    <br />
                    <span>{formatDate(data.created_at)}</span>
                </div>
                <Link href={`/customers/${data?.customer_id}`}>
                    <Button variant="warning">view customer details</Button>
                </Link>
            </ViewHeader>
            <Card>
                <div style={{ marginBottom: 0 }} className="vendor-info">
                    <div className="details vendor-details-list">
                        <ul>
                            <li>
                                <span>Retailer ID</span>
                                <p>{data?.customer_id}</p>
                            </li>
                            <li>
                                <span>Shop Name</span>
                                <p>{data?.store_name}</p>
                            </li>
                            <li>
                                <span>Legal name</span>
                                <p>{data?.legal_name}</p>
                            </li>
                            <li>
                                <span>Retailer Mobile</span>
                                <p>{data?.mobile}</p>
                            </li>
                            <li>
                                <span>State</span>
                                <p>{data?.state?.description}</p>
                            </li>
                            {data?.reason && (
                                <li>
                                    <span>Rejected reason</span>
                                    <p>{data?.reason?.reason}</p>
                                </li>
                            )}
                            <li>
                                <span>Created at</span>
                                <p>{formatDate(data?.created_at)}</p>
                            </li>
                            <li>
                                <span>Updated at</span>
                                <p>{formatDate(data?.updated_at)}</p>
                            </li>
                            <li>
                                <span> Commercial registration number</span>
                                <p>{data.commercial_registration_number}</p>
                            </li>
                            {data?.cr_certificate && (
                                <li>
                                    <span>CR certificate</span>
                                    <p>
                                        <Button
                                            variant="link"
                                            onClick={() => {
                                                downloadFileFromAPI(
                                                    data?.cr_certificate,
                                                    'cr_certificate',
                                                );
                                            }}
                                        >
                                            download attachment
                                        </Button>
                                    </p>
                                </li>
                            )}
                            <li>
                                <span> Has tax number</span>
                                <p>{data.has_tax_number ? 'Yes' : 'No'}</p>
                            </li>
                            {data?.customer_vat_number && (
                                <li>
                                    <span>Retailer vat number </span>
                                    <p>{data?.customer_vat_number}</p>
                                </li>
                            )}
                            {data?.vat_certificate && (
                                <li>
                                    <span>VAT certificate</span>
                                    <p>
                                        <Button
                                            variant="link"
                                            onClick={() => {
                                                downloadFileFromAPI(
                                                    data?.vat_certificate,
                                                    'vat_certificate',
                                                );
                                            }}
                                        >
                                            download attachment
                                        </Button>
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="px-3 py-2">
                    <div className="flex__ai-center__jc-end">
                        <Button
                            style={{ borderRadius: '6px', width: '160px', height: '40px' }}
                            variant="primary"
                            disabled={data?.state?.code === 'accepted'}
                            onClick={() => {
                                set_errors([]);
                                set_action('verified');
                                dispatch(
                                    setModalStatus({
                                        modal: 'confirm_verification_record__modal',
                                        status: true,
                                    }),
                                );
                            }}
                        >
                            Verify Retailer
                        </Button>
                        <Button
                            variant="outline-danger"
                            disabled={data?.state?.code === 'rejected'}
                            onClick={() => {
                                set_errors([]);
                                set_action('rejected');
                                dispatch(
                                    setModalStatus({
                                        modal: 'confirm_verification_record__modal',
                                        status: true,
                                    }),
                                );
                            }}
                        >
                            Reject Retailer
                        </Button>
                    </div>
                </div>
                <div className="px-3 py-2">
                    <h3 className="divider-title"> Possible Duplication </h3>
                    {data?.duplicate_customers?.length ? (
                        <DataTable
                            onRowClicked={({ id }) => push(`/customers/${id}`)}
                            data={data?.duplicate_customers}
                            columns={columns}
                            loading={loading}
                            searchable={false}
                        />
                    ) : (
                        <h2 className="flex__ai-center__jc-center">
                            There is no duplication detected
                        </h2>
                    )}
                </div>
                <div className="px-3 py-2">
                    <h3 className="divider-title">Possible Merchant Duplication </h3>
                    {data?.duplicate_vendors?.length ? (
                        <DataTable
                            onRowClicked={({ id }) => push(`/merchants/${id}`)}
                            data={data?.duplicate_vendors}
                            columns={vendorDuplicateColumns}
                            loading={loading}
                            searchable={false}
                        />
                    ) : (
                        <h2 className="flex__ai-center__jc-center">
                            There is no merchant duplication detected
                        </h2>
                    )}
                </div>
            </Card>

            <VerificationRecordComments />

            <Modal
                title={
                    action === 'verified'
                        ? 'Are you sure you want to Verify this customer?'
                        : 'Are you sure you want to Reject this customer?'
                }
                name="confirm_verification_record__modal"
                variant={action === 'verified' ? 'primary' : 'warning'}
                onSubmit={(closeModal) => onConfirmationSubmit(closeModal)}
                loading={submitting}
            >
                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            </Modal>
            <Modal
                title="please select the reason of Rejection!!"
                name="rejected_verification_reason__modal"
                onSubmit={(closeModal) => handleRejected(closeModal)}
                loading={submitting}
                variant="danger"
            >
                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
                <Select {...reason} />
            </Modal>
        </div>
    );
}

export default RecordDetailView;
