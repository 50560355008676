import React from 'react';
import whatsappLogo from '@assets/icons/whatsapp.svg';

function WhatsApp({ number }) {
    if(!number) return 'no number exist';

    let validate_number;

    validate_number = number.startsWith('+') ? number.slice(1) : number;
    validate_number = validate_number.startsWith('966') ?
        validate_number : `966${validate_number}`;


    return (
        <a target="_blank" rel="noreferrer" href={`https://wa.me/${validate_number}`}>
            <img src={whatsappLogo} alt='whatsapp' width='30px' height='30px' />
        </a>
    );
}

export default WhatsApp;