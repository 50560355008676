export const filterInactiveCells = (grid_data) => {
    return grid_data
        .map((row) => {
            row.locations = row.locations.filter((cell) => cell.is_active || cell.is_allocated);
            return row.locations.length ? row : null;
        })
        .filter((row) => !!row);
};

export const getGridCols = (grid_data) => {
    const biggest_lane = grid_data?.reduce((prev, curr) => {
        if (curr.locations.length > (prev?.locations?.length || 0)) {
            return curr;
        }

        return prev;
    }, {});

    return biggest_lane?.locations?.map((l) => l.shelf) || [];
};

export const stripParentOrderId = (pretty_id) => {
    return pretty_id.split('-')[1] || '';
};

export const isAllSubordersReadyToDispatchOrAfter = (suborders = []) => {
    if (!suborders?.length) return false;
    return suborders.every((s) => [256, 512, 1024, 4, 8, 2048, 8192].includes(s.state.code));
};

export const checkIfCityMatch = (shipping_address, cities) => {
    if (!cities?.length) return false;
    if (cities?.indexOf(String(shipping_address?.city)) > -1) return true;
    return false;
};

export const checkIfRegionMatch = (shipping_address, regions) => {
    if (!regions?.length) return false;
    if (regions?.indexOf(String(shipping_address?.region?.name)) > -1) return true;
    return false;
};
