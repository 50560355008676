import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const RatingSlice = createSlice({
    name: 'ratedOrders',
    initialState: {
        ratedOrders: [],
        ratedOrders_pages: null,
        ratedOrders_records: null,
        ratedOrders_loading: true,
    },
    reducers: {
        setRatedOrders(state, { payload }) {
            state.ratedOrders = payload.sort((a,b) => b.id - a.id);
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setRatedOrdersPages(state, { payload }) {
            state.ratedOrders_pages = payload;
        },
        setRatedOrdersRecords(state, { payload }) {
            state.ratedOrders_records = payload;
        },
    },
});

// export actions
export const {
    setRatedOrders,
    setLoading,
    setRatedOrdersPages,
    setRatedOrdersRecords,
} = RatingSlice.actions;

export const loadRatedOrdersData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'ratedOrders_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/order/rated?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`
    });
    dispatch(setRatedOrders(res?.data || []));
    if(res?.meta){
        dispatch(setRatedOrdersPages(res?.meta?.last_page || 1));
        dispatch(setRatedOrdersRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'ratedOrders_loading',
            value: false,
        })
    );
};

export default RatingSlice.reducer;