import React from 'react';
import PropTypes from 'prop-types';
import { Editor as E } from 'react-draft-wysiwyg';
import { Form } from '@components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function Editor({ value, setValue, label = '', required = false, tooltip = '' }) {
    const onEditorStateChange = (editorState) => setValue(editorState);

    return (
        <Form.Group controlId={label} className={value?.getCurrentContent?.()?.hasText() 
            ? 'show-tooltip' : ''}>
            {label && <Form.Label>{label}</Form.Label>}
            {required && <span> *</span>}
            <E
                editorState={value}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-area"
                onEditorStateChange={onEditorStateChange}
            />
            {tooltip && <div className="tooltip">{tooltip}</div>}
        </Form.Group>
    );
}

Editor.propTypes = {
    value: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool
};
