import React from 'react';
import { DataTable, Link, WhatsApp, Dialer } from '@components';
import { GET, formatDate, formatMoney } from '@utils';
import { stringify } from 'query-string';

function FollowingCustomersTab({ id }) {
    const [following_customers, set_following_customers] = React.useState([]);
    const [meta_pages, set_meta_pages] = React.useState(0);
    const [page, set_page] = React.useState(1);
    const [loading, set_loading] = React.useState(true);
    const fetchData = async () => {
        const res = await GET({
            endpoint: `/vendors/${id}/customers?${stringify(
                { page },
                {
                    skipNull: true,
                    skipEmptyString: true,
                },
            )}`,
        });
        set_loading(false);
        if (res.status_code === 200) {
            set_following_customers(res?.data || []);
            set_meta_pages(res?.meta?.last_page || 0);
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            center: true,
            width: '100px',
            cell: (row) => (
                <Link href={`/customers/${row.id}`} className="tr-name">
                    {row.id}
                </Link>
            ),
        },
        {
            name: 'Retailer Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/customers/${row.id}`} className="tr-name">
                    <h5>
                        {row.first_name} {row.last_name}
                        <small>
                            {row.store_name} | {row.state}
                        </small>
                    </h5>
                </Link>
            ),
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            sortable: false,
            width: '140px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            sortable: false,
            cell: (row) => <WhatsApp number={row?.mobile} />,
        },
        {
            name: 'Cities',
            selector: 'city',
            sortable: false,
            cell: (row) => (
                <>
                    {row?.cities
                        ?.filter((c) => !!c)
                        ?.map((c) => c?.name_ar)
                        .join(',')}
                </>
            ),
        },
        {
            name: 'Created date',
            selector: 'created_date',
            sortable: false,
            cell: (row) => <>{formatDate(row.created_date)}</>,
        },
        {
            name: 'Following',
            selector: 'follows_count',
            sortable: false,
            center: true,
            cell: (row) => (
                <Link href={`/customers/${row.id}`} className="tr-name">
                    {row?.follows_count}
                </Link>
            ),
        },
        {
            name: 'Total orders',
            selector: 'total_orders',
            sortable: false,
            center: true,
        },
        {
            name: 'Total sales',
            selector: 'total_spent',
            sortable: false,
            center: true,
            cell: (row) => formatMoney(row.total_spent),
        },
        {
            name: 'Total live cart',
            selector: 'total_live_cart',
            center: true,
            cell: (row) => formatMoney(row.total_live_cart),
        },
        {
            name: 'Monthly target',
            selector: 'monthly_target',
            cell: (row) => <span>{formatMoney(row.monthly_target)}</span>,
        },
        {
            name: 'This month achieved',
            selector: 'month_achieved',
            cell: (row) => (
                <span>
                    {formatMoney(row.month_achieved)}
                    &nbsp; - &nbsp;
                    {row.achieved_percentage ? +row.achieved_percentage?.toFixed(2) : 0}%
                </span>
            ),
        },
        {
            name: 'Balance',
            selector: 'balance',
            center: true,
            cell: (row) => formatMoney(row.balance),
        },
        {
            name: 'Life time',
            selector: 'life_time',
        },
        {
            name: 'Account manager',
            selector: 'account_manager',
            cell: (row) => (
                <span>
                    {row?.account_manager
                        ? `${row?.account_manager?.first_name} ${row?.account_manager?.last_name}`
                        : ''}
                </span>
            ),
        },
        {
            name: 'Acquisition manager',
            selector: 'acquisition_user',
            width: '80px',
            cell: (row) =>
                row?.acquisition_user
                    ? `${row?.acquisition_user?.first_name} ${row?.acquisition_user?.last_name}`
                    : '',
        },
    ];
    return (
        <DataTable
            data={following_customers}
            pages={meta_pages}
            page={page}
            handlePageChange={set_page}
            columns={columns}
            loading={loading}
        />
    );
}

export default FollowingCustomersTab;
