import React from 'react';
import { Checkbox, Modal, Input } from '@components';

export default function OrderStateModal({ suborderModal, onSubmit }) {
    const [selected_reasons, set_selected_reasons] = React.useState({
        key: null,
        has_sub_reason: false,
        sub_key: null,
        other_details: '',
    });

    const extractPrettyId = (t) => t?.pretty_id || t?.id?.toString();
    const isValidReason = () => {
        return selected_reasons?.has_sub_reason
            ? selected_reasons?.sub_key && selected_reasons?.key
            : selected_reasons?.key !== null;
    };
    return (
        <Modal
            title={
                suborderModal?.state === 2048 || !suborderModal?.state
                    ? `Select cancellation reason of order/suborder ${extractPrettyId(
                        suborderModal
                    )}?`
                    : `${suborderModal?.title} suborder ${extractPrettyId(
                        suborderModal
                    )}?`
            }
            name="single_order_view__suborder_modal"
            variant={suborderModal?.title === 'Cancel by admin' ? 'danger' : 'info'}
            onSubmit={(closeModal) => onSubmit(closeModal, selected_reasons)}
            onClose={() =>
                set_selected_reasons({
                    ...selected_reasons,
                    key: null,
                    sub_key: null,
                    has_sub_reason: false,
                    other_details: '',
                })
            }
            submitBtnDisabled={
                suborderModal?.reasons?.length ? !isValidReason() : false
            }
        >
            <div className="flex-column">
                {!!suborderModal?.reasons?.length &&
          suborderModal?.reasons?.map((reason) => (
              <div key={reason?.code}>
                  <Checkbox
                      key={reason.code}
                      label={reason.reason}
                      onChange={(val) => {
                          set_selected_reasons({
                              ...selected_reasons,
                              key: val ? reason?.code : null,
                              has_sub_reason: reason?.sub_reasons?.length ? true : false,
                              sub_key: null,
                              other_details: '',
                          });
                      }}
                      checked={selected_reasons?.key === reason?.code}
                  />
                  <div className="flex-column pl-4">
                      {!!reason?.sub_reasons?.length &&
                  selected_reasons?.key === reason?.code &&
                  reason?.sub_reasons?.map((sub) => (
                      <div key={sub?.code}>
                          <Checkbox
                              key={sub.code}
                              label={sub.reason}
                              onChange={(val) => {
                                  set_selected_reasons({
                                      ...selected_reasons,
                                      sub_key: val ? sub?.code : null,
                                      other_details: '',
                                  });
                              }}
                              checked={selected_reasons?.sub_key === sub?.code}
                          />
                          <div className="flex-column pl-4">
                              {sub?.code === 'other' &&
                          selected_reasons?.sub_key === sub?.code && (
                                  <Input
                                      label="Other Cancellation Reason"
                                      value={selected_reasons?.other_details}
                                      setValue={(e) =>
                                          set_selected_reasons({
                                              ...selected_reasons,
                                              other_details: e,
                                          })
                                      }
                                  ></Input>
                              )}
                          </div>
                      </div>
                  ))}
                      {reason?.code === 'other' &&
                  selected_reasons?.key === reason?.code && (
                          <Input
                              label="Other Cancellation Reason"
                              value={selected_reasons?.other_details}
                              setValue={(e) =>
                                  set_selected_reasons({
                                      ...selected_reasons,
                                      other_details: e,
                                  })
                              }
                          ></Input>
                      )}
                  </div>
              </div>
          ))}
            </div>
        </Modal>
    );
}
