import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const InvoiceFinancingOrders = createSlice({
    name: 'vendors-orders',
    initialState: {
        vendors_orders: [],
        vendors_orders_pages: null,
        vendors_orders_records: null,
        vendors_orders_loading: true,
        vendors_order_actions_loading: true,
    },
    reducers: {
        set_vendors_orders(state, { payload }) {
            state.vendors_orders = payload;
        },
        update_single_vendors_order_from_list(state, { payload }) {
            state.vendors_orders = state.vendors_orders.map(order => {
                if (order.id === payload.id) {
                    return {
                        ...order,
                        ...payload,
                    };
                }
                return order;
            });
        },
        set_vendors_orders_pages(state, { payload }) {
            state.vendors_orders_pages = payload;
        },
        set_vendors_orders_records(state, { payload }) {
            state.vendors_orders_records = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },

    },
});

// export actions
export const {
    set_vendors_orders,
    update_single_vendors_order_from_list,
    set_vendors_orders_pages,
    set_vendors_orders_records,
    setLoading
} = InvoiceFinancingOrders.actions;

export const loadInvoiceFinancingOrdersData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendors_orders_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/suborder?sources=vendor&${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(set_vendors_orders(res?.data || []));
    dispatch(set_vendors_orders_pages(res?.meta?.last_page || 0));
    dispatch(set_vendors_orders_records({
        from: res?.meta?.from,
        to: res?.meta?.to,
        total: res?.meta?.total,
    }));
    dispatch(
        setLoading({
            key: 'vendors_orders_loading',
            value: false,
        })
    );
};


export default InvoiceFinancingOrders.reducer;
