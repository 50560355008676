import React from 'react';

const PercentageIndicator = ({ percentage, count = null }) => {
    let backgroundColor;

    if ((percentage >= 0 && percentage <= 25) || percentage === 'below_25_percent') {
        backgroundColor = 'red';
    } else if (
        (percentage > 25 && percentage <= 50) ||
        percentage === 'between_25_and_50_percent'
    ) {
        backgroundColor = 'orange';
    } else if (
        (percentage > 50 && percentage <= 75) ||
        percentage === 'between_50_and_75_percent'
    ) {
        backgroundColor = 'yellow';
    } else if ((percentage > 75 && percentage <= 100) || percentage === 'above_75_percent') {
        backgroundColor = 'green';
    }
    return (
        <div
            className="percentage-indicator"
            style={{
                display: 'inline-block',
                padding: count ? '20px 15px' : '5px 20px',
                color: '#fff',
                fontWeight: 'bold',
                borderRadius: count ? 0 : 5,
                background: backgroundColor,
            }}
        >
            {count && count}
        </div>
    );
};

export default PercentageIndicator;
