import React from 'react';
import { GET, POST } from '@utils';
import { stringify } from 'query-string';
import { Button, Input, Row, Col, ShowErrors, Select } from '@components';
import { toast } from 'react-toastify';

function BankDetailsTab({ id }) {
    const [loading, set_loading] = React.useState(true);
    const [submitting, set_submitting] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [beneficiary_full_name, set_beneficiary_full_name] = React.useState({
        label: 'Beneficiary full name',
        value: '',
        setValue: (val) => set_beneficiary_full_name({ ...beneficiary_full_name, value: val }),
    });
    const [iban, set_iban] = React.useState({
        label: 'IBAN',
        value: '',
        setValue: (val) => set_iban({ ...iban, value: val }),
    });
    const [bank_name, set_bank_name] = React.useState({
        label: 'Bank name',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_bank_name((prev) => ({ ...prev, value: val })),
    });

    const fetchBanks = async () => {
        const res = await GET({
            endpoint: '/payment/banks',
        });

        const banks = res?.data?.map(b => ({
            label: b.name_en,
            id: b.id,
        }));

        set_bank_name({
            ...bank_name,
            options: banks,
        });
    };

    const fetchData = async () => {
        set_loading(true);

        await fetchBanks();

        const res = await GET({
            endpoint: `/customers/${id}/bank-account?${stringify({
                skipNull: true,
                skipEmptyString: true
            })}`
        });
        set_loading(false);

        if (res.status_code === 200) {
            const bank = res?.data?.bank;

            beneficiary_full_name.setValue(res?.data?.beneficiary_full_name);
            iban.setValue(res?.data?.iban);
            if (bank) bank_name.onChange({ label: bank.name, id: bank.id });
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const onSubmit = async () => {
        set_errors([]);
        set_submitting(true);
        const data = {
            beneficiary_full_name: beneficiary_full_name.value,
            iban: iban.value,
            bank_id: bank_name?.value?.id,
            _method: 'PUT'
        };

        const res = await POST({
            endpoint: `/customers/${id}/bank-account`,
            data,
        });

        set_submitting(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            toast.success('updated successfully!');
        }
    };


    if (loading) return 'loading...';

    return (
        <div className='px-3'>
            {!!Object.keys(errors).length && (
                <ShowErrors errors={errors} />
            )}
            <Row className="justify-content-md-center">
                <Col md={6} className="bank-details-card">
                    <Input {...beneficiary_full_name} />
                    <Input {...iban} />
                    <Select {...bank_name} />
                    <Button disabled={submitting} onClick={onSubmit}>
                        Save changes
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default BankDetailsTab;