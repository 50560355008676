/* eslint-disable */
import React from 'react';
import { 
    Modal, 
    Radio, 
    Input, 
    Textarea, 
    Select, 
    ApiAsyncSelect,
    ShowErrors,
    DataTable,
    Button
} from '@components';
import { POST, GET, randomId, formatMoney } from '@utils';
import { 
    loadCustomerBalance,
} from '../../../store/slices/customers.slice';
import { useDispatch, useSelector } from 'react-redux';
import defaultLogo from '@assets/img/default.jpg';

export default function CreditModal({ id }) {
    const dispatch = useDispatch();
    const idempotencyKey = randomId();
    const customer_refund_reasons = useSelector((state) => 
        state.CustomersSlice.customer_refund_reasons);
    const [loading, set_loading] = React.useState(false);
    const [operation_type, set_operation_type] = React.useState(null);
    const [amount, set_amount] = React.useState({
        label: 'Amount',
        value: null,
        type: 'number',
        acceptDecimal: true,
        setValue: (val) => set_amount({ ...amount, value: val }),
    });
    const [description, set_description] = React.useState({
        label: 'Description',
        value: '',
        setValue: (val) => set_description({ ...description, value: val })
    });
    const [refund_reason_note, set_refund_reason_note] = React.useState({
        label: 'Other Reason (refund reason note)',
        value: '',
        setValue: (val) => set_refund_reason_note({ ...refund_reason_note, value: val })
    });
    const [selected_order, set_selected_order] = React.useState(null);
    const [selected_suborder, set_selected_suborder] = React.useState(null);
    const [refund_reasons, set_refund_reasons] = React.useState({
        label: 'Select the reason',
        options: [],
        value: [],
        getOptionValue: (option) => option.id,
        onChange: (val) => set_refund_reasons((prev) => ({ ...prev, value: val })),
    });
    const [given_amount_type, set_given_amount_type] = React.useState({
        label: 'Is the added amount refund or appeasement?',
        options: [
            { id: 'order_refund', label: 'Refund' },
            { id: 'appeasement', label: 'Appeasement' },
        ],
        value: [],
        getOptionValue: (option) => option.id,
        onChange: (val) => set_given_amount_type((prev) => ({ ...prev, value: val })),
    });
    const [deduct_from, set_deduct_from] = React.useState({
        label: 'Deducted from',
        options: [
            { id: 'disty_account', label: 'Disty account' },
            { id: 'vendor_account', label: 'merchant account' },
        ],
        value: [],
        getOptionValue: (option) => option.id,
        onChange: (val) => set_deduct_from((prev) => ({ ...prev, value: val })),
    });
    const [force_refund, set_force_refund] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [products_data, set_products_data] = React.useState([]);
    const [quantities, set_quantities] = React.useState({});
    const [expand_products_table, set_expand_products_table] = React.useState(true);

    React.useEffect(() => {
        set_refund_reasons({ 
            ...refund_reasons, 
            options: customer_refund_reasons
        });
    }, [customer_refund_reasons]);

    const fetchSuborderData = async () => {
        set_products_data([]);
        const res = await GET({
            endpoint: `/suborder/${selected_suborder.id}`,
        });
        if(res.status_code === 200){
            set_products_data(res?.data?.products?.map(p => ({
                id: p?.id,
                logo: p?.image_conversions?.thumb || p?.image,
                name: p?.name,
                price_after_discount: p?.price_after_discount,
                quantity: p?.quantity,
            })));
        }
    };

    const updateQuantities = (val, id) => {
        set_quantities((prev) => ({
            ...prev,
            [id]: {
                ...prev[id], value: val
            }
        }));
    };

	
    React.useEffect(() => {
        if(products_data.length){
            set_quantities({});
            let quantities_selectboxs = {};
            products_data?.map(p=> {
                quantities_selectboxs[p.id] = {
                    price_after_discount: p.price_after_discount,
                    options: [...Array(parseInt(p.quantity)).keys()].map(k => ({
                        id: k+1, label: k+1
                    })),
                    value: [],
                    getOptionValue: (option) => option.id,
                    onChange: (val) => updateQuantities(val, p.id)
                };
            });
            set_quantities(quantities_selectboxs);
        }
    }, [products_data]);
	
    React.useEffect(() => {
        if(
            operation_type === 'credit' &&
            given_amount_type?.value?.id === 'order_refund' &&
            selected_suborder?.id
        ){
            fetchSuborderData();
        }
    }, [given_amount_type, selected_suborder]);


    const onSubmit = async (close) => {
        set_loading(true);
        const data = {
            amount: amount.value,
            description: description.value,
            suborder_pretty_id: selected_suborder?.pretty_id,
        };
        if(operation_type === 'debit') data.order_id = selected_order?.id;
        if(operation_type === 'credit'){
            data.reason = given_amount_type.value?.id;
            data.refund_reason = refund_reasons.value?.id;
            data.deduct_from = deduct_from.value?.id;
            if(deduct_from.value.id === 'disty_account'){
                data.order_id = selected_order?.id;
            }
            if(refund_reasons.value.id == 1){
                data.refund_reason_note = refund_reason_note.value;
            }
        }
        if(force_refund){
            data.force_refund = 1;
        }

        const res = await POST({
            endpoint: `/payment/customers/${id}/wallet/charge?type=${operation_type}`,
            data,
            headers: {
                'Idempotency-Key': idempotencyKey,
            }
        });

        if(res?.data?.errors === 112){
            set_force_refund(true);
        }

        setErrors([]);
        set_loading(false);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if(res?.status === 400) {
            setErrors({
                'customer_balance': [res?.data?.message],
            });
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            close();
            resetData();
            dispatch(loadCustomerBalance(id));
            setErrors([]);
        }
    };

    const resetData = () => {
        set_force_refund(false);
        set_operation_type(null);
        set_selected_order(null);
        set_selected_suborder(null);
        set_amount((prev) => ({ ...prev, value: null }));
        set_description((prev) => ({ ...prev, value: '' }));
        set_refund_reason_note((prev) => ({ ...prev, value: '' }));
        set_deduct_from((prev) => ({ ...prev, value: [] }));
        set_refund_reasons((prev) => ({ ...prev, value: [] }));
        set_given_amount_type((prev) => ({ ...prev, value: [] }));
        set_products_data([]);
        set_expand_products_table(true);
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            center: true,
            maxWidth: '100px',
        },
        {
            name: 'Logo',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.logo || defaultLogo} alt="Logo" />
                </div>
            ),
        },
        {
            name: 'Product',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <div className="tr-name">
                    <h5>{row.name}</h5>
                    <h6 style={{ whiteSpace: 'nowrap' }}>
                        <small>CODE: {row.id}</small>
                    </h6>
                </div>
            ),
        },
        {
            name: 'Price after discount',
            selector: 'price_after_discount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.price_after_discount)}</div>
                </div>
            ),
        },
        {
            name: 'Affected quantity',
            selector: 'quantity',
            sortable: false,
            center: true,
            cell: (row) => (
                <Select 
                    price_after_discount={row?.price_after_discount}
                    value={quantities[row.id]?.value}
                    options={quantities[row.id]?.options}
                    getOptionValue={(option) => option?.id}
                    onChange={(val) => updateQuantities(val, row?.id)}
                />
            )
        },
    ];

    const calculateAmount = () =>{
        let total = 0;
        Object.keys(quantities).forEach(q => {
            if(quantities[q]?.value?.id){
                total += quantities[q]?.price_after_discount * quantities[q]?.value?.id
            }
        });
        amount.setValue(total.toFixed(2));
        set_expand_products_table(false);
    };
        
    return (
        <>
            <Modal
                size="xl"
                title="Add/Deduct Credit"
                className="credit-modal"
                name="single_customer_view__credit_modal"
                loading={loading}
                submitBtnDisabled={!operation_type || !amount?.value || 
					(operation_type === 'debit' && !description?.value)}
                onSubmit={onSubmit}
                onClose={resetData}
            >
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
                        
                {force_refund ? (
                    <h3>You already add the same amount for the same order number, 
                    are you sure you want to proceed?</h3>
                ) : (
                    <>
                        <div className="flex__jc-around_ai-center">
                            <Radio 
                                onChange={val => set_operation_type(val)} 
                                option={operation_type} 
                                label="Add money to customer's wallet" 
                                value="credit" 
                                name="operation-type" 
                            />
                            <Radio
                                onChange={val => set_operation_type(val)}
                                option={operation_type}
                                label="Deduct money from customer's wallet"
                                value="debit"
                                name="operation-type"
                            />
                        </div>
                        <Input {...amount} />
                        {operation_type === 'credit' && (
                            <>
                                <Select {...refund_reasons} />
                                {refund_reasons?.value?.id == 1 && (
                                    <Input {...refund_reason_note} />
                                )}
                                <Select {...given_amount_type} />
                                <Select {...deduct_from} />
                            </>
                        )}
                        {operation_type === 'debit' || 
                            (operation_type === 'credit' && 
                            deduct_from.value.id === 'disty_account') && (
                                <ApiAsyncSelect
                                    label="Order ID"
                                    api="/order"
                                    params={{ customers: id }}
                                    onlyFetchActiveRecords={false}
                                    placeholder="Search by order Id..."
                                    labelProperty={(r) => r.id}
                                    onChange={set_selected_order}
                                    value={selected_order}
                                />
                            )}
                        <ApiAsyncSelect
                            label="Suborder ID"
                            api="/suborder"
                            params={{ customers: id }}
                            onlyFetchActiveRecords={false}
                            placeholder="Search by suborder Id..."
                            labelProperty={(r) => r.pretty_id}
                            onChange={set_selected_suborder}
                            value={selected_suborder}
                        />
                        {expand_products_table && (
                            <>
                                {
                                    operation_type === 'credit' &&
									given_amount_type?.value?.id === 'order_refund' &&
									selected_suborder?.id && (
                                        <>
                                            <h3>Products in order {selected_suborder?.label}</h3>
                                            <DataTable 
                                                columns={columns}
                                                data={products_data}
                                            />
                                            <div className="flex__jc-end">
                                                <Button 
                                                    variant="primary" 
                                                    onClick={calculateAmount}
                                                >
													Confirm affected quantities
                                                </Button>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )}
                        {!expand_products_table && products_data.length > 0 && (
                            <Button 
                                variant="secondary" 
                                className="mb-3"
                                onClick={() => set_expand_products_table(true)}
                            >
                            Expand products table
                            </Button>
                        )}
                        <Textarea {...description} />
                    </>
                )}
            </Modal>
        </>
    );
}
