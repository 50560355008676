/* eslint-disable max-len */
/* eslint-disable indent */
import { Button, Link, Modal, Select } from '@components';
// import { setModalStatus } from '@slices/ui.slice';
import { GET, POST, formatMoney, quantityOptions } from '@utils';
import clsx from 'clsx';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deleteIcon from '@assets/icons/delete.svg';
import helpRedOutlinedIcon from '@assets/icons/help_red_outlined.svg';
import { setModalStatus } from '@slices/ui.slice';
import { toast } from 'react-toastify';

function ReOrderModal({ orderId, customerId }) {
    const [products, set_products] = React.useState([]);
    const [deleted_id, set_deleted_id] = React.useState(null);
    const [loading, set_loading] = React.useState(null);
    const [errors, set_errors] = React.useState([]);
    const [message, set_message] = React.useState('');

    const dispatch = useDispatch();
    const modals_status = useSelector((state) => state.UISlice.modals_status);

    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/order/${orderId}/reorder-products`,
        });
        set_loading(false);
        if (res?.status_code === 200) {
            set_products(res?.data.map((p) => ({ ...p, removed: false })));
        }
    };

    const submit = async () => {
        set_errors([]);
        set_message('');
        const filterCartProduct = products
            .filter((p) => !p.removed && p.orderable)
            .map((x) => ({ item: x.id, quantity: +x.quantity }));
        set_loading(true);
        const res = await POST({
            endpoint: `/live-cart/${customerId}/items`,
            data: { items: filterCartProduct },
        });
        set_loading(false);
        if (res.success) {
            if (res?.data?.errors.length) {
                set_message(res.message);
                return set_errors(res?.data?.errors);
            }
            dispatch(setModalStatus({ modal: 'customer_re_order_modal', status: false }));
            toast.success(res.message);
        }
    };

    React.useEffect(() => {
        if (modals_status['customer_re_order_modal'] && orderId) {
            fetchData();
        }

        if (!modals_status['customer_re_order_modal']) {
            set_products([]);
            set_errors([]);
            set_message('');
            set_deleted_id(null);
        }
    }, [modals_status, orderId]);

    return (
        <Modal
            name="customer_re_order_modal"
            title="Re-fill the live cart"
            onSubmit={submit}
            loading={loading}
            submitBtnDisabled={
                products.filter((p) => !p.removed && p.orderable).length === 0 ? true : false
            }
            className="overflow_able_modal"
        >
            {errors?.length !== 0 && (
                <div className="alert alert-warning">
                    <p>{message}</p>
                    {errors?.map((error) => (
                        <p key={error?.id}>{error?.message}</p>
                    ))}
                </div>
            )}

            <div className="products-list">
                {products?.map((p, index) => (
                    <div
                        className="flex__jc-between"
                        key={p.id}
                        style={
                            index + 1 !== products.length
                                ? { borderBottom: '1px solid #ccc', marginBottom: 16 }
                                : {}
                        }
                    >
                        <div className="flex" style={{ gap: 16 }}>
                            <Link
                                style={{
                                    width: 100,
                                }}
                                href={'/products/' + p.slug}
                            >
                                <img
                                    className="w-100"
                                    style={{
                                        objectFit: 'contain',
                                        maxHeight: 100,
                                    }}
                                    src={p.image}
                                    alt={p.name}
                                />
                            </Link>
                            <div className="details">
                                <div className="content">
                                    <h4 className="h6">{p.name}</h4>
                                </div>
                                <div className="price">
                                    <div className="total">
                                        {p.removed ? (
                                            <p>This product was deleted</p>
                                        ) : (
                                            <>
                                                {!p.discount ? (
                                                    <>{formatMoney(p.price)} SAR</>
                                                ) : (
                                                    <>
                                                        <span>
                                                            {formatMoney(
                                                                p?.discount?.price_after_discount,
                                                            )}{' '}
                                                            SAR
                                                        </span>
                                                        &nbsp; &nbsp; &nbsp; &nbsp;
                                                        <s>{formatMoney(p.price)} SAR</s>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {p?.orderable ? (
                                    <>
                                        <div className="flex gap-2 mt-2">
                                            <div className="select_quantity">
                                                <Select
                                                    options={quantityOptions(p)}
                                                    value={{
                                                        value: p.quantity,
                                                        label: 'Qty: ' + p.quantity,
                                                    }}
                                                    onChange={(val) => {
                                                        set_products((prev) =>
                                                            prev?.map((x) =>
                                                                x.id === p.id
                                                                    ? {
                                                                          ...x,
                                                                          quantity: val.value,
                                                                      }
                                                                    : x,
                                                            ),
                                                        );
                                                    }}
                                                    disabled={p.removed}
                                                />
                                            </div>

                                            <div className="mt-1">
                                                {!p.removed && deleted_id !== p.id && (
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => set_deleted_id(p.id)}
                                                    >
                                                        <img
                                                            width="18px"
                                                            src={deleteIcon}
                                                            alt="remove product form Re-order"
                                                        />{' '}
                                                    </Button>
                                                )}

                                                {deleted_id === p.id && (
                                                    <div className="flex">
                                                        <Button
                                                            onClick={() => {
                                                                set_products((prev) =>
                                                                    prev?.map((x) =>
                                                                        x.id === p.id
                                                                            ? {
                                                                                  ...x,
                                                                                  removed: true,
                                                                              }
                                                                            : x,
                                                                    ),
                                                                );
                                                                set_deleted_id(null);
                                                            }}
                                                            variant="danger"
                                                            size="sm"
                                                        >
                                                            Delete
                                                        </Button>
                                                        <Button
                                                            onClick={() => set_deleted_id(null)}
                                                            variant="outline-secondary"
                                                            size="sm"
                                                        >
                                                            Back
                                                        </Button>
                                                    </div>
                                                )}
                                                {p.removed && deleted_id !== p.id && (
                                                    <Button
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        onClick={() => {
                                                            set_products((prev) =>
                                                                prev?.map((x) =>
                                                                    x.id === p.id
                                                                        ? {
                                                                              ...x,
                                                                              removed: false,
                                                                          }
                                                                        : x,
                                                                ),
                                                            );
                                                            set_deleted_id(null);
                                                        }}
                                                    >
                                                        Restore
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div style={{ marginTop: 16 }}>
                                        <img src={helpRedOutlinedIcon} alt="help" />{' '}
                                        <strong>Unavailable</strong>{' '}
                                    </div>
                                )}
                            </div>
                            <div className="variant-options">
                                {p?.variant_options?.map((o, i) => (
                                    <div key={i}>
                                        <span>{o.option_name}: </span>
                                        <span>{o.value}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {!p.removed && p?.orderable && (
                            <div
                                className={clsx(
                                    'total_price',
                                    p?.variant_options?.length ? 'full_width_price' : '',
                                )}
                            >
                                <p>
                                    {p?.discount
                                        ? formatMoney(
                                              p?.quantity * p?.discount?.price_after_discount,
                                          )
                                        : formatMoney(p.quantity * p.price)}{' '}
                                    SAR
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
}

export default ReOrderModal;
