import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import ChildOrdersListView from './ChildOrdersListView';
import { Helmet } from 'react-helmet';
import BoxesDetail from './Ops2/BoxesDetail';

export default function OrdersModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/child-orders/list">
                <Helmet>
                    <title>Child Orders | Disty Admin Dashboard</title>
                </Helmet>
                <ChildOrdersListView />
            </ProtectedRoute>
            <ProtectedRoute path="/child-orders/:childOrderId/boxes">
                <Helmet>
                    <title>Child Order Boxes | Disty Admin Dashboard</title>
                </Helmet>
                <BoxesDetail />
            </ProtectedRoute>
        </Switch>
    );
}
