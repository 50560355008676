import { useState, useEffect, useMemo } from 'react';
import { Card, DataTable, Select } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadPayoutSuborders } from '@slices/payouts.slice';
import _ from 'lodash';
import useColumns from './columns';

const ChildOrders = ({ payout_id, vendor }) => {
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();

    const errors = PayoutsSlice.payout_suborders_errors;

    const suborders = PayoutsSlice.payout_suborders;

    const subordersWithVendor = useMemo(
        () =>
            suborders.map((suborder) => ({
                ...suborder,
                vendor,
            })),
        [suborders],
    );

    const payout_purchase_orders = useMemo(
        () =>
            _.uniqBy(
                suborders
                    .filter((s) => s.vendor_invoice_request_id)
                    .map((s) => ({
                        label: s.vendor_invoice_request_id,
                        id: s.vendor_invoice_request_id,
                    })),
                (o) => o.id,
            ),
        [suborders, payout_id],
    );

    const isSubordersInRetail = !!payout_purchase_orders.length;
    const COLUMNS = useColumns({ isSubordersInRetail });

    const [purchase_orders, set_purchase_orders] = useState({
        label: 'Filter by purchase order',
        options: [],
        value: [],
        isSearchable: true,
        isMulti: true,
        getOptionValue: (option) => option.id,
        onChange: (val) => set_purchase_orders((prev) => ({ ...prev, value: val })),
    });

    const fetchSuborders = () => {
        dispatch(
            loadPayoutSuborders(payout_id, {
                vendor_invoice_requests: purchase_orders.value.map((p) => p.id),
                records_per_page: 1000000,
            }),
        );
    };

    useEffect(fetchSuborders, [payout_id, purchase_orders.value]);

    useEffect(() => {
        if (!purchase_orders.value.length) {
            set_purchase_orders((prev) => ({
                ...prev,
                options: payout_purchase_orders,
            }));
        }
    }, [payout_purchase_orders]);

    return (
        <Card title="Child Orders">
            {!!Object.keys(errors).length && (
                <div className="m-3 alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}

            {purchase_orders.options.length > 1 && (
                <div style={{ width: '40%', padding: '20px', paddingBottom: 0 }}>
                    <Select {...purchase_orders} />
                </div>
            )}

            {!Object.keys(errors).length && (
                <DataTable
                    data={subordersWithVendor}
                    pages={PayoutsSlice.payout_suborders_pages}
                    columns={COLUMNS}
                    loading={PayoutsSlice.payout_suborders_loading}
                    hiddenColumns={!isSubordersInRetail ? ['purchase_order'] : []}
                    disablePageHistory
                />
            )}
        </Card>
    );
};

export default ChildOrders;
