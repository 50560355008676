import React from 'react';
import { DataTable } from '@components';

export default function VendorsTab() {
    const data = [
        {
            id: 1,
            name: 'ركن المذاق',
            orders_count: 10
        },
        {
            id: 2,
            name: 'ركن المذاق',
            orders_count: 10
        },
    ];
    const columns = [
        {
            name: '#',
            selector: 'id',
            width: '100px',
            center: true
        },
        {
            name: 'Name',
            selector: 'name',
            width: '400px',
            sortable: false,
        },
        {
            name: 'Orders count',
            selector: 'orders_count',
            sortable: false,
        },
    ];

    return (
        <DataTable
            data={data}
            columns={columns}
        />
    );
}
