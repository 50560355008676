import React from 'react';
import {
    ViewHeader,
    Link,
    Button,
    DataTable,
    Card,
    StatusToggler,
    Filters,
    Sop,
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadCollectionsData, updateSingleRow } from '@slices/collections.slice';
import { formatDate } from '@utils';
import defaultLogo from '@assets/img/default.jpg';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function CollectionsListView() {
    const CollectionsSlice = useSelector((state) => state.CollectionsSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({
        status: null,
        tagged: null,
        market_availability: null,
        sort_by: 'created_at',
    });

    useDidMountEffect(() => {
        dispatch(loadCollectionsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'Image',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <Link href={`/collections/${row.id}`} className="tr-logo">
                    <img src={row.image || defaultLogo} alt="Logo" />
                </Link>
            ),
        },
        {
            name: 'Name',
            selector: 'name',
            cell: (row) => (
                <Link href={`/collections/${row.id}`} className="tr-name">
                    <h5>{row.name}</h5>
                </Link>
            ),
        },
        {
            name: 'From',
            cell: (row) => formatDate(row.period_from),
        },
        {
            name: 'To',
            cell: (row) => formatDate(row.period_to),
        },
        {
            name: 'Status',
            selector: 'status',
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row.is_active}
                    path={`/product-collection/${row.id}/status`}
                    callback={({ id, status }) => {
                        dispatch(updateSingleRow({ id, is_active: status }));
                    }}
                />
            ),
        },
        {
            name: 'Collection Type',
            selector: 'preview_type',
        },
        {
            name: 'Collection availability',
            selector: 'is_active',
            cell: (row) => (
                <>{row.is_active ? <span>Available in market</span> : <span>Not available</span>}</>
            ),
        },
        {
            name: 'Manage sub-collections',
            center: true,
            cell: (row) => (
                <Link href={`/collections/${row.id}/sub-collections`} style={{ color: '#007bff' }}>
                    Sub-collections
                </Link>
            ),
        },
        {
            name: 'Sort products',
            center: true,
            cell: (row) => (
                <Link href={`/collections/${row.id}/sort`} style={{ color: '#007bff' }}>
                    Sort
                </Link>
            ),
        },
        {
            name: 'CTA Link',
            center: true,
            cell: (row) => (
                <Button
                    onClick={() => {
                        navigator?.clipboard?.writeText(
                            process.env.REACT_APP_STOREFRONT_BASE_URL + '/collections/' + row.id,
                        );
                    }}
                    variant="secondary"
                    style={{ color: 'white' }}
                >
                    Copy CTA Link
                </Button>
            ),
        },
    ];

    return (
        <div className="collections-list-view">
            <ViewHeader title="Collections">
                <Sop id="30003" />
                <Link href="/collections/add" className="btn btn-primary">
                    New collection
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    filters={['status', 'tagged', 'collection_type']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'id',
                        sort_direction: 'asc',
                    }}
                    sort_options={[
                        {
                            label: 'ID (DESC)',
                            value: 'id__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'ID (ASC)',
                            value: 'id__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={[...CollectionsSlice.collections].sort((a, b) =>
                        a.type > b.type ? 1 : -1,
                    )}
                    pages={CollectionsSlice.collections_pages}
                    page={page}
                    records={CollectionsSlice.collections_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={CollectionsSlice.collections_loading}
                />
            </Card>
        </div>
    );
}
