/* eslint-disable max-len */
import React from 'react';
import { toast } from 'react-toastify';
import {
    ApiAsyncSelect,
    ViewHeader,
    Card,
    Input,
    Textarea,
    Row,
    Col,
    Filters,
    Sop
} from '@components';
import { stringify } from 'query-string';
import { POST, GET, formatDate, randomId, errorsNormalizer } from '@utils';

export default function SendNotificationsListView() {
    const [received_devices_count, set_received_devices_count] =
        React.useState(null);
    const [selected_notification, set_selected_notification] = React.useState(
        {}
    );
    const idempotencyKey = randomId();

    const [loading, set_loading] = React.useState(false);
    const [pushing_notification, set_pushing_notification] =
        React.useState(false);

    const [filters, set_filters] = React.useState({});

    const fetchData = async (params) => {
        if (!params.has_wallet_balance?.includes(1)) {
            delete params.min_wallet_balance;
            delete params.max_wallet_balance;
        }

        set_loading(true);
        const res = await GET({
            endpoint: `/notifications/dry-push?${stringify(
                {
                    ...params,
                    created_at_from: params.created_at_from
                        ? formatDate(params.created_at_from)?.split(' ')[0]
                        : null,
                    created_at_to: params.created_at_to
                        ? formatDate(params.created_at_to)?.split(' ')[0]
                        : null,
                    
                },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma'
                }
            )}`
        });
        set_loading(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_received_devices_count(res?.data?.received_devices_count || 0);
        } else if(res?.status === 422){
            toast.error(errorsNormalizer(res?.data?.errors).join(', '));
        }
    };

    const pushNotification = async (params) => {
        set_pushing_notification(true);
        const res = await POST({
            endpoint: `/notifications/${
                selected_notification?.id
            }/push?${stringify(
                {
                    ...params,
                    created_at_from: params.created_at_from
                        ? formatDate(params.created_at_from)?.split(' ')[0]
                        : null,
                    created_at_to: params.created_at_to
                        ? formatDate(params.created_at_to)?.split(' ')[0]
                        : null,
                    // min_total_orders: params?.min_total_orders,
                    // max_total_orders: params?.max_total_orders,
                    // total_spent_from: params?.total_spent_from,
                    // total_spent_to: params?.total_spent_to
                },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma'
                }
            )}`,
            headers: {
                'Idempotency-Key': idempotencyKey
            }
        });
        set_pushing_notification(false);

        if (res?.status_code === 200 || res?.status_code === 201) {
            toast.success('Notification sent successfully.');
            set_selected_notification({});
            set_received_devices_count(null);
        }
    };

    return (
        <div className="send-notifications-view">
            <ViewHeader title="Send notifications to customers">
                <Sop id="25423"/>
            </ViewHeader>

            <Card>
                <Filters
                    filters={[
                        'status',
                        'cities',
                        'entity_types',
                        'account_managers',
                        'verification_statuses',
                        'has_wallet_balance',
                        ...(filters?.has_wallet_balance?.includes(1) ? ['wallet_balance'] : []),
                        'created_at_from',
                        'created_at_to',
                        'total_orders',
                        'total_spent',
                        'fulfilled_orders',
                        'average_rating',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    disableFiltersCaching
                />
                <button
                    className="btn btn-info btn-block"
                    onClick={() => fetchData(filters)}
                >
                        Get devices count
                </button>

                <div className="devices-counter">
                    {received_devices_count === null ? null : (
                        <>
                            {loading ? (
                                'loading...'
                            ) : (
                                <>
                                    <h1 className="text-center">
                                        <span style={{ fontSize: 40 }}>
                                            {received_devices_count}
                                        </span>
                                        <br />
                                        <small>
                                            Devices will receive the
                                            notification.
                                        </small>
                                    </h1>
                                    <div className="px-3 py-3">
                                        <ApiAsyncSelect
                                            label="Search for a notification"
                                            api="/notifications"
                                            onlyFetchActiveRecords={false}
                                            placeholder="Type to search..."
                                            labelProperty={(r) => r.title_en}
                                            onChange={set_selected_notification}
                                            value={selected_notification}
                                        />
                                        {selected_notification?.id && (
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label="Title Ar"
                                                            disabled
                                                            dir="auto"
                                                            value={
                                                                selected_notification?.title_ar
                                                            }
                                                        />
                                                        <Textarea
                                                            label="Body Ar"
                                                            disabled
                                                            dir="auto"
                                                            value={
                                                                selected_notification?.body_ar
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Input
                                                            label="Title En"
                                                            disabled
                                                            dir="auto"
                                                            value={
                                                                selected_notification?.title_en
                                                            }
                                                        />
                                                        <Textarea
                                                            label="Body En"
                                                            disabled
                                                            dir="auto"
                                                            value={
                                                                selected_notification?.body_en
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label="CTA"
                                                            disabled
                                                            dir="auto"
                                                            value={
                                                                selected_notification?.cta
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                pushNotification(filters)
                                            }
                                            disabled={
                                                !selected_notification?.id ||
                                                received_devices_count === 0 ||
                                                pushing_notification
                                            }
                                        >
                                            Confirm sending to{' '}
                                            {received_devices_count} devices?
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
}
