import React from 'react';
import { Modal, Input, Select, DatePicker, FileUpload, Textarea } from '@components';
import { POST, randomId } from '@utils';
import { loadGlobalBanks } from '@slices/banks.slice';
import { loadOrderData, loadOrderPayments } from '@slices/orders.slice';
import { useDispatch, useSelector } from 'react-redux';

const NewWireTransactionModal = ({ id }) => {
    const BanksSlice = useSelector((state) => state.BanksSlice);
    const dispatch = useDispatch();
    const [submitting, set_submitting] = React.useState(false);
    React.useEffect(() => {
        dispatch(loadGlobalBanks());
    }, [dispatch]);

    const [data, setData] = React.useState({
        transfer_name: '',
        bank_transaction_reference_number: '',
        amount: '',
    });

    const [bank, setBank] = React.useState({
        label: 'Bank',
        value: '',
        onChange: (val) => setBank({ ...bank, value: val }),
    });
    // const [payment_method, set_payment_method] = React.useState({
    //     label: 'Payment Method',
    //     value: '',
    //     options: [
    //         { label: 'Cash', value:'cash' },
    //         { label: 'Wallet', value:'wallet' },
        
    //     ],
    //     getOptionValue: option => option.value,
    //     onChange: (val) => set_payment_method({ ...payment_method, value: val }),
    // });
    const [bankOptions, setBankOptions] = React.useState([]);
    React.useEffect(() => {
        if (BanksSlice?.global_banks?.length) {
            setBankOptions(
                BanksSlice.global_banks.map((b) => {
                    return {
                        value: b.id,
                        label: `${b.name_ar} (${b.name_en})`,
                    };
                }),
            );
        }
    }, [BanksSlice?.global_banks]);
    const [transfer_date, set_transfer_date] = React.useState({
        label: 'Transfer date',
        selectedDate: new Date(),
        onChange: (val) => set_transfer_date({ ...transfer_date, selectedDate: val }),
    });
    const [transfer_receipt, set_transfer_receipt] = React.useState({
        label: 'Transfer receipt (optional)',
        files: [],
        allowPDF: true,
        setFiles: (val) => set_transfer_receipt({ ...transfer_receipt, files: val }),
    });
    const [comments, set_comments] = React.useState({
        label: 'Comments',
        value: '',
        setValue: (val) => set_comments({ ...comments, value: val }),
    });
    const [errors, setErrors] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (
            !data.transfer_name ||
            data.transfer_name.length < 6 ||
            !/^\s*\S+\s+\S+/.test(data.transfer_name)
        )
            newErrors.transfer_name =
                'The transfer name field is required , must be at least 6 characters and must contain at least a first and last name.';
        if (!bank.value) newErrors.bank_id = 'The bank id is required.';
        // if (!data.amount) newErrors.amount = 'The amount field is required.';
        if (!data.amount) {
            newErrors.amount = 'The amount field is required.';
        } else {
            if (!/^-?\d*\.?\d*$/.test(data.amount)) {
                newErrors.amount = 'Please enter a valid numeric amount.';
            }
        }
       
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    React.useEffect(() => {
        validate();
    }, [data, bank]);
    const onSubmit = async (close) => {
        set_loading(true);
        if (!validate()) return;
        let d = new FormData();
        d.append('transfer_name', data.transfer_name);
        d.append('bank_id', bank.value.value);
        d.append('order_id', id);
        d.append('bank_transaction_ref_number', data.bank_transaction_reference_number);
        d.append('transfer_date', transfer_date.selectedDate?.toISOString().split('T')[0]);
        d.append('amount', data.amount);
        d.append('comments', comments.value);
        if (transfer_receipt.files.length > 0) {
            d.append('receipt', transfer_receipt.files[0]);
        }

        set_submitting(true);
        const res = await POST({
            endpoint: '/payment/transactions/wire',
            data: d,
            headers: {
                'Idempotency-Key': randomId(),
            },
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data?.errors);
        } else if (res?.status === 400) {
            setErrors({
                message: res?.data?.message,
            });
            set_loading(false);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            close();
            dispatch(loadOrderPayments(id));
            dispatch(loadOrderData(id));
            set_loading(false);
            setData({
                transfer_name: '',
                bank_transaction_reference_number: '',
                amount: '',
            });
            set_transfer_date({ ...transfer_date, selectedDate: new Date() });
            setBank({ ...bank, value: '' }), set_comments({ ...comments, value: '' });
            set_transfer_receipt({ ...transfer_receipt, files: [] });
        }
    };

    return (
        <Modal
            title="Add New Wire Transaction"
            name="add_new_wire_transaction_modal"
            onSubmit={onSubmit}
            loading={submitting}
        >
            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
            <Input
                label="Transfer name"
                setValue={(val) => {
                    setData({ ...data, transfer_name: val });
                }}
                value={data.transfer_name}
            />
            {loading && validation_errors.transfer_name && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.transfer_name}
                </p>
            )}
            <Select {...bank} options={bankOptions} />
            {loading && validation_errors.bank_id && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>{validation_errors.bank_id}</p>
            )}
            <Input
                label="Bank transaction reference number (optional)"
                setValue={(val) => {
                    setData({
                        ...data,
                        bank_transaction_reference_number: val,
                    });
                }}
                value={data.bank_transaction_reference_number}
            />
            <DatePicker {...transfer_date} />
            <Input
                label="Amount"
                setValue={(val) => {
                    setData({ ...data, amount: val });
                }}
                value={data.amount}
                
            />
            {loading && validation_errors.amount && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>{validation_errors.amount}</p>
            )}
            {/* <Select {...payment_method}/> */}
            <FileUpload {...transfer_receipt} />
            <Textarea {...comments} />
        </Modal>
    );
};

export default NewWireTransactionModal;
