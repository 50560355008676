import React from 'react';
import { Redirect } from 'react-router';
import { POST } from '@utils';
import { useDispatch } from 'react-redux';
import { clearAuthState } from '@store/slices/auth.slice';

export default function Logout() {
    const dispatch = useDispatch();
    const [loggedout, setLoggedout] = React.useState(false);

    React.useEffect(() => {
        const logout = async () => {
            await POST({ endpoint: '/logout' });
            dispatch(clearAuthState());
            window.localStorage.removeItem('Disty_admin_token');
            setLoggedout(true);
        };
        logout();
    }, []);

    if (loggedout) {
        return <Redirect to="/login" />;
    }

    return <div className="logout">logging out...</div>;
}
