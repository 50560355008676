import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function PayLaterTypeFilter({
    value = null,
    set_value = () => { },
}) {
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const options = OrdersSlice?.order_static_data?.pay_later_providers?.map((p) => ({
        label: p.description,
        value: p.code,
    })) || [];

    const onChange = useCallback(
        (value) => set_value(value),
        [],
    );

    return (
        <ChoiceList
            choices={options}
            selected={value || []}
            onChange={onChange}
            allowMultiple
        />
    );
}
