import React from 'react';
import {
    ViewHeader,
    Button,
    Card,
    Row,
    Col,
    Input,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { Redirect } from 'react-router';
import { POST, randomId } from '@utils';

export default function VendorsAcquisitionsAddView() {
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(null);
    const [errors, set_errors] = React.useState([]);

    const [first_name, set_first_name] = React.useState({
        label: 'First name',
        value: '',
        setValue: (val) => set_first_name({ ...first_name, value: val }),
    });
    const [last_name, set_last_name] = React.useState({
        label: 'Last name',
        value: '',
        setValue: (val) => set_last_name({ ...last_name, value: val }),
    });
    const [mobile, set_mobile] = React.useState({
        label: 'Mobile number',
        value: '',
        prefix: '+966',
        setValue: (val) => set_mobile({ ...mobile, value: val }),
    });
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}-\u{1F0CF}]/u;

        if (!first_name.value || emojiRegex.test(first_name.value))
            newErrors.first_name =
                'The first name field is required';
        if (!last_name.value || emojiRegex.test(last_name.value))
            newErrors.last_name =
                'The last name field is required';
        if (!mobile.value || !/^5[0-9]{1}[0-9]{7}$/i.test(mobile.value)) {
            newErrors.mobile =
                'The mobile field is required. It should start with 5 followed by 8 digits.';
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [first_name, last_name, mobile]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const data = {
                first_name: first_name.value,
                last_name: last_name.value,
                mobile: mobile.value,
            };

            const res = await POST({
                endpoint: '/acquisition/acquisitions',
                data,
                headers: {
                    'Idempotency-Key': randomId(),
                },
            });

            set_loading(false);
            if (res?.status === 422) {
                set_errors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_created(res?.data.id);
            }
        }
    };

    if (created) {
        return <Redirect to="/acquisitions/list" />;
    }
    return (
        <div className="vendor-acquisitions-add-view">
            <CustomPrompt>
                <ViewHeader title="Create new acquisition" renderBackArrow>
                    <Sop id="29903" />
                    <Button variant="primary" onClick={submit}>
                        Create
                    </Button>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        <Row>
                            <Col xs={12} md={6}>
                                <Input {...first_name} />
                                {loading && validation_errors.first_name && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.first_name}
                                    </p>
                                )}
                                <Input {...mobile} />
                                {loading && validation_errors.mobile && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.mobile}
                                    </p>
                                )}
                            </Col>
                            <Col xs={12} md={6}>
                                <Input {...last_name} />
                                {loading && validation_errors.last_name && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.last_name}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
