import React from 'react';
import { Modal } from '@components';
import { formatDate, GET } from '@utils';

const PriceHistoryModal = ({ productID, onClose }) => {
    const [loading, set_loading] = React.useState(false);
    const [price_history, set_price_history] = React.useState([]);
    const [applied_discount, set_applied_discount] = React.useState(null);

    const fetchData = async () => {
        set_loading(true);
        const [price_audits, applied_discount] = await Promise.all([
            GET({
                endpoint: `/product/${productID}/price-audit`,
            }),
            GET({
                endpoint: `/product/${productID}/active-discount`,
            }),
        ]);
        set_loading(false);
        if(price_audits?.status_code === 200){
            set_price_history(price_audits?.data?.audits);
        }
        if(applied_discount?.status_code === 200){
            set_applied_discount(applied_discount?.data?.percentage);
        }
    };

    React.useEffect(() => {
        if (productID) fetchData();
    }, [productID]);

    return (
        <Modal
            name="price_history_modal"
            title={`Price history of product #${productID}`}
            confirmBtn={false}
            className="price-history-modal"
            onClose={onClose}
        >
            {loading ? (<p>loading...</p>) : (
                <>
                    {applied_discount && (
                        <div className="applied-discount">
                            <b>Applied discount</b>
                            <b>{applied_discount}%</b>
                        </div>
                    )}
                    <table>
                        <thead>
                            <tr>
                                <th>Old price</th>
                                <th>New price</th>
                                <th>Change date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {price_history?.map((item, _i) => (
                                <tr key={_i}>
                                    <td>{item.changes.price.old || 0}</td>
                                    <td>{item.changes.price.new || 0}</td>
                                    <td>
                                        {formatDate(item.date)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </Modal>
    );
};
export default PriceHistoryModal;