import React from 'react';
import { DataTable } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadLogsData } from '@slices/logs.slice';

export default function HistoryTab({ id }) {
    const LogsSlice = useSelector((state) => state.LogsSlice);
    const dispatch = useDispatch();

    const [q, set_q] = React.useState('');
    const [page, set_page] = React.useState(1);

    React.useEffect(() => {
        dispatch(loadLogsData({ q, page, customer_id: id }));
    }, [q, page, id]);

    const columns = [
        {
            name: 'Time',
            selector: 'id', // TODO change to timestamp
            sortable: false,
        },
        {
            name: 'Action',
            selector: 'action',
            sortable: false,
            format: (row) =>
                row.action.auditable_type + ' #' + row.action.auditable_id + ' ' + row.action.event,
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: false,
            format: (row) =>
                row.action.event === 'updated' ? Object.keys(row.changes).join(', ') : '',
        },
        {
            name: 'Action by',
            selector: 'user.name',
            sortable: false,
        },
    ];

    return (
        <DataTable
            data={LogsSlice.logs}
            pages={LogsSlice.logs_pages}
            page={page}
            handlePageChange={set_page}
            columns={columns}
            searchable={true}
            onSearch={set_q}
            loading={LogsSlice.logs_loading}
        />
    );
}
