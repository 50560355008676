import React from 'react';
import {
    ViewHeader,
    CriticalActionButton,
    Toggler,
    Checkbox,
    Card,
    Row,
    Col,
    Input,
    ApiAsyncSelect,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { loadVendorUserData } from '@slices/merchantUsers.slice';
import { useParams, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PATCH, GET, confirmationMessage } from '@utils';
import { setVendorUser } from '../../store/slices/merchantUsers.slice';

export default function MerchantUsersUpdateView() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [loading, set_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const [errors, set_errors] = React.useState([]);
    const VendorUsersSlice = useSelector((state) => state.VendorUsersSlice);
    const [permissions_list, set_permissions_list] = React.useState([]);
    const [is_checked, set_is_checked] = React.useState({});
    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({ endpoint: '/vendorUsers/permissions' });
            set_loading(false);
            if (res?.status_code === 200) {
                set_is_checked(
                    res?.data
                        ?.map((item) => item.code)
                        .reduce((a, v) => ({ ...a, [v]: false }), {}),
                );
                set_permissions_list(res?.data);
            }
        };
        fetchData();
    }, []);

    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val }),
    });
    const [first_name, set_first_name] = React.useState({
        label: 'First name',
        value: '',
        setValue: (val) => set_first_name({ ...first_name, value: val }),
    });
    const [last_name, set_last_name] = React.useState({
        label: 'Last name',
        value: '',
        setValue: (val) => set_last_name({ ...last_name, value: val }),
    });
    const [mobile, set_mobile] = React.useState({
        label: 'Mobile number',
        value: '',
        prefix: '+966',
        setValue: (val) => set_mobile({ ...mobile, value: val }),
    });
    const [id_card_number, set_id_card_number] = React.useState({
        label: 'Card number',
        value: '',
        setValue: (val) => set_id_card_number({ ...id_card_number, value: val }),
    });
    const [email, set_email] = React.useState({
        label: 'Email',
        type: 'email',
        value: '',
        setValue: (val) => set_email({ ...email, value: val }),
    });
    const [vendors, set_vendors] = React.useState([]);
    const resetCheckBox = () => {
        set_is_checked({
            superVendor: false,
            createOrder: false,
            onlyAccessToRelatedOrders: false,
            onlyAccessToRelatedInvoices: false,
            viewProducts: false,
        });
    };
    React.useEffect(() => {
        dispatch(setVendorUser({}));
        dispatch(loadVendorUserData(id));
    }, []);
    React.useEffect(() => {
        if (VendorUsersSlice.vendorUser.id) {
            first_name.setValue(VendorUsersSlice.vendorUser.first_name);
            last_name.setValue(VendorUsersSlice.vendorUser.last_name);
            mobile.setValue(VendorUsersSlice.vendorUser.mobile);
            id_card_number.setValue(VendorUsersSlice?.vendorUser?.id_card_number);
            email.setValue(VendorUsersSlice.vendorUser.email);
            set_vendors(
                VendorUsersSlice.vendorUser.vendors.map((v) => ({
                    ...v,
                    label: v.company_name,
                    value: v.id,
                })),
            );
            status.onChange(VendorUsersSlice.vendorUser.is_active);
            if (VendorUsersSlice?.vendorUser?.permissions?.length !== 0) {
                resetCheckBox();
                for (const p of VendorUsersSlice.vendorUser.permissions.map(
                    (permission) => permission?.code,
                )) {
                    if (p in is_checked)
                        set_is_checked((prevState) => ({
                            ...prevState,
                            [p]: true,
                        }));
                }
            }
        }
    }, [VendorUsersSlice.vendorUser, permissions_list]);
    const hasPermissions = (obj) => {
        return Object.values(obj).some((value) => value === true);
    };
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
       // const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}-\u{1F0CF}]/u;

        if (!first_name.value || !/^[a-zA-Z0-9\u0600-\u06FF\s]*$/.test(first_name.value))
            newErrors.first_name ='The first name field is required and must not contain emojis or  symbols.';
        if (!last_name.value || !/^[a-zA-Z0-9\u0600-\u06FF\s]*$/.test(last_name.value))
            newErrors.last_name =
                'The last name field is required and must not contain emojis or  symbols.';
        if (!mobile.value || !/^5[0-9]{1}[0-9]{7}$/i.test(mobile.value)) {
            newErrors.mobile =
                'The mobile field is required. It should start with 5 followed by 8 digits.';
        }
        if (!email.value) {
            newErrors.email = 'The email field is required.';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value)) {
            newErrors.email = 'Invalid email address.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(email.value)) {
            newErrors.email = 'Email should not contain Arabic letters';
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [first_name, last_name, mobile, email]);
    const [error, setError] = React.useState('');
    const submit = async () => {
        set_loading(true);
        if (!hasPermissions(is_checked) && !validate()) {
            setError('you must assign permission for this user ');
            return;
        }
        if (vendors === null) {
            setError('you must assign merchant for this user');
            return;
        }
        if (validate()) {
            const res = await PATCH({
                endpoint: `/vendorUsers/${id}`,
                data: {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    is_active: status.checked,
                    mobile: mobile.value,
                    id_card_number: id_card_number.value,
                    vendors: vendors.map((v) => v.id),
                    permissions: Object.keys(is_checked).filter((k) => is_checked[k]),
                },
            });

            set_loading(false);
            if (res?.status === 422) {
                set_errors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_updated(true);
            }
        }
    };

    if (updated) {
        return <Redirect to="/merchant-users/list" />;
    }

    if (VendorUsersSlice.vendorUser_loading) {
        return 'loading...';
    }

    return (
        <div className="categories-form-view">
            <CustomPrompt>
                <ViewHeader title="Update Merchant user" renderBackArrow>
                    <Sop id="29863" />
                    <CriticalActionButton
                        onSubmit={() => {
                            VendorUsersSlice?.vendorUser?.is_active
                                ? submit()
                                : confirmationMessage(submit);
                        }}
                    >
                        Save changes
                    </CriticalActionButton>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        <Row>
                            <Col xs={12} md={6}>
                                <Toggler {...status} />
                                <Input {...first_name} />
                                {loading && validation_errors.first_name && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.first_name}
                                    </p>
                                )}
                                <Input {...last_name} />
                                {loading && validation_errors.last_name && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.last_name}
                                    </p>
                                )}
                                <Input {...mobile} />
                                {loading && validation_errors.mobile && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.mobile}
                                    </p>
                                )}
                                <Input {...email} />
                                {loading && validation_errors.email && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.email}
                                    </p>
                                )}
                                <Input {...id_card_number} />
                            </Col>
                            <Col xs={12} md={6}>
                                <ApiAsyncSelect
                                    isMulti
                                    api="/vendors"
                                    onlyFetchActiveRecords={false}
                                    placeholder="Type to Add Merchants..."
                                    labelProperty={(r) => {
                                        return (
                                            <div className="custom-option-with-thumbnail">
                                                <img src={r.logo_ar} />
                                                <div className="content">
                                                    <h4>{r.company_name_ar}</h4>
                                                    <p>
                                                        {r.email}
                                                        {r.mobile ? ` | ${r.mobile}` : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    }}
                                    value={vendors}
                                    onChange={(v) => {
                                        console.log(v);
                                        set_vendors(v);
                                        v !==null
                                            ? setError('')
                                            : setError('you must assign merchant for this user');
                                    }}
                                />
                                {permissions_list?.map((permission, index) => (
                                    <>
                                        <Checkbox
                                            key={permission?.code || index}
                                            label={permission?.description}
                                            checked={is_checked[permission?.code]}
                                            onChange={(val) => {
                                                const name = permission?.code;
                                                set_is_checked({
                                                    ...is_checked,
                                                    [name]: val,
                                                });
                                                val === true
                                                    ? setError('')
                                                    : setError(
                                                          'you must assign permission for this user',
                                                      );
                                            }}
                                        />
                                    </>
                                ))}
                                {error && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>{error}</p>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
