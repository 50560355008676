import React from 'react';
import { DataTable, Button, Modal, Input } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { PUT, POST, DELETE, errorsNormalizer } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
import useMutation from '@hooks/useMutation';
import useQuery from '@hooks/useQuery';
import { toast } from 'react-toastify';

const PayoutOptionsTab = () => {
    const dispatch = useDispatch();
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });
    const [days, set_days] = React.useState({
        label: 'Payout days',
        value: '',
        type: 'number',
        setValue: (val) => val.toString().length <= 3 && set_days({ ...days, value: val }),
    });

    const {
        data: { data } = { data: [] },
        isLoading,
        refetch,
    } = useQuery('/vendors/vendor-payout-options');

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'name_en',
        },
        {
            name: 'Payout days',
            selector: 'days',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            dispatch(
                                setModalStatus({
                                    modal: 'payout_option__delete_modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            name_ar.setValue(row.name_ar);
                            name_en.setValue(row.name_en);
                            days.setValue(row.days);
                            dispatch(
                                setModalStatus({
                                    modal: 'payout_option__modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const createOrUpdateMutations = useMutation(
        () =>
            (edit_id ? PUT : POST)({
                endpoint: edit_id
                    ? `/vendors/vendor-payout-options/${edit_id}`
                    : '/vendors/vendor-payout-options',
                data: {
                    name_ar: name_ar.value,
                    name_en: name_en.value,
                    days: +days.value,
                },
            }),
        {
            onSuccess: () => {
                refetch();
                dispatch(
                    setModalStatus({
                        modal: 'payout_option__modal',
                        status: false,
                    }),
                );
                name_ar.setValue('');
                name_en.setValue('');
                days.setValue('');
            },
            onError: (errors) => toast.error(errorsNormalizer(errors).join(', ')),
        },
    );
    const deleteMutation = useMutation(
        () =>
            DELETE({
                endpoint: `/vendors/vendor-payout-options/${delete_id}`,
            }),
        {
            onSuccess: () => {
                refetch();
                dispatch(
                    setModalStatus({
                        modal: 'payout_option__delete_modal',
                        status: false,
                    }),
                );
            },
            onError: (errors) => toast.error(errorsNormalizer(errors).join(', ')),
        },
    );
    return (
        <>
            <DataTable data={data} columns={columns} loading={isLoading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        name_ar.setValue('');
                        name_en.setValue('');
                        days.setValue('');
                        dispatch(
                            setModalStatus({
                                modal: 'payout_option__modal',
                                status: true,
                            }),
                        );
                    }}
                >
                    Add new payout option
                </Button>
            </div>

            <Modal
                title={edit_id ? 'Update payout option' : 'Add new payout option'}
                variant={edit_id ? 'warning' : 'primary'}
                name="payout_option__modal"
                onSubmit={() => createOrUpdateMutations.mutate()}
                loading={createOrUpdateMutations.isLoading}
            >
                <div className="alert alert-warning">
                    make sure you include the payout days number in the name for better
                    accessibility across the system, E.g. (Merchant premium payout - 3 days)
                </div>
                <Input {...name_ar} />
                <Input {...name_en} />
                <Input {...days} />
            </Modal>

            <Modal
                title="Delete payout option?"
                name="payout_option__delete_modal"
                variant="danger"
                onSubmit={() => deleteMutation.mutate()}
                loading={deleteMutation.isLoading}
            />
        </>
    );
};

export default PayoutOptionsTab;
