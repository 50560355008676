import React from 'react';
import {
    ViewHeader,
    Button,
    Card,
    Row,
    Col,
} from '@components';
import defaultLogo from '@assets/img/default.jpg';
import { POST, obj2formdata } from '@utils';
import { useParams, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadCollectionData } from '@slices/collections.slice';
import { ReactSortable } from 'react-sortablejs';
import { useHistory } from 'react-router-dom';

export default function CollectionsUpdateView() {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const CollectionsSlice = useSelector((state) => state.CollectionsSlice);

    const [products, set_products] = React.useState([]);
    const [loading, set_loading] = React.useState(true);
    const [sorted, set_sorted] = React.useState(false);

    React.useEffect(() => {
        dispatch(loadCollectionData(id));
    }, []);

    React.useEffect(() => {
        if (CollectionsSlice.collection.id) {
            set_products(CollectionsSlice.collection.products);
            set_loading(false);
        }
    }, [CollectionsSlice.collection.products]);

    const submit = async () => {
        set_loading(true);
        const data = obj2formdata({});
        
        products.forEach((p, i) => {
            data.append('products['+i+'][id]', p.id);
            data.append('products['+i+'][priority]', i + 1);
        });
        
        const res = await POST({
            endpoint: `/product-collection/${id}/priority`,
            data
        });
        set_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            set_sorted(true);
        }
    };

    if (sorted) {
        return <Redirect to="/collections/list" />;
    }

    if (loading) {
        return 'loading...';
    }

    return (
        <div className="collections-sort-products-view">
            <ViewHeader 
                title={`Sort collection's products: ${CollectionsSlice.collection.name_en}`} 
                renderBackArrow
            >
                <Button 
                    variant="light" 
                    onClick={() => history.goBack()}
                >
					          Cancel
                </Button>
                <Button 
                    variant="primary" 
                    onClick={submit}
                    disabled={loading}
                >
					          Save changes
                </Button>
            </ViewHeader>

            <Row>
                <Col xs={12}>
                    <Card>
                        <ReactSortable 
                            tag="ul"
                            // list={products} 
                            list={products.map(x => ({ ...x, chosen: true }))}
                            setList={set_products}
                        >
                            {products.map((p) => (
                                <li key={p.id} className="products-list-item">
                                    <div className="handle">
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="logo">
                                        <img src={p.image || defaultLogo} alt="Logo" />
                                    </div>
                                    <div className="name">{p.name}</div>
                                </li>
                            ))}
                        </ReactSortable>
                    </Card>
                </Col>
            </Row>

        </div>
    );
}
