/* eslint-disable max-len */
// https://www.npmjs.com/package/@react-google-maps/api
import React from 'react';
import makerIconDispatch from '../../assets/icons/marker-dispatch.svg';
import makerIconPickup from '../../assets/icons/marker-pickup.svg';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from '@react-google-maps/api';
import { randomId } from '@utils';
import { Link } from '@components';

const defaultStyles = [
    {
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'administrative.neighborhood',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#050505',
            },
            {
                visibility: 'on',
            },
            {
                weight: 1.5,
            },
        ],
    },
    {
        featureType: 'landscape',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

const defaultMapOptions = {
    mapTypeControl: false,
    panControl: false,
    scaleControl: false,
    streetViewControl: false,
    overviewMapControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    // zoomControl: false,
    // minZoom: 7,
    styles: defaultStyles,
};

const libraries = ['places'];

const center_of_SA = {
    lat: 24.804219,
    lng: 45.8601,
};

function Map({ data, center = center_of_SA }) {
    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    const [map, set_map] = React.useState(null);
    const [show_info_id, set_show_info_id] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        set_map(map);
    }, []);

    const onUnmount = React.useCallback(function callback() {
        set_map(null);
    }, []);

    return (
        isLoaded && (
            <GoogleMap
                mapContainerStyle={{
                    height: '90vh',
                    maxHeight: '100vh',
                }}
                mapContainerClassName="map-element"
                center={center}
                zoom={6}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={defaultMapOptions}
                onZoomChanged={() => {
                    if (map) {
                        if (map?.getZoom() > 9) {
                            map.setOptions({
                                styles: [
                                    ...defaultStyles,
                                    {
                                        featureType: 'road',
                                        stylers: [
                                            {
                                                visibility: 'on',
                                            },
                                        ],
                                    },
                                ],
                            });
                        } else {
                            map.setOptions({
                                styles: [
                                    ...defaultStyles,
                                    {
                                        featureType: 'road',
                                        stylers: [
                                            {
                                                visibility: 'off',
                                            },
                                        ],
                                    },
                                ],
                            });
                        }
                    }
                }}
            >
                <>
                    {Object.keys(data)?.map((id) => {
                        const entity = data[id];

                        const { coords, suborders, customer, vendor } = entity;
                        const is_customer_marker = !!customer;

                        return (
                            <Marker
                                onClick={() => set_show_info_id(id)}
                                key={randomId()}
                                icon={is_customer_marker ? makerIconDispatch : makerIconPickup}
                                position={coords}
                            >
                                {show_info_id === id && (
                                    <InfoWindow
                                        onCloseClick={() => set_show_info_id(null)}
                                        position={coords}
                                    >
                                        <div>
                                            {suborders.map((suborder, i) => (
                                                <Link
                                                    href={'/orders/' + suborder.parent_order_id}
                                                    target="_blank"
                                                    key={randomId()}
                                                >
                                                    <h1>{i + 1}) #{suborder.pretty_id}</h1>
                                                </Link>
                                            ))}
                                            {is_customer_marker ? (
                                                <>
                                                    <Link
                                                        href={'/customers/' + customer.id}
                                                        target="_blank"
                                                    >
                                                        <h2>
                                                            {customer.name}
                                                            <br />
                                                            <small>{customer.store_name}</small>
                                                        </h2>
                                                    </Link>
                                                </>
                                            ) : (
                                                <>
                                                    <Link href={'/merchants/' + vendor.id} target="_blank">
                                                        <h2>
                                                            <img
                                                                src={vendor.logo}
                                                                width={50}
                                                                alt={vendor.company_name}
                                                            />
															&nbsp;
                                                            {vendor.company_name}
                                                        </h2>
                                                    </Link>
                                                </>
                                            )}
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker>
                        );
                    })}
                </>
            </GoogleMap>
        )
    );
}

export default React.memo(Map);
