export default function closeFullScreen() {
    /* we must include prefixes for different browsers, 
		as they don't support the exitFullscreen property yet */

    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
    }
}