import React from 'react';
import { DataTable, Button, Modal, Input, ShowErrors } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { GET, POST, DELETE, obj2formdata, randomId } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';

const PackagesTypesTab = () => {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/package-type',
            });
            set_data(res?.data || []);
            set_loading(false);
        };
        fetchData();
    }, [rerender]);

    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });

    const submit = async (closeModal, id) => {
        set_loading(true);

        const data = obj2formdata({
            name_ar: name_ar.value,
            name_en: name_en.value,
        });

        if (id) {
            data.append('_method', 'PUT');
        }

        const res = await POST({
            endpoint: id ? `/package-type/${id}` : '/package-type',
            data,
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('package_type__modal');
            set_rerender(randomId());
            name_ar.setValue('');
            name_en.setValue('');
            set_errors([]);
        }
    };

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'name_en',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger" 
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'package_type__delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>

					&nbsp;&nbsp;
                    
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            name_ar.setValue(row.name_ar);
                            name_en.setValue(row.name_en);
                            set_errors([]);

                            dispatch(
                                setModalStatus({
                                    modal: 'package_type__modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const deletePackageType = async closeModal => {
        set_loading(true);
        const res = await DELETE({
            endpoint: `/package-type/${delete_id}`
        });
        set_loading(false);
        
        closeModal('package_type__delete_modal');

        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('package_type__delete_modal');
            set_rerender(randomId());
            set_delete_id(null);
        }
    };

    return (
        <>
            <DataTable data={data} columns={columns} loading={loading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        name_ar.setValue('');
                        name_en.setValue('');
                        set_errors([]);

                        dispatch(
                            setModalStatus({
                                modal: 'package_type__modal',
                                status: true,
                            })
                        );
                    }}
                >
						Add new package type
                </Button>
            </div>

            <Modal
                title={edit_id ? 'Update package type' : 'Add new package type'}
                variant={edit_id ? 'warning' : 'primary'}
                name="package_type__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
                loading={loading}
            >
                <Input {...name_ar} />
                <Input {...name_en} />
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>

            <Modal
                title="Delete package type?"
                name="package_type__delete_modal"
                variant="danger"
                onSubmit={deletePackageType}
                loading={loading}
            />
        </>
    );
};

export default PackagesTypesTab;
