import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

const initialState = {
    app_feedbacks: [],
    app_feedbacks_pages: null,
    app_feedbacks_records: null,
    app_feedbacks_loading: true,
};

export const AppFeedbackSlice = createSlice({
    name: 'app-feedback',
    initialState,
    reducers: {
        setAppFeedbacks(state, { payload }) {
            state.app_feedbacks = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setAppFeedbacksTotalPages(state, { payload }) {
            state.app_feedbacks_pages = payload;
        },
        setAppFeedbacksRecords(state, { payload }) {
            state.app_feedbacks_records = payload;
        },
    },
});

// export actions
export const {
    setAppFeedbacks,
    setAppFeedbacksTotalPages,
    setLoading,
    setAppFeedbacksRecords,
} = AppFeedbackSlice.actions;

export const loadAppFeedbacksData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'app_feedbacks_loading',
            value: true,
        })
    );
	
    const res = await GET({
        endpoint: `/customers/feedback?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`,
    });
    
    dispatch(setAppFeedbacks(res?.data || []));
    if(res?.meta){
        dispatch(setAppFeedbacksTotalPages(res?.meta?.last_page || 0));
        dispatch(setAppFeedbacksRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'app_feedbacks_loading',
            value: false,
        })
    );
};

export default AppFeedbackSlice.reducer;
