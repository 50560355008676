import { setModalStatus } from '@slices/ui.slice';
import { ApiAsyncSelect, Button, Modal } from '@components';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { POST, randomId } from '@utils';
import useMutation from '../../../hooks/useMutation';

const MODAL_NAME = 'create_scheduled_order_invoice_modal';

const AddPoInvoice = ({ fetchData }) => {
    const dispatch = useDispatch();
    const [data, set_data] = useState({ vendor: null, suborders: [] });

    const handleChange = (key) => (value) =>
        set_data((state) => ({ ...state, [key]: value }));

    const openModal = () =>
        dispatch(
            setModalStatus({
                modal: MODAL_NAME,
                status: true
            })
        );

    const closeModal = () =>
        dispatch(
            setModalStatus({
                modal: MODAL_NAME,
                status: false
            })
        );

    const { mutate, error, isLoading } = useMutation(
        () =>
            POST({
                endpoint: '/vendors/invoice-requests',
                data: {
                    vendor_id: data.vendor?.id,
                    suborders: data.suborders?.map(({ id }) => id)
                },
                headers: {
                    'Idempotency-Key': randomId()
                }
            }),
        {
            onSuccess: () => {
                closeModal();
                fetchData?.();
            }
        }
    );

    return (
        <>
            <Button onClick={openModal} variant="info">
                Add new PO
            </Button>
            <Modal
                name={MODAL_NAME}
                title="Adding new purchase order invoice"
                onSubmit={() => mutate()}
                loading={isLoading}
            >
                {!!Object.keys(error ?? {}).length && (
                    <div className="alert alert-danger">
                        <ul className="errors-list">
                            {Object.keys(error).map((key) => error[key]?.map((errMsg, _i) => (
                                <li key={_i}>{errMsg}</li>
                            )))}
                        </ul>
                    </div>
                )}

                <ApiAsyncSelect
                    label="Merchant"
                    api="/vendors"
                    onlyFetchActiveRecords={false}
                    placeholder="Type to Add Merchant..."
                    labelProperty={(r) => r.company_name_ar}
                    onChange={handleChange('vendor')}
                    value={data.vendor}
                />
                <ApiAsyncSelect
                    label="suborder"
                    api="/suborder"
                    params={{ vendors: data.vendor?.id }}
                    onlyFetchActiveRecords={false}
                    placeholder="Type to add suborder..."
                    labelProperty={(r) => `${r.pretty_id}(${r.id})`}
                    onChange={handleChange('suborders')}
                    value={data.suborders}
                    isMulti
                />
            </Modal>
        </>
    );
};

export default AddPoInvoice;
