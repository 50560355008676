import React from 'react';
import { Modal } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import ScreenCard from './ScreenCard';
import { ReactSortable } from 'react-sortablejs';
import { sortOnboardingScreens } from '@slices/onboardingScreens.slice';
import { setModalStatus } from '@slices/ui.slice';

export default function SortScreensModal() {
    const dispatch = useDispatch();
    const OnboardingScreensSlice = useSelector((state) => state.OnboardingScreensSlice);
    const screens = OnboardingScreensSlice.screens_list;
    const modals_status = useSelector((state) => state.UISlice.modals_status);
    const [sorted_screens, set_sorted_screens] = React.useState([]);

    React.useEffect(() => {
        set_sorted_screens(screens.map((s) => ({ ...s, chosen: true })));
    }, [modals_status, screens]);

    const onSubmit = async () => {
        await dispatch(sortOnboardingScreens({
            onboarding_screens: sorted_screens.map((s, i) => ({
                id: s.id,
                display_order: i + 1
            }))
        }));
        closeModalAndReset();
    };

    const closeModalAndReset = () => {
        dispatch(
            setModalStatus({
                modal: 'sort_onboarding_screens_modal',
                status: false
            })
        );
        set_sorted_screens([]);
    };

    return (
        <Modal
            name="sort_onboarding_screens_modal"
            title="Sort onboarding screens"
            size="lg"
            onSubmit={onSubmit}
            onClose={closeModalAndReset}
        >
            {!!sorted_screens?.length && (
                <ReactSortable
                    list={sorted_screens}
                    setList={set_sorted_screens}
                    className="onboarding-screens-grid"
                >
                    {sorted_screens?.map(s => (
                        <ScreenCard key={s?.id} screen={s} minimal />
                    ))}
                </ReactSortable>
            )}
        </Modal>
    );
}