import React from 'react';
import { Input, Select, ApiAsyncSelect, Checkbox, Modal, ShowErrors } from '@components';
import { regexLib, POST } from '@utils';
import { useSelector, useDispatch } from 'react-redux';
import useMutation from '@hooks/useMutation';
import { setModalStatus } from '@slices/ui.slice';

function InviteUserModal({ retailer_data = null, refetch = () => {} }) {
    const dispatch = useDispatch();
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const [loading, set_loading] = React.useState(false);
    const [first_name, set_first_name] = React.useState({
        label: 'First name',
        value: '',
        setValue: (val) => set_first_name({ ...first_name, value: val }),
    });
    const [last_name, set_last_name] = React.useState({
        label: 'Last name',
        value: '',
        setValue: (val) => set_last_name({ ...last_name, value: val }),
    });
    const [email, set_email] = React.useState({
        label: 'Email',
        value: '',
        type: 'email',
        regex: regexLib['email'],
        setValue: (val) => set_email({ ...email, value: val }),
    });
    const [mobile, set_mobile] = React.useState({
        label: 'Mobile',
        value: '',
        prefix: '+966',
        setValue: (val) => set_mobile({ ...mobile, value: val }),
    });
    const [is_account_admin, set_is_account_admin] = React.useState({
        label: 'is_account_admin',
        checked: false,
        onChange: (val) => set_is_account_admin({ ...is_account_admin, checked: val }),
    });
    const [occupations, set_occupations] = React.useState({
        label: 'Occupations',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_occupations((prev) => ({ ...prev, value: val })),
    });
    const [retailer, set_retailer] = React.useState(null);
    React.useEffect(() => {
        if (GlobalsSlice?.occupations?.length) {
            set_occupations({ ...occupations, options: GlobalsSlice.occupations });
        }
    }, [GlobalsSlice.occupations]);
    React.useEffect(() => {
        if (retailer_data?.id) set_retailer(retailer_data);
    }, [retailer_data]);
    const resetForm = () => {
        set_loading(false);
        first_name.setValue('');
        last_name.setValue('');
        email.setValue('');
        mobile.setValue('');
        occupations.onChange('');
        is_account_admin.onChange(false);
        set_retailer(retailer_data?.id ? retailer_data : null);
    };

    const createMutations = useMutation(
        () =>
            POST({
                endpoint: '/customers/retailer_users',
                data: {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    mobile: mobile.value,
                    occupation: occupations?.value?.label,
                    retailer_id: retailer?.id,
                    is_account_admin: is_account_admin?.checked ? '1' : '0',
                },
            }),

        {
            onSuccess: () => {
                refetch();
                dispatch(
                    setModalStatus({
                        modal: 'invite_new_user_modal',
                        status: false,
                    }),
                );
                resetForm();
            },
        },
    );
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        //const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}-\u{1F0CF}]/u;

        if (!first_name.value || !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(first_name.value))
            newErrors.first_name =
                'The first name field is required and must be contained only letters and spaces.';
        if (!last_name.value || !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(last_name.value))
            newErrors.last_name =
                'The last name field is required and must be contained only letters and spaces.';
        if (!mobile.value || !/^5[0-9]{1}[0-9]{7}$/i.test(mobile.value)) {
            newErrors.mobile =
                'The mobile field is required. It should start with 5 followed by 8 digits.';
        }
        if (!email.value) {
            newErrors.email = 'The email field is required.';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value)) {
            newErrors.email = 'Invalid email address.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(email.value)) {
            newErrors.email = 'Email should not contain Arabic letters';
        }
        if (!occupations.value) newErrors.occupations = 'The Occupation field is required.';
        if (!retailer?.value) newErrors.retailer = 'The retailer id field is required.';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [first_name, last_name, mobile, email, occupations, retailer]);
    return (
        <Modal
            name="invite_new_user_modal"
            title="Invite New User"
            onSubmit={() => {
                set_loading(true);
                if (validate()) createMutations.mutate();
            }}
            loading={createMutations.isLoading}
            onClose={() => resetForm()}
        >
            <Input {...first_name} />
            {loading && validation_errors.first_name && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.first_name}
                </p>
            )}
            <Input {...last_name} />
            {loading && validation_errors.last_name && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.last_name}
                </p>
            )}
            <Input {...mobile} />
            {loading && validation_errors.mobile && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>{validation_errors.mobile}</p>
            )}
            <Input {...email} />
            {loading && validation_errors.email && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>{validation_errors.email}</p>
            )}
            <Select {...occupations} />
            {loading && validation_errors.occupations && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.occupations}
                </p>
            )}
            <ApiAsyncSelect
                label="Retailers"
                api="/customers"
                onlyFetchActiveRecords={false}
                placeholder="Type to add Retailer..."
                labelProperty={(r) => r.store_name}
                onChange={(v) => set_retailer(v)}
                value={
                    retailer?.id
                        ? {
                              value: retailer?.id,
                              label: retailer?.store_name,
                          }
                        : null
                }
            />
            {loading && validation_errors.retailer && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>{validation_errors.retailer}</p>
            )}
            <Checkbox {...is_account_admin} />
            {createMutations.error && <ShowErrors errors={createMutations.error} />}
        </Modal>
    );
}

export default InviteUserModal;
