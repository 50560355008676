import React, { useEffect } from 'react';
import {
    Modal,
    Input,
    FileUpload,
    Textarea,
    Select,
} from '@components';
import { POST,obj2formdata,randomId } from '@utils';
import { loadOrderData, loadOrderPayments } from '@slices/orders.slice';
import { loadAdjustmentTypes } from '@slices/adjustmentTypes.slice';

import { useDispatch, useSelector } from 'react-redux';
const AddAdjustmentTransactionsModal = ({ id , fetch_adjustments }) => {
    const idempotencyKey = randomId();
    const dispatch = useDispatch();
    const AdjustmentTypesSlice = useSelector((state) => state.AdjustmentTypesSlice);
    const [attachment, set_attachment] = React.useState({
        label: 'Evidence/Attachment (optional)',
        files: [],
        allowPDF: true,
        maxFiles: 1,
        setFiles: (val) => set_attachment({ ...attachment, files: val })
    });
    const [description, set_description] = React.useState({
        label: 'Description (mandatory)',
        value: '',
        setValue: (val) => set_description({ ...description, value: val })
    });
    const [reference_number, set_reference_number] = React.useState({
        label: 'Adjustment Transaction Reference Number (optional)',
        value: '',
        setValue: (val) => set_reference_number({ ...reference_number, value: val })
    });
    const [amount, set_amount] = React.useState({
        label: 'Adjustment Amount (mandatory)',
        value: '',
        type: 'number',
        acceptDecimal: true,
        setValue: (val) => set_amount({ ...amount, value: val })
    });
    const [selected_type, set_selected_type] = React.useState({
        label: 'Adjustment Type',
        options: AdjustmentTypesSlice?.adjustment_types?.map((b) => ({
            id: b?.id,
            label: b?.type_en
        })),
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_selected_type((prev) => ({ ...prev, value: val }))
    });
    const [errors, set_errors] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const resetData = ()=>{
        attachment.setFiles([]);
        description.setValue('');
        amount.setValue('');
        reference_number.setValue('');
        set_errors([]);
    };
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if ( !description.value )
            newErrors.description ='The description field is required.';
        if (!selected_type?.value) newErrors.selected_type = 'The type id is required.';
        if (!amount.value) newErrors.amount = 'The amount field is required.';

        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    React.useEffect(() => {
        validate();
    }, [description.value,amount.value,selected_type.value]);
    const submit = async (closeModal) => {
        set_loading(true);
        if (!validate()) return;
        const data = obj2formdata({
            order_id : id,
            reference_number : reference_number.value,
            amount : amount.value,
            description: description.value,
            type_id: selected_type?.value?.id,
        });
        attachment.files.length && data.append('attachment', attachment.files[0]);
        const res = await POST({
            endpoint: '/payment/transactions/adjustment',
            data,
            headers: {
                'Idempotency-Key': idempotencyKey
            }
        });
        set_loading(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            fetch_adjustments();
            dispatch(loadOrderPayments(id));
            dispatch(loadOrderData(id));
            closeModal('add_adjustment_transaction');
            resetData();
        } else {
            set_errors(res?.data?.errors || { message : res?.data.message } || {});
        }
    };

    useEffect(() => {
        dispatch(loadAdjustmentTypes());
    }, []);

    useEffect(() => {
        set_selected_type({
            ...selected_type,
            options: AdjustmentTypesSlice?.adjustment_types?.map((b) => ({
                id: b?.id,
                label: b?.type_en
            })),
        });
    }, [AdjustmentTypesSlice]);

    return (
        <Modal
            title="Add Adjustment Transaction"
            name="add_adjustment_transaction"
            onSubmit={(closeModal) => submit(closeModal)}
            onClose={resetData}
        >
            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
            <Input {...reference_number}/>
            <Input {...amount}/>
            {loading && validation_errors.amount && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.amount}
                </p>
            )}
            <FileUpload {...attachment} />
            <Select {...selected_type} />
            {loading && validation_errors.selected_type && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.selected_type}
                </p>
            )}
            <Textarea {...description} />
            {loading && validation_errors.description && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.description}
                </p>
            )}
        </Modal>
    );
};

export default AddAdjustmentTransactionsModal;