export const ZATCA_IDENTIFICATION_TYPES = [
    {
        value: 'TIN',
        label: 'Tax Identification Number',
    },
    {
        value: 'MOM',
        label: 'Momrah Licence',
    },
    {
        value: 'MLS',
        label: 'Mhrsd Licence',
    },
    {
        value: '700',
        label: 'Seven Hundred Licence',
    },
    {
        value: 'SAG',
        label: 'Misa Licence',
    },
    {
        value: 'NAT',
        label: 'National Id',
    },
    {
        value: 'GCC',
        label: 'Gcc Id',
    },
    {
        value: 'IQA',
        label: 'Iqama Number',
    },
    {
        value: 'PAS',
        label: 'Passport Id',
    },
    {
        value: 'OTH',
        label: 'Other',
    },
];
