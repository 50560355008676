/* eslint-disable no-unused-vars */
import jsbarcode from 'jsbarcode';


function generateSuborderBarcode({ pretty_id, cell, vendor_name }){
    const img = document.createElement('img');

    new jsbarcode(img, pretty_id, {
    });

    img.onload = function () {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // setting the dimensions
        canvas.width = img.width;
        canvas.height = img.height + 30;

        // set bg color
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);

        // adding the barcode 
        context.drawImage(img, 0, 30);

        // add top left text
        context.font = '20px Arial';
        context.fillStyle = 'black';
        context.textAlign = 'left';
        if(vendor_name?.length > 15){
            context.fillText('..' + vendor_name.slice(0, 15), 15, 30);
        } else{
            context.fillText(vendor_name, 15, 30);
        }

        // add top right text
        context.font = '20px Arial';
        context.fillStyle = 'black';
        context.textAlign = 'right';
        context.fillText(cell, canvas.width - 20, 30);

        // print the canvas as an image
        printCanvas(canvas);
    };
}


function printCanvas(c){
    const data = c.toDataURL('image/jpeg');
    const w = window.open('');
    w.document.write(`
        <style>
            @page { size: auto;  margin: 0mm; }
        </style>
        <img src="${data}" />
    `);
    w.document.close();

    setTimeout(() => {
        w.print();
    }, 500);
}
function downloadCanvas(c, filename){
    const link = document.createElement('a');
    link.download = `${filename}.jpeg`;
    const data = c.toDataURL('image/jpeg');
    link.href = data;
    link.click();
}

export default generateSuborderBarcode;