import { formatMoney } from '@utils';

const Price = ({ amount }) => (
    <div className="tr-price">
        <span>SAR</span>
        <div>{formatMoney(amount || 0)}</div>
    </div>
);

export default Price;
