import React from 'react';
import { DataTable, Button } from '@components';
import { GET, formatDate, downloadFileFromAPI } from '@utils';
import { useSelector } from 'react-redux';
function VendorAccountStatusHistoryTab({ id }) {
    const vendor = useSelector((state) => state.VendorsSlice.vendor);
    const [status_history , set_status_history]= React.useState([]);
    const [meta_pages , set_meta_pages]=React.useState(0);
    const [page, set_page] = React.useState(1);
    const [loading , set_loading]=React.useState(true);
    
    const fetchData = async () => {
        set_loading(true);
        const res = await GET({ endpoint:`/vendors/${id}/status_history` });
        set_loading(false);
        if(res.status_code === 200){
            set_status_history(res?.data || []);
            set_meta_pages(res?.meta?.last_page || 0);
        }
    };
    React.useEffect(()=>{
        fetchData();
    },[vendor?.status]);
    const columns = [
        {
            name: 'Status',
            selector: 'status',
            cell: (row) => row?.status ? 'active' : 'inactive'
        },
        {
            name: 'Reason',
            selector: 'reason',
            cell: (row) => row?.reason.reason
        },
        {
            name: 'Other Reason',
            selector: 'other_reason',
            cell: (row) => row?.other_reason
        },
        {
            name: 'Comments',
            selector: 'comments',
            cell: (row) => row?.comments
        },
        {
            name: 'Created at',
            cell: (row) => formatDate(row?.created_at),
        },
        {
            name: 'Attachment',
            selector: 'attachment',
            cell: (row) =>( row?.attachment ?  (<Button 
                variant="link"
                onClick={() => {
                    downloadFileFromAPI(row?.attachment);
                }}>
            download attachment
            </Button>) : '__' )
        },
        
    ];
    return (
        <DataTable
            data={status_history}
            pages={meta_pages}
            page={page}
            handlePageChange={set_page}
            columns={columns}
            loading={loading}
        />
    );
}

export default VendorAccountStatusHistoryTab;