import { ButtonGroup, DropdownButton, Dropdown } from '@components';
import useQuery from '@hooks/useQuery';
function Awb({ id }) {
    const { data: { data: suborder } = {} } = useQuery(`/suborder/${id}`);
    function getFilenameFromUrl(url) {
        const pathname = new URL(url).pathname;
        const index = pathname.lastIndexOf('/');
        return pathname.substring(index + 1);
    }
    if (!suborder?.awb?.length) return null;
    return (
        <>
            <DropdownButton as={ButtonGroup} title="AWBs" id="bg-nested-dropdown" variant="light">
                {suborder?.awb?.map((l, i) => (
                    <Dropdown.Item
                        key={i}
                        eventKey={i}
                        onClick={() => {
                            window.open(l, '_blank');
                        }}
                    >
                        {getFilenameFromUrl(l)}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </>
    );
}

export default Awb;
