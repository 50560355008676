import React, { useEffect } from 'react';
import { Row, Col, Select, Modal, ShowErrors } from '@components';
import { POSITIONS, POSITION_LABELS } from './constants';
import { POST, PUT } from '@utils';
import contentful from '@utils/contentful';
import { loadHelpMappingData } from '@slices/helpMapping.slice';
import { useDispatch, useSelector } from 'react-redux';

const MODAL_KEY = 'help_mapping_form__modal';

const FormModal = ({ open, onClose, onSubmitted }) => {
    const HelpMappingSlice = useSelector((state) => state.HelpMappingSlice);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [article_keys, set_article_keys] = React.useState({});
    const [id, set_id] = React.useState();
    const dispatch = useDispatch();

    const loadHelpMapping = () => {
        if (!id) return;
        dispatch(loadHelpMappingData(id));
    };

    const loadArticles = async () => {
        const items = await contentful.getAllArticles();
        const options = [];
        const keys = {};

        items.map((i) => {
            options.push({
                label: i.title,
                value: i.id,
            });
            keys[i.id] = i.title;
        });

        set_article((prev) => ({ ...prev, options }));
        set_article_keys(keys);
    };

    const submit = async (closeModal) => {
        set_loading(true);
        set_errors([]);

        const data = {
            position_id: position.value?.value,
            contentful_article_id: article.value?.value,
        };

        const METHOD = id ? PUT : POST;

        const req = await METHOD({
            endpoint: `/vendors/help-center-articles${id ? `/${id}` : ''}`,
            data,
        });

        set_loading(false);

        if (req?.success || req?.data?.success) {
            closeModal(MODAL_KEY);
            onSubmitted();
        } else {
            set_errors(req?.data.errors);
        }
    };

    const [position, set_position] = React.useState({
        label: 'Merchant UI Position',
        options: POSITIONS,
        value: [],
        getOptionValue: (option) => option.value,
        onChange: (val) => set_position((prev) => ({ ...prev, value: val })),
    });

    const [article, set_article] = React.useState({
        label: 'Contentful Article',
        options: [],
        placeholder: 'Search by Arabic title....',
        getOptionValue: (option) => option.value,
        onChange: (val) => set_article((prev) => ({ ...prev, value: val })),
    });

    const reset = () => {
        article.onChange(null);
        position.onChange(null);
        set_errors([]);
    };

    useEffect(() => {
        if (open) {
            loadArticles();
        }
    }, [open]);

    useEffect(() => {
        if (HelpMappingSlice.help_mapping?.id) {
            const data = HelpMappingSlice.help_mapping;

            position.onChange({
                label: POSITION_LABELS[data.position_id],
                value: data.position_id,
            });

            article.onChange({
                label: article_keys[data.contentful_article_id],
                value: data.contentful_article_id,
            });
        }
    }, [HelpMappingSlice.help_mapping]);

    useEffect(loadHelpMapping, [id]);

    return (
        <Modal
            title={`${id ? 'Update' : 'Create'} Help Topic Mapping`}
            variant="primary"
            name={MODAL_KEY}
            onSubmit={submit}
            onClose={() => {
                reset();
                onClose();
            }}
            loading={loading}
            setPayload={(p) => set_id(p?.id)}
        >
            <Row>
                <Col lg={12}>
                    <Select {...position} />
                </Col>
                <Col lg={12}>
                    <Select {...article} />
                </Col>
            </Row>
            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
        </Modal>
    );
};

export default FormModal;
