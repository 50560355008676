import { createSlice } from '@reduxjs/toolkit';
import { GET } from '@utils';

export const GlobalsSlice = createSlice({
    name: 'globals',
    initialState: {
        loading: false,
        countries: [],
        regions: [],
        cities: [],
        ksa_cities: [],
        districts: [],
        districtsCity: null,
        plans: [],
        all_plans: [],
        shippingMethods: [],
        accountManagers: [],
        vendors_categories: [],
        occupations: [],
        entity_types: [],
        vendors_types: [],

        vendors: [],
        brands: [],
        categories: [],
        categories_flat: [],
        packages_types: [],
        delivery_times: [],
        customer_types: [],
        banner_types:[]
    },
    reducers: {
        set_arr_value(state, { payload: { key, value = [], label_name } }) {
            state[key] =
				value.map &&
				value.map((item) => ({
				    id: item?.id || item[label_name || 'name_ar'],
				    label: item[label_name || 'name_ar'],
				}));
        },
        set_districts_city(state, { payload }) {
            state.districtsCity = payload;
        },
        set_account_managers(state, { payload }) {
            state.accountManagers =
				payload.map &&
				payload.map((item) => ({
				    id: item.id,
				    label: `${item.first_name} ${item.last_name}`,
				}));
        },
        set_loading(state, { payload }){
            state.loading = payload;
        },
        set_all_plans(state, { payload }) {
            state.all_plans = payload;
        }
    },
});

// export actions
export const {
    set_arr_value,
    set_account_managers,
    set_loading,
    set_districts_city,
    set_all_plans,
} = GlobalsSlice.actions;

export const loadGlobalsData = () => async (dispatch) => {
    dispatch(set_loading(true));
    const [
        countries,
        regions,
        cities,
        ksa_cities,
        plans,
        shippingMethods,
        accountManagers,
        vendors,
        brands,
        categories,
        packages_types,
        delivery_times,
        vendors_categories,
        occupations,
        entity_types,
        vendors_types,
        customer_types,
        banner_types
    ] = await Promise.all([
        GET({
            endpoint: '/countries',
            auth: false,
        }),
        GET({
            endpoint: '/countries/1/regions',
            auth: false,
        }),
        GET({
            endpoint: '/regions/1/cities',
            auth: false,
        }),
        GET({
            endpoint: '/cities',
            auth: false,
        }),
        GET({
            endpoint: '/vendors/plans',
        }),
        GET({
            endpoint: '/vendors/shipping-methods',
        }),
        GET({
            endpoint: '/vendors/account-managers',
        }),

        GET({
            endpoint: '/vendors',
        }),
        GET({
            endpoint: '/brand?records_per_page=999',
        }),
        GET({
            endpoint: '/category?status=1',
        }),
        GET({
            endpoint: '/package-type',
        }),
        GET({
            endpoint: '/customers/suitable-delivery-time',
            realm: 'store'
        }),
        GET({
            endpoint: '/vendors/category',
        }),
        GET({
            endpoint: '/occupations',
            auth: false,
        }),
        GET({
            endpoint: '/customers/entity-types',
            auth: false,
        }),
        GET({
            endpoint: '/vendors/vendor-types',
        }),
        GET({
            endpoint: '/customers/customer-types',
        }),
        GET({
            endpoint: '/marketing/banners/banner-position-list',
         
        }),
    ]);
    dispatch(
        set_arr_value({
            key: 'countries',
            value: countries?.data,
        })
    );
    dispatch(
        set_arr_value({
            key: 'regions',
            value: regions?.data,
        })
    );
    dispatch(
        set_arr_value({
            key: 'cities',
            value: cities?.data,
        })
    );
    dispatch(
        set_arr_value({
            key: 'ksa_cities',
            value: ksa_cities?.data,
        })
    );
    dispatch(
        set_arr_value({
            key: 'plans',
            value: plans?.data,
        })
    );
    dispatch(
        set_all_plans(plans?.data)
    );
    dispatch(
        set_arr_value({
            key: 'shippingMethods',
            value: shippingMethods?.data,
        })
    );
    dispatch(
        set_arr_value({
            key: 'vendors',
            value: vendors?.data,
            label_name: 'company_name_ar',
        })
    );
    dispatch(
        set_arr_value({
            key: 'brands',
            value: brands?.data,
        })
    );
    dispatch(
        set_arr_value({
            key: 'vendors_categories',
            value: vendors_categories?.data,
        })
    );

    // parent categories
    dispatch(
        set_arr_value({
            key: 'categories',
            value: categories?.data,
        })
    );
    // flattened categories
    const flattened_category = (arr) => {
        const flat = [];
        if(Array.isArray(arr)){
            arr?.forEach((c) => {
                flat.push({
                    id: c.id,
                    name_ar: c.name_ar,
                    name_en: c.name_en,
                    image: c.image,
                    is_active: c.is_active,
                    products_count: c.products_count,
                });

                if (c.children.length) {
                    flat.push(...flattened_category(c.children));
                }
            });
        }
        return flat;
    };
    dispatch(
        set_arr_value({
            key: 'categories_flat',
            value: flattened_category(categories.data),
        })
    );

    dispatch(
        set_arr_value({
            key: 'packages_types',
            value: packages_types?.data,
        })
    );

    dispatch(
        set_arr_value({
            key: 'delivery_times',
            value: delivery_times?.data,
            label_name: 'name',
        })
    );
    dispatch(
        set_arr_value({
            key: 'occupations',
            value: occupations?.data,
            label_name: 'name_ar',
        })
    );
    dispatch(
        set_arr_value({
            key: 'entity_types',
            value: entity_types?.data,
            label_name: 'name_ar',
        })
    );
    dispatch(
        set_arr_value({
            key: 'vendors_types',
            value: vendors_types?.data,
            label_name: 'name_ar',
        })
    );
    dispatch(
        set_arr_value({
            key: 'customer_types',
            value: customer_types?.data,
            label_name: 'name_ar',
        })
    );
    dispatch(
        set_arr_value({
            key: 'banner_types',
            value: banner_types?.data,
        })
    );
    dispatch(set_account_managers(accountManagers?.data));

    dispatch(set_loading(false));
};

export const loadDistricts = (cityId) => async (dispatch) => {
    dispatch(set_loading(true));
    dispatch(set_districts_city(cityId));
    if (cityId) {
        const res = await GET({
            endpoint: `/cities/${cityId}/districts`,
            auth: false,
        });
        dispatch(
            set_arr_value({
                key: 'districts',
                value: res?.data,
            })
        );
    } else {
        dispatch(
            set_arr_value({
                key: 'districts',
                value: [],
            })
        );
    }
    dispatch(set_loading(false));
};

export default GlobalsSlice.reducer;
