import { useEffect, useState } from 'react';
import { GET } from '@utils';

export default function useScheduledOrdersQuery(params) {
    const [loading, set_loading] = useState(false);
    const [data, set_data] = useState([]);

    const fetchScheduledOrders = async () => {
        try {
            set_loading(true);

            const res = await GET({
                endpoint: '/scheduled-order',
            });

            if (res?.status_code === 201 || res?.status_code === 200) {
                set_data(res.data?.filter((item) => item.is_active));
            }
        } catch (error) {
            // TODO: handle errors
        } finally {
            set_loading(false);
        }
    };

    useEffect(() => {
        fetchScheduledOrders();
    }, [params]);

    return { loading, data };
}
