import React from 'react';
import {
    ViewHeader,
    Card,
    Input,
    Button,
    Toggler,
    Row,
    Col,
    Select,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { POST, GET } from '@utils';
import { Redirect } from 'react-router';

export default function AdminsAddView() {
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(null);
    const [errors, setErrors] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            // set_loading(true);
            const res = await GET({
                endpoint: '/user/roles',
            });
            set_loading(false);
            set_users_group((prev) => {
                return {
                    ...prev,
                    options:
                        res?.data?.map?.((r) => ({
                            id: r.id,
                            label: r.name,
                        })) || [],
                };
            });
        };
        fetchData();
    }, []);

    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val }),
    });
    const [first_name, set_first_name] = React.useState({
        label: 'First name',
        value: '',
        setValue: (val) => set_first_name({ ...first_name, value: val }),
    });
    const [last_name, set_last_name] = React.useState({
        label: 'Last name',
        value: '',
        setValue: (val) => set_last_name({ ...last_name, value: val }),
    });
    const [email, set_email] = React.useState({
        label: 'email',
        value: '',
        setValue: (val) => set_email({ ...email, value: val }),
    });
    const [phone, set_phone] = React.useState({
        label: 'Phone',
        value: '',
        // prefix:'+966',
        setValue: (val) => set_phone({ ...phone, value: val }),
    });
    const [password, set_password] = React.useState({
        label: 'Password',
        value: '',
        type: 'password',
        setValue: (val) => set_password({ ...password, value: val }),
    });
    const [confirm_password, set_confirm_password] = React.useState({
        label: 'Confirm password',
        value: '',
        type: 'password',
        setValue: (val) => set_confirm_password({ ...confirm_password, value: val }),
    });
    const [users_group, set_users_group] = React.useState({
        label: 'Users group',
        options: [],
        value: [],
        getOptionValue: (option) => option.id,
        isMulti: true,
        onChange: (val) => set_users_group((prev) => ({ ...prev, value: val })),
    });

    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (
            !first_name.value ||
            first_name.value.length < 3 ||
            !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(first_name.value)
        )
            newErrors.first_name =
                'The first name must be at least 3 characters and must be contained only letters and spaces ';
        if (!last_name.value || !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(last_name.value))
            newErrors.last_name =
                'The last name field is required and must be contained only letters and spaces.';
        if (!email.value) {
            newErrors.email = 'The email field is required.';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value)) {
            newErrors.email = 'Invalid email address.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(email.value)) {
            newErrors.email = 'Email should not contain Arabic letters';
        }
        if (!phone.value || !/^5[0-9]{1}[0-9]{7}$/i.test(phone.value)) {
            newErrors.phone =
                'The mobile field is required. It should start with 5 followed by 8 digits.';
        }
        if (!password.value) {
            newErrors.password = 'The password field is required.';
        } else if (password.value.length < 8 || password.value.length > 16) {
            newErrors.password = 'Password must be between 8 and 16 characters long.';
        } else if (
            !/[A-Z]/.test(password.value) ||
            !/[a-z]/.test(password.value) ||
            !/\d/.test(password.value)
        ) {
            newErrors.password =
                'Password must include at least one uppercase letter, one lowercase letter, and one digit.';
        }
        if (password.value !== confirm_password.value) {
            newErrors.confirm_password = 'The password confirmation does not match.';
        }
        if (users_group.value.length === 0) newErrors.users_group = 'The roles field is required.';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [first_name, last_name, phone, password, email, users_group, confirm_password]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const res = await POST({
                endpoint: '/user',
                data: {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    mobile: phone.value,
                    password: password.value,
                    password_confirmation: confirm_password.value,
                    is_active: status.checked ? 1 : 0,
                    roles: users_group.value.length ? users_group.value?.map((g) => g.id) : [],
                },
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_created(res?.data.id);
            }
        }
    };

    if (created) {
        return <Redirect to="/admins/list" />;
    }

    return (
        <div className="admins-form-view">
            <CustomPrompt>
                <ViewHeader title="Add New admin" renderBackArrow>
                    <Sop id="30183" />
                    <Button variant="primary" onClick={submit}>
                        Create
                    </Button>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-3">
                        <Row>
                            <Col xs={12} md={8} lg={6}>
                                <Toggler {...status} />
                                <Row>
                                    <Col xs={12}>
                                        <Input {...first_name} />
                                        {loading && validation_errors.first_name && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.first_name}
                                            </p>
                                        )}
                                    </Col>
                                    <Col xs={12}>
                                        <Input {...last_name} />
                                        {loading && validation_errors.last_name && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.last_name}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <Input {...email} />
                                {loading && validation_errors.email && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.email}
                                    </p>
                                )}
                                <Input {...phone} />
                                {loading && validation_errors.phone && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.phone}
                                    </p>
                                )}
                                <Input {...password} />
                                {loading && validation_errors.password && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.password}
                                    </p>
                                )}
                                <Input {...confirm_password} />
                                {loading && validation_errors.confirm_password && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.confirm_password}
                                    </p>
                                )}
                                <Select {...users_group} />
                                {loading && validation_errors.users_group && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.users_group}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
