import React from 'react';

const TextWitSub = ({ text, sub }) => {
    return (
        <div>
            <small>{text}</small>
            <br />
            <strong style={{ height:'2.25rem', lineHeight: '2.25rem' }}>{sub}</strong>
        </div>
    );
};

export default TextWitSub;