import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export default function ProtectedRoute({ children, ...rest }) {
    const token = useSelector(state => state.AuthSlice.token);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                return token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
}
