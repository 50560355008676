/* eslint-disable max-len */

import React from 'react';
import {
    Input,
    Button,
    Row,
    Col,
    Textarea,
    Select,
    Checkbox,
    Slug,
    FileUpload,
    ApiAsyncSelect,
    Radio,
    ShowErrors,
    CustomPrompt,
    CreatableTextSelect,
    Tooltip,
    OverlayTrigger,
    CriticalActionButton,
} from '@components';
import { obj2formdata, POST, confirmationMessage, downloadFileFromAPI } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import helpIcon from '@assets/icons/help.svg';
import { loadVendorData } from '../../../../store/slices/merchant.slice';
import { ZATCA_IDENTIFICATION_TYPES } from '../../constants';

export default function GeneralTab() {
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const dispatch = useDispatch();
    const vendor = useSelector((state) => state.VendorsSlice.vendor);
    const [loading, set_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);

    const [is_all_entity_types_selected, set_is_all_entity_types_selected] = React.useState('all');
    const [select_all, set_select_all] = React.useState(false);

    const [entity_types, set_entity_types] = React.useState({
        options: [],
        getOptionValue: (option) => option.id,
        value: [],
        isMulti: true,
        onChange: (val) => set_entity_types((prev) => ({ ...prev, value: val })),
    });

    React.useEffect(() => {
        // set globals
        set_entity_types({ ...entity_types, options: GlobalsSlice.entity_types });
        set_country({ ...country, options: GlobalsSlice.countries });
        set_city({ ...city, options: GlobalsSlice.ksa_cities });
        set_plan({ ...plan, options: GlobalsSlice.plans });
        set_shipping_method({
            ...shipping_method,
            options: GlobalsSlice.shippingMethods,
        });
        set_vendor_categories({
            ...vendor_categories,
            options: GlobalsSlice.vendors_categories,
        });
        // set_account_manager({
        //     ...account_manager,
        //     options: GlobalsSlice.accountManagers
        // });
        set_vendor_type_id({
            ...vendor_type_id,
            options: GlobalsSlice.vendors_types,
        });
    }, [GlobalsSlice]);

    React.useEffect(() => {
        if (vendor.id) {
            commercial_registration_number.setValue(vendor.commercial_registration_number);
            company_name_ar.setValue(vendor.company_name_ar);
            company_name_en.setValue(vendor.company_name_en);
            description_ar.setValue(vendor.description_ar);
            description_en.setValue(vendor.description_en);
            establishment_ar.setValue(vendor.establishment_ar);
            establishment_en.setValue(vendor.establishment_en);
            story_ar.setValue(vendor.story_ar);
            story_en.setValue(vendor.story_en);
            email.setValue(vendor.email);

            tax.onChange(vendor.is_taxable);
            is_vendor_cbv_enabled.onChange(vendor.is_vendor_cbv_enabled);
            is_crm_feature_enabled.onChange(vendor.is_crm_feature_enabled);
            uses_vendor_app.onChange(vendor.uses_vendor_app);
            vendor_type_id.onChange({
                id: vendor.vendor_type?.id,
                label: vendor.vendor_type?.name_ar,
            });
            state.onChange(state.options.find((item) => item.value === vendor.state));

            vat_account_number.setValue(vendor.vat_account_number);
            instagram_url.setValue(vendor.instagram_url);
            facebook_url.setValue(vendor.facebook_url);
            linkedin_url.setValue(vendor.linkedin_url);
            twitter_url.setValue(vendor.twitter_url);

            legal_company_name_ar.setValue(vendor.legal_company_name_ar);
            legal_company_name_en.setValue(vendor.legal_company_name_en);
            vendor_min_amount.setValue(vendor.minimum_order_amount);
            mobile.setValue(vendor.mobile);

            page_title_ar.setValue(vendor.seo_page_title_ar);
            page_title_en.setValue(vendor.seo_page_title_en);

            meta_description_ar.setValue(vendor.seo_meta_description_ar);
            meta_description_en.setValue(vendor.seo_meta_description_en);
            visibility_priority.setValue(vendor?.visibility_priority);
            if (vendor?.personalized_entity_types?.length !== GlobalsSlice.entity_types.length) {
                set_is_all_entity_types_selected('selected');
                entity_types.onChange(
                    vendor?.personalized_entity_types.map((entity) => ({
                        id: entity.id,
                        label: entity.name_en,
                    })),
                );
            } else {
                set_is_all_entity_types_selected('all');
                entity_types.onChange([]);
            }
            vendor.seo_link && slug.setValue(vendor.seo_link);

            vendor.seo_meta_keywords_en &&
                set_meta_tag_en(
                    vendor.seo_meta_keywords_en
                        .split(',')
                        .map((tag) => ({ label: tag, value: tag })),
                );

            vendor.seo_meta_keywords_ar &&
                set_meta_tag_ar(
                    vendor.seo_meta_keywords_ar
                        .split(',')
                        .map((tag) => ({ label: tag, value: tag })),
                );

            status.onChange({
                id: vendor.status ? '1' : '0',
                label: vendor.status ? 'فعال' : 'غير فعال',
            });
            vendor.country &&
                country.onChange({
                    id: vendor.country.id,
                    label: vendor.country.name_ar,
                });
            vendor.city &&
                city.onChange({
                    id: vendor.city.id,
                    label: vendor.city.name_ar,
                });
            // const { first_name, last_name, id } = vendor.account_manager || {};
            // vendor.account_manager &&
            //     account_manager.onChange({
            //         id: id,
            //         label: `${first_name} ${last_name}`
            //     });
            vendor?.assigned_admin_user
                ? set_assigned_admin_user({
                      id: vendor.assigned_admin_user.id,
                      // eslint-disable-next-line max-len
                      label: `${vendor.assigned_admin_user.first_name} ${vendor.assigned_admin_user.last_name}`,
                  })
                : set_assigned_admin_user(null);
            vendor.plan &&
                plan.onChange({
                    id: vendor.plan.id,
                    label: vendor.plan.name_ar,
                });
            vendor.first_mile &&
                first_mile.onChange({
                    id: vendor.first_mile,
                    label: vendor.first_mile,
                });
            vendor.second_mile &&
                second_mile.onChange({
                    id: vendor.second_mile,
                    label: vendor.second_mile,
                });
            vendor.last_mile &&
                last_mile.onChange({
                    id: vendor.last_mile,
                    label: vendor.last_mile,
                });
            vendor.plan_type &&
                plan_type.onChange({
                    value: vendor.plan_type?.code,
                    label: vendor.plan_type?.code,
                });
            vendor.shipping_type &&
                shipping_type.onChange({
                    value: vendor.shipping_type?.replace(/ /g, '_'),
                    label: vendor.shipping_type,
                });
            vendor.shipping_method &&
                shipping_method.onChange({
                    id: vendor.shipping_method.id,
                    label: vendor.shipping_method.name_ar,
                });
            vendor_categories.onChange(
                vendor?.categories?.map((c) => ({
                    id: c.id,
                    label: c.name_ar,
                })),
            );
            // document_id.setValue(vendor.free_work_document_number);
            zatca_type.onChange(
                ZATCA_IDENTIFICATION_TYPES.find(
                    (t) => t.value === vendor.zatca_identification_type,
                ),
            );
            zatca_id.setValue(vendor.zatca_identification_id);
            // meta_tag_ar.onChange({
            //     id: vendor.shipping_method.id,
            //     label: vendor.shipping_method.name_ar,
            // });

            if (vendor.contract_document) {
                contract_doc.label = (
                    <>
                        Contract document
                        <Button
                            variant="link"
                            onClick={() => {
                                downloadFileFromAPI(vendor.contract_document);
                            }}
                        >
                            Preview uploaded contract
                        </Button>
                    </>
                );
            }
        }
    }, [vendor, GlobalsSlice.entity_types]);

    React.useEffect(() => {
        const cbv_plan = GlobalsSlice.all_plans.find((p) => p.percentage === vendor.cbv_take_rate);
        const value = cbv_plan ? { id: cbv_plan.percentage, label: cbv_plan.name_en } : null;
        set_cbv_take_rate({
            ...cbv_take_rate,
            value,
            options: GlobalsSlice.all_plans.map((p) => ({
                id: p.percentage,
                label: p.name_en,
            })),
        });

        const vendor_cvb_plan = GlobalsSlice.all_plans.find(
            (p) => p.percentage === vendor.vendor_cbv_take_rate,
        );
        const vendor_cbv_value = vendor_cvb_plan
            ? { id: vendor_cvb_plan.percentage, label: vendor_cvb_plan.name_en }
            : null;
        set_vendor_cbv_take_rate({
            ...vendor_cbv_take_rate,
            value: vendor_cbv_value,
            options: GlobalsSlice.all_plans.map((p) => ({
                id: p.percentage,
                label: p.name_en,
            })),
        });
    }, [vendor, GlobalsSlice.all_plans.length]);

    {
        /* Arabic fields */
    }
    const [legal_company_name_ar, set_legal_company_name_ar] = React.useState({
        label: 'Legal company name',
        value: '',
        setValue: (val) => set_legal_company_name_ar({ ...legal_company_name_ar, value: val }),
    });
    const [company_name_ar, set_company_name_ar] = React.useState({
        label: 'Company Name',
        value: '',
        setValue: (val) => set_company_name_ar({ ...company_name_ar, value: val }),
    });
    const [description_ar, set_description_ar] = React.useState({
        label: 'Description',
        value: '',
        setValue: (val) => set_description_ar({ ...description_ar, value: val }),
    });
    const [logo_ar, set_logo_ar] = React.useState({
        label: 'Merchant logo',
        files: [],
        setFiles: (val) => set_logo_ar({ ...logo_ar, files: val }),
    });
    const [banner_image_ar, set_banner_image_ar] = React.useState({
        label: 'Merchant banner image',
        files: [],
        setFiles: (val) => set_banner_image_ar({ ...banner_image_ar, files: val }),
    });
    const [establishment_ar, set_establishment_ar] = React.useState({
        label: 'Establishment',
        value: '',
        setValue: (val) => set_establishment_ar({ ...establishment_ar, value: val }),
    });
    const [story_ar, set_story_ar] = React.useState({
        label: 'Story',
        value: '',
        setValue: (val) => set_story_ar({ ...story_ar, value: val }),
    });
    {
        /* English fields  */
    }
    const [legal_company_name_en, set_legal_company_name_en] = React.useState({
        label: 'Legal company name',
        value: '',
        setValue: (val) => set_legal_company_name_en({ ...legal_company_name_en, value: val }),
    });
    const [company_name_en, set_company_name_en] = React.useState({
        label: 'Company Name',
        value: '',
        setValue: (val) => set_company_name_en({ ...company_name_en, value: val }),
    });
    const [description_en, set_description_en] = React.useState({
        label: 'Description',
        value: '',
        setValue: (val) => set_description_en({ ...description_en, value: val }),
    });
    const [logo_en, set_logo_en] = React.useState({
        label: 'Merchant logo',
        files: [],
        setFiles: (val) => set_logo_en({ ...logo_en, files: val }),
    });
    const [banner_image_en, set_banner_image_en] = React.useState({
        label: 'Merchant banner image',
        files: [],
        setFiles: (val) => set_banner_image_en({ ...banner_image_en, files: val }),
    });
    const [establishment_en, set_establishment_en] = React.useState({
        label: 'Establishment',
        value: '',
        setValue: (val) => set_establishment_en({ ...establishment_en, value: val }),
    });
    const [story_en, set_story_en] = React.useState({
        label: 'Story',
        value: '',
        setValue: (val) => set_story_en({ ...story_en, value: val }),
    });
    {
        /* Generic fields  */
    }
    const [commercial_registration_number, set_commercial_registration_number] = React.useState({
        label: 'Commercial Registration Number',
        value: '',
        type: 'number',
        errorMsg: '',
        showError: false,
        onBlur: (e) => {
            const value = e.target.value;

            const is_valid = /^\d{10}$/.test(value);
            const showError = !is_valid;
            const errorMsg = !is_valid && 'Enter 10 digits only';

            set_commercial_registration_number({
                ...commercial_registration_number,
                value,
                showError,
                errorMsg,
            });
        },
        setValue: (val) =>
            set_commercial_registration_number({
                ...commercial_registration_number,
                value: val,
            }),
    });
    const [country, set_country] = React.useState({
        label: 'Country',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_country((prev) => ({ ...prev, value: val })),
    });
    const [city, set_city] = React.useState({
        label: 'City',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_city((prev) => ({ ...prev, value: val })),
    });
    const [status, set_status] = React.useState({
        label: 'Status',
        options: [
            { id: '1', label: 'فعال' },
            { id: '0', label: 'غير فعال' },
        ],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_status({ ...status, value: val }),
    });
    const [email, set_email] = React.useState({
        label: 'Email',
        type: 'email',
        value: '',
        setValue: (val) => set_email({ ...email, value: val }),
    });
    const [mobile, set_mobile] = React.useState({
        label: 'Mobile number',
        value: '',
        prefix: '+966',
        setValue: (val) => set_mobile({ ...mobile, value: val }),
    });
    const [shipping_method, set_shipping_method] = React.useState({
        label: 'Shipping method',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_shipping_method((prev) => ({ ...prev, value: val })),
    });

    const [vendor_type_id, set_vendor_type_id] = React.useState({
        label: 'Merchant type',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_type_id((prev) => ({ ...prev, value: val })),
    });

    // const [account_manager, set_account_manager] = React.useState({
    //     label: 'Account managers',
    //     options: [],
    //     value: '',
    //     getOptionValue: (option) => option.id,
    //     onChange: (val) =>
    //         set_account_manager((prev) => ({ ...prev, value: val }))
    // });
    const [assigned_admin_user, set_assigned_admin_user] = React.useState({});
    const [vendor_min_amount, set_vendor_min_amount] = React.useState({
        label: 'Merchant Minimum Amount',
        type: 'number',
        value: '',
        setValue: (val) => set_vendor_min_amount({ ...vendor_min_amount, value: val }),
    });
    const [vendor_categories, set_vendor_categories] = React.useState({
        label: 'Merchant category',
        options: [],
        value: '',
        isMulti: true,
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_categories((prev) => ({ ...prev, value: val })),
    });
    const [state, set_state] = React.useState({
        label: 'Verification state',
        options: [
            {
                id: 1,
                label: 'Not verified',
                value: 'not_verified',
            },
            {
                id: 2,
                label: 'Verification in progress',
                value: 'verification_in_progress',
            },
            {
                id: 3,
                label: 'Verified',
                value: 'verified',
            },
        ],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_state((prev) => ({ ...prev, value: val })),
    });
    const [tax, setTax] = React.useState({
        label: 'Is taxable',
        checked: false,
        onChange: (val) => setTax({ ...tax, checked: val }),
    });
    const [uses_vendor_app, set_uses_vendor_app] = React.useState({
        label: 'Uses Merchant app',
        checked: true,
        onChange: (val) => set_uses_vendor_app({ ...uses_vendor_app, checked: val }),
    });

    const [vat_account_number, set_vat_account_number] = React.useState({
        label: 'VAT account number',
        type: 'number',
        value: '',
        setValue: (val) => set_vat_account_number({ ...vat_account_number, value: val }),
    });
    const [instagram_url, set_instagram_url] = React.useState({
        label: 'Instagram URL',
        type: 'text',
        value: '',
        setValue: (val) => set_instagram_url({ ...instagram_url, value: val }),
    });
    const [facebook_url, set_facebook_url] = React.useState({
        label: 'Facebook URL',
        type: 'text',
        value: '',
        setValue: (val) => set_facebook_url({ ...facebook_url, value: val }),
    });
    const [linkedin_url, set_linkedin_url] = React.useState({
        label: 'Linkedin URL',
        type: 'text',
        value: '',
        setValue: (val) => set_linkedin_url({ ...linkedin_url, value: val }),
    });
    const [twitter_url, set_twitter_url] = React.useState({
        label: 'Twitter URL',
        type: 'text',
        value: '',
        setValue: (val) => set_twitter_url({ ...twitter_url, value: val }),
    });
    // const [document_id, set_document_id] = React.useState({
    //     label: 'Free work document ID',
    //     value: '',
    //     setValue: (val) => set_document_id({ ...document_id, value: val }),
    // });

    {
        /* Agreement fields  */
    }
    const [plan, set_plan] = React.useState({
        label: 'Plan',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_plan((prev) => ({ ...prev, value: val })),
    });
    const [cbv_take_rate, set_cbv_take_rate] = React.useState({
        label: 'CBV - (Admin) Source Take rate',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_cbv_take_rate((prev) => ({ ...prev, value: val })),
    });
    const [vendor_cbv_take_rate, set_vendor_cbv_take_rate] = React.useState({
        label: 'CBV - (Merchant) source Take rate',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_cbv_take_rate((prev) => ({ ...prev, value: val })),
    });
    const [is_vendor_cbv_enabled, set_is_vendor_cbv_enabled] = React.useState({
        label: 'Is Merchant cbv enabled',
        checked: false,
        onChange: (val) => {
            set_is_vendor_cbv_enabled({ ...is_vendor_cbv_enabled, checked: val });
            if (val) {
                is_crm_feature_enabled.onChange(true);
            }
        },
    });
    const [is_crm_feature_enabled, set_is_crm_feature_enabled] = React.useState({
        label: 'Enabled Retailer Feature',
        checked: false,
        onChange: (val) => set_is_crm_feature_enabled((prev) => ({ ...prev, checked: val })),
    });
    const shipmentAgreement = [
        {
            id: 'Disty',
            label: 'Disty',
        },
        {
            id: 'Vendor',
            label: 'Merchant',
        },
    ];

    const [first_mile, set_first_mile] = React.useState({
        label: 'FM',
        options: shipmentAgreement,
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_first_mile((prev) => ({ ...prev, value: val })),
    });
    const [second_mile, set_second_mile] = React.useState({
        label: 'MM',
        options: shipmentAgreement,
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_second_mile((prev) => ({ ...prev, value: val })),
    });
    const [last_mile, set_last_mile] = React.useState({
        label: 'LM',
        options: shipmentAgreement,
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_last_mile((prev) => ({ ...prev, value: val })),
    });
    const [plan_type, set_plan_type] = React.useState({
        label: 'Plan type',
        options: [
            {
                value: 'single',
                label: 'Single',
            },
            {
                value: 'multiple',
                label: 'Multiple',
            },
        ],
        value: '',
        // getOptionValue: (option) => option.id,
        onChange: (val) => set_plan_type((prev) => ({ ...prev, value: val })),
    });
    const [shipping_type, set_shipping_type] = React.useState({
        label: 'Shipping type',
        options: [
            {
                value: 'direct_to_consumer',
                label: 'direct to consumer',
            },
            {
                value: 'first_mile',
                label: 'first mile',
            },
            {
                value: 'first_mile_last_mile',
                label: 'first mile last mile',
            },
        ],
        value: '',
        // getOptionValue: (option) => option.id,
        onChange: (val) => set_shipping_type((prev) => ({ ...prev, value: val })),
    });
    const [contract_doc, set_contract_doc] = React.useState({
        label: 'Contract document',
        files: [],
        acceptedMimeTypes:
            '.pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        hintMessage: 'Only pdf and doc/docx files are accepted.',
        setFiles: (val) => set_contract_doc({ ...contract_doc, files: val }),
    });

    {
        /* SEO fields  */
    }
    const [slug, set_slug] = React.useState({
        label: 'Merchant link',
        prefix: '/',
        value: '',
        setValue: (val) => set_slug({ ...slug, value: val }),
    });
    const [page_title_ar, set_page_title_ar] = React.useState({
        label: 'Page title',
        value: '',
        setValue: (val) => set_page_title_ar({ ...page_title_ar, value: val }),
    });
    const [meta_tag_ar, set_meta_tag_ar] = React.useState([]);
    const [meta_description_ar, set_meta_description_ar] = React.useState({
        label: 'Meta description',
        value: '',
        setValue: (val) => set_meta_description_ar({ ...meta_description_ar, value: val }),
    });
    const [page_title_en, set_page_title_en] = React.useState({
        label: 'Page title',
        value: '',
        setValue: (val) => set_page_title_en({ ...page_title_en, value: val }),
    });
    const [meta_tag_en, set_meta_tag_en] = React.useState([]);
    const [meta_description_en, set_meta_description_en] = React.useState({
        label: 'Meta description',
        value: '',
        setValue: (val) => set_meta_description_en({ ...meta_description_en, value: val }),
    });
    const [visibility_priority, set_visibility_priority] = React.useState({
        label: 'visibility priority',
        value: '',
        setValue: (val) => set_visibility_priority({ ...visibility_priority, value: val }),
    });

    const [zatca_type, set_zatca_type] = React.useState({
        label: 'Zatca Identification Type',
        options: ZATCA_IDENTIFICATION_TYPES,
        value: '',
        getOptionValue: (option) => option.value,
        onChange: (val) => set_zatca_type((prev) => ({ ...prev, value: val })),
    });

    const [zatca_id, set_zatca_id] = React.useState({
        label: 'Zatca Identification ID',
        value: '',
        setValue: (val) => set_zatca_id({ ...zatca_id, value: val }),
    });
    const [validation_errors, set_validation_errors] = React.useState({});
   
    const validate = () => {
        const newErrors = {};
        if (!legal_company_name_ar.value)
            newErrors.legal_company_name_ar = 'The Legal Company Name AR field is required.';
        if (!legal_company_name_en.value)
            newErrors.legal_company_name_en = 'The Legal Company Name EN field is required.';
        if (!company_name_ar.value)
            newErrors.company_name_ar = 'The Company Name AR field is required.';
        if (!company_name_en.value)
            newErrors.company_name_en = 'The Company Name EN field is required.';
        if (!zatca_id.value) newErrors.zatca_id = 'The Zatca Identification ID field is required.';
        if (!visibility_priority.value) newErrors.visibility_priority = 'The visibility priority field is required.';
        if (!vendor_min_amount.value && vendor_min_amount.value!==0) newErrors.vendor_min_amount = 'The Minimum Order Amount field is required.';
        if (!mobile.value) newErrors.mobile = 'The Mobile field is required.';
        else if (!/^5[0-9]{1}[0-9]{7}$/i.test(mobile.value))
            newErrors.mobile =
                'Invalid phone number format.Please enter a valid Saudi mobile number.';
        if (!vat_account_number.value)
            newErrors.vat_account_number = 'The Vat Account Number field is required.';
      if (!commercial_registration_number.value) {
            newErrors.commercial_registration_number =
                'The Commercial Registration Number field is required.';
        } else if (commercial_registration_number.value.length !== 10) {
            newErrors.commercial_registration_number =
                'The Commercial Registration Number must be exactly 10 digits.';
        }

        if (!email.value) {
            newErrors.email = 'The email field is required.';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value)) {
            newErrors.email = 'Invalid email address.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(email.value)) {
            newErrors.email = 'Email should not contain Arabic letters';
        }
        
        if (vendor.facebook_url &&!facebook_url.value) {
            newErrors.facebook_url = 'The Facebook URL field is required.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(facebook_url.value)) {
            newErrors.facebook_url = 'Facebook URL should not contain Arabic letters.';
        }
    
        if (vendor.linkedin_url && !linkedin_url.value) {
            newErrors.linkedin_url = 'The LinkedIn URL field is required.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(linkedin_url.value)) {
            newErrors.linkedin_url = 'LinkedIn URL should not contain Arabic letters.';
        }
        if (vendor.twitter_url && !twitter_url.value) {
            newErrors.twitter_url = 'The Twitter URL field is required.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(twitter_url.value)) {
            newErrors.twitter_url = 'Twitter URL should not contain Arabic letters.';
        }
    
        if (vendor.instagram_url && !instagram_url.value) {
            newErrors.instagram_url = 'The Instagram URL field is required.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(instagram_url.value)) {
            newErrors.instagram_url = 'Instagram URL should not contain Arabic letters.';
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Validate on value change
    React.useEffect(() => {
        validate();
    }, [zatca_id,
        email,
        company_name_ar,
        company_name_en,
        legal_company_name_ar,
        legal_company_name_en,
        mobile,
        commercial_registration_number,
        vat_account_number,
        vendor_min_amount,
        visibility_priority,
        facebook_url,
        twitter_url,linkedin_url,instagram_url
    ]);
    console.log(validation_errors);
    const [errors, setErrors] = React.useState([]);

    const handleSubmit = async () => {
        set_loading(true) ;
        if (!validate()) return ;
        const data = obj2formdata(
            {
                ...(!!commercial_registration_number.value && {
                    commercial_registration_number: commercial_registration_number.value,
                }),
                country_id: country.value?.id || null,
                city_id: city.value?.id || null,
                legal_company_name_en: legal_company_name_en.value,
                legal_company_name_ar: legal_company_name_ar.value,
                company_name_en: company_name_en.value,
                company_name_ar: company_name_ar.value,
                mobile: mobile.value,
                email: email.value,
                status: status.value?.id ?? '0',
                plan_id: plan.value?.id || null,
                first_mile: first_mile?.value?.id || null,
                second_mile: second_mile?.value?.id || null,
                last_mile: last_mile?.value?.id || null,
                // invoice_issuance_type: vendor?.invoice_issuance_type?.code,
                plan_type: plan_type.value?.value || null,
                shipping_type: shipping_type.value?.value || null,
                cbv_take_rate: cbv_take_rate.value?.id || null,
                vendor_cbv_take_rate: vendor_cbv_take_rate.value?.id || null,
                // account_manager_id: account_manager.value?.id || null,
                assigned_admin_user_id: assigned_admin_user?.id || null,
                shipping_method_id: shipping_method.value?.id || null,
                minimum_order_amount: vendor_min_amount.value,
                description_en: description_en.value || '',
                description_ar: description_ar.value || '',
                establishment_ar: establishment_ar.value || '',
                establishment_en: establishment_en.value || '',
                story_ar: story_ar.value || '',
                story_en: story_en.value || '',
                is_taxable: tax.checked ? '1' : '0',
                is_vendor_cbv_enabled: is_vendor_cbv_enabled.checked ? '1' : '0',
                is_crm_feature_enabled: is_crm_feature_enabled.checked ? '1' : '0',
                uses_vendor_app: uses_vendor_app.checked ? '1' : '0',
                vendor_type_id: vendor_type_id?.value?.id || null,
                state: state?.value?.value || null,
                ...(!!vat_account_number.value && {
                    vat_account_number: vat_account_number.value,
                }),
                instagram_url: instagram_url.value,
                facebook_url: facebook_url.value,
                linkedin_url: linkedin_url.value,
                twitter_url: twitter_url.value,
                seo_link: slug.value,
                seo_page_title_en: page_title_en.value,
                disty_pay_app_take_rate: vendor.disty_pay_app_take_rate,
                disty_pay_down_payment: vendor.disty_pay_down_payment,
                seo_meta_keywords_en: meta_tag_en.map((tag) => tag.label).join(','),
                seo_meta_description_en: meta_description_en.value,
                seo_page_title_ar: page_title_ar.value,
                seo_meta_keywords_ar: meta_tag_ar.map((tag) => tag.label).join(','),
                seo_meta_description_ar: meta_description_ar.value,
                // ...(!!document_id.value && {
                //     free_work_document_number: document_id.value,
                // }),
                visibility_priority: visibility_priority.value,
                ...(!!zatca_type?.value?.value && {
                    zatca_identification_type: zatca_type?.value?.value,
                }),
                ...(!!zatca_id?.value && {
                    zatca_identification_id: zatca_id?.value,
                }),
            },
            { acceptEmptyString: true },
        );

        if (vendor_categories.value.length) {
            vendor_categories.value.forEach((c, i) => {
                data.append('categories[' + i + ']', c.id);
            });
        }

        if (is_all_entity_types_selected === 'selected') {
            entity_types?.value.forEach((e, i) => {
                data.append('personalized_entity_types[' + i + ']', e.id);
            });
        }
        if (is_all_entity_types_selected === 'all') {
            GlobalsSlice.entity_types?.forEach((e, i) => {
                data.append('personalized_entity_types[' + i + ']', e.id);
            });
        }

        if (logo_ar.files.length) data.append('logo_ar', logo_ar.files[0]);
        if (logo_en.files.length) data.append('logo_en', logo_en.files[0]);
        if (banner_image_ar.files.length) data.append('banner_image_ar', banner_image_ar.files[0]);
        if (banner_image_en.files.length) data.append('banner_image_en', banner_image_en.files[0]);
        if (contract_doc.files.length) data.append('contract_document', contract_doc.files[0]);

        data.append('_method', 'PATCH');
        set_loading(true);
        const res = await POST({
            endpoint: `/vendors/${vendor.id}`,
            data,
        });
        set_loading(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 200) {
            set_updated('Success');
            dispatch(loadVendorData(vendor.id));
            setTimeout(() => {
                set_updated(null);
            }, 2000);
        }
    };
    const Guideline = (
        <div className="vendor-story-instructions mx-5">
            <h2 className="text-center mt-4 mb-3 divider-title">Merchant Story Guideline</h2>
            <ol>
                <li className="my-4">
                    In the <span className="text-danger">Merchant description</span>, you should focus
                    on writing 2 sentences (max) that introduce the visitor to the Merchant and the
                    Merchant offerings.
                </li>
                <li className="my-4">
                    In the <span className="text-danger">Merchant establishment</span>, you should
                    focus on providing the date of establishment of the Merchant. Maybe combine it
                    with a short 1 sentence to give more info to the shopper.
                </li>
                <li className="my-4">
                    In the <span className="text-danger">Merchant story</span>, you should write an
                    anecdote that tells the shopper more about the Merchant and convince him to buy
                    from this Merchant.
                </li>
            </ol>
        </div>
    );

    return (
        <div className="vendors-single-view">
            {/* General fields */}
            <CustomPrompt>
                <div className="general-fields">
                    {/* Arabic fields */}
                    <h3 className="divider-title">Arabic generic content</h3>
                    <Row>
                        <Col lg={6}>
                            <Input {...legal_company_name_ar} />
                            {loading && validation_errors.legal_company_name_ar && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.legal_company_name_ar}
                                        </p>
                                    )}
                            <Input {...company_name_ar} />
                            {loading && validation_errors.company_name_ar && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.company_name_ar}
                                        </p>
                                    )}
                            <Textarea {...description_ar} />
                            <Textarea {...establishment_ar} />
                            <Textarea {...story_ar} />
                        </Col>
                        <Col lg={6}>
                            <FileUpload {...logo_ar} />
                            <ul className="thumpnails">
                                <li>
                                    <img src={vendor.logo_ar} alt="Slide image" />
                                </li>
                            </ul>
                            <FileUpload {...banner_image_ar} />
                            <ul className="thumpnails">
                                <li>
                                    <img src={vendor.banner_image_ar} alt="Slide image" />
                                </li>
                            </ul>
                            {Guideline}
                        </Col>
                    </Row>
                    {/* English fields  */}
                    <h3 className="divider-title">English generic content</h3>
                    <Row>
                        <Col lg={6}>
                            <Input {...legal_company_name_en} />
                            {loading && validation_errors.legal_company_name_en && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.legal_company_name_en}
                                        </p>
                                    )}
                            <Input {...company_name_en} />
                            {loading && validation_errors.company_name_en && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.company_name_en}
                                        </p>
                                    )}
                            <Textarea {...description_en} />
                            <Textarea {...establishment_en} />
                            <Textarea {...story_en} />
                        </Col>
                        <Col lg={6}>
                            <FileUpload {...logo_en} />
                            <ul className="thumpnails">
                                <li>
                                    <img src={vendor.logo_en} alt="Slide image" />
                                </li>
                            </ul>
                            <FileUpload {...banner_image_en} />
                            <ul className="thumpnails">
                                <li>
                                    <img src={vendor.banner_image_en} alt="Slide image" />
                                </li>
                            </ul>
                            {Guideline}
                        </Col>
                    </Row>

                    {/* Generic fields  */}
                    <h3 className="divider-title">Generic content</h3>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6}>
                                    <Select {...zatca_type} />
                                </Col>
                                <Col>
                                    <Input {...zatca_id} />
                                    {loading && validation_errors.zatca_id && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.zatca_id}
                                        </p>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <Input {...commercial_registration_number} />
                                    {loading && validation_errors.commercial_registration_number && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.commercial_registration_number}
                                        </p>
                                    )}
                                </Col>
                                {/* <Col>
                                    <Input {...document_id} />
                                </Col> */}
                            </Row>
                            <Select {...country} />
                            <Select {...city} />
                            <Select {...status} />
                            <Input {...email} />

                            {loading && validation_errors.email&& (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.email}
                                        </p>
                                    )}
                            <Input {...mobile} />
                            {loading && validation_errors.mobile && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.mobile}
                                        </p>
                                    )}
                            <Select {...state} />

                            <Checkbox {...tax} />
                            <Input {...visibility_priority} />
                            {loading && validation_errors.visibility_priority && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.visibility_priority}
                                        </p>
                                    )}
                            <Select {...vendor_type_id} />
                            <Checkbox {...uses_vendor_app} />
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-right" width="300px">
                                        check this flag if the Merchant is confirming his orders using
                                        Disty Merchant app, Uncheck this flag if this Merchant is not
                                        using our Merchant app and we confirm the orders on his behalf
                                    </Tooltip>
                                }
                            >
                                <img src={helpIcon} alt="i" />
                            </OverlayTrigger>
                            {uses_vendor_app.checked &&
                                vendor?.invoice_issuance_type?.code === 'purchase_order' && (
                                    <div className="alert alert-warning">
                                        You must specify at least one invoice days to enable the
                                        system to create purchase orders for this Merchant - Go to{' '}
                                        {'"Invoice Settings Tab"'}
                                        then select at least one day
                                    </div>
                                )}
                        </Col>
                        <Col lg={6}>
                            <Select {...shipping_method} />
                            {/* <Select {...account_manager} /> */}
                            <ApiAsyncSelect
                                label="Merchant account manager"
                                api="/user"
                                onlyFetchActiveRecords={false}
                                placeholder="Type to Add Merchant account manager..."
                                labelProperty={(r) => `${r.first_name} ${r.last_name}`}
                                onChange={set_assigned_admin_user}
                                value={assigned_admin_user}
                            />
                            <Input {...vendor_min_amount} />
                            {loading && validation_errors.vendor_min_amount && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.vendor_min_amount}
                                        </p>
                                    )}
                            <Select {...vendor_categories} />

                            <Input {...vat_account_number} />
                            {loading && validation_errors.vat_account_number && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.vat_account_number}
                                        </p>
                                    )}
                            <Input {...instagram_url} />
                            {loading && validation_errors.instagram_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.instagram_url}
                                        </p>
                                    )}
                            <Input {...facebook_url} />
                            {loading && validation_errors.facebook_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.facebook_url}
                                        </p>
                                    )}
                            <Input {...linkedin_url} />
                            {loading && validation_errors.linkedin_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.linkedin_url}
                                        </p>
                                    )}
                            <Input {...twitter_url} />
                            {loading && validation_errors.twitter_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.twitter_url}
                                        </p>
                                    )}
                        </Col>
                    </Row>
                </div>

                {/* entity types */}
                <div className="entity-types">
                    <h3 className="divider-title">Entity types</h3>
                    <Row>
                        <Col lg={12}>
                            <div className="my-3">
                                <Radio
                                    onChange={set_is_all_entity_types_selected}
                                    option={is_all_entity_types_selected}
                                    label="All Entity"
                                    value="all"
                                    name="is-all-banner-entity-selected"
                                />
                                <Radio
                                    onChange={set_is_all_entity_types_selected}
                                    option={is_all_entity_types_selected}
                                    label="Select specific entity"
                                    value="selected"
                                    name="is-all-banner-entity-selected"
                                />

                                {is_all_entity_types_selected === 'selected' && (
                                    <>
                                        <Select {...entity_types} />
                                        <Button
                                            onClick={() => {
                                                set_select_all(!select_all);
                                                select_all
                                                    ? entity_types.onChange([])
                                                    : entity_types.onChange(
                                                          GlobalsSlice.entity_types,
                                                      );
                                            }}
                                            variant={select_all ? 'danger' : 'primary'}
                                        >
                                            {select_all ? 'undo' : 'select all'}{' '}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Agreement fields */}
                <div className="agreement-fields">
                    <h3 className="divider-title">Agreement</h3>
                    <Row>
                        <Col lg={6}>
                            <Select {...plan_type} />
                            <Select {...plan} />

                            <Select {...cbv_take_rate} />
                            <Select {...vendor_cbv_take_rate} />
                            <Select {...first_mile} />
                            <Select {...second_mile} />
                            <Select {...last_mile} />
                            <Select {...shipping_type} />
                        </Col>
                        <Col lg={6}>
                            <FileUpload {...contract_doc} />
                            <Checkbox {...is_vendor_cbv_enabled} />
                            {/* <div className="alert alert-warning">
                                Enabling this flag will give the Merchant the ability to add Customers
                                and add orders right from the Merchant app
                            </div> */}
                            <Checkbox
                                {...is_crm_feature_enabled}
                                disabled={is_vendor_cbv_enabled.checked}
                            />
                        </Col>
                    </Row>
                </div>

                {/* SEO fields */}
                <div className="seo-fields">
                    <h3 className="divider-title">SEO</h3>
                    <Slug {...slug} />
                    <Row>
                        <Col lg={6}>
                            <h3 className="divider-title">Arabic SEO content</h3>
                            <Input {...page_title_ar} />
                            <CreatableTextSelect
                                label="Meta tags"
                                value={meta_tag_ar}
                                setValue={set_meta_tag_ar}
                            />
                            <Textarea {...meta_description_ar} />
                        </Col>
                        <Col lg={6}>
                            <h3 className="divider-title">English SEO content</h3>
                            <Input {...page_title_en} />
                            <CreatableTextSelect
                                label="Meta tags"
                                value={meta_tag_en}
                                setValue={set_meta_tag_en}
                            />
                            <Textarea {...meta_description_en} />
                        </Col>
                    </Row>
                </div>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                {updated && <div className="alert alert-success">{updated}</div>}
                <CriticalActionButton
                   
                    onSubmit={() => {
                        vendor?.status ? handleSubmit() : confirmationMessage(handleSubmit);
                    }}
                >
                    Save changes
                </CriticalActionButton>
            </CustomPrompt>
        </div>
    );
}
