import React from 'react';
import { Icon, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useCallback } from 'react';

export default function InputFilter({ value = null, set_value = () => {} }) {
    const onChange = useCallback((value) => set_value(value), []);

    return (
        <TextField
            value={value}
            onChange={(value) => {
                onChange(value);
            }}
            autoComplete="off"
            placeholder="Search"
            prefix={<Icon source={SearchMinor} />}
        />
    );
}
