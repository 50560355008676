import copyIcon from '@assets/icons/copy.svg';
import { toast } from 'react-toastify';

const CopyToClipboard = ({ text }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(text);
        toast.info('Copied to clipboard');
    };

    return (
        <div className="copy-to-clipboard">
            <img src={copyIcon} alt="copy to clipboard" onClick={handleCopy} />
        </div>
    );
};

export default CopyToClipboard;
