import React, { useCallback } from 'react';
import {
    ViewHeader,
    DataTable,
    Card,
    Link,
    Button,
    StatusBlock,
    Filters,
    Sop,
    Tabs,
    PercentageIndicator,
    Modal,
    ShowErrors,
} from '@components';
import { formatMoney, formatDate, POST, obj2formdata } from '@utils';
import AddPoInvoice from './AddPoInvoice';
import ChangeInvoiceDays from './ChangeInvoiceDays';
import { useDispatch, useSelector } from 'react-redux';
import { loadInvoicesData } from '@slices/invoices.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';
import Recalculate from './Recalculate';
import { setModalStatus } from '@slices/ui.slice';
import useQuery from '@hooks/useQuery';
export const INVOICE_STATUSES = [
    { label: 'All', value: 'all' },
    { label: 'Pending Purchase Orders upload by vendor', value: 1 << 0 },
    { label: 'Submitted by vendor', value: 1 << 1 },
    { label: 'Rejected with re-upload ability', value: 1 << 2 },
    { label: 'Rejected without re-upload ability', value: 1 << 3 },
    { label: 'Approved', value: 1 << 4 },
    { label: 'Paid', value: 1 << 5 },
    { label: 'Partially paid', value: 1 << 8 },
    { label: 'Hold', value: 1 << 6 },
    { label: 'Canceled', value: 1 << 7 },
];
export default function PoInvoicesListView() {
    const InvoicesSlice = useSelector((state) => state.InvoicesSlice);
    const dispatch = useDispatch();
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [tabs, setTabs] = React.useState(
        INVOICE_STATUSES?.map((s) => ({
            name: s.label,
            isActive: s.value === 'all' ? true : false,
            status: s.value,
        })),
    );
    const [updateTableKey, setUpdateTableKey] = React.useState(0);
    let activeTab = tabs.find((x) => x.isActive);
    const { data: { data: payout_percentage } = { data: [] } } = useQuery(
        '/vendors/invoice-requests/payout-percentage-count',
    );

    const fetchData = async () => {
        activeTab.status === 'all'
            ? dispatch(loadInvoicesData({ ...filters, page, records_per_page }))
            : dispatch(
                  loadInvoicesData({
                      ...{ ...filters, states: [activeTab.status] },
                      page,
                      records_per_page,
                  }),
              );
    };

    useDidMountEffect(() => {
        fetchData();
    }, [filters, page, records_per_page, activeTab]);

    const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows.map((row) => row.id) || []);
    };

    const columns = [
        {
            name: 'Purchase order',
            selector: 'id',
            width: '100px',
            cell: (row) => <Link href={`/purchase-orders/${row.id}`}>{row.id}</Link>,
        },
        {
            name: 'Invoice Number',
            selector: 'invoice_number',
            width: '100px',
        },
        {
            name: 'Merchant',
            cell: (row) => (
                <Link href={`/merchants/${row?.vendor?.id}`}>{row?.vendor?.company_name_en}</Link>
            ),
        },
        {
            name: 'Merchant ID',
            cell: (row) => <Link href={`/merchants/${row.vendor?.id}`}>{row.vendor?.id}</Link>,
        },
        /*{
            name: 'Is HORECA',
            cell: (row) => (row.is_horeca ? 'Yes' : 'No'),
        },*/
        {
            name: 'Suborders count',
            selector: 'suborders_count',
            sortable: true,
        },
        {
            name: 'Invoice created date',
            selector: 'created_at',
            sortable: true,
            format: (row) => formatDate(row.created_at),
        },
        {
            name: 'Invoice uploaded date',
            selector: 'invoice_uploaded_date',
            sortable: true,
            format: (row) => formatDate(row.invoice_uploaded_date),
        },
        {
            name: 'Payout date',
            selector: 'payout_date',
            sortable: true,
            format: (row) => formatDate(row.payout_date),
        },
        {
            name: 'Payout deadline',
            selector: 'payout_remaining_time_percentage',
            sortable: true,
            center: true,
            cell: (row) => (
                <>
                    {row?.has_payout_remaining_time_percentage ? (
                        <PercentageIndicator percentage={row?.payout_remaining_time_percentage} />
                    ) : (
                        '-'
                    )}
                </>
            ),
        },
        {
            name: 'Amount Due',
            selector: 'amount_due',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.amount_due)}</div>
                </div>
            ),
        },
        {
            name: 'Subtotal',
            selector: 'subtotal',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.subtotal)}</div>
                </div>
            ),
        },
        {
            name: 'VAT',
            selector: 'vat',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vat)}</div>
                </div>
            ),
        },
        {
            name: 'Merchant commission',
            selector: 'vendor_commission',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vendor_commission)}</div>
                </div>
            ),
        },
        {
            name: 'Merchant discount amount',
            selector: 'vendor_discount_amount',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vendor_discount_amount)}</div>
                </div>
            ),
        },
        {
            name: 'Delivery fees (Including Vat)',
            selector: 'delivery_fees_with_vat',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.delivery_fees_with_vat)}</div>
                </div>
            ),
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '300px',
            cell: (row) => <StatusBlock state={row.state} />,
        },
        {
            name: 'Actions',
            cell: (row) =>
                row?.updatable && (
                    <>
                        <Recalculate id={row.id} fetch_invoice={fetchData} icon={true} />
                    </>
                ),
        },
    ];
    const submitBulkApprove = async (closeModal) => {
        const data = obj2formdata({});
        if (selectedRows.length) {
            selectedRows.forEach((r, i) => {
                data.append('invoice_requests[' + i + ']', r);
            });
        }
        data.append('_method', 'PUT');
        set_loading(true);
        const res = await POST({ endpoint: '/vendors/invoice-requests/mark-paid', data });
        set_loading(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_errors([]);
            closeModal('bulk_approve_po_modal');
            setSelectedRows([]);
            fetchData();
            setUpdateTableKey(updateTableKey + 1);
        } else {
            set_errors(res?.data?.errors || []);
        }
    };

    const rowDisabledCriteria = useCallback((row) => row.is_payout_managed, []);

    return (
        <div className="live-carts-list-view">
            <ViewHeader title="Purchase Orders">
                <div className="flex__ai-center">
                    <ChangeInvoiceDays fetchData={fetchData} />
                    <Sop id="25263" />
                    <Button variant="info">Export CSV</Button>
                    <AddPoInvoice fetchData={fetchData} />
                </div>
            </ViewHeader>
            <Card>
                <div className="flex__jc-end gap-0 px-3">
                    {payout_percentage?.map((p, i) => (
                        <PercentageIndicator
                            key={i}
                            percentage={p?.percentage_range}
                            count={p?.count}
                        />
                    ))}
                </div>
                <Tabs tabs={tabs} onSelect={setTabs} setPage={set_page} offsetBottom />
                <Filters
                    // eslint-disable-next-line max-len
                    queryPlaceholder="Search by Purchase order number, Invoice number, Vendor, Order number"
                    filters={
                        activeTab.status === 'all'
                            ? ['invoice_states', 'order_sources', 'vendors', 'from', 'to']
                            : ['order_sources', 'vendors', 'from', 'to']
                    }
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    sort_options={[
                        {
                            label: 'Payout Date (DESC)',
                            value: 'payout_date__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Payout Date (ASC)',
                            value: 'payout_date__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                {activeTab.name === 'Approved' && (
                    <div className="flex__jc-end px-4">
                        <Button
                            disabled={!selectedRows.length}
                            style={{ width: 150 }}
                            onClick={() => {
                                dispatch(
                                    setModalStatus({
                                        modal: 'bulk_approve_po_modal',
                                        status: true,
                                    }),
                                );
                            }}
                        >
                            Change to Paid
                        </Button>
                    </div>
                )}

                <DataTable
                    key={updateTableKey}
                    data={InvoicesSlice.invoices}
                    pages={InvoicesSlice.invoices_pages}
                    columns={columns}
                    page={page}
                    records={InvoicesSlice.invoices_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    selectableRows={activeTab.name === 'Approved'}
                    handlePageChange={set_page}
                    loading={InvoicesSlice.invoices_loading}
                    handleSelectedRows={handleRowSelected}
                    rowDisabledCriteria={rowDisabledCriteria}
                />
            </Card>
            <Modal
                name="bulk_approve_po_modal"
                title="Approve to Paid!!"
                loading={loading}
                onSubmit={(closeModal) => submitBulkApprove(closeModal)}
            >
                <div style={{ margin: '0 20px 20px' }}>
                    <h2 style={{ fontWeight: 'bold', fontSize: 16 }}>
                        Bulk Approve for Purchase Orders ID`s :
                    </h2>
                    <ul style={{ listStyleType: 'circle', margin: '8px 25px 0' }}>
                        {selectedRows.map((x) => (
                            <li style={{ marginBottom: 5 }} key={x}>
                                {x}
                            </li>
                        ))}
                    </ul>
                </div>
                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            </Modal>
        </div>
    );
}
