/* eslint-disable quotes */
import React from 'react';
import {
    ViewHeader,
    // ButtonGroup,
    // DropdownButton,
    // Dropdown,
    Card,
    Modal,
    Tabs,
    Button,
    ActivationReasonModal,
    Sop,
} from '@components';
import { useParams, Redirect } from 'react-router';
import defaultLogo from '@assets/img/default.jpg';
import {
    ProductsTab,
    OrdersTab,
    GeneralTab,
    InvoiceSettingsTab,
    VendorAddressesTab,
    FollowingCustomersTab,
    SocialPosConfig,
    VendorAccountStatusHistoryTab,
    SuborderRatingTab,
    ShippingFulfillmentSettings,
    // PaymentsTab
    // CustomersTab,
} from './Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { loadVendorData } from '@slices/merchant.slice';
import { DELETE } from '@utils';
import { setVendor } from '../../../store/slices/merchant.slice';
import DistyPayConfig from './Tabs/DistyPayConfig';
import BankDetailsTab from './Tabs/BankDetailsTab';
import ErpIntegrationTap from './Tabs/ErpIntegrationTap';

export default function VendorsUpdateView() {
    const { id } = useParams();
    const vendor = useSelector((state) => state.VendorsSlice.vendor);
    const dispatch = useDispatch();
    const [deleted, set_deleted] = React.useState(false);
    const [submitting, set_submitting] = React.useState(false);

    React.useEffect(() => {
        dispatch(setVendor({}));
        dispatch(loadVendorData(id));
    }, []);

    const [tabs, setTabs] = React.useState([
        {
            name: 'General',
            component: <GeneralTab />,
            isActive: true,
        },
        {
            name: 'Products',
            component: <ProductsTab id={id} />,
            isActive: false,
        },
        {
            name: 'Orders',
            component: <OrdersTab id={id} />,
            isActive: false,
        },
        {
            name: 'Bank details',
            component: <BankDetailsTab />,
            isActive: false,
        },
        {
            name: 'Invoice Settings',
            component: <InvoiceSettingsTab id={id} />,
            isActive: false,
        },
        {
            // eslint-disable-next-line quotes
            name: "Merchant's addresses",
            component: <VendorAddressesTab id={id} />,
            isActive: false,
        },
        {
            name: 'Shipping/ Fulfillment Settings',
            component: <ShippingFulfillmentSettings id={id} />,
        },
        {
            name: 'Invoice financing configuration',
            component: <DistyPayConfig id={id} />,
            isActive: false,
        },
        {
            name: 'Following Retailers ',
            component: <FollowingCustomersTab id={id} />,
            isActive: false,
        },
        {
            name: 'Social POS Config',
            component: <SocialPosConfig id={id} />,
            isActive: false,
        },
        {
            name: 'Account Status History',
            component: <VendorAccountStatusHistoryTab id={id} />,
            isActive: false,
        },
        {
            name: 'Rates Record',
            component: <SuborderRatingTab id={id} />,
            isActive: false,
        },
        {
            name: 'ERP Integration',
            component: <ErpIntegrationTap />,
            isActive: false,
        },
        // {
        //     name: 'Payments',
        //     component: <PaymentsTab />,
        //     isActive: false
        // },
        // {
        //     name: 'Customers',
        //     component: <CustomersTab />,
        //     isActive: false
        // }
    ]);

    const deleteVendor = async () => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/vendors/${id}`,
        });
        set_submitting(false);

        if (res?.status_code === 200) {
            set_deleted(true);
        }
    };

    if (deleted) {
        return <Redirect to="/merchants/list" />;
    }

    return (
        <div className="vendors-single-view">
            <ViewHeader title={`Merchant: ${vendor.company_name_ar}`} renderBackArrow>
                <Sop id="29843" />
                {/**
                 * @todo unhide this once backend figured out a way to handle deps..
                 * @see https://distyworkspace.slack.com/archives/C04EMRP0WF2/p1686061842963019?thread_ts=1686052819.570609&cid=C04EMRP0WF2
                 *
                 * <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Actions"
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            <Dropdown.Item
                                eventKey="2"
                                onClick={() =>
                                    dispatch(
                                        setModalStatus({
                                            modal: 'single_vendor_view__delete_modal',
                                            status: true
                                        })
                                    )
                                }
                            >
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                 */}
            </ViewHeader>

            <Card>
                <div className="vendor-info">
                    <div className="overview">
                        <img src={vendor.logo_en || defaultLogo} alt="Logo" />
                        <h2>{vendor.company_name_ar}</h2>
                        {vendor.company_name_en && <h3>{vendor.company_name_en}</h3>}
                        {vendor.state && <h3>{vendor.state}</h3>}
                    </div>
                    <div className="details vendor-details-list">
                        <ul>
                            <li>
                                <span>Total orders</span>
                                <p>{vendor.total_orders}</p>
                            </li>
                            <li>
                                <span>Total Products</span>
                                <p>{vendor.total_products}</p>
                            </li>
                            <li>
                                <span>Active products</span>
                                <p>{vendor.active_products}</p>
                            </li>
                            <li>
                                <span>Out of stock products</span>
                                <p>{vendor.out_of_stock_products}</p>
                            </li>
                            <li>
                                <span>Subtotal sales amount</span>
                                <p>{vendor.subtotal_sales_amount} SAR</p>
                            </li>
                            <li>
                                <span>Tax amount</span>
                                <p>{vendor.tax_amount} SAR</p>
                            </li>
                            <li>
                                <span>Total sales including tax</span>
                                <p>{vendor.total_sales} SAR</p>
                            </li>
                            <li>
                                <span>Plan</span>
                                <p>
                                    <p>{vendor?.plan?.name_ar}</p>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <Button
                        className="activation"
                        variant={vendor?.status ? 'danger' : 'primary'}
                        onClick={() => {
                            dispatch(
                                setModalStatus({
                                    modal: 'activation_reason_modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        {vendor?.status ? 'Disable' : 'Enable'} account
                    </Button>
                </div>

                <Tabs tabs={tabs} onSelect={setTabs}>
                    <div className="px-3 py-2">{tabs.find((t) => t.isActive)?.component}</div>
                </Tabs>
            </Card>

            <Modal
                title={`Delete ${vendor.company_name_en}?`}
                name="single_vendor_view__delete_modal"
                variant="danger"
                onSubmit={(closeModal) => {
                    deleteVendor();
                    closeModal('single_vendor_view__delete_modal');
                }}
                loading={submitting}
            />
            <ActivationReasonModal
                name="vendor"
                status={vendor?.status}
                id={vendor?.id}
                reasons_option={vendor?.reasons}
            />
        </div>
    );
}
