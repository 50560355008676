import axios from 'axios';
import { validURL } from '@utils';

/**
 * @typedef {{
 *  method: "GET"| "POST"|"PUT"|"DELETE";
 *  endpoint: string;
 *  realm?: string;
 *  data?: any;
 *  auth?: boolean
 *  contentType?: string
 *  headers?: any
 *  options?: import('axios').AxiosRequestConfig & { throwError?: boolean }
 * }} HttpArgs
 * @param {HttpArgs} config
 */
const HTTP_REQUEST = async ({
    method,
    endpoint,
    realm = 'admin',
    data = {},
    auth = true,
    contentType = 'application/json',
    headers = {},
    options = {
        throwError: false,
    },
    version = 'v1'
}) => {
    if (auth) {
        const token = window.localStorage.getItem('Disty_admin_token');
        var auth_header = auth
            ? {
                  Authorization: `Bearer ${token}`,
              }
            : {};
    }

    try {
        const res = await axios({
            method,
            headers: {
                ...auth_header,
                ...headers,
                platform: 'admin',
                'Content-Type':
                    data instanceof FormData
                        ? 'multipart/form-data'
                        : contentType || 'application/json',
            },
            url: validURL(endpoint)
                ? endpoint
                : `${process.env.REACT_APP_BASE_URL}/${realm}/${version}${endpoint}`,
            data: data,
            ...options,
        }).then((res) => {
            if (options.return_full_response) return res;
            return res?.data || res;
        });
        return res;
    } catch (err) {
        if (err?.response?.status === 401) {
            let redirect = '';
            if (window.location.pathname !== '/login' && window.location.pathname !== '/logout') {
                redirect = `?redirect=${window.location.pathname}`;
            }
            if (window.location.pathname !== '/login') {
                window.location.href = `/login${redirect}`;
            }
            window.localStorage.removeItem('Disty_admin_token');
            return err.response;
        }
        if (err?.response?.status === 403 && (options.handlePermissionError || method !== 'get')) {
            window.location.href = '/403';
            return;
        }
        if (options.throwError) throw new Error(err.response || err);
        return err.response || err;
    }
};

/** @param {Omit<HttpArgs, 'method'>} config */
export const GET = ({ endpoint, realm, auth, contentType, headers, options, version }) =>
    HTTP_REQUEST({
        method: 'get',
        endpoint,
        realm,
        auth,
        contentType,
        headers,
        options,
        version
    });
/** @param {Omit<HttpArgs, 'method'>} config */
export const POST = ({ endpoint, realm, data, auth, contentType, headers, options, version }) =>
    HTTP_REQUEST({
        method: 'post',
        endpoint,
        realm,
        data,
        auth,
        contentType,
        headers,
        options,
        version
    });

/** @param {Omit<HttpArgs, 'method'>} config */
export const PATCH = ({ endpoint, realm, data, auth, contentType, headers, options, version }) =>
    HTTP_REQUEST({
        method: 'patch',
        endpoint,
        realm,
        data,
        auth,
        contentType,
        headers,
        options,
        version
    });

/** @param {Omit<HttpArgs, 'method'>} config */
export const PUT = ({ endpoint, realm, data, auth, contentType, headers, options, version }) =>
    HTTP_REQUEST({
        method: 'put',
        endpoint,
        realm,
        data,
        auth,
        contentType,
        headers,
        options,
        version
    });

/** @param {Omit<HttpArgs, 'method'>} config */
export const DELETE = ({ endpoint, realm, data, auth, contentType, headers, options, version }) =>
    HTTP_REQUEST({
        method: 'delete',
        endpoint,
        realm,
        data,
        auth,
        contentType,
        headers,
        options,
        version
    });
