import React, { useEffect } from 'react';
import { ViewHeader, Card, DataTable, Button, Modal } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadHelpMappingListData } from '@slices/helpMapping.slice';
import contentful from '@utils/contentful';
import { POSITION_LABELS } from './constants';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
import { setModalStatus } from '@slices/ui.slice';
import { DELETE } from '@utils';
import FormModal from './FormModal';

const HelpMapping = () => {
    const HelpMappingSlice = useSelector((state) => state.HelpMappingSlice);
    const dispatch = useDispatch();
    const [page, set_page] = React.useState(1);
    const [articles, set_articles] = React.useState({});
    const [deleting, set_deleting] = React.useState(false);
    const [delete_id, set_delete_id] = React.useState();
    const [open_form, set_open_form] = React.useState(false);

    const openFormModal = (id) => {
        set_open_form(true);
        dispatch(
            setModalStatus({
                modal: 'help_mapping_form__modal',
                status: true,
                payload: { id },
            }),
        );
    };

    const loadArticles = async () => {
        const items = await contentful.getAllArticles();
        const articles_keys = {};

        items.map((i) => {
            articles_keys[i.id] = i.title;
        });

        set_articles(articles_keys);
    };

    const deleteItem = async (closeModal) => {
        set_deleting(true);

        await DELETE({
            endpoint: `/vendors/help-center-articles/${delete_id}`,
        });

        set_deleting(false);

        closeModal('help_mapping__delete_item');

        loadHelpMapping();
    };

    const loadHelpMapping = () => {
        dispatch(loadHelpMappingListData({ page }));
    };

    useEffect(() => {
        loadHelpMapping();
    }, [page]);

    useEffect(() => {
        loadArticles();
    }, []);

    const columns = [
        {
            name: 'Position',
            cell: (row) => POSITION_LABELS[row.position_id],
        },
        {
            name: 'Article',
            cell: (row) => (
                <a
                    href={`https://help.disty.sa/ar/article/${row.contentful_article_id}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {articles[row.contentful_article_id]}
                </a>
            ),
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            dispatch(
                                setModalStatus({
                                    modal: 'help_mapping__delete_item',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            openFormModal(row.id);
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];
    return (
        <div>
            <ViewHeader title="Help Topic Mapping">
                <Button onClick={() => openFormModal()} className="btn btn-primary">
                    New Topic Mapping
                </Button>
            </ViewHeader>
            <Card>
                <DataTable
                    data={HelpMappingSlice.help_mapping_list}
                    // pages={HelpMappingSlice.}
                    page={page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={HelpMappingSlice.help_mapping_list_loading}
                />
            </Card>
            <Modal
                title="Delete help mapping item?"
                name="help_mapping__delete_item"
                variant="danger"
                onSubmit={deleteItem}
                loading={deleting}
            />
            <FormModal
                open={open_form}
                onClose={() => set_open_form(false)}
                onSubmitted={loadHelpMapping}
            />
        </div>
    );
};

export default HelpMapping;
