import React from 'react';
import { DataTable, Button, Modal, Input, ShowErrors } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { POST, PUT, DELETE, errorsNormalizer } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
import useMutation from '@hooks/useMutation';
import useQuery from '@hooks/useQuery';
import { toast } from 'react-toastify';

const StorageConditionTypesTab = () => {
    const dispatch = useDispatch();
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });

    const {
        data: { data } = { data: [] },
        isLoading,
        refetch,
    } = useQuery('/storage-condition-type');

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'name_en',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            dispatch(
                                setModalStatus({
                                    modal: 'storage_condition_type_delete__modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            name_ar.setValue(row.name_ar);
                            name_en.setValue(row.name_en);

                            dispatch(
                                setModalStatus({
                                    modal: 'storage_condition_type__modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];
    const createOrUpdateMutations = useMutation(
        () =>
            (edit_id ? PUT : POST)({
                endpoint: edit_id
                    ? `/storage-condition-type/${edit_id}`
                    : '/storage-condition-type',
                data: {
                    name_ar: name_ar.value,
                    name_en: name_en.value,
                },
            }),
        {
            onSuccess: () => {
                refetch();
                dispatch(
                    setModalStatus({
                        modal: 'storage_condition_type__modal',
                        status: false,
                    }),
                );
                name_ar.setValue('');
                name_en.setValue('');
            },
        },
    );
    const deleteMutation = useMutation(
        () =>
            DELETE({
                endpoint: `/storage-condition-type/${delete_id}`,
            }),
        {
            onSuccess: () => {
                refetch();
                dispatch(
                    setModalStatus({
                        modal: 'storage_condition_type_delete__modal',
                        status: false,
                    }),
                );
            },
            onError: (errors) => toast.error(errorsNormalizer(errors).join(', ')),
        },
    );
    return (
        <>
            <DataTable data={data} columns={columns} loading={isLoading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        name_ar.setValue('');
                        name_en.setValue('');
                        dispatch(
                            setModalStatus({
                                modal: 'storage_condition_type__modal',
                                status: true,
                            }),
                        );
                    }}
                >
                    Add new storage condition type
                </Button>
            </div>

            <Modal
                title={edit_id ? 'Update storage condition type' : 'Add new storage condition type'}
                variant={edit_id ? 'warning' : 'primary'}
                name="storage_condition_type__modal"
                onSubmit={() => createOrUpdateMutations.mutate()}
                loading={createOrUpdateMutations.isLoading}
            >
                <Input {...name_ar} />
                <Input {...name_en} />
                {createOrUpdateMutations.error && (
                    <ShowErrors errors={createOrUpdateMutations.error} />
                )}
            </Modal>

            <Modal
                title="Delete storage condition type?"
                name="storage_condition_type_delete__modal"
                variant="danger"
                onSubmit={() => deleteMutation.mutate()}
                loading={deleteMutation.isLoading}
            />
        </>
    );
};

export default StorageConditionTypesTab;
