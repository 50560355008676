import { useState } from 'react';
import { Select, ConfirmationModal } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { useEffect } from 'react';
import { POST } from '@utils';
import { toast } from 'react-toastify';

export default function SelectPayLaterProvider({
    hideLabel = false,
    order_id,
    current_provider,
    pay_later_allowed_transitions = [],
    onSuccess,
}) {
    const dispatch = useDispatch();
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const [loading, set_loading] = useState(false);
    const [selected_provider, set_selected_provider] = useState({});
    const [pay_later_providers, set_pay_later_providers] = useState({
        label: hideLabel ? '' : 'Pay later type',
        options: [],
        getOptionValue: (option) => option.id,
        value: selected_provider,
        disabled: loading,
        onChange: (val) => {
            set_selected_provider(val);
            dispatch(
                setModalStatus({
                    modal: 'confirmation_modal',
                    status: true,
                }),
            );
        },
    });

    const isCurrentSelectionAllowed = !!pay_later_allowed_transitions.find(
        ({ code }) => code === selected_provider.id,
    );

    useEffect(() => {
        set_selected_provider({ id: current_provider.code, label: current_provider.description });
    }, [current_provider]);

    useEffect(() => {
        set_pay_later_providers((prev) => ({ ...prev, value: selected_provider }));
    }, [selected_provider]);

    // change pay_later_providers only if they changed from order_static_data
    useEffect(() => {
        set_pay_later_providers((prev) => ({
            ...prev,
            options: OrdersSlice?.order_static_data?.pay_later_providers?.map((o) => ({
                id: o.code,
                label: o.description,
            })),
        }));
    }, [OrdersSlice?.order_static_data?.pay_later_providers]);

    const onSubmit = async () => {
        if (!order_id) return false;
        set_loading(true);
        const res = await POST({
            endpoint: `/order/${order_id}/pay-later-provider`,
            data: {
                pay_later_provider: selected_provider.id,
            },
        });
        set_loading(false);
        if (res.success) {
            set_pay_later_providers((prev) => ({ ...prev, value: selected_provider }));
            dispatch(
                setModalStatus({
                    modal: 'confirmation_modal',
                    status: false,
                }),
            );
            toast.success('Pay later provider changed successfully!');
            onSuccess?.();
        } else {
            toast.error('Something went wrong!, please try again later');
        }
    };

    return (
        <>
            <Select {...pay_later_providers} />
            <ConfirmationModal
                title={
                    isCurrentSelectionAllowed
                        ? modalSwitchAllowedMessage
                        : modalSwitchNotAllowedMessage
                }
                variant={isCurrentSelectionAllowed ? 'primary' : 'danger'}
                onSubmit={onSubmit}
                confirmBtn={isCurrentSelectionAllowed}
            />
        </>
    );
}
const modalSwitchNotAllowedMessage = 'Changing To ByNow is not allowed';
const modalSwitchAllowedMessage = 'Are you sure that you want to change the pay later provider ?';
