import React from 'react';
import { Form } from '@components';
import PropTypes from 'prop-types';

export default function Toggler({ label = '', checked, onChange, disabled, name }) {
    return (
        <Form.Group controlId={label} className="flex__jc-between">
            {label && <Form.Label>{label}</Form.Label>}
            <div className="toggler ml-3">
                <input
                    onChange={({ target }) => onChange(target.checked, target.name)}
                    checked={checked}
                    type="checkbox"
                    disabled={disabled}
                    name={name}
                />
                <label></label>
            </div>
        </Form.Group>
    );
}

Toggler.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
};
