import React from 'react';
import { Modal, ShowErrors, ApiAsyncSelect } from '@components';
import { PUT } from '@utils';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';

function UpdateChildOrder({
    invoice_data,
    fetch_invoice,
    fetch_audits = () => {},
    set_updated,
}) {
    const [suborders, set_suborders] = React.useState([]);
    const [modal_loading, set_modal_loading] = React.useState(false);
    const [errors, set_errors] = React.useState({});
    const [deleted_purchase_order , set_deleted_purchase_order] = React.useState(null);

    React.useEffect(() => {
        if (invoice_data?.suborders?.length) {
            set_suborders(
                invoice_data?.suborders?.map((suborder) => {
                    return {
                        id: suborder?.suborder_id,
                        label: suborder?.pretty_id + '(' + suborder?.suborder_id + ')',
                        value: suborder?.suborder_id,
                    };
                })
            );
        }
        
    }, [invoice_data]);
    const handleChildOrder = async (closeModal) => {
        set_modal_loading(true);
        const res = await PUT({
            endpoint: `/vendors/invoice-requests/${invoice_data.id}`,
            data: {
                ...(suborders.length && { suborders: suborders?.map(({ id }) => id) }),
            },
        });
        set_modal_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            if(suborders.length){
                fetch_invoice();
                fetch_audits();
                set_updated(res?.message);
                setTimeout(() => {
                    set_updated(null);
                }, 2000);
            }else{
                toast.success('purchase order deleted successfully');
                set_deleted_purchase_order(true);
            }
            set_errors([]);
            closeModal('purchase_orders_add_child_order_modal');
        } else {
            set_errors(res?.data?.errors || { error : [res?.data?.message] } || []);
        }
    };
    if (deleted_purchase_order) {
        return <Redirect to="/purchase-orders/list" />;
    }
    const resetData = () => {
        set_errors([]);
        set_suborders(
            invoice_data?.suborders?.map((suborder) => {
                return {
                    id: suborder?.suborder_id,
                    label: suborder?.pretty_id + '(' + suborder?.suborder_id + ')',
                    value: suborder?.suborder_id,
                };
            })
        );
    };
    return (
        <>
            <Modal
                title="Add new child order(s)"
                name="purchase_orders_add_child_order_modal"
                confirmBtnName="Confirm and Recalculate Purchase order"
                variant="primary"
                onSubmit={handleChildOrder}
                loading={modal_loading}
                onClose={resetData}
            >
                <ShowErrors errors={errors} />
                <ApiAsyncSelect
                    label="suborder"
                    api="/suborder"
                    params={{ vendors: invoice_data?.vendor?.id }}
                    onlyFetchActiveRecords={false}
                    placeholder="Type to add suborder..."
                    labelProperty={(r) => `${r?.pretty_id}(${r?.id})`}
                    onChange={set_suborders}
                    value={suborders}
                    isMulti
                />
            </Modal>
        </>
    );
}

export default UpdateChildOrder;
