import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import VendorPayouts from './MerchantPayouts';
import { Helmet } from 'react-helmet';
import PayoutDetails from './PayoutDetails';

export default function VendorPayoutsRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/vendor-payouts">
                <Helmet>
                    <title>Merchant Payouts | Disty Admin Dashboard</title>
                </Helmet>
                <VendorPayouts />
            </ProtectedRoute>
            <ProtectedRoute exact path="/vendor-payouts/:id">
                <Helmet>
                    <title>Merchant Payout Details | Disty Admin Dashboard</title>
                </Helmet>
                <PayoutDetails />
            </ProtectedRoute>
        </Switch>
    );
}
