/* eslint-disable max-len */
import React from 'react';
import { GET, randomId, formatDate, POST, DELETE, PUT } from '@utils';
import {
    DataTable,
    StatusToggler,
    Modal,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Select,
    ShowErrors
} from '@components';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';

function DefineScheduledOrders() {
    const idempotencyKey = randomId();
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [is_loading, set_is_loading] = React.useState(true);
    const [errors, set_errors] = React.useState([]);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);
    const [name, set_name] = React.useState({
        label: 'name',
        value: '',
        setValue: (val) => set_name({ ...name, value: val }),
    });
    const [title_ar_field, set_title_ar_field] = React.useState({
        label: 'title_ar',
        value: '',
        setValue: (val) => set_title_ar_field({ ...title_ar_field, value: val }),
    });
    const [title_en_field, set_title_en_field] = React.useState({
        label: 'title_en',
        value: '',
        setValue: (val) => set_title_en_field({ ...title_en_field, value: val }),
    });
    const [order_minimum_amount, set_order_minimum_amount] = React.useState({
        label: 'order_minimum_amount',
        type: 'number',
        value: '',
        setValue: (val) =>
            set_order_minimum_amount({ ...order_minimum_amount, value: val }),
    });
    const [allowed_payment_methods, set_allowed_payment_methods] = React.useState(
        {
            label: 'allowed_payment_methods',
            options: [
                { id: 1, label: 'wire_transfer' },
                { id: 2, label: 'sadad' },
                { id: 3, label: 'card' },
                { id: 4, label: 'apple_pay' },
                { id: 5, label: 'cash_on_delivery' },
            ],
            value: [],
            getOptionValue: (option) => option.id,
            isMulti: true,
            onChange: (val) =>
                set_allowed_payment_methods((prev) => ({ ...prev, value: val })),
        }
    );
    const [delivery_options, set_delivery_options] = React.useState([
        {
            id: 'ID_' + randomId(),
            title_ar: '',
            title_en: '',
            payment_option_description_ar: '',
            payment_option_description_en: '',
            period_from: null,
            period_to: null,
        },
    ]);
    const [payment_options, set_payment_options] = React.useState([
        {
            id: 'ID_' + randomId(),
            down_payment_percentage: '',
            coupon_code: '',
        },
    ]);
    const fetchData = async () => {
        const res = await GET({
            endpoint: '/scheduled-order',
        });
        set_data(res?.data || []);
        set_is_loading(false);
    };
    React.useEffect(() => {
        fetchData();
    }, []);
    const handleEditFields = (scheduled) => {
        name.setValue(scheduled?.name);
        title_ar_field.setValue(scheduled?.title_ar);
        title_en_field.setValue(scheduled?.title_en);
        order_minimum_amount.setValue(scheduled?.order_minimum_amount);
        allowed_payment_methods.onChange(
            scheduled?.allowed_payment_methods.map((method) => {
                return { id: randomId(), label: method };
            })
        );
        set_delivery_options(
            scheduled?.delivery_options?.map((option) => {
                return {
                    ...option,
                    period_from: new Date(option.period_from),
                    period_to: new Date(option.period_to),
                };
            })
        );
        set_payment_options(scheduled?.payment_options);
    };
    const resetForm = () => {
        name.setValue('');
        title_ar_field.setValue('');
        title_en_field.setValue('');
        order_minimum_amount.setValue('');
        allowed_payment_methods.onChange([]);
        set_delivery_options([
            {
                id: 'ID_' + randomId(),
                title_ar: '',
                title_en: '',
                payment_option_description_ar: '',
                payment_option_description_en: '',
                period_from: null,
                period_to: null,
            },
        ]);
        set_payment_options([
            {
                id: 'ID_' + randomId(),
                down_payment_percentage: '',
                coupon_code: '',
            },
        ]);
    };

    const submit = async (closeModal) => {
        set_is_loading(true);
        const data = {
            name: name.value,
            title_ar: title_ar_field.value,
            title_en: title_en_field.value,
            order_minimum_amount: +order_minimum_amount.value,
            allowed_payment_methods: allowed_payment_methods.value.map(
                (option) => option.label
            ),
            delivery_options: delivery_options.map((option) => {
                return {
                    ...(!option.id.startsWith?.('ID_') ? { id: option.id } : {}),
                    title_ar: option.title_ar,
                    title_en: option.title_en,
                    payment_option_description_en: option.payment_option_description_en,
                    payment_option_description_ar: option.payment_option_description_ar,
                    period_from: option.period_from?.toISOString().split('T')[0],
                    period_to: option.period_to?.toISOString().split('T')[0],
                };
            }),
            payment_options: payment_options.map((option) => {
                return {
                    ...(!option.id.startsWith?.('ID_') ? { id: option.id } : {}),
                    down_payment_percentage: +option.down_payment_percentage,
                    coupon_code: option.coupon_code || null,
                };
            }),
        };

        const res = await (edit_id ? PUT : POST)({
            endpoint: edit_id ? `/scheduled-order/${edit_id}` : '/scheduled-order',
            headers: {
                'Idempotency-Key': idempotencyKey,
            },
            data,
        });
        set_is_loading(false);
        if (res.status_code === 200) {
            closeModal('scheduled_orders-config__modal');
            fetchData();
            set_errors([]);
            resetForm();
        } else {
            set_errors(res?.data?.errors || []);
        }
    };

    const deleteScheduledOrder = async (closeModal) => {
        set_is_loading(true);
        const res = await DELETE({
            endpoint: `/scheduled-order/${delete_id}`,
        });
        set_is_loading(false);

        if (res?.message === 'resource deleted successfully.') {
            fetchData();
            closeModal('scheduled_orders_delete_modal');
            set_delete_id(null);
        } else {
            set_errors(res?.data?.errors);
        }
    };
    const columns = [
        {
            name: 'Title_en',
            selector: 'title_en',
        },
        {
            name: 'Minimum amount',
            selector: 'order_minimum_amount',
        },
        {
            name: 'Allowed payment methods',
            cell: (row) => {
                return (
                    <>
                        <ul style={{ listStyleType: 'circle' }}>
                            {row?.allowed_payment_methods.map((payment) => (
                                <li key={randomId()}>{payment}</li>
                            ))}
                        </ul>
                    </>
                );
            },
        },
        {
            name: 'Delivery options',
            cell: (row) => {
                return (
                    <>
                        <ul style={{ listStyleType: 'circle' }}>
                            {row?.delivery_options.map((option) => (
                                <li key={option?.id}>{option?.title_en}</li>
                            ))}
                        </ul>
                    </>
                );
            },
        },
        {
            name: 'Payment options',
            cell: (row) => {
                return (
                    <ul style={{ listStyleType: 'circle' }}>
                        {row?.payment_options.map((option) => (
                            <li key={option?.id}>{option?.down_payment_percentage}</li>
                        ))}
                    </ul>
                );
            },
        },
        {
            name: 'Created_at',
            cell: (row) => formatDate(row?.created_at),
        },
        {
            name: 'Updated_at',
            cell: (row) => formatDate(row?.updated_at),
        },
        {
            name: 'Status',
            selector: 'is_active',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row?.is_active}
                    path={`/scheduled-order/${row.id}/status`}
                    pathOff={`/scheduled-order/${row.id}/status`}
                    method={row?.is_active ? 'delete' : 'post'}
                    callback={({ id, status }) => {
                        set_data((prev) =>
                            prev.map((item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        is_active: status,
                                    };
                                }
                                return item;
                            })
                        );
                    }}
                />
            ),
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'scheduled_orders_delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
					&nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            handleEditFields(row);
                            set_edit_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'scheduled_orders-config__modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];
    return (
        <>
            <DataTable data={data} columns={columns} loading={is_loading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        resetForm();
                        dispatch(
                            setModalStatus({
                                modal: 'scheduled_orders-config__modal',
                                status: true,
                            })
                        );
                    }}
                >
					Add new Scheduled orders
                </Button>
            </div>
            <Modal
                size="lg"
                title={edit_id ? 'Edit scheduled order' : 'Add new scheduled order'}
                variant="primary"
                name="scheduled_orders-config__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal);
                }}
                loading={is_loading}
            >
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
                <Row>
                    <Col>
                        <Input {...name} />
                    </Col>
                    <Col>
                        {' '}
                        <Input {...title_ar_field} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input {...title_en_field} />
                    </Col>
                    <Col>
                        <Input {...order_minimum_amount} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Select {...allowed_payment_methods} />
                    </Col>
                </Row>

                {/* delivery Options */}
                <div className="scheduled-orders__options">
                    <div
                        className="options__header d-flex 
                align-items-center justify-content-between my-2"
                    >
                        <h3>Delivery Options</h3>
                        <Button
                            size="sm"
                            className="add-option"
                            onClick={() => {
                                set_delivery_options((currentOption) => [
                                    ...currentOption,
                                    {
                                        id: 'ID_' + randomId(),
                                        title_ar: '',
                                        title_en: '',
                                        payment_option_description_en: '',
                                        payment_option_description_ar: '',
                                        period_from: null,
                                        period_to: null,
                                    },
                                ]);
                            }}
                        >
							Add new option
                        </Button>
                    </div>

                    <Row>
                        {delivery_options.map((p) => {
                            return (
                                <>
                                    <Col>
                                        <div key={p.id}>
                                            <div className="d-flex align-items-center justify-content-between my-2">
                                                <label>title_ar</label>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        set_delivery_options((currentOption) =>
                                                            currentOption.filter((x) => x.id !== p.id)
                                                        );
                                                    }}
                                                >
                                                    <img src={deleteIcon} alt="Delete" width="10px" />
                                                </Button>
                                            </div>

                                            <input
                                                onChange={(e) => {
                                                    const title_ar = e.target.value;
                                                    set_delivery_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    title_ar,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                                value={p.title_ar}
                                            />

                                            <label>payment_option_description_ar</label>
                                            <input
                                                onChange={(e) => {
                                                    const payment_option_description_ar = e.target.value;
                                                    set_delivery_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    payment_option_description_ar,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                                value={p.payment_option_description_ar}
                                            />

                                            <label>title_en</label>
                                            <input
                                                onChange={(e) => {
                                                    const title_en = e.target.value;
                                                    set_delivery_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    title_en,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                                value={p.title_en}
                                            />

                                            <label>payment_option_description_en</label>
                                            <input
                                                onChange={(e) => {
                                                    const payment_option_description_en = e.target.value;
                                                    set_delivery_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    payment_option_description_en,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                                value={p.payment_option_description_en}
                                            />

                                            <label>period_from</label>
                                            <DatePicker
                                                selectedDate={p.period_from}
                                                onChange={(date) => {
                                                    set_delivery_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    period_from: date,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                            />

                                            <label>period_to</label>
                                            <DatePicker
                                                selectedDate={p.period_to}
                                                onChange={(date) => {
                                                    set_delivery_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    period_to: date,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                </div>
                {/* payment_options */}
                <div className="scheduled-orders__options">
                    <div
                        className="options__header d-flex 
                align-items-center justify-content-between my-2"
                    >
                        <h3>Payment Options</h3>
                        <Button
                            size="sm"
                            onClick={() => {
                                set_payment_options((currentOption) => [
                                    ...currentOption,
                                    {
                                        id: 'ID_' + randomId(),
                                        down_payment_percentage: '',
                                        coupon_code: '',
                                    },
                                ]);
                            }}
                        >
							Add new option
                        </Button>
                    </div>

                    <Row>
                        {payment_options.map((p) => {
                            return (
                                <>
                                    <Col>
                                        <div key={p.id}>
                                            <div
                                                className="d-flex 
                align-items-center justify-content-between my-2"
                                            >
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        set_payment_options((currentOption) =>
                                                            currentOption.filter((x) => x.id !== p.id)
                                                        );
                                                    }}
                                                >
                                                    <img src={deleteIcon} alt="Delete" width="10px" />
                                                </Button>
                                            </div>

                                            <label>down_payment_percentage</label>
                                            <input
                                                type="number"
                                                onChange={(e) => {
                                                    const down_payment_percentage = e.target.value;
                                                    set_payment_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    down_payment_percentage,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                                value={p.down_payment_percentage}
                                            />
                                            <label>coupon_code</label>
                                            <input
                                                onChange={(e) => {
                                                    const coupon_code = e.target.value;
                                                    set_payment_options((currentOption) =>
                                                        currentOption.map((x) =>
                                                            x.id === p.id
                                                                ? {
                                                                    ...x,
                                                                    coupon_code,
																  }
                                                                : x
                                                        )
                                                    );
                                                }}
                                                value={p.coupon_code}
                                            />
                                        </div>
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                </div>
            </Modal>
            <Modal
                title="Delete the scheduled order ?"
                name="scheduled_orders_delete_modal"
                variant="danger"
                onSubmit={(closeModal) => deleteScheduledOrder(closeModal)}
                loading={is_loading}
            >
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>
        </>
    );
}

export default DefineScheduledOrders;
