import React from 'react';
import {
    ViewHeader,
    Button,
    Card,
    Row,
    Col,
    Toggler,
    Input,
    DataTable,
    Textarea,
    FileUpload,
    DateRangePicker,
    Radio,
    Select,
    Tags,
    CustomPrompt,
    ShowErrors,
    Filters,
    Sop,
} from '@components';
import defaultLogo from '@assets/img/default.jpg';
import { stringify } from 'query-string';
import { GET, POST, obj2formdata, formatMoney ,isArabic, isEnglish } from '@utils';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import useDidMountEffect from '@hooks/useDidMountEffect';
import { COLLECTION_TYPES } from '@components/shared/Filters/constants';

export default function CollectionsAddView() {
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const [filters, set_filters] = React.useState('');
    const [page, set_page] = React.useState(1);
    const [start_date, set_start_date] = React.useState(new Date());
    const [end_date, set_end_date] = React.useState(new Date());
    const [products_loading, set_products_loading] = React.useState(false);
    const [products_pages, set_products_pages] = React.useState(0);
    const [products, set_products] = React.useState([]);
    const [selected_products, set_selected_products] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(null);
    const [selected_tags, set_selected_tags] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState('');
    const [is_all_entity_types_selected, set_is_all_entity_types_selected] = React.useState('all');
    const [select_all, set_select_all] = React.useState(false);

    const [entity_types, set_entity_types] = React.useState({
        options: [],
        getOptionValue: (option) => option.id,
        value: [],
        isMulti: true,
        onChange: (val) => set_entity_types((prev) => ({ ...prev, value: val })),
    });

    React.useEffect(() => {
        set_entity_types({ ...entity_types, options: GlobalsSlice.entity_types });
    }, [GlobalsSlice]);

    const fetchData = async () => {
        set_products_loading(true);
        const res = await GET({
            endpoint: `/product?${stringify(
                {
                    ...filters,
                    status: '1',
                    page,
                },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                },
            )}`,
        });
        set_products(res?.data || []);
        set_products_pages(res?.meta?.last_page || 0);
        set_products_loading(false);
    };

    useDidMountEffect(() => {
        fetchData();
    }, [page, filters]);

    const columns = [
        {
            name: 'Logo',
            selector: 'logo',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.image || defaultLogo} alt="Logo" />
                </div>
            ),
        },
        {
            name: 'Product',
            selector: 'name',
            sortable: false,
            cell: (row) => <div className="tr-name">{row.name}</div>,
        },
        {
            name: 'Variant Options',
            cell: (row) => (
                <div>
                    {row?.variant_options?.map((o, i) => (
                        <div key={i}>
                            <span>{o.option_name}: </span>
                            <span>{o.value}</span>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.price)}</div>
                </div>
            ),
        },
        {
            name: 'Stock',
            selector: 'stock',
            sortable: false,
        },
        {
            name: '',
            selector: 'select',
            sortable: false,
            cell: (row) => {
                if (selected_products.find((p) => p.id === row.id)) {
                    return (
                        <Button
                            onClick={() => {
                                const filtred = selected_products.filter((p) => p.id !== row.id);
                                set_selected_products(filtred);
                            }}
                            variant="danger"
                        >
                            -
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            onClick={() => {
                                set_selected_products((prev) => [...prev, { ...row }]);
                            }}
                            variant="success"
                        >
                            +
                        </Button>
                    );
                }
            },
        },
    ];

    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val }),
    });
    const [preview_type, set_preview_type] = React.useState({
        label: 'Products preview?',
        options: COLLECTION_TYPES,
        getOptionValue: (option) => option.value,
        onChange: (val) => set_preview_type((prev) => ({ ...prev, value: val })),
    });
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => (isArabic(val) ? set_name_ar({ ...name_ar, value: val }) : ''),

    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_name_en({ ...name_en, value: val }) : ''),
 });
    const [period, set_period] = React.useState({
        label: 'Period',
        selectedDate: new Date(),
        onChange: (val) => set_period({ ...period, selectedDate: val }),
    });
    const [priority, set_priority] = React.useState({
        label: 'Priority',
        type: 'number',
        value: '',
        setValue: (val) => set_priority({ ...priority, value: val }),
    });
    const [description_ar, set_description_ar] = React.useState({
        label: 'Description (arabic)',
        value: '',
        setValue: (val) => set_description_ar({ ...description_ar, value: val }),
    });
    const [description_en, set_description_en] = React.useState({
        label: 'Description (english)',
        value: '',
        setValue: (val) => set_description_en({ ...description_en, value: val }),
    });
    const [image, set_image] = React.useState({
        label: 'Image',
        files: [],
        setFiles: (val) => set_image({ ...image, files: val }),
    });
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name_ar.value ) 
            newErrors.name_ar = 'The name (arabic) field is required';
        if (!name_en.value) 
            newErrors.name_en = 'The name (english) field is required';
        if (!priority.value) 
            newErrors.priority = 'The priority field is required';
        if (!preview_type.value) 
            newErrors.preview_type = 'The preview type field is required';
        if (image.files.length ===0) 
            newErrors.image = 'The image field is required';
        if (selected_products.length === 0) 
            newErrors.selected_products = 'The products field is required';
          set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name_en,name_ar,image,priority,selected_products,preview_type]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
        setErrors([]);
        setError('');
        const data = obj2formdata({
            is_active: status.checked ? '1' : '0',
            preview_type: preview_type.value?.value,
            name_ar: name_ar.value,
            name_en: name_en.value,
            description_ar: description_ar.value,
            description_en: description_en.value,
            priority: priority.value,
            period_from: start_date.toISOString().slice(0, 10).split('-').reverse().join('-'),
            period_to: end_date.toISOString().slice(0, 10).split('-').reverse().join('-'),
        });

        image.files.length && data.append('image', image.files[0]);

        selected_products.forEach((p, i) => {
            data.append('products[' + i + ']', p.id);
        });
        if (is_all_entity_types_selected === 'selected') {
            entity_types?.value.forEach((e, i) => {
                data.append('personalized_entity_types[' + i + ']', e.id);
            });
        }
        selected_tags.forEach((tag, i) => {
            data.append('tags[' + i + ']', tag.id);
        });

        const res = await POST({
            endpoint: '/product-collection',
            data,
        });
        set_loading(false);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status === 400) {
            setError(res?.data.message);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            set_created(res?.data.id);
        }
    }
    };

    const onTagSelected = async (tag) => {
        const res = await GET({
            endpoint: `/tags/${tag.id}/products`,
        });
        if (res?.status_code === 200 && res.data.length) {
            res.data.forEach((p) => {
                if (!selected_products.find((selected_p) => selected_p.id === p.id)) {
                    set_selected_products((prev) => [...prev, p]);
                }
            });
        }
    };

    if (created) {
        return <Redirect to="/collections/list" />;
    }

    return (
        <div className="collections-form-view">
            <CustomPrompt>
                <ViewHeader title="Add new collection">
                    <Sop id="30003" />
                    <Button
                        variant="info"
                        className="btn btn-primary"
                        onClick={submit}
                       >
                        New collection
                    </Button>
                </ViewHeader>

                <ShowErrors error={error} errors={errors} />

                <Row>
                    <Col lg={6}>
                        <Card>
                            <div className="px-3 py-3">
                                <h3 className="divider-title">Selected products</h3>
                                {!selected_products.length ? (
                                   <> <div className="text-center p-4 mb-4">
                                   No products was selected!
                               </div>
                           {loading && validation_errors.selected_products && (
                                   <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                       {validation_errors.selected_products}
                                   </p>
                               )} 
                             </>
                                ) : (
                                    <DataTable data={selected_products} columns={columns} />
                                )}

                                <h3 className="divider-title mt-3">Selected tags</h3>
                                <Row>
                                    <Col xs={12}>
                                        <Tags
                                            name="collection_update_tags"
                                            selected_tags={selected_tags}
                                            submitted_tags={set_selected_tags}
                                            onTagSelected={onTagSelected}
                                        />
                                    </Col>
                                </Row>

                                <h3 className="divider-title">General info</h3>
                                <Toggler {...status} />
                                <Select {...preview_type} />
                                {loading && validation_errors.preview_type && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.preview_type}
                                            </p>
                                        )}
                                {/* <Toggler {...market_availability} /> */}
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_ar} />
                                        {loading && validation_errors.name_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_ar}
                                            </p>
                                        )}
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_en} />
                                        {loading && validation_errors.name_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_en}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <DateRangePicker
                                    label="Period"
                                    startDate={start_date}
                                    endDate={end_date}
                                    setStartDate={set_start_date}
                                    setEndDate={set_end_date}
                                />
                                <Input {...priority} />
                                {loading && validation_errors.priority && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.priority}
                                            </p>
                                        )}
                                <div className="my-3">
                                    <label htmlFor="">Entity types</label> <br />
                                    <Radio
                                        onChange={set_is_all_entity_types_selected}
                                        option={is_all_entity_types_selected}
                                        label="All Entity"
                                        value="all"
                                        name="is-all-collections-entity-selected"
                                    />
                                    <Radio
                                        onChange={set_is_all_entity_types_selected}
                                        option={is_all_entity_types_selected}
                                        label="Select specific entity"
                                        value="selected"
                                        name="is-all-collections-entity-selected"
                                    />
                                    {is_all_entity_types_selected === 'selected' && (
                                        <>
                                            <Select {...entity_types} />
                                            <Button
                                                onClick={() => {
                                                    set_select_all(!select_all);
                                                    select_all
                                                        ? entity_types.onChange([])
                                                        : entity_types.onChange(
                                                              GlobalsSlice.entity_types,
                                                          );
                                                }}
                                                variant={select_all ? 'danger' : 'primary'}
                                            >
                                                {select_all ? 'undo' : 'select all'}{' '}
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <Textarea {...description_ar} />
                                <Textarea {...description_en} />
                                <FileUpload {...image} />
                                {loading && validation_errors.image && (
                                   <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                       {validation_errors.image}
                                   </p>
                               )} 
                            </div>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <Filters
                                filters={[
                                    'vendor',
                                    'stock_type',
                                    'take_rate',
                                    'take_rate_amount',
                                    'is_exclusive',
                                    'categories',
                                    'price',
                                    'profit_margin',
                                    'discount_value',
                                    'discount_percentage',
                                ]}
                                onFilterChange={(filters_values) => {
                                    set_filters(filters_values);
                                }}
                            />
                            <DataTable
                                data={products}
                                pages={products_pages}
                                page={page}
                                handlePageChange={set_page}
                                columns={columns}
                                searchable={false}
                                loading={products_loading}
                            />
                        </Card>
                    </Col>
                </Row>
            </CustomPrompt>
        </div>
    );
}
