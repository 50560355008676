import React, { useState, useEffect } from 'react';
import {
    Input,
    Row,
    Col,
    Toggler,
    FileUpload,
    ApiAsyncSelect,
    CreatableTextSelect,
    ShowErrors,
    Textarea,
    CriticalActionButton,
} from '@components';
import useMutation from '@hooks/useMutation';
import { toast } from 'react-toastify';
import { POST, obj2formdata ,GET } from '@utils';
import copyIcon from '@assets/icons/copy.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setVendor } from '@slices/merchant.slice';

export default function SocialPosConfig({ id }) {
    const vendor = useSelector((state) => state.VendorsSlice.vendor);
    const loading = useSelector((state) => state.VendorsSlice.vendor_loading);

    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);
    const [og_tags, set_og_tags] = useState([]);
    const [errors, set_errors] = useState([]);
    const [has_products,set_has_products]=useState(false) ;
    const [loading_products, set_loading_products] = useState(true);
    const [pos, set_pos] = useState({
        subdomain: '',
        title: '',
        description: '',
        is_active: false,
        cover_image_desktop: [],
        cover_image_mobile: [],
        products: [],
    });
    const fetchData = async () => {
        const res = await GET({ endpoint: `/product/?vendor_id=${id}` });
        if (res?.status_code === 200) {
            if(res?.data.length>0){ set_has_products(true) ;}
        }
        set_loading_products(false) ;
    };
   React.useEffect(() => {
        fetchData();
    }, []);
    
    const handleChange = (val, name) => {
         set_pos((prev) => ({
            ...prev,
            [name]: val,
        }));
    };
    const [validation_errors, set_validation_errors] = React.useState({});
    const [submmited, set_submmited] = React.useState(false) ;
    const validate = () => {
        const newErrors = {};
        if (!pos.title) newErrors.title = 'The titel field is required.';
        if (!pos.description) newErrors.description = 'The description field is required.';
        if (!pos.subdomain) newErrors.subdomain = 'The subdomain field is required.';
        if ( pos.products.length === 0) newErrors.products = 'The products field is required.';
        if (!vendor?.pos?.cover_image_mobile && pos.cover_image_mobile.length === 0)
            newErrors.cover_image_mobile = 'The cover image mobile field is required.';
        if (!vendor?.pos?.cover_image_desktop && pos.cover_image_desktop.length === 0)
            newErrors.cover_image_desktop = 'The cover image desktop field is required.';
        if (og_tags.length === 0) newErrors.og_tags = 'The og tags field is required.';

        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [pos, og_tags]);
    const updateVendorPOS = () => {
        const endpoint = `/vendors/${id}/pos`;
        const data = obj2formdata({
            subdomain: pos?.subdomain,
            title: pos?.title,
            description: pos?.description,
            is_active: pos?.is_active ? 1 : 0,
            og_tags: og_tags?.map((t) => t.label).join(','),
        });

        pos?.cover_image_mobile?.length &&
            data.append('cover_image_mobile', pos?.cover_image_mobile[0]);

        pos?.products?.length &&
            pos?.products.forEach((product, i) => {
                data.append('products[' + i + ']', product?.id);
            });

        pos?.cover_image_desktop?.length &&
            data.append('cover_image_desktop', pos?.cover_image_desktop[0]);

        data.append('_method', 'PATCH');

        return POST({ endpoint: endpoint, data: data });
    };

    const { mutate } = useMutation(updateVendorPOS, {
        onSuccess: (res) => {
            if (res?.status_code === 201 || res?.status_code === 200) {
                set_errors([]);
                toast.success(res?.message);
                set_pos({
                    ...pos,
                    cover_image_desktop: [],
                    cover_image_mobile: [],
                });
                dispatch(
                    setVendor({
                        ...vendor,
                        pos: res?.data,
                    }),
                );
            } else {
                set_errors(res?.data.errors);
            }
        },
        onError: (errors) => set_errors(errors),
    });

    useEffect(() => {
        if (vendor?.pos) {
            set_pos({
                ...pos,
                subdomain: vendor?.pos?.subdomain,
                title: vendor?.pos?.title,
                description: vendor?.pos?.description,
                is_active: vendor?.pos?.is_active,
                products: vendor?.pos?.products?.map(({ id, name_en }) => ({
                    id,
                    label: name_en,
                    value: id,
                })),
            });
            vendor.pos?.og_tags &&
                set_og_tags(vendor.pos?.og_tags?.split(',').map((t) => ({ label: t, value: t })));
        }
    }, [vendor?.pos]);

    if (loading) {
        return 'loading...';
    }

    const full_url = `https://${pos?.subdomain}.disty.app`;

    return (
        <div className="social-pos">
            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            <h3 className="divider-title">Social POS configuration</h3>
            { !has_products && !loading_products &&  <div className="alert alert-warning">You can&apos;t add a Social POS configuration because you don&apos;t have any products</div>}
            <Row>
                <Col lg={6}>
                    <Row className="__ai-center">
                        <Col lg={9}>
                            <Input
                                label="Domain"
                                value={vendor?.pos ? full_url : pos?.subdomain}
                                setValue={handleChange}
                                name="subdomain"
                                disabled={vendor?.pos ? true : false}
                            />
                            {submmited && validation_errors.subdomain && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.subdomain}
                                </p>
                            )}
                        </Col>
                        <Col lg={3}>
                            {vendor?.pos && (
                                <div
                                    className="copy-url"
                                    onClick={() => {
                                        navigator.clipboard.writeText(full_url);
                                        setCopied(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 2000);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {!copied ? (
                                        <img src={copyIcon} />
                                    ) : (
                                        <p style={{ padding: '2px' }}>Copied!</p>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} className="pos-status">
                    <Toggler
                        label="Status"
                        checked={pos?.is_active}
                        onChange={handleChange}
                        name="is_active"
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FileUpload
                        label="Desktop cover image"
                        files={pos?.cover_image_desktop}
                        name="cover_image_desktop"
                        setFiles={handleChange}
                        isMulti
                    />
                     {submmited && validation_errors.cover_image_desktop && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.cover_image_desktop}
                                </p>
                            )}
                    {vendor?.pos?.cover_image_desktop && (
                        <ul className="thumpnails">
                            <li>
                                <img
                                    src={vendor?.pos?.cover_image_desktop}
                                    alt="Desktop Cover image"
                                />
                            </li>
                        </ul>
                    )}
                    <Input label="Title" value={pos?.title} setValue={handleChange} name="title" />
                    {submmited && validation_errors.title && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.title}
                                </p>
                            )}
                    <Textarea
                        label="Description"
                        setValue={handleChange}
                        value={pos?.description}
                        name="description"
                    />
                     {submmited && validation_errors.description && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.description}
                                </p>
                            )}
                </Col>
                <Col lg={6}>
                    <FileUpload
                        label="Mobile Cover Image"
                        name="cover_image_mobile"
                        files={pos?.cover_image_mobile}
                        setFiles={handleChange}
                    />
                     {submmited && validation_errors.cover_image_mobile && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.cover_image_mobile}
                                </p>
                            )}
                    {vendor?.pos?.cover_image_mobile && (
                        <ul className="thumpnails">
                            <li>
                                <img
                                    src={vendor?.pos?.cover_image_mobile}
                                    alt="Mobile Cover image"
                                />
                            </li>
                        </ul>
                    )}
                    <ApiAsyncSelect
                        api="/product"
                        params={{ vendor_id: id }}
                        label="Products"
                        placeholder="Type to add product..."
                        labelProperty={(p) => p?.name}
                        onChange={handleChange}
                        value={pos?.products}
                        name="products"
                        isMulti
                    />
                     {submmited && validation_errors.products && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.products}
                                </p>
                            )}
                    <CreatableTextSelect label="OG tags" value={og_tags} setValue={set_og_tags} />
                    {submmited && validation_errors.og_tags && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.og_tags}
                                </p>
                            )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <CriticalActionButton  onSubmit={() => {
                        set_submmited(true);
                        if(!validate()) return;
                        mutate();
                    }} disabled={!has_products}>
                        Save changes
                    </CriticalActionButton>
                </Col>
            </Row>
        </div>
    );
}