import React, { useEffect, useState } from 'react';
import { Button, FileUpload, Row, Col, Modal, Toggler } from '@components';
import {
    POST,
    obj2formdata,
    errorsNormalizer,
    randomId,
    // videoValidation,
    PATCH,
    DELETE,
} from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import useMutation from '@hooks/useMutation';
import { setModalStatus } from '@slices/ui.slice';
import { toast } from 'react-toastify';
import { loadProductData } from '@slices/products.slice';
import removeIcon from '@assets/icons/delete.svg';
import SelectVariantOrBase from '../../components/SelectVariantOrBase';

export const ACCEPTABLE_VIDEO_FORMATS = ['mp4', 'ogx', 'oga', 'ogv', 'ogg', 'webm'];
export const acceptedMimeTypes = ACCEPTABLE_VIDEO_FORMATS.map((format) => 'video/' + format).join(
    ', ',
);

export default function VideoTab() {
    return (
        <SelectVariantOrBase>
            {({ selected, isBaseSelected }) => (
                <UpdateVideo selectedId={selected} isBaseSelected={isBaseSelected} />
            )}
        </SelectVariantOrBase>
    );
}

function UpdateVideo({ selectedId, isBaseSelected }) {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.ProductsSlice.product);
    const [current_video, set_current_video] = useState();
    const [selected_video, set_selected_video] = useState({
        files: [],
        // validator: (file) => videoValidation(file, 15),
        setFiles: (val) => set_selected_video({ ...selected_video, files: val }),
        acceptedMimeTypes,
        hintMessage: 'Only videos files will be accepted',
    });

    useEffect(() => {
        if (Object.keys(product || {}).length) {
            const _current_video = isBaseSelected
                ? product?.video
                : product.variants.find(({ id }) => id === selectedId)?.video;
            set_current_video(_current_video);
        }
    }, [selectedId, isBaseSelected, product]);

    const updateTheVideo = () => {
        const endpoint = `/product/${selectedId}/video`;
        const data = obj2formdata({});
        const headers = {
            'Idempotency-Key': randomId(),
        };

        selected_video.files.length && data.append('video', selected_video.files[0]);

        return POST({ endpoint, data, headers });
    };

    const { isLoading, mutate } = useMutation(updateTheVideo, {
        onSuccess: (res) => {
            if (res?.status_code === 201 || res?.status_code === 200) {
                toast.success(res?.message);
                dispatch(loadProductData(product.id));
                selected_video.setFiles([]);
            } else {
                toast.error(res?.data?.message);
            }
        },
        onError: (errors) => toast.error(errorsNormalizer(errors).join(', ')),
    });

    const updateStatusAndDeleteMutation = useMutation(
        (isDelete = false) => {
            const headers = { 'Idempotency-Key': randomId() };
            if (isDelete) {
                return DELETE({
                    endpoint: `/product/video/${current_video?.id}`,
                    headers,
                });
            } else {
                return PATCH({
                    endpoint: `/product/video/${current_video?.id}/status`,
                    headers,
                });
            }
        },
        {
            onSuccess: (res) => {
                if (res?.status_code === 201 || res?.status_code === 200) {
                    toast.success(res?.message);
                    dispatch(loadProductData(product?.id));
                } else {
                    toast.error(res?.data?.message);
                }
            },
            onError: (errors) => toast.error(errorsNormalizer(errors).join(', ')),
        },
    );

    return (
        <div>
            <Row className="mb-4">
                <Col xs={12}>
                    <FileUpload {...selected_video} />
                    <Button
                        title={
                            selectedId
                                ? 'Submitting the new video'
                                : 'Please select a variant from the top'
                        }
                        onClick={() => {
                            dispatch(
                                setModalStatus({
                                    modal: 'update_product_video_confirm_modal',
                                    status: true,
                                }),
                            );
                        }}
                        disabled={!selected_video?.files?.length || isLoading || !selectedId}
                    >
                        {isLoading ? 'Uploading...' : 'Upload video'}
                    </Button>
                </Col>
            </Row>
            <h4>The video will be shown after the images of the product</h4>
            {current_video && (
                <div className="flex__jc-between">
                    <div className="video-wrapper">
                        <span
                            className="remove-icon"
                            onClick={() => updateStatusAndDeleteMutation.mutate(true)}
                        >
                            <img src={removeIcon} alt="x" />
                        </span>
                        <video key={current_video?.video} controls>
                            <source src={current_video?.video}></source>
                        </video>
                    </div>
                    <Toggler
                        label="Is Active"
                        checked={current_video?.is_active}
                        onChange={() => updateStatusAndDeleteMutation.mutate()}
                        disabled={updateStatusAndDeleteMutation.isLoading}
                    />
                </div>
            )}
            <Modal
                title={
                    // eslint-disable-next-line max-len
                    'Uploading a new video will replace the old one, are you sure you want to do that?'
                }
                name="update_product_video_confirm_modal"
                loading={isLoading}
                onSubmit={(closeModal) => {
                    mutate();
                    closeModal('update_product_video_confirm_modal');
                }}
            />
        </div>
    );
}
