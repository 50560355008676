import React from 'react';
import { Card, ViewHeader, DataTable, Link, Dialer, WhatsApp, Filters, Sop } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadAppFeedbacksData } from '@slices/appFeedback.slice';
import { openFullScreen } from '@utils';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function ListView() {
    const AppFeedbackSlice = useSelector((state) => state.AppFeedbackSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [filters, set_filters] = React.useState({});
    const [records_per_page, set_records_per_page] = React.useState(null);

    useDidMountEffect(() => {
        dispatch(loadAppFeedbacksData({ ...filters, page, records_per_page }));
    }, [page, records_per_page, filters]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
            cell: (row) => row?.id,
        },
        {
            name: 'Retailer Name',
            cell: (row) => (
                <Link href={`/customers/${row?.customer?.id}`} className="tr-name">
                    <h5>
                        {row?.customer?.first_name} {row?.customer?.last_name}
                    </h5>
                </Link>
            ),
        },
        {
            name: 'Feedback type',
            selector: 'type',
            cell: (row) => row?.type,
        },
        {
            name: 'Feedback details',
            selector: 'details',
            cell: (row) => row?.details,
        },
        {
            name: 'Picture',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) =>
                row.picture !== '' && (
                    <img
                        style={{ cursor: 'pointer' }}
                        width="100%"
                        onClick={({ target }) => openFullScreen(target)}
                        src={row.picture}
                        alt="Picture"
                    />
                ),
        },
        {
            name: 'Email',
            selector: 'email',
            cell: (row) => <a href={`mailto:${row?.customer?.email}`}>{row?.customer?.email}</a>,
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            width: '140px',
            cell: (row) => <Dialer number={row?.customer?.mobile} />,
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            cell: (row) => <WhatsApp number={row?.customer?.mobile} />,
        },
    ];

    return (
        <div>
            <ViewHeader title="App Feedbacks">
                <Sop id="25223" />
            </ViewHeader>

            <Card>
                <Filters
                    filters={['app_feedback_type']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={AppFeedbackSlice?.app_feedbacks}
                    pages={AppFeedbackSlice?.app_feedbacks_pages}
                    columns={columns}
                    page={page}
                    records={AppFeedbackSlice?.app_feedbacks_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    loading={AppFeedbackSlice?.app_feedbacks_loading}
                />
            </Card>
        </div>
    );
}
