import { useState, useEffect } from 'react';
import { Modal } from '@components';
import { formatStringWithUnderscores, formatMoney } from '@utils';
import { useSelector } from 'react-redux';
import avatarIcon from '@assets/icons/avatar.svg';

function ProductReviewModal({ data, handleSubmit, loading }) {
    const modals_status = useSelector((state) => state.UISlice.modals_status);
    const [disabled, set_disabled] = useState(false);
    const [product_data, set_product_data] = useState(data);

    useEffect(() => {
        if (modals_status['product__review_modal']) {
            set_disabled(true);
            let timer = setTimeout(() => {
                set_disabled(false);
            }, 4000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [modals_status]);

    useEffect(() => {
        Object.keys(data).forEach((key) => {
            if (
                (!data[key] && !data[key] === false) ||
                data[key] === '' ||
                (Array.isArray(data) && data.length)
            ) {
                delete data[key];
            } else {
                if (
                    key !== 'images' &&
                    key !== 'video' &&
                    key !== 'vendor' &&
                    key !== 'name_en' &&
                    key !== 'price' &&
                    key !== 'custom_attributes'
                ) {
                    data['details'] = { ...data['details'], [key]: data[key] };
                    delete data[key];
                }
            }
        });
        set_product_data(data);
    }, [data]);

    return (
        <Modal
            size="lg"
            title="Review the product details"
            name="product__review_modal"
            submitBtnDisabled={disabled || loading}
            onSubmit={(closeModal) => {
                handleSubmit(closeModal);
            }}
        >
            {!!product_data && !!Object.keys(product_data).length && (
                <div className="product-preview">
                    <section className="product-gallery">
                        {product_data.images &&
                            product_data?.images?.map((img, i) => (
                                <div className="gallery-item" key={i}>
                                    <img src={img} />
                                </div>
                            ))}
                        {product_data?.video && (
                            <div className="gallery-item">
                                <video controls>
                                    <source src={product_data.video}></source>
                                </video>
                            </div>
                        )}
                    </section>
                    {product_data?.vendor && (
                        <div className="vendor-name">
                            <div className="icon">
                                <img src={avatarIcon} alt="Vendor" />
                            </div>
                            <h3>{product_data.vendor}</h3>
                        </div>
                    )}
                    {!!product_data?.name_en && (
                        <div className="product-name">
                            <h1>{product_data.name_en}</h1>
                            {!!product_data?.price && (
                                <div className="price">
                                    {formatMoney(product_data?.price)}
                                    <span>SAR</span>
                                </div>
                            )}
                        </div>
                    )}
                    {product_data?.details && Object.keys(product_data?.details || {}) && (
                        <div className="product-details">
                            <label>Product details</label>
                            {Object.entries(product_data.details)?.map(([key, value]) => (
                                <div className="key-value-row" key={key}>
                                    <label>{formatStringWithUnderscores(key)}</label>
                                    <span>{renderValue(value)}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    {!!product_data?.custom_attributes?.length && (
                        <div className="custom-attributes">
                            <label>Custom attributes</label>
                            {product_data.custom_attributes.map((attr, i) => (
                                <div className="key-value-row" key={i}>
                                    <label>{attr.name_en}</label>
                                    <span>
                                        {attr.value_en || ''} {attr.unit_en || ''}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
}

const renderValue = (value) => {
    if (typeof value === 'boolean') return value ? 'true' : 'false';

    return value;
};

export default ProductReviewModal;
