import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

const initialState = {
    self_invoices: [],
    self_invoices_loading: true,
    self_invoices_pages: null,
    self_invoices_records: null,

    self_invoice: {},
    self_invoice_loading: true,

    audits: {},
    audits_loading: true,
};

export const SelfInvoicesSlice = createSlice({
    name: 'selfInvoices',
    initialState,
    reducers: {
        setSelfInvoices(state, { payload }) {
            state.self_invoices = payload;
        },
        setSelfInvoice(state, { payload }) {
            state.self_invoice = payload;
        },
        setAudits(state, { payload }) {
            state.audits = payload;
        },
        setSelfInvoicesTotalPages(state, { payload }) {
            state.self_invoices_pages = payload;
        },
        setSelfInvoicesRecords(state, { payload }) {
            state.self_invoices_records = payload;
        },

        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
    },
});

// export actions
export const {
    setSelfInvoices,
    setSelfInvoice,
    setAudits,
    setSelfInvoicesTotalPages,
    setSelfInvoicesRecords,
    setLoading,
} = SelfInvoicesSlice.actions;

export const loadSelfInvoicesData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'self_invoices_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/self-invoices?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setSelfInvoices(res?.data || []));
    if(res?.meta){
        dispatch(setSelfInvoicesTotalPages(res?.meta?.last_page || 0));
        dispatch(setSelfInvoicesRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'self_invoices_loading',
            value: false,
        })
    );
};

export const loadSelfInvoiceData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'self_invoice_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/self-invoices/${id}`,
    });
    dispatch(setSelfInvoice(res?.data || {}));
    dispatch(
        setLoading({
            key: 'self_invoice_loading',
            value: false,
        })
    );
};

export const loadAuditsData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'audits_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/self-invoices/${id}/audits`,
    });
    dispatch(setAudits(res?.data || {}));
    dispatch(
        setLoading({
            key: 'audits_loading',
            value: false,
        })
    );
};

export default SelfInvoicesSlice.reducer;
