import { useEffect } from 'react';
import { Comments, Card } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadPayoutComments, createPayoutComment, resetComments } from '@slices/payouts.slice';

const PayoutComments = ({ payout_id }) => {
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();

    const comments = PayoutsSlice.comments;
    const loading = !comments.length && PayoutsSlice.comments_loading;
    const errors = PayoutsSlice.comments_errors;

    const fetchComments = async () => {
        dispatch(resetComments());
        await dispatch(loadPayoutComments(payout_id));
    };

    const createComment = async (c, u) => dispatch(createPayoutComment(payout_id, c, u));

    useEffect(fetchComments, []);

    if (loading || Object.keys(errors).length)
        return (
            <Card title="Payout Comments">
                {loading && <div className="p-3">Loading...</div>}

                {!!Object.keys(errors).length && (
                    <div className="mx-3 alert alert-danger">
                        <ul>
                            {Object.keys(errors).map((key) => {
                                return <li key={key}>{errors[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
            </Card>
        );

    return (
        <Comments
            comments={PayoutsSlice.comments}
            onSubmit={createComment}
            listStyle={{ height: 175, overflowY: 'auto' }}
            title="Payout Comments"
        />
    );
};

export default PayoutComments;
