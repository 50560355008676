import { useEffect } from 'react';
import { GET } from '@utils';
import useMutation from './useMutation';
import { stringify } from 'query-string';

const handleGetRequest = (url, params = {}) => {
    const queryString = stringify(params, {
        skipNull: true,
        arrayFormat: 'comma',
        skipEmptyString: true,
    });

    return GET({
        endpoint: queryString ? `${url}?${queryString}` : url,
        options: {
            throwError: true,
        },
    });
};

/**
 *
 * @param {string} url
 * @param {Record<string, string| number | string[] | number[]>} params
 * @param {import('./useMutation').Config & { stop?: boolean, public?: boolean }} config
 * @returns
 */
export default function useQuery(url, params = {}, config = { stop: false }) {
    const stringParams = JSON.stringify(params);

    const { mutate, isLoading, data, error } = useMutation(
        () => handleGetRequest(url, params),
        config,
    );

    const refetch = () => mutate();

    useEffect(() => {
        if (!config?.stop && url) {
            mutate();
        }
    }, [stringParams, url]);

    return {
        refetch,
        isLoading: isLoading,
        error: error,
        data: data,
    };
}
