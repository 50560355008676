import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const VendorAcquisitionsSlice = createSlice({
    name: 'vendorUsers',
    initialState: {
        vendorAcquisitions: [],
        vendorAcquisitions_pages: null,
        vendorAcquisitions_records: null,
        vendorAcquisitions_loading: true,
    },
    reducers: {
        setVendorAcquisitions(state, { payload }) {
            state.vendorAcquisitions = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVendorAcquisitionsTotalPages(state, { payload }) {
            state.vendorAcquisitions_pages = payload;
        },
        setVendorAcquisitionsRecords(state, { payload }) {
            state.vendorAcquisitions_records = payload;
        },
    },
});

// export actions
export const {
    setVendorAcquisitions,
    setLoading,
    setVendorAcquisitionsTotalPages,
    setVendorAcquisitionsRecords,
} = VendorAcquisitionsSlice.actions;

export const loadVendorAcquisitionsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendorAcquisitions_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/acquisition/acquisitions?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`
    });
    dispatch(setVendorAcquisitions(res?.data || []));
    if(res?.meta){
        dispatch(setVendorAcquisitionsTotalPages(res?.meta?.last_page || 1));
        dispatch(setVendorAcquisitionsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'vendorAcquisitions_loading',
            value: false,
        })
    );
};

export default VendorAcquisitionsSlice.reducer;