import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import CategoriesListView from './CategoriesListView';
import CategoriesAddView from './CategoriesAddView';
import CategoriesUpdateView from './CategoriesUpdateView';
import { Helmet } from 'react-helmet';

export default function CategoriesModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/categories/list">
                <Helmet>
                    <title>Categories | Disty Admin Dashboard</title>
                </Helmet>
                <CategoriesListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/categories/add">
                <Helmet>
                    <title>Add Category | Disty Admin Dashboard</title>
                </Helmet>
                <CategoriesAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/categories/:id">
                <Helmet>
                    <title>Edit Category | Disty Admin Dashboard</title>
                </Helmet>
                <CategoriesUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
