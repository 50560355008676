/* eslint-disable */
import React, { useState } from 'react';

import {
    Card,
    StatusBlock,
    ViewHeader,
    DataTable,
    Dialer,
    WhatsApp,
    Filters,
    Sop,
    Tabs
} from '@components';
import { formatDate } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { loadVendorProductRequestsData } from '@store/slices/merchantProductRequests.slice';
import { Link } from 'react-router-dom';
import defaultLogo from '@assets/img/default.jpg';
import useDidMountEffect from '@hooks/useDidMountEffect';


const VendorRequests = () => {
    const VendorProductRequestsSlice = useSelector((state) => state.VendorProductRequestsSlice);

    const dispatch = useDispatch();
    const [tabs, set_tabs] = useState([
        { key: 'create', name: 'Product creation requests', isActive: true },
        { key: 'update', name: 'Product update requests' },
    ]);

    const [filters, set_filters] = React.useState('');
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [type, set_type] = React.useState('create');

    const columns = [
        {
            name: 'ID',
            width: '100px',
            cell: (row) => <Link to={`/vendor-product-requests/${row.id}`}>#{row.id}</Link>,
        },
        {
            name: 'Merchant ID',
            width: '100px',
            cell: (row) => (
                <Link to={`/merchants/${row.vendor.id}`}>
                    <span>
                        {row.vendor.id}
                    </span>
                </Link>
            ),
        },
        {
            width: '80px',
            name: 'Logo',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.vendor.logo_en || defaultLogo} alt={row.vendor.company_name} />
                </div>
            ),
        },
        {
            name: 'Name',
            cell: (row) => (
                <Link to={`/merchants/${row.vendor.id}`}>
                    <span>
                        {row.vendor.company_name}
                    </span>
                </Link>
            ),
        },
        {
            name: 'Request type',
            width: '150px',
            cell: (row) => (
                <Link to={`/vendor-product-requests/${row.id}`}>
                    <StatusBlock
                        noIcon
                        state={{
                            code: 500,
                            description: row.type?.description,
                        }}
                    />
                </Link>
            ),
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            width: '160px',
            cell: (row) => <Dialer number={row.vendor.mobile} />
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '100px',
            center: true,
            cell: (row) => <WhatsApp number={row.vendor.mobile} />
        },
        {
            name: 'Account Manager',
            cell: ({ vendor: { account_manager } }) => (
                <span>{account_manager?.first_name} {account_manager?.last_name}</span>
            )
        },
        {
            name: 'Status',
            width: '200px',
            cell: (row) => <StatusBlock noIcon state={row.state} />
        },
        {
            name: 'Created at',
            cell: (row) => formatDate(row?.created_at)
        },
        {
            name: 'Updated at',
            cell: (row) => formatDate(row?.updated_at)
        },
    ];

    const fetchData = async () => {
        dispatch(loadVendorProductRequestsData({
            ...filters,
            page,
            records_per_page,
            type,
        }));
    };

    const handleTabs = (tabs) => {
        const active_tab = tabs.find(t => t.isActive);
        set_tabs(tabs);
        set_type(active_tab.key);
    }

    useDidMountEffect(() => {
        fetchData();
    }, [filters, type, page, records_per_page]);

    return (
        <div>
            <ViewHeader title="Merchant Product Requests">
                <Sop id="29923" />
            </ViewHeader>

            <Tabs tabs={tabs} onSelect={handleTabs} />

            <Card>
                <Filters
                    queryPlaceholder="Search IDs, Merchant IDs Request Names."
                    filters={[
                        'vendor_product_request_status',
                        'vendors',
                        'account_manager_ids',
                        'from',
                        'to',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    sort_options={[
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={VendorProductRequestsSlice?.vendor_product_requests}
                    pages={VendorProductRequestsSlice?.vendor_product_requests_pages}
                    columns={columns}
                    page={page}
                    records={VendorProductRequestsSlice?.vendor_product_requests_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    searchable={false}
                    handlePageChange={set_page}
                    loading={VendorProductRequestsSlice?.vendor_product_requests_loading}
                />
            </Card>
        </div>
    );
};


export default VendorRequests;