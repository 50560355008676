import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Login';
import Logout from './Logout';
import Root from './Root';
import { Helmet } from 'react-helmet';

export default function AuthModuleRoutes() {
    return (
        <Switch>
            <Route exact path="/login">
                <Helmet>
                    <title>Login | Disty Admin Dashboard</title>
                </Helmet>
                <Login />
            </Route>   
            <Route exact path="/logout">
                <Helmet>
                    <title>Logging out | Disty Admin Dashboard</title>
                </Helmet>
                <Logout />
            </Route>   
            <Route exact path="/">
                <Root />
            </Route>   
        </Switch>
    );
}
