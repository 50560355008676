/* eslint-disable max-len */
import React from 'react';
import {
    Button,
    ViewHeader,
    DataTable,
    Card,
    Link,
    Input,
    Modal,
    Select,
    Dialer,
    Filters,
    ShowErrors,
    Sop,
} from '@components';
import { PATCH, GET, formatDate, formatMoney, confirmationMessage } from '@utils';
import { loadCustomersData, updateCustomerInListing } from '@slices/customers.slice';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '../../store/slices/ui.slice';
import editIcon from '@assets/icons/edit.svg';
import { stringify } from 'query-string';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function CustomersListView() {
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const CustomersSlice = useSelector((state) => state.CustomersSlice);

    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    const [edit_customer, set_edit_customer] = React.useState({});
    const [errors, set_errors] = React.useState([]);

    const [loading_targets_totals, set_loading_targets_totals] = React.useState(false);
    const [targets_totals, set_targets_totals] = React.useState({
        monthly_target: 0,
        purchases_amount: 0,
    });

    const [loading, set_loading] = React.useState(false);
    const [new_monthly_target, set_new_monthly_target] = React.useState({
        label: 'Monthly target',
        value: '',
        type: 'number',
        setValue: (val) => set_new_monthly_target({ ...new_monthly_target, value: val }),
    });

    const [new_account_manager, set_new_account_manager] = React.useState({
        label: 'New Account manager',
        options: [],
        value: {},
        getOptionValue: (option) => option.id,
        onChange: (val) => set_new_account_manager({ ...new_account_manager, value: val }),
    });

    const [filters, set_filters] = React.useState({});

    useDidMountEffect(() => {
        dispatch(
            loadCustomersData({
                ...filters,
                page,
                records_per_page,
            }),
        );
    }, [filters, page, records_per_page]);

    React.useEffect(() => {
        fetchTargetsTotals(filters.account_managers);
    }, [filters.account_managers]);

    // React.useEffect(() => {
    //     console.log(GlobalsSlice.accountManagers);
    //     set_new_account_manager(prev => ({
    //         ...prev,
    //         options:
    //     }));
    // }, [GlobalsSlice.accountManagers]);

    const fetchTargetsTotals = async (account_managers) => {
        set_loading_targets_totals(true);

        const res = await GET({
            endpoint: `/customers/targets?${stringify(
                { account_managers },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                },
            )}`,
        });

        set_loading_targets_totals(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            set_targets_totals(res?.data);
        }
    };

    const updateTarget = async (closeModal, { id }) => {
        set_loading(true);

        const res = await PATCH({
            endpoint: `/customers/${id}/monthly-target?monthly_target=${new_monthly_target.value}`,
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('update_monthly_target_modal');
            dispatch(updateCustomerInListing(res.data));
            set_errors([]);
        }
    };

    const updateAccountManager = async (closeModal, { id }) => {
        set_loading(true);

        const res = await PATCH({
            endpoint: `/customers/${id}/account-manager?account_manager=${new_account_manager?.value?.id}`,
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('update_account_manager_modal');
            dispatch(updateCustomerInListing(res.data));
            set_errors([]);
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            center: true,
            width: '100px',
            cell: (row) => (
                <Link href={`/customers/${row.id}`} className="tr-name">
                    {row.id}
                </Link>
            ),
        },
        // {
        //     name: 'Logo',
        //     selector: 'logo',
        //     center: true,
        //     width: '100px',
        //     cell: (row) => (
        //         <div className="tr-logo">
        //             <img src={row.logo || defaultLogo} alt="Logo" />
        //         </div>
        //     )
        // },
        {
            name: 'Retail Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/customers/${row.id}`} className="tr-name">
                    <h5>
                        {row.store_name} | {row.state}
                    </h5>
                </Link>
            ),
        },
        {
            name: 'Business Mobile',
            selector: 'mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Business Email',
            selector: 'email',
            // minWidth: '130px',
            cell: (row) => row?.email,
        },
        {
            name: 'City',
            selector: 'city',
            sortable: false,
            cell: (row) => <>{row?.city?.name}</>,
        },
        {
            name: 'Created date',
            selector: 'created_date',
            sortable: false,
            cell: (row) => <>{formatDate(row.created_date)}</>,
        },
        {
            name: 'Following',
            selector: 'follows_count',
            sortable: false,
            center: true,
            cell: (row) => (
                <Link href={`/customers/${row.id}?tab_index=9`} className="tr-name">
                    {row?.follows_count}
                </Link>
            ),
        },
        {
            name: 'Total orders',
            selector: 'total_orders',
            sortable: false,
            center: true,
        },
        {
            name: 'Total sales',
            selector: 'total_spent',
            sortable: false,
            center: true,
            cell: (row) => formatMoney(row.total_spent),
        },
        {
            name: 'Total live cart',
            selector: 'total_live_cart',
            center: true,
            cell: (row) => formatMoney(row.total_live_cart),
        },
        {
            name: 'Monthly target',
            selector: 'monthly_target',
            cell: (row) => (
                <span>
                    {formatMoney(row.monthly_target)}
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_customer(row);
                            new_monthly_target.setValue(row.monthly_target);
                            set_errors([]);

                            dispatch(
                                setModalStatus({
                                    modal: 'update_monthly_target_modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </span>
            ),
        },
        {
            name: 'This month achieved',
            selector: 'month_achieved',
            cell: (row) => (
                <span>
                    {formatMoney(row.month_achieved)}
                    &nbsp; - &nbsp;
                    {row.achieved_percentage ? +row.achieved_percentage?.toFixed(2) : 0}%
                </span>
            ),
        },
        {
            name: 'Balance',
            selector: 'balance',
            center: true,
            cell: (row) => formatMoney(row.balance),
        },
        {
            name: 'Life time',
            selector: 'life_time',
        },
        {
            name: 'Account manager',
            selector: 'account_manager',
            cell: (row) => (
                <span>
                    {row?.account_manager
                        ? `${row?.account_manager?.first_name} ${row?.account_manager?.last_name}`
                        : ''}
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_customer(row);
                            set_errors([]);

                            dispatch(
                                setModalStatus({
                                    modal: 'update_account_manager_modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </span>
            ),
        },
        {
            name: 'Acquisition manager',
            selector: 'acquisition_user',
            width: '80px',
            cell: (row) =>
                row?.acquisition_user
                    ? `${row?.acquisition_user?.first_name} ${row?.acquisition_user?.last_name}`
                    : '',
        },
    ];

    const CustomersTargetsTotals = () => {
        if (loading_targets_totals) return 'loading...';

        const percentage = targets_totals.purchases_amount
            ? ((+targets_totals.purchases_amount * 100) / +targets_totals.monthly_target).toFixed(2)
            : 0;

        return (
            <div className="totals-wrapper">
                {formatMoney(+targets_totals.purchases_amount)} SAR purchases ({percentage}%) &nbsp;
                / &nbsp;
                <small>{formatMoney(+targets_totals.monthly_target)} SAR target</small>
            </div>
        );
    };

    return (
        <div className="customers-list-view">
            <ViewHeader title="Retails">
                <div className="flex__ai-center">
                    <CustomersTargetsTotals />
                    <Sop id="25183" className="ml-2" />
                    <Link href="/customers/add" className="btn btn-primary">
                        New Retail
                    </Link>
                </div>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by name, mobile, email or ID"
                    filters={[
                        'status',
                        'regions',
                        'cities',
                        'entity_types',
                        'vendors',
                        'account_managers',
                        'verification_statuses',
                        'has_wallet_balance',
                        ...(filters?.has_wallet_balance?.includes(1) ? ['wallet_balance'] : []),
                        'acquisition_users',
                        'total_orders',
                        'follows_count',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'total_orders',
                        sort_direction: 'asc',
                    }}
                    sort_options={[
                        {
                            label: 'ID (DESC)',
                            value: 'id__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'ID (ASC)',
                            value: 'id__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Status (DESC)',
                            value: 'status__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Status (ASC)',
                            value: 'status__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Total Orders (DESC)',
                            value: 'total_orders__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Total Orders (ASC)',
                            value: 'total_orders__asc',
                            direction: 'asc',
                        },

                        {
                            label: 'Total Spent (Newest first)',
                            value: 'total_spent__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Total Spent (Oldest first)',
                            value: 'total_spent__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Verification Requested Date (Newest first)',
                            value: 'verification_requested_date__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Verification Requested Date (Oldest first)',
                            value: 'verification_requested_date__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={CustomersSlice.customers}
                    pages={CustomersSlice.customers_pages}
                    records={CustomersSlice.customers_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    page={page}
                    handlePageChange={set_page}
                    columns={columns}
                    loading={CustomersSlice.customers_loading}
                />
            </Card>

            <Modal
                title={`Update ${edit_customer.store_name}'s monthly target`}
                name="update_monthly_target_modal"
                onSubmit={(closeModal) => {
                    edit_customer?.status
                        ? updateTarget(closeModal, edit_customer)
                        : confirmationMessage(() => updateTarget(closeModal, edit_customer));
                }}
                loading={loading}
            >
                <Input {...new_monthly_target} />

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            </Modal>

            <Modal
                title={`Update ${edit_customer.store_name}'s account manager`}
                name="update_account_manager_modal"
                onSubmit={(closeModal) => {
                    edit_customer?.status
                        ? updateAccountManager(closeModal, edit_customer)
                        : confirmationMessage(() =>
                              updateAccountManager(closeModal, edit_customer),
                          );
                }}
                loading={loading}
            >
                <label>
                    Current account manager : {edit_customer?.account_manager?.first_name || ''}
                </label>
                <Select {...new_account_manager} options={GlobalsSlice.accountManagers} />

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            </Modal>
        </div>
    );
}
