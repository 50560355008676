import React from 'react';
import {
    DataTable,
    Button,
    Modal,
    Toggler,
    ShowErrors
} from '@components';
import { PUT, randomId } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadPushNotificationSettingsData } from '@slices/settings.slice';

const PushNotificationSettingsTab = () => {
    const SettingsSlice = useSelector((state) => state.SettingsSlice);
    const dispatch = useDispatch();
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [order_placed_notification, set_order_placed_notification] = React.useState({
        label:'Order placed notification',
        checked: true,
        onChange: (val) => set_order_placed_notification(
            { ...order_placed_notification, checked: val }),
    });
    const [suborder_partially_confirmed_notification, 
        set_suborder_partially_confirmed_notification] = React.useState({
        label: 'Suborder partially confirmed notification',
        checked: true,
        onChange: (val) => set_suborder_partially_confirmed_notification(
            { ...suborder_partially_confirmed_notification, checked: val }),
    });

    const [suborder_delivered_notification, 
        set_suborder_delivered_notification] = React.useState({
        label: 'Suborder delivered notification',
        checked: true,
        onChange: (val) => set_suborder_delivered_notification(
            { ...suborder_delivered_notification, checked: val }),
    });
    const [suborder_confirmed_notification, 
        set_suborder_confirmed_notification] = React.useState({
        label: 'Suborder confirmed notification',
        checked: true,
        onChange: (val) => set_suborder_confirmed_notification(
            { ...suborder_confirmed_notification, checked: val }),
    });
    const [suborder_ready_for_pickup_notification, 
        set_suborder_ready_for_pickup_notification] = React.useState({
        label: 'Suborder Ready for pickup notification',
        checked: true,
        onChange: (val) => set_suborder_ready_for_pickup_notification(
            { ...suborder_ready_for_pickup_notification, checked: val }),
    });
    const [suborder_picked_up_notification, 
        set_suborder_picked_up_notification] = React.useState({
        label: 'Suborder picked up notification',
        checked: true,
        onChange: (val) => set_suborder_picked_up_notification(
            { ...suborder_picked_up_notification, checked: val }),
    });
    const [suborder_received_in_warehouse_notification, 
        set_suborder_received_in_warehouse_notification] = React.useState({
        label: 'Suborder received in warehouse notification',
        checked: true,
        onChange: (val) => set_suborder_received_in_warehouse_notification(
            { ...suborder_received_in_warehouse_notification, checked: val }),
    });
    const [suborder_under_fulfillment_notification, 
        set_suborder_under_fulfillment_notification] = React.useState({
        label: 'Suborder under fulfillment notification',
        checked: true,
        onChange: (val) => set_suborder_under_fulfillment_notification(
            { ...suborder_under_fulfillment_notification, checked: val }),
    });
    const [suborder_ready_for_dispatch_notification, 
        set_suborder_ready_for_dispatch_notification] = React.useState({
        label: 'Suborder ready for dispatch notification',
        checked: true,
        onChange: (val) => set_suborder_ready_for_dispatch_notification(
            { ...suborder_ready_for_dispatch_notification, checked: val }),
    });
    const [suborder_out_for_delivery_notification, 
        set_suborder_out_for_delivery_notification] = React.useState({
        label: 'Suborder out for delivery notification',
        checked: true,
        onChange: (val) => set_suborder_out_for_delivery_notification(
            { ...suborder_out_for_delivery_notification, checked: val }),
    });
    const [suborder_fulfilled_notification, 
        set_suborder_fulfilled_notification] = React.useState({
        label: 'Suborder fulfilled notification',
        checked: true,
        onChange: (val) => set_suborder_fulfilled_notification(
            { ...suborder_fulfilled_notification, checked: val }),
    });
    const [suborder_canceled_notification, 
        set_suborder_canceled_notification] = React.useState({
        label: 'Suborder canceled notification',
        checked: true,
        onChange: (val) => set_suborder_canceled_notification(
            { ...suborder_canceled_notification, checked: val }),
    });

    const [canceled_ready_for_pickup_notification, 
        set_canceled_ready_for_pickup_notification] = React.useState({
        label: 'Canceled ready for pickup notification',
        checked: true,
        onChange: (val) => set_canceled_ready_for_pickup_notification(
            { ...canceled_ready_for_pickup_notification, checked: val }),
    });
    
    const [suborder_fulfillment_delayed_notification, 
        set_suborder_fulfillment_delayed_notification] = React.useState({
        label: 'Suborder fulfillment delayed notification',
        checked: true,
        onChange: (val) => set_suborder_fulfillment_delayed_notification(
            { ...suborder_fulfillment_delayed_notification, checked: val }),
    });
    const [sadad_transaction_received_notification, 
        set_sadad_transaction_received_notification] = React.useState({
        label: 'Sadad transaction received notification',
        checked: true,
        onChange: (val) => set_sadad_transaction_received_notification(
            { ...sadad_transaction_received_notification, checked: val }),
    });
    const [wire_transfer_transaction_received_notification, 
        set_wire_transfer_transaction_received_notification] = React.useState({
        label: 'Wire transfer transaction received notification',
        checked: true,
        onChange: (val) => set_wire_transfer_transaction_received_notification(
            { ...wire_transfer_transaction_received_notification, checked: val }),
    });

    React.useEffect(() => {
        dispatch(loadPushNotificationSettingsData());
    }, [rerender]);

    const submit = async (closeModal) => {
        set_loading(true);
        set_errors([]);

        const res = await PUT({
            endpoint: '/notifications/push-notification',
            data: {
                order_placed_notification: 
                order_placed_notification?.checked ? 1 : 0,
                suborder_partially_confirmed_notification: 
                suborder_partially_confirmed_notification?.checked ? 1 : 0,
                suborder_delivered_notification: 
                suborder_delivered_notification?.checked ? 1 : 0,
                suborder_confirmed_notification: suborder_confirmed_notification?.checked ? 1 : 0,
                suborder_ready_for_pickup_notification: 
                suborder_ready_for_pickup_notification?.checked ? 1 : 0,
                suborder_picked_up_notification: 
                suborder_picked_up_notification?.checked ? 1 : 0,
                suborder_received_in_warehouse_notification: 
                suborder_received_in_warehouse_notification?.checked ? 1 : 0,
                suborder_under_fulfillment_notification: 
                suborder_under_fulfillment_notification?.checked ? 1: 0,
                suborder_ready_for_dispatch_notification: 
                suborder_ready_for_dispatch_notification?.checked ? 1 : 0,
                suborder_out_for_delivery_notification: 
                suborder_out_for_delivery_notification?.checked ? 1 : 0,
                suborder_fulfilled_notification: 
                suborder_fulfilled_notification?.checked ? 1 : 0,
                suborder_canceled_notification: 
                suborder_canceled_notification?.checked ? 1 : 0,
                canceled_ready_for_pickup_notification: 
                canceled_ready_for_pickup_notification?.checked ? 1 : 0,
                suborder_fulfillment_delayed_notification: 
                suborder_fulfillment_delayed_notification?.checked ? 1 : 0,
                sadad_transaction_received_notification: 
                sadad_transaction_received_notification?.checked ? 1 : 0,
                wire_transfer_transaction_received_notification: 
                wire_transfer_transaction_received_notification?.checked ? 1 : 0
            }
        });
        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('edit_pushNotification_settings__modal');
            set_rerender(randomId());
        }
    };

    const data = [
        {
            key: 'Order placed notification',
            value: SettingsSlice?.pushNotificationSettings?.order_placed_notification,
        },
        {
            key: 'Suborder partially confirmed notification',
            value: SettingsSlice?.pushNotificationSettings?.
			    suborder_partially_confirmed_notification,
        },
        {
            key: 'Suborder delivered notification',
            value: SettingsSlice?.pushNotificationSettings?.
            suborder_delivered_notification,
        },
        {
            key: 'Suborder confirmed notification',
            value: SettingsSlice?.pushNotificationSettings?.suborder_confirmed_notification,
        },
        {
            key: 'Suborder Ready for pickup notification',
            value: SettingsSlice?.pushNotificationSettings?.suborder_ready_for_pickup_notification,
        },
        {
            key: 'Suborder picked up notification',
            value: SettingsSlice?.pushNotificationSettings?.suborder_picked_up_notification,
        },
        {
            key: 'Suborder received in warehouse notification',
            value: SettingsSlice?.pushNotificationSettings?.
			    suborder_received_in_warehouse_notification,
        },
        {
            key: 'Suborder under fulfillment notification',
            value: SettingsSlice?.pushNotificationSettings?.suborder_under_fulfillment_notification,
        },
        {
            key: 'Suborder ready for dispatch notification',
            value: SettingsSlice?.pushNotificationSettings?.
			    suborder_ready_for_dispatch_notification,
        },
        {
            key: 'Suborder out for delivery notification',
            value: SettingsSlice?.pushNotificationSettings?.suborder_out_for_delivery_notification,
        },
        {
            key: 'Suborder fulfilled notification',
            value: SettingsSlice?.pushNotificationSettings?.suborder_fulfilled_notification,
        },
        {
            key: 'Suborder canceled notification',
            value: SettingsSlice?.pushNotificationSettings?.suborder_canceled_notification,
        },
        {
            key: 'Canceled ready for pickup notification',
            value: SettingsSlice?.pushNotificationSettings?.canceled_ready_for_pickup_notification,
        },
        {
            key: 'Suborder fulfillment delayed notification',
            value: SettingsSlice?.pushNotificationSettings?.
			    suborder_fulfillment_delayed_notification,
        },
        {
            key: 'Sadad transaction received notification',
            value: SettingsSlice?.pushNotificationSettings?.
			    sadad_transaction_received_notification,
        },
        {
            key: 'Wire transfer transaction received notification',
            value: SettingsSlice?.pushNotificationSettings?.
			    wire_transfer_transaction_received_notification,
        },
    ];

    const columns = [
	    {
            name: 'Setting',
            cell: (row) => row?.key
        },
        {
            name: 'Value',
            center: true,
            cell: (row) => (
                <div
                    className={`status-indicator ${
                        row.value && 'active-indicator'
                    }`}
                >
                    {row.value ? 'Active' : 'Inactive'}
                </div>
            )
        },
    ];

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                loading={SettingsSlice?.pushNotificationSettings_loading}
            />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        order_placed_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.order_placed_notification);
                        suborder_partially_confirmed_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							   suborder_partially_confirmed_notification);
                               suborder_delivered_notification.onChange( 
                                SettingsSlice?.pushNotificationSettings?.
                                suborder_delivered_notification);
                        suborder_confirmed_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							   suborder_confirmed_notification);
                        suborder_ready_for_pickup_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_ready_for_pickup_notification);
                        suborder_picked_up_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_picked_up_notification);
                        suborder_received_in_warehouse_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_received_in_warehouse_notification);
                        suborder_under_fulfillment_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_under_fulfillment_notification);
                        suborder_ready_for_dispatch_notification.onChange(
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_ready_for_dispatch_notification);
                        suborder_out_for_delivery_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_out_for_delivery_notification);
                        suborder_fulfilled_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_fulfilled_notification);
                        suborder_canceled_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_canceled_notification);

                                canceled_ready_for_pickup_notification.onChange( 
                                    SettingsSlice?.pushNotificationSettings?.
                                    canceled_ready_for_pickup_notification);
                        suborder_fulfillment_delayed_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    suborder_fulfillment_delayed_notification);
                        sadad_transaction_received_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    sadad_transaction_received_notification);
                        wire_transfer_transaction_received_notification.onChange( 
                            SettingsSlice?.pushNotificationSettings?.
							    wire_transfer_transaction_received_notification);
                        set_errors([]);
                        dispatch(
                            setModalStatus({
                                modal: 'edit_pushNotification_settings__modal',
                                status: true
                            })
                        );
                    }}
                >
                    Edit Push Notification settings
                </Button>
            </div>
            <Modal
                title={'Update Push Notification Settings'}
                variant={'warning'}
                name="edit_pushNotification_settings__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal);
                }}
                loading={loading}
            >

                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
                <Toggler
                    {...order_placed_notification}
                />
                <Toggler
                    {...suborder_partially_confirmed_notification}
                />
                 <Toggler
                    {...suborder_delivered_notification}
                />
                <Toggler
                    {...suborder_confirmed_notification}
                />
                <Toggler
                    {...suborder_ready_for_pickup_notification}
                />
                <Toggler
                    {...suborder_picked_up_notification}
                />
                <Toggler
                    {...suborder_received_in_warehouse_notification}
                />
                <Toggler
                    {...suborder_under_fulfillment_notification}
                />
                <Toggler
                    {...suborder_ready_for_dispatch_notification}
                />
                <Toggler
                    {...suborder_out_for_delivery_notification}
                />
                <Toggler
                    {...suborder_fulfilled_notification}
                />
                <Toggler
                    {...suborder_canceled_notification}
                />
                 <Toggler
                    {...canceled_ready_for_pickup_notification}
                />
                <Toggler
                    {...suborder_fulfillment_delayed_notification}
                />
                <Toggler
                    {...sadad_transaction_received_notification}
                />
                <Toggler
                    {...wire_transfer_transaction_received_notification}
                />
            </Modal>
        </>
    );
};

export default PushNotificationSettingsTab;