import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const UsersGroupsSlice = createSlice({
    name: 'usersGroups',
    initialState: {
        usersGroups: [],
        usersGroups_pages: null,
        usersGroups_records: null,
        usersGroup: {},
        usersGroups_loading: true,
        usersGroup_loading: true,
    },
    reducers: {
        setUsersGroups(state, { payload }) {
            state.usersGroups = payload;
        },
        setUsersGroup(state, { payload }) {
            state.usersGroup = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setUsersGroupsTotalPages(state, { payload }) {
            state.usersGroups_pages = payload;
        },
        setUsersGroupsRecords(state, { payload }) {
            state.usersGroups_records = payload;
        },
    },
});

// export actions
export const {
    setUsersGroup,
    setUsersGroups,
    setLoading,
    setUsersGroupsTotalPages,
    setUsersGroupsRecords,
} = UsersGroupsSlice.actions;

export const loadUsersGroupsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'usersGroups_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/role?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setUsersGroups(res?.data || []));
    if(res?.meta){
        dispatch(setUsersGroupsTotalPages(res?.meta?.last_page || 0));
        dispatch(setUsersGroupsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'usersGroups_loading',
            value: false,
        })
    );
};

export const loadUsersGroupData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'usersGroup_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/role/${id}`,
    });
    dispatch(setUsersGroup(res?.data || {}));
    dispatch(
        setLoading({
            key: 'usersGroup_loading',
            value: false,
        })
    );
};

export default UsersGroupsSlice.reducer;
