/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { Badge, Button, Checkbox, Input, Modal, PriceHistoryModal } from '@components';
import generateVariantsByOptions from '@utils/generateVariantsByOptions/generateVariantsByOptions';
import BaseProductForm from './BaseProductForm';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import deleteIcon from '@assets/icons/delete.svg';
import useQuery from '@hooks/useQuery';
import isEqual from 'lodash.isequal';
import { updateProductOptionStatus } from '../product-utils';
import HistoryIcon from '@assets/icons/history.svg';

const EMPTY_VARIANT_MAX_KEYS = 2;

const ProductVariantsForm = ({
    baseProductData = {},
    variantsData = {},
    // initial data of variant
    initialVariantData = {},
    title,
    onFieldChange,
    onVariantChange,
    defaultVariants = [],
    variants = [],
    setVariants = () => {},
}) => {
    delete initialVariantData.id;

    const dispatch = useDispatch();

    const [options, setOptions] = useState([]);
    const [tempData, setTempData] = useState({});
    const [selectedVariantId, setSelectedVariantId] = useState(null);
    const [mounted, setMounted] = useState(false);

    const isOptionsUpdate = useRef(false);

    const optionsCombinations = defaultVariants?.map(({ options }) =>
        options.map(({ value_id }) => value_id),
    );

    const updateVariants = _options => {
        let generatedVariants = generateVariantsByOptions(_options);
        if (!mounted) {
            // remove any variants that are not in default variants
            generatedVariants = generatedVariants.filter(
                variant =>
                    optionsCombinations.findIndex(opts =>
                        isEqual(
                            variant.options.map(({ id }) => id),
                            opts,
                        ),
                    ) >= 0,
            );
        }

        const newVariants = generatedVariants.reduce((prev, variant) => {
            const indexOfDefaultVariant = optionsCombinations.findIndex(opts =>
                isEqual(
                    variant.options.map(({ id }) => id),
                    opts,
                ),
            );
            const data = {
                ...initialVariantData,
                ...defaultVariants[indexOfDefaultVariant],
                ...variantsData[variant.name],
            };

            return {
                ...prev,
                [variant.name]: {
                    ...data,
                    shelf_life: data?.shelf_life?.split(' ')[0] || data.shelf_life,
                    shelf_life_unit: data?.shelf_life?.split(' ')[1] || data.shelf_life_unit,
                    // status: indexOfDefaultVariant >=0 ? 'old' : 'new',
                    options: variant.options.map(({ option_id, id }) => ({
                        product_option_id: option_id,
                        product_option_value_id: id,
                    })),
                },
            };
        }, {});

        setVariants(generatedVariants);
        onVariantChange(newVariants);
    };

    const toggleOptionValue = (optionId, valueId) => {
        /** @type {"activate" | "deactivate"} */
        let action = 'activate';
        let newOptions = options.map(({ values, ...option }) => {
            if (option.id !== optionId) return { ...option, values };
            const newValues = values.map(option_value => {
                const founded = valueId === option_value.id;
                if (founded && option_value.active) action = 'deactivate';

                return {
                    ...option_value,
                    option_id: option.id,
                    active: founded ? !option_value.active : option_value.active,
                };
            });

            const newOption = {
                ...option,
                values: newValues,
            };

            return {
                ...newOption,
            };
        });

        setOptions(newOptions);

        if (action === 'activate') {
            const generatedVariants = generateVariantsByOptions(newOptions);
            const isNewOptionActivated = variants.every(variant => {
                const isAllNotHadTheOptionId = variant.options.every(
                    optValues => optValues.option_id !== optionId,
                );

                return isAllNotHadTheOptionId;
            });

            if (isNewOptionActivated) {
                const newVariants = generatedVariants.reduce((prev, variant, index) => {
                    return {
                        ...prev,
                        [variant.name]: {
                            ...initialVariantData,

                            // moving data of old variant to the new one :\
                            ...variantsData[variants[index]?.name],

                            status:
                                !variantsData[variants[index]?.id] ||
                                Object.keys(variantsData[variants[index]?.name] || {}).length <=
                                    EMPTY_VARIANT_MAX_KEYS
                                    ? 'new'
                                    : 'old',

                            options: variant.options.map(({ option_id, id }) => ({
                                product_option_id: option_id,
                                product_option_value_id: id,
                            })),
                        },
                    };
                }, {});
                setVariants(generatedVariants);
                onVariantChange(newVariants);
            } else {
                const newVariants = generatedVariants.reduce((prev, variant) => {
                    const oldVariant = variants.find(({ options }) =>
                        isEqual(options, variant.options),
                    );
                    const oldVariantData = variantsData[oldVariant?.name];

                    return {
                        ...prev,
                        [variant.name]: {
                            ...initialVariantData,

                            // moving data of old variant to the new one :\
                            ...oldVariantData,

                            status:
                                !oldVariantData?.id ||
                                Object.keys(oldVariantData || {}).length <= EMPTY_VARIANT_MAX_KEYS
                                    ? 'new'
                                    : 'old',

                            options: variant.options.map(({ option_id, id }) => ({
                                product_option_id: option_id,
                                product_option_value_id: id,
                            })),
                        },
                    };
                }, {});
                setVariants(generatedVariants);
                onVariantChange(newVariants);
            }
        } else {
            // if value in old variant add status to remove
            const newVariants = [];

            const isOldOptionDeActivated = isOptionDeactivated(newOptions, optionId);

            let newVariantsData;
            if (isOldOptionDeActivated) {
                const generatedVariants = generateVariantsByOptions(newOptions);
                newVariantsData = generatedVariants.reduce((prev, variant, index) => {
                    newVariants.push(variant);

                    return {
                        ...prev,
                        [variant.name]: {
                            ...initialVariantData,

                            // moving data of old variant to the new one :\
                            ...variantsData[variants[index]?.name],

                            status:
                                !variantsData[variants[index]?.id] ||
                                Object.keys(variantsData[variants[index]?.name] || {}).length <=
                                    EMPTY_VARIANT_MAX_KEYS
                                    ? 'new'
                                    : 'old',

                            options: variant.options.map(({ option_id, id }) => ({
                                product_option_id: option_id,
                                product_option_value_id: id,
                            })),
                        },
                    };
                }, {});
            } else {
                const generatedVariants = generateVariantsByOptions(options);
                newVariantsData = generatedVariants.reduce((prev, variant, index) => {
                    const oldStatus = variantsData[variants[index]?.name]?.status;
                    const status = variant.options.some(({ id }) => id === valueId)
                        ? 'removed'
                        : oldStatus;

                    // todo check if removed variant is new or old
                    // in case it's new we should not save it to new variant and we should skip it and return prev only
                    // other wise we can mark it as 'removed' status
                    if (status === 'removed' && oldStatus === 'new') return prev;
                    newVariants.push(variant);

                    return {
                        ...prev,
                        [variant.name]: {
                            ...initialVariantData,

                            // moving data of old variant to the new one :\
                            ...variantsData[variants[index]?.name],

                            status,

                            options: variant.options.map(({ option_id, id }) => ({
                                product_option_id: option_id,
                                product_option_value_id: id,
                            })),
                        },
                    };
                }, {});
            }

            setVariants(newVariants);
            onVariantChange(newVariantsData);
        }
    };

    const handleOpenVariantForm = name => dispatch(setModalStatus({ modal: name, status: true }));

    const handleCloseVariantForm = name => {
        dispatch(setModalStatus({ modal: name, status: false }));
        setTempData({});
    };

    const handleRemoveVariant = variantName => {
        /** @todo deactivate option value if it's only in this variant */

        onVariantChange({
            ...variantsData,
            [variantName]: { ...variantsData[variantName], status: 'removed' },
        });
    };

    const handleUndoRemoveVariant = variantName => {
        /** @todo activate option value if it's in active */

        onVariantChange({
            ...variantsData,
            [variantName]: {
                ...variantsData[variantName],
                status: variantsData[variantName]?.id ? 'old' : 'new',
            },
        });
    };

    const isVariantRemoved = variantName => variantsData[variantName]?.status === 'removed';

    const handlePriceHistoryModal = id => {
        setSelectedVariantId(id);
        dispatch(
            setModalStatus({
                modal: 'price_history_modal',
                status: true,
            }),
        );
    };

    const handleUpdateTempData = (formData, fieldName) => {
        setTempData(prev => ({
            ...formData,
            ...prev,
            [fieldName]: formData[fieldName],
        }));
    };

    useQuery(
        '/product-option',
        {},
        {
            stop: !!options.length,
            onSuccess: ({ data }) =>
                setOptions(
                    (data || []).map(option => ({
                        ...option,
                        values: option.values.map(val => ({ ...val, option_id: option.id })),
                    })),
                ),
        },
    );

    useEffect(() => {
        const selected_values_ids = optionsCombinations.flat();

        if (selected_values_ids.length && options.length >= 1 && !isOptionsUpdate.current) {
            isOptionsUpdate.current = true;
            let _options = updateProductOptionStatus(options, selected_values_ids);
            setOptions([..._options]);
            updateVariants(_options);
            setMounted(true);
        }
    }, [defaultVariants, options]);
     const [is_valid ,set_is_valid]=useState(false) ;
     console.log(is_valid);
    return (
        <div className="p-3">
            {title}
            <div className="mb-4">
                <h4 className="h6 py-2">Variant Options</h4>
                {options?.map((option, index) => (
                    <div className="grid grid-cols-3 gap-2 mb-2" key={index}>
                        <div className="flex__ai-center">
                            <div>
                                <h3 className="h6 mb-0">{option.name_en}</h3>
                                <span style={{ opacity: 0.8 }}>{option.description_en}</span>
                            </div>
                        </div>
                        <div style={{ flex: 1 }} className="p-2 col-span-2 flex gap-2">
                            {option.values?.map(option_value => (
                                <Checkbox
                                    checked={option_value.active}
                                    onChange={() => toggleOptionValue(option.id, option_value.id)}
                                    key={option_value.value_en}
                                    label={option_value.value_en}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {variants
                .sort((a, b) => {
                    // sort variants by status to show removed at the end
                    const statusA = variantsData[a.name]?.status;
                    const statusB = variantsData[b.name]?.status;
                    if (statusA === statusB) return 0;
                    if (statusA === 'removed') return 1;
                    if (statusB === 'removed') return -1;
                    return 0;
                })
                .map(variant => {
                    return (
                        <div
                            key={variant.name}
                            className={
                                isVariantRemoved(variant.name)
                                    ? 'flex__jc-between gap-2 px-2 py-3'
                                    : 'grid grid-cols-7 gap-2'
                            }
                            style={{ alignItems: 'center' }}
                        >
                            <p
                                className="h6 col-span-2"
                                style={
                                    isVariantRemoved(variant.name)
                                        ? {
                                              textDecoration: 'line-through',
                                          }
                                        : undefined
                                }
                            >
                                {variantsData[variant.name]?.id} -{variant.name}{' '}
                                {variantsData[variant.name]?.status === 'new' && (
                                    <Badge
                                        color="info"
                                        pill
                                        style={{ backgroundColor: '#4356fd6b' }}
                                    >
                                        NEW
                                    </Badge>
                                )}
                            </p>
                            {isVariantRemoved(variant.name) ? (
                                <div>
                                    <Button
                                        onClick={() => handleUndoRemoveVariant(variant.name)}
                                        type="button"
                                        variant="secondary"
                                        size="sm h-14"
                                        style={{ height: 40 }}
                                    >
                                        Undo
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    <Input
                                        wrapperClassName="col-span-2"
                                        label="SKU"
                                        value={
                                            variantsData[variant.name]?.sku_code ||
                                            baseProductData?.sku_code
                                        }
                                        setValue={val =>
                                            onFieldChange(variant.name, {
                                                ...variantsData[variant.name],
                                                sku_code: val,
                                            })
                                        }
                                    />
                                    <Input
                                        wrapperClassName="col-span-2"
                                        label={
                                            <div className="flex__ai-center">
                                                <span>Price</span>
                                                {variantsData[variant.name]?.id && (
                                                    <div
                                                        className="price-history-icon"
                                                        onClick={() =>
                                                            handlePriceHistoryModal(
                                                                variantsData[variant.name]?.id,
                                                            )
                                                        }
                                                    >
                                                        <img src={HistoryIcon} alt="history" />
                                                        <span>History</span>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        value={
                                            variantsData[variant.name]?.price ||
                                            baseProductData?.price
                                        }
                                        setValue={val =>
                                            onFieldChange(variant.name, {
                                                ...variantsData[variant.name],
                                                price: val,
                                            })
                                        }
                                    />
                                    <div className="flex__jc-end gap-2">
                                        <Button
                                            onClick={() => handleOpenVariantForm(variant.name)}
                                            type="button"
                                            variant="secondary"
                                            size="sm h-14"
                                            style={{ height: 40 }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={() => handleRemoveVariant(variant.name)}
                                            type="button"
                                            variant="secondary"
                                            size="sm h-14"
                                            style={{ height: 40 }}
                                        >
                                            <img
                                                src={deleteIcon}
                                                alt="delete variant"
                                                style={{ width: 15 }}
                                            />
                                        </Button>
                                    </div>
                                </>
                            )}

                            <Modal
                                title={variant.name}
                                name={variant.name}
                                onClose={() => handleCloseVariantForm(variant.name)}
                                size="xl"
                                onSubmit={() => {
                                    onFieldChange(variant.name, tempData);
                                    handleCloseVariantForm(variant.name);
                                }}
                            >
                                <BaseProductForm
                                    variant="variant"
                                    initialValues={{
                                        ...baseProductData,
                                        ...initialVariantData,
                                        ...variantsData[variant.name],
                                        ...tempData,
                                    }}
                                    onFieldChange={(data, fieldName) =>
                                        handleUpdateTempData(
                                            data,
                                            fieldName,
                                            variantsData[variant.name],
                                        )
                                    }
                                    loading={false}
                                    errors={[]}
                                    onSubmit={() => {}}
                                    set_is_valid={set_is_valid}
                                    showDiscountRanges={false}
                                ></BaseProductForm>
                            </Modal>
                        </div>
                    );
                })}
            <PriceHistoryModal
                productID={selectedVariantId}
                onClose={() => setSelectedVariantId(null)}
            />
        </div>
    );
};

export default ProductVariantsForm;

function isOptionDeactivated(newOptions, optionId) {
    const hasOption = newOptions.some(option => {
        return optionId === option.id && option.values.every(({ active }) => !active);
    });

    return hasOption;
}
