import React from 'react';
import { ViewHeader, Tabs, Sop } from '@components';
import {
    AccountManagersTab,
    PackagesTypesTab,
    CustomerEntityTab,
    InvoicesRejectionReasonsTab,
    VendorsTypesTab,
    PlansTab,
    TagsTab,
    DefineScheduledOrders,
    RibbonSettingsTab,
    GeneralSettingsTab,
    VendorSettingsTab,
    OrderSettingsTab,
    NotificationSettingsTab,
    PushNotificationSettingsTab,
    ConfigsCustomAttributesTab,
    PayLaterTab,
    ProductVariantOptionsTab,
    Finance,
    StorageConditionTypesTab,
    DeliveryTypesTab,
    AdjustmentTypes,
    ProductRejectionReasons,
    PayoutOptionsTab,
    ProductAccessories,
    AcquisitionChannels,
} from './Tabs';
import BannerPositionTab from './Tabs/BannerPositionTab';

export default function ConfigsView() {
    const [tabs, setTabs] = React.useState([
        {
            name: 'Custom Attributes',
            component: <ConfigsCustomAttributesTab />,
            isActive: true,
        },
        {
            name: 'Product Variant Options',
            component: <ProductVariantOptionsTab />,
            isActive: false,
        },
        {
            name: 'Packages Types',
            component: <PackagesTypesTab />,
            isActive: false,
        },
        {
            name: 'Storage Condition Types',
            component: <StorageConditionTypesTab />,
            isActive: false,
        },
        {
            name: 'Delivery Types',
            component: <DeliveryTypesTab />,
            isActive: false,
        },
        {
            name: 'Tags',
            component: <TagsTab />,
            isActive: false,
        },
        {
            name: 'Customer Entity Type',
            component: <CustomerEntityTab />,
            isActive: false,
        },
        {
            name: 'Merchants types',
            component: <VendorsTypesTab />,
            isActive: false,
        },
        {
            name: 'Plans types',
            component: <PlansTab />,
            isActive: false,
        },
        {
            name: 'Pay Later',
            component: <PayLaterTab />,
            isActive: false,
        },
        {
            name: 'Invoices rejection reasons',
            component: <InvoicesRejectionReasonsTab />,
            isActive: false,
        },
        {
            name: 'Account Managers',
            component: <AccountManagersTab />,
            isActive: false,
        },
        {
            name: 'Order Settings',
            component: <OrderSettingsTab />,
            isActive: false,
        },
        {
            name: 'Merchant Settings',
            component: <VendorSettingsTab />,
            isActive: false,
        },
        {
            name: 'Payout Options',
            component: <PayoutOptionsTab />,
            isActive: false,
        },
        {
            name: 'Ribbon Settings',
            component: <RibbonSettingsTab />,
            isActive: false,
        },
        {
            name: 'Notification Settings',
            component: <NotificationSettingsTab />,
            isActive: false,
        },
        {
            name: 'PushNotification Settings',
            component: <PushNotificationSettingsTab />,
            isActive: false,
        },
        {
            name: 'General Settings',
            component: <GeneralSettingsTab />,
            isActive: false,
        },
        {
            name: 'Define scheduled orders',
            component: <DefineScheduledOrders />,
            isActive: false,
        },
        {
            name: 'Finance',
            component: <Finance />,
            isActive: false,
        },
        {
            name: 'Adjustment Types',
            component: <AdjustmentTypes />,
            isActive: false,
        },
        {
            name: 'Product Rejection Reasons',
            component: <ProductRejectionReasons />,
            isActive: false,
        },
        {
            name: 'Accessories',
            component: <ProductAccessories />,
            isActive: false,
        },
        {
            name: 'Acquisition Channels',
            component: <AcquisitionChannels />,
            isActive: false,
        },
         {
            name: 'Banner Position',
            component: <BannerPositionTab />,
            isActive: false,
        },
    ]);
    return (
        <div>
            <ViewHeader title="Config">
                <Sop id="30063" />
            </ViewHeader>

            <Tabs tabs={tabs} onSelect={setTabs} vertical>
                {tabs.find((t) => t.isActive)?.component}
            </Tabs>
        </div>
    );
}
