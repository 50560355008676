import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import PoInvoicesListView from './PoInvoicesListView';
import PoInvoiceUpdateView from './PoInvoicesUpdateView';
// import LiveCartsSingleView from './LiveCartsSingleView';
import { Helmet } from 'react-helmet';

export default function PoInvoicesModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/purchase-orders/list">
                <Helmet>
                    <title>Purchase Orders | Disty Admin Dashboard</title>
                </Helmet>
                <PoInvoicesListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/purchase-orders/:id">
                <Helmet>
                    <title>
                        Edit Purchase Orders | Disty Admin Dashboard
                    </title>
                </Helmet>
                <PoInvoiceUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
