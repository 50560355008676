import React from 'react';
import { useSelector } from 'react-redux';
import { Button, FileUpload, Row, Col } from '@components';
import { PATCH, DELETE, POST, obj2formdata, randomId, confirmationMessage } from '@utils';
import removeIcon from '@assets/icons/delete.svg';
import { ReactSortable } from 'react-sortablejs';
import { Sortable, MultiDrag } from 'sortablejs';
import SelectVariantOrBase from '../../components/SelectVariantOrBase';
import useQuery from '@hooks/useQuery';
import { toast } from 'react-toastify';
Sortable.mount(new MultiDrag());

export default function ImagesTab() {
    return (
        <>
            <SelectVariantOrBase selectionType="both">
                {({ selected, isBaseSelected }) => (
                    <UpdateImages isBaseSelected={isBaseSelected} selectedID={selected} />
                )}
            </SelectVariantOrBase>
        </>
    );
}

const UpdateImages = ({ selectedID, isBaseSelected }) => {
    const [files, set_files] = React.useState([]);
    const { product, product_loading } = useSelector((state) => state.ProductsSlice);
    const [loading, set_loading] = React.useState(false);
    const [images, set_images] = React.useState([]);
    const endpoint = isBaseSelected ? 'base-product' : 'product';
    const { data: { data: selectedProductImages } = {}, refetch } = useQuery(
        `/${endpoint}/${selectedID}/image`,
    );

    React.useEffect(() => {
        if (Array.isArray(selectedProductImages)) {
            set_images([...(selectedProductImages || [])]);
        }
    }, [selectedProductImages, isBaseSelected, selectedID]);

    const deleteSlide = async (id) => {
        const res = await DELETE({
            endpoint: `/${endpoint}/${selectedID}/image/${id}`,
        });

        if (res?.status_code === 200) {
            refetch();
        }else {
        toast.error(res.data?.message);
        }
    };

    const submitSlider = async () => {
        const data = obj2formdata({});
        set_loading(true);

        files.forEach((file) => {
            data.append('images[]', file);
        });

        const res = await POST({
            endpoint: `/${endpoint}/${selectedID}/image`,
            data,
            headers: {
                'Idempotency-Key': randomId(),
            },
        });
        set_loading(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_files([]);
            refetch();
        }
    };

    const reorderProductImages = async () => {
        const res = await PATCH({
            endpoint: `/${endpoint}/${selectedID}/image`,
            data: {
                order: [...images.map((img) => img.id)],
            },
        });

        if (res?.status_code === 200) {
            refetch();
        }
    };

    return (
        <>
            <Row className="mb-4">
                <Col xs={12}>
                    <FileUpload files={files} setFiles={set_files} maxFiles={5} />
                    <Button
                        disabled={!files.length || loading}
                        onClick={() => {
                            product?.is_active ? submitSlider() : confirmationMessage(submitSlider);
                        }}
                    >
                        {loading ? 'Loading...' : 'Upload images'}
                    </Button>
                </Col>
            </Row>
            <h4>Reorderable images, 1st image is the main image of the product</h4>
            {!product_loading && (
                <ReactSortable
                    group="product-thumbnails"
                    MultiDrag
                    list={images.map((x) => ({ ...x, chosen: true }))}
                    setList={(ordered_images) => {
                        set_images([...ordered_images.filter((x) => !!x)]);
                    }}
                    className="thumbnails-grid"
                    onEnd={reorderProductImages}
                >
                    {images?.map((image, i) => (
                        <div key={i} className="thumbnail">
                            <span className="remove-icon" onClick={() => deleteSlide(image.id)}>
                                <img src={removeIcon} alt="x" />
                            </span>
                            <span className="thumbnail-order">{i + 1}</span>
                            <img src={image.image} alt="Slide image" />
                        </div>
                    ))}
                </ReactSortable>
            )}
        </>
    );
};
