/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { DataTable, StatusBlock, Link, Filters } from '@components';
import { stringify } from 'query-string';
import { GET, formatDate } from '@utils';
import walletDebit from '@assets/icons/wallet-debit.svg';
import walletCredit from '@assets/icons/wallet-credit.svg';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function OrdersStockTransactionsTab() {
    const product = useSelector((state) => state.ProductsSlice.product);
    const [stock_transactions_loading, set_stock_transactions_loading] = React.useState(false);
    const [stock_transactions, set_stock_transactions] = React.useState([]);

    const [filters, set_filters] = React.useState({
        suborder_states: [],
        cities: [],
        order_states: [],
        order_payment_states: [],
        pay_later_providers: null,
        created_at_from: null,
        created_at_to: null,
        sort_by: 'id',
        sort_direction: 'asc',
    });

    useDidMountEffect(() => {
        if (product.id) {
            loadStockTransactions(filters);
        }
    }, [product, filters]);

    const loadStockTransactions = async (params) => {
        set_stock_transactions_loading(true);
        const res = await GET({
            endpoint: `/product-stock-transaction/${product?.id}?${stringify(params, {
                skipNull: true,
                skipEmptyString: true,
            })}`,
        });
        set_stock_transactions_loading(false);
        if (res?.status_code === 200) {
            set_stock_transactions(res?.data);
        }
    };

    const columns = [
        {
            name: 'Suborder',
            width: '100px',
            cell: (row) => (
                <Link href={`/orders/${row.order?.id}`} target="_blank">
                    #{row.suborder?.pretty_id}
                </Link>
            ),
        },
        {
            name: 'Type',
            selector: 'type',
            width: '50px',
            cell: (row) => (
                <>
                    <img
                        src={row.type === 'increase' ? walletCredit : walletDebit}
                        style={{
                            width: 30,
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    />
                </>
            ),
        },
        {
            name: 'Suborder state',
            cell: (row) => <StatusBlock state={row.suborder?.state} />,
        },
        {
            name: 'Parent order state',
            cell: (row) => <StatusBlock state={row.order?.state} />,
        },
        {
            name: 'Quantity',
            selector: 'quantity',
            center: true,
        },
        {
            name: 'Order city',
            selector: 'order.city',
        },
        {
            name: 'Order allocation',
            cell: (row) => row.order?.warehouse_allocation?.location_name,
        },
        {
            name: 'Stock type',
            selector: 'stock_type',
        },
        {
            name: 'Reason',
            selector: 'reason',
            wrap: true,
        },
        {
            name: 'Product',
            width: '100px',
            cell: (row) => (
                <Link href={`/products/${row.product_id}`} target="_blank">
                    #{row.product_id}
                </Link>
            ),
        },
        {
            name: 'Created at',
            selector: 'created_at',
            cell: (row) => formatDate(row.created_at),
        },
    ];

    return (
        <>
            <Filters
                filters={[
                    'child_order_state',
                    'cities',
                    'order_states',
                    'order_payment_states',
                    'pay_later_providers',
                    'created_at_from',
                    'created_at_to',
                ]}
                onFilterChange={(filters_values) => {
                    set_filters(filters_values);
                }}
                initialFiltersValues={{
                    sort_by: 'created_at',
                }}
                sort_options={[
                    { label: 'Order ID', value: 'id' },
                    {
                        label: 'Updated at (Oldest first)',
                        value: 'updated_at__desc',
                        direction: 'desc',
                    },
                    {
                        label: 'Updated at (Oldest first)',
                        value: 'updated_at__asc',
                        direction: 'asc',
                    },
                    {
                        label: 'Created at (Newest first)',
                        value: 'created_at__desc',
                        direction: 'desc',
                    },
                ]}
            />
            <DataTable
                data={stock_transactions}
                columns={columns}
                loading={stock_transactions_loading}
            />
        </>
    );
}
