import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from '@store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';


// import ReactGA from 'react-ga';

// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
