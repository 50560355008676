// const months = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December',
// ];

// const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// function convertTZ(date, tzString = 'Asia/Riyadh') {
//     return new Date(
//         (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
//             timeZone: tzString,
//         })
//     );
// }

function formatDate(date, type = 'full') {
    if (!date) return;
    const d = new Date(date);

    let hours = d.getHours();
    let minutes = d.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = `${hours}:${minutes} ${ampm.toUpperCase()}`;

    if(type === 'full') {
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()} ${strTime}`;
    }
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
}
export default formatDate;

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function diffDays(a, b = new Date()) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}