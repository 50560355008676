import React from 'react';
import {
    DataTable,
    Button,
    Modal,
    Col,
    Toggler,
    ShowErrors
} from '@components';
import { PUT, randomId } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadGeneralSettingsData } from '@slices/settings.slice';

const GeneralSettingsTab = () => {
    const SettingsSlice = useSelector((state) => state.SettingsSlice);
    const dispatch = useDispatch();
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [registration_disabled, set_registration_disabled] = React.useState({
        label: 'Registration disabled',
        checked: true,
        onChange: (val) => set_registration_disabled({ ...registration_disabled, checked: val }),
    });
    const [allow_production_app_testing, set_allow_production_app_testing] = React.useState({
        label: 'Allow production app testing',
        checked: true,
        onChange: (val) => set_allow_production_app_testing(
            { ...allow_production_app_testing, checked: val }),
    });

    React.useEffect(() => {
        dispatch(loadGeneralSettingsData());
    }, [rerender]);

    const submit = async (closeModal) => {
        set_loading(true);
        set_errors([]);

        const res = await PUT({
            endpoint: '/general-settings',
            data: {
                registration_disabled: registration_disabled?.checked ? 1 : 0,
                allow_production_app_testing: allow_production_app_testing?.checked ? 1 : 0,
            }
        });
        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
            set_loading(false);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('edit_general_settings__modal');
            set_rerender(randomId());
        }
    };

    const data = [
        {
            key: 'Registration Disabled',
            value: SettingsSlice?.generalSettings?.registration_disabled,
        },
        {
            key: 'Allow Production App Testing',
            value: SettingsSlice?.generalSettings?.allow_production_app_testing,
        },
    ];

    const columns = [
	    {
            name: 'Setting',
            cell: (row) => row?.key
        },
        {
            name: 'Value',
            center: true,
            cell: (row) => (
                <div
                    className={`status-indicator ${
                        row?.value && 'active-indicator'
                    }`}
                >
                    {row?.value ? 'Active' : 'Inactive'}
                </div>
            )
        }
    ];

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                loading={SettingsSlice?.generalSettings_loading}
            />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_errors([]);
                        registration_disabled.onChange(
                            SettingsSlice?.generalSettings?.registration_disabled);
                        allow_production_app_testing. 
                            onChange(SettingsSlice?.generalSettings?.
                                allow_production_app_testing);
                        dispatch(
                            setModalStatus({
                                modal: 'edit_general_settings__modal',
                                status: true
                            })
                        );
                    }}
                >
                    Edit General settings
                </Button>
            </div>
            <Modal
                title={'Update General Settings'}
                variant={'warning'}
                name="edit_general_settings__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal);
                }}
                loading={loading}
            >

                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
                <Col>
                    <Toggler
                        {...registration_disabled}
                    />
                </Col>
                <Col>
                    <Toggler
                        {...allow_production_app_testing}
                    />
                </Col>
            </Modal>
        </>
    );
};

export default GeneralSettingsTab;