import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const WireTransactionsSlice = createSlice({
    name: 'wiretransactions',
    initialState: {
        wire_transactions: [],
        wire_transactions_pages: null,
        wire_transactions_records: null,
        wire_transactions_loading: true,

        wire_transaction: {},
        wire_transaction_loading: true,
     
    },
    reducers: {
        setWireTransactions(state, { payload }) {
            state.wire_transactions = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.wire_transactions.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.wire_transactions[index] = {
                    ...state.wire_transactions[index],
                    ...payload,
                };
            }
        },
       
       
        setWireTransaction(state, { payload }) {
            state.wire_transaction = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setWireTransactionsTotalPages(state, { payload }) {
            state.wire_transactions_pages = payload;
        },
        setWireTransactionsRecords(state, { payload }) {
            state.wire_transactions_records = payload;
        },
        
    },
});

// export actions
export const {
    setWireTransactions,
    updateSingleRow,
    setWireTransaction,
    setLoading,
     setWireTransactionsTotalPages,
     setWireTransactionsRecords,
} =WireTransactionsSlice.actions;

export const loadWireTransactionsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'wire_transactions_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/payment/transactions/new-wire?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setWireTransactions(res?.data || []));
    dispatch(setWireTransactionsTotalPages(res?.meta?.last_page || 0));
    dispatch(setWireTransactionsRecords({
        from: res?.meta?.from,
        to: res?.meta?.to,
        total: res?.meta?.total
    }));
    dispatch(
        setLoading({
            key: 'wire_transactions_loading',
            value: false,
        })
    );
};


export const loadWireTransactionData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'wire_transaction_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/payment/transactions/new-wire/${id}`,
    });
    dispatch(setWireTransaction(res?.data || {}));
    dispatch(
        setLoading({
            key: 'wire_transaction_loading',
            value: false,
        })
    );
};

export default WireTransactionsSlice.reducer;
