import { createSlice } from '@reduxjs/toolkit';
import { GET } from '@utils';

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        user: {},
        is_super_admin: false,
    },
    reducers: {
        setUser(state, { payload }) {
            state.user = payload;
        },
        setToken(state, { payload }) {
            state.token = payload;
        },
        clearAuthState(state){
            state.token = null;
            state.user = {};
        },
        setIsSuperAdmin(state, { payload }) {
            state.is_super_admin = payload;
        }
    },
});

// export actions
export const { setUser, setToken, clearAuthState, setIsSuperAdmin } = AuthSlice.actions;

export const loadUserData = () => async (dispatch) => {
    const res = await GET({
        endpoint: '/user/me',
    });

    const is_super_admin = !!res?.data.roles.find(r => r.is_super_admin);

    dispatch(setIsSuperAdmin(is_super_admin));
    dispatch(setUser(res?.data || {}));
};

export default AuthSlice.reducer;
