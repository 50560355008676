import {
    ViewHeader,
    Row,
    Col,
    Select,
    Input,
    Textarea,
    FileUpload,
    ApiAsyncSelect,
    Button,
    ShowErrors,
    DatePicker,
    ConfirmationModal, StatusBlock,
} from '@components';
import React from 'react';
import { formatMoney } from '@utils';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadGlobalBanks } from '@slices/banks.slice';
import deleteIcon from '@assets/icons/delete.svg';
import { toast } from 'react-toastify';
import { setModalStatus } from '@slices/ui.slice';

import { obj2formdata, POST, GET, randomId  } from '@utils';
function WireTransactionsAddView() {
    const [errors, setErrors] = React.useState([]);
    const BanksSlice = useSelector((state) => state.BanksSlice);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(loadGlobalBanks());
    }, [dispatch]);
    const [bank, setBank] = React.useState({
        label: 'Bank name',
        value: '',
        onChange: (val) => setBank({ ...bank, value: val }),
    });
    const [bankOptions, setBankOptions] = React.useState([]);
    React.useEffect(() => {
        if (BanksSlice?.global_banks?.length) {
            setBankOptions(
                BanksSlice.global_banks.map((b) => {
                    return {
                        value: b.id,
                        label: `${b.name_ar} (${b.name_en})`,
                    };
                }),
            );
        }
    }, [BanksSlice?.global_banks]);
    const [amount, set_amount] = React.useState({
        label: 'Total collected amount',
        value: '',
        type: 'number',
        acceptDecimal:true,
        setValue: (val) => set_amount({ ...amount, value: val.replace(/[^\d.-]+/g, '') }),
    });

    const [attachment, set_attachment] = React.useState({
        label: 'Attach deposit',
        maxFiles: 1,
        allowPDF: true,
        files: [],
        setFiles: (val) => set_attachment({ ...attachment, files: val }),
    });
    const [comments, set_comments] = React.useState({
        label: 'Comments',
        value: '',
        setValue: (val) => set_comments({ ...comments, value: val }),
    });
    const [transaction_reference_code , set_transaction_reference_code ] = React.useState({
        label: 'Bank transaction reference number',
        value: '',
        setValue: (val) => set_transaction_reference_code({ ...transaction_reference_code, value: val }),
    });
    const [transfer_date, set_transfer_date] = React.useState({
        label: 'Transfer date',
        selectedDate: '',
        maxDate: new Date(),
        onChange: (val) => set_transfer_date((prev) => ({ ...prev, selectedDate: val })),
    });
    const [created, set_created] = React.useState(null);
    const [customers, setCustomers] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!bank?.value) newErrors.bank = 'The bank name field is required.';
        if (!amount?.value || Number(amount.value) === 0)
            newErrors.amount = 'The amount field is required and cannot be 0.';
        if (attachment.files.length === 0)
            newErrors.attachment = 'The attach deposit field is required.';
        if (customers.length === 0 || !customers[0].selectedCustomer)
            newErrors.customers = 'At least one customer must be selected.';
        if (!transfer_date.selectedDate) {
            newErrors.transfer_date = 'The transfer date field is required.';
        }
        if ( !/^[a-zA-Z0-9\s]*$/.test(transaction_reference_code.value))
            newErrors.reference_code = 'The transaction reference number field  must  contain only english letters and numbers.';
        let totalCollected = 0;
        customers.forEach((customer) => {
            if (customer?.orders) {
                customer.orders.forEach((order) => {
                    totalCollected += order.collected_amount || 0;
                    const remainingAmount = order.payment_type === 'CASH'
                    ? order.total_due
                    : order.total_due - order.paid_amount;

                if (order.collected_amount > remainingAmount) {
                    newErrors.order = `The received amount for order ${order.id} cannot exceed the remaining amount`;
                }
                   if (
                        order.payment_type === 'CASH' &&
                        Number(order.collected_amount) !==  Number(order.total_due)
                    ) {
                        newErrors.order = ` The received amount must equal the due amount for cash payment.`;
                    }
                    
                });
            }
        });
        if (customers.length > 0 && totalCollected !== Number(amount.value)) {
            newErrors.totalCollected = `The total received amount (${totalCollected}) does not match the collected  amount.`;
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    React.useEffect(() => {
        validate();
    }, [bank, amount, attachment, customers, transfer_date ,transaction_reference_code]);

    const onSubmit = async () => {
        set_loading(true);
        if (validate()) {
            const data = obj2formdata({
                bank_id: bank.value.value,
                amount: amount.value,
                comments: comments.value,
                bank_transaction_ref_number : transaction_reference_code.value,
            });
            const date = transfer_date.selectedDate;
            const isoDate = date
                ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                      .toISOString()
                      .split('T')[0]
                : '';
            data.append('transfer_date', isoDate);
            if (attachment.files.length > 0) {
                data.append('receipt', attachment.files[0]);
            }
            let orderIndex = 0;
            customers.forEach((customer) => {
                customer.orders.forEach((order) => {
                    data.append(`orders[${orderIndex}][order_id]`, order.id);
                    data.append(`orders[${orderIndex}][customer_id]`, customer.selectedCustomer.id);
                    data.append(`orders[${orderIndex}][amount]`, order.collected_amount);
                    orderIndex++;
                });
            });
            const res = await POST({
                endpoint: '/payment/transactions/new-wire',
                data,
                headers: {
                    'Idempotency-Key': randomId(),
                },
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_created('created');
            }
        }
    };

    const fetchOrdersForCustomer = async (id) => {
        const ordersData = await GET({
            endpoint: `/order/customer/${id}`,
        });
        return ordersData?.data || [];
    };
    const handleCustomerSelect = async (index, selectedCustomer) => {
        const isDuplicate = customers.some(
            (customer) => customer.selectedCustomer && customer.selectedCustomer.id === selectedCustomer.id
        );
        if (isDuplicate) {
          toast.info(  `This customer has already been added.`);
           return; 
        }
        const updatedCustomers = [...customers];
        const customerOrders = await fetchOrdersForCustomer(selectedCustomer.id);
        updatedCustomers[index] = {
            ...updatedCustomers[index],
            selectedCustomer,
            orders: customerOrders,
            showTable: true,
        };

        setCustomers(updatedCustomers);
    };

    const handleReceivedAmountChange = (orderId, val, customerIndex) => {console.log(val);
    
        const newAmount = parseFloat(val) ;
        const updatedCustomers = customers.map((customer, index) => {
            if (index === customerIndex) {
                const updatedOrders = customer.orders.map((order) =>
                    order.id === orderId ? { ...order, collected_amount: newAmount } : order,
                );
                return { ...customer, orders: updatedOrders };
            }
            return customer;
        });

        setCustomers(updatedCustomers);
        validate();
    };

    const deleteCustomer = (id) => {
        const updatedCustomers = customers.filter((customer) => customer?.id !== id);
        setCustomers(updatedCustomers);
    };
    const deleteOrder = (customerIndex, orderId) => {
        const updatedCustomers = customers.map((customer, index) => {
            if (index === customerIndex) {
                const updatedOrders = customer.orders.filter((order) => order.id !== orderId);
                return { ...customer, orders: updatedOrders };
            }
            return customer;
        });

        setCustomers(updatedCustomers);
    };
    const areAllReceivedAmountsFilled = () => {
        for (const customer of customers) {
            if (customer.orders) {
                for (const order of customer.orders) {
                    if (!order.collected_amount) {
                        return false;
                    }
                }
            }
        }

        return true;
    };
    const addCustomerSection = () => {
        if (areAllReceivedAmountsFilled()) {
            setCustomers([
                ...customers,
                { showCustomer: false, selectedCustomer: null, showTable: false, id: randomId() },
            ]);
        } else {
            set_validation_errors((prevErrors) => ({
                ...prevErrors,
                addCustomer: 'Please fill out all received amounts before adding a new customer.',
            }));
        }
    };

    const handleCustomerToggle = (index) => {
        const newCustomers = customers.map((customer, i) =>
            i === index ? { ...customer, showCustomer: !customer.showCustomer } : customer,
        );
        setCustomers(newCustomers);
    };
    if (created) {
        return <Redirect to="/wire-transactions/list" />;
    }

    return (
        <div>
            <ViewHeader renderBackArrow title="Add new wire transaction">
            <ConfirmationModal
                        title="Are you sure that you want to confirm and send to approval"
                        onSubmit={onSubmit}
                    />
            </ViewHeader>
            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            <Row className="px-3 py-4">
                <Col lg={6}>
                    <Select {...bank} options={bankOptions} />
                    {loading && validation_errors.bank && (
                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                            {validation_errors.bank}
                        </p>
                    )}
                    <Textarea {...comments} />
                    <DatePicker {...transfer_date} />
                    {loading && validation_errors.transfer_date && (
                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                            {validation_errors.transfer_date}
                        </p>
                    )}
                </Col>

                <Col lg={6}>
                    <Input {...amount} />
                    {loading && validation_errors.amount && (
                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                            {validation_errors.amount}
                        </p>
                    )}
                    <FileUpload {...attachment} />
                    {loading && validation_errors.attachment && (
                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                            {validation_errors.attachment}
                        </p>
                    )}
                     <Input {...transaction_reference_code} />
                     {loading && validation_errors.reference_code && (
                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                            {validation_errors.reference_code}
                        </p>
                    )}
                </Col>
            </Row>
            {customers.map((customer, index) => (
                <div key={index} className="p-2 ">
                    <div
                        className="p-2 d-flex align-items-center cursor-pointer bg-white text-border rounded border border-gray "
                        onClick={() => handleCustomerToggle(index)}
                    >
                        <svg
                            style={{
                                transform: customer.showCustomer
                                    ? 'rotate(0deg)'
                                    : 'rotate(-90deg)',
                                transition: 'transform 0.3s',
                            }}
                            fill="currentColor"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                            <path d="M0-.75h24v24H0z" fill="none"></path>
                        </svg>
                        <span> {`Customer ${index + 1}`}</span>
                    </div>
                    {customer.showCustomer && (
                        <>
                            {customer.showCustomer && (
                                <div className="d-flex justify-content-between my-3">
                                    <h2>Customer Name</h2>
                                    <Button
                                        onClick={() => {
                                            deleteCustomer(customer.id);
                                        }}
                                        variant="danger"
                                        size="sm"
                                    >
                                        &times;
                                    </Button>
                                </div>
                            )}
                            <Row>
                                <Col lg={6}>
                                    <ApiAsyncSelect
                                        api="/order/customers"
                                        placeholder="Type to select customer..."
                                        labelProperty={(r) => `${r.store_name}`}
                                        value={
                                            customer.selectedCustomer
                                                ? {
                                                      value: customer.selectedCustomer.id,
                                                      label: `${customer.selectedCustomer.store_name}`,
                                                  }
                                                : null
                                        }
                                        onChange={(v) => handleCustomerSelect(index, v)}
                                    />
                                </Col>
                            </Row>
                            {customer.showTable && customer.selectedCustomer && (
                             customer.orders?.length > 0 ?
                            ( <div className="table-tag-wrapper">
                                <table className="table-component-wrapper">
                                    <thead
                                        style={{
                                            color: '#232323',
                                            background: '#f0f0f0',
                                            fontWeight: 700,
                                        }}
                                    >
                                        <tr>
                                            <td>Order ID</td>
                                            <td>Payment type</td>
                                            <td>Payment status</td>
                                            <td>Order status</td>
                                            <td>Total due</td>
                                            <td>Paid amount</td>
                                            <td>Remaining amount</td>
                                            <td>Received amount</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customer?.orders.map((row) => (
                                            <tr key={row.id}>
                                                <td>{row.id}</td>
                                                <td>{row.payment_type}</td>
                                                <td>{row.payment_status}</td>
                                                <td> <StatusBlock state={row?.state} /></td>
                                                <td>
                                                    <span
                                                        style={{
                                                            color: '#4356fd',
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {formatMoney(row?.total_due)}  SAR
                                                    </span>
                                                   
                                                </td>
                                                <td>
                                                    <span
                                                        style={{
                                                            color: '#4356fd',
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {formatMoney(row?.paid_amount)}  SAR
                                                    </span>
                                                   
                                                </td>
                                                <td>
                                                    <span
                                                        style={{
                                                            color: '#4356fd',
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {row.payment_type==='CASH'? formatMoney(row?.total_due ):formatMoney(row?.total_due - row.paid_amount )}  SAR
                                                    </span>
                                                   
                                                </td>
                                                <td width={200}>
                                                    <Input
                                                        value={row.collected_amount }
                                                        setValue={(val) =>
                                                            handleReceivedAmountChange(
                                                                row.id,
                                                                val,
                                                                index,
                                                            )
                                                        }
                                                        type="number"
                                                        acceptDecimal={true}
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() =>
                                                            deleteOrder(index, row.id)
                                                        }
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                            alt="Delete"
                                                            width="16px"
                                                        />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>) :<p className='text-center py-5'>
                            There are no orders for this customer to display </p>
                            )}
                        </>
                    )}
                </div>
            ))}
           
            <div className="ml-2 mb-3">
                <button className="btn btn-info" type="button" onClick={addCustomerSection}>
                    + Add Customer
                </button>
            </div>
            {validation_errors?.order && (
                <p style={{ color: 'red', margin: '10px 0 15px' }}>{validation_errors.order}</p>
            )}
            {validation_errors.addCustomer && (
                <p style={{ color: 'red', margin: '10px 0' }}>{validation_errors.addCustomer}</p>
            )}
            {loading && validation_errors.customers && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.customers}
                </p>
            )}
            {loading && validation_errors.totalCollected && (
                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                    {validation_errors.totalCollected}
                </p>
            )}
            <div className='d-flex justify-content-end'>
            <Button
                            onClick={() => {
                                dispatch(
                                    setModalStatus({
                                        modal: 'confirmation_modal',
                                        status: true,
                                    }),
                                );
                            }}
                        >
                              Confirm and send to approval
                        </Button>
        
                     
            </div>
        </div>
    );
}
export default WireTransactionsAddView;