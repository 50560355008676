/* eslint-disable max-len */
import React from 'react';
import { Button, AllOrSpecificSelection } from '@components';
import { POST, confirmationMessage } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { loadProductData } from '@store/slices/products.slice';
import SelectVariantOrBase from '../../components/SelectVariantOrBase';
import useMutation from '@hooks/useMutation';
import { toast } from 'react-toastify';

const SELECT_ALL = 'none';

export default function PurchaseAvailabilityTab() {
    return (
        <SelectVariantOrBase>
            {({ selected, isBaseSelected }) => (
                <UpdatePurchaseAvailability {...{ selectedId: selected, isBaseSelected }} />
            )}
        </SelectVariantOrBase>
    );
}

function UpdatePurchaseAvailability({ selectedId }) {
    const [is_all_cities_selected, set_is_all_cities_selected] = React.useState(SELECT_ALL); // none or cities
    const [is_all_entities_selected, set_is_all_entities_selected] = React.useState(SELECT_ALL); // none or specific
    const dispatch = useDispatch();
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const product = useSelector((state) => state.ProductsSlice.product);
    const variant = product.variants.find(({ id }) => id === selectedId);

    const {
        mutate,
        isLoading: loading,
        data: response,
    } = useMutation(
        (data) =>
            POST({
                endpoint: `/product/${selectedId}/availability`,
                data,
            }),
        {
            onSuccess: (res) => {
                dispatch(loadProductData(product?.id));
                toast.success(res.message);
            },
        },
    );
    const errors = response?.data?.errors || {};

    React.useEffect(() => {
        if (variant) {
            set_is_all_cities_selected(variant?.purchase_availability?.type);
            cities.onChange(
                variant?.purchase_availability?.cities.map((city) => ({
                    id: city.id,
                    label: city.name,
                })),
            );

            const hasEntities = variant?.personalized_entity_types?.length;
            set_is_all_entities_selected(hasEntities ? 'specific' : SELECT_ALL);

            entities.onChange(
                hasEntities
                    ? variant?.personalized_entity_types.map((entity) => ({
                          id: entity.id,
                          label: entity.name_en,
                      }))
                    : [],
            );
        }
    }, [variant]);

    const [cities, set_cities] = React.useState({
        label: 'Cities',
        value: [],
        getOptionValue: (option) => option.id,
        isMulti: true,
        onChange: (val) => set_cities((prev) => ({ ...prev, value: val })),
    });

    const [entities, set_entities] = React.useState({
        label: 'entities',
        value: [],
        getOptionValue: (option) => option.id,
        isMulti: true,
        onChange: (val) => set_entities((prev) => ({ ...prev, value: val })),
    });

    const submit = async () => {
        if (!product?.id) return;

        const cities_ids = cities.value.map(({ id }) => id);
        const entities_ids = entities.value.map(({ id }) => id);

        mutate({
            availability_type: is_all_cities_selected,
            cities: cities_ids?.length ? cities_ids : null,
            personalized_entity_types:
                is_all_entities_selected === 'specific' ? entities_ids : null,
        });
    };

    return (
        <>
            <AllOrSpecificSelection
                name="cities"
                label="Purchase Availability for Cities"
                selectSpecificValue="cities"
                value={is_all_cities_selected}
                onChange={set_is_all_cities_selected}
                selectProps={{ ...cities, options: GlobalsSlice.ksa_cities }}
            />
            <hr />
            <AllOrSpecificSelection
                name="entities"
                label="Purchase Availability for Entities"
                onChange={set_is_all_entities_selected}
                value={is_all_entities_selected}
                selectProps={{ ...entities, options: GlobalsSlice.entity_types }}
            />

            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}

            <Button
                onClick={() => {
                    variant?.is_active ? submit() : confirmationMessage(submit);
                }}
                variant="primary"
                disabled={
                    loading ||
                    (is_all_cities_selected === 'cities' && !cities?.value?.length) ||
                    !selectedId
                }
            >
                Submit
            </Button>
        </>
    );
}
