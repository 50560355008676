import React from 'react';
import {
    ViewHeader,
    Link,
    Card,
    DataTable,
    StatusToggler,
    Filters,
    Sop
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import defaultLogo from '@assets/img/default.jpg';
import { loadVendorCategoriesData, updateSingleRow } from '@slices/merchantCategories.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function VendorCategoriesListView() {
    const [filters, set_filters] = React.useState({});
    const [page, set_page] = React.useState(1);

    const VendorCategoriesSlice = useSelector((state) => state.VendorCategoriesSlice);
    const dispatch = useDispatch();


    useDidMountEffect(() => {
        dispatch(loadVendorCategoriesData({ ...filters, page }));
    }, [filters, page]);

    const columns = [
        {
            name: 'Image',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo category-logo">
                    <img src={row.image || defaultLogo} alt="Logo" />
                </div>
            ),
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/merchant-categories/${row.id}`} className="tr-name">
                    <h5>{row.name_ar}</h5>
                </Link>
            ),
        },
        {
            name: 'Merchants count',
            selector: 'vendors_count',
            sortable: false,
            center: true
        },
        {
            name: 'Priority',
            selector: 'priority',
            sortable: false,
            center: true
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row.is_active}
                    path={`/vendors/category/${row.id}/status`}
                    callback={({ id, status }) => {
                        dispatch(updateSingleRow({ id, is_active: status }));
                    }}
                />
            ),
        },
    ];


    return (
        <div className="categories-list-view">
            <ViewHeader title="Merchant Categories">
                <Sop id="29823" />
                <Link href="/merchant-categories/add" className="btn btn-primary">
                    New category
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by ID, name"
                    filters={[
                        'is_active'
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    sort_options={[
                        {
                            label: 'Priority (Biggest first)',
                            value: 'priority__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Priority (Smallest first)',
                            value: 'priority__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={VendorCategoriesSlice.vendorCategories}
                    pages={VendorCategoriesSlice.vendorCategories_pages}
                    page={page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={VendorCategoriesSlice.vendorCategories_loading}
                />
            </Card>
        </div>
    );
}
