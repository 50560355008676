import { stringify } from 'query-string';
import React, { useCallback } from 'react';
import { GET, useDebounce } from '@utils';
import { ChoiceList, Icon, TextField, Spinner } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useEffect } from 'react';

export default function APIAsyncFilter({
    api,
    placeholder = 'Type to search...',
    labelProperty,
    isMulti = true,
    params = {},
    onlyFetchActiveRecords = true,
    // allowClear,
    value = null,
    set_value = () => {},
}) {
    const [loading, set_loading] = React.useState(false);
    const [q, set_q] = React.useState('');
    const debounced_q = useDebounce(q, 650);

    const [options, set_options] = React.useState([]);

    const loadOptions = async (q) => {
        if (!api) return [];
        set_loading(true);
        const res = await GET({
            endpoint:
                api +
                '?' +
                stringify({
                    q,
                    ...params,
                    records_per_page: 20,
                    ...(onlyFetchActiveRecords
                        ? {
                              status: 1,
                          }
                        : {}),
                }),
        });
        set_loading(false);
        return res?.data?.map((v) => {
            //special fix in case the id isn't in the filter payload so we fall back on name_en
            if (typeof v.id === 'undefined' || v.id === null) {
                if (v.name_en !== 'undefined' && v.name_en !== null) {
                    v.id = v.name_en;
                }
            }

            v.value = v.id;
            if (labelProperty instanceof Function) {
                v.label = labelProperty(v);
            } else {
                v.label = v[labelProperty];
            }
            return v;
        });
    };

    const triggerFetchOptions = async () => {
        const results = await loadOptions(debounced_q);
        set_options(results);
    };

    useEffect(() => {
        triggerFetchOptions();
    }, [debounced_q]);

    const onChange = useCallback((value) => set_value(value), []);

    return (
        <>
            <TextField
                value={q}
                onChange={set_q}
                autoComplete="off"
                placeholder={placeholder}
                prefix={<Icon source={SearchMinor} />}
            />
            <br />
            {loading ? (
                <div className="flex__jc-center">
                    <Spinner size="small" />
                </div>
            ) : (
                <ChoiceList
                    choices={options}
                    selected={value || []}
                    onChange={onChange}
                    allowMultiple={isMulti}
                />
            )}
        </>
    );
}
