/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    ViewHeader,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Modal,
    Row,
    Col,
    Card,
    StatusBlock,
    Select,
} from '@components';
import useMutation from '@hooks/useMutation';
import { POST, GET } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadVendorProductRequestData } from '@slices/merchantProductRequests.slice';
import VendorChanges from './components/VendorChanges';
import { setModalStatus } from '@slices/ui.slice';
import TextWitSub from './components/TextWitSub';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const WITH_REASON = ['rejected', 'needs_changes'];
const ACTIONS = [
    { key: 'approved', label: 'Approved' },
    { key: 'rejected', label: 'Rejected' },
    { key: 'needs_changes', label: 'Needs changes' },
];

const VendorRequest = () => {
    const { id } = useParams();
    const MODAL_NAME = `vendor_${id}_request_modal`;
    const [action, setAction] = useState(null);
    const dispatch = useDispatch();
    const { vendor_product_request, vendor_product_request_loading } = useSelector(
        (state) => state.VendorProductRequestsSlice,
    );
    const shouldAddReason = WITH_REASON.includes(action?.key);
    const [loading, set_loading] = useState(false);

    const [reason, set_reason] = React.useState({
        label: 'Select the reason',
        options: [],
        value: {},
        getOptionValue: (option) => option?.id,
        onChange: (val) => set_reason((prev) => ({ ...prev, value: val })),
    });

    const { mutate, error } = useMutation(
        (data) =>
            POST({
                endpoint: `/vendorUsers/vendor-product-request/${id}`,
                data,
            }),
        {
            onSuccess: (res) => {
                set_loading(false);
                toast.success(res.message);
                onCloseModal();
            },
            onError: () => {
                set_loading(false);
            },
        },
    );

    const { mutate: getReasons } = useMutation(
        () => GET({ endpoint: '/vendorUsers/vendor-product-request-state-reason' }),
        {
            onSuccess: (s) => {
                const reasons = s.data?.map((r) => ({ label: r.reason_en, id: r.id }));
                reason.options = reasons;
            },
        },
    );

    const onOpenModal = (action) => {
        setAction(action);
        dispatch(setModalStatus({ modal: MODAL_NAME, status: true }));
    };

    const onCloseModal = () => {
        setAction(null);
        dispatch(loadVendorProductRequestData(id));
        dispatch(setModalStatus({ modal: MODAL_NAME, status: false }));
    };

    const onSubmit = () => {
        set_loading(true);
        mutate({ state: action.key, reason_id: reason?.value?.id });
    };

    useEffect(() => {
        dispatch(loadVendorProductRequestData(id));
    }, [id]);

    useEffect(() => {
        if (shouldAddReason) {
            getReasons();
        }
    }, [action]);

    if (vendor_product_request_loading) return 'loading...';

    const actions_list =
        vendor_product_request?.state?.code === 'rejected' ? ACTIONS.slice(-1) : ACTIONS;

    return (
        <div className="vendor-categories-update-view">
            <ViewHeader title={`Merchant Product Request #${id}`} renderBackArrow>
                {['pending_disty_approval', 'rejected'].includes(
                    vendor_product_request?.state?.code,
                ) && (
                    <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Actions"
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            {actions_list?.map((action, i) => (
                                <Dropdown.Item
                                    key={i}
                                    eventKey={i}
                                    onClick={() => onOpenModal(action)}
                                >
                                    {action.label}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </ButtonGroup>
                )}
            </ViewHeader>

            <Card>
                <div className="px-3 py-2">
                    <h3 className="divider-title">Request Information</h3>
                    <Row>
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Merchant"
                                sub={
                                    <Link to={`/merchants/${vendor_product_request?.vendor.id}`}>
                                        {vendor_product_request?.vendor.company_name}
                                    </Link>
                                }
                            />
                        </Col>
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Account Manger"
                                sub={
                                    <>
                                        {vendor_product_request?.vendor.account_manager?.first_name}{' '}
                                        {vendor_product_request?.vendor.account_manager?.last_name}
                                    </>
                                }
                            />
                        </Col>
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Type"
                                sub={vendor_product_request?.type?.description}
                            />
                        </Col>
                        {vendor_product_request?.reason && (
                            <Col lg={3} md={4}>
                                <TextWitSub
                                    text="Reason"
                                    sub={vendor_product_request?.reason?.reason}
                                />
                            </Col>
                        )}
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Status"
                                sub={<StatusBlock noIcon state={vendor_product_request?.state} />}
                            />
                        </Col>

                        {vendor_product_request?.base_product_id && (
                            <Col lg={3} md={4}>
                                <TextWitSub
                                    text="Product ID"
                                    sub={
                                        <Link
                                            to={`/products/${vendor_product_request?.base_product_id}`}
                                        >
                                            {vendor_product_request?.base_product_id}
                                        </Link>
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                </div>
            </Card>

            <Card>
                <div className="px-3 py-2">
                    <h3 className="divider-title">Vendor Changes</h3>

                    <VendorChanges
                        data={vendor_product_request?.requestable}
                        changesType="create_request"
                    />
                </div>
            </Card>

            <Modal
                title={`Update request state to ${action?.label}?`}
                name={MODAL_NAME}
                variant={shouldAddReason ? 'danger' : undefined}
                onSubmit={onSubmit}
                loading={loading}
            >
                {!!Object.keys(error || {}).length && (
                    <div className="alert alert-danger">
                        <ul>
                            {Object.keys(error || {}).map((key) => {
                                return <li key={key}>{error?.[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
                {shouldAddReason && <Select {...reason} />}
            </Modal>
        </div>
    );
};

export default VendorRequest;