// set quantity steps select box
export default function quantityOptions({
    order_min_quantity = 1,
    order_max_quantity = 10,
    order_quantity_step,
    top_allowed_value,
}) {
    const min = order_min_quantity || 1;
    const max = order_max_quantity || min * 20;
    const step = order_quantity_step || 1;

    const options = [];
    for (let i = min; i <= max; i += step) {
        options.push({ value: i, label: i });
    }
    
    if(top_allowed_value){
        return options.filter((option) => option.value <= top_allowed_value);
    }
    // set max to limit options
    return options.slice(0, 1000);
}