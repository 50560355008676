import { setModalStatus, setImagePreviewSrc } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
export default function ImageComponent({
    src,
    showPreview = false,
    alt = 'image',
    width = '100%',
    height = '100%',
    style,
}) {

    const dispatch = useDispatch();
    const handleShowPreview = () => {
        dispatch(
            setModalStatus({
                modal: 'image_preview__modal',
                status: true,
            })
        );
        dispatch(
            setImagePreviewSrc(src)
        );
    };
    return (
        <div className="image-container-preview">
            <img
                className={showPreview ? 'preview-img' : ''}
                src={src}
                width={width}
                height={height}
                alt={alt}
                style={style}
            />
            {showPreview && (
                <p onClick={() => handleShowPreview()}>Click To preview</p>
            )}
        </div>
    );
}
