import React from 'react';
import { DataTable, Button, Modal, Input, ShowErrors } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { GET, POST, DELETE, obj2formdata, randomId } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';

const AdjustmentTypes = () => {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/payment/transactions/adjustment-type',
            });
            set_data(res?.data || []);
            set_loading(false);
        };
        fetchData();
    }, [rerender]);

    const [type_ar, set_type_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_type_ar({ ...type_ar, value: val }),
    });
    const [type_en, set_type_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_type_en({ ...type_en, value: val }),
    });

    const submit = async (closeModal, id) => {
        set_loading(true);

        const data = obj2formdata({
            type_ar: type_ar.value,
            type_en: type_en.value,
        });

        if (id) {
            data.append('_method', 'PUT');
        }

        const res = await POST({
            endpoint: id 
                ? `/payment/transactions/adjustment-type/${id}`
                : '/payment/transactions/adjustment-type',
            data,
            headers: {
                'Idempotency-Key': randomId(),
            }
        });

        set_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('adjustment_type__modal');
            set_rerender(randomId());
            type_ar.setValue('');
            type_en.setValue('');
            set_errors([]);
        } else {
            const errors = res?.data.errors;
            const message = { error: [res?.data.message || 'something wrong'] };
            const defaultError = { error: ['something wrong'] };

            set_errors(errors || message || defaultError);
        }
    };

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'type_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'type_en',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger" 
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'adjustment_type__delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>

					&nbsp;&nbsp;
                    
                    
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            type_ar.setValue(row.type_ar);
                            type_en.setValue(row.type_en);
                            set_errors([]);

                            dispatch(
                                setModalStatus({
                                    modal: 'adjustment_type__modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const deleteAdjustmentType = async closeModal => {
        set_loading(true);
        const res = await DELETE({
            endpoint: `/payment/transactions/adjustment-type/${delete_id}`
        });
        set_loading(false);

        if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('adjustment_type__delete_modal');
            set_rerender(randomId());
            set_delete_id(null);
        } else {
            const errors = res?.data.errors;
            const message = { error: [res?.data.message || 'something wrong'] };
            const defaultError = { error: ['something wrong'] };

            set_errors(errors || message || defaultError);
        }
    };

    return (
        <>
            <DataTable data={data} columns={columns} loading={loading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        type_ar.setValue('');
                        type_en.setValue('');
                        set_errors([]);

                        dispatch(
                            setModalStatus({
                                modal: 'adjustment_type__modal',
                                status: true,
                            })
                        );
                    }}
                >
                        Add new adjustment type
                </Button>
            </div>

            <Modal
                title={edit_id ? 'Update adjustment type' : 'Add new adjustment type'}
                variant={edit_id ? 'warning' : 'primary'}
                name="adjustment_type__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
                loading={loading}
            >
                <Input {...type_ar} />
                <Input {...type_en} />
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>

            <Modal
                title="Delete adjustment type?"
                name="adjustment_type__delete_modal"
                variant="danger"
                onSubmit={deleteAdjustmentType}
                loading={loading}
            >
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>
        </>
    );
};

export default AdjustmentTypes;
