import React from 'react';
import {
    Button
} from '@components';
import {
    POST,
} from '@utils';
import recalculateIcon from '@assets/icons/replay.svg';
import { toast } from 'react-toastify';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
function Recalculate({ id,fetch_invoice, fetch_audits = ()=>{}, icon = false }) {
    const [modal_loading, set_modal_loading] = React.useState(false);
    const handleRecalculate = async () => {
        set_modal_loading(true);
        const res = await POST ({
            endpoint: `/vendors/invoice-requests/${id}/recalculate`,
        });
        set_modal_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            fetch_invoice();
            fetch_audits();
            toast.success(res?.message);
        }else{toast.error(res?.data?.message);}
    };
    return (
        <>
            
            <Button disabled={modal_loading}  onClick={handleRecalculate}
                size="sm" variant="secondary">
                {icon ? (<Tooltip placement="top" 
                    overlay="recalculate purchase order">
                    <div> <img src={recalculateIcon} alt='recalculate'/></div>
                </Tooltip>) 
                    : 'Recalculate purchase order'}</Button>
            
        </>
    );
}

export default Recalculate;