import React from 'react';
import {
    ViewHeader,
    Link,
    DataTable,
    Card,
    Filters,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    StatusBlock,
    Modal,
    Input,
    ShowErrors,
} from '@components';
import { formatDate, formatMoney, GET, POST } from '@utils';
import { stringify } from 'query-string';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';

export default function WalletWithdrawalRequests() {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [submitting, set_submitting] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [total, set_total] = React.useState(null);
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const states_options = [
        { code: 'rejected', description: 'Rejected' },
        { code: 'paid', description: 'Paid' },
    ];
    const [updated_request_data, set_updated_request_data] = React.useState({});
    const [rejected_reason_field, set_rejected_reason_field] = React.useState({
        value: '',
        placeholder: 'Type the details of rejection',
        required: true,
        maxLength: 256,
        setValue: (val) => set_rejected_reason_field({ ...rejected_reason_field, value: val }),
    });
    const [filters, set_filters] = React.useState({});

    React.useEffect(() => {
        set_filters({
            ...filters,
        });
    }, []);

    const getWithdrawalRequests = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/payment/wallet-withdraw-requests?${stringify(
                { ...filters, page, records_per_page },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                },
            )}`,
        });
        set_loading(false);
        if (res?.status_code == 200) {
            set_data(res?.data || []);
            set_total(res?.meta?.last_page);
        }
    };
    React.useEffect(() => {
        getWithdrawalRequests();
    }, [filters, page, records_per_page]);

    const updateState = async () => {
        set_submitting(true);
        const data = {
            state: updated_request_data.state.code,
        };
        if (updated_request_data?.state.code === 'rejected') {
            data['reason'] = rejected_reason_field.value;
        }
        const res = await POST({
            endpoint: `/payment/wallet-withdraw-requests/${updated_request_data?.id}/change-state`,
            data,
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status === 500) {
            setErrors({ message: [res?.data.message] });
        } else if (res?.status_code === 200 || res?.status_code === 201) {
            getWithdrawalRequests();
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            center: true,
            width: '100px',
        },
        {
            name: 'Last update date',
            selector: 'updated_at',
            sortable: false,
            cell: (row) => <>{formatDate(row.updated_at)}</>,
        },
        {
            name: 'Retailer ID',
            selector: 'id',
            sortable: false,
            cell: (row) => <Link href={`/customers/${row.customer?.id}`}>{row.customer?.id}</Link>,
        },
        {
            name: 'Retailer name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/customers/${row.customer?.id}`} className="tr-name">
                    <h5>{row.customer?.name}</h5>
                </Link>
            ),
        },
        {
            name: 'Withdrawal Amount',
            selector: 'withdrawal_amount',
            sortable: false,
            cell: (row) => formatMoney(row.withdrawal_amount),
        },
        {
            name: 'Beneficiary Full Name',
            selector: 'beneficiary_full_name',
            sortable: false,
            cell: (row) => <div>{row?.customer?.bank_account?.beneficiary_full_name}</div>,
        },
        {
            name: 'IBAN',
            selector: 'iban',
            sortable: false,
            cell: (row) => <div>{row?.customer?.bank_account?.iban}</div>,
        },
        {
            name: 'bank',
            selector: 'bank',
            sortable: false,
            cell: (row) => <div>{row?.customer?.bank_account?.bank?.name}</div>,
        },
        {
            name: 'State',
            selector: 'state',
            sortable: false,
            cell: (row) =>
                row.state.code === 'processing' ? (
                    <DropdownButton
                        as={ButtonGroup}
                        title={<div className="status-block">{row.state.code}</div>}
                        id="bg-nested-dropdown"
                        variant="transparent"
                        className="child-fulfillment-status"
                    >
                        {states_options.map((state, i) => (
                            <Dropdown.Item
                                key={i}
                                eventKey={i}
                                onClick={() => {
                                    set_updated_request_data({
                                        id: row.id,
                                        state: state,
                                    });
                                    dispatch(
                                        setModalStatus({
                                            modal: 'update_withdrawal_request_state_modal',
                                            status: true,
                                        }),
                                    );
                                }}
                            >
                                {state?.description}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                ) : (
                    <StatusBlock state={row.state} />
                ),
        },
    ];

    return (
        <div>
            <ViewHeader title="Wallet Withdrawal Requests" />

            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            <Card>
                <Filters
                    queryPlaceholder="Search by customer name, customer ID, and request ID"
                    filters={['wallet_withdrawal_requests_states', 'customers']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'created_at',
                        sort_direction: 'desc',
                    }}
                    sort_options={[
                        {
                            label: 'Request ID (Newest first)',
                            value: 'id__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Request ID (Oldest first)',
                            value: 'id__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Updated at (Newest first)',
                            value: 'updated_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Updated at (Oldest first)',
                            value: 'updated_at__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={data}
                    columns={columns}
                    loading={loading}
                    pages={total}
                    page={page}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    searchable={false}
                />
                <Modal
                    name="update_withdrawal_request_state_modal"
                    title={`
                        #${updated_request_data?.id} change state to be
                        ${updated_request_data?.state?.code}
                    `}
                    submitBtnDisabled={
                        updated_request_data?.state?.code === 'rejected' &&
                        !rejected_reason_field.value
                    }
                    onClose={() => rejected_reason_field.setValue('')}
                    onSubmit={(closeModal) => {
                        updateState();
                        closeModal('update_withdrawal_request_state_modal');
                    }}
                    loading={submitting}
                >
                    {updated_request_data?.state?.code === 'rejected' && (
                        <Input {...rejected_reason_field} />
                    )}
                </Modal>
            </Card>
        </div>
    );
}
