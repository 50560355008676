import { useState } from 'react';
import { Modal, FileUpload, Button } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { bulkPaymentWithCSVRequest } from '@slices/payouts.slice';

import sampleFile from '@assets/files/bulk_payout_csv_sample.csv';

const BulkPaymentWithCSV = () => {
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();
    const errors = PayoutsSlice.bulk_payment_with_csv_errors;

    const [csv_file, set_csv_file] = useState({
        label: 'CSV Bulk Payment File',
        files: [],
        acceptedMimeTypes: 'text/csv',
        maxFiles: 1,
        hintMessage: 'Only *.csv file will be accepted',
        setFiles: (val) => set_csv_file({ ...csv_file, files: val }),
    });

    const [transfer_receipt, set_transfer_receipt] = useState({
        label: 'Transfer receipt (optional)',
        files: [],
        allowPDF: true,
        setFiles: (val) => set_transfer_receipt({ ...transfer_receipt, files: val }),
    });

    const onSubmit = (closeModal) => {
        dispatch(
            bulkPaymentWithCSVRequest({
                payouts_file: csv_file.files[0],
                receipts: transfer_receipt.files,
            }),
        ).then((isSuccess) => {
            if (isSuccess) {
                closeModal();
            }
        });
    };

    const downloadSample = () => {
        var url = sampleFile;
        var a = document.createElement('a');
        a.href = url;
        const fileName = 'bulk_payout_csv_sample';

        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    return (
        <Modal
            title="Bulk Payment With CSV"
            name="payouts_bulk_payment_with_csv_modal"
            confirmBtnName="Upload"
            onSubmit={onSubmit}
            loading={PayoutsSlice.bulk_payment_with_csv_loading}
        >
            <Button
                variant="info"
                onClick={downloadSample}
                style={{ margin: 'auto', marginBottom: 30 }}
            >
                Download CSV Sample File
            </Button>

            <FileUpload {...csv_file} />
            <FileUpload {...transfer_receipt} />

            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default BulkPaymentWithCSV;
