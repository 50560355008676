import React from 'react';
import { Card, Tabs } from '@components';
import formatDate from '@utils/formatDate';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory  } from 'react-router-dom';

const OrderTimeline = ({ active_tab , tabs , setTabs, isSuborderDeleted }) => {
    const { id } = useParams();
    const [data, setData] = React.useState([]);
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    
    const history = useHistory();
    

    React.useEffect(() => {
        if(active_tab === 'All'){
            history.replace(`/orders/${id}`);
            setData(OrdersSlice.order_timeline);
        }else{
            history.replace(`/orders/${id}/${active_tab}`);
            setData(
                OrdersSlice.suborders_timeline?.find(t => t.pretty_id === active_tab)?.audits
            );
        }
    }, [active_tab, OrdersSlice]);
    
    return (
        <Card title="Order Timeline" className="timeline">
            <Tabs tabs={tabs} onSelect={setTabs} />
            {isSuborderDeleted && (
                <div className='alert alert-danger text-center'>
                    Suborder Deleted
                </div>
            )}
            <ul className="timeline__list">
                {data?.map((itm, i) => (
                    <li key={i}>
                        <span>{formatDate(itm.date)}</span>
                        <h4>{itm.user}</h4>
                        <p>{itm.message}</p>
                    </li>
                ))}
            </ul>
        </Card>
    );
};

export default OrderTimeline;
