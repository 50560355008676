import React from 'react';
import {
    Row,
    Radio,
    Col,
    Card,
    Button,
    DataTable,
    Filters,
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadCollectionsData } from '@slices/collections.slice';

import { GET, errorsNormalizer } from '@utils';
import { stringify } from 'query-string';
import { toast } from 'react-toastify';

function EntitiesSelectors({
    products_apply_method,
    set_products_apply_method,
    selected_products,
    set_selected_products,

    vendors_apply_method,
    set_vendors_apply_method,
    selected_vendors,
    set_selected_vendors,

    customers_apply_method,
    set_customers_apply_method,
    selected_customers,
    set_selected_customers,
    disable_customers_selection = false,

    collections_apply_method,
    set_collections_apply_method,
    selected_collections,
    set_selected_collections,
}) {
    // if(set_selected_customers) return '';

    const products_columns = [
        {
            name: 'Product',
            selector: 'name',
            sortable: false,
            cell: (row) => <div className="tr-name">{row.name}</div>,
        },
        {
            name: 'Variant Options',
            cell: row => <div>
                {row?.variant_options?.map((o, i) => (
                    <div key={i}>
                        <span>{o.option_name}: </span>
                        <span>{o.value}</span>
                    </div>
                ))}
            </div>
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: false,
        },
        {
            name: 'Stock',
            selector: 'stock',
            sortable: false,
        },
        {
            name: '',
            selector: 'select',
            sortable: false,
            cell: (row) => {
                if (selected_products.find((p) => p.id === row.id)) {
                    return (
                        <Button
                            onClick={() => {
                                const filtred = selected_products.filter(
                                    (p) => p.id !== row.id
                                );
                                set_selected_products(filtred);
                            }}
                            variant="danger"
                        >
              				-
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            onClick={() => {
                                set_selected_products((prev) => [...prev, { ...row }]);
                            }}
                            variant="success"
                        >
              				+
                        </Button>
                    );
                }
            },
        },
    ];
    const [products_loading, set_products_loading] = React.useState(false);
    const [products_pages, set_products_pages] = React.useState(0);
    const [products, set_products] = React.useState([]);
    const [products_filters, set_products_filters] = React.useState('');
    const [products_page, set_products_page] = React.useState(1);
    React.useEffect(() => {
        const fetchData = async () => {
            set_products_loading(true);
            const res = await GET({
                endpoint: `/product?${stringify(
                    {
                        ...products_filters,
                        page: products_page,
                    },
                    {
                        skipNull: true,
                        skipEmptyString: true,
                    }
                )}`,
            });
            set_products(res?.data || []);
            set_products_pages(res?.meta?.last_page || 0);
            set_products_loading(false);
        };
        fetchData();
    }, [products_filters, products_page]);

    const vendors_columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <div className="tr-name">
                    <h5>
                        {row.company_name_ar}
                        <small>{row.company_name_en}</small>
                    </h5>
                </div>
            ),
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <div className={`status-indicator ${row.status && 'active-indicator'}`}>
                    {row.status ? 'Active' : 'Inactive'}
                </div>
            ),
        },
        {
            name: '',
            selector: 'select',
            sortable: false,
            cell: (row) => {
                if (selected_vendors.find((p) => p.id === row.id)) {
                    return (
                        <Button
                            onClick={() => {
                                const filtred = selected_vendors.filter((p) => p.id !== row.id);
                                set_selected_vendors(filtred);
                            }}
                            variant="danger"
                        >
              				-
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            onClick={() => {
                                set_selected_vendors((prev) => [...prev, { ...row }]);
                            }}
                            variant="success"
                        >
              				+
                        </Button>
                    );
                }
            },
        },
    ];
    const [vendors_loading, set_vendors_loading] = React.useState(false);
    const [vendors_pages, set_vendors_pages] = React.useState(0);
    const [vendors, set_vendors] = React.useState([]);
    const [vendors_filters, set_vendors_filters] = React.useState('');
    const [vendors_page, set_vendors_page] = React.useState(1);
    React.useEffect(() => {
        const fetchData = async () => {
            set_vendors_loading(true);
            const res = await GET({
                endpoint: `/vendors?${stringify(
                    {
                        ...vendors_filters,
                        page: vendors_page,
                    },
                    {
                        skipNull: true,
                        skipEmptyString: true,
                    }
                )}`,
            });
            set_vendors(res?.data || []);
            set_vendors_pages(res?.meta.last_page || 0);
            set_vendors_loading(false);
        };
        fetchData();
    }, [vendors_filters, vendors_page]);

    const customers_columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <div className="tr-name">
                    <h5>{row.first_name}</h5>
                </div>
            ),
        },
        {
            name: 'Total orders',
            selector: 'total_orders',
            sortable: false,
            center: true,
        },
        {
            name: 'Total spent',
            selector: 'total_spent',
            sortable: false,
            center: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <div className={`status-indicator ${row.status && 'active-indicator'}`}>
                    {row.status ? 'Active' : 'Inactive'}
                </div>
            ),
        },
        {
            name: '',
            selector: 'select',
            sortable: false,
            cell: (row) => {
                if (selected_customers?.find((p) => p.id === row.id)) {
                    return (
                        <Button
                            onClick={() => {
                                const filtred = selected_customers.filter(
                                    (p) => p.id !== row.id
                                );
                                set_selected_customers(filtred);
                            }}
                            variant="danger"
                        >
              				-
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            onClick={() => {
                                set_selected_customers((prev) => [...prev, { ...row }]);
                            }}
                            variant="success"
                        >
              				+
                        </Button>
                    );
                }
            },
        },
    ];
    const [customers_loading, set_customers_loading] = React.useState(false);
    const [customers_pages, set_customers_pages] = React.useState(0);
    const [customers, set_customers] = React.useState([]);
    const [customers_filters, set_customers_filters] = React.useState('');
    const [customers_page, set_customers_page] = React.useState(1);
    const [filters, set_filters] = React.useState({});

    const fetchCustomersData = async () => {
        set_customers_loading(true);
        const res = await GET({
            endpoint: `/customers?${stringify(
                {
                    ...customers_filters,
                    page: customers_page,
                    // page,
                    // records_per_page,
                    // min_total_orders,
                    // max_total_orders,
                },
                {
                    skipNull: true,
                    skipEmptyString: true,
                }
            )}`,
        });
        set_customers_loading(false);
        if(res?.status === 422){
            toast.error(errorsNormalizer(res?.data?.errors).join(', '));
        } else {
            set_customers(res?.data || []);
            set_customers_pages(res?.meta?.last_page || 0);
        }      
    };
    React.useEffect(() => {
        fetchCustomersData(filters);
    }, [customers_filters, customers_page, filters]);

    const collections_columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <div className="tr-name">
                    <h5>
                        {row.name}
                    </h5>
                </div>
            ),
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <div className={`status-indicator ${row.is_active && 'active-indicator'}`}>
                    {row.is_active ? 'Active' : 'Inactive'}
                </div>
            ),
        },
        {
            name: '',
            selector: 'select',
            sortable: false,
            cell: (row) => {
                if (selected_collections?.find((p) => p.id === row.id)) {
                    return (
                        <Button
                            onClick={() => {
                                const filtred = selected_collections?.filter(
                                    (p) => p.id !== row.id
                                );
                                set_selected_collections(filtred);
                            }}
                            variant="danger"
                        >
              				-
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            onClick={() => {
                                set_selected_collections((prev) => [...prev, { ...row }]);
                            }}
                            variant="success"
                        >
              				+
                        </Button>
                    );
                }
            },
        },
    ];
    const CollectionsSlice = useSelector((state) => state.CollectionsSlice);
    const dispatch = useDispatch();
    const [collections_q, set_collections_q] = React.useState('');
    const [collections_page, set_collections_page] = React.useState(1);
    const type = 'static';
    React.useEffect(() => {
        dispatch(
            loadCollectionsData({ q: collections_q, type, page: collections_page })
        );
    }, [collections_q, collections_page]);

    return (
        <>
            <Row>
                <Col xs={3}>Apply to merchants :</Col>
                <Col>
                    <Radio
                        onChange={() => {
                            set_vendors_apply_method(null);
                        }}
                        option={vendors_apply_method}
                        label="All"
                        value={null}
                        name="vendors_apply_method"
                    />
                    <Radio
                        onChange={set_vendors_apply_method}
                        option={vendors_apply_method}
                        label="Specific"
                        value="specific"
                        name="vendors_apply_method"
                    />
                    <Radio
                        onChange={set_vendors_apply_method}
                        option={vendors_apply_method}
                        label="Except"
                        value="except"
                        name="vendors_apply_method"
                    />
                </Col>
            </Row>
            <Row
                style={{
                    display: vendors_apply_method ? 'flex' : 'none',
                }}
            >
                <Col xs={3}></Col>
                <Col>
                    {selected_vendors.map((row) => (
                        <div key={row.id} className="single-selected-row">
                            <span
                                onClick={() => {
                                    const filtred = selected_vendors.filter(
                                        (p) => p.id !== row.id
                                    );
                                    set_selected_vendors(filtred);
                                }}
                            >
                				-
                            </span>
                            {row.company_name_ar || row.company_name}
                        </div>
                    ))}
                    <Card>
                        <Filters
                            onFilterChange={(filters_values) => {
                                set_vendors_filters(filters_values);
                            }}
                        />
                        <DataTable
                            data={vendors}
                            pages={vendors_pages}
                            page={vendors_page}
                            handlePageChange={set_vendors_page}
                            columns={vendors_columns}
                            searchable={false}
                            loading={vendors_loading}
                        />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs={3}>Apply to Collections :</Col>
                <Col>
                    <Radio
                        onChange={() => {
                            set_collections_apply_method(null);
                        }}
                        option={collections_apply_method}
                        label="All"
                        value={null}
                        name="collections_apply_method"
                    />
                    <Radio
                        onChange={set_collections_apply_method}
                        option={collections_apply_method}
                        label="Specific"
                        value="specific"
                        name="collections_apply_method"
                    />
                    <Radio
                        onChange={set_collections_apply_method}
                        option={collections_apply_method}
                        label="Except"
                        value="except"
                        name="collections_apply_method"
                    />
                </Col>
            </Row>
            <Row
                style={{
                    display: collections_apply_method ? 'flex' : 'none',
                }}
            >
                <Col xs={3}></Col>
                <Col>
                    {selected_collections?.map((row) => (
                        <div key={row.id} className="single-selected-row">
                            <span
                                onClick={() => {
                                    const filtred = selected_collections?.filter(
                                        (p) => p.id !== row.id
                                    );
                                    set_selected_collections(filtred);
                                }}
                            >
                				-
                            </span>
                            {row.name}
                        </div>
                    ))}
                    <Card>
                        <Filters
                            onFilterChange={(filters_values) => {
                                set_filters(filters_values);
                            }}
                        />
                        <DataTable
                            data={CollectionsSlice.collections || []}
                            pages={CollectionsSlice.collections_pages}
                            page={collections_page}
                            handlePageChange={set_collections_page}
                            columns={collections_columns}
                            searchable={true}
                            onSearch={set_collections_q}
                            loading={CollectionsSlice.collections_loading}
                        />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs={3}>Apply to products :</Col>
                <Col>
                    <Radio
                        onChange={() => {
                            set_products_apply_method(null);
                        }}
                        option={products_apply_method}
                        label="All"
                        value={null}
                        name="products_apply_method"
                    />
                    <Radio
                        onChange={set_products_apply_method}
                        option={products_apply_method}
                        label="Specific"
                        value="specific"
                        name="products_apply_method"
                    />
                    <Radio
                        onChange={set_products_apply_method}
                        option={products_apply_method}
                        label="Except"
                        value="except"
                        name="products_apply_method"
                    />
                </Col>
            </Row>
            <Row
                style={{
                    display: products_apply_method ? 'flex' : 'none',
                }}
            >
                <Col xs={3}></Col>
                <Col>
                    {selected_products.map((row) => (
                        <div key={row.id} className="single-selected-row">
                            <span
                                onClick={() => {
                                    const filtred = selected_products.filter(
                                        (p) => p.id !== row.id
                                    );
                                    set_selected_products(filtred);
                                }}
                            >
                				-
                            </span>
                            {row.name || row.name_en}
                        </div>
                    ))}
                    <Card>
                        <Filters
                            onFilterChange={(filters_values) => {
                                set_products_filters(filters_values);
                            }}
                        />
                        <DataTable
                            data={products}
                            pages={products_pages}
                            page={products_page}
                            handlePageChange={set_products_page}
                            columns={products_columns}
                            searchable={false}
                            loading={products_loading}
                        />
                    </Card>
                </Col>
            </Row>

            {typeof set_customers_apply_method !== 'undefined' && (
                <>
                    <Row>
                        <Col xs={3}>Apply to customers :</Col>
                        <Col>
                            <Radio
                                onChange={() => {
                                    set_customers_apply_method(null);
                                }}
                                option={customers_apply_method}
                                label="All"
                                value={null}
                                name="customers_apply_method"
                                disabled={disable_customers_selection}
                            />
                            <Radio
                                onChange={set_customers_apply_method}
                                option={customers_apply_method}
                                label="Specific"
                                value="specific"
                                name="customers_apply_method"
                                disabled={disable_customers_selection}
                            />
                            <Radio
                                onChange={set_customers_apply_method}
                                option={customers_apply_method}
                                label="Except"
                                value="except"
                                name="customers_apply_method"
                                disabled={disable_customers_selection}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            display: customers_apply_method ? 'flex' : 'none',
                        }}
                    >
                        <Col xs={3}></Col>
                        <Col>
                            {selected_customers?.map((row) => (
                                <div key={row.id} className="single-selected-row">
                                    <span
                                        onClick={() => {
                                            const filtred = selected_customers.filter(
                                                (p) => p.id !== row.id
                                            );
                                            set_selected_customers(filtred);
                                        }}
                                    >
                    					-
                                    </span>
                                    {row.first_name} {row.last_name}
                                </div>
                            ))}

                            {!!selected_customers?.length && (
                                <Button className="mt-1" onClick={() => set_selected_customers([])}>
                  					remove all
                                </Button>
                            )}
                            <Card>
                                <div className="px-3 py-2">
                                    <Button
                                        disabled={customers_loading}
                                        onClick={() =>
                                            set_selected_customers((prev) => [
                                                ...prev,
                                                ...customers.filter(
                                                    ({ id }) => !prev.find((v) => v.id == id)
                                                ),
                                            ])
                                        }
                                    >
                    					select all customers in this page
                                    </Button>
                                </div>
                                
                                <Filters
                                    filters={[
                                        'status',
                                        'cities',
                                        'entity_types',
                                        'account_managers',
                                        'verification_statuses',
                                        'has_wallet_balance',
                                        // eslint-disable-next-line max-len
                                        ...(customers_filters?.has_wallet_balance?.includes(1) ? ['wallet_balance'] : []),
                                        'acquisition_users',
                                        'fulfilled_orders',
                                        'average_rating',
                                    ]}
                                    onFilterChange={(filters_values) => {
                                        set_customers_filters(filters_values);
                                    }}
                                    sort_options={[
                                        {
                                            label: 'ID (DESC)',
                                            value: 'id__desc',
                                            direction: 'desc'
                                        },
                                        {
                                            label: 'ID (ASC)',
                                            value: 'id__asc',
                                            direction: 'asc'
                                        },
                                        {
                                            label: 'Date (DESC)',
                                            value: 'date__desc',
                                            direction: 'desc'
                                        },
                                        {
                                            label: 'Date (ASC)',
                                            value: 'date__asc',
                                            direction: 'asc'
                                        },
                                        {
                                            label: 'Status (DESC)',
                                            value: 'status__desc',
                                            direction: 'desc',
                                        },
                                        {
                                            label: 'Status (ASC)',
                                            value: 'status__asc',
                                            direction: 'asc',
                                        },
                                        {
                                            label: 'Total Orders (DESC)',
                                            value: 'total_orders__desc',
                                            direction: 'desc',
                                        },
                                        {
                                            label: 'Total Orders (ASC)',
                                            value: 'total_orders__asc',
                                            direction: 'asc',
                                        },
				
                                        {
                                            label: 'Total Spent (Newest first)',
                                            value: 'total_spent__desc',
                                            direction: 'desc',
                                        },
                                        {
                                            label: 'Total Spent (Oldest first)',
                                            value: 'total_spent__asc',
                                            direction: 'asc',
                                        },				
                                        {
                                            label: 'Verification Requested Date (Newest first)',
                                            value: 'verification_requested_date__desc',
                                            direction: 'desc',
                                        },
                                        {
                                            label: 'Verification Requested Date (Oldest first)',
                                            value: 'verification_requested_date__asc',
                                            direction: 'asc',
                                        },
                                    ]}
                                    disableFiltersCaching
                                />
                                <DataTable
                                    data={customers}
                                    pages={customers_pages}
                                    page={customers_page}
                                    handlePageChange={set_customers_page}
                                    columns={customers_columns}
                                    searchable={false}
                                    loading={customers_loading}
                                />
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}

const MemoizedEntitiesSelectors = React.memo(EntitiesSelectors);

export default MemoizedEntitiesSelectors;
