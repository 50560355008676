import React from 'react';
import { 
    Card,
    ViewHeader,
    DataTable,
    Button,
    Link,
    Modal,
    Filters,
    Sop
} from '@components';
import { formatMoney, formatDate, POST, downloadCSV } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { loadSelfInvoicesData } from '@slices/selfInvoices.slice';
import { toast } from 'react-toastify';
import { setModalStatus } from '@slices/ui.slice';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function ListView() {
    const SelfInvoicesSlice = useSelector((state) => state.SelfInvoicesSlice);
    const dispatch = useDispatch();
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});
    const [selected_invoices, set_selected_invoices] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [resetRows, setRestRows] = React.useState(false);

    const fetchData = async () => {
        dispatch(loadSelfInvoicesData({ ...filters, page, records_per_page }));
    };


    useDidMountEffect(() => {
        fetchData();
    }, [filters, page, records_per_page]);

    const handleChange = ({ selectedRows }) => {
        set_selected_invoices(selectedRows);
    };

    const markInvoicesAsPaid = async(invoices_ids) => {
        setLoading(true);
        const endpoint = '/vendors/self-invoices/pay';
        const data = { self_invoices: invoices_ids };
        const res = await POST({
            endpoint: endpoint,
            data: data
        });

        setLoading(false);
        if(res?.status_code === 200){
            toast.success('payment status updated successfully');
            set_selected_invoices([]);
            setRestRows(!resetRows);
            dispatch(loadSelfInvoicesData({ ...filters, page, records_per_page }));
        }
        else if(res?.status === 400){
            toast.error(res?.data?.message);
        }       
    };

    // eslint-disable-next-line no-unused-vars
    const removeLinks = (array) => array.map(({ links, vendor, ...rest }) => {
        return {
            ...rest,
            'vendor': vendor?.company_name_en,
        };
    });

    const columns = [
        {
            name: 'SN',
            selector: 'id',
            width: '50px',
            cell: (row) => <Link href={`/invoices/${row?.id}`}>{row?.id}</Link>,
        },
        {
            name: 'Invoice Number',
            selector: 'pretty_id',
            width: '150px',
            cell: (row) => (
                <Link
                    href={`/invoices/${row?.id}`}
                >
                    #{row?.pretty_id}
                </Link>
            )
        },
        {
            name: 'Invoice created date',
            selector: 'created_at',
            sortable: true,
            format: (row) => formatDate(row?.created_at),
        },
        {
            name: 'Merchant',
            cell: (row) => (
                <Link href={`/merchants/${row?.vendor?.id}`}>
                    {row?.vendor?.company_name_en}
                </Link>
            ),
        },
        {
            name: 'Merchant ID',
            cell: (row) => (
                <Link href={`/merchants/${row.vendor?.id}`}>
                    {row.vendor?.id}
                </Link>
            ),
        },
        {
            name: 'Suborder ID',
            selector: 'suborder_pretty_id',
        },
        {
            name: 'Payout date',
            selector: 'payout_date',
            sortable: true,
            format: (row) => formatDate(row?.payout_date),
        },
        {
            name: 'Subtotal',
            selector: 'subtotal',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.subtotal)}</div>
                </div>
            ),
        },
        {
            name: 'VAT',
            selector: 'vat',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.vat)}</div>
                </div>
            ),
        },
        {
            name: 'Vendor commission',
            selector: 'vendor_commission',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.vendor_commission)}</div>
                </div>
            ),
        },
        {
            name: 'Vendor discount amount',
            selector: 'vendor_discount_amount',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.vendor_discount_amount)}</div>
                </div>
            ),
        },
        {
            name: 'Invoice Amount',
            selector: 'amount_due',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.amount)}</div>
                </div>
            ),
        },
        {
            name: 'Payable Amount',
            selector: 'payable_amount',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.payable_amount)}</div>
                </div>
            ),
        },
        {
            name: 'Credit Note Amount',
            selector: 'credit_note',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.credit_note_amount)}</div>
                </div>
            ),
        },
        {
            name: 'Is Paid',
            selector: 'payment_status',
            cell: (row) => row?.is_paid ? 
                <img src={checkedIcon} alt="Yes" /> : 
                <img src={cancelIcon} alt="No" className='dimmed-icon' />
        },
        {
            name: 'Ready to Pay',
            selector: 'ready_to_pay',
            cell: (row) => row?.ready_to_pay ? 
                <img src={checkedIcon} alt="Yes" /> : 
                <img src={cancelIcon} alt="No" className='dimmed-icon' />,
        },
        {
            name: 'Credit note issued',
            selector: 'credit_note_issued',
            cell: (row) => row?.has_credit_notes ? 
                <img src={checkedIcon} alt="Yes" /> : 
                <img src={cancelIcon} alt="No" className='dimmed-icon' />,
        },
    ];
    
    return (
        <div>
            <ViewHeader title="Invoices">
                <div className="flex__ai-center">
                    <Sop id="25283"/>
                    {selected_invoices?.length  > 0 && (
                        <>
                            <Button 
                                variant="info"
                                disabled={loading}
                                onClick={() => downloadCSV('self-invoices',
							 removeLinks(selected_invoices) )}
                            >
							Export CSV
                            </Button>
                            <Button 
                                variant="danger" 
                                className="mr-2"
                                disabled={loading}
                                onClick={() => dispatch(
                                    setModalStatus({
                                        modal: 'invoices_confirm_modal',
                                        status: true
                                    })
                                )}
                            >
							Pay
                            </Button>
                            {selected_invoices?.length} Invoices Selected
                        </>
                    )}
                </div>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by Invoice number, Vendor ID, Order ID, Sub-order ID"
                    filters={[
                        'is_paid',
                        'ready_to_pay',
                        'has_credit_notes',
                        'order_sources',
                        'created_at_from',
                        'created_at_to',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={SelfInvoicesSlice?.self_invoices}
                    pages={SelfInvoicesSlice?.self_invoices_pages}
                    columns={columns}
                    page={page}
                    records={SelfInvoicesSlice?.self_invoices_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    selectableRows
                	handleSelectedRows={handleChange}
                    clearSelectedRows={resetRows}
                    handlePageChange={set_page}
                    loading={SelfInvoicesSlice?.self_invoices_loading}
                />
            </Card>
            <Modal
                title={`Are you sure you want to turn the invoices (invoices 
					${selected_invoices.map(({ id }) => id)?.join(', ')}) status to Paid?`}
                name="invoices_confirm_modal"
                onSubmit={(closeModal) => {
                    markInvoicesAsPaid(
                        selected_invoices.map(({ id }) => id));
                    closeModal('invoices_confirm_modal');
                }}
                loading={loading}
            />
        </div>
    );
}
