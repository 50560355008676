/**
 *
 * @typedef {Object} Return
 * @property {Function} setFiltersCache
 * @property {Function} getFiltersCache
 *
 * @returns {Return}
 */

import { useEffect } from 'react';
import { useRef } from 'react';

function useFiltersCache() {
    const url_path = window.location.pathname;
    const localStorageName = `cached_filters_${url_path}`;
    const does_cache_exist = !!localStorage.getItem(localStorageName);
    const checked_for_cached_filters = useRef(false);

    const setFiltersCache = (filters = {}) => {
        /**
         * sometimes for some reason the filters are passed like this :
         *
        {
            "0": "status",
            "1": "type",
            "sort_by": "created_at",
            "q": null
        }
        and we need to remove the keys that are numbers because they are not valid filters
        */
        const filtered_filters = Object.keys(filters).reduce((acc, key) => {
            if (isNaN(key)) {
                acc[key] = filters[key];
            }
            return acc;
        }, {});
        const data_as_string = JSON.stringify(filtered_filters);

        localStorage.setItem(localStorageName, data_as_string);
    };

    const getFiltersCache = () => {
        const data = JSON.parse(localStorage.getItem(localStorageName) || '{}');

        const filtered_filters = Object.keys(data).reduce((acc, key) => {
            if (isNaN(key)) {
                acc[key] = data[key];
            }
            return acc;
        }, {});

        return filtered_filters;
    };

    useEffect(() => {
        if (does_cache_exist && !checked_for_cached_filters.current) {
            checked_for_cached_filters.current = true;
        }
    }, [does_cache_exist]);

    return {
        setFiltersCache,
        getFiltersCache,
        does_cache_exist,
        checked_for_cached_filters: checked_for_cached_filters.current,
    };
}

export default useFiltersCache;
