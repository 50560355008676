import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';

export default function ReferralsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/referral">
                <h4>
                    Referrals : To be created!
                </h4>
            </ProtectedRoute>   
        </Switch>
    );
}
