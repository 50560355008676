/* eslint-disable max-len */
import React from 'react';
import {
    ViewHeader,
    Button,
    Modal,
    Input,
    Select,
    Toggler,
    Row,
    Col,
    Card,
    ShowErrors,
    Sop
} from '@components';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { POST, GET, DELETE, formatDate } from '@utils';

function ForceUpdate() {
    const dispatch = useDispatch();
    const [errors, set_errors] = React.useState([]);
    const [is_loading, set_is_loading] = React.useState(false);
    const [is_submitting, set_is_submitting] = React.useState(false);
    const [ios, set_ios] = React.useState({});
    const [delete_platform, set_delete_platforms] = React.useState(null);
    const [android, set_android] = React.useState({});
    const [submitting, set_submitting] = React.useState(false);

    const [version_number, set_version_number] = React.useState({
        label: 'Version Number',
        value: '',
        setValue: (val) => set_version_number({ ...version_number, value: val }),
    });
    const [force_update, set_force_update] = React.useState({
        label: 'Force Update',
        checked: true,
        disabled: true,
        onChange: (val) => set_force_update({ ...force_update, checked: val }),
    });
    const [platforms, set_platforms] = React.useState({
        label: 'Platforms',
        options: [
            { label: 'android', value: 'android' },
            { label: 'ios', value: 'ios' },
        ],
        value: [],
        isMulti: true,
        onChange: (val) => set_platforms((prev) => ({ ...prev, value: val })),
    });

    const handelSubmit = async (closeModal) => {
        set_is_submitting(true);
        const res = await POST({
            endpoint: '/app-versions',
            data: {
                version_number: version_number.value,
                platforms: platforms.value.map((x) => x.value),
                force_update: 1 //force_update.value ? 1 : 0,
            },
        });
        set_is_submitting(false);

        if (res?.status_code === 200) {
            closeModal('force_update_create_modal');
            set_version_number({ ...version_number, value: '' });
            set_platforms({ ...platforms, value: [] });
            set_errors([]);
            fetchData();
        } else {
            set_errors(res?.data?.errors);
        }
    };
    const fetchData = async () => {
        set_is_loading(true);
        const [ios_res, android_res] = await Promise.all([
            GET({
                endpoint: '/app-versions/forced?platform=ios',
            }),

            GET({
                endpoint: '/app-versions/forced?platform=android',
            }),
        ]);
        set_is_loading(false);

        if (ios_res?.status_code === 200) {
            set_ios(ios_res?.data);
        }
        if (android_res?.status_code === 200) {
            set_android(android_res?.data);
        }
    };

    const handleDelete = async () => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: '/app-versions/forced',
            data: { platform: delete_platform },
        });
        set_submitting(false);
        if (res?.status_code === 200) {
            set_delete_platforms(null);
            fetchData();
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);
    return (
        <div>
            <ViewHeader title="Force Update">
                <Sop id="30103" />
                <Button
                    onClick={() => {
                        dispatch(
                            setModalStatus({
                                modal: 'force_update_create_modal',
                                status: true,
                            })
                        );
                        set_version_number({ ...version_number, value: '' });
                        set_platforms({ ...platforms, value: [] });
                        set_errors([]);
                    }}
                >
                    {' '}
                    New Version
                </Button>
            </ViewHeader>
            {is_loading ? (
                <h2 className="text-center mt-5">loading...</h2>
            ) : (
                <>
                    <Row>
                        <Col lg={6} sm={12}>
                            <Card className="py-3">
                                <div className="d-flex mx-3 align-items-center justify-content-between">
                                    <h2>IOS</h2>
                                    <Button
                                        onClick={() => {
                                            dispatch(
                                                setModalStatus({
                                                    modal: 'force_update_delete_modal',
                                                    status: true,
                                                })
                                            );
                                            set_delete_platforms('ios');
                                        }}
                                        className="py-2  bg-danger"
                                    >
                                        Rollback
                                    </Button>
                                </div>
                                <div className="mx-3 my-3 text-black">
                                    <h3>Force update : {ios?.force_update ? 'true' : 'false'}</h3>
                                    <h3>Forced at : {formatDate(ios?.forced_at)} </h3>
                                    <h3>Display version : {ios?.display_version}</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={6} sm={12}>
                            <Card className="py-3">
                                <div className="d-flex mx-3 align-items-center justify-content-between">
                                    <h2>Android</h2>
                                    <Button
                                        onClick={() => {
                                            dispatch(
                                                setModalStatus({
                                                    modal: 'force_update_delete_modal',
                                                    status: true,
                                                })
                                            );
                                            set_delete_platforms('android');
                                        }}
                                        className="py-2  bg-danger"
                                    >
                                        Rollback
                                    </Button>
                                </div>
                                <div className="mx-3 my-3 text-black">
                                    <h3>
                                        Force update : {android?.force_update ? 'true' : 'false'}
                                    </h3>
                                    <h3>Forced at : {formatDate(android?.forced_at)} </h3>
                                    <h3>Display version : {android?.display_version}</h3>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}

            <Modal
                name="force_update_delete_modal"
                title={`Confirm delete
             ${delete_platform} platform`}
                variant="danger"
                onSubmit={(closeModal) => {
                    handleDelete();
                    closeModal('force_update_delete_modal');
                }}
                loading={submitting}
            ></Modal>

            <Modal
                onSubmit={(closeModal) => handelSubmit(closeModal)}
                title="Add Version"
                name="force_update_create_modal"
                loading={is_submitting}
            >
                <Input {...version_number} />
                <Select {...platforms} />

                <Toggler {...force_update} />
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors} />
                )}
            </Modal>
        </div>
    );
}

export default ForceUpdate;
