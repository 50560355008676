import React from 'react';
import {
    ViewHeader,
    Link,
    Card,
    DataTable,
    Filters,
    Sop
} from '@components';
import defaultLogo from '@assets/img/default.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { loadBrandsData } from '@slices/brands.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function BrandsListView() {
    const BrandsSlice = useSelector((state) => state.BrandsSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({
        status: null,
    });

    useDidMountEffect(() => {
        dispatch(loadBrandsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'Image',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.logo_ar || defaultLogo} alt="Logo" />
                </div>
            ),
        },
        {
            name: 'Image',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.logo_en || defaultLogo} alt="Logo" />
                </div>
            ),
        },
        {
            name: 'Arabic Name',
            selector: 'name_ar',
            sortable: false,
            cell: (row) => (
                <Link href={`/brands/${row.id}`} className="tr-name">
                    <h5>{row.name_ar}</h5>
                </Link>
            ),
        },
        {
            name: 'English Name',
            selector: 'name_en',
            sortable: false,
            cell: (row) => (
                <Link href={`/brands/${row.id}`} className="tr-name">
                    <h5>{row.name_en}</h5>
                </Link>
            ),
        },
    ];

    return (
        <div className="brands-list-view">
            <ViewHeader title="Brands">
                <Sop id="30023" />
                <Link href="/brands/add" className="btn btn-primary">
                    New brand
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    filters={[
                        'status',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={BrandsSlice.brands}
                    pages={BrandsSlice.brands_pages}
                    page={page}
                    records={BrandsSlice.brands_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={BrandsSlice.brands_loading}
                />
            </Card>
        </div>
    );
}
