import React from 'react';
import {
    ViewHeader,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Card,
    Tabs,
    Modal,
    Sop
} from '@components';
import { useParams, Redirect } from 'react-router';
import { DELETE } from '@utils';
import {
    GeneralTab,
    ImagesTab,
    QuantityDiscountsTab,
    StockTab,
    OrdersStockTransactionsTab,
    PurchaseAvailabilityTab,
    ProductRecommendationTab,
    VideoTab,
    ProductCustomAttributesTab,
} from './Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { loadProductData } from '@slices/products.slice';
import { setProduct } from '../../../store/slices/products.slice';
import VariantsTab from './Tabs/VariantsTab';
import { useLocation } from 'react-router-dom';
import StatusTab from './Tabs/StatusTab';
import ProductDetailsTab from './Tabs/ProductDetailsTab';
import DiscountRangesTab from './Tabs/DiscountRangesTab';
import TeaserImagesTab from './Tabs/TeaserImagesTap';

export default function ProductsUpdateView() {
    const { id } = useParams();
    const { search } = useLocation();
    const ProductsSlice = useSelector((state) => state.ProductsSlice);
    const dispatch = useDispatch();
    const [deleted, set_deleted] = React.useState(false);
    const [submitting, set_submitting] = React.useState(false);
    const [tabs, setTabs] = React.useState([
        {
            name: 'General',
            component: <GeneralTab product={ProductsSlice.product} />,
            isActive: true,
        },
        {
            name: 'Product Details',
            component: <ProductDetailsTab product={ProductsSlice.product} />,
            isActive: false,
        },
        {
            name: 'Discount Ranges',
            component: <DiscountRangesTab/>,
            isActive: false,
        },
        {
            name: 'Product Variants',
            component: <VariantsTab />,
            isActive: false,
        },
        {
            name: 'Custom Attributes',
            component: <ProductCustomAttributesTab />,
            isActive: false,
        },
        {
            name: 'Variants Status',
            component: <StatusTab />,
            isActive: false,
        },
        {
            name: 'Images',
            component: <ImagesTab />,
            isActive: false,
        },
        {
            name: 'Teaser Images',
            component: <TeaserImagesTab />,
            isActive: false,
        },
        {
            name: 'Video',
            component: <VideoTab />,
            isActive: false,
        },
        {
            name: 'Quantity discounts',
            component: <QuantityDiscountsTab />,
            isActive: false,
        },
        {
            name: 'Stock',
            component: <StockTab />,
            isActive: false,
        },
        {
            name: 'Purchase Availability',
            component: <PurchaseAvailabilityTab />,
            isActive: false,
        },
        {
            name: 'Recommendations for a similar product',
            component: <ProductRecommendationTab />,
            isActive: false,
        },
        {
            name: 'Orders stock transaction',
            component: <OrdersStockTransactionsTab />,
            isActive: false,
        },
    ]);

    const deleteProduct = async () => {
        set_submitting(true);

        const res = await DELETE({
            endpoint: `/base-product/${id}`,
        });

        set_submitting(false);

        if (res?.status_code === 200) {
            set_deleted(true);
        }
    };

    React.useEffect(() => {
        dispatch(setProduct({}));
        dispatch(loadProductData(id));
    }, []);

    React.useEffect(() => {
        const browserUrlTab = Number(search.match(/tab_index=(\d+)/)?.[1] || 1);
        const activeTabIndex = tabs.findIndex((t) => t.isActive);

        if (browserUrlTab && activeTabIndex && browserUrlTab !== activeTabIndex + 1) {
            setTabs((state) =>
                state.map((tab, index) => ({ ...tab, isActive: browserUrlTab === index + 1 })),
            );
        }
    }, [search, tabs]);

    if (ProductsSlice.product_loading && !ProductsSlice.product?.id) {
        return 'loading...';
    }

    if (deleted) {
        return <Redirect to="/products/list" />;
    }

    return (
        <div className="products-single-view">
            <ViewHeader
                title={`Product: ${ProductsSlice.product.name_ar}`}
                renderBackArrow
            >
                <Sop id="29963" />
                <ButtonGroup>
                    <DropdownButton
                        as={ButtonGroup}
                        title="Actions"
                        id="bg-nested-dropdown"
                        variant="light"
                    >
                        <Dropdown.Item
                            eventKey="2"
                            onClick={() =>
                                dispatch(
                                    setModalStatus({
                                        modal: 'single_product_view__delete_modal',
                                        status: true,
                                    }),
                                )
                            }
                        >
                            Delete
                        </Dropdown.Item>
                    </DropdownButton>
                </ButtonGroup>
            </ViewHeader>

            <Card className="product-tabs">
                <Tabs tabs={tabs} onSelect={setTabs}>
                    <div className="px-3 py-2">{tabs.find((t) => t.isActive)?.component}</div>
                </Tabs>
            </Card>

            <Modal
                title={`Delete ${ProductsSlice.product.name_ar}?`}
                name="single_product_view__delete_modal"
                variant="danger"
                onSubmit={(closeModal) => {
                    deleteProduct();
                    closeModal('single_product_view__delete_modal');
                }}
                loading={submitting}
            />
        </div>
    );
}
