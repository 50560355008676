import React from 'react';
import { Prompt } from 'react-router-dom';
function CustomPrompt({ children }) {
    const [form_change , set_form_change ] = React.useState(false);
    const formRef = React.useRef();
    React.useEffect(() => {
        const element = formRef.current;
        let selectElement = element.querySelectorAll('.form_select');
        let imageElement = element.querySelectorAll('.custom-dropzone');
        if(selectElement?.length){
            selectElement.forEach((item => item.addEventListener('click'
                , () => set_form_change(true)) ));
        }
        if(imageElement?.length){
            imageElement.forEach((item => item.addEventListener('drop'
                , () => set_form_change(true)) ));
        }
        element.addEventListener('input', () => set_form_change(true));
        element.addEventListener('select', () => set_form_change(true));
        element.addEventListener('textarea', () => set_form_change(true));
        element.addEventListener('option', () => set_form_change(true));
        
        return () => {
            element.removeEventListener('input',null);
            element.removeEventListener('select',null);
            element.removeEventListener('textarea',null);
            element.removeEventListener('option',null);
            if(selectElement?.length){
                selectElement.forEach((item => item.removeEventListener('click',null)));
            }
            if(imageElement?.length){
                imageElement.forEach((item => item.removeEventListener('drop',null)));
            }
        };
    }, []);
    const alertUser = e => {
        e.preventDefault();
        e.returnValue = '';
    };
    React.useEffect(()=>{
        if(form_change){
            window.addEventListener('beforeunload', alertUser);
        }
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    },[form_change]);
    return (<>
        <Prompt when={form_change} message='If you leave this page, any unsaved changes
         will be lost '/>
        <form ref={formRef}>
            {children}
        </form>
    </>
      
    );
}

export default CustomPrompt;