import React from 'react';
import { Modal, Input, FileUpload, Row, Col, Checkbox, ShowErrors } from '@components';
import { obj2formdata } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import {
    editOnboardingScreen,
    createOnboardingScreen,
    setScreen,
    setScreenErrors,
} from '@slices/onboardingScreens.slice';

export default function OnboardingScreenModal() {
    const dispatch = useDispatch();
    const OnboardingScreensSlice = useSelector((state) => state.OnboardingScreensSlice);
    const screen = OnboardingScreensSlice.screen;
    const [errors, setErrors] = React.useState([]);
    const [image_ar_thumb, set_image_ar_thumb] = React.useState('');
    const [image_en_thumb, set_image_en_thumb] = React.useState('');
    const [image_ar, set_image_ar] = React.useState({
        label: 'Image (AR)',
        files: [],
        setFiles: (val) => set_image_ar({ ...image_ar, files: val }),
    });
    const [image_en, set_image_en] = React.useState({
        label: 'Image (EN)',
        files: [],
        setFiles: (val) => set_image_en({ ...image_en, files: val }),
    });
    const [title_ar, set_title_ar] = React.useState({
        label: 'title (AR)',
        dir: 'rtl',
        value: '',
        setValue: (val) => set_title_ar({ ...title_ar, value: val }),
    });
    const [content_ar, set_content_ar] = React.useState({
        label: 'Content (AR)',
        dir: 'rtl',
        value: '',
        setValue: (val) => set_content_ar({ ...content_ar, value: val }),
    });
    const [title_en, set_title_en] = React.useState({
        label: 'title (EN)',
        value: '',
        setValue: (val) => set_title_en({ ...title_en, value: val }),
    });
    const [content_en, set_content_en] = React.useState({
        label: 'Content (EN)',
        value: '',
        setValue: (val) => set_content_en({ ...content_en, value: val }),
    });
    const [is_active, set_is_active] = React.useState({
        label: 'Is Active',
        checked: true,
        onChange: (val) => set_is_active({ ...is_active, checked: val }),
    });

    React.useEffect(() => {
        setErrors(OnboardingScreensSlice.screen_errors);
    }, [OnboardingScreensSlice.screen_errors]);


    React.useEffect(() => {
        if (screen?.id) {
            is_active.onChange(screen?.is_active);
            set_image_ar_thumb(screen?.image_ar);
            set_image_en_thumb(screen?.image_en);
            title_ar.setValue(screen?.title_ar);
            title_en.setValue(screen?.title_en);
            content_ar.setValue(screen?.content_ar);
            content_en.setValue(screen?.content_en);
        }
    }, [screen]);

    const onSubmit = async () => {
        const data = obj2formdata({
            is_active: is_active.checked ? '1' : '0',
            title_ar: title_ar.value,
            title_en: title_en.value,
            content_ar: content_ar.value,
            content_en: content_en.value,
        });
        image_ar.files.forEach((image) => {
            data.append('image_ar', image);
        });
        image_en.files.forEach((image) => {
            data.append('image_en', image);
        });

        const onSuccess = () => closeModalAndResetData();
        const onFailed = () => setErrors(OnboardingScreensSlice.screen_errors);
        // edit screen data
        if (screen?.id) {
            data.append('_method', 'PATCH');
            await dispatch(editOnboardingScreen(screen?.id, data, onSuccess, onFailed));
            return;
        }
        // create new screen
        await dispatch(createOnboardingScreen(data, onSuccess, onFailed));
    };

    const closeModalAndResetData = () => {
        dispatch(
            setModalStatus({
                modal: 'onboarding_screen_modal',
                status: false,
            })
        );
        // reset modal data
        is_active.onChange(true);
        image_ar.setFiles([]);
        image_en.setFiles([]);
        set_image_ar_thumb(null);
        set_image_en_thumb(null);
        title_ar.setValue('');
        title_en.setValue('');
        content_ar.setValue('');
        content_en.setValue('');
    };

    return (
        <Modal
            name="onboarding_screen_modal"
            title={screen?.id ? `Edit Screen #${screen.id}` : 'Create new screen'}
            size="lg"
            onSubmit={onSubmit}
            onClose={() => {
                closeModalAndResetData();
                dispatch(setScreen(null));
                dispatch(setScreenErrors(null));
            }}
            submitBtnDisabled={
                OnboardingScreensSlice.screen_loading ||
                !title_ar.value || !title_en.value ||
                !content_ar.value || !content_en.value
            }
        >
            {OnboardingScreensSlice?.screens_list?.filter(s => s.is_active).length === 6 &&
                !screen?.id ?
                (
                    <div className="alert alert-danger">
                        The maximum number of onboarding screens is 6.
                        Please deactivate one of the existing ones to proceed.
                    </div>
                ) : (
                    <>
                        {errors && (
                            <>
                                {typeof errors === 'string' ? (
                                    <div className="alert alert-danger">{errors}</div>
                                ) : (
                                    <ShowErrors errors={errors} />
                                )}
                            </>
                        )}
                        <Row>
                            <Col>
                                <Checkbox {...is_active} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FileUpload {...image_en} />
                                {screen?.id && (
                                    <ul className="thumpnails">
                                        <li>
                                            <img src={image_en_thumb} alt="" />
                                        </li>
                                    </ul>
                                )}
                                <Input {...title_en} />
                                <Input {...content_en} />
                            </Col>
                            <Col>
                                <FileUpload {...image_ar} />
                                {screen?.id && (
                                    <ul className="thumpnails">
                                        <li>
                                            <img src={image_ar_thumb} alt="" />
                                        </li>
                                    </ul>
                                )}
                                <Input {...title_ar} />
                                <Input {...content_ar} />
                            </Col>
                        </Row>
                    </>
                )}
        </Modal>
    );
}