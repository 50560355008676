import React from 'react';
import { DataTable ,Link, WhatsApp, Dialer } from '@components';
import { GET } from '@utils';
import { stringify } from 'query-string';
import defaultLogo from '@assets/img/default.jpg';

function FollowedVendorsTab({ id }) {
    const [followed_vendors , set_followed_vendors]= React.useState([]);
    const [meta_pages , set_meta_pages]=React.useState(0);
    const [page, set_page] = React.useState(1);
    const [loading , set_loading]=React.useState(true);
    const fetchData = async () => {
        const res = await GET({ endpoint:`/customers/${id}/vendors?${stringify({ page } , {
            skipNull: true,
            skipEmptyString: true
        })}` });
        set_loading(false);
        if(res.status_code === 200){
            set_followed_vendors(res?.data || []);
            set_meta_pages(res?.meta?.last_page || 0);
        }
    };
    React.useEffect(()=>{
        fetchData();
    },[]);
    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            center: true,
            width: '100px'
        },
        {
            name: 'Logo',
            selector: 'logo',
            center: true,
            width: '100px',
            cell: (row) => (
                <Link href={`/merchants/${row.id}`} className="tr-logo">
                    <img src={row.logo_ar || defaultLogo} alt="Logo" />
                </Link>
            )
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/merchants/${row.id}`} className="tr-name">
                    <h5>
                        {row.company_name_ar}
                        <small>{row.company_name_en}</small>
                    </h5>
                </Link>
            )
        },
        {
            name: 'Products',
            sortable: false,
            cell: (row) =>(
                <Link to={`/merchants/${row.id}?tab_index=2`}>{row.products_count}</Link>
            )
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            sortable: false,
            cell: (row) => <Dialer number={row?.mobile}/>
            
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            sortable: false,
            cell: (row) => <WhatsApp number={row?.mobile}/>
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: false,
            cell:(row)=> <a href={`mailto:${row?.email}`}>
                {row?.email}</a>
        },
        {
            name: 'Vendor Type',
            selector: 'vendor_type',
            sortable: false,
            cell: (row) => row?.vendor_type?.name_en
        },
        {
            name: 'Registered',
            selector: 'created_date',
            sortable: false
        },
        {
            name: 'Verification state',
            selector: 'state',
        },
        {
            name: 'visibility priority',
            selector: 'visibility_priority',
        },
        {
            name: 'Followers',
            selector: 'followers_count',
            sortable: false,
            cell: (row) => (<Link href={`/merchants/${row.id}`} className="tr-name">
                {row?.followers_count}
            </Link>)
        },
    ];
    return (
        <DataTable
            data={followed_vendors}
            pages={meta_pages}
            page={page}
            handlePageChange={set_page}
            columns={columns}
            loading={loading}
        />
    );
}

export default FollowedVendorsTab;