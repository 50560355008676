/* eslint-disable max-len */

import React from 'react';
import {
    ViewHeader,
    Input,
    Button,
    Card,
    Row,
    Col,
    Textarea,
    FileUpload,
    Select,
    Checkbox,
    Slug,
    ApiAsyncSelect,
    Radio,
    ShowErrors,
    CustomPrompt,
    CreatableTextSelect,
    OverlayTrigger,
    Tooltip,
    Sop,
} from '@components';
import { POST, isEnglish, isArabic } from '@utils';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import helpIcon from '@assets/icons/help.svg';
import ShippingFulfillmentSettingsField from './VendorsUpdateView/ShippingFulfillmentSettingsField';
import serialize from '@utils/objectSerializer';
import MarketplaceInvoicing from './VendorsUpdateView/Tabs/MarketplaceInvoicing';
import { GET } from '@utils';
import useQuery from '@hooks/useQuery';
import { DEFAULT_DELIVERY_OPTIONS, SUB_ORDER_STATUS } from '@components/shared/Filters/constants';
import DeliveryCartSizes from './VendorsUpdateView/Tabs/DeliveryCartSizes';
import styles from './MerchantsAddView.module.css';
import { ZATCA_IDENTIFICATION_TYPES } from './constants';

export default function VendorsAddView() {
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(null);
    const [banks, set_banks] = React.useState([]);

    const [is_all_entity_types_selected, set_is_all_entity_types_selected] = React.useState('all');
    const [select_all, set_select_all] = React.useState(false);

    const [entity_types, set_entity_types] = React.useState({
        options: [],
        getOptionValue: (option) => option.id,
        value: [],
        isMulti: true,
        onChange: (val) => set_entity_types((prev) => ({ ...prev, value: val })),
    });
    const [invoice_issuance_type, set_invoice_issuance_type] = React.useState('invoicing_3');
    const [invoicing_3_data, set_invoicing_3_data] = React.useState({
        tax_invoice_trigger: SUB_ORDER_STATUS.Fulfilled,
    });
    const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [days, set_days] = React.useState({
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
    });
    const { data: { data: payoutOptions } = { data: [] } } = useQuery(
        '/vendors/vendor-payout-options',
    );
    const [payout_option_id, set_payout_option_id] = React.useState({
        label: 'Payout option',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_payout_option_id((prev) => ({ ...prev, value: val })),
    });
    React.useEffect(() => {
        set_payout_option_id({
            ...payout_option_id,
            value: '',
            label: `Payout ${invoice_issuance_type === 'purchase_order' ? '2.0' : '3.0'}`,
            options: payoutOptions?.map((p) => ({
                id: p?.id,
                label: p?.name_en,
            })),
        });
    }, [payoutOptions, invoice_issuance_type]);
    const fetchBanks = async () => {
        const res = await GET({
            endpoint: '/payment/banks',
        });

        const banksList = res?.data?.map((b) => ({
            label: b.name_en,
            id: b.id,
        }));

        set_banks(banksList);
    };

    React.useEffect(() => {
        fetchBanks();
    }, []);

    React.useEffect(() => {
        // set globals
        set_entity_types({
            ...entity_types,
            options: GlobalsSlice.entity_types,
        });
        set_country({ ...country, options: GlobalsSlice.countries });
        set_city({ ...city, options: GlobalsSlice.ksa_cities });
        set_vendor_categories({
            ...vendor_categories,
            options: GlobalsSlice.vendors_categories,
        });
        set_plan({ ...plan, options: GlobalsSlice.plans });
        set_cbv_take_rate({
            ...cbv_take_rate,
            options: GlobalsSlice.all_plans.map((p) => ({
                id: p.percentage,
                label: p.name_en,
            })),
        });
        set_vendor_cbv_take_rate({
            ...vendor_cbv_take_rate,
            options: GlobalsSlice.all_plans.map((p) => ({
                id: p.percentage,
                label: p.name_en,
            })),
        });
        set_shipping_method({
            ...shipping_method,
            options: GlobalsSlice.shippingMethods,
        });
        // set_account_manager({
        //     ...account_manager,
        //     options: GlobalsSlice.accountManagers
        // });
        set_vendor_type_id({
            ...vendor_type_id,
            options: GlobalsSlice.vendors_types,
        });
    }, [GlobalsSlice]);

    {
        /* Arabic fields */
    }
    const [legal_company_name_ar, set_legal_company_name_ar] = React.useState({
        label: 'Legal company name',
        value: '',
        setValue: (val) =>
            isArabic(val)
                ? set_legal_company_name_ar({ ...legal_company_name_ar, value: val })
                : '',
    });
    const [company_name_ar, set_company_name_ar] = React.useState({
        label: 'Company Name',
        value: '',
        setValue: (val) =>
            isArabic(val) ? set_company_name_ar({ ...company_name_ar, value: val }) : '',
    });
    const [description_ar, set_description_ar] = React.useState({
        label: 'Description',
        value: '',
        setValue: (val) =>
            isArabic(val) ? set_description_ar({ ...description_ar, value: val }) : '',
    });
    const [logo_ar, set_logo_ar] = React.useState({
        label: 'Merchant logo',
        files: [],
        setFiles: (val) => set_logo_ar({ ...logo_ar, files: val }),
    });
    const [banner_image_ar, set_banner_image_ar] = React.useState({
        label: 'Merchant banner image',
        files: [],
        setFiles: (val) => set_banner_image_ar({ ...banner_image_ar, files: val }),
    });
    {
        /* English fields  */
    }
    const [legal_company_name_en, set_legal_company_name_en] = React.useState({
        label: 'Legal company name',
        value: '',
        setValue: (val) =>
            isEnglish(val)
                ? set_legal_company_name_en({ ...legal_company_name_en, value: val })
                : '',
    });
    const [company_name_en, set_company_name_en] = React.useState({
        label: 'Company Name',
        value: '',
        setValue: (val) =>
            isEnglish(val) ? set_company_name_en({ ...company_name_en, value: val }) : '',
    });
    const [description_en, set_description_en] = React.useState({
        label: 'Description',
        value: '',
        setValue: (val) =>
            isEnglish(val) ? set_description_en({ ...description_en, value: val }) : '',
    });
    const [logo_en, set_logo_en] = React.useState({
        label: 'Merchant logo',
        files: [],
        setFiles: (val) => set_logo_en({ ...logo_en, files: val }),
    });
    const [banner_image_en, set_banner_image_en] = React.useState({
        label: 'Merchant banner image',
        files: [],
        setFiles: (val) => set_banner_image_en({ ...banner_image_en, files: val }),
    });
    const [establishment_ar, set_establishment_ar] = React.useState({
        label: 'Establishment',
        value: '',
        setValue: (val) =>
            isArabic(val) ? set_establishment_ar({ ...establishment_ar, value: val }) : '',
    });
    const [establishment_en, set_establishment_en] = React.useState({
        label: 'Establishment',
        value: '',
        setValue: (val) =>
            isEnglish(val) ? set_establishment_en({ ...establishment_en, value: val }) : '',
    });
    const [story_ar, set_story_ar] = React.useState({
        label: 'Story',
        value: '',
        setValue: (val) => (isArabic(val) ? set_story_ar({ ...story_ar, value: val }) : ''),
    });
    const [story_en, set_story_en] = React.useState({
        label: 'Story',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_story_en({ ...story_en, value: val }) : ''),
    });
    {
        /* Generic fields  */
    }
    const [commercial_registration_number, set_commercial_registration_number] = React.useState({
        label: 'Commercial Registration Number',
        value: '',
        type: 'number',
        errorMsg: '',
        showError: false,
        onBlur: (e) => {
            const value = e.target.value;

            const is_valid = /^\d{10}$/.test(value);
            const showError = !is_valid;
            const errorMsg = !is_valid && 'Enter 10 digits only';

            set_commercial_registration_number({
                ...commercial_registration_number,
                value,
                showError,
                errorMsg,
            });
        },
        setValue: (val) =>
            set_commercial_registration_number({
                ...commercial_registration_number,
                value: val,
            }),
    });

    const [country, set_country] = React.useState({
        label: 'Country',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_country((prev) => ({ ...prev, value: val })),
    });
    const [city, set_city] = React.useState({
        label: 'City',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_city((prev) => ({ ...prev, value: val })),
    });
    const [status, set_status] = React.useState({
        label: 'Status',
        options: [
            { value: '1', label: 'فعال' },
            { value: '0', label: 'غير فعال' },
        ],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_status({ ...status, value: val }),
    });
    const [email, set_email] = React.useState({
        label: 'Email',
        type: 'email',
        value: '',
        setValue: (val) => set_email({ ...email, value: val }),
    });
    const [order_delivery_fees_inside_riyadh, set_order_delivery_fees_inside_riyadh] =
        React.useState({
            label: 'Inside Riyadh',
            type: 'number',
            value: '',
            prefix: <span>SAR</span>,
            setValue: (val) =>
                set_order_delivery_fees_inside_riyadh({
                    ...order_delivery_fees_inside_riyadh,
                    value: val,
                }),
        });
    const [order_delivery_fees_outside_riyadh, set_order_delivery_fees_outside_riyadh] =
        React.useState({
            label: 'Outside Riyadh',
            type: 'number',
            value: '',
            prefix: <span>SAR</span>,
            setValue: (val) =>
                set_order_delivery_fees_outside_riyadh({
                    ...order_delivery_fees_outside_riyadh,
                    value: val,
                }),
        });
    const [mobile, set_mobile] = React.useState({
        label: 'Mobile number',
        value: '',
        prefix: '+966',
        setValue: (val) => set_mobile({ ...mobile, value: val }),
    });

    const [vendor_type_id, set_vendor_type_id] = React.useState({
        label: 'Merchant type',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_type_id((prev) => ({ ...prev, value: val })),
    });
    const [uses_vendor_app, set_uses_vendor_app] = React.useState({
        label: 'Uses merchant app',
        checked: true,
        onChange: (val) => set_uses_vendor_app({ ...uses_vendor_app, checked: val }),
    });

    const [shipping_method, set_shipping_method] = React.useState({
        label: 'Shipping method',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_shipping_method((prev) => ({ ...prev, value: val })),
    });
    // const [account_manager, set_account_manager] = React.useState({
    //     label: 'Account manager',
    //     options: [],
    //     value: '',
    //     getOptionValue: (option) => option.id,
    //     onChange: (val) =>
    //         set_account_manager((prev) => ({ ...prev, value: val }))
    // });
    const [assigned_admin_user, set_assigned_admin_user] = React.useState(null);
    const [vendor_min_amount, set_vendor_min_amount] = React.useState({
        label: 'Merchant Minimum Amount',
        type: 'number',
        value: '',
        setValue: (val) => set_vendor_min_amount({ ...vendor_min_amount, value: val }),
    });
    const [vendor_categories, set_vendor_categories] = React.useState({
        label: 'Merchant category',
        options: [],
        value: '',
        isMulti: true,
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_categories((prev) => ({ ...prev, value: val })),
    });
    const [state, set_state] = React.useState({
        label: 'Verification state',
        options: [
            {
                id: 1,
                label: 'Not verified',
                value: 'not_verified',
            },
            {
                id: 2,
                label: 'Verification in progress',
                value: 'verification_in_progress',
            },
            {
                id: 3,
                label: 'Verified',
                value: 'verified',
            },
        ],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_state((prev) => ({ ...prev, value: val })),
    });
    const [tax, setTax] = React.useState({
        label: 'Is taxable',
        checked: true,
        onChange: (val) => setTax({ ...tax, checked: val }),
    });
    const [vat_account_number, set_vat_account_number] = React.useState({
        label: 'VAT account number',
        type: 'number',
        value: '',
        setValue: (val) => set_vat_account_number({ ...vat_account_number, value: val }),
    });
    const [instagram_url, set_instagram_url] = React.useState({
        label: 'Instagram URL',
        type: 'text',
        value: '',
        setValue: (val) => set_instagram_url({ ...instagram_url, value: val }),
    });
    const [facebook_url, set_facebook_url] = React.useState({
        label: 'Facebook URL',
        type: 'text',
        value: '',
        setValue: (val) => set_facebook_url({ ...facebook_url, value: val }),
    });
    const [linkedin_url, set_linkedin_url] = React.useState({
        label: 'Linkedin URL',
        type: 'text',
        value: '',
        setValue: (val) => set_linkedin_url({ ...linkedin_url, value: val }),
    });
    const [twitter_url, set_twitter_url] = React.useState({
        label: 'Twitter URL',
        type: 'text',
        value: '',
        setValue: (val) => set_twitter_url({ ...twitter_url, value: val }),
    });

    {
        /* Bank details  */
    }
    const [bank_name, set_bank_name] = React.useState({
        label: 'Bank name',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_bank_name((prev) => ({ ...prev, value: val })),
    });
    const [holder_name, set_holder_name] = React.useState({
        label: 'Holder name',
        type: 'text',
        value: '',
        setValue: (val) => set_holder_name({ ...holder_name, value: val }),
    });
    const [account_number, set_account_number] = React.useState({
        label: 'Account number',
        type: 'number',
        value: '',
        setValue: (val) => set_account_number({ ...account_number, value: val }),
    });
    const [iban, set_iban] = React.useState({
        label: 'IBAN',
        type: 'text',
        value: '',
        setValue: (val) => set_iban({ ...iban, value: val }),
    });
    const [visibility_priority, set_visibility_priority] = React.useState({
        label: 'visibility priority',
        value: '',
        setValue: (val) => set_visibility_priority({ ...visibility_priority, value: val }),
    });

    {
        /* Agreement fields  */
    }
    const shipmentAgreement = [
        {
            id: 'Disty',
            label: 'Disty',
        },
        {
            id: 'Vendor',
            label: 'Merchant',
        },
    ];
    const [plan, set_plan] = React.useState({
        label: 'Disty Take Rate',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_plan((prev) => ({ ...prev, value: val })),
    });
    const [delivery_options, set_delivery_options] = React.useState(DEFAULT_DELIVERY_OPTIONS);
    const [shipping_type, set_shipping_type] = React.useState({
        label: 'Shipping type',
        options: [
            {
                value: 'direct_to_consumer',
                label: 'direct to consumer',
            },
            {
                value: 'first_mile',
                label: 'first mile',
            },
            {
                value: 'first_mile_last_mile',
                label: 'first mile last mile',
            },
        ],
        value: '',
        // getOptionValue: (option) => option.id,
        onChange: (val) => set_shipping_type((prev) => ({ ...prev, value: val })),
    });
    const [cbv_take_rate, set_cbv_take_rate] = React.useState({
        label: 'CBV - (Admin) Source Take rate',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_cbv_take_rate((prev) => ({ ...prev, value: val })),
    });
    const [vendor_cbv_take_rate, set_vendor_cbv_take_rate] = React.useState({
        label: 'CBV - (Merchant) source Take rate',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_cbv_take_rate((prev) => ({ ...prev, value: val })),
    });
    const [is_vendor_cbv_enabled, set_is_vendor_cbv_enabled] = React.useState({
        label: 'Is merchant cbv enabled',
        checked: false,
        onChange: (val) => {
            set_is_vendor_cbv_enabled({ ...is_vendor_cbv_enabled, checked: val });
            if (val) {
                is_crm_feature_enabled.onChange(true);
            }
        },
    });
    const [is_crm_feature_enabled, set_is_crm_feature_enabled] = React.useState({
        label: 'Enabled Retailer Feature',
        checked: true,
        onChange: (val) => set_is_crm_feature_enabled((prev) => ({ ...prev, checked: val })),
    });
    const [first_mile, set_first_mile] = React.useState({
        label: 'FM',
        options: shipmentAgreement,
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_first_mile((prev) => ({ ...prev, value: val })),
    });
    const [second_mile, set_second_mile] = React.useState({
        label: 'MM',
        options: shipmentAgreement,
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_second_mile((prev) => ({ ...prev, value: val })),
    });
    const [last_mile, set_last_mile] = React.useState({
        label: 'LM',
        options: shipmentAgreement,
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_last_mile((prev) => ({ ...prev, value: val })),
    });
    const [plan_type, set_plan_type] = React.useState({
        label: 'Plan type',
        options: [
            {
                value: 'single',
                label: 'Single',
            },
            {
                value: 'multiple',
                label: 'Multiple',
            },
        ],
        value: '',
        // getOptionValue: (option) => option.id,
        onChange: (val) => set_plan_type((prev) => ({ ...prev, value: val })),
    });
    const [contract_doc, set_contract_doc] = React.useState({
        label: 'Contract document',
        files: [],
        acceptedMimeTypes:
            '.pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        hintMessage: 'Only pdf and doc/docx files are accepted.',
        setFiles: (val) => set_contract_doc({ ...contract_doc, files: val }),
    });

    {
        /* SEO fields  */
    }
    const [slug, set_slug] = React.useState({
        label: 'Merchant link',
        prefix: '/',
        value: '',
        setValue: (val) => set_slug({ ...slug, value: val }),
    });
    const [page_title_ar, set_page_title_ar] = React.useState({
        label: 'Page title',
        value: '',
        setValue: (val) => set_page_title_ar({ ...page_title_ar, value: val }),
    });
    const [meta_tags_ar, set_meta_tags_ar] = React.useState([]);
    const [meta_description_ar, set_meta_description_ar] = React.useState({
        label: 'Meta description',
        value: '',
        setValue: (val) => set_meta_description_ar({ ...meta_description_ar, value: val }),
    });
    const [page_title_en, set_page_title_en] = React.useState({
        label: 'Page title',
        value: '',
        setValue: (val) => set_page_title_en({ ...page_title_en, value: val }),
    });
    const [meta_tags_en, set_meta_tags_en] = React.useState([]);
    const [meta_description_en, set_meta_description_en] = React.useState({
        label: 'Meta description',
        value: '',
        setValue: (val) => set_meta_description_en({ ...meta_description_en, value: val }),
    });
    // const [document_id, set_document_id] = React.useState({
    //     label: 'Free work document ID',
    //     value: '',
    //     setValue: (val) => set_document_id({ ...document_id, value: val }),
    // });
    const [delivery_fees_calculation_method, set_delivery_fees_calculation_method] =
        React.useState('flat_fees');
    const [vendor_cart_delivery_fees_configurations, set_vendor_cart_delivery_fees_configurations] =
        React.useState([]);

    const [is_horeca, set_is_horeca] = React.useState({
        label: (
            <>
                <span>HORECA Merchant</span>
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip id="tooltip-right" width="300px">
                            HORECA is an acronym for HOtel, REstaurant, CAfé. If you enable this
                            flag for this merchant, Purchase orders will be created on every child
                            order once it is confirmed and paid or pay later
                        </Tooltip>
                    }
                >
                    <img
                        src={helpIcon}
                        alt="i"
                        width={20}
                        style={{ marginInlineStart: 5, marginBottom: 3 }}
                    />
                </OverlayTrigger>
            </>
        ),
        checked: false,
        onChange: (checked) => set_is_horeca((prev) => ({ ...prev, checked })),
    });

    const [zatca_type, set_zatca_type] = React.useState({
        label: 'Zatca Identification Type',
        options: ZATCA_IDENTIFICATION_TYPES,
        value: '',
        getOptionValue: (option) => option.value,
        onChange: (val) => set_zatca_type((prev) => ({ ...prev, value: val })),
    });

    const [zatca_id, set_zatca_id] = React.useState({
        label: 'Zatca Identification ID',
        value: '',
        setValue: (val) => set_zatca_id({ ...zatca_id, value: val }),
    });

    const [errors, setErrors] = React.useState([]);
    const [validation_errors, set_validation_errors] = React.useState({});
    const validateIBAN = (iban) => {
        iban = iban.replace(/\s/g, '').toUpperCase();
        if (iban.length < 4 || iban.length > 34) {
            return false;
        }
        iban = iban.substring(4) + iban.substring(0, 4);
        let numericIBAN = '';
        for (let i = 0; i < iban.length; i++) {
            const char = iban.charAt(i);
            if (char >= 'A' && char <= 'Z') {
                numericIBAN += (char.charCodeAt(0) - 55).toString();
            } else {
                numericIBAN += char;
            }
        }
        let remainder = '';
        for (let i = 0; i < numericIBAN.length; i++) {
            const currentChar = numericIBAN.charAt(i);
            remainder = (remainder + currentChar) % 97;
        }

        return remainder === 1;
    };

    const validate = () => {
        const newErrors = {};
        if (!legal_company_name_ar.value)
            newErrors.legal_company_name_ar = 'The Legal Company Name AR field is required.';
        if (!legal_company_name_en.value)
            newErrors.legal_company_name_en = 'The Legal Company Name EN field is required.';
        if (!company_name_ar.value)
            newErrors.company_name_ar = 'The Company Name AR field is required.';
        if (!company_name_en.value)
            newErrors.company_name_en = 'The Company Name EN field is required.';
        if (!account_number.value)
            newErrors.account_number = 'The Bank Account Number field is required.';
        if (
            !holder_name.value ||
            holder_name.value.length < 6 ||
            !/^\s*\S+\s+\S+/.test(holder_name.value)
        )
            newErrors.holder_name =
                'The Holder Name field is required, must be at least 6 characters long, and must contain at least a first and last name.';
        if (!iban.value) {
            newErrors.iban = 'The Iban field is required.';
        } else if (!validateIBAN(iban.value)) {
            newErrors.iban = 'Invalid IBAN format.';
        }

        if (!first_mile.value) newErrors.first_mile = 'The First Mile field is required.';
        if (!second_mile.value) newErrors.second_mile = 'The Second Mile field is required.';
        if (!last_mile.value) newErrors.last_mile = 'The Last Mile field is required.';
        if (!plan_type.value) newErrors.plan_type = 'The Plan Type field is required.';
        if (!plan.value) newErrors.plan = 'The Plan field is required.';
        if (!mobile.value) newErrors.mobile = 'The Mobile field is required.';
        else if (!/^5[0-9]{1}[0-9]{7}$/i.test(mobile.value))
            newErrors.mobile =
                'Invalid phone number format.Please enter a valid Saudi mobile number.';
        if (!vat_account_number.value)
            newErrors.vat_account_number = 'The Vat Account Number field is required.';
        if (!zatca_type.value)
            newErrors.zatca_type = 'The Zatca Identification Type field is required.';
        if (!zatca_id.value) newErrors.zatca_id = 'The Zatca Identification ID field is required.';

        if (!commercial_registration_number.value) {
            newErrors.commercial_registration_number =
                'The Commercial Registration Number field is required.';
        } else if (commercial_registration_number.value.length !== 10) {
            newErrors.commercial_registration_number =
                'The Commercial Registration Number must be exactly 10 digits.';
        }

        if (!email.value) {
            newErrors.email = 'The Email field is required.';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value)) {
            newErrors.email = 'Invalid email address.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(email.value)) {
            newErrors.email = 'Email should not contain Arabic letters';
        }
        if (!bank_name.value) newErrors.bank_name = 'The Bank name field is required.';
        if (!assigned_admin_user || !assigned_admin_user.id) {
            newErrors.assigned_admin_user = 'You must select a Merchant Account Manager.';
        }
        if (facebook_url.value && /[\u0600-\u06FF\u0750-\u077F]/.test(facebook_url.value)) {
            newErrors.facebook_url = 'Facebook URL should not contain Arabic letters.';
        }
        if (linkedin_url.value && /[\u0600-\u06FF\u0750-\u077F]/.test(linkedin_url.value)) {
            newErrors.linkedin_url = 'LinkedIn URL  should not contain Arabic letters.';
        }
        if (twitter_url.value && /[\u0600-\u06FF\u0750-\u077F]/.test(twitter_url.value)) {
            newErrors.twitter_url = 'Twitter URL should not contain Arabic letters.';
        }
        if (instagram_url.value && /[\u0600-\u06FF\u0750-\u077F]/.test(instagram_url.value)) {
            newErrors.instagram_url = 'Instagram URL  should not contain Arabic letters.';
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Validate on value change
    React.useEffect(() => {
        validate();
    }, [
        assigned_admin_user,
        email,
        plan,
        plan_type,
        first_mile,
        last_mile,
        company_name_ar,
        company_name_en,
        legal_company_name_ar,
        legal_company_name_en,
        zatca_type,
        iban,
        account_number,
        holder_name,
        second_mile,
        mobile,
        commercial_registration_number,
        vat_account_number,
        bank_name,
        zatca_id,
        facebook_url,
        twitter_url,linkedin_url,instagram_url
    ]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        set_loading(true);
        if (validate()) {
            const data = serialize(
                {
                    delivery_options: delivery_options?.length ? delivery_options : null,
                    ...(!!commercial_registration_number.value && {
                        commercial_registration_number: commercial_registration_number.value,
                    }),
                    country_id: country.value?.id || null,
                    city_id: city.value?.id || null,
                    legal_company_name_en: legal_company_name_en.value,
                    legal_company_name_ar: legal_company_name_ar.value,
                    company_name_en: company_name_en.value,
                    company_name_ar: company_name_ar.value,
                    mobile: mobile.value,
                    email: email.value,
                    status: status.value ? '1' : '0',
                    plan_id: plan.value?.id || null,
                    cbv_take_rate: cbv_take_rate.value?.id || null,
                    vendor_cbv_take_rate: vendor_cbv_take_rate.value?.id || null,
                    first_mile: first_mile?.value?.id || null,
                    second_mile: second_mile?.value?.id || null,
                    last_mile: last_mile?.value?.id || null,
                    invoice_issuance_type: invoice_issuance_type,
                    plan_type: plan_type.value?.value || null,
                    shipping_type: shipping_type.value?.value || null,
                    // account_manager_id: account_manager.value?.id || null,
                    assigned_admin_user_id: assigned_admin_user?.id || null,
                    shipping_method_id: shipping_method.value?.id || null,
                    minimum_order_amount: vendor_min_amount.value,
                    description_en: description_en.value,
                    description_ar: description_ar.value,
                    establishment_ar: establishment_ar.value,
                    establishment_en: establishment_en.value,
                    story_ar: story_ar.value,
                    story_en: story_en.value,
                    is_taxable: tax.checked ? '1' : '0',
                    uses_vendor_app: uses_vendor_app.checked ? '1' : '0',
                    is_vendor_cbv_enabled: is_vendor_cbv_enabled.checked ? '1' : '0',
                    is_crm_feature_enabled: is_crm_feature_enabled.checked ? '1' : '0',
                    vendor_type_id: vendor_type_id?.value?.id || null,
                    state: state?.value?.value || null,
                    ...(!!vat_account_number.value && {
                        vat_account_number: vat_account_number.value,
                    }),
                    instagram_url: instagram_url.value,
                    facebook_url: facebook_url.value,
                    linkedin_url: linkedin_url.value,
                    twitter_url: twitter_url.value,

                    seo_link: slug.value,
                    seo_page_title_en: page_title_en.value,
                    seo_meta_keywords_en: meta_tags_en?.map((t) => t.label).join(','),
                    seo_meta_description_en: meta_description_en.value,
                    seo_page_title_ar: page_title_ar.value,
                    seo_meta_keywords_ar: meta_tags_ar?.map((t) => t.label).join(','),
                    seo_meta_description_ar: meta_description_ar.value,
                    // ...(!!document_id.value && {
                    //     free_work_document_number: document_id.value,
                    // }),
                    visibility_priority: visibility_priority.value,
                    ...(invoice_issuance_type === 'invoicing_3' && invoicing_3_data),
                    ...((invoice_issuance_type === 'invoicing_3' ||
                        invoice_issuance_type === 'purchase_order') && {
                        payout_option_id: payout_option_id?.value?.id || null,
                    }),

                    delivery_fees_calculation_method,
                    ...(!!zatca_type?.value?.value && {
                        zatca_identification_type: zatca_type?.value?.value,
                    }),
                    ...(!!zatca_id?.value && {
                        zatca_identification_id: zatca_id?.value,
                    }),
                },
                { booleansAsIntegers: true, nullsAsUndefineds: true },
            );

            if (vendor_categories.value.length) {
                vendor_categories.value.forEach((c, i) => {
                    data.append('categories[' + i + ']', c.id);
                });
            }

            if (is_all_entity_types_selected === 'selected') {
                entity_types?.value.forEach((e, i) => {
                    data.append('personalized_entity_types[' + i + ']', e.id);
                });
            }

            if (invoice_issuance_type === 'purchase_order') {
                if (is_horeca.checked) {
                    data.append('is_horeca', 1);
                } else {
                    let i = 0;
                    for (const key in days) {
                        if (days[key]) {
                            data.append(`days[${i}]`, days[key]);
                            i++;
                        }
                    }
                }
            }

            if (delivery_fees_calculation_method === 'cart_size') {
                vendor_cart_delivery_fees_configurations.forEach((cart, i) => {
                    Object.keys(cart).map((k) => {
                        data.append(
                            `vendor_cart_delivery_fees_configurations[${i}][${k}]`,
                            cart[k],
                        );
                    });
                });
            } else {
                data.append(
                    'order_delivery_fees_inside_riyadh',
                    order_delivery_fees_inside_riyadh.value,
                );
                data.append(
                    'order_delivery_fees_outside_riyadh',
                    order_delivery_fees_outside_riyadh.value,
                );
            }

            // appending bank details
            bank_name.value && data.append('bank_account[bank_id]', bank_name?.value?.id);
            holder_name.value && data.append('bank_account[holder_name]', holder_name.value);
            account_number.value &&
                data.append('bank_account[account_number]', account_number.value);
            iban.value && data.append('bank_account[iban]', iban.value);

            // categories: vendor_categories.value.length ? vendor_categories.value.map(c => c.id) : []

            // add files to form
            if (logo_ar.files.length) data.append('logo_ar', logo_ar.files[0]);
            if (logo_en.files.length) data.append('logo_en', logo_en.files[0]);
            if (banner_image_ar.files.length)
                data.append('banner_image_ar', banner_image_ar.files[0]);
            if (banner_image_en.files.length)
                data.append('banner_image_en', banner_image_en.files[0]);
            if (contract_doc.files.length) data.append('contract_document', contract_doc.files[0]);

            const res = await POST({
                endpoint: '/vendors',
                data,
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 200) {
                set_created(res?.data.id);
            }
        }
    };

    const Guideline = (
        <div className="vendor-story-instructions mx-5">
            <h2 className="text-center mt-4 mb-3 divider-title">Merchant Story Guideline</h2>
            <ol>
                <li className="my-4">
                    In the <span className="text-danger">Merchant description</span>, you should
                    focus on writing 2 sentences (max) that introduce the visitor to the merchant
                    and the menchants offerings.
                </li>
                <li className="my-4">
                    In the <span className="text-danger">Merchant establishment</span>, you should
                    focus on providing the date of establishment of the merchant. Maybe combine it
                    with a short 1 sentence to give more info to the shopper.
                </li>
                <li className="my-4">
                    In the <span className="text-danger">merchant story</span>, you should write an
                    anecdote that tells the shopper more about the merchant and convince him to buy
                    from this merchant.
                </li>
            </ol>
        </div>
    );
    if (created) {
        return <Redirect to={`/merchants/${created}`} />;
    }

    return (
        <div className="vendors-single-view">
            <CustomPrompt>
                <ViewHeader className={styles.header} title="Add new merchant" renderBackArrow>
                    <Sop id="29843" />
                    <Button type="submit" onClick={handleSubmit}>
                        Save
                    </Button>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card className="vendor-fields">
                    <div className="px-3 py-2">
                        {/* General fields */}
                        <div className="general-fields">
                            {/* Arabic fields */}
                            <h3 className="divider-title">Arabic generic content</h3>
                            <Row>
                                <Col lg={6}>
                                    <Input {...legal_company_name_ar} />
                                    {loading && validation_errors.legal_company_name_ar && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.legal_company_name_ar}
                                        </p>
                                    )}
                                    <Input {...company_name_ar} />
                                    {loading && validation_errors.company_name_ar && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.company_name_ar}
                                        </p>
                                    )}
                                    <Textarea {...description_ar} />
                                    <Textarea {...establishment_ar} />
                                    <Textarea {...story_ar} />
                                </Col>
                                <Col lg={6}>
                                    <FileUpload {...logo_ar} />
                                    <FileUpload {...banner_image_ar} />
                                    {Guideline}
                                </Col>
                            </Row>

                            {/* English fields  */}
                            <h3 className="divider-title">English generic content</h3>
                            <Row>
                                <Col lg={6}>
                                    <Input {...legal_company_name_en} />
                                    {loading && validation_errors.legal_company_name_en && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.legal_company_name_en}
                                        </p>
                                    )}
                                    <Input {...company_name_en} />
                                    {loading && validation_errors.company_name_en && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.company_name_en}
                                        </p>
                                    )}
                                    <Textarea {...description_en} />
                                    <Textarea {...establishment_en} />
                                    <Textarea {...story_en} />
                                </Col>
                                <Col lg={6}>
                                    <FileUpload {...logo_en} />
                                    <FileUpload {...banner_image_en} />
                                    {Guideline}
                                </Col>
                            </Row>

                            {/* Generic fields  */}
                            <h3 className="divider-title">Generic content</h3>
                            <Row>
                                <Col lg={6}>
                                    <Row>
                                        <Col lg={6}>
                                            <Select {...zatca_type} />
                                            {loading && validation_errors.zatca_type && (
                                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                    {validation_errors.zatca_type}
                                                </p>
                                            )}
                                        </Col>
                                        <Col>
                                            <Input {...zatca_id} />
                                            {loading && validation_errors.zatca_id && (
                                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                    {validation_errors.zatca_id}
                                                </p>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Input {...commercial_registration_number} />
                                            {loading &&
                                                validation_errors.commercial_registration_number && (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            margin: '-10px 0 10px',
                                                        }}
                                                    >
                                                        {
                                                            validation_errors.commercial_registration_number
                                                        }
                                                    </p>
                                                )}
                                        </Col>
                                        {/* <Col>
                                            <Input {...document_id} />
                                        </Col> */}
                                    </Row>
                                    <Select {...country} />
                                    <Select {...city} />
                                    <Select {...status} />
                                    <Input {...email} />
                                    {loading && validation_errors.email && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.email}
                                        </p>
                                    )}
                                    <Input {...mobile} />
                                    {loading && validation_errors.mobile && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.mobile}
                                        </p>
                                    )}
                                    <Select {...state} />

                                    <Checkbox {...tax} />
                                    <Input {...visibility_priority} />
                                    <Select {...vendor_type_id} />
                                    <Checkbox {...uses_vendor_app} />
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-right" width="300px">
                                                check this flag if the merchant is confirming his
                                                orders using Disty merchant app, Uncheck this flag
                                                if this merchant is not using our merchant app and
                                                we confirm the orders on his behalf
                                            </Tooltip>
                                        }
                                    >
                                        <img src={helpIcon} alt="i" />
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={6}>
                                    <Select {...shipping_method} />
                                    {/* <Select {...account_manager} /> */}
                                    <ApiAsyncSelect
                                        label="Merchant account manager"
                                        api="/user"
                                        onlyFetchActiveRecords={false}
                                        placeholder="Type to Add Merchant account manager..."
                                        labelProperty={(r) => `${r.first_name} ${r.last_name}`}
                                        onChange={set_assigned_admin_user}
                                        value={assigned_admin_user}
                                    />
                                    {loading && validation_errors.assigned_admin_user && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.assigned_admin_user}
                                        </p>
                                    )}

                                    <Input {...vendor_min_amount} />
                                    <Select {...vendor_categories} />

                                    <Input {...vat_account_number} />
                                    {loading && validation_errors.vat_account_number && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.vat_account_number}
                                        </p>
                                    )}
                                    <Input {...instagram_url} />
                                    {loading && validation_errors.instagram_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.instagram_url}
                                        </p>
                                    )}
                                    <Input {...facebook_url} />
                                    {loading && validation_errors.facebook_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.facebook_url}
                                        </p>
                                    )}
                                    <Input {...linkedin_url} />
                                    {loading && validation_errors.linkedin_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.linkedin_url}
                                        </p>
                                    )}
                                    <Input {...twitter_url} />
                                    {loading && validation_errors.twitter_url && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.twitter_url}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        </div>

                        {/* entity types */}
                        <div className="entity-types">
                            <h3 className="divider-title">Entity types</h3>
                            <Row>
                                <Col lg={12}>
                                    <div className="my-3">
                                        <Radio
                                            onChange={set_is_all_entity_types_selected}
                                            option={is_all_entity_types_selected}
                                            label="All Entity"
                                            value="all"
                                            name="is-all-banner-entity-selected"
                                        />
                                        <Radio
                                            onChange={set_is_all_entity_types_selected}
                                            option={is_all_entity_types_selected}
                                            label="Select specific entity"
                                            value="selected"
                                            name="is-all-banner-entity-selected"
                                        />

                                        {is_all_entity_types_selected === 'selected' && (
                                            <>
                                                <Select {...entity_types} />
                                                <Button
                                                    onClick={() => {
                                                        set_select_all(!select_all);
                                                        select_all
                                                            ? entity_types.onChange([])
                                                            : entity_types.onChange(
                                                                  GlobalsSlice.entity_types,
                                                              );
                                                    }}
                                                    variant={select_all ? 'danger' : 'primary'}
                                                >
                                                    {select_all ? 'undo' : 'select all'}{' '}
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {/* Delivery Options */}
                        <div>
                            <h3 className="divider-title">Delivery Options</h3>
                            <ShippingFulfillmentSettingsField
                                initialOptions={delivery_options}
                                onChange={set_delivery_options}
                            />
                        </div>

                        <div>
                            <h3 className="divider-title">
                                Delivery Fees Charged to retailer/ child order (Without VAT)
                            </h3>
                            <div>
                                <div className="my-4 flex gap-10">
                                    <Radio
                                        name="delivery_fees_calculation_method"
                                        value="flat_fees"
                                        option={delivery_fees_calculation_method}
                                        label="Flat Fees"
                                        onChange={(val) =>
                                            set_delivery_fees_calculation_method(val)
                                        }
                                        labelStyle={{ color: '#000', fontSize: 16 }}
                                    />
                                    <Radio
                                        name="delivery_fees_calculation_method"
                                        value="cart_size"
                                        option={delivery_fees_calculation_method}
                                        label="Based On Cart Size"
                                        onChange={(val) =>
                                            set_delivery_fees_calculation_method(val)
                                        }
                                        labelStyle={{ color: '#000', fontSize: 16 }}
                                    />
                                </div>
                            </div>
                            {delivery_fees_calculation_method === 'flat_fees' && (
                                <div className="flex">
                                    <Input {...order_delivery_fees_inside_riyadh} />
                                    <Input {...order_delivery_fees_outside_riyadh} />
                                </div>
                            )}

                            {delivery_fees_calculation_method === 'cart_size' && (
                                <DeliveryCartSizes
                                    value={vendor_cart_delivery_fees_configurations}
                                    onChange={set_vendor_cart_delivery_fees_configurations}
                                />
                            )}
                        </div>

                        {/* invoice issuance type */}
                        <div className="invoice-types">
                            <h3 className="divider-title">Invoice issuance type</h3>
                            <Row>
                                <Col lg={12}>
                                    <div className="my-3">
                                        <Radio
                                            onChange={set_invoice_issuance_type}
                                            option={invoice_issuance_type}
                                            label="Purchase Order Invoiced"
                                            value="purchase_order"
                                            name="purchase_order_type"
                                        />
                                        <Radio
                                            onChange={set_invoice_issuance_type}
                                            option={invoice_issuance_type}
                                            label="Self Invoiced"
                                            value="self"
                                            name="self_invoices_type"
                                        />
                                        <Radio
                                            onChange={set_invoice_issuance_type}
                                            option={invoice_issuance_type}
                                            label="Marketplace invoicing 3.0"
                                            value="invoicing_3"
                                            name="invoicing_3"
                                        />
                                    </div>
                                </Col>
                                {invoice_issuance_type === 'purchase_order' && (
                                    <div>
                                        <Col sm={12}>
                                            <div style={{ margin: '20px 0' }}>
                                                <Checkbox {...is_horeca} />
                                            </div>
                                        </Col>
                                        {!is_horeca.checked && (
                                            <Col sm={12}>
                                                {DAYS.map((d, i) => (
                                                    <Checkbox
                                                        key={i}
                                                        label={d}
                                                        checked={!!days[i]}
                                                        disabled={is_horeca.checked}
                                                        onChange={(bool) => {
                                                            set_days((prev) => ({
                                                                ...prev,
                                                                [i]: bool ? 1 << i : null,
                                                            }));
                                                        }}
                                                    />
                                                ))}
                                            </Col>
                                        )}
                                    </div>
                                )}
                            </Row>
                            {invoice_issuance_type === 'invoicing_3' && (
                                <MarketplaceInvoicing
                                    initialValues={invoicing_3_data}
                                    onChange={(data) => set_invoicing_3_data(data)}
                                />
                            )}
                            {uses_vendor_app.checked &&
                                invoice_issuance_type === 'purchase_order' &&
                                !is_horeca.checked && (
                                    <div className="alert alert-warning">
                                        You must specify at least one invoice days to enable the
                                        system to create purchase orders for this vendor - Go to{' '}
                                        {'"Invoice Settings Tab"'} then select at least one day
                                    </div>
                                )}
                            {(invoice_issuance_type === 'invoicing_3' ||
                                invoice_issuance_type === 'purchase_order') && (
                                <Select {...payout_option_id} />
                            )}
                        </div>

                        {/* Agreement fields */}
                        <div className="agreement-fields">
                            <h3 className="divider-title">Agreement</h3>
                            <Row>
                                <Col lg={6}>
                                    <Select {...plan_type} />
                                    {loading && validation_errors.plan_type && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.plan_type}
                                        </p>
                                    )}
                                    <Select {...plan} />
                                    {loading && validation_errors.plan && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.plan}
                                        </p>
                                    )}
                                    <Select {...cbv_take_rate} />
                                    <Select {...vendor_cbv_take_rate} />
                                    <Select {...first_mile} />
                                    {loading && validation_errors.first_mile && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.first_mile}
                                        </p>
                                    )}
                                    <Select {...second_mile} />
                                    {loading && validation_errors.second_mile && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.second_mile}
                                        </p>
                                    )}
                                    <Select {...last_mile} />
                                    {loading && validation_errors.last_mile && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.last_mile}
                                        </p>
                                    )}
                                    <Select {...shipping_type} />
                                </Col>
                                <Col lg={6}>
                                    <FileUpload {...contract_doc} />
                                    <Checkbox {...is_vendor_cbv_enabled} />
                                    {/* <div className="alert alert-warning">
                                        Enabling this flag will give the vendor the ability to add
                                        Retailers and add orders right from the vendor app
                                    </div> */}
                                    <Checkbox
                                        {...is_crm_feature_enabled}
                                        disabled={is_vendor_cbv_enabled.checked}
                                    />
                                </Col>
                            </Row>
                        </div>

                        {/* Bank details fields  */}
                        <div className="bank-details-fields">
                            <h3 className="divider-title">Bank details</h3>
                            <Row>
                                <Col lg={6}>
                                    <Select {...bank_name} options={banks} />
                                    {loading && validation_errors.bank_name && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.bank_name}
                                        </p>
                                    )}
                                    <Input {...account_number} />
                                    {loading && validation_errors.account_number && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.account_number}
                                        </p>
                                    )}
                                </Col>
                                <Col lg={6}>
                                    <Input {...holder_name} />
                                    {loading && validation_errors.holder_name && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.holder_name}
                                        </p>
                                    )}
                                    <Input {...iban} />
                                    {loading && validation_errors.iban && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.iban}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        </div>

                        {/* SEO fields */}
                        <div className="seo-fields">
                            <h3 className="divider-title">SEO</h3>
                            <Slug {...slug} />
                            <h3 className="divider-title">Arabic SEO content</h3>
                            <Input {...page_title_ar} />
                            <CreatableTextSelect
                                label="Meta tags"
                                value={meta_tags_ar}
                                setValue={set_meta_tags_ar}
                            />
                            <Textarea {...meta_description_ar} />

                            <h3 className="divider-title">English SEO content</h3>
                            <Input {...page_title_en} />
                            <CreatableTextSelect
                                label="Meta tags"
                                value={meta_tags_en}
                                setValue={set_meta_tags_en}
                            />
                            <Textarea {...meta_description_en} />
                        </div>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
