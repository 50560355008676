import React from 'react';
import {
    ViewHeader,
    Link,
    Card,
    DataTable,
    WhatsApp,
    Dialer,
    Filters,
    Sop
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadVendorAcquisitionsData } from '@slices/merchantAcquisitions.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function VendorsAcquisitionsListView() {
    const VendorAcquisitionsSlice = useSelector((state) => state.VendorAcquisitionsSlice);
    const dispatch = useDispatch();

    const [filters, set_filters] = React.useState({});
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    useDidMountEffect(() => {
        dispatch(loadVendorAcquisitionsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);


    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
            center: true,
            cell: (row) => row?.id
        },
        {
            name: 'First name',
            selector: 'first_name',
            cell: (row) => row?.first_name
        },
        {
            name: 'Last name',
            selector: 'last_name',
            cell: (row) => row?.last_name
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />

        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '120px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />
        },
    ];
    return (
        <div className="vendor-acquisitions-list-view">
            <ViewHeader title="Acquisitions">
                <Sop id="29903" />
                <Link href="/acquisitions/add" className="btn btn-primary">
                    New Acquisition
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by ID, First name, Last name, Mobile"
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={VendorAcquisitionsSlice.vendorAcquisitions}
                    pages={VendorAcquisitionsSlice.vendorAcquisitions_pages}
                    page={page}
                    records={VendorAcquisitionsSlice.vendorAcquisitions_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={VendorAcquisitionsSlice.vendorAcquisitions_loading}
                />
            </Card>
        </div>
    );
}
