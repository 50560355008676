function WarehouseTable({
    cols = [],
    data = [],
    renderItem,
}) {
    return (
        <table>
            <thead>
                <tr>
                    <th />
                    {cols.map((col, i) => (
                        <th className="cell-label" key={i}>
                            {col}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {data.map((row, i) => (
                    <tr key={i}>
                        <th className="cell-label">{row?.lane}</th>
                        {row?.locations?.map((loc, i) =>  (renderItem(loc, i)))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default WarehouseTable;