// ? note: the changes here are just to show the usage of new hooks
// ? and how we avoid the code repetition pls check it
// ? if it is fine we can use them in another pages
import React from 'react';
import { DataTable, Link, Button, Filters } from '@components';
import { formatDate, formatMoney, downloadCSV, formatStringWithUnderscores } from '@utils';
import walletDebit from '@assets/icons/wallet-debit.svg';
import walletCredit from '@assets/icons/wallet-credit.svg';
import { useSelector } from 'react-redux';
import useFetch from '@hooks/useFetch';
import usePaginationList from '@hooks/usePaginationList';

export default function WalletTransactionsTab({ id }) {
    const [page, set_page] = React.useState(1);
    const CustomersSlice = useSelector((state) => state.CustomersSlice);
    const [filters, set_filters] = React.useState({});

    const { state, fetch } = useFetch({
        endpoint: `/payment/customers/${id}/wallet/transactions/export`,
    });

    const { dataList, pages, isLoading, records } = usePaginationList({
        endpoint: `/payment/customers/${id}/wallet/transactions`,
        params: { page, ...filters },
        deps: [page, filters, CustomersSlice?.customer_balance?.balance],
    });

    const fetchCSVData = () => {
        fetch(
            { ...filters },
            {
                onSuccess: (res) => {
                    downloadCSV(`customer-${id}-transactions`, res, true);
                },
            },
        );
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            width: '50px',
            cell: (row) => (
                <>
                    <img
                        src={row.type === 'debit' ? walletDebit : walletCredit}
                        style={{
                            width: 30,
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    />
                </>
            ),
        },
        {
            name: 'State',
            selector: 'state',
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.amount)}</div>
                </div>
            ),
        },
        {
            name: 'Order number',
            selector: 'order_id',
            cell: (row) => <Link href={`/orders/${row?.order_id}`}>{row?.order_id}</Link>,
        },
        {
            name: 'Suborder ID',
            cell: (row) => (
                <Link
                    // eslint-disable-next-line max-len
                    href={`/orders/${row?.suborder_pretty_id?.split('-')[0]}/${
                        row?.suborder_pretty_id
                    }`}
                >
                    {row?.suborder_pretty_id}
                </Link>
            ),
        },
        {
            name: 'Reasons',
            width: '200px',
            selector: 'refund_reason',
            cell: (row) => row?.refund_reason,
        },
        {
            name: 'Deduct from',
            selector: 'deduct_from',
        },
        {
            name: 'Reason type',
            selector: 'reason_type',
            cell: (row) => formatStringWithUnderscores(row?.reason_type),
        },
        {
            name: 'Description',
            width: '200px',
            selector: 'description',
            cell: (row) => row?.description,
        },
        {
            name: 'Created at',
            selector: 'created_at',
            sortable: true,
            cell: (row) => formatDate(row.created_at),
        },
    ];

    return (
        <div>
            <div
                className="flex__jc-between__ai-center p-3"
                style={{ backgroundColor: 'aliceblue' }}
            >
                {isLoading ? 'loading...' : `${records?.total} transactions`}
                <Button
                    className="ml-3"
                    variant="info"
                    disabled={isLoading || state.isLoading}
                    onClick={() => fetchCSVData()}
                >
                    Export CSV
                </Button>
            </div>

            <Filters
                filters={['deducted_from', 'amount_type', 'refund_reason']}
                onFilterChange={(filters_values) => {
                    set_filters(filters_values);
                }}
            />

            <DataTable
                data={dataList}
                pages={pages}
                page={page}
                handlePageChange={set_page}
                columns={columns}
                searchable={false}
                loading={isLoading}
            />
        </div>
    );
}
