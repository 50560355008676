import { useEffect, useState } from 'react';
import style from './SelectVariantOrBase.module.scss';
import editIcon from '@assets/icons/edit.svg';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SelectVariantOrBase = ({ selectionType = 'variantOnly', children }) => {
    const initialVariant = selectionType === 'both' ? 'base' : undefined;
    const [selected, setSelected] = useState(initialVariant);
    const product = useSelector((state) => state.ProductsSlice.product);
    const { pathname } = useLocation();
    const { push } = useHistory();

    const isBaseSelected = selected === 'base';

    if (typeof children !== 'function') {
        console.error(
            'Error: you should pass children of SelectVariantOrBase as a function instead of ',
            typeof children,
        );
        return null;
    }

    useEffect(() => {
        if (selectionType === 'variantOnly' && product.id && !selected) {
            setSelected(product.variants[0]?.id);
        }
    }, [selectionType, product, selected]);

    return (
        <div>
            <div className={style.wrapper}>
                {selectionType === 'both' && (
                    <button
                        onClick={() => setSelected(initialVariant)}
                        className={[
                            style.button,
                            selected === initialVariant ? style.buttonActive : undefined,
                        ].join(' ')}
                    >
                        Base Product
                    </button>
                )}
                {product?.variants?.map((variant, index) => {
                    return (
                        <button
                            onClick={() => setSelected(variant.id)}
                            className={[
                                style.button,
                                selected === variant.id ? style.buttonActive : undefined,
                            ].join(' ')}
                            key={index}
                        >
                            {variant.name_en} #{variant.id}
                        </button>
                    );
                })}
                <button
                    onClick={() => push(pathname + '?tab_index=10')}
                    className={[style.button, style.editIcon].join(' ')}
                >
                    <img src={editIcon} alt="Edit" width="16px" />
                </button>
            </div>
            {children({ isBaseSelected, selected: isBaseSelected ? product.id : selected })}
        </div>
    );
};

export default SelectVariantOrBase;
