import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from '@components';
import { default as DP } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function DateRangePicker({
    label = '',
    startDate = new Date(),
    setStartDate,
    endDate = new Date(),
    setEndDate,
    startDateLabel,
    endDateLabel,
    disabled = false,
    dateFormat = 'dd/MMM/yyyy',
    isClearable = false,
}) {
    return (
        <Form.Group controlId={label}>
            {label && (
                <Form.Label>{label}</Form.Label>
            )}
            <Row>
                <Col>
                    {startDateLabel && <Form.Label>{startDateLabel}</Form.Label>}
                    <DP
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat={dateFormat}
                        className="form-control"
                        placeholderText="From"
                        disabled={disabled}
                        isClearable={isClearable}
                    />
                </Col>
                <Col>
                    {endDateLabel && <Form.Label>{endDateLabel}</Form.Label>}
                    <DP
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat={dateFormat}
                        className="form-control"
                        placeholderText="To"
                        disabled={!startDate || disabled}
                        isClearable={isClearable}
                    />
                </Col>
            </Row>
        </Form.Group>
    );
}

DateRangePicker.propTypes = {
    label: PropTypes.string,
    startDate: PropTypes.instanceOf(Date).isRequired,
    setStartDate: PropTypes.func.isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    setEndDate: PropTypes.func.isRequired,
};
