import { useState, useCallback } from 'react';
import { ViewHeader, Button, Card, Tabs, Filters, DataTable } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadPayoutsData } from '@slices/payouts.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

import { PayPayout, BulkPayment, BulkPaymentWithCSV } from './Modals';
import { setModalStatus } from '@slices/ui.slice';
import COLUMNS from './columns';

const INIT_TABS = [
    { name: 'All', value: 'all' },
    { name: 'Pending', value: 'pending' },
    { name: 'Outstanding', value: 'outstanding' },
    { name: 'Partially Paid', value: 'partially_paid' },
    { name: 'Paid', value: 'paid' },
];

export const ACTIONABLE_TABS = ['all', 'outstanding', 'partially_paid'];

const VendorPayouts = () => {
    const [tabs, set_tabs] = useState(INIT_TABS);
    const [selected_rows, set_selected_rows] = useState([]);
    const [records_per_page, set_records_per_page] = useState(null);
    const [page, set_page] = useState(1);
    const [filters, set_filters] = useState({});
    const [clear_selected_rows_toggle, set_clear_selected_rows_toggle] = useState(false);

    const active_tab = tabs.find((t) => t.isActive)?.value || INIT_TABS[0].value;

    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();

    const fetchPayoutsData = async () => {
        handleClearRows();
        dispatch(
            loadPayoutsData({
                ...filters,
                page,
                records_per_page,
                ...(active_tab !== 'all' ? { states: active_tab } : {}),
            }),
        );
    };

    const handleClearRows = () => {
        set_selected_rows([]);
        set_clear_selected_rows_toggle(!clear_selected_rows_toggle);
    };

    const handleSelectedRows = (rows) => {
        const ids = rows.selectedRows.map((r) => r.id);
        set_selected_rows(ids);
    };

    const openModal = (modal) => {
        dispatch(
            setModalStatus({
                modal,
                status: true,
            }),
        );
    };

    const rowDisabledCriteria = useCallback((row) => !ACTIONABLE_TABS.includes(row.state.code), []);

    useDidMountEffect(() => {
        fetchPayoutsData();
    }, [filters, page, records_per_page, active_tab]);

    return (
        <div>
            <ViewHeader title="Merchant Payouts">
                <Button
                    disabled={!ACTIONABLE_TABS.includes(active_tab) || !selected_rows.length}
                    onClick={() => openModal('payouts_bulk_payment_modal')}
                >
                    Bulk Payment
                </Button>
                <Button onClick={() => openModal('payouts_bulk_payment_with_csv_modal')}>
                    Bulk Payment With CSV
                </Button>

                <BulkPayment payouts={selected_rows} onSuccess={fetchPayoutsData} />
                <BulkPaymentWithCSV />
            </ViewHeader>

            <Card>
                <Tabs tabs={tabs} onSelect={set_tabs} setPage={set_page} offsetBottom />

                <Filters
                    queryPlaceholder="search by :Payout #, Child order ID"
                    filters={['vendors', 'from', 'to']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    sort_options={[
                        {
                            label: 'Outstanding days (DESC)',
                            value: 'outstanding_days__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Outstanding days (ASC)',
                            value: 'outstanding_days__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Settlement period (DESC)',
                            value: 'to__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Settlement period (ASC)',
                            value: 'to__asc',
                            direction: 'asc',
                        },
                    ]}
                />

                <DataTable
                    data={PayoutsSlice.payouts}
                    pages={PayoutsSlice.payouts_pages}
                    columns={COLUMNS}
                    page={page}
                    records={PayoutsSlice.payouts_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    loading={PayoutsSlice.payouts_loading}
                    handleSelectedRows={handleSelectedRows}
                    rowDisabledCriteria={rowDisabledCriteria}
                    clearSelectedRows={clear_selected_rows_toggle}
                    selectableRows={ACTIONABLE_TABS.includes(active_tab)}
                />
            </Card>

            <PayPayout onSuccess={fetchPayoutsData} />
        </div>
    );
};

export default VendorPayouts;
