import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { StatusBlock, Button, Col, Row, Card } from '@components';
import { loadChildOrderData, loadChildOrderBoxesData } from '@slices/childOrders.slice';
import { useDispatch, useSelector } from 'react-redux';
import BoxesForm, { printBoxes } from './BoxesForm';
import logo from '@assets/img/logo.svg';
import { PATCH, DELETE } from '@utils/HTTP';
import { toast } from 'react-toastify';
import { setModalStatus } from '@slices/ui.slice';
import BoxesDetailStyled from './BoxesDetail.styled';
import barCode from '@assets/img/barcode.png';

function BoxesDetail() {
    const { push } = useHistory();
    const [editing, set_editing] = useState(false);
    const [removed_boxes, set_removed_boxes] = useState([]);
    const { childOrderId } = useParams();
    const dispatch = useDispatch();
    const ChildOrdersSlice = useSelector((state) => state.ChildOrdersSlice);
    const childOrder = ChildOrdersSlice.child_order || {};
    const child_order_boxes = ChildOrdersSlice.child_order_boxes || {};
    const filteredBoxes = child_order_boxes?.boxes?.
        filter((_, index) => removed_boxes.length <= index) || [];
    const totalBoxes = child_order_boxes.boxes?.length - removed_boxes.length;

    const addBoxesCount = (closeModal) => async (data) =>
        await PATCH({ endpoint: `/suborder/${childOrderId}/boxes`,
            data: { count: parseInt(data.count) + totalBoxes } }).then((res) => {
            if (res.status_code === 200) {
                toast.info('Child Order Boxes Updated Successfully');
                closeModal('child_order_boxes_count_modal');
                dispatch(loadChildOrderBoxesData(childOrderId));
                set_editing(false);
                set_removed_boxes([]);
            } else {
                toast.error(res.data?.message);
            }
        });


    const subBoxesCount = async () =>
        await PATCH({ endpoint: `/suborder/${childOrderId}/boxes`,
            data: { count: totalBoxes  } }).then((res) => {
            if (res.status_code === 200) {
                toast.info('Child Order Boxes Updated Successfully');
                dispatch(loadChildOrderBoxesData(childOrderId));
                set_editing(false);
                set_removed_boxes([]);
            } else {
                toast.error(res.data?.message);
            }
        });

    const removeSuborderBox = async () =>
        await DELETE({ endpoint: `/suborder/${childOrderId}/boxes` }).then((res) => {
            if (res.status_code === 200) {
                toast.info('Child Order Boxes Deleted Successfully');
                dispatch(loadChildOrderBoxesData(childOrderId));
                push('/child-orders/list');
                set_editing(false);
            } else {
                toast.error(res.data?.message);
            }
        });

    useEffect(() => {
        dispatch(loadChildOrderData(childOrderId));
        dispatch(loadChildOrderBoxesData(childOrderId));
    }, [childOrderId]);

    if (!Object.keys(childOrder).length) return <div>Loading...</div>;

    return (
        <BoxesDetailStyled>
            <div className="boxes-header">
                <div className="state">
                    <h1>Child Order #{childOrder.pretty_id}</h1>
                    <StatusBlock state={childOrder.state} />
                </div>
                <div className="boxes-actions">
                    {!!child_order_boxes.actions?.length && (
                        <>
                            {!editing ? <Button onClick={() => set_editing(true)}>Edit</Button>:
                                <>
                                    <Button onClick={subBoxesCount}>Save</Button>
                                    <Button variant="light"
                                        onClick={() => {
                                            set_editing(false);
                                            set_removed_boxes([]);
                                        }}>Cancel</Button>
                                </>}
                            <Button onClick={removeSuborderBox} >Remove all</Button>
                        </>
                    )}
                    <Button onClick={() => printBoxes(childOrderId)}>Print</Button>
                </div>
            </div>
            <Row md={1} lg={2} xl={3}>
                {filteredBoxes
                    .map(({ id, code }, index) => (
                        <Col key={id}>
                            <Card className="boxes-card">
                                <div
                                    className="card-header"
                                >
                                    <div className="logo">
                                        <img src={logo}/>
                                        <span></span>
                                        <div >{child_order_boxes?.fulfillment}</div>
                                    </div>
                                    <div>Box {index + 1} Of {child_order_boxes?.boxes_count}</div>
                                </div>
                                <div className="card-body">
                                    <div className='delivery-info'>
                                        <div>
                                            <h3>SHIP FROM</h3>
                                            <p>{childOrder.vendor?.company_name}</p>
                                        </div>
                                        <div>
                                            <h3>SHIP TO</h3>
                                            <p>{childOrder.customer?.name}</p>
                                        </div>
                                    </div>
                                    <img src={barCode} alt="barcode" className="barcode-img" />
                                    <div className="box-code">{code}</div>
                                    <div className="box-shipping" >
                                        {child_order_boxes?.shipping} {}
                                    </div>
                                </div>
                                <div className="card-bottom">
                                    <div className="box-order-status">
                                        <StatusBlock state={childOrder.state} />
                                    </div>
                                    {editing && (
                                        <Button
                                            onClick={() =>
                                                set_removed_boxes(state => [...state, index])
                                            }
                                        >
                                            Remove
                                        </Button>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    ))}
                {editing && (
                    <Col>
                        <Card
                            role="button"
                            tabIndex={0}
                            className="barcode-edit"
                            onClick={() => {
                                dispatch(
                                    setModalStatus({
                                        modal: 'child_order_boxes_count_modal',
                                        status: true
                                    })
                                );
                            }}
                        >
                            <p>
                                +
                            </p>
                            Add Card
                        </Card>
                    </Col>
                )}
            </Row>
            <BoxesForm
                onSubmit={addBoxesCount}
            />
        </BoxesDetailStyled>
    );
}

export default BoxesDetail;