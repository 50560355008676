import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const VirtualIbansSlice = createSlice({
    name: 'virtualIban',
    initialState: {
        virtualIbans_list: [],
        virtualIbans_pages: null,
        virtualIbans_records: null,
        virtualIbans_loading: true,
        virtualIban_transactions: [],
        virtualIban_transactions_pages: null,
        virtualIban_transactions_records: null,
        virtualIban_transactions_loading: true,
    },
    reducers: {
        setVirtualIbansList(state, { payload }) {
            state.virtualIbans_list = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVirtualIbansPages(state, { payload }) {
            state.virtualIbans_pages = payload;
        },
        setVirtualIbansRecords(state, { payload }) {
            state.virtualIbans_records = payload;
        },
        setVirtualIbanTransactions(state, { payload }) {
            state.virtualIban_transactions = payload;
        },
        setVirtualIbanTransactionsPages(state, { payload }) {
            state.virtualIban_transactions_pages = payload;
        },
        setVirtualIbanTransactionsRecords(state, { payload }) {
            state.virtualIban_transactions_records = payload;
        },
    },
});

// export actions
export const {
    setVirtualIbansList,
    setLoading,
    setVirtualIbansPages,
    setVirtualIbansRecords,
    setVirtualIbanTransactions,
    setVirtualIbanTransactionsPages,
    setVirtualIbanTransactionsRecords,
} = VirtualIbansSlice.actions;

export const loadVirtualIbansListData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'virtualIbans_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/payment/virtual-ibans?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setVirtualIbansList(res?.data || []));
    if(res?.meta){
        dispatch(setVirtualIbansPages(res?.meta?.last_page || 0));
        dispatch(setVirtualIbansRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'virtualIbans_loading',
            value: false,
        })
    );
};

export const loadVirtualIbansTransactionsData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'virtualIban_transactions_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/payment/virtual-ibans/${id}/transactions`,
    });
    dispatch(setVirtualIbanTransactions(res?.data || []));
    if(res?.meta){
        dispatch(setVirtualIbanTransactionsPages(res?.meta?.last_page || 0));
        dispatch(setVirtualIbanTransactionsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'virtualIban_transactions_loading',
            value: false,
        })
    );
};

export default VirtualIbansSlice.reducer;
