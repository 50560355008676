import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const DiscountsSlice = createSlice({
    name: 'discounts',
    initialState: {
        discounts: [],
        discounts_pages: null,
        discounts_records: null,
        discount: {},
        discounts_loading: true,
        discount_loading: true,
    },
    reducers: {
        setDiscounts(state, { payload }) {
            state.discounts = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.discounts.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.discounts[index] = {
                    ...state.discounts[index],
                    ...payload,
                };
            }
        },
        setDiscount(state, { payload }) {
            state.discount = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setDiscountsTotalPages(state, { payload }) {
            state.discounts_pages = payload;
        },
        setDiscountsRecords(state, { payload }) {
            state.discounts_records = payload;
        },
    },
});

// export actions
export const {
    setDiscount,
    updateSingleRow,
    setDiscounts,
    setLoading,
    setDiscountsTotalPages,
    setDiscountsRecords,
} = DiscountsSlice.actions;

export const loadDiscountsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'discounts_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/marketing/discount?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setDiscounts(res?.data || []));
    if(res?.meta){
        dispatch(setDiscountsTotalPages(res?.meta?.last_page || 0));
        dispatch(setDiscountsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'discounts_loading',
            value: false,
        })
    );
};

export const loadDiscountData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'discount_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/marketing/discount/${id}`,
    });
    dispatch(setDiscount(res?.data || {}));
    dispatch(
        setLoading({
            key: 'discount_loading',
            value: false,
        })
    );
};

export default DiscountsSlice.reducer;
