import { useState } from 'react';
import { Modal, FileUpload, Checkbox, Input } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { changePayout, resetChangePayout } from '@slices/payouts.slice';

const PayPayout = ({ onSuccess }) => {
    const [payout, set_payout] = useState();
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();
    const errors = PayoutsSlice.change_payout_errors;

    const [is_full_payment, set_is_full_payment] = useState({
        label: 'Full Payment',
        checked: true,
        onChange: (val) =>
            set_is_full_payment({
                ...is_full_payment,
                checked: val,
            }),
    });

    const [amount, set_amount] = useState({
        label: 'Payment amount',
        value: '',
        setValue: (val) => set_amount({ ...amount, value: val.replace(/[^\d.-]+/g, '') }),
    });

    const [transfer_receipt, set_transfer_receipt] = useState({
        label: 'Transfer receipt (optional)',
        files: [],
        allowPDF: true,
        setFiles: (val) => set_transfer_receipt({ ...transfer_receipt, files: val }),
    });

    const onSubmit = (closeModal) => {
        dispatch(
            changePayout({
                id: payout.id,
                is_full_payment: is_full_payment.checked ? 1 : 0,
                amount: amount.value,
                receipts: transfer_receipt.files,
            }),
        ).then((isSuccess) => {
            if (isSuccess) {
                closeModal();
                onSuccess();
            }
        });
    };

    const reset = () => {
        transfer_receipt.setFiles([]);
        amount.setValue('');
        is_full_payment.onChange(true);
        dispatch(resetChangePayout());
    };

    return (
        <Modal
            title="Change Payout to Paid/Partially Paid"
            name="pay_payout_modal"
            onSubmit={onSubmit}
            loading={PayoutsSlice.change_payout_loading}
            onClose={reset}
            setPayload={set_payout}
        >
            <div>
                <Checkbox {...is_full_payment} />
            </div>

            {!is_full_payment.checked && <Input {...amount} />}

            <b>Payment Proof</b>
            <hr />
            <FileUpload {...transfer_receipt} />

            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default PayPayout;
