import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ViewHeader,
    Link,
    DataTable,
    Card,
    WhatsApp,
    Dialer,
    Filters,
    Sop
} from '@components';
import { formatDate } from '@utils';
import defaultLogo from '@assets/img/default.jpg';
import { loadVendorsData } from '@slices/merchant.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function VendorsListView() {

    const VendorsSlice = useSelector((state) => state.VendorsSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({
        status: null,
        vendor_types: [],
        account_manager: null,
        sort_by: null,
        sort_direction: null
    });

    useDidMountEffect(() => {
        dispatch(loadVendorsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    // TODO: missing filters
    // const orderByOptions = [
    //     { id: 'id', label: 'ID' },
    //     { id: 'email', label: 'Email' },
    //     { id: 'mobile', label: 'Mobile' },
    //     { id: 'status', label: 'Status' },
    //     { id: 'created_at', label: 'Created at' },
    //     { id: 'created_at', label: 'Registration date' },
    //     { id: 'visibility_priority', label: 'Visibility Priority' },
    //     { id: 'visibility_priority', label: 'Visibility Priority' },
    //     { id: 'followers_count', label: 'Followers count' },
    //     { id: 'products_count', label: 'Products count' },
    // ];

    // const sortDirectionOptions = [
    //     { id: 'asc', label: 'ASC' },
    //     { id: 'desc', label: 'DESC' },
    // ];

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            center: true,
            width: '100px'
        },
        {
            name: 'Logo',
            selector: 'logo',
            center: true,
            width: '100px',
            cell: (row) => (
                <Link href={`/merchants/${row.id}`} className="tr-logo">
                    <img src={row.logo_ar || defaultLogo} alt="Logo" />
                </Link>
            )
        },
        {
            name: 'Name',
            selector: 'name',
            cell: (row) => (
                <Link href={`/merchants/${row.id}`} className="tr-name">
                    <h5>
                        {row.company_name_ar}
                        <small>{row.company_name_en}</small>
                    </h5>
                </Link>
            )
        },
        {
            name: 'Products',
            cell: (row) => (
                <Link to={`/merchants/${row.id}?tab_index=2`}>{row.products_count}</Link>
            )
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />

        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />
        },
        {
            name: 'Email',
            selector: 'email',
            minWidth: '180px',
            cell: (row) => <a href={`mailto:${row?.email}`}>
                {row?.email}</a>
        },
        {
            name: 'Merchant Type',
            selector: 'vendor_type',
            cell: (row) => row?.vendor_type?.name_en
        },
        {
            name: 'Registered',
            selector: 'created_date',
            minWidth: '150px',
            format: (r) => formatDate(r.created_date),
        },
        {
            name: 'Verification state',
            selector: 'state',
        },
        {
            name: 'visibility priority',
            selector: 'visibility_priority',
        },
        {
            name: 'Followers',
            selector: 'followers_count',
            sortable: false,
            cell: (row) => (<Link href={`/merchants/${row.id}?tab_index=7`} className="tr-name">
                {row?.followers_count}
            </Link>)
        },
    ];

    return (
        <div className="vendors-list-view">
            <ViewHeader title="Merchants">
                <Sop id="29843" />
                <Link href="/merchants/add" className="btn btn-primary">
                    New merchant
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by ID, Name, Mobile, E-mail"
                    filters={[
                        'status',
                        'vendor_states',
                        'vendor_types',
                        'account_managers',
                        'followers_count'
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'created_at',
                        sort_direction: 'desc'
                    }}
                    sort_options={[
                        { label: 'Order ID', value: 'id' },
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Visibility priority (DESC)',
                            value: 'visibility_priority__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Visibility priority (ASC)',
                            value: 'visibility_priority__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Followers count (DESC)',
                            value: 'followers_count__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Followers count (ASC)',
                            value: 'followers_count__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Products count (DESC)',
                            value: 'products_count__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Products count (ASC)',
                            value: 'products_count__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={VendorsSlice.vendors}
                    pages={VendorsSlice.vendors_pages}
                    page={page}
                    records={VendorsSlice.vendors_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    loading={VendorsSlice.vendors_loading}
                />
            </Card>
        </div>
    );
}
