import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Form } from '@components';
import { optimizeImage } from '@utils';

export default function FileUpload({
    label = '',
    files = [],
    setFiles,
    maxFiles = 100,
    errorMsg = 'This field is required',
    required = true,
    allowImageOptimization = false,
    allowPDF = false,
    acceptedMimeTypes = 'image/jpeg, image/png',
    hintMessage = 'Only *.jpeg and *.png images will be accepted',
    validator = () => null,
    name,
}) {
    const allowVideoOnly = acceptedMimeTypes.includes('video');

    const [valid] = React.useState(null);
    // for keeping previous cases unchanged
    const accept = allowPDF ? 'application/pdf, image/jpeg, image/png' : acceptedMimeTypes;
    const hint = allowPDF ? 'Only *.jpeg, *.png and pdf files will be accepted' : hintMessage;
    const isMultiple = maxFiles > 1;

    const onDrop = React.useCallback(
        async (acceptedFiles) => {
            let uploadedFiles = acceptedFiles;

            if (allowImageOptimization) {
                // Process each file
                const filePromises = acceptedFiles.map(async (acceptedFile) => {
                    // on reader load something...
                    return await optimizeImage(acceptedFile);
                });

                uploadedFiles = await Promise.all(filePromises);
            }
            const availableFiles = isMultiple ? [...files, ...uploadedFiles] : uploadedFiles;

            setFiles(
                availableFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
                name,
            );
        },
        [files],
    );

    // React.useEffect(
    //     () => () => {
    //         // Make sure to revoke the data uris to avoid memory leaks
    //         files.forEach((file) => URL.revokeObjectURL(file.preview));
    //     },
    //     [files]
    // );

    // review fix
    React.useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        return () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    const customFileGetter = async (event) => {
        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        const promises = [];

        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const promise = new Promise((resolve) => {
                const video = document.createElement('video');
                video.preload = 'metadata';

                const fileExtension = file.name.split('.').reverse()[0];
                const fileAcceptable = accept.includes(fileExtension);

                if (!fileAcceptable) {
                    resolve(file);
                }

                let url;
                video.onloadedmetadata = () => {
                    window.URL.revokeObjectURL(video.src);
                    Object.assign(file, {
                        duration: video.duration,
                    });
                    resolve(file);
                };
                url = URL.createObjectURL(file);
                video.src = url;
            });
            promises.push(promise);
        }
        return await Promise.all(promises);
    };

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop,
        validator,
        maxFiles,
        accept,
        multiple: maxFiles > 1,
        ...(allowVideoOnly && { getFilesFromEvent: customFileGetter }),
    });

    const removeFile = (file) => () => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        if (newFiles.length) {
            setFiles(newFiles, name);
        } else {
            setFiles([], name);
        }
    };
    const filesItems = files.map((file) => (
        <div
            className="file-preview"
            key={file.path || file.name}
            onClick={(e) => e.stopPropagation()}
        >
            {/* {file.path} - {file.size} bytes{' '} */}
            {allowVideoOnly ? (
                <>
                    <video controls width={'200px'} height={'150px'}>
                        <source src={file.preview}></source>
                    </video>
                    <div className="file-name">{file.path || file.name}</div>
                </>
            ) : (
                <>
                    <img src={file.preview} />
                    <div>{file.path}</div>
                </>
            )}
            <button className="btn btn-danger" onClick={removeFile(file)}>
                x
            </button>
        </div>
    ));
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div key={file.name}>
            {errors.map((err) => (
                <p key={err.code}>{err.message}</p>
            ))}
        </div>
    ));
    return (
        <Form.Group controlId={label || ''}>
            {label && <Form.Label>{label}</Form.Label>}
            <div {...getRootProps({ className: 'custom-dropzone' })}>
                <input {...getInputProps()} required={required} />
                {filesItems.length ? (
                    filesItems
                ) : (
                    <div className="text-center">
                        <p>Drag drop some files here, or click to select files</p>
                        <em>({hint})</em>
                        <div className="mt-4 c-danger">{fileRejectionItems}</div>
                    </div>
                )}
            </div>
            {valid === false && <Form.Text className="text-danger">{errorMsg}</Form.Text>}
        </Form.Group>
    );
}

FileUpload.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Allow string or JSX node
    files: PropTypes.array.isRequired,
    setFiles: PropTypes.func.isRequired,
    allowImageOptimization: PropTypes.bool,
};
