import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@components';

export default function Card({
    style,
    title,
    children,
    className,
    headerBtn,
    headerContent,
    onHeaderBtnClick,
    ...rest
}) {
    return (
        <div {...rest} style={style} className={`card ${className ? className : ''}`}>
            {title && (
                <div className="card__title">
                    <h4>{title}</h4>
                    {headerContent}
                    {headerBtn && (
                        <Button variant="link" onClick={onHeaderBtnClick}>
                            {headerBtn}
                        </Button>
                    )}
                </div>
            )}
            {children}
        </div>
    );
}

Card.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    headerBtn: PropTypes.string,
    onHeaderBtnClick: PropTypes.func,
};
