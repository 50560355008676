import React from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { GET } from '@utils';
import { Form } from '@components';
import { stringify } from 'query-string';

export default function ApiAsyncSelect({
    api,
    label = '',
    labelProperty,
    // labelComponent = null,
    placeholder = 'Type to search...',
    value = null,
    onChange,
    isMulti = false,
    params = {},
    onlyFetchActiveRecords = true,
    allowClear,
    name,
    isDisabled,
}) {
    const loadOptions = async (q) => {
        const res = await GET({
            endpoint:
				api +
				'?' +
				stringify({
				    q,
				    ...params,
				    ...(onlyFetchActiveRecords
				        ? {
				            status: 1,
						  }
				        : {}),
				}),
        });
        return res?.data.map((v) => {
            v.value = v.id;
            if (labelProperty instanceof Function) {
                v.label = labelProperty(v);
            } else {
                v.label = v[labelProperty];
            }
            return v;
        });
    };

    const CustomOption = ({ innerProps, isDisabled, ...props }) => {
        return !isDisabled ? (
            <div
                {...innerProps}
                className={`custom-react-select-option ${props.isSelected ? 'isSelected' : ''}`}
                // dangerouslySetInnerHTML={{ __html:  }}
            >
                {props.label}
            </div>
        ) : null;
    };

    return (
        <Form.Group controlId={label}>
            {label && <Form.Label>{label}</Form.Label>}
            <AsyncSelect
                isMulti={isMulti}
                value={value}
                loadOptions={loadOptions}
                onChange={(val, triggeredAction) => {
                    if(triggeredAction.action === 'clear') {
                        return onChange(null);
                    }
                    onChange(val, name);
                }}
                placeholder={placeholder}
                components={{ Option: CustomOption }}
                isClearable={allowClear}
                isDisabled={isDisabled}
                // menuIsOpen={true}
            />
        </Form.Group>
    );
}

ApiAsyncSelect.propTypes = {
    api: PropTypes.string.isRequired,
    labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};
