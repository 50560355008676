/* eslint-disable */
import React from 'react';

import {
    Card,
    StatusBlock,
    ViewHeader,
    DataTable,
    Dialer,
    WhatsApp,
    Filters,
    Sop
} from '@components';
import { formatDate, formatStringWithUnderscores } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { loadVendorRequestsData } from '@store/slices/merchantRequests.slice';
import { Link } from 'react-router-dom';
import defaultLogo from '@assets/img/default.jpg';
import useDidMountEffect from '@hooks/useDidMountEffect';

const VendorRequests = () => {
    const VendorRequestsSlice = useSelector((state) => state.VendorRequestsSlice);
    const dispatch = useDispatch();

    const [filters, set_filters] = React.useState('');
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    const columns = [
        {
            name: 'ID',
            width: '100px',
            cell: (row) => <Link to={`/merchant-requests/${row.id}`}>#{row.id}</Link>,
        },
        {
            name: 'Merchant ID',
            width: '100px',
            cell: (row) => (
                <Link to={`/merchants/${row.vendor.id}`}>
                    <span>
                        {row.vendor.id}
                    </span>
                </Link>
            ),
        },
        {
            width: '80px',
            name: 'Logo',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.vendor.logo_en || defaultLogo} alt={row.vendor.company_name} />
                </div>
            ),
        },
        {
            name: 'Name',
            cell: (row) => (
                <Link to={`/merchants/${row.vendor.id}`}>
                    <span>
                        {row.vendor.company_name}
                    </span>
                </Link>
            ),
        },
        {
            name: 'Request type',
            width: '150px',
            cell: (row) => (
                <Link to={`/merchant-requests/${row.id}`}>
                    <StatusBlock
                        noIcon
                        state={{
                            code: getCodeByRequestType(row.type),
                            description: formatStringWithUnderscores(row.type)
                        }}
                    />
                </Link>
            ),
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            width: '160px',
            cell: (row) => <Dialer number={row.vendor.mobile} />
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '100px',
            center: true,
            cell: (row) => <WhatsApp number={row.vendor.mobile} />
        },
        {
            name: 'Account Manager',
            cell: ({ vendor: { account_manager } }) => (
                <span>{account_manager?.first_name} {account_manager?.last_name}</span>
            )
        },
        {
            name: 'Status',
            cell: (row) => <StatusBlock noIcon state={row.state} />
        },
        {
            name: 'Created at',
            cell: (row) => formatDate(row?.created_at)
        },
        {
            name: 'Updated at',
            cell: (row) => formatDate(row?.updated_at)
        },
    ];

    const fetchData = async () => {
        dispatch(loadVendorRequestsData({ ...filters, page, records_per_page }));
    };

    useDidMountEffect(() => {
        fetchData();
    }, [filters, page, records_per_page]);

    return (
        <div>
            <ViewHeader title="Merchant Requests">
                <Sop id="29923" />
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search IDs, Merchant IDs Request Names."
                    filters={[
                        'vendor_request_type',
                        'vendor_request_status',
                        'vendors',
                        'account_manager_ids',
                        'from',
                        'to',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    sort_options={[
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={VendorRequestsSlice?.vendor_requests}
                    pages={VendorRequestsSlice?.vendor_requests_pages}
                    columns={columns}
                    page={page}
                    records={VendorRequestsSlice?.vendor_requests_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    searchable={false}
                    handlePageChange={set_page}
                    loading={VendorRequestsSlice?.vendor_requests_loading}
                />
            </Card>
        </div>
    );
};

const getCodeByRequestType = (requestType) => {
    switch (requestType) {
        case 'update_profile':
        case 'update_bank_details':
            return 200;
        case 'create_product':
        case 'update_product':
        case 'product_activation':
            return 500;

        default:
            return 700;
    }
};


export default VendorRequests;