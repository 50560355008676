import React from 'react';
import {
    ViewHeader,
    CriticalActionButton,
    FileUpload,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Card,
    Row,
    Col,
    Toggler,
    Input,
    Modal,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { useParams, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { obj2formdata, POST, DELETE, confirmationMessage } from '@utils';
import { loadVendorCategoryData } from '@slices/merchantCategories.slice';
import { setModalStatus } from '@slices/ui.slice';
import { setVendorCategory } from '../../store/slices/merchantCategories.slice';

export default function VendorCategoriesUpdateView() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const VendorCategoriesSlice = useSelector((state) => state.VendorCategoriesSlice);
    const [loading, set_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [image, set_image] = React.useState('');
    const [deleted, set_deleted] = React.useState(false);
    const [submitting, set_submitting] = React.useState(false);

    React.useEffect(() => {
        dispatch(setVendorCategory({}));
        dispatch(loadVendorCategoryData(id));
    }, []);

    React.useEffect(() => {
        if (VendorCategoriesSlice.vendorCategory.id) {
            status.onChange(VendorCategoriesSlice.vendorCategory.is_active);
            name_ar.setValue(VendorCategoriesSlice.vendorCategory.name_ar);
            name_en.setValue(VendorCategoriesSlice.vendorCategory.name_en);
            priority.setValue(VendorCategoriesSlice.vendorCategory.priority);
            if (VendorCategoriesSlice.vendorCategory.image) {
                set_image(VendorCategoriesSlice.vendorCategory.image);
            }
        }
    }, [VendorCategoriesSlice.vendorCategory]);

    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val }),
    });
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });
    const [priority, set_priority] = React.useState({
        label: 'Priority',
        type: 'number',
        value: '',
        setValue: (val) => set_priority({ ...priority, value: val }),
    });
    const [images, set_images] = React.useState({
        label: 'Images',
        files: [],
        setFiles: (val) => set_images({ ...images, files: val }),
    });
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name_ar.value) newErrors.name_ar = 'The name (arabic) field is required';
        if (!name_en.value) newErrors.name_en = 'The name (english) field is required';
        if (!image && !images.files.length) newErrors.images = 'The image field is required.';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name_en, name_ar, image ,images]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const data = obj2formdata({
                is_active: status.checked ? '1' : '0',
                name_ar: name_ar.value,
                name_en: name_en.value,
                priority: priority.value,
            });

            images.files.forEach((image) => {
                data.append('image', image);
            });

            data.append('_method', 'PUT');

            const res = await POST({
                endpoint: `/vendors/category/${id}`,
                data,
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_updated(true);
            }
        }
    };

    const deleteVendorCategory = async () => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/vendors/category/${id}`,
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status === 500) {
            setErrors({ message: [res?.data.message] });
        } else if (res?.status_code === 200 || res?.status_code === 201) {
            set_deleted(true);
        }
    };

    if (updated || deleted) {
        return <Redirect to="/merchant-categories/list" />;
    }

    if (VendorCategoriesSlice.vendorCategory_loading) {
        return 'loading...';
    }
    console.log(images.files);
    return (
        <div className="vendor-categories-update-view">
            <CustomPrompt>
                <ViewHeader title={`Update merchant category ${id}`} renderBackArrow>
                    <Sop id="29823" />
                    <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Actions"
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            <Dropdown.Item
                                eventKey="2"
                                onClick={() =>
                                    dispatch(
                                        setModalStatus({
                                            modal: 'single_vendor_category_view__delete_modal',
                                            status: true,
                                        }),
                                    )
                                }
                            >
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                    <CriticalActionButton
                        // loading={loading}
                        onSubmit={() => {
                            VendorCategoriesSlice.vendorCategory?.is_active
                                ? submit()
                                : confirmationMessage(submit);
                        }}
                    >
                        Save changes
                    </CriticalActionButton>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        <h4 className="divider-title">Merchant category info</h4>
                        <Row>
                            <Col lg={6}>
                                <Toggler {...status} />
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_ar} />
                                        {loading && validation_errors.name_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_ar}
                                            </p>
                                        )}
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_en} />
                                        {loading && validation_errors.name_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_en}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <Input {...priority} />
                            </Col>
                            <Col lg={6}>
                                <FileUpload {...images} />
                                {loading && validation_errors.images && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.images}
                                    </p>
                                )}
                                <ul className="thumpnails">
                                    <li>
                                        <img src={image} />
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Card>

                <Modal
                    title={`Delete ${VendorCategoriesSlice.vendorCategory.name_ar}?`}
                    name="single_vendor_category_view__delete_modal"
                    variant="danger"
                    onSubmit={(closeModal) => {
                        deleteVendorCategory();
                        closeModal('single_vendor_category_view__delete_modal');
                    }}
                    loading={submitting}
                />
            </CustomPrompt>
        </div>
    );
}
