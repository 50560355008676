import {  useDispatch, useSelector } from 'react-redux';
import defaultLogo from '@assets/img/default.jpg';
import { StatusToggler } from '@components';
import { loadProductData } from '@slices/products.slice';

export default function StatusTab() {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.ProductsSlice.product);
    const refetch = () => {
        dispatch(loadProductData(product.id));
    };
 return (
        <div>
            {product.variants?.map(({
                image,
                name_en,
                is_active,
                id,
            }) => (
                <div className='flex__jc-between__ai-center p-2' key={id}>
                    <div className='flex__ai-center gap-8'>
                        <div>#{id}</div>
                        <div>
                            <img style={{ width: 40 }}
                            src={image || defaultLogo} alt="Logo" />
                        </div>
                        <div>{name_en}</div>
                    </div>
                    <StatusToggler
                        id={id}
                        checked={is_active}
                        path={'/product/'+ id +'/status'}
                        callback={() => {
                            refetch();
                        }}
                    />
                </div>)
            )}
        </div>
    );
}
