import React from 'react';
import {
    DataTable,
    Button,
    Modal,
    Input,
    Checkbox,
    Select,
    PaymentMethodIcons,
    ShowErrors
} from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { GET, POST, DELETE, PUT, randomId } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';

const CustomerEntityTab = () => {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);
    const OrdersSlice = useSelector((state) => state.OrdersSlice);

    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/customers/entity-types'
            });
            set_data(res?.data?.sort((a,b) => {
                return a?.name_ar?.localeCompare?.(b.name_ar);
            }) || []);
            allowed_payment_methods.options = OrdersSlice.order_static_data?.payment_methods?.map?.(
                (method) => {
                    return {
                        id: method.code,
                        label: method.name,
                        value: method.code
                    };
                }
            );
            set_loading(false);
        };
        fetchData();
    }, [rerender, OrdersSlice.order_static_data]);

    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val })
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val })
    });
    const [order_min_amount, set_order_min_amount] = React.useState({
        label: 'Order min amount',
        value: '',
        type: 'number',
        setValue: (val) =>
            set_order_min_amount({ ...order_min_amount, value: val })
    });
    const [allowed_payment_methods, set_allowed_payment_methods] =
        React.useState({
            label: 'Allowed payment methods',
            options: [],
            value: [],
            getOptionValue: (option) => option.id,
            isMulti: true,
            onChange: (val) =>
                set_allowed_payment_methods((prev) => ({ ...prev, value: val }))
        });
    const [is_personalization_restricted, set_is_personalization_restricted] =
        React.useState({
            label: 'Is personalization restricted',
            checked: false,
            onChange: (val) =>
                set_is_personalization_restricted({
                    ...is_personalization_restricted,
                    checked: val
                })
        });

    const submit = async (closeModal, id) => {
        set_loading(true);

        const res = await (id ? PUT : POST)({
            endpoint: id
                ? `/customers/entity-types/${id}`
                : '/customers/entity-types',
            data: {
                name_ar: name_ar.value,
                name_en: name_en.value,
                order_min_amount: order_min_amount.value,
                allowed_payment_methods: allowed_payment_methods.value.map(
                    (option) => option.id
                ),
                is_personalization_restricted:
                    is_personalization_restricted.checked ? '1' : '0'
            }
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('customer_entity__modal');
            set_rerender(randomId());
            name_ar.setValue('');
            name_en.setValue('');
            order_min_amount.setValue('');
            allowed_payment_methods.onChange([]);
            is_personalization_restricted.onChange(false);
            set_errors([]);
        }
    };

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar'
        },
        {
            name: 'Name (EN)',
            selector: 'name_en'
        },
        {
            name: 'Order min amount',
            selector: 'order_min_amount'
        },
        {
            name: 'Allowed payment methods',
            cell: (row) => {
                return (
                    <div className="flex" style={{ gap: 5 }}>
                        {row?.allowed_payment_methods?.map((method, index) => (
                            <PaymentMethodIcons state={method.code} key={index} />
                        ))}
                    </div>
                );
            }
        },
        {
            name: 'Is personalization restricted',
            cell: (row) => (row.is_personalization_restricted ? 'Yes' : 'No')
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'customer_entity__delete_modal',
                                    status: true
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            set_errors([]);
                            name_ar.setValue(row.name_ar);
                            name_en.setValue(row.name_en);
                            order_min_amount.setValue(row.order_min_amount);
                            allowed_payment_methods.onChange(
                                row?.allowed_payment_methods?.map((method) => ({
                                    label: method.name,
                                    value: method.code,
                                    id: method.code
                                }))
                            );
                            is_personalization_restricted.onChange(
                                row.is_personalization_restricted
                            );
                            dispatch(
                                setModalStatus({
                                    modal: 'customer_entity__modal',
                                    status: true
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            )
        }
    ];

    const deleteCustomerEntity = async (closeModal) => {
        set_loading(true);
        const res = await DELETE({
            endpoint: `/customers/entity-types/${delete_id}`
        });
        set_loading(false);

        closeModal('customer_entity__delete_modal');

        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('customer_entity__delete_modal');
            set_rerender(randomId());
            set_delete_id(null);
        }
    };

    return (
        <>
            <DataTable data={data} columns={columns} loading={loading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        name_ar.setValue('');
                        name_en.setValue('');
                        order_min_amount.setValue('');
                        allowed_payment_methods.onChange([]);
                        is_personalization_restricted.onChange(false);
                        set_errors([]);

                        dispatch(
                            setModalStatus({
                                modal: 'customer_entity__modal',
                                status: true
                            })
                        );
                    }}
                >
                    Add new customer entity
                </Button>
            </div>

            <Modal
                title={
                    edit_id
                        ? 'Update customer entity'
                        : 'Add new customer entity'
                }
                variant={edit_id ? 'warning' : 'primary'}
                name="customer_entity__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
                loading={loading}
            >
                <Input {...name_ar} />
                <Input {...name_en} />
                <Input {...order_min_amount} />
                <Select {...allowed_payment_methods} />
                <Checkbox {...is_personalization_restricted} />

                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>

            <Modal
                title="Delete customer entity?"
                name="customer_entity__delete_modal"
                variant="danger"
                onSubmit={deleteCustomerEntity}
                loading={loading}
            />
        </>
    );
};

export default CustomerEntityTab;
