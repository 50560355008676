import { createSlice } from '@reduxjs/toolkit';
import { GET } from '@utils';

export const BanksSlice = createSlice({
    name: 'banks',
    initialState: {
        global_banks: [],
        global_banks_loaded: false,
    },
    reducers: {
        setGlobalBanks(state, { payload }) {
            state.global_banks = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
    },
});

// export actions
export const {
    setGlobalBanks,
    setLoading,
} = BanksSlice.actions;

export const loadGlobalBanks = () => async (dispatch) => {
    const res = await GET({
        endpoint: '/payment/banks',
    });
    dispatch(setGlobalBanks(res?.data || []));
    dispatch(
        setLoading({
            key: 'global_banks_loaded',
            value: true,
        })
    );
};

export default BanksSlice.reducer;
