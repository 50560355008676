function diffTimes(a, b = new Date()){
    const d1 = new Date(a);
    const d2 = new Date(b);
    const diff_hours = Math.abs(d1 - d2) / 36e5;
    const days = (diff_hours - (diff_hours%24)) / 24;
    const hours = Math.floor(diff_hours%24);
    if(diff_hours < 1){
        return 'less than hour';
    }
    if(hours === 0){
        return `${days} days`;
    }
    if(diff_hours < 24){
        return `${Math.floor(diff_hours)} hours`;
    }
    return `${days} days, ${hours} hours`;
}

export default diffTimes;
