/* eslint-disable no-unused-vars */
import React from 'react';
import { formatStringWithUnderscores } from '@utils';

export default function FormattedLabel(filter_key, filter_value, label) {
    const formatted_label = formatStringWithUnderscores(filter_key);
    const is_value_array = Array.isArray(filter_value);
    let formatted_key = is_value_array
        ? filter_value.join?.(', ')
        : filter_value;

    
    return (
        <>
            {formatted_label}
            {': '}
            {label || formatted_key}
        </>
    );
}
