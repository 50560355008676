import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const AdminsSlice = createSlice({
    name: 'admins',
    initialState: {
        admins: [],
        admins_pages: null,
        admins_records: null,
        admin: {},
        admins_loading: true,
        admin_loading: true,
    },
    reducers: {
        setAdmins(state, { payload }) {
            state.admins = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.admins.findIndex((admin) => admin.id === payload.id);
            if (index !== -1) {
                state.admins[index] = {
                    ...state.admins[index],
                    ...payload,
                };
            }
        },
        setAdmin(state, { payload }) {
            state.admin = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setAdminsTotalPages(state, { payload }) {
            state.admins_pages = payload;
        },
        setAdminsRecords(state, { payload }) {
            state.admins_records = payload;
        },
    },
});

// export actions
export const {
    setAdmin,
    updateSingleRow,
    setAdmins,
    setLoading,
    setAdminsTotalPages,
    setAdminsRecords,
} = AdminsSlice.actions;

export const loadAdminsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'admins_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/user?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setAdmins(res?.data || []));
    if(res?.meta){
        dispatch(setAdminsTotalPages(res?.meta?.last_page || 0));
        dispatch(setAdminsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'admins_loading',
            value: false,
        })
    );
};

export const loadAdminData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'admin_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/user/${id}`,
    });
    dispatch(setAdmin(res?.data || {}));
    dispatch(
        setLoading({
            key: 'admin_loading',
            value: false,
        })
    );
};

export default AdminsSlice.reducer;
