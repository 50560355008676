import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import DiscountsListView from './DiscountsListView';
import DiscountsAddView from './DiscountsAddView';
import DiscountsUpdateView from './DiscountsUpdateView';
import { Helmet } from 'react-helmet';

export default function DiscountsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/discounts/list">
                <Helmet>
                    <title>Discounts | Disty Admin Dashboard</title>
                </Helmet>
                <DiscountsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/discounts/add">
                <Helmet>
                    <title>Add Discount | Disty Admin Dashboard</title>
                </Helmet>
                <DiscountsAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/discounts/:id">
                <Helmet>
                    <title>Edit Discount | Disty Admin Dashboard</title>
                </Helmet>
                <DiscountsUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
