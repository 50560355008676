import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
function PieChart({ label , labels , dataNum ,backgroundColor ,borderColor }) {
    const  data = {
        labels,
        datasets: [
            {
                label,
                data: dataNum,
                backgroundColor,
                borderColor,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: 'bottom',
            },
        }
    };
      
    return (
        <div>
            <Pie data={data} options={options} style={{ maxHeight : '350px' }} />
        </div>
    );
}

export default PieChart;



