import { Card, CopyToClipboard } from '@components';

const BankDetails = ({ bank_account, loading, errors }) => (
    <Card title="Bank details">
        {loading && <div className="p-3">Loading...</div>}

        {!!Object.keys(errors).length && (
            <div className="mx-3 alert alert-danger">
                <ul>
                    {Object.keys(errors).map((key) => {
                        return <li key={key}>{errors[key]}</li>;
                    })}
                </ul>
            </div>
        )}

        {!loading && !Object.keys(errors).length && (
            <ul className="flex__fd-column gap-5 py-2">
                <li className="flex__jc-between flex__ai-center">
                    <b>Bank Name</b>
                    <div className="flex">
                        {bank_account?.bank?.name}
                        {bank_account?.bank?.name && (
                            <CopyToClipboard text={bank_account?.bank?.name} />
                        )}
                    </div>
                </li>
                <li className="flex__jc-between flex__ai-center">
                    <b>Holder Name</b>
                    <div className="flex">
                        {bank_account?.holder_name}
                        {bank_account?.holder_name && (
                            <CopyToClipboard text={bank_account?.holder_name} />
                        )}
                    </div>
                </li>
                <li className="flex__jc-between flex__ai-center">
                    <b>Account Number</b>
                    <div className="flex">
                        {bank_account?.account_number}
                        {bank_account?.account_number && (
                            <CopyToClipboard text={bank_account?.account_number} />
                        )}
                    </div>
                </li>
                <li className="flex__jc-between flex__ai-center">
                    <b>IBAN</b>
                    <div className="flex">
                        {bank_account?.iban}
                        {bank_account?.iban && <CopyToClipboard text={bank_account?.iban} />}
                    </div>
                </li>
            </ul>
        )}
    </Card>
);

export default BankDetails;
