import React from 'react';
import { Modal, Input, Select } from '@components';
import { useSelector } from 'react-redux';
import { Map } from '@components';

export default function AddressModal({ getAddress }) {
    const delivery_times = useSelector((state) => state.GlobalsSlice.delivery_times);
    const [google_maps_address, set_google_maps_address] = React.useState(null);
    const center_of_SA = {
        lat: 24.804219,
        lng: 45.8601,
    };
    const [marker, set_marker] = React.useState(center_of_SA);
    const [center, set_center] = React.useState(center_of_SA);
    const [coords, set_coords] = React.useState({});

    const [address, set_address] = React.useState({
        name: '',
        suitable_delivery_time_id: '',
        contact_name: '',
        contact_mobile: '',
        latitude: coords?.lat || '',
        longitude: coords?.lng || '',
    });

    React.useEffect(() => {
        set_address({
            ...address,
            latitude: coords.lat,
            longitude: coords.lng,
        });
    }, [coords.lat, coords.lng]);

    return (
        <Modal
            title="Retailer Address"
            name="customer_address_modal"
            onSubmit={(close) => getAddress(close, address)}
        >
            <Map
                onCoordsChange={set_coords}
                coords={coords}
                set_center={set_center}
                center={center}
                set_marker={set_marker}
                marker={marker}
                google_maps_address={google_maps_address}
                set_google_maps_address={set_google_maps_address}
                allow_search={true}
                height="600px"
            />
            <hr />
            <Input
                label="Name"
                setValue={(val) => {
                    set_address({ ...address, name: val });
                }}
                value={address?.name}
            />
            <Input
                label="Contact name"
                setValue={(val) => {
                    set_address({ ...address, contact_name: val });
                }}
                value={address?.contact_name}
            />
            <Input
                label="Contact mobile"
                type="number"
                prefix="+966"
                setValue={(val) => {
                    set_address({ ...address, contact_mobile: val });
                }}
                value={address?.contact_mobile}
            />
            <Select
                options={delivery_times?.map((i) => ({ value: i.id, label: i.label }))}
                label="Suitable delivery time"
                value={address?.suitable_delivery_time_id || ''}
                onChange={(val) =>
                    set_address({
                        ...address,
                        suitable_delivery_time_id: val,
                    })
                }
            />
        </Modal>
    );
}
