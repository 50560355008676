import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import { Helmet } from 'react-helmet';
import VendorRequests from './MerchantRequests';
import VendorRequest from './MerchantRequest';

export default function VendorRequestsRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/merchant-requests">
                <Helmet>
                    <title>Merchant request | Disty Admin Dashboard</title>
                </Helmet>
                <VendorRequests />
            </ProtectedRoute>
            <ProtectedRoute exact path="/merchant-requests/:id">
                <Helmet>
                    <title>Merchant request | Disty Admin Dashboard</title>
                </Helmet>
                <VendorRequest />
            </ProtectedRoute>
        </Switch>
    );
}