import React from 'react';
import { ViewHeader, Link, Card, Button, Sop } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadActiveBannersData } from '@slices/banners.slice';
import { ReactSortable } from 'react-sortablejs';
import { PATCH, randomId } from '@utils';
import { Redirect } from 'react-router';

export default function BannersListView() {
    const BannersSlice = useSelector((state) => state.BannersSlice);
    const dispatch = useDispatch();
    const [banners, set_banners] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [updated, set_updated] = React.useState(false);

    React.useEffect(() => {
        dispatch(loadActiveBannersData());
    }, []);

    React.useEffect(() => {
        set_banners(BannersSlice.active_banners);
    }, [BannersSlice.active_banners]);

    const submit = async () => {
        set_loading(true);
        const data = banners.map((banner, i) => ({
            id: banner.id,
            display_priority: i + 1
        }));
        const res = await PATCH({
            endpoint: '/marketing/banners/priority',
            data: {
                banners: data
            },
            headers: {
                'Idempotency-Key': randomId()
            }
        });

        set_loading(false);
        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            set_updated(true);
        }
    };

    if (updated) {
        return <Redirect to="/banners/list" />;
    }

    if (BannersSlice.active_banners_loading) {
        return 'loading...';
    }

    return (
        <div className="banners-list-view">
            <ViewHeader title="Sort Banners">
                <div className="flex__ai-center">
                    <Sop id="25323"/>
                    <Link href="/banners/list" className="btn btn-light">
                    Cancel
                    </Link>
                    <Button variant="primary" onClick={submit} disabled={loading}>
                    Save changes
                    </Button>
                </div>
          
            </ViewHeader>

            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}

            <Card>
                {banners.length > 1 && (
                    <ReactSortable
                        list={banners.map((x) => ({ ...x, chosen: true }))}
                        setList={set_banners}
                        className="sortable-banners-list"
                    >
                        {banners.map((banner, i) => (
                            <div key={i} className="sortable-banner-item">
                                <div className="banner-header">
                                    <div className="priority-number">
                                        {i + 1}
                                    </div>
                                    <p>{banner.title_en}</p>
                                </div>
                                <img
                                    src={banner.banner_image_ar}
                                    alt={banner.banner_image_ar}
                                />
                            </div>
                        ))}
                    </ReactSortable>
                )}
            </Card>
        </div>
    );
}
