import React from 'react';
import {
    ViewHeader,
    CriticalActionButton,
    Card,
    Input,
    Checkbox,
    Row,
    Col,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Modal,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { GET, PUT, DELETE } from '@utils';
import { useParams, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadUsersGroupData, setUsersGroup } from '@slices/usersGroups.slice';
import { setModalStatus } from '@slices/ui.slice';

export default function UsersGroupsUpdateView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const UsersGroupsSlice = useSelector((state) => state.UsersGroupsSlice);

    const [loading, set_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(false);
    const [deleted, set_deleted] = React.useState(false);
    const [name, set_name] = React.useState('');
    const [roles, set_roles] = React.useState({});
    const [selected_roles, set_selected_roles] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [submitting, set_submitting] = React.useState(false);

    React.useEffect(() => {
        if (UsersGroupsSlice.usersGroup.id) {
            set_name(UsersGroupsSlice.usersGroup.name);

            Object.keys(UsersGroupsSlice.usersGroup.permissions).map((m) => {
                const module = UsersGroupsSlice.usersGroup.permissions[m];
                Object.keys(module).map((p) => {
                    const permission = module[p];
                    if (permission.has_permission) {
                        set_selected_roles((prev) => [...prev, permission.key]);
                    }
                });
            });
        }
    }, [UsersGroupsSlice.usersGroup.id]);

    React.useEffect(() => {
        set_selected_roles([]);
        dispatch(setUsersGroup({}));
        dispatch(loadUsersGroupData(id));

        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/role/permissions',
            });
            set_loading(false);
            set_roles(res?.data || {});
        };
        fetchData();
    }, []);

    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name || !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(name))
            newErrors.name = 'The name field is required and must be contained only letters and spaces.';
        if (selected_roles.length === 0)
            newErrors.permissions = 'The permissions field is required.';

        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name, selected_roles]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const res = await PUT({
                endpoint: `/role/${id}`,
                data: {
                    name,
                    permissions: selected_roles,
                },
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_updated(true);
            }
        }
    };

    const deleteUsersGroup = async () => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/role/${id}`,
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status === 500) {
            setErrors({ message: [res?.data.message] });
        } else if (res?.status_code === 200 || res?.status_code === 201) {
            set_deleted(true);
        }
    };

    if (updated || deleted) {
        return <Redirect to="/users-groups/list" />;
    }

    if (UsersGroupsSlice.usersGroup_loading) {
        return 'loading...';
    }

    return (
        <div className="users-groups-form-view">
            <CustomPrompt>
                <ViewHeader
                    title={`Update users group : ${UsersGroupsSlice.usersGroup.name}`}
                    renderBackArrow
                >
                    <Sop id="30163" />
                    <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Actions"
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            <Dropdown.Item
                                eventKey="2"
                                onClick={() =>
                                    dispatch(
                                        setModalStatus({
                                            modal: 'single_usersGroup_view__delete_modal',
                                            status: true,
                                        }),
                                    )
                                }
                            >
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>

                    <CriticalActionButton onSubmit={submit}>Save changes</CriticalActionButton>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        <Row>
                            <Col xs={12} md={6}>
                                <Input label="Group name" setValue={set_name} value={name} />
                                {loading && validation_errors.name && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.name}
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {loading && validation_errors.permissions && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.permissions}
                                    </p>
                                )}
                                {Object.keys(roles).length
                                    ? Object.keys(roles).map((role, i) => (
                                          <div key={i} className="single-module-permissions">
                                              <label>{role}</label>
                                              <Row>
                                                  {Object.keys(roles[role]).map((permission, i) => {
                                                      return (
                                                          <Col key={i} xs={6} md={4} lg={3}>
                                                              <Checkbox
                                                                  label={permission}
                                                                  checked={
                                                                      selected_roles.indexOf(
                                                                          roles[role][permission],
                                                                      ) !== -1
                                                                  }
                                                                  onChange={(bool) => {
                                                                      if (
                                                                          bool &&
                                                                          selected_roles.indexOf(
                                                                              roles[role][
                                                                                  permission
                                                                              ],
                                                                          ) === -1
                                                                      ) {
                                                                          set_selected_roles(
                                                                              (prev) => [
                                                                                  ...prev,
                                                                                  roles[role][
                                                                                      permission
                                                                                  ],
                                                                              ],
                                                                          );
                                                                      } else {
                                                                          set_selected_roles(
                                                                              (prev) => [
                                                                                  ...prev.filter(
                                                                                      (r) =>
                                                                                          r !==
                                                                                          roles[
                                                                                              role
                                                                                          ][
                                                                                              permission
                                                                                          ],
                                                                                  ),
                                                                              ],
                                                                          );
                                                                      }
                                                                  }}
                                                              />
                                                          </Col>
                                                      );
                                                  })}
                                              </Row>
                                          </div>
                                      ))
                                    : 'loading....'}
                            </Col>
                        </Row>
                    </div>
                </Card>

                <Modal
                    title={`Delete ${UsersGroupsSlice.usersGroup.name}?`}
                    name="single_usersGroup_view__delete_modal"
                    variant="danger"
                    onSubmit={(closeModal) => {
                        deleteUsersGroup();
                        closeModal('single_usersGroup_view__delete_modal');
                    }}
                    loading={submitting}
                />
            </CustomPrompt>
        </div>
    );
}
