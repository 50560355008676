import { useState, useEffect } from 'react';
import { FileUpload, Card } from '@components';
import { loadPayoutReceipts, uploadPayoutReceipt, resetReceipts } from '@slices/payouts.slice';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, downloadFileFromAPI } from '@utils';

const PayoutReceipts = ({ payout_id }) => {
    const [downloading, set_downloading] = useState(false);
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();

    const receipts = PayoutsSlice.receipts;
    const list_loading = !receipts.length && PayoutsSlice.receipts_loading;
    const uploading = PayoutsSlice.receipt_uploading;
    const errors = { ...PayoutsSlice.receipts_errors, ...PayoutsSlice.receipt_errors };

    const fetchReceipts = () => {
        dispatch(resetReceipts());
        dispatch(loadPayoutReceipts(payout_id));
    };

    const uploadReceipt = async () => {
        if (!transfer_receipt.files.length) return false;

        const { files } = transfer_receipt;
        const req = await dispatch(uploadPayoutReceipt(payout_id, files));
        if (req) {
            transfer_receipt.setFiles([]);
        }
    };

    const downloadReceipt = async (receipt_id) => {
        set_downloading(true);

        await downloadFileFromAPI(
            `/vendors/vendor-payouts/${payout_id}/receipts/${receipt_id}`,
            `payout-${payout_id}-receipt-${receipt_id}`,
        );

        set_downloading(false);
    };

    const [transfer_receipt, set_transfer_receipt] = useState({
        label: 'Transfer receipt (optional)',
        files: [],
        allowPDF: true,
        setFiles: (val) => set_transfer_receipt({ ...transfer_receipt, files: val }),
    });

    useEffect(fetchReceipts, []);
    useEffect(uploadReceipt, [transfer_receipt]);

    return (
        <Card title="Payment Proof/ receipt">
            {list_loading && <div className="p-3">Loading...</div>}

            {!!Object.keys(errors).length && (
                <div className="mx-3 alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}

            {!list_loading && !Object.keys(PayoutsSlice.receipts_errors)?.length && (
                <div className="payout-receipt px-3" data-loading={uploading || downloading}>
                    <div className="loading">
                        {`Receipt ${downloading ? 'Downloading' : 'Uploading'}...`}
                    </div>

                    <div className="receipt-upload">
                        <div>
                            <b>Payment Proof</b>
                            <hr />
                            <FileUpload {...transfer_receipt} />
                        </div>
                    </div>

                    <ul className="receipts-list">
                        {receipts.map((receipt) => (
                            <li
                                className="link"
                                key={receipt.id}
                                onClick={() => downloadReceipt(receipt.id)}
                            >
                                {`Download Payment receipt - ${receipt.id}, ${formatDate(
                                    receipt.created_at,
                                )}`}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </Card>
    );
};

export default PayoutReceipts;
