import { useState } from 'react';

const defaultStates = {
    data: undefined,
    error: null,
    isLoading: false,
};

const defaultConfig = {
    throwError: true,
};

const defaultErrorMessage = 'Something went wrong!';

/**
 *
 * @typedef {Object} Config
 * @property {Function} onSuccess
 * @property {Function} onError
 * @property {boolean} throwError
 *
 * @typedef {Object} Return
 * @property {*} data
 * @property {string | null} error
 * @property {boolean} isLoading
 * @property {Function} mutate
 *
 * @param {Function} mutationFn
 * @param {Config} config
 * @returns {Return}
 */
export default function useMutation(mutationFn, config = {}) {
    const [isLoading, setIsLoading] = useState(defaultStates.isLoading);
    const [error, setError] = useState(defaultStates.error);
    const [data, setData] = useState(defaultStates.data);

    const mutationConfig = {
        ...defaultConfig,
        ...config,
    };

    /**
     * run mutation function with async mode
     * @returns {Promise<any>}
     */
    const mutate = async (...args) => {
        try {
            setIsLoading(true);
            setError(defaultStates.error);
            const res = await mutationFn(...args);
            setData(res);

            // throw error on backend error come
            if (
                mutationConfig?.throwError &&
                (res?.data?.errors ||
                    res?.data?.status_code >= 500 ||
                    res?.data?.status_code === 400 ||
                    res?.success === false ||
                    res?.data?.success === false)
            ) {
                const error = res?.data?.errors || {
                    error: [res?.data?.message || defaultErrorMessage],
                };
                throw { error, data: res?.data };
            }

            mutationConfig?.onSuccess?.(res, ...args);
        } catch (err) {
            setError(err.error || defaultErrorMessage);
            mutationConfig?.onError?.(err.error || defaultErrorMessage, err.data);
        } finally {
            setIsLoading(defaultStates.isLoading);
        }
    };

    /**
     * reset hook status to first render status
     */
    const reset = () => {
        setData(defaultStates.data);
        setIsLoading(defaultStates.isLoading);
        setError(defaultStates.error);
    };

    return {
        data,
        error,
        isLoading,
        mutate,
        reset,
    };
}
