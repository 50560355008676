import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import ConfigsView from './ConfigsView';
import { Helmet } from 'react-helmet';

export default function ConfigsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/configs">
                <Helmet>
                    <title>Configs | Disty Admin Dashboard</title>
                </Helmet>
                <ConfigsView />
            </ProtectedRoute>
        </Switch>
    );
}
