import React from 'react';
import {
    ViewHeader,
    Button,
    Link,
    Card,
    DataTable,
    Input,
    Modal,
    Col,
    Row,
    Textarea,
    DropdownButton,
    Dropdown,
    Checkbox,
    ButtonGroup,
    ShowErrors,
    Sop
} from '@components';
import {
    loadNotificationsData,
    loadVendorNotifications,
} from '@slices/notifications.slice';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { DELETE, PUT, randomId, formatDate } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
import { useLocation } from 'react-router-dom';

export default function PushNotificationsListView() {
    const NotificationsSlice = useSelector((state) => state.NotificationsSlice);
    const dispatch = useDispatch();
    const location = useLocation();
    const isCustomerNotifications = location.pathname.includes(
        '/customers-notifications'
    );

    const href = isCustomerNotifications
        ? '/customers-notifications'
        : '/vendors-notifications';
    const [filters, set_filters] = React.useState({
        sort_by: 'created_at',
        categories: [],
    });

    const [q, set_q] = React.useState('');
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [edit_id, set_edit_id] = React.useState(null);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [delete_id, set_delete_id] = React.useState(null);
    const [title_en, set_title_en] = React.useState({
        label: 'Title',
        value: '',
        dir: 'auto',
        setValue: (val) => set_title_en({ ...title_en, value: val }),
    });
    const [body_en, set_body_en] = React.useState({
        label: 'Body',
        value: '',
        dir: 'auto',
        setValue: (val) => set_body_en({ ...body_en, value: val }),
    });
    const [title_ar, set_title_ar] = React.useState({
        label: 'Title',
        value: '',
        dir: 'auto',
        setValue: (val) => set_title_ar({ ...title_ar, value: val }),
    });
    const [body_ar, set_body_ar] = React.useState({
        label: 'Body',
        value: '',
        dir: 'auto',
        setValue: (val) => set_body_ar({ ...body_ar, value: val }),
    });
    const [CTA, set_CTA] = React.useState({
        label: 'Call to action (Link)',
        value: '',
        setValue: (val) => set_CTA({ ...CTA, value: val }),
    });
    const [cta_is_external, set_cta_is_external] = React.useState({
        label: 'Is CTA external?',
        checked: false,
        onChange: (val) =>
            set_cta_is_external({
                ...cta_is_external,
                checked: val,
            }),
    });

    React.useEffect(() => {
        isCustomerNotifications
            ? dispatch(
                loadNotificationsData({ q, page, ...filters, records_per_page })
			  )
            : dispatch(
                loadVendorNotifications({ q, ...filters, page, records_per_page })
			  );
    }, [location.pathname, q, page, records_per_page, filters]);

    const categories = [
        {
            id: 'order',
            label: 'Order',
        },
        {
            id: 'release',
            label: 'Release',
        },
        {
            id: 'important',
            label: 'Important',
        },
        {
            id: 'general',
            label: 'General',
        },
    ];

    const notificationFilters = (
        <DropdownButton
            variant="light"
            as={ButtonGroup}
            title={`Categories ${
                filters.categories.length ? `(${filters.categories.length})` : ''
            }`}
        >
            {categories.map((c, i) => (
                <Dropdown.Item key={i} eventKey="1" as="div">
                    <Checkbox
                        label={c.label}
                        checked={filters.categories.indexOf(c.id) > -1}
                        onChange={(val) => {
                            if (!val) {
                                set_filters({
                                    ...filters,
                                    categories: filters.categories.filter((s) => s !== c.id),
                                });
                            } else {
                                set_filters({
                                    ...filters,
                                    categories: [...filters.categories, c.id],
                                });
                            }
                        }}
                    />
                </Dropdown.Item>
            ))}
            <Dropdown.Item
                eventKey="2"
                onClick={() =>
                    set_filters({
                        ...filters,
                        categories: [],
                    })
                }
            >
				Clear
            </Dropdown.Item>
        </DropdownButton>
    );

    const columns = [
        {
            name: 'Category',
            cell: (row) => row.category,
        },
        {
            name: 'Arabic Title',
            selector: 'title_ar',
            sortable: false,
            width: '300px',

            cell: (row) =>
                isCustomerNotifications ? (
                    <Link href={`${href}/${row.id}`} className="tr-name">
                        <h5>{row.title_ar}</h5>
                    </Link>
                ) : (
                    row?.title_ar
                ),
        },
        {
            name: 'Arabic body',
            selector: 'body_ar',
            width: '300px',
            cell: (row) => row.body_ar,
        },

        {
            name: 'English Title',
            selector: 'title_en',
            width: '300px',
            cell: (row) => row.title_en,
        },
        {
            name: 'English body',
            selector: 'body_en',
            width: '300px',
            cell: (row) => row.body_en,
        },
        {
            name: 'CTA',
            selector: 'cta',
            width: '100px',

            cell: (row) => (
                <a target="blank" href={row?.cta}>
                    <h5>{row?.cta}</h5>
                </a>
            ),
        },
        {
            name: 'Created at',
            selector: 'created_at',
            sortable: false,
            width: '100px',

            cell: (row) => <>{formatDate(row.created_at)}</>,
        },
        {
            name: '',
            selector: 'actions',

            width: '200px',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'notification__delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
					&nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            set_errors([]);

                            set_title_en({ ...title_en, value: row.title_en });
                            set_body_en({ ...body_en, value: row.body_en });
                            set_title_ar({ ...title_ar, value: row.title_ar });
                            set_body_ar({ ...body_ar, value: row.body_ar });
                            set_CTA({ ...CTA, value: row.cta });
                            set_cta_is_external({
                                ...cta_is_external,
                                checked: row.cta_is_external,
                            });

                            dispatch(
                                setModalStatus({
                                    modal: 'notification__modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const handleSubmit = async (closeModal, id) => {
        set_loading(true);
        const data = {
            title_ar: title_ar.value,
            title_en: title_en.value,
            body_ar: body_ar.value,
            body_en: body_en.value,
            cta: CTA.value,
            cta_is_external: cta_is_external.checked ? '1' : '0',
        };

        const res = await PUT({
            endpoint: `/notifications/${id}`,
            data,
        });
        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal();
            dispatch(loadNotificationsData({ q, page }));
        }
    };

    const deleteNotification = async (closeModal) => {
        const res = await DELETE({
            endpoint: `/notifications/${delete_id}`,
        });

        closeModal('notification__delete_modal');

        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            set_delete_id(null);
            dispatch(loadNotificationsData({ q, page }));
        }
    };
    const tableFilters = (
        <ButtonGroup key={1}>
            <DropdownButton
                variant="light"
                as={ButtonGroup}
                title={
                    <span>
						Sort by {filters.sort_by === 'created_at' && '(created_at)'}
                    </span>
                }
                id={`bg-nested-dropdown-${randomId()}`}
            >
                <Dropdown.Item
                    eventKey="1"
                    onClick={() =>
                        set_filters({
                            ...filters,
                            sort_by: 'created_at',
                        })
                    }
                >
					Created At
                </Dropdown.Item>
                <Dropdown.Item
                    eventKey="2"
                    onClick={() => set_filters({ ...filters, sort_by: null })}
                >
					Clear
                </Dropdown.Item>
            </DropdownButton>
        </ButtonGroup>
    );

    return (
        <div className="notifications-list-view">
            <ViewHeader title="All notifications">
                <div className="flex__ai-center">
                    <Sop id="25403"/>
                    <Link href={`${href}/add`} className="btn btn-primary">
					New notification
                    </Link>
                </div>
            
            </ViewHeader>

            <Card>
                <DataTable
                    columns={
                        isCustomerNotifications ? columns.slice(1) : columns.slice(0, -1)
                    }
                    data={
                        isCustomerNotifications
                            ? NotificationsSlice.notifications
                            : NotificationsSlice.vendor_notifications
                    }
                    page={page}
                    records={
                        isCustomerNotifications
                            ? NotificationsSlice.notifications_records
                            : NotificationsSlice.vendor_notifications_records
                    }
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    pages={
                        isCustomerNotifications
                            ? NotificationsSlice.notifications_pages
                            : NotificationsSlice.vendor_notifications_pages
                    }
                    handlePageChange={set_page}
                    searchable={true}
                    onSearch={set_q}
                    loading={
                        isCustomerNotifications
                            ? NotificationsSlice.notifications_loading
                            : NotificationsSlice.vendor_notifications_loading
                    }
                    tableFilters={
                        isCustomerNotifications ? tableFilters : notificationFilters
                    }
                />
            </Card>

            <Modal
                title={'update notification'}
                variant={'warning'}
                name="notification__modal"
                size="xl"
                onSubmit={(closeModal) => {
                    handleSubmit(closeModal, edit_id);
                }}
                loading={loading}
            >
                <Row>
                    <Col sm={12} md={6}>
                        <h3 className="divider-title">Arabic content</h3>
                        <Input {...title_ar} />
                        <Textarea {...body_ar} />
                    </Col>
                    <Col sm={12} md={6}>
                        <h3 className="divider-title">English content</h3>
                        <Input {...title_en} />
                        <Textarea {...body_en} />
                    </Col>
                    <Col sm={12}>
                        <Input {...CTA} />
                        <Checkbox {...cta_is_external} />
                    </Col>
                </Row>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            </Modal>

            <Modal
                title="Delete notification?"
                name="notification__delete_modal"
                variant="danger"
                onSubmit={deleteNotification}
                loading={loading}
            />
        </div>
    );
}
