import React from 'react';
import {
    DataTable,
    WhatsApp,
    Dialer,
    Card,
    ButtonGroup,
    DropdownButton,
    Radio,
    Dropdown,
    Filters,
    ViewHeader,
    Sop,
} from '@components';
import { POST, formatStringWithUnderscores, errorsNormalizer } from '@utils';
import usePaginationList from '@hooks/usePaginationList';
import useMutation from '@hooks/useMutation';
import { toast } from 'react-toastify';

const STATUS_OPTIONS = [
    'request_received',
    'vendor_shortlisted',
    'vendor_contacted',
    'vendor_negotiation',
    'agreement_failed',
    'vendor_joined',
    'customer_contacted',
];

export default function ListView() {
    const [q, set_q] = React.useState('');
    const [page, set_page] = React.useState(1);

    const [filters, set_filters] = React.useState({});

    const { dataList, pages, isLoading, records, mutate } = usePaginationList({
        endpoint: '/vendors/request-vendors',
        params: { q, ...filters, page },
    });

    const handleChangeStatus = (state, id) => {
        const endpoint = `/vendors/request-vendors/${id}/state`;
        const data = {
            _method: 'patch',
            state: state,
        };
        return POST({
            endpoint,
            data,
        });
    };

    const updateMutation = useMutation(handleChangeStatus, {
        onSuccess: ({ message }) => {
            toast.success(message);
            mutate({ q, ...filters, page });
        },
        onError: (errors) => toast.error(errorsNormalizer(errors).join(', ')),
    });

    const columns = [
        {
            name: 'Request ID',
            selector: 'id',
            width: '100px',
            center: true,
        },
        {
            name: 'First Name',
            cell: (row) => row?.customer?.first_name,
        },
        {
            name: 'Last Name',
            cell: (row) => row?.customer?.last_name,
        },
        {
            name: 'Mobile (Retailer)',
            width: '150px',
            cell: (row) => <Dialer number={row?.customer?.mobile} />,
        },
        {
            name: 'Whatsapp (Retailer)',
            width: '100px',
            center: true,
            cell: (row) => <WhatsApp number={row?.customer?.mobile} />,
        },
        {
            name: 'Merchant name ',
            selector: 'vendor_name',
            width: '150px',
            cell: (row) => row?.vendor_name,
        },
        {
            name: 'Merchant Details ',
            width: '150px',
            cell: (row) => row?.details,
        },
        {
            name: 'Address',
            selector: 'address',
            width: '150px',
            cell: (row) => row?.address,
        },
        {
            name: 'Mobile number',
            selector: 'mobile',
            width: '150px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Email',
            selector: 'email',
            width: '200px',
            cell: (row) => row?.email && <a href={`mailto:${row?.email}`}>{row?.email}</a>,
        },
        {
            name: 'Status',
            center: true,
            width: '200px',
            cell: (row) => {
                return (
                    <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title={formatStringWithUnderscores(row?.state)}
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            {STATUS_OPTIONS.map((action, i) => (
                                <Dropdown.Item as="div" key={i} eventKey={i}>
                                    <Radio
                                        onChange={(value) => updateMutation.mutate(value, row.id)}
                                        option={row?.state}
                                        label={formatStringWithUnderscores(action)}
                                        value={action}
                                        name={`selected_status${row.id}`}
                                    />
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </ButtonGroup>
                );
            },
        },
    ];

    return (
        <div>
            <ViewHeader title="Requested Merchants">
                <Sop id="29783" />
            </ViewHeader>
            <Card>
                <Filters
                    filters={['request_vendor_statuses']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    queryPlaceholder="Search by merchant / customer name"
                />
                <DataTable
                    data={dataList}
                    columns={columns}
                    loading={isLoading}
                    pages={pages}
                    page={page}
                    records={records}
                    handlePageChange={set_page}
                    onSearch={set_q}
                />
            </Card>
        </div>
    );
}
