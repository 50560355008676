import React from 'react';
import {
    ViewHeader,
    Link,
    Card,
    DataTable,
    StatusToggler,
    Filters,
    Sop
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import defaultLogo from '@assets/img/default.jpg';
import { loadCategoriesData, updateSingleRow } from '@slices/categories.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function CategoriesListView() {
    const CategoriesSlice = useSelector((state) => state.CategoriesSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [filters, set_filters] = React.useState({});

    useDidMountEffect(() => {
        dispatch(loadCategoriesData({ ...filters, page }));
    }, [filters, page]);

    const columns = [
        {
            name: 'Image',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo category-logo">
                    <img src={row.image || defaultLogo} alt="Logo" />
                </div>
            )
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/categories/${row.id}`} className="tr-name">
                    <h5>{row.name_ar}</h5>
                </Link>
            )
        },
        {
            name: 'Products count',
            selector: 'products_count',
            sortable: false,
            center: true
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row.is_active}
                    path={`/category/${row.id}/status`}
                    callback={({ id, status }) => {
                        dispatch(updateSingleRow({ id, is_active: status }));
                    }}
                />
            )
        },
        {
            name: 'CTA',
            cell: (row) => (
                <>
                    <a
                        href={`${process.env.REACT_APP_STOREFRONT_BASE_URL}/categories/${row.id}`}
                        className="btn btn-sm"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Open CTA
                    </a>
                </>
            )
        }
    ];

    const Menu = ({ data }) => {
        return (
            <ul>
                {data.map((c, i) => {
                    return (
                        <li key={i}>
                            <Link
                                href={`/categories/${c.id}`}
                                className="tr-name"
                            >
                                {c.name_ar}
                            </Link>
                            &nbsp; - &nbsp;
                            <a
                                // eslint-disable-next-line max-len
                                href={`${process.env.REACT_APP_STOREFRONT_BASE_URL}/categories/${c.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Open CTA
                            </a>
                            {!!c.children.length && <Menu data={c.children} />}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const ExpandableRowsComponent = ({ data }) => (
        <>
            <ul className="directory-list">
                <li>
                    <Link href={`/categories/${data.id}`} className="tr-name">
                        {data.name_ar}
                    </Link>
                    <Menu data={data.children} />
                </li>
            </ul>
        </>
    );

    return (
        <div className="categories-list-view">
            <ViewHeader title="Categories">
                <Sop id="29983" />
                <Link href="/categories/add" className="btn btn-primary">
                    New category
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    filters={[
                        'status',
                        'sort_by'
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'is_active',
                        sort_direction: 'desc',
                    }}
                    sort_options={[
                        {
                            label: 'ID (DESC)',
                            value: 'id__desc',
                            direction: 'desc'
                        },
                        {
                            label: 'ID (ASC)',
                            value: 'id__asc',
                            direction: 'asc'
                        },
                        {
                            label: 'Is active (DESC)',
                            value: 'is_active__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Is active (ASC)',
                            value: 'is_active__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={CategoriesSlice.categories}
                    pages={CategoriesSlice.categories_pages}
                    page={page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={CategoriesSlice.categories_loading}
                    expandableRowsComponent={<ExpandableRowsComponent />}
                    expandableRowDisabled={(row) => !row.children.length}
                />
            </Card>
        </div>
    );
}
