import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@components';
import { stringToSlug } from '@utils';

export default function Slug({
    value,
    setValue,
    label = '',
    placeholder,
    prefix
}) {
    return (
        <Input 
            label={label}
            placeholder={placeholder}
            setValue={setValue}
            value={stringToSlug(value)}
            prefix={prefix}
            type="text"
            note="Only english characters and numbers are allowed!"
        />
    );
}

Slug.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),
    setValue: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
};