import React from 'react';
import DeleteIcon from '@assets/icons/delete-icon.svg';

export default function DeleteBtn({ onClick }) {
    return (
        <div className="icon-btn delete-btn" onClick={onClick}>
            <img src={DeleteIcon} alt="" />
        </div>
    );
}
