import React from 'react';
import { Modal } from '@components';
import { POST } from '@utils';
import { loadOrderData, loadOrderPayments } from '../../store/slices/orders.slice';
import { useDispatch } from 'react-redux';

const ConfirmCODModal = ({ id }) => {
    const dispatch = useDispatch();
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);

    const onSubmit = async (close) => {
        set_loading(true);
        const res = await POST({
            endpoint: `/order/${id}/confirm-cash-on-delivery-payment`
        });
        set_loading(false);
        set_errors([]);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            close();
            dispatch(loadOrderPayments(id));
            dispatch(loadOrderData(id));
        }
    };

    return (
        <Modal
            title="Confirm COD payment"
            name="confirm_cod_payment_modal"
            onSubmit={onSubmit}
            loading={loading}
        >
            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
            <p>Do you want to confirm that the order amount is paid?</p>
        </Modal>
    );
};

export default ConfirmCODModal;
