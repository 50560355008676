import React from 'react';

export default function Checkbox({
    onChange,
    checked,
    label = '',
    onClick = () => {},
    disabled
}) {
    return (
        <>
            <label className="custome-checkbox" onClick={onClick}>
                {label}
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={({ target }) => onChange(target.checked)}
                    disabled={disabled}
                />
                <span className="checkmark"></span>
            </label>
        </>
    );
}
