import { Comments } from '@components';
import useMutation from '@hooks/useMutation';
import useQuery from '@hooks/useQuery';
import { POST } from '@utils';
import { useParams } from 'react-router-dom';

const PoInvoiceComments = () => {
    const { id } = useParams();
    const { data: { data: comments } = { data: { data: [] } }, refetch } = useQuery(
        `/vendors/invoice-requests/${id}/comments`,
    );
    const { mutate } = useMutation(
        (data) => POST({ endpoint: `/vendors/invoice-requests/${id}/comments`, data }),
        { onSuccess: () => refetch() },
    );

    const handleCreateComment = (comment) => mutate({ comment });

    return <Comments title="Payout Comments" comments={comments} onSubmit={handleCreateComment} />;
};

export default PoInvoiceComments;
