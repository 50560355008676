import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Form } from '@components';
import PropTypes from 'prop-types';

const components = {
    DropdownIndicator: null,
};

export default function CreatableTextSelect({
    isMulti = true,
    value,
    setValue,
    label = '',
    preventDuplication = false,
    placeholder = 'Type something and press enter...',
}) {
    const createOption = (label) => ({
        label,
        value: label,
    });

    const [inputValue, setInputValue] = React.useState('');

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
        case 'Enter':
        case 'Tab':
            if (
                isMulti && preventDuplication &&
          		value?.map((item) => item.value)?.includes(inputValue)
            ) {
                setInputValue('');
                return;
            }
            setValue((prev) =>
                isMulti
                    ? [...prev, createOption(inputValue)]
                    : createOption(inputValue)
            );
            setInputValue('');
            event.preventDefault();
        }
    };

    return (
        <Form.Group controlId={label}>
            {label && <Form.Label>{label}</Form.Label>}
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti={isMulti}
                menuIsOpen={false}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                value={value}
            />
        </Form.Group>
    );
}

CreatableTextSelect.propTypes = {
    value: PropTypes.any.isRequired,
    setValue: PropTypes.func.isRequired,
    label: PropTypes.string,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    preventDuplication: PropTypes.bool,
};
