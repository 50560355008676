import React, { useRef } from 'react';
import { Card } from '@components';
import submitCommentIcon from '@assets/icons/submit-comment.svg';
import { randomId, formatDate } from '@utils';
import styles from './Comments.module.scss';

const Comments = ({
    comments,
    onSubmit,
    title = 'Comments',
    noDateMessage = 'No comments yet!',
    textareaPlaceholder = 'Leave a comment',
    listStyle,
}) => {
    const [comment, setComment] = React.useState('');
    const [uuid, setUuid] = React.useState('');
    const [sending, setSending] = React.useState(false);
    const commentsList = useRef(null);

    const scrollComments = () => {
        const elm = commentsList.current;
        elm.scrollTop = elm.scrollHeight;
    };

    React.useEffect(() => {
        setUuid(randomId());
    }, []);

    return (
        <Card title={title} className={styles.comments}>
            {comments && comments.length > 0 ? (
                <div className={styles.comments__list} style={listStyle} ref={commentsList}>
                    {comments.map((c, i) => (
                        <div className={styles.comments__item} key={i}>
                            <div className={styles.avatar}>JD</div>
                            <div className={styles.content}>
                                <div className="flex__jc-between">
                                    <h4>{c.user || c.admin?.name}</h4>
                                    <span>{formatDate(c.date || c.created_at)}</span>
                                </div>
                                <p>{c.comment}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="alert alert-light text-center" role="alert" style={listStyle}>
                    {noDateMessage}
                </div>
            )}
            <div className={styles.addComment}>
                <textarea
                    disabled={sending}
                    placeholder={textareaPlaceholder}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <button
                    disabled={sending}
                    onClick={async () => {
                        setSending(true);
                        await onSubmit(comment, uuid);
                        setComment('');
                        setUuid(randomId());
                        setSending(false);
                        scrollComments();
                    }}
                >
                    <img src={submitCommentIcon} alt="Submit" />
                </button>
            </div>
        </Card>
    );
};

export default Comments;
