import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import VendorsAcquisitionsListView from './MerchantsAcquisitionsListView';
import VendorsAcquisitionsAddView from './MerchantsAcquisitionsAddView';
import { Helmet } from 'react-helmet';

export default function VendorAcquisitionsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/acquisitions/list">
                <Helmet>
                    <title>Merchant Acquisitions | Disty Admin Dashboard</title>
                </Helmet>
                <VendorsAcquisitionsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/acquisitions/add">
                <Helmet>
                    <title>Add Merchant Acquisitions | Disty Admin Dashboard</title>
                </Helmet>
                <VendorsAcquisitionsAddView />
            </ProtectedRoute>
        </Switch>
    );
}