import React from 'react';
import {
    Row,
    Input,
    Col,
    Select,
    DateRangePicker,
    ViewHeader,
    Card,
    // ButtonGroup,
    // DropdownButton,
    // Dropdown,
    Modal,
    CustomPrompt,
    ShowErrors,
    Sop,
    CriticalActionButton,
} from '@components';
import { PUT, DELETE, randomId, confirmationMessage } from '@utils';
import Slider from 'react-input-slider';
import { Redirect, useParams } from 'react-router';
// import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadDiscountData, setDiscount } from '@slices/discounts.slice';
import MemoizedEntitiesSelectors from './EntitiesSelectors';

export default function DiscountsAddView() {
    const { id } = useParams();
    const DiscountsSlice = useSelector((state) => state.DiscountsSlice);
    const [loading, set_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const [deleted, set_deleted] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [submitting, set_submitting] = React.useState(false);
    const idempotencyKey = randomId();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setDiscount({}));
        dispatch(loadDiscountData(id));

        return () => {
            set_default_discount_value_populated(false);
        };
    }, []);

    React.useEffect(() => {
        if (DiscountsSlice.discount.id) {
            discount_type.onChange(
                discount_type.options.find(
                    (o) => o.value === DiscountsSlice.discount.discount_type,
                ),
            );
            calculating_type.onChange(
                calculating_type.options.find(
                    (o) => o.value === DiscountsSlice.discount.calculation_type,
                ),
            );
            discount_value.setValue(DiscountsSlice.discount.discount_value);
            setTimeout(() => {
                set_ratio(
                    DiscountsSlice.discount.discount_value -
                        DiscountsSlice.discount.app_discount_ratio,
                );
                set_app_discount_ratio(DiscountsSlice.discount.app_discount_ratio);
                set_vendor_discount_ratio(DiscountsSlice.discount.vendor_discount_ratio);
                set_default_discount_value_populated(true);
            }, 500);

            name_ar.setValue(DiscountsSlice.discount.name_ar);
            name_en.setValue(DiscountsSlice.discount.name_en);
            max_discount_amount.setValue(DiscountsSlice.discount.max_discount_amount);
            min_order_applied_products.setValue(DiscountsSlice.discount.min_order_applied_products);
            max_order_amount.setValue(DiscountsSlice.discount.max_order_amount);
            min_order_amount.setValue(DiscountsSlice.discount.min_order_amount);
            coupon_code.setValue(DiscountsSlice.discount.coupon_code);
            customer_usage_limit.setValue(DiscountsSlice.discount.customer_usage_limit);
            usage_limit.setValue(DiscountsSlice.discount.usage_limit);
            set_start_date(new Date(DiscountsSlice.discount.starts_at));
            set_end_date(new Date(DiscountsSlice.discount.ends_at));

            set_products_apply_method(DiscountsSlice.discount.products_apply_method);
            set_vendors_apply_method(DiscountsSlice.discount.vendors_apply_method);
            set_customers_apply_method(DiscountsSlice.discount.customers_apply_method);
            set_collections_apply_method(DiscountsSlice.discount.collections_apply_method);
            set_selected_products(DiscountsSlice.discount?.products);
            set_selected_vendors(DiscountsSlice.discount?.vendors);
            set_selected_customers(DiscountsSlice.discount?.customers);
            set_selected_collections(DiscountsSlice.discount?.collections);
        }
    }, [DiscountsSlice.discount.id]);

    const [name_ar, set_name_ar] = React.useState({
        label: 'Discount Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),    });
    const [name_en, set_name_en] = React.useState({
        label: 'Discount Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),    });
    const [discount_type, set_discount_type] = React.useState({
        label: 'Discount type',
        options: [
            { value: 'automated', label: 'Automated' },
            { value: 'coupon', label: 'Coupon' },
            { value: 'system_coupon', label: 'System coupon' },
        ],
        value: [],
        getOptionValue: (option) => option.value,
        onChange: (val) => {
            if (val.value === 'automated') {
                calculating_type.onChange({ value: 'percentage', label: 'Percentage' });
                set_calculating_type((prev) => ({
                    ...prev,
                    disabled: true,
                }));
                calculating_type.disabled = true;
            } else {
                calculating_type.onChange(null);
                set_calculating_type((prev) => ({
                    ...prev,
                    disabled: false,
                }));
                calculating_type.disabled = true;
            }
            set_discount_type((prev) => ({ ...prev, value: val }));
        },
    });
    const [calculating_type, set_calculating_type] = React.useState({
        label: 'Calculating type',
        options: [
            { value: 'percentage', label: 'Percentage' },
            { value: 'fixed', label: 'Fixed amount' },
        ],
        value: [],
        getOptionValue: (option) => option.value,
        onChange: (val) => set_calculating_type((prev) => ({ ...prev, value: val })),
    });
    const [discount_value, set_discount_value] = React.useState({
        label: 'Discount value',
        value: '',
        type: 'number',
        acceptDecimal: true,
        setValue: (val) => {
            set_discount_value({ ...discount_value, value: val });
        },
    });
    const [max_discount_amount, set_max_discount_amount] = React.useState({
        label: 'Max discount amount',
        value: '',
        type: 'number',
        setValue: (val) => set_max_discount_amount({ ...max_discount_amount, value: val }),
    });
    const [min_order_applied_products, set_min_order_applied_products] = React.useState({
        label: 'Min order applied products',
        value: '',
        type: 'number',
        setValue: (val) =>
            set_min_order_applied_products({
                ...min_order_applied_products,
                value: val,
            }),
    });
    const [max_order_amount, set_max_order_amount] = React.useState({
        label: 'Max order amount',
        value: '',
        type: 'number',
        setValue: (val) => set_max_order_amount({ ...max_order_amount, value: val }),
    });
    const [min_order_amount, set_min_order_amount] = React.useState({
        label: 'Min order amount',
        value: '',
        type: 'number',
        setValue: (val) => set_min_order_amount({ ...min_order_amount, value: val }),
    });
    const [coupon_code, set_coupon_code] = React.useState({
        label: 'Coupon code',
        value: '',
        setValue: (val) => set_coupon_code({ ...coupon_code, value: val }),
    });
    const [customer_usage_limit, set_customer_usage_limit] = React.useState({
        label: 'Customer usage limit',
        type: 'number',
        value: null,
        setValue: (val) => set_customer_usage_limit({ ...customer_usage_limit, value: val }),
    });
    const [usage_limit, set_usage_limit] = React.useState({
        label: 'Usage limit',
        type: 'number',
        value: null,
        setValue: (val) => set_usage_limit({ ...usage_limit, value: val }),
    });
    const [start_date, set_start_date] = React.useState(new Date());
    const [end_date, set_end_date] = React.useState(new Date());

    const [products_apply_method, set_products_apply_method] = React.useState(null);
    const [selected_products, set_selected_products] = React.useState([]);

    const [vendors_apply_method, set_vendors_apply_method] = React.useState(null);
    const [selected_vendors, set_selected_vendors] = React.useState([]);

    const [customers_apply_method, set_customers_apply_method] = React.useState(null);
    const [selected_customers, set_selected_customers] = React.useState([]);

    const [collections_apply_method, set_collections_apply_method] = React.useState(null);
    const [selected_collections, set_selected_collections] = React.useState([]);

    const [default_discount_value_populated, set_default_discount_value_populated] =
        React.useState(false);
    const [ratio, set_ratio] = React.useState(0);
    const [vendor_discount_ratio, set_vendor_discount_ratio] = React.useState(0);
    const [app_discount_ratio, set_app_discount_ratio] = React.useState(0);

    React.useEffect(() => {
        if (default_discount_value_populated) {
            set_ratio(discount_value.value / 2);
            set_app_discount_ratio(discount_value.value / 2);
            set_vendor_discount_ratio(discount_value.value / 2);
        }
    }, [discount_value.value]);

    React.useEffect(() => {
        set_app_discount_ratio(discount_value.value - ratio);
        set_vendor_discount_ratio(ratio);
    }, [ratio]);
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        const emojiAndEnglish = /[\u{1F600}-\u{1F6FF}|\u{1F300}-\u{1F5FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{20A0}-\u{20CF}|\u{1F1E6}-\u{1F1FF}A-Za-z]/u;
        const emojiAndArabic = /[\u{1F600}-\u{1F6FF}|\u{1F300}-\u{1F5FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{20A0}-\u{20CF}|\u{1F1E6}-\u{1F1FF}|\u0600-\u06FF]/u;

        if (!name_ar.value || emojiAndEnglish.test(name_ar.value))
            newErrors.name_ar =
                'The name  (arabic) field is required and must not contain emojis or English letters.';
        if (!name_en.value || emojiAndArabic.test(name_en.value))
            newErrors.name_en =
                'The name (english) field is required and must not contain emojis or arabic letters.';
        if (!discount_type.value || discount_type.value.length === 0)
            newErrors.discount_type = 'The discount type field is required';
        if (!discount_value.value)
            newErrors.discount_value = 'The discount value field is required';
        if (!calculating_type.value || calculating_type.value.length === 0)
            newErrors.calculating_type = 'The calculation type field is required.';
        if (
            discount_type.value?.value === 'system_coupon' && !coupon_code.value ||
            (discount_type.value?.value === 'coupon' && !coupon_code.value)
        ) {
            newErrors.coupon_code =
                'The coupon code field is required when discount type is system_coupon  or coupon';
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name_en, name_ar, discount_type, discount_value, calculating_type, ratio, coupon_code]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const res = await PUT({
                endpoint: `/marketing/discount/${id}`,
                data: {
                    name_ar: name_ar.value,
                    name_en: name_en.value,
                    max_discount_amount: max_discount_amount.value,
                    min_order_applied_products: min_order_applied_products.value,
                    max_order_amount: max_order_amount.value,
                    min_order_amount: min_order_amount.value,
                    discount_type: discount_type.value?.value,
                    calculation_type: calculating_type.value?.value,
                    discount_value: discount_value.value,
                    ...(discount_type?.value.value === 'coupon' ||
                    discount_type?.value.value === 'system_coupon'
                        ? {
                              coupon_code: coupon_code.value,
                              customer_usage_limit: customer_usage_limit.value,
                              usage_limit: usage_limit.value,
                              ...(customers_apply_method
                                  ? {
                                        customers: selected_customers.map((p) => p.id),
                                    }
                                  : {}),
                          }
                        : {}),
                    starts_at: start_date.toISOString().slice(0, 10).split('-').reverse().join('-'),
                    ends_at: end_date.toISOString().slice(0, 10).split('-').reverse().join('-'),

                    app_discount_ratio: discount_value.value - ratio,
                    vendor_discount_ratio: ratio,

                    products_apply_method: products_apply_method,
                    ...(products_apply_method
                        ? {
                              products: selected_products.map((p) => p.id),
                          }
                        : {}),

                    vendors_apply_method: vendors_apply_method,
                    ...(vendors_apply_method
                        ? {
                              vendors: selected_vendors.map((p) => p.id),
                          }
                        : {}),
                    customers_apply_method:
                        discount_type?.value.value === 'coupon' ? customers_apply_method : null,

                    collections_apply_method: collections_apply_method,
                    ...(collections_apply_method
                        ? {
                              collections: selected_collections.map((c) => c.id),
                          }
                        : {}),
                },
                headers: {
                    'Idempotency-Key': idempotencyKey,
                },
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_updated(res?.data.id);
            }
        }
    };

    if (updated) {
        return <Redirect to="/discounts/list" />;
    }

    const deleteDiscount = async () => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/marketing/discount/${id}`,
        });
        set_submitting(false);

        if (res?.status_code === 200 || res?.status_code === 201) {
            set_deleted(true);
        }
    };

    if (deleted) {
        return <Redirect to="/discounts/list" />;
    }

    if (DiscountsSlice.discount_loading) {
        return 'loading...';
    }

    return (
        <div className="discounts-form-view">
            <CustomPrompt>
                <ViewHeader
                    title={`Updating discount ${DiscountsSlice.discount.name_ar}`}
                    renderBackArrow
                >
                    <div className="flex__ai-center">
                        <Sop id="25343" />
                        {/* <ButtonGroup>
                            <DropdownButton
                                as={ButtonGroup}
                                title="Actions"
                                id="bg-nested-dropdown"
                                variant="light"
                            >
                                <Dropdown.Item
                                    eventKey="2"
                                    onClick={() =>
                                        dispatch(
                                            setModalStatus({
                                                modal: 'single_discount_view__delete_modal',
                                                status: true,
                                            })
                                        )
                                    }
                                >
                                    Delete
                                </Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup> */}

                        <CriticalActionButton
                            onSubmit={() => {
                                DiscountsSlice?.discount?.status
                                    ? submit()
                                    : confirmationMessage(submit);
                            }}
                        >
                            Save changes
                        </CriticalActionButton>
                    </div>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        <h3 className="divider-title">General info</h3>
                        <Row>
                            <Col lg={6}>
                                <Input {...name_ar} />
                                {loading && validation_errors.name_ar && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.name_ar}
                                    </p>
                                )}
                            </Col>
                            <Col lg={6}>
                                <Input {...name_en} />
                                {loading && validation_errors.name_en && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.name_en}
                                    </p>
                                )}
                            </Col>
                        </Row>

                        <h3 className="divider-title">Discount type</h3>
                        <Row>
                            <Col lg={6}>
                                <Select {...discount_type} />
                                {loading && validation_errors.discount_type && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.discount_type}
                                    </p>
                                )}
                                <Input
                                    {...customer_usage_limit}
                                    disabled={
                                        discount_type?.value?.value !== 'coupon' &&
                                        discount_type?.value?.value !== 'system_coupon'
                                    }
                                />

                                <Select {...calculating_type} />
                                {loading && validation_errors.calculating_type && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.calculating_type}
                                    </p>
                                )}
                                <DateRangePicker
                                    label="Period"
                                    startDate={start_date}
                                    endDate={end_date}
                                    setStartDate={set_start_date}
                                    setEndDate={set_end_date}
                                />
                            </Col>
                            <Col lg={6}>
                                <Input
                                    {...coupon_code}
                                    disabled={
                                        discount_type?.value?.value !== 'coupon' &&
                                        discount_type?.value?.value !== 'system_coupon'
                                    }
                                />
                                {loading && validation_errors.coupon_code && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.coupon_code}
                                    </p>
                                )}
                                <Input
                                    {...usage_limit}
                                    disabled={
                                        discount_type?.value?.value !== 'coupon' &&
                                        discount_type?.value?.value !== 'system_coupon'
                                    }
                                />

                                <Input {...discount_value} />
                                {loading && validation_errors.discount_value && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.discount_value}
                                    </p>
                                )}

                                <label className="flex__jc-between">
                                    <span>Disty&lsquo;s Ratio ({app_discount_ratio})</span>
                                    <span>Merchant&lsquo;s Ratio ({vendor_discount_ratio})</span>
                                </label>
                                <Slider
                                    axis="x"
                                    x={ratio}
                                    onChange={({ x }) => set_ratio(x)}
                                    disabled={!discount_value?.value}
                                    xmin={0}
                                    xmax={discount_value?.value || 0}
                                    xstep={0.01}
                                    styles={{
                                        track: {
                                            width: '100%',
                                        },
                                    }}
                                />
                            </Col>
                        </Row>

                        <h3 className="divider-title">Discount Min/Max values</h3>
                        <Row>
                            <Col lg={6}>
                                <Input {...max_discount_amount} />
                                <Input {...min_order_applied_products} />
                            </Col>
                            <Col lg={6}>
                                <Input {...max_order_amount} />
                                <Input {...min_order_amount} />
                            </Col>
                        </Row>

                        <h3 className="divider-title">Applied to</h3>
                        <MemoizedEntitiesSelectors
                            products_apply_method={products_apply_method}
                            set_products_apply_method={set_products_apply_method}
                            selected_products={selected_products}
                            set_selected_products={set_selected_products}
                            vendors_apply_method={vendors_apply_method}
                            set_vendors_apply_method={set_vendors_apply_method}
                            selected_vendors={selected_vendors}
                            set_selected_vendors={set_selected_vendors}
                            customers_apply_method={customers_apply_method}
                            set_customers_apply_method={set_customers_apply_method}
                            selected_customers={selected_customers}
                            set_selected_customers={set_selected_customers}
                            use_customers_filters={true}
                            disable_customers_selection={discount_type.value.value === 'automated'}
                            collections_apply_method={collections_apply_method}
                            set_collections_apply_method={set_collections_apply_method}
                            selected_collections={selected_collections}
                            set_selected_collections={set_selected_collections}
                        />
                    </div>
                </Card>

                <Modal
                    title="Delete"
                    name="single_discount_view__delete_modal"
                    variant="danger"
                    onSubmit={(closeModal) => {
                        deleteDiscount();
                        closeModal('single_discount_view__delete_modal');
                    }}
                    loading={submitting}
                />
            </CustomPrompt>
        </div>
    );
}
