import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const CollectionsSlice = createSlice({
    name: 'collections',
    initialState: {
        collections: [],
        collections_pages: null,
        collections_records: null,
        collection: {},
        collections_loading: true,
        collection_loading: true,
    },
    reducers: {
        setCollections(state, { payload }) {
            state.collections = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.collections.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.collections[index] = {
                    ...state.collections[index],
                    ...payload,
                };
            }
        },
        setCollection(state, { payload }) {
            state.collection = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setCollectionsTotalPages(state, { payload }) {
            state.collections_pages = payload;
        },
        setCollectionsRecords(state, { payload }) {
            state.collections_records = payload;
        },
    },
});

// export actions
export const {
    setCollection,
    updateSingleRow,
    setCollections,
    setLoading,
    setCollectionsTotalPages,
    setCollectionsRecords,
} = CollectionsSlice.actions;

export const loadCollectionsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'collections_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/product-collection?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setCollections(res?.data || []));
    if(res?.meta){
        dispatch(setCollectionsTotalPages(res?.meta?.last_page || 0));
        dispatch(setCollectionsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'collections_loading',
            value: false,
        })
    );
};

export const loadCollectionData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'collection_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/product-collection/${id}`,
    });
    dispatch(setCollection(res?.data || {}));
    dispatch(
        setLoading({
            key: 'collection_loading',
            value: false,
        })
    );
};

export default CollectionsSlice.reducer;
