/* eslint-disable max-len */
import React from 'react';
import { set_is_full_screen_view } from '../../store/slices/ui.slice';
import { useDispatch } from 'react-redux';
import GoogleMapsComponent from './GoogleMapsComponent';
import { randomId, GET } from '@utils';
import { Radio, Link } from '@components';

function OrdersMap() {
    const dispatch = useDispatch();
    const [suborders, set_suborders] = React.useState([]);
    const [suborders_with_no_coords, set_suborders_with_no_coords] = React.useState([]);
    const [
        suborders_with_coords_ready_for_pickup_grouped_by_vendor,
        set_suborders_with_coords_ready_for_pickup_grouped_by_vendor,
    ] = React.useState({});
    const [
        suborders_with_coords_ready_for_dispatch_grouped_by_customer,
        set_suborders_with_coords_ready_for_dispatch_grouped_by_customer,
    ] = React.useState({});
    const [suborders_stats, set_suborders_stats] = React.useState({
        total: 0,
        ready_for_pickup: 0,
        ready_for_dispatch: 0,
    });
    const [selected_suborders_status_to_filter, set_selected_suborders_status_to_filter] =
        React.useState('all');

    const formatSuborders = (orders) => {
        return orders.reduce((acc, order) => {
            order?.sub_orders.slice(0, 10).forEach((s) => {
                const { state, vendor } = s;
                let coords = null;

                if (state.code === 256 && order.shipping_address) {
                    // if ready for dispatch
                    // customer coords
                    coords = {
                        lat: +order.shipping_address?.latitude,
                        lng: +order.shipping_address?.longitude,
                    };
                } else if (state.code === 16 && vendor?.address) {
                    // if ready for pickup
                    // vendor address coords
                    coords = {
                        lat: +vendor?.address?.latitude,
                        lng: +vendor?.address?.longitude,
                    };
                }
                acc.push({
                    parent_order_id: order.id,
                    parent_order: order,
                    customer: order.customer,
                    shipping_address: order.shipping_address,
                    coords,
                    ...s,
                });
            });
            return acc;
        }, []);
    };

    const fetchOrderData = async () => {
        const res = await GET({ endpoint: '/order/map-grid' });
        if (res?.status_code === 200) {
            const suborders = formatSuborders(res?.data || []);
            set_suborders(suborders);
        }
    };

    const filterByStatus = (suborders) => {
        if (selected_suborders_status_to_filter === 'all') return suborders;

        return suborders.filter((o) => selected_suborders_status_to_filter === o.state.code);
    };

    React.useEffect(() => {
        fetchOrderData();
        dispatch(set_is_full_screen_view(true));

        return () => {
            dispatch(set_is_full_screen_view(false));
        };
    }, []);

    React.useEffect(() => {
        const suborders_filtered = filterByStatus(suborders);

        const suborders_with_no_coords = suborders_filtered.filter((s) => !s.coords);
        const suborders_with_coords = suborders_filtered.filter((s) => s.coords);
        const suborders_with_coords_ready_for_pickup = suborders_with_coords.filter(
            (s) => s.state.code === 16,
        );
        const suborders_with_coords_ready_for_dispatch = suborders_with_coords.filter(
            (s) => s.state.code === 256,
        );

        const suborders_with_coords_ready_for_pickup_grouped_by_vendor =
            suborders_with_coords_ready_for_pickup.reduce((acc, s) => {
                const vendor_id = s.vendor?.id;
                if (!acc[vendor_id]) {
                    acc[vendor_id] = {
                        vendor: s.vendor,
                        coords: {
                            lat: +s.vendor?.address?.latitude,
                            lng: +s.vendor?.address?.longitude,
                        },
                        suborders: [],
                    };
                }
                acc[vendor_id].suborders.push(s);
                return acc;
            }, {});
        const suborders_with_coords_ready_for_dispatch_grouped_by_customer =
            suborders_with_coords_ready_for_dispatch.reduce((acc, s) => {
                const customer_id = s.customer?.id;
                if (!acc[customer_id]) {
                    acc[customer_id] = {
                        customer: s.customer,
                        coords: {
                            lat: +s.parent_order.shipping_address?.latitude,
                            lng: +s.parent_order.shipping_address?.longitude,
                        },
                        suborders: [],
                    };
                }
                acc[customer_id].suborders.push(s);
                return acc;
            }, {});

        set_suborders_with_no_coords(suborders_with_no_coords);
        set_suborders_with_coords_ready_for_pickup_grouped_by_vendor(
            suborders_with_coords_ready_for_pickup_grouped_by_vendor,
        );
        set_suborders_with_coords_ready_for_dispatch_grouped_by_customer(
            suborders_with_coords_ready_for_dispatch_grouped_by_customer,
        );
        set_suborders_stats({
            total: suborders.filter((s) => s.coords).length,
            ready_for_pickup: suborders.filter((s) => s.coords).filter((s) => s.state.code === 16)
                .length,
            ready_for_dispatch: suborders
                .filter((s) => s.coords)
                .filter((s) => s.state.code === 256).length,
        });
    }, [selected_suborders_status_to_filter, suborders]);

    return (
        <div className="orders-map-view">
            <Link href="/orders/list" className="back-btn">
                Go back to Dashboard
            </Link>

            <h1>Orders Map</h1>

            <GoogleMapsComponent
                data={{
                    ...suborders_with_coords_ready_for_dispatch_grouped_by_customer,
                    ...suborders_with_coords_ready_for_pickup_grouped_by_vendor,
                }}
            />

            <div className="map-filters">
                Filter by suborder status : &nbsp;
                <Radio
                    name="suborder-status"
                    value={'all'}
                    option={`${selected_suborders_status_to_filter}`}
                    label={`All (${suborders_stats.total} order)`}
                    onChange={() => {
                        set_selected_suborders_status_to_filter('all');
                    }}
                />
                <Radio
                    name="suborder-status"
                    value={'256'}
                    option={`${selected_suborders_status_to_filter}`}
                    label={`Ready for dispatch (${suborders_stats.ready_for_dispatch} order)`}
                    onChange={(val) => {
                        set_selected_suborders_status_to_filter(+val);
                    }}
                />
                <Radio
                    name="suborder-status"
                    value={'16'}
                    option={`${selected_suborders_status_to_filter}`}
                    label={`Ready for pickup (${suborders_stats.ready_for_pickup} order)`}
                    onChange={(val) => {
                        set_selected_suborders_status_to_filter(+val);
                    }}
                />
            </div>

            <br />
            <h1>
                <strong>{filterByStatus(suborders_with_no_coords).length}</strong> Suborders with no
                coords
            </h1>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Suborder ID</th>
                        <th>Suborder state</th>
                        <th>Retailer</th>
                        <th>Vendor</th>
                    </tr>
                </thead>

                <tbody>
                    {filterByStatus(suborders_with_no_coords)?.map((suborder, i) => (
                        <tr key={randomId()}>
                            <td>{i + 1}</td>
                            <td>
                                <Link href={'/orders/' + suborder.parent_order_id} target="_blank">
                                    {suborder.pretty_id}
                                </Link>
                            </td>
                            <td>{suborder.state.description}</td>
                            <td>
                                <Link href={'/customers/' + suborder.customer.id} target="_blank">
                                    {suborder.customer.name}
                                </Link>
                            </td>
                            <td>
                                <Link href={'/merchants/' + suborder.vendor.id} target="_blank">
                                    {suborder.vendor.company_name}
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default OrdersMap;
