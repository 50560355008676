/* eslint-disable max-len */
import React from 'react';
import {
    DataTable,
    Button,
    Modal,
    Input,
    ShowErrors
} from '@components';
import { PUT, randomId } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadNotificationSettingsData } from '@slices/settings.slice';

const NotificationSettingsTab = () => {
    const SettingsSlice = useSelector((state) => state.SettingsSlice);
    const dispatch = useDispatch();
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [stock_quantity_alert, set_stock_quantity_alert] = React.useState({
        label: 'Stock Quantity Alert',
        value: '',
        setValue: (val) => set_stock_quantity_alert({ ...stock_quantity_alert, value: val }),
    });
    const [vendor_emails_notification_start_hour, 
        set_vendor_emails_notification_start_hour] = React.useState({
        label: 'Vendor Emails Notification Start Hour',
        value: '',
        setValue: (val) => set_vendor_emails_notification_start_hour(
            { ...vendor_emails_notification_start_hour, value: val }),
    });
    const [vendor_emails_notification_end_hour, 
        set_vendor_emails_notification_end_hour] = React.useState({
        label: 'Vendor Emails Notification End Hour',
        value: '',
        setValue: (val) => set_vendor_emails_notification_end_hour(
            { ...vendor_emails_notification_end_hour, value: val }),
    });

    const [product_push_notification_delay_after_hours, 
        set_product_push_notification_delay_after_hours] = React.useState({
        label: 'product push notification delay after hours',
        value: '',
        setValue: (val) => set_product_push_notification_delay_after_hours(
            { ...product_push_notification_delay_after_hours, value: val }),
    });

    const [product_push_notification_delay_after_minutes, 
        set_product_push_notification_delay_after_minutes] = React.useState({
        label: 'product push notification delay after minutes',
        value: '',
        setValue: (val) => set_product_push_notification_delay_after_minutes(
            { ...product_push_notification_delay_after_minutes, value: val }),
    });
    React.useEffect(() => {
        dispatch(loadNotificationSettingsData());
    }, [rerender]);

    const submit = async (closeModal) => {
        set_loading(true);
        set_errors([]);

        const res = await PUT({
            endpoint: '/notifications/notification-settings',
            data: {
                stock_quantity_alert: stock_quantity_alert?.value,
                vendor_emails_notification_start_hour: 
                vendor_emails_notification_start_hour?.value,
                vendor_emails_notification_end_hour: 
                vendor_emails_notification_end_hour?.value,
                product_push_notification_delay_after_hours: product_push_notification_delay_after_hours?.value ,
                product_push_notification_delay_after_minutes : product_push_notification_delay_after_minutes?.value

            }
        });
        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
            set_loading(false);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('edit_notification_settings__modal');
            set_rerender(randomId());
        }
    };

    const data = [
        {
            key: 'Stock Quantity Alert',
            value: SettingsSlice?.notificationSettings?.stock_quantity_alert,
        },
        {
            key: 'Invoice Request Receipt Notification Start Hour',
            value: SettingsSlice?.notificationSettings?.
                vendor_emails_notification_start_hour,
        },
        {
            key: 'Invoice Request Receipt Notification End Hour',
            value: SettingsSlice?.notificationSettings?.
                vendor_emails_notification_end_hour,
        },
        {
            key: 'product push notification delay after hours',
            value: SettingsSlice?.notificationSettings?.
                product_push_notification_delay_after_hours,
        },
        {
            key: 'product push notification delay after minutes',
            value: SettingsSlice?.notificationSettings?.
                product_push_notification_delay_after_minutes,
        },
    ];

    const columns = [
	    {
            name: 'Setting',
            cell: (row) => row?.key
        },
        {
            name: 'Value',
            center: true,
            cell: (row) => row?.value
        }
    ];

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                loading={SettingsSlice?.notificationSettings_loading}
            />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        stock_quantity_alert.setValue(
                            SettingsSlice?.notificationSettings?.stock_quantity_alert);
                        vendor_emails_notification_start_hour.setValue(
                            SettingsSlice?.notificationSettings?.
                                vendor_emails_notification_start_hour);
                        vendor_emails_notification_end_hour.setValue(
                            SettingsSlice?.notificationSettings?.
                                vendor_emails_notification_end_hour);

                        product_push_notification_delay_after_hours.setValue(
                            SettingsSlice?.notificationSettings?.
                                product_push_notification_delay_after_hours);

                        product_push_notification_delay_after_minutes.setValue(
                            SettingsSlice?.notificationSettings?.
                                product_push_notification_delay_after_minutes);
                        set_errors([]);
                        dispatch(
                            setModalStatus({
                                modal: 'edit_notification_settings__modal',
                                status: true
                            })
                        );
                    }}
                >
                    Edit Notification settings
                </Button>
            </div>
            <Modal
                title={'Update Notification Settings'}
                variant={'warning'}
                name="edit_notification_settings__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal);
                }}
                loading={loading}
            >

                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
                <Input {...stock_quantity_alert} type='number' />
                <Input {...vendor_emails_notification_start_hour} type='number' />
                <Input {...vendor_emails_notification_end_hour} type='number' />
                <Input {...product_push_notification_delay_after_hours} type='number' />
                <Input {...product_push_notification_delay_after_minutes} type='number' />
            </Modal>
        </>
    );
};

export default NotificationSettingsTab;