import { useEffect, useMemo, useState } from 'react';
import ProductVariantsForm from '../../components/ProductVariantsForm';
import { useDispatch, useSelector } from 'react-redux';
import BaseProductForm from '../../components/BaseProductForm';
import { Button, Checkbox, Modal, ShowErrors } from '@components';
import useUpdateProduct from '../../hooks/useUpdateProduct';
import { setModalStatus } from '@slices/ui.slice';

const VariantsTab = () => {
    const dispatch = useDispatch();
    const [variant_default_values, set_variant_default_values] = useState({});
    const [has_variants, set_has_variants] = useState(false);
    const [initialize_new_variants, set_initialize_new_variants] = useState(false);
    const { variants, ...baseProduct } = useSelector((state) => state.ProductsSlice.product) || {};
    const [variantsState, setVariantsState] = useState([]);
    const [is_valid ,set_is_valid]=useState(false) ;

    const { 
        removedVariants, 
        newVariants, 
        updatedVariants, 
        allVariantsThatWillBeSentToBE 
    } = useMemo(() =>  {
        let removedVariants = [];
        let newVariants = [];
        let updatedVariants = [];
        let allVariantsThatWillBeSentToBE = [];

        Object.entries(variant_default_values).forEach(([key, value])=> {
            const variant = { variantName: key, ...value };

            if(variant.status !== 'removed') allVariantsThatWillBeSentToBE.push(variant);

            if (value.status === 'new') return newVariants.push(variant);
            if (value.status === 'removed') return removedVariants.push(variant);
            return updatedVariants.push(variant);
        });

        return { removedVariants, newVariants, updatedVariants, allVariantsThatWillBeSentToBE };
    }, [variant_default_values]);

    const variantsInDBAndNotExistInState = variants?.filter((variant) =>
        Object.values(variant_default_values).every((removed)=> removed.id !== variant.id));
    const cleanPayload = (payload) => {
        if (Array.isArray(payload)) {
            return payload.map(item => cleanPayload(item));
        } else if (typeof payload === 'object' && payload !== null) {
            const cleanedObject = {};
            Object.keys(payload).forEach(key => {
                if (key !== 'range_percentage') {
                    cleanedObject[key] = cleanPayload(payload[key]);
                }
            });
            return cleanedObject;
        }
        return payload;
    };
    const cleanedValues = cleanPayload(baseProduct);

    const {
        mutate,
        isLoading,
        data: mutationData,
    } = useUpdateProduct(cleanedValues, {
        ...cleanedValues,
        variants:cleanPayload(allVariantsThatWillBeSentToBE)
            .map((variant) => ({ ...variant }))
    }, {
        view_name: 'variants',
    });
    const mutationErrors = {};
    Object.keys(mutationData?.data?.errors || {}).forEach((key) => {
        const value = mutationData?.data?.errors[key];
        // convert key from variants.3.is_active key to variants.4.is_active key
        const newKey = key.replace(
            /variants.\d./,
            (match) => `variants.${parseInt(match.match(/\d/)[0]) + 1}.`,
        );
        mutationErrors[newKey] = value;
    });

    const handleVariantFieldChange = (variantName, data) => {
        if(data.status === 'new') delete data.id;
        set_variant_default_values((prev) => ({ ...prev, [variantName]: data }));
    };

    const handleAllVariantsChange = (newVariants) => {
        set_variant_default_values(newVariants);
    };


    const handleSubmit = async (closeModal) =>{
        try {
            await mutate();
        } finally {
            closeModal();
        }
    };
console.log(is_valid);
    useEffect(() => {
        if (variants?.length > 1) set_has_variants(true);
    }, [variants]);
   
    return (
        <div>
            <Modal
                onSubmit={handleSubmit}
                loading={isLoading}
                name='submit_edit_variants_modal'
                title='Update Product Variants Confirmation'
            >
                {has_variants && 
                (
                    !!removedVariants.length || !!variantsInDBAndNotExistInState.length
                ) && 
                (<div>
                    <h2 className='h6'>You{'\''}re going to remove these variants</h2>
                    <div className='alert alert-danger'>
                        {[...removedVariants, ...variantsInDBAndNotExistInState].map((variant) => (
                            <div
                            key={variant.variantName}
                                className='grid grid-cols-3'
                                >
                                <span>{variant.variantName || variant.name_en}</span>
                                <span>#{variant.id}</span>
                                <span>{variant.price} SAR</span>
                            </div>
                        ))}
                    </div>
                </div>)}
                {!!newVariants.length && (<div>
                    <h2 className='h6'>You{'\''}re going to add these variants</h2>
                    <div className='alert alert-info'>
                        {newVariants.map((variant) => (
                            <div key={variant.variantName} className='grid grid-cols-3'>
                                <span>{variant.variantName}</span>
                                <span></span>
                                <span>{variant.price} SAR</span>
                            </div>
                        ))}
                    </div>
                </div>)}
                {!!updatedVariants.length && (<div>
                    <h2 className='h6'>You{'\''}re going to update these variants</h2>
                    <div className='alert alert-warning'>
                        {updatedVariants.map((variant) => (
                            <div key={variant.variantName} className='grid grid-cols-3'>
                                <span>{variant.variantName}</span>
                                <span>#{variant.id}</span>
                                <span>{variant.price} SAR</span>
                            </div>
                        ))}
                    </div>
                </div>)}
            </Modal>
            <div>
                <ShowErrors errors={mutationErrors} />

                <h3 className="divider-title">Product variants</h3>
                <Checkbox
                    onChange={(checked) => set_has_variants(checked)}
                    checked={has_variants}
                    id="has_variants"
                    label="This product has options, like size or color"
                    disabled={variants?.length > 1}
                />
            </div>

            {variants?.length === 1 && !has_variants ? (
                <BaseProductForm
                    variant="variant"
                    initialValues={{
                        ...baseProduct,
                        ...variants[0],
                        slug: variants[0].slug || '',
                    }}
                    onFieldChange={(data) => handleVariantFieldChange('default', data)}
                    set_is_valid={ set_is_valid }
                    showDiscountRanges={false}
                    />
            ) : (
                <>
                    <Checkbox
                        onChange={(checked) => set_initialize_new_variants(checked)}
                        checked={initialize_new_variants}
                        id="initialize_new_variants"
                        label="Do you want to initialize new variants by the first variant values?"
                    />
                    <ProductVariantsForm
                        title={
                            <div className="flex__jc-between">
                                <h2 className='h2'>Product variant</h2>
                            </div>
                        }
                        defaultVariants={variants || []}
                        onFieldChange={handleVariantFieldChange}
                        baseProductData={{
                            ...baseProduct,
                        }}
                        initialVariantData={initialize_new_variants ? {
                            ...variants[0],
                            status: 'new'
                        } : {}}
                        onVariantChange={(variants) => handleAllVariantsChange(variants)}
                        variantsData={variant_default_values}
                        variants={variantsState}
                        setVariants={setVariantsState}
                    />
                </>
            )}

            <div className="flex__jc-end pt-4">
                <Button 
                    disabled={isLoading || (
                        has_variants &&
                        variants?.length === 1 &&
                        !Object.values(
                            variant_default_values
                        ).some((variant) => variant.status === 'new'
                        )
                    )}
                    onClick={() => {
                        dispatch(setModalStatus({
                            modal: 'submit_edit_variants_modal',
                            status: true,
                        }));
                    }}
                >
                    Submit 
                </Button>
            </div>
        </div>
    );
};

export default VariantsTab;
