import React from 'react';
import { obj2formdata, randomId, POST, DELETE, confirmationMessage } from '@utils';
import { useSelector } from 'react-redux';
import { Input, CriticalActionButton } from '@components';
import { toast } from 'react-toastify';
import SelectVariantOrBase from '../../components/SelectVariantOrBase';
import useQuery from '@hooks/useQuery';

export default function QuantityDiscountsTab() {
    return (
        <SelectVariantOrBase>
            {({ selected, isBaseSelected }) => (
                <UpdateQuantityDiscounts {...{ selectedId: selected, isBaseSelected }} />
            )}
        </SelectVariantOrBase>
    );
}

function UpdateQuantityDiscounts({ selectedId }) {
    const product = useSelector((state) => state.ProductsSlice.product);

    const [loading, set_loading] = React.useState(false);
    const { data: { data } = {}, refetch } = useQuery(
        !!selectedId && `/product/${selectedId}/quantity-price`,
    );
    const idempotencyKey = randomId();

    const [discounts, setDiscounts] = React.useState([]);

    React.useEffect(() => {
        if (selectedId && data) {
            setDiscounts(data);
        }
    }, [selectedId, data]);

    const submit = async () => {
        set_loading(true);
        const to_be_updated = [];
        const to_be_created = obj2formdata({});
        let i = 0;

        discounts
            .filter((d) => d.quantity && d.price)
            ?.forEach((d) => {
                if (typeof d.id === 'string') {
                    if (d.quantity && d.price) {
                        to_be_created.append('quantity_prices[' + i + '][quantity]', d.quantity);
                        to_be_created.append('quantity_prices[' + i + '][price]', d.price);
                        i++;
                    }
                } else {
                    to_be_updated.push(d);
                }
            });

        await Promise.all(
            to_be_updated.map((d) => {
                const data = obj2formdata({});
                data.append('_method', 'PUT');
                data.append('quantity', d.quantity);
                data.append('price', d.price);

                return POST({
                    endpoint: `/product/${selectedId}/quantity-price/${d.id}`,
                    data,
                });
            }),
        );

        if (i > 0) {
            const create_request = await POST({
                endpoint: `/product/${selectedId}/quantity-price`,
                data: to_be_created,
                headers: {
                    'Idempotency-Key': idempotencyKey,
                },
            });

            if (create_request.status === 422) {
                toast.error(create_request.data.message);
            } else if (create_request.status_code === 201 || create_request.status_code === 200) {
                toast.info('Saved');
            }
        }
        set_loading(false);
        refetch();
    };

    const deleteDiscount = async (id) => {
        set_loading(true);
        const res = await DELETE({
            endpoint: `/product/${selectedId}/quantity-price/${id}`,
        });
        set_loading(false);

        if (res?.status_code === 200) {
            refetch();
        }
    };

    return (
        <>
            <div className="quantity-discounts-wrapper table-tag-wrapper">
                <table>
                    <thead>
                        <tr>
                            <td className="action-col"></td>
                            <td className="quantity-col">Quantity</td>
                            <td className="value-col">Price</td>
                            <td className="empty-col">
                                <button
                                    className="btn btn-info"
                                    type="button"
                                    disabled={loading}
                                    onClick={() =>
                                        setDiscounts([
                                            ...discounts,
                                            {
                                                id: `new_${randomId()}`,
                                                quantity: 0,
                                                price: 0,
                                            },
                                        ])
                                    }
                                >
                                    + New quantity discount
                                </button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {discounts.map((d, i) => (
                            <tr key={i}>
                                <td>
                                    <button
                                        className="btn btn-secondary"
                                        type="button"
                                        disabled={loading}
                                        onClick={() => {
                                            if (typeof d.id === 'string') {
                                                setDiscounts([
                                                    ...discounts.filter((item) => d.id !== item.id),
                                                ]);
                                            } else {
                                                deleteDiscount(d.id);
                                            }
                                        }}
                                    >
                                        -
                                    </button>
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        required={false}
                                        value={d.quantity}
                                        setValue={(val) =>
                                            setDiscounts([
                                                ...discounts.map((item) => {
                                                    if (item.id === d.id) {
                                                        item['quantity'] = +val;
                                                    }
                                                    return item;
                                                }),
                                            ])
                                        }
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        required={false}
                                        value={d.price}
                                        setValue={(val) =>
                                            setDiscounts([
                                                ...discounts.map((item) => {
                                                    if (item.id === d.id) {
                                                        item['price'] = +val;
                                                    }
                                                    return item;
                                                }),
                                            ])
                                        }
                                    />
                                </td>
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <br />
            <br />
            <CriticalActionButton
                loading={loading || !selectedId}
                onSubmit={() => {
                    /**
                     *  @todo check is_active based on variant below
                     *  as this may lead to bug
                     */
                    product?.is_active ? submit()
                        : confirmationMessage(submit);
                }}
            >
                Update Discounts
            </CriticalActionButton>
        </>
    );
}
