import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import { Helmet } from 'react-helmet';
import NotEnoughPermissionsView from './NotEnoughPermissionsView';

export default function GenericViews() {
    return (
        <Switch>
            <ProtectedRoute exact path="/403">
                <Helmet>
                    <title>Not enough permissions | Disty Admin Dashboard</title>
                </Helmet>

                <NotEnoughPermissionsView /> 
            </ProtectedRoute>
        </Switch>
    );
}
