import React from 'react';
import { Card, Map, Button } from '@components';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const ShippingAddress = ({ shippingAddressInfo, map, canEdit = false }) => {
    const dispatch = useDispatch();
    return (
        <Card
            title="Shipping Address"
            className="shipping-address"
            headerBtn={canEdit ? 'Edit address' : null}
            onHeaderBtnClick={() => {
                dispatch(
                    setModalStatus({
                        modal: 'edit_shipping_address_modal',
                        status: true,
                    })
                );
            }}
        >
            <div className="px-3 pb-2">
                <>
                    <div className="data-pairs">
                        {shippingAddressInfo.data &&
							shippingAddressInfo.data.map((itm, i) => (
							    <div key={i} className="data-pairs__item">
							        <div className="key">{itm.key}</div>
							        {itm.value ? (
							            <div className="value">
							                <div className={itm.className}>{itm.value}</div>
							            </div>
							        ) : (
							            'No value'
							        )}
							    </div>
							))}
                    </div>
                    {map && shippingAddressInfo.coordinates ? (
                        <div>
                            <Map
                                coords={{
                                    lat: shippingAddressInfo.coordinates.latitude,
                                    lng: shippingAddressInfo.coordinates.longitude
                                }}
                                center={{
                                    lat: shippingAddressInfo.coordinates.latitude,
                                    lng: shippingAddressInfo.coordinates.longitude
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className="alert alert-warning fs-sm text-center py-3"
                            role="alert"
                        >
							No coordinates provided for this address!
                        </div>
                    )}
                    {shippingAddressInfo.address && (
                        <div className="mt-3">
                            <CopyToClipboard
                                text={`${shippingAddressInfo.data
                                    .filter((item) => !!item.value)
                                    .map((item) => `${item.key}: ${item.value}`)
                                    .join('\n')}\nAddress: ${Object.values(
                                    shippingAddressInfo.address
                                ).join('-')}\nGoogle maps: https://www.google.com/maps/place/${
                                    shippingAddressInfo.coordinates.latitude
                                },${shippingAddressInfo.coordinates.longitude}`}
                                onCopy={() => toast.info('Copied to clipboard')}
                            >
                                <Button
                                    size="sm"
                                    variant="outline-secondary"
                                    className="float-right"
                                >
									Copy
                                </Button>
                            </CopyToClipboard>
                            <p className="fs-sm c-subdued">
                                {shippingAddressInfo.address.country}
                            </p>
                            <p className="fs-sm c-subdued">
                                {shippingAddressInfo.address.address}
                            </p>
                        </div>
                    )}
                </>
            </div>
        </Card>
    );
};

export default ShippingAddress;
