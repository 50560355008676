import React from 'react';
import {
    ViewHeader,
    Card,
    DataTable,
    Filters,
    Link,
    Modal,
    Button,
    Sop
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadLogsData, loadLogsFilterOptions } from '@slices/logs.slice';
import { formatDate, formatStringWithUnderscores } from '@utils';
import { setModalStatus } from '@slices/ui.slice';

export default function LogsListView() {
    const LogsSlice = useSelector((state) => state.LogsSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});
    const [selected_row, set_selected_row] = React.useState(null);

    React.useEffect(() => {
        dispatch(loadLogsFilterOptions());
    }, []);

    React.useEffect(() => {
        dispatch(loadLogsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const getAuditShortDescription = (row) => {
        if (!row?.action) return '';
        const { event, auditable_type, auditable_id } = row?.action;
        return `${event?.toUpperCase()} action on ${auditable_type} with id ${auditable_id}`;
    };
    const columns = [
        {
            name: 'Time',
            cell: (row) => formatDate(row.date),
            width: '300px',
        },
        {
            name: 'User',
            width: '300px',
            cell: (row) => {
                const user_type = row.user?.type;
                let module_link = '';
                if (user_type === 'Customer') module_link = 'customers';
                if (user_type === 'VendorUser') module_link = 'vendor-users';
                if (user_type === 'User') module_link = 'admins';

                if (row?.user?.id) {
                    return (
                        <Link
                            href={module_link ? `/${module_link}/${row.user?.id}` : '#'}
                            className="tr-name"
                            target="_blank"
                        >
                            {row.user.name}
                        </Link>
                    );
                }
                return 'System';
            },
        },
        {
            name: 'Action',
            width: '400px',
            cell: (row) => getAuditShortDescription(row),
        },
        {
            name: 'Description',
            selector: 'message',
        },
        {
            name: 'Changes made',
            width: '300px',
            cell: (row) => (
                <Button
                    onClick={() => {
                        set_selected_row(row);
                        dispatch(
                            setModalStatus({
                                modal: 'audit_change_diff',
                                status: true,
                            })
                        );
                    }}
                >
                    View changes
                </Button>
            ),
        },
    ];

    return (
        <div className="logs-list-view">
            <ViewHeader title="All logs">
                <Sop id="30083" />
            </ViewHeader>

            <Card>
                <Filters
                    filters={[
                        'customer',
                        'from',
                        'to',
                        'event',
                        'auditable_type',
                        'auditable_id',
                        'user_type',
                        'user_id',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={LogsSlice.logs}
                    pages={LogsSlice.logs_pages}
                    page={page}
                    records={LogsSlice.logs_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    loading={LogsSlice.logs_loading}
                />
            </Card>

            <Modal
                size="lg"
                name="audit_change_diff"
                title={getAuditShortDescription(selected_row)}
                confirmBtn={false}
            >
                {selected_row && (
                    <table className="audit-change-diff-table">
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Old value</th>
                                <th>New value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(selected_row?.changes).map((key) => {
                                return (
                                    <tr key={key}>
                                        <td>{formatStringWithUnderscores(key)}</td>
                                        <td>{selected_row?.changes?.[key]?.old}</td>
                                        <td>{selected_row?.changes?.[key]?.new}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </Modal>
        </div>
    );
}
