import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import { Helmet } from 'react-helmet';
import VerificationRecordsListView from './VerificationRecordsListView';
import RecordDetailView from './RecordDetailView';

export default function VerificationRecordsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/verification-center/list">
                <Helmet>
                    <title>Verification Records list| Disty Admin Dashboard</title>
                </Helmet>
                <VerificationRecordsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/verification-center/:id">
                <Helmet>
                    <title>Record Detail | Disty Admin Dashboard</title>
                </Helmet>
                <RecordDetailView />
            </ProtectedRoute>
        </Switch>
    );
}
