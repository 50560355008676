import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const LiveCartsSlice = createSlice({
    name: 'liveCarts',
    initialState: {
        liveCarts: [],
        liveCarts_pages: null,
        liveCarts_records: null,
        liveCart: {},
        liveCartAddress: null,
        liveCarts_loading: true,
        liveCart_loading: true,
    },
    reducers: {
        setLiveCarts(state, { payload }) {
            state.liveCarts = payload;
        },
        setLiveCart(state, { payload }) {
            state.liveCart = payload;
        },
        setLiveCartAddress(state, { payload }) {
            state.liveCartAddress = payload;
        },
        setLiveCartsRecords(state, { payload }) {
            state.liveCarts_records = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setLiveCartsTotalPages(state, { payload }) {
            state.liveCarts_pages = payload;
        },
    },
});

// export actions
export const {
    setLiveCart,
    setLiveCartAddress,
    setLiveCarts,
    setLiveCartsRecords,
    setLoading,
    setLiveCartsTotalPages,
} = LiveCartsSlice.actions;

export const loadLiveCartsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'liveCarts_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/live-cart?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setLiveCarts(res?.data || []));
    dispatch(setLiveCartsTotalPages(res?.meta?.last_page || 0));
    dispatch(
        setLiveCartsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        })
    );
    dispatch(
        setLoading({
            key: 'liveCarts_loading',
            value: false,
        })
    );
};

export const loadLiveCartData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'liveCart_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/live-cart/${id}`,
    });
    dispatch(setLiveCart(res?.data || {}));
    if (res?.data.address_id && res?.data.customer?.id) {
        const addressed = await GET({
            endpoint: `/customers/${res?.data.customer.id}/addresses`,
        });
        // TODO there is no API endpoint to get single address by ID
        // so we have to get all addresses of customer
        if (addressed.data) {
            addressed.data.map((i) => {
                if (i.id === res?.data.address_id) {
                    dispatch(setLiveCartAddress(i));
                }
            });
        }
    } else {
        dispatch(setLiveCartAddress(null));
    }
    dispatch(
        setLoading({
            key: 'liveCart_loading',
            value: false,
        })
    );
};

export default LiveCartsSlice.reducer;
