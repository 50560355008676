import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import AppInstructionsListView from './AppInstructionsListView';
import { Helmet } from 'react-helmet';
import AppInstructionsAddView from './AppInstructionsAddView';
import AppInstructionsUpdateView from './AppInstructionsUpdateView';

export default function AppInstructionsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/app-instructions/list">
                <Helmet>
                    <title>App Instructions | Disty Admin Dashboard</title>
                </Helmet>
                <AppInstructionsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/app-instructions/add">
                <Helmet>
                    <title>App Instructions | Disty Admin Dashboard</title>
                </Helmet>
                <AppInstructionsAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/app-instructions/:id">
                <Helmet>
                    <title>App Instructions | Disty Admin Dashboard</title>
                </Helmet>
                <AppInstructionsUpdateView/>
            </ProtectedRoute>
        </Switch>
    );
}