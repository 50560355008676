import React from 'react';
import backArrowIcon from '@assets/icons/back-arrow.svg';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

const ViewHeader = ({ title = '', children, renderBackArrow = false, history, className = '' }) => {
    return (
        <div className={clsx('view-header', className)}>
            {renderBackArrow && (
                <div className="view-header__back-btn" onClick={() => history.goBack()}>
                    <img src={backArrowIcon} alt="Click to go back" />
                </div>
            )}
            <div className="view-header__title">
                {typeof title === 'string' ? <h1>{title}</h1> : title}
            </div>
            <div className="view-header__actions">{children}</div>
        </div>
    );
};
ViewHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.any,
    renderBackArrow: PropTypes.bool,
    history: PropTypes.any,
};

export default withRouter(ViewHeader);
