import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import ProductsListView from './ProductsListView';
import { ProductsUpdateView } from './ProductsUpdateView';
import ProductsAddView from './ProductsAddView';
import { Helmet } from 'react-helmet';

export default function ProductsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/products/list">
                <Helmet>
                    <title>Products | Disty Admin Dashboard</title>
                </Helmet>
                <ProductsListView />
            </ProtectedRoute>   
            <ProtectedRoute exact path="/products/add">
                <Helmet>
                    <title>Add Product | Disty Admin Dashboard</title>
                </Helmet>
                <ProductsAddView />
            </ProtectedRoute>    
            <ProtectedRoute exact path="/products/:id">
                <Helmet>
                    <title>Edit Product | Disty Admin Dashboard</title>
                </Helmet>
                <ProductsUpdateView />
            </ProtectedRoute>    
        </Switch>
    );
}
// Add single product page
// Notifications page
// Add new custom notification
// Order filters
