import React from 'react';
import { ChoiceList, Icon, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function OrderStateFilter({
    value = null,
    set_value = () => {},
}) {
    const [q, set_q] = React.useState('');
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const filters_options = OrdersSlice?.filters_options || {};

    const onChange = useCallback(
        (value) => set_value(value),
        [],
    );

    return (
        <>
            <TextField
                value={q}
                onChange={set_q}
                autoComplete="off"
                placeholder='Search'
                prefix={<Icon source={SearchMinor} />}
            />
            <br />
            <ChoiceList
                choices={filters_options?.order_states?.filter(
                    (item) => item.label.toLowerCase().includes(q.toLowerCase()),
                ) || []}
                selected={value || []}
                onChange={onChange}
                allowMultiple
            />

        </>
    );
}
