import React, { useCallback } from 'react';
import { ChoiceList, Icon, Spinner, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { GET } from '@utils';

export default function ScheduledOrderDeliveryOptionFilter({
    value = null,
    set_value = () => {},
}) {
    const [q, set_q] = React.useState('');
    const [scheduled_order, set_scheduled_order] = React.useState([]);
    const [loading, set_loading] = React.useState(false);

    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: '/scheduled-order',
        });
        let format_scheduled = [];
        res?.data?.forEach?.((order) => {
            order?.delivery_options.forEach((option) => {
                format_scheduled.push({
                    label: option.title_en,
                    value: option.id,
                });
            });
        });
        set_loading(false);
        set_scheduled_order(format_scheduled);
    };
    
    React.useEffect(() => {
        fetchData();
    }, []);

    const onChange = useCallback((value) => set_value(value), []);

    return (
        <>
            <TextField
                value={q}
                onChange={set_q}
                autoComplete="off"
                placeholder="Search"
                prefix={<Icon source={SearchMinor} />}
            />
            <br />
            {loading ? (
                <div className="flex__jc-center">
                    <Spinner size="small" />
                </div>
            ) : (
                <ChoiceList
                    choices={
                        scheduled_order?.filter((item) =>
                            item.label.toLowerCase().includes(q.toLowerCase())
                        ) || []
                    }
                    selected={value || []}
                    onChange={onChange}
                    allowMultiple
                />
            )}
        </>
    );
}
