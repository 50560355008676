import React from 'react';
import { Modal, Input, TimeRangePicker, Checkbox } from '@components';
import { Map } from '@components';
import { POST, PUT } from '@utils';

const BASE_WORKING_HOURS = {
    sunday: {
        active: true,
        time_from: '08:00',
        time_to: '20:00',
    },
    monday: {
        active: true,
        time_from: '08:00',
        time_to: '20:00',
    },
    tuesday: {
        active: true,
        time_from: '08:00',
        time_to: '20:00',
    },
    wednesday: {
        active: true,
        time_from: '08:00',
        time_to: '20:00',
    },
    thursday: {
        active: true,
        time_from: '08:00',
        time_to: '20:00',
    },
    friday: {
        active: true,
        time_from: '08:00',
        time_to: '20:00',
    },
    saturday: {
        active: true,
        time_from: '08:00',
        time_to: '20:00',
    },
};

const NewVendorAddressModal = ({ id, onSave, address_to_be_edited }) => {
    const [google_maps_address, set_google_maps_address] = React.useState(null);
    const center_of_SA = {
        lat: 24.804219,
        lng: 45.8601,
    };
    const [marker, set_marker] = React.useState(center_of_SA);
    const [center, set_center] = React.useState(center_of_SA);
    const [coords, set_coords] = React.useState({});
    const [loading, set_loading] = React.useState(false);

    const [data, set_data] = React.useState({
        name: '',
        details: '',
        contact_name: '',
        contact_mobile: '',
        special_mark: '',
    });

    const [business_hours, set_business_hours] =
		React.useState(BASE_WORKING_HOURS);

    const [errors, setErrors] = React.useState([]);

    React.useEffect(() => {
        if (address_to_be_edited) {
            set_data({
                name: address_to_be_edited.name,
                details: address_to_be_edited.details,
                contact_name: address_to_be_edited.contact_name,
                contact_mobile: address_to_be_edited.contact_mobile,
                special_mark: address_to_be_edited.special_mark,
            });
            const coord = {
                lat: address_to_be_edited.latitude,
                lng: address_to_be_edited.longitude,
            };
            set_center(coord);
            set_marker(coord);
            set_coords(coord);
            
            const merged_business_hours = {};
            for (const day in business_hours) {
                if (Object.hasOwnProperty.call(business_hours, day)) {
                    // const element = business_hours[day];
                    const x = address_to_be_edited?.businessHours?.find(
                        (item) => item.day === day
                    );

                    if (x) {
                        merged_business_hours[day] = {
                            active: true,
                            time_from: x.time_from,
                            time_to: x.time_to,
                        };
                    } else {
                        merged_business_hours[day] = {
                            ...business_hours[day],
                            active: false,
                        };
                    }
                }
            }
            set_business_hours(merged_business_hours);
        } else {
            resetForm();
        }
    }, [address_to_be_edited]);

    const resetForm = () => {
        set_business_hours(BASE_WORKING_HOURS);
        set_center(center_of_SA);
        set_marker(center_of_SA);
        set_data({
            name: '',
            details: '',
            contact_name: '',
            contact_mobile: '',
            special_mark: '',
        });
        setErrors([]);
    };

    const onSubmit = async (close) => {
        set_loading(true);
        // const d = obj2formdata({
        //     name: data.name,
        //     details: data.details,
        //     contact_name: data.contact_name,
        //     contact_mobile: data.contact_mobile,
        //     special_mark: data.special_mark,
        //     latitude: coords.lat,
        //     longitude: coords.lng,
        // });
        const d = {
            name: data.name,
            details: data.details,
            contact_name: data.contact_name,
            contact_mobile: data.contact_mobile,
            special_mark: data.special_mark,
            latitude: coords.lat,
            longitude: coords.lng,
            business_hours: [],
        };

        // let i = 0;
        for (const day in business_hours) {
            if (Object.hasOwnProperty.call(business_hours, day)) {
                const element = business_hours[day];
                if (element.active) {
                    d.business_hours.push({
                        day,
                        time_from: `${element.time_from.split(':')[0]}:${
                            element.time_from.split(':')[1]
                        }`,
                        time_to: `${element.time_to.split(':')[0]}:${
                            element.time_to.split(':')[1]
                        }`
                    });
                    // d.append(`business_hours[${i}][day]`, day);
                    // d.append(`business_hours[${i}][time_from]`, element.time_from);
                    // d.append(`business_hours[${i}][time_to]`, element.time_to);
                    // i++;
                }
            }
        }

        const res = await (address_to_be_edited ? PUT : POST)({
            endpoint: address_to_be_edited
                ? `/vendors/${id}/addresses/${address_to_be_edited?.id}`
                : `/vendors/${id}/addresses`,
            data: d,
        });

        set_loading(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            close();
            // reset form
            resetForm();
            if (onSave) {
                onSave(res?.data);
            }
        }
    };

    const dateToTime = (date) => {
        return `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
            date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        }`;
    };

    const timeToDate = (date) => {
        return new Date(0, 0, 0, date.split(':')[0], date.split(':')[1]);
    };

    return (
        <Modal
            title={address_to_be_edited ? 'Edit Address' : 'Add Address'}
            name="new_vendor_address_modal"
            onSubmit={onSubmit}
            loading={loading}
        >
            <label>Location</label>
            <Map
                onCoordsChange={set_coords}
                coords={coords}
                set_center={set_center}
                center={center}
                set_marker={set_marker}
                marker={marker}
                google_maps_address={google_maps_address}
                set_google_maps_address={set_google_maps_address}
                allow_search={true}
                height="600px"
            />
            <hr />
            <Input
                label="Name"
                setValue={(val) => {
                    set_data({ ...data, name: val });
                }}
                value={data.name}
            />
            <Input
                label="Details"
                setValue={(val) => {
                    set_data({ ...data, details: val });
                }}
                value={data.details}
            />
            <Input
                label="Contact name"
                setValue={(val) => {
                    set_data({ ...data, contact_name: val });
                }}
                value={data.contact_name}
            />
            <Input
                label="Contact mobile"
                type="number"
                prefix="+966"
                setValue={(val) => {
                    set_data({ ...data, contact_mobile: val });
                }}
                value={data.contact_mobile}
            />
            <Input
                label="Special mark"
                setValue={(val) => {
                    set_data({ ...data, special_mark: val });
                }}
                value={data.special_mark}
            />

            {Object.keys(business_hours).map((day) => (
                <div className="day" key={day}>
                    <div className="day-label">
                        <Checkbox
                            label={day.toUpperCase()}
                            checked={business_hours[day].active}
                            onChange={(val) => {
                                set_business_hours((prev) => ({
                                    ...prev,
                                    [day]: {
                                        ...prev[day],
                                        active: val,
                                    },
                                }));
                            }}
                        />
                    </div>
                    <div className="day-content">
                        <TimeRangePicker
                            disabled={!business_hours[day].active}
                            startDate={timeToDate(business_hours[day].time_from)}
                            endDate={timeToDate(business_hours[day].time_to)}
                            startDateLabel="Start working hours"
                            endDateLabel="End working hours"
                            setStartDate={(val) => {
                                set_business_hours((prev) => ({
                                    ...prev,
                                    [day]: {
                                        ...prev[day],
                                        time_from: dateToTime(val),
                                    },
                                }));
                            }}
                            setEndDate={(val) => {
                                set_business_hours((prev) => ({
                                    ...prev,
                                    [day]: {
                                        ...prev[day],
                                        time_to: dateToTime(val),
                                    },
                                }));
                            }}
                        />
                    </div>
                </div>
            ))}

            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default NewVendorAddressModal;
