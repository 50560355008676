import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const VendorRequestsSlice = createSlice({
    name: 'vendorRequests',
    initialState: {
        vendor_requests: [],
        vendor_requests_pages: null,
        vendor_requests_records: null,
        vendor_request: {},
        vendor_requests_loading: true,
        vendor_request_loading: true,
    },
    reducers: {
        setVendorRequests(state, { payload }) {
            state.vendor_requests = payload;
        },
        setVendorRequest(state, { payload }) {
            state.vendor_request = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVendorRequestsTotalPages(state, { payload }) {
            state.vendor_requests_pages = payload;
        },
        setVendorRequestsRecords(state, { payload }) {
            state.vendor_requests_records = payload;
        },
    },
});

// export actions
export const {
    setVendorRequest,
    setVendorRequests,
    setLoading,
    setVendorRequestsTotalPages,
    setVendorRequestsRecords,
} = VendorRequestsSlice.actions;

export const loadVendorRequestsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendor_requests_loading',
            value: true,
        })
    );

    const res = await GET({
        endpoint: `/vendors/vendor-change-requests?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma',
        })}`,
    });

    dispatch(setVendorRequests(res?.data || []));

    if(res?.meta){
        dispatch(setVendorRequestsTotalPages(res?.meta?.last_page || 0));
        dispatch(setVendorRequestsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }

    dispatch(
        setLoading({
            key: 'vendor_requests_loading',
            value: false,
        })
    );
};

export const loadVendorRequestData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendor_request_loading',
            value: true,
        })
    );

    const res = await GET({
        endpoint: `/vendors/vendor-change-requests/${id}`,
    });

    dispatch(setVendorRequest(res?.data || {}));

    dispatch(
        setLoading({
            key: 'vendor_request_loading',
            value: false,
        })
    );
};

export default VendorRequestsSlice.reducer;
