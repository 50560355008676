import React from 'react';
import { useParams } from 'react-router-dom';
import { PUT, GET, EditorStateToRawHTML, RawHTMLToEditorState } from '@utils';
import { Redirect } from 'react-router';
import { Editor, ViewHeader, Input, Card, Sop } from '@components';

function AppInstructionsUpdateView() {
    const { id: slug } = useParams();
    const [record_id, set_record_id] = React.useState(null);
    const [instruction_ar, set_instruction_ar] = React.useState('');
    const [instruction_en, set_instruction_en] = React.useState('');
    const [updated, set_updated] = React.useState(null);
    const [errors, set_errors] = React.useState([]);
    const [title, set_title] = React.useState({
        label: 'Title',
        value: '',
        setValue: (val) => set_title({ ...title, value: val })
    });

    const fetchData = async () => {
        const res = await GET({ endpoint: `/app-instruction/${slug}` });
        if (res?.status_code === 200) {
            set_record_id(res.data.id);
            set_instruction_ar(
                RawHTMLToEditorState(
                    res?.data?.content_ar
                )
            );
            set_instruction_en(
                RawHTMLToEditorState(
                    res?.data?.content_en
                )
            );
            title.setValue(res?.data?.title);
        }
    };

    const submit = async () => {
        if (!record_id) return;

        const res = await PUT({
            endpoint: `/app-instruction/${record_id}`,
            data: {
                title: title.value,
                content_ar: EditorStateToRawHTML(instruction_ar),
                content_en: EditorStateToRawHTML(instruction_en)
            }
        });
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_errors({});
            set_updated(res?.data);
        } else {
            set_errors(res?.data?.errors || {});
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    if (updated) {
        return <Redirect to="/app-instructions/list" />;
    }
    return (
        <>
            <ViewHeader title="Edit instruction">
                <Sop id="30123" />
                <button className="btn btn-primary" onClick={submit}>
                    Submit
                </button>
            </ViewHeader>
            <Card>
                <div className="px-3 py-2">
                    {!!Object.keys(errors).length && (
                        <div className="alert alert-danger">
                            <ul>
                                {Object.keys(errors).map((key) => {
                                    return <li key={key}>{errors[key]}</li>;
                                })}
                            </ul>
                        </div>
                    )}
                    <Input {...title} />
                    <div className="">
                        <h3 className="divider-title">
                            Instructions Content ( AR )
                        </h3>
                        <Editor
                            value={instruction_ar}
                            setValue={set_instruction_ar}
                        />
                    </div>
                    <div className="mt-2">
                        <h3 className="divider-title">
                            Instructions Content ( EN )
                        </h3>
                        <Editor
                            value={instruction_en}
                            setValue={set_instruction_en}
                        />
                    </div>
                </div>
            </Card>
        </>
    );
}

export default AppInstructionsUpdateView;
