import React from 'react';
import PropTypes from 'prop-types';
import { default as S } from 'react-select';
import { Form } from '@components';

export default function Select({
    label = '',
    options,
    value,
    onChange,
    required = true,
    isMulti = false,
    disabled = false,
    getOptionValue,
    placeholder,
    isSearchable = true,
}) {
    const setSelectRef = React.useRef();
    const [valid, ] = React.useState(null);

    return (
        <Form.Group controlId={label}>
            {label && (
                <Form.Label>{label}</Form.Label>
            )}
            <S  
                onFocus={() => document.body.classList.add('select-menu-is-open')}
                onBlur={() => document.body.classList.remove('select-menu-is-open')}
                isMulti={isMulti}
                value={value}
                options={options}
                onChange={(e) => {
                    onChange(e);
                    setSelectRef.current.blur();
                }}
                isDisabled={disabled}
                isSearchable={isSearchable}
                getOptionValue={getOptionValue}
                ref={setSelectRef}
                placeholder={placeholder}
                className='form_select'
            />
            {required && (
                <>
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            width: '100%',
                            display: 'none'
                            // height: 0,
                            // position: 'absolute'
                        }}
                        value={value}
                        onChange={() => {}}
                        onFocus={() => setSelectRef.current.focus()}
                        required={true}
                    />
                    {valid === false && <Form.Text className="text-danger">
                        This field is required!
                    </Form.Text>}
                </>
            )}
        </Form.Group>
    );
}

Select.propTypes = {
    value: PropTypes.any.isRequired,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
};