import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const CustomersSlice = createSlice({
    name: 'customers',
    initialState: {
        customers: [],
        customers_pages: null,
        customers_rows_count: null,
        customers_records: null,
        customer: {},
        customer_balance: {},
        customers_loading: true,
        customer_loading: true,

        customer_refund_reasons: []
    },
    reducers: {
        setCustomers(state, { payload }) {
            state.customers = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.customers.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.customers[index] = {
                    ...state.customers[index],
                    ...payload,
                };
            }
        },
        setCustomer(state, { payload }) {
            state.customer = payload;
        },
        updateCustomerInListing(state, { payload }) {
            state.customers = state.customers.map(customer => {
                if (customer.id === payload.id) {
                    return { ...customer, ...payload };
                }
                return customer;
            });
        },
        setCustomerBalance(state, { payload }) {
            state.customer_balance = payload;
        },
        setCustomerRefundReasons(state, { payload }){
            state.customer_refund_reasons = payload?.map((r) => ({
                id: r?.status,
                label: r?.reason
            }));
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setCustomersTotalPages(state, { payload }) {
            state.customers_pages = payload;
        },
        setCustomersRecords(state, { payload }) {
            state.customers_records = payload;
        },
    },
});

// export actions
export const {
    setCustomer,
    updateSingleRow,
    updateCustomerInListing,
    setCustomersRecords,
    setCustomers,
    setCustomerBalance,
    setLoading,
    setCustomersTotalPages,
    setCustomerRefundReasons
} = CustomersSlice.actions;

export const loadCustomersData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'customers_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/customers?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setCustomers(res?.data || []));
    dispatch(setCustomersTotalPages(res?.meta?.last_page || 0));
    dispatch(setCustomersRecords({
        from: res?.meta?.from,
        to: res?.meta?.to,
        total: res?.meta?.total
    }));
    dispatch(
        setLoading({
            key: 'customers_loading',
            value: false,
        })
    );
};

export const loadCustomerData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'customer_loading',
            value: true,
        })
    );
    const [res, balance] = await Promise.all([
        GET({
            endpoint: `/customers/${id}`,
        }),
        GET({
            endpoint: `/payment/customers/${id}/wallet`,
        }),
    ]);
    dispatch(setCustomer(res?.data || {}));
    dispatch(setCustomerBalance(balance.data || {}));
    dispatch(
        setLoading({
            key: 'customer_loading',
            value: false,
        })
    );
};

export const loadCustomerBalance = (id) => async (dispatch) => {
    const res = await GET({
        endpoint: `/payment/customers/${id}/wallet`,
    });
    dispatch(setCustomerBalance(res?.data || {}));
};

export const loadCustomerRefundReasons = () => async (dispatch) => {
    const res = await GET({
        endpoint: '/payment/wallet-refund-reasons',
    });
    dispatch(setCustomerRefundReasons(res?.data || {}));
};

export default CustomersSlice.reducer;
