import React from 'react';
import {
    DataTable,
    Button,
    Modal,
    Row,
    Col,
    Toggler,
    ShowErrors
} from '@components';
import { PUT, randomId } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadRibbonSettingsData } from '@slices/settings.slice';

const RibbonSettingsTab = () => {
    const SettingsSlice = useSelector((state) => state.SettingsSlice);
    const ribbon_settings = SettingsSlice.ribbonSettings;
    const dispatch = useDispatch();
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);

    const [settings, set_settings] = React.useState({
        discount_card_app: true,
        discount_card_web: true,
        discount_details_app: true,
        discount_details_web: true,
        featured_card_app: true,
        featured_card_web: true,
        featured_details_app: true,
        featured_details_web: true,
        scheduled_order_card_app: true,
        scheduled_order_card_web: true,
        scheduled_order_details_app: true,
        scheduled_order_details_web: true,
        is_exclusive_card_app: true,
        is_exclusive_card_web: true,
        is_exclusive_details_app: true,
        is_exclusive_details_web: true
    });

    const handleChange = (val, name) => {
        set_settings({
            ...settings,
            [name]: val
        });
    };

    React.useEffect(() => {
        dispatch(loadRibbonSettingsData());
    }, [rerender]);

    const submit = async (closeModal) => {
        set_loading(true);
        set_errors([]);

        const res = await PUT({
            endpoint: '/ribbons-control-settings',
            data: {
                discount_card_app: settings?.discount_card_app ? 1 : 0,
                discount_card_web: settings?.discount_card_web ? 1 : 0,
                discount_details_app: settings?.discount_details_app ? 1 : 0,
                discount_details_web: settings?.discount_details_web ? 1 : 0,
                featured_card_app: settings?.featured_card_app ? 1 : 0,
                featured_card_web: settings?.featured_card_web ? 1 : 0,
                featured_details_app: settings?.featured_details_app ? 1: 0,
                featured_details_web: settings?.featured_details_web ? 1 : 0,
                scheduled_order_card_app: settings?.scheduled_order_card_app ? 1 : 0,
                scheduled_order_card_web: settings?.scheduled_order_card_web ? 1 : 0,
                scheduled_order_details_app: settings?.scheduled_order_details_app ? 1 : 0,
                scheduled_order_details_web: settings?.scheduled_order_details_web ? 1 : 0,
                is_exclusive_card_app: settings?.is_exclusive_card_app ? 1 : 0,
                is_exclusive_card_web: settings?.is_exclusive_card_web ? 1 : 0,
                is_exclusive_details_app: settings?.is_exclusive_details_app ? 1 : 0,
                is_exclusive_details_web: settings?.is_exclusive_details_web ? 1 : 0
            }
        });
        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('edit_ribbons_settings__modal');
            set_rerender(randomId());
        }
    };

    const data = [
        {
            key: 'Discount Card',
            app: ribbon_settings?.discount_card_app,
            web: ribbon_settings?.discount_card_web
        },
        {
            key: 'Discount Details',
            app: ribbon_settings?.discount_details_app,
            web: ribbon_settings?.discount_details_web
        },
        {
            key: 'Featured Card',
            app: ribbon_settings?.featured_card_app,
            web: ribbon_settings?.featured_card_web
        },
        {
            key: 'Featured Details',
            app: ribbon_settings?.featured_details_app,
            web: ribbon_settings?.featured_details_web
        },
        {
            key: 'Scheduled Order Card',
            app: ribbon_settings?.scheduled_order_card_app,
            web: ribbon_settings?.scheduled_order_card_web
        },
        {
            key: 'Scheduled Order Details',
            app: ribbon_settings?.scheduled_order_details_app,
            web: ribbon_settings?.scheduled_order_details_web
        },
        {
            key: 'Exclusive Card',
            app: ribbon_settings?.is_exclusive_card_app,
            web: ribbon_settings?.is_exclusive_card_web
        },
        {
            key: 'Exclusive Details',
            app: ribbon_settings?.is_exclusive_details_app,
            web: ribbon_settings?.is_exclusive_details_web
        },
    ];

    const columns = [
	    {
            name: 'Setting',
            cell: (row) => row?.key
        },
        {
            name: 'App',
            center: true,
            cell: (row) => (
                <div
                    className={`status-indicator ${
                        row.app && 'active-indicator'
                    }`}
                >
                    {row.app ? 'Active' : 'Inactive'}
                </div>
            )
        },
        {
            name: 'Web',
            center: true,
            cell: (row) => (
                <div
                    className={`status-indicator ${
                        row?.web && 'active-indicator'
                    }`}
                >
                    {row?.web ? 'Active' : 'Inactive'}
                </div>
            )
        }
    ];

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                loading={SettingsSlice?.ribbonSettings_loading}
            />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_settings({
                            ...settings,
                            discount_card_app: ribbon_settings?.discount_card_app,
                            discount_card_web: ribbon_settings?.discount_card_web,
                            discount_details_app: ribbon_settings?.discount_details_app,
                            discount_details_web: ribbon_settings?.discount_details_web,
                            featured_card_app: ribbon_settings?.featured_card_app,
                            featured_card_web: ribbon_settings?.featured_card_web,
                            featured_details_app: ribbon_settings?.featured_details_app,
                            featured_details_web: ribbon_settings?.featured_details_web,
                            scheduled_order_card_app: ribbon_settings?.scheduled_order_card_app,
                            scheduled_order_card_web: ribbon_settings?.scheduled_order_card_web,
                            scheduled_order_details_app: 
								ribbon_settings?.scheduled_order_details_app,
                            scheduled_order_details_web:
								ribbon_settings?.scheduled_order_details_web,
                            is_exclusive_card_app: ribbon_settings?.is_exclusive_card_app,
                            is_exclusive_card_web: ribbon_settings?.is_exclusive_card_web,
                            is_exclusive_details_app: ribbon_settings?.is_exclusive_details_app,
                            is_exclusive_details_web: ribbon_settings?.is_exclusive_details_web
                        });
                        set_errors([]);
                        dispatch(
                            setModalStatus({
                                modal: 'edit_ribbons_settings__modal',
                                status: true
                            })
                        );
                    }}
                >
                    Edit Ribbons settings
                </Button>
            </div>
            <Modal
                title={'Update Ribbons Settings'}
                variant={'warning'}
                name="edit_ribbons_settings__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal);
                }}
                loading={loading}
            >

                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
                <Row>
                    <Col xs={12} md={8} lg={6}>
					   
                    </Col>
                    <Col xs={12} md={8} lg={3}>
					   App
                    </Col>
                    <Col xs={12} md={8} lg={3}>
					   Web
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8} lg={6}>
					   Discount Card
                    </Col>
                    <Col xs={12} md={8} lg={3}>
					   <Toggler
                            checked={settings?.discount_card_app}
                            name="discount_card_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.discount_card_web}
                            name="discount_card_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8} lg={6}>
					    Discount Details
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.discount_details_app}
                            name="discount_details_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.discount_details_web}
                            name="discount_details_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
                <Row>
				    <Col xs={12} md={8} lg={6}>
					   Featured Card
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.featured_card_app}
                            name="featured_card_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.featured_card_web}
                            name="featured_card_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
                <Row>
				    <Col xs={12} md={8} lg={6}>
					   Featured Details
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.featured_details_app}
                            name="featured_details_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.featured_details_web}
                            name="featured_details_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
                <Row>
				    <Col xs={12} md={8} lg={6}>
					    Scheduled Order Card
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.scheduled_order_card_app}
                            name="scheduled_order_card_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.scheduled_order_card_web}
                            name="scheduled_order_card_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
                <Row>
				    <Col xs={12} md={8} lg={6}>
					    Scheduled Order Details
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.scheduled_order_details_app}
                            name="scheduled_order_details_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.scheduled_order_details_web}
                            name="scheduled_order_details_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
                <Row>
				    <Col xs={12} md={8} lg={6}>
					    Exclusive Card
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.is_exclusive_card_app}
                            name="is_exclusive_card_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.is_exclusive_card_web}
                            name="is_exclusive_card_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
                <Row>
				    <Col xs={12} md={8} lg={6}>
					Exclusive Details
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.is_exclusive_details_app}
                            name="is_exclusive_details_app"
                            onChange={handleChange}
					   />
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                        <Toggler
                            checked={settings?.is_exclusive_details_web}
                            name="is_exclusive_details_web"
                            onChange={handleChange}
					   />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default RibbonSettingsTab;