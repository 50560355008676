import React from 'react';
import {
    ViewHeader,
    Textarea,
    Button,
    Card,
    Row,
    Col,
    FileUpload,
    DateRangePicker,
    Toggler,
    Input,
    Radio,
    Select,
    CustomPrompt,
    ShowErrors,
    Sop,
    Filters
} from '@components';
import { isArabic, isEnglish, obj2formdata, POST, randomId } from '@utils';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

export default function BannersAddView() {
    const [start_date, set_start_date] = React.useState(new Date());
    const [end_date, set_end_date] = React.useState(new Date());
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const idempotencyKey = randomId();
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);

    const [is_all_entity_types_selected, set_is_all_entity_types_selected] =
    React.useState('all');
    const [select_all_entity , set_select_all_entity] = React.useState(false);
   
    const [entity_types, set_entity_types] = React.useState({
        options: [],
        getOptionValue: (option) => option.id,
        value: [],
        isMulti: true,
        onChange: (val) =>  set_entity_types((prev) => ({ ...prev, value: val })),
    });
    // cities
    const [is_all_cities_selected, set_is_all_cities_selected] =
    React.useState('all');
    const [select_all_cities , set_select_all_cities] = React.useState(false);
   
    const [cities, set_cities] = React.useState({
        options: [],
        getOptionValue: (option) => option.id,
        value: [],
        isMulti: true,
        onChange: (val) =>  set_cities((prev) => ({ ...prev, value: val })),
    });
   //banner_types
   const [banner_types, set_banner_types] = React.useState({
    label: 'Banner Type',
    options: [],
    value: '',
    getOptionValue: (option) => option.id,
    onChange: (val) => set_banner_types((prev) => ({ ...prev, value: val })),
});
    React.useEffect(()=>{
        set_entity_types({ ...entity_types ,  options: GlobalsSlice.entity_types });
        set_cities({ ...cities ,  options: GlobalsSlice.cities });
        set_banner_types({ ...banner_types ,  options: GlobalsSlice.banner_types });
    },[GlobalsSlice]);
  
    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val })
    });
    const [name_ar, set_name_ar] = React.useState({
        label: 'Banner Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),    });
    const [name_en, set_name_en] = React.useState({
        label: 'Banner Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),    });
    const [priority, set_priority] = React.useState({
        label: 'Priority',
        value: '',
        type: 'number',
        setValue: (val) => set_priority({ ...priority, value: val })
    });
    const [cta, set_cta] = React.useState({
        label: 'CTA',
        value: '',
        setValue: (val) => set_cta({ ...cta, value: val })
    });
    const [title_ar, set_title_ar] = React.useState({
        label: 'Title',
        value: '',
        setValue: (val) => (isArabic(val) ? set_title_ar({ ...title_ar, value: val }) : ''),
    });
    const [body_ar, set_body_ar] = React.useState({
        label: 'Body',
        value: '',
        setValue: (val) => (isArabic(val) ? set_body_ar({ ...body_ar, value: val }) : ''),
    });
    const [banner_web_ar, set_banner_web_ar] = React.useState({
        label: 'Banner (web)',
        files: [],
        setFiles: (val) => set_banner_web_ar({ ...banner_web_ar, files: val })
    });
    const [banner_mobile_ar, set_banner_mobile_ar] = React.useState({
        label: 'Banner (mobile)',
        files: [],
        setFiles: (val) =>
            set_banner_mobile_ar({ ...banner_mobile_ar, files: val })
    });

    const [title_en, set_title_en] = React.useState({
        label: 'Title',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_title_en({ ...title_en, value: val }) : ''),
    });
    const [body_en, set_body_en] = React.useState({
        label: 'Body',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_body_en({ ...body_en, value: val }) : '')
    });
    const [banner_web_en, set_banner_web_en] = React.useState({
        label: 'Banner (web)',
        files: [],
        setFiles: (val) => set_banner_web_en({ ...banner_web_en, files: val })
    });
    const [banner_mobile_en, set_banner_mobile_en] = React.useState({
        label: 'Banner (mobile)',
        files: [],
        setFiles: (val) =>
            set_banner_mobile_en({ ...banner_mobile_en, files: val })
    });
    const [filters, set_filters] = React.useState({});
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        const emojiAndEnglish = /[\u{1F600}-\u{1F6FF}|\u{1F300}-\u{1F5FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{20A0}-\u{20CF}|\u{1F1E6}-\u{1F1FF}A-Za-z]/u;
        const emojiAndArabic = /[\u{1F600}-\u{1F6FF}|\u{1F300}-\u{1F5FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{20A0}-\u{20CF}|\u{1F1E6}-\u{1F1FF}|\u0600-\u06FF]/u;

        if (!name_ar.value || emojiAndEnglish.test(name_ar.value))
            newErrors.name_ar =
                'The name  (arabic) field is required and must not contain emojis or English letters.';
        if (!name_en.value || emojiAndArabic.test(name_en.value))
            newErrors.name_en =
                'The name (english) field is required and must not contain emojis or arabic letters.';
        if (!cta.value) 
            newErrors.cta = 'The cta field is required';
        if (!title_ar.value) 
            newErrors.title_ar = 'The title (arabic) field is required';
        if (!title_en.value) 
            newErrors.title_en = 'The title (english) field is required';
        if (!body_ar.value) 
            newErrors.body_ar = 'The body (arabic) field is required';
        if (!body_en.value) 
            newErrors.body_en = 'The body (english) field is required';
        if (!priority.value) 
            newErrors.priority = 'The banner priority field is required';
        if (banner_mobile_en.files.length==0) 
            newErrors.banner_mobile_en = 'The banner image mobile (english) field is required.';
        if (banner_mobile_ar.files.length ==0) 
            newErrors.banner_mobile_ar = 'The banner image mobile (arabic) field is required.';
        if (banner_web_en.files.length==0) 
            newErrors.banner_web_en = 'The banner image (english) field is required';
        if (banner_web_ar.files.length==0) 
            newErrors.banner_web_ar = 'The banner image (arabic) field is required';
        if (!banner_types.value) 
            newErrors.banner_types = 'The banner position field is required';


        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name_en,name_ar,cta,title_ar,title_en,body_ar,body_en,banner_types,priority,banner_mobile_ar,banner_mobile_en,banner_web_ar,banner_web_en]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
        const data = obj2formdata({
            name_ar: name_ar.value,
            name_en: name_en.value,
            banner_priority:priority.value,
            banner_position:banner_types?.value?.label || null,
            title_en: title_en.value,
            title_ar: title_ar.value,
            body_en: body_en.value,
            body_ar: body_ar.value,
            cta: cta.value,
            status: status.checked ? '1' : '0',
            starts_at: start_date
                .toISOString()
                .slice(0, 10)
                .split('-')
                .reverse()
                .join('-'),
            ends_at: end_date
                .toISOString()
                .slice(0, 10)
                .split('-')
                .reverse()
                .join('-'),
        });
        filters?.has_wallet_balance?.length && (
            data.append('filtration_data[has_wallet_balance]', 
                filters.has_wallet_balance[0] === 1 ? '1' : '0'));
        filters?.account_managers?.length && (
            filters?.account_managers.forEach((x, i) => {
                data.append('filtration_data[account_managers]['+i+']', x);
            })
        );
        filters?.verification_statuses?.length && (
            filters?.verification_statuses.forEach((x, i) => {
                data.append('filtration_data[verification_statuses]['+i+']', x);
            })
        );
        Object.entries(filters).forEach(([key,value]) => {
            if (!['verification_statuses', 'q','account_managers',
                'has_wallet_balance'].includes(key)) {
                data.append(`filtration_data[${key}]`, value);
            } 
        });
        banner_web_ar.files.length &&
            data.append('banner_image_ar', banner_web_ar.files[0]);
        banner_web_en.files.length &&
            data.append('banner_image_en', banner_web_en.files[0]);

        banner_mobile_ar.files.length &&
            data.append('banner_image_mobile_ar', banner_mobile_ar.files[0]);
        banner_mobile_en.files.length &&
            data.append('banner_image_mobile_en', banner_mobile_en.files[0]);
        if(is_all_entity_types_selected === 'selected') {
            entity_types?.value.forEach((e, i) => {
                data.append('personalized_entity_types['+i+']', e.id);
            });
        }
        if(is_all_cities_selected === 'selected') {
            cities?.value.forEach((e, i) => {
                data.append('cities['+i+']', e.id);
            });
        }
        const res = await POST({
            endpoint: '/marketing/banners',
            data,
            headers: {
                'Idempotency-Key': idempotencyKey
            }
        });

        set_loading(false);
        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            set_created(res?.data.id);
        }
    }
    };

    if (created) {
        return <Redirect to="/banners/list" />;
    }

    return (
        <div className="banners-form-view">
            <CustomPrompt>
                <ViewHeader title="Create new banner">
                    <div className="flex__ai-center">
                        <Sop id="25323"/>
                        <Button variant="primary" onClick={submit}>
                    Save changes
                        </Button>
                    </div>
                </ViewHeader>

                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}

                <Card>
                    <div className="px-3 py-2">
                        <h3 className="divider-title">General</h3>
                        <Row className="align-items-md-end">
                            <Col lg={6}>
                                <Toggler {...status} />
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_ar} />
                                        {loading && validation_errors.name_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_ar}
                                            </p>
                                        )}
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_en} />
                                        {loading && validation_errors.name_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_en}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <Input {...cta} />   
                                {loading && validation_errors.cta && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.cta}
                                            </p>
                                        )}                    
                            </Col>
                            <Col lg={6} style={{ zIndex:200 }}>
                                <DateRangePicker
                                    label="Period"
                                    startDate={start_date}
                                    endDate={end_date}
                                    setStartDate={set_start_date}
                                    setEndDate={set_end_date}
                                />
                                  <Row>
                                    <Col xs={12} sm={6}>
                                    <Select {...banner_types}/>
                                    {loading && validation_errors.banner_types && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.banner_types}
                                            </p>
                                        )}
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Input {...priority} />
                                        {loading && validation_errors.priority && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.priority}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                           
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}> <div>
                                <label>Entity types</label> <br />
                                <Radio
                                    onChange={set_is_all_entity_types_selected}
                                    option={is_all_entity_types_selected}
                                    label="All Entity"
                                    value="all"
                                    name="is-all-banner-entity-selected"
                                />
                                <Radio
                                    onChange={set_is_all_entity_types_selected}
                                    option={is_all_entity_types_selected}
                                    label="Select specific entity"
                                    value="selected"
                                    name="is-all-banner-entity-selected"
                                />
                                {is_all_entity_types_selected ===
                                     'selected' &&<>
                                    <Select {...entity_types}/>
                                    <Button 
                                        onClick={()=>{
                                            set_select_all_entity(!select_all_entity);
                                            select_all_entity? entity_types.onChange([]):
                                                entity_types.
                                                    onChange(GlobalsSlice.entity_types);
                                        } 
                                        }
                                        variant= {select_all_entity ? 'danger': 'primary'}> 
                                        {select_all_entity ? 'undo' : 'select all'}
                                    </Button></> 
                                }
                            </div></Col>
                            <Col lg={6} style={{ zIndex:100 }}>
                                <div>
                                    <label>Cities</label> <br />
                                    <Radio
                                        onChange={set_is_all_cities_selected}
                                        option={is_all_cities_selected}
                                        label="All Cities"
                                        value="all"
                                        name="is-all-banner-cities-selected"
                                    />
                                    <Radio
                                        onChange={set_is_all_cities_selected}
                                        option={is_all_cities_selected}
                                        label="Select specific city"
                                        value="selected"
                                        name="is-all-banner-cities-selected"
                                    />
                                    {is_all_cities_selected ===
                                     'selected' &&<>
                                        <Select {...cities}/>
                                        <Button
                                            onClick={()=>{
                                                set_select_all_cities(!select_all_cities);
                                                select_all_cities? cities.onChange([]):
                                                    cities.onChange(GlobalsSlice.cities);
                                            } 
                                            }
                                            variant= {select_all_cities ? 'danger': 'primary'}> 
                                            {select_all_cities ? 'undo' : 'select all'}
                                        </Button></> 
                                    }
                                </div>
                            </Col>
                        </Row>
                        <h3 className="divider-title">Controlled Personalized</h3>
                        <Card className="mb-3" style={{ zIndex:50 }}>
                            <Filters
                                filters={[
                                    'account_managers',
                                    'verification_statuses',
                                    'has_wallet_balance',
                                    ...(filters?.has_wallet_balance?.
                                        includes(1) ? ['wallet_balance'] : []),
                                    'created_at_from',
                                    'created_at_to',
                                    'total_orders',
                                    'total_spent',
                                    'fulfilled_orders',
                                    'average_rating',
                                ]}
                                onFilterChange={(filters_values) => {
                                    set_filters(filters_values);
                                }}
                                disableFiltersCaching
                                hide_query_field
                            />
                        </Card>
                        <h3 className="divider-title">Arabic content</h3>
                        <Row>
                            <Col lg={6}>
                                <Input {...title_ar} />
                                {loading && validation_errors.title_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.title_ar}
                                            </p>
                                        )}
                                <Textarea {...body_ar} />
                                {loading && validation_errors.body_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.body_ar}
                                            </p>
                                        )}
                                
                            </Col>
                            <Col lg={6}>
                                <FileUpload {...banner_web_ar} />
                                {loading && validation_errors.banner_web_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.banner_web_ar}
                                            </p>
                                        )}
                                <FileUpload {...banner_mobile_ar} />
                                {loading && validation_errors.banner_mobile_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.banner_mobile_ar}
                                            </p>
                                        )}
                            </Col>
                        </Row>
                        <h3 className="divider-title">English content</h3>
                        <Row>
                            <Col lg={6}>
                                <Input {...title_en} />
                                {loading && validation_errors.title_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.title_en}
                                            </p>
                                        )}
                                <Textarea {...body_en} />
                                {loading && validation_errors.body_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.body_en}
                                            </p>
                                        )}
                            </Col>
                            <Col lg={6}>
                                <FileUpload {...banner_web_en} />
                                {loading && validation_errors.banner_web_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.banner_web_en}
                                            </p>
                                        )}
                                <FileUpload {...banner_mobile_en} />
                                {loading && validation_errors.banner_mobile_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.banner_mobile_en}
                                            </p>
                                        )}
                            </Col>
                        </Row>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
