/* eslint-disable max-len */
import React, { useEffect, useState ,useRef } from 'react';
import {
    Card,
    Row,
    Col,
    Toggler,
    Select,
    Checkbox,
    Input,
    FileUpload,
    DatePicker,
    Textarea,
    Slug,
    ApiAsyncSelect,
    Tags,
    Modal,
    CustomPrompt,
    ShowErrors,
    CustomAttributes,
    CreatableTextSelect,
    PriceHistoryModal,
    Link,
} from '@components';
import { randomId, videoValidation } from '@utils';
import { useSelector, useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import VisibilityTypeProvider from '../context/VisibilityType';
import isEqual from 'lodash.isequal';
import FieldVisibility from './FieldVisibility';
import HistoryIcon from '@assets/icons/history.svg';
import { useParams } from 'react-router';
import useQuery from '@hooks/useQuery';
import DiscountRanges from './DiscountRanges';

const defaultValues = {
    is_active: true,
    name_ar: '',
    name_en: '',
    description_ar: '',
    description_en: '',
    plan: '',
    /** @todo check with team why array */
    brand: [],
    categories: [],
    price: '',
    suggested_retail_price: '',
    images: [],
    teaser_images: [],
    teaser_background_color: '',
    video: [],
    tags: [],
    quantity_prices: [],
    vendor_code: '',
    upc_code: '',
    order_min_quantity: '',
    order_max_quantity: '',
    order_quantity_step: '',
    stock: '',
    applied_stock_type: {},
    is_featured: false,
    is_exclusive: false,
    has_taxes: true,
    allow_backorder: false,
    is_returnable: false,
    is_owned_by_disty: false,
    vendor: null,
    units_per_pack: '',
    packaging_weight: '',
    packaging_weight_unit: '',
    item_weight: '',
    item_weight_unit: '',
    item_volume: '',
    item_volume_unit: '',
    boxes_per_vendor_carton: '',
    shelf_life: '',
    shelf_life_unit: '',
    shelf_life_details: '',
    vendor_notes_ar: '',
    vendor_notes_en: '',
    packaging_height: '',
    packaging_length: '',
    packaging_width: '',
    seo_link: '',
    seo_page_title_ar: '',
    seo_page_title_en: '',
    seo_meta_keywords_ar: [],
    seo_meta_keywords_en: [],
    seo_meta_description_ar: '',
    seo_meta_description_en: '',
    custom_attributes: [],
    range_percentage: [],
    storage_conditions: [],
    accessories: [],
    storage_instructions: '',
    delivery_type_id: '',
    packaging_stackable: true,
    packaging_max_stackable_level: '',
    packaging_combinable: true,
    model_number: '',
    serial_number: '',
    screen_size: '',
    processor: '',
    operating_system: '',
    general_info_en: '',
    general_info_ar: '',
    trade_mark_en: '',
    trade_mark_ar: '',
    model_name_en: '',
    model_name_ar: '',
    wireless_carrier_en: '',
    wireless_carrier_ar: '',
    cellular_technology_ar: '',
    storage_capacity_en: '',
    storage_capacity_ar: '',
    communication_technology_en: '',
    communication_technology_ar: '',
    color_en: '',
    color_ar: '',
    network_technology_en: '',
    network_technology_ar: '',
    port_type_en: '',
    port_type_ar: '',
    udf1_en: '',
    udf1_ar: '',
    udf2_en: '',
    udf2_ar: '',
    udf3_en: '',
    udf3_ar: '',
    udf4_en: '',
    udf4_ar: '',
};

const APPLIED_STOCK_TYPES = [
    {
        id: 1,
        label: 'Merchant',
        value: 'vendor',
    },
    {
        id: 2,
        label: 'Warehouse',
        value: 'warehouse',
    },
    {
        id: 3,
        label: 'None',
        value: 'NONE',
    },
];

export default function BaseProductForm({
    errors,
    children,
    prepend,
    initialValues,
    variant = 'base',
    onFieldChange,
    showErrors,
    set_is_valid,
    showDiscountRanges,
}) {
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const { data: { data: storage_res } = { data: [] }, isLoading: storage_loading } =
        useQuery('/storage-condition-type');
    const { data: { data: delivery_res } = { data: [] }, isLoading: delivery_loading } =
        useQuery('/delivery-type');
    const { data: { data: accessories_res } = { data: [] }, isLoading: accessories_loading } =
        useQuery('/product-accessories');
    const active_accessories = accessories_res?.filter((a) => a.is_active);
    const prevInitialValues = React.useRef();
    const dispatch = useDispatch();
    const [values, setValues] = React.useState(
        normalizeBaseFormValues({ ...defaultValues, ...initialValues }),
    );
    // get query params
    const { id } = useParams();

    /**
     *
     * @param {keyof typeof values} field
     * @param {any} value
     */
    const onChange = (field, value) => {
        setValues((prev) => {
            const newValues = { ...prev, [field]: value };
            onFieldChange?.(newValues, field);

            return newValues;
        });
    };
    const debounceTimeout = useRef(null);

    const debounce = (func, delay) => {
        return (...args) => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    const [validationErrors, setValidationErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!values.name_ar || !/^[0-9\u0600-\u06FF\s]*$/.test(values.name_ar))
            newErrors.name_ar =
                'The name  (arabic) field is required and must  contain only arabic letters.';
        if (!values.name_en || !/^[a-zA-Z0-9\s]*$/.test(values.name_en))
            newErrors.name_en =
                'The name (english) field is required and must  contain only english letters.';

        if (!values.delivery_type_id) newErrors.delivery_type_id = 'delivery type  is required';
        if (values.categories.length == 0) newErrors.categories = 'categories field is required';
        if (!values.release_date) newErrors.release_date = 'release date is required';
        if (!values.order_quantity_step)
            newErrors.order_quantity_step = 'order quantity step is required';
        if (!values.price) newErrors.price = 'price is required';
        if (!values.stock) newErrors.stock = 'stock is required';
        if (!values.boxes_per_vendor_carton)
            newErrors.boxes_per_vendor_carton = 'boxes per merchant carton field is required';
        if (!values.units_per_pack) newErrors.units_per_pack = 'units per pack field is required';
        if (values.storage_conditions.length == 0)
            newErrors.storage_conditions = 'storage conditions is required';
        if (values.images.length == 0)
            newErrors.images = 'you have to add at least one picture to the product listing';
        if (values.vendor == null) newErrors.vendor_id = 'merchant field is required';
        if (!values.packaging_weight) {
            newErrors.packaging_weight_unit = 'The packaging weight field is required';
        } else if (values.packaging_weight && !values.packaging_weight_unit) {
            newErrors.packaging_weight_unit =
                'The packaging weight unit field is required when packaging weight is present.';
        }
        if (!values.item_weight) {
            newErrors.item_weight_unit = 'The Item weight field is required';
        } else if (values.item_weight && !values.item_weight_unit) {
            newErrors.item_weight_unit =
                'The Item weight unit field is required when Item weight is present.';
        }
        if (!values.item_volume) {
            newErrors.item_volume_unit = 'The Item volume  field is required';
        } else if (values.item_volume && !values.item_volume_unit) {
            newErrors.item_volume_unit =
                'The Item volume unit field is required when Item volume is present.';
        }
        if (!values.order_max_quantity) {
            newErrors.order_max_quantity = 'Order max quantity is required.';
        } else if (
            values.order_quantity_step &&
            values.order_max_quantity % values.order_quantity_step !== 0
        ) {
            newErrors.order_max_quantity =
                'Order max quantity must be a multiple of order quantity step.';
        }

        if (!values.order_min_quantity) {
            newErrors.order_min_quantity = 'Order min quantity is required.';
        } else if (
            values.order_quantity_step &&
            values.order_min_quantity % values.order_quantity_step !== 0
        ) {
            newErrors.order_min_quantity =
                'Order min quantity must be a multiple of order quantity step.';
        }

        if (
            values.order_max_quantity &&
            values.order_min_quantity &&
            parseInt(values.order_max_quantity) <= parseInt(values.order_min_quantity)
        ) {
            newErrors.order_max_quantity =
                'Order max quantity must be greater than order min quantity.';
        }
        if (values.custom_attributes) {
            values.custom_attributes.forEach((attribute, index) => {
                if (!attribute.value_ar) {
                    newErrors[`value_ar_${index}`] = 'value ar is required';
                }
                if (!attribute.value_en) {
                    newErrors[`value_en_${index}`] = 'value en is required';
                }
            });
        }
        // if (!values.range_percentage || values.range_percentage.length === 0) {
        //     newErrors.range_percentage = 'At least one discount range must be added.';
        // } else {
        //     values.range_percentage.forEach((range) => {
        //         if (range.min_quantity <= 0 || range.max_quantity <= 0 || range.percentage <= 0) {
        //             newErrors.range_values = 'All values must be greater than zero.';
        //         }
        //        else if (parseInt(range.max_quantity) <= parseInt(range.min_quantity )) {
        //             newErrors.range_values = 'Max quantity must be greater than min quantity.';
        //         }
        //     });
        // }
        if (values.range_percentage && values.range_percentage.length > 0) {
            values.range_percentage.forEach((range) => {
                if (range.min_quantity <= 0 || range.max_quantity <= 0 || range.percentage <= 0) {
                    newErrors.range_values = 'All discount range values must be greater than zero.';
                } else if (parseInt(range.max_quantity) <= parseInt(range.min_quantity)) {
                    newErrors.range_values = 'Max quantity must be greater than min quantity.';
                }
            });
        }
        setValidationErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    /** @param {keyof typeof values} key an alias of onChange */
    const onChangeByKey = (key) => (val) => {
        onChange(key, val);
        if (validationErrors[key]) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [key]: '',
            }));
        }
    };



    useEffect(() => {
        set_is_valid(validate());
    }, [values]);

    const onExclusiveChange = (val, confirmed) => {
        if (val && !confirmed) {
            dispatch(
                setModalStatus({
                    modal: 'product__ribbon_modal',
                    status: true,
                }),
            );
        } else {
            onChange('is_exclusive', val);
            onChange('is_featured', !val);
        }
    };

    const onFeaturedChange = (val) => {
        onChange('is_featured', val);
        val && onChange('is_exclusive', false);
    };

    const handlePriceHistoryModal = () => {
        dispatch(
            setModalStatus({
                modal: 'price_history_modal',
                status: true,
            }),
        );
    };

    React.useEffect(() => {
        const isInitialValuesPropsNotEmpty = Object.keys(initialValues || {}).length;
        const isInitialValuesPropUpdate = !isEqual(initialValues, prevInitialValues.current);
        const isInitialValuesPropNotEqualCurrentState = !isEqual(initialValues, values);

        if (
            isInitialValuesPropsNotEmpty &&
            isInitialValuesPropUpdate &&
            isInitialValuesPropNotEqualCurrentState
        ) {
            const newValues = { ...values, ...initialValues };

            setValues(normalizeBaseFormValues(newValues));
            prevInitialValues.current = initialValues;
        }
    }, [initialValues, values]);

    return (
        <VisibilityTypeProvider type={variant}>
            <div className="products-single-view">
                <PriceHistoryModal productID={values?.id} />
                <CustomPrompt>
                    {prepend}

                    {!!Object.keys(errors || {}).length && <ShowErrors errors={errors} />}

                    <Card>
                        <div className="px-3 py-2">
                            <Row>
                                <Col md={8}>
                                    <h3 className="divider-title">Product info</h3>
                                    <Row>
                                        <Col lg={12}>
                                            {values.status === 'new' && (
                                                <FieldVisibility visibility="variant">
                                                    <Toggler
                                                        name="is_active"
                                                        label="Status"
                                                        checked={values.is_active}
                                                        onChange={(checked) =>
                                                            onChange('is_active', checked)
                                                        }
                                                    />
                                                </FieldVisibility>
                                            )}
                                            <FieldVisibility visibility="both">
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            value={values.name_ar}
                                                            name="name_ar"
                                                            label="Name (arabic)"
                                                            tooltip='Please make the product title concise and clean. Follow this guideline "Brand (name of the brand), Product type (chocolate, drink), Product Specifics (e.g., Flavour), size (e.g., 1Lt, 1Kg)"'
                                                            setValue={onChangeByKey('name_ar')}
                                                        />
                                                        {validationErrors.name_ar &&
                                                        showErrors === true ? (
                                                            <p
                                                                style={{
                                                                    color: 'red',
                                                                    margin: '-12px 0 15px',
                                                                }}
                                                            >
                                                                {validationErrors.name_ar}
                                                            </p>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <Input
                                                            value={values.name_en}
                                                            name="name_en"
                                                            label="Name (english)"
                                                            tooltip='Please make the product title concise and clean. Follow this guideline "Brand (name of the brand), Product type (chocolate, drink), Product Specifics (e.g., Flavour), size (e.g., 1Lt, 1Kg)"'
                                                            setValue={onChangeByKey('name_en')}
                                                        />
                                                        {validationErrors.name_en &&
                                                        showErrors === true ? (
                                                            <p
                                                                style={{
                                                                    color: 'red',
                                                                    margin: '-12px 0 15px',
                                                                }}
                                                            >
                                                                {validationErrors.name_en}
                                                            </p>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Col>
                                                </Row>
                                            </FieldVisibility>
                                            <FieldVisibility visibility="base">
                                                <ApiAsyncSelect
                                                    label="Merchant"
                                                    api="/vendors"
                                                    onlyFetchActiveRecords={false}
                                                    placeholder="Type to Add Merchantnt..."
                                                    labelProperty={(r) => {
                                                        return (
                                                            <div className="custom-option-with-thumbnail">
                                                                <img src={r.logo_ar} />
                                                                <div className="content">
                                                                    <h4>{r.company_name_ar}</h4>
                                                                    <p>
                                                                        {r.email}
                                                                        {r.mobile
                                                                            ? ` | ${r.mobile}`
                                                                            : ''}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                    onChange={onChangeByKey('vendor')}
                                                    value={
                                                        values.vendor?.id
                                                            ? {
                                                                  value: values.vendor?.id,
                                                                  label: values.vendor
                                                                      ?.company_name_ar,
                                                              }
                                                            : null
                                                    }
                                                />
                                                {validationErrors.vendor_id &&
                                                showErrors === true ? (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            margin: '-12px 0 15px',
                                                        }}
                                                    >
                                                        {validationErrors.vendor_id}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                            </FieldVisibility>
                                            <FieldVisibility visibility="base">
                                                <Select
                                                    label="Brand"
                                                    value={values.brand}
                                                    options={GlobalsSlice.brands}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={onChangeByKey('brand')}
                                                />
                                            </FieldVisibility>
                                            <FieldVisibility visibility="both">
                                                <Select
                                                    isMulti
                                                    label="Categories"
                                                    value={values.categories}
                                                    options={GlobalsSlice.categories_flat}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={onChangeByKey('categories')}
                                                />
                                                {validationErrors.categories &&
                                                showErrors === true ? (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            margin: '-12px 0 15px',
                                                        }}
                                                    >
                                                        {validationErrors.categories}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                            </FieldVisibility>
                                            <FieldVisibility visibility="variant">
                                                <Input
                                                    name="price"
                                                    label={
                                                        <div className="flex__ai-center">
                                                            <span>Price</span>
                                                            {values?.id &&
                                                                values.status === 'old' && (
                                                                    <div
                                                                        className="price-history-icon"
                                                                        onClick={
                                                                            handlePriceHistoryModal
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={HistoryIcon}
                                                                            alt="history"
                                                                        />
                                                                        <span>History</span>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    }
                                                    type="number"
                                                    value={values.price}
                                                    setValue={onChangeByKey('price')}
                                                />
                                                {validationErrors.price && showErrors === true ? (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            margin: '-12px 0 15px',
                                                        }}
                                                    >
                                                        {validationErrors.price}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                            </FieldVisibility>
                                            <FieldVisibility visibility="variant">
                                                <Input
                                                    name="suggested_retail_price"
                                                    label="Suggested retailed price"
                                                    type="number"
                                                    value={values.suggested_retail_price}
                                                    setValue={(val) =>
                                                        onChange('suggested_retail_price', val)
                                                    }
                                                />
                                            </FieldVisibility>
                                            <FieldVisibility>
                                                <Input
                                                    style={{ cursor: 'pointer' }}
                                                    name="teaser_background_color"
                                                    label="Teaser background color"
                                                    type="color"
                                                    value={`#${values.teaser_background_color}` }
                                                    setValue={
                                                        debounce((val) => {
                                                            const colorWithoutHash = val.slice(1);
                                                            onChange('teaser_background_color', colorWithoutHash);
                                                        }, 200)
                                                        
                                                    }
                                                />
                                            </FieldVisibility>
                                            <FieldVisibility visibility="both" view="add">
                                                <FileUpload
                                                    name="teaser_images"
                                                    label="Teaser images"
                                                    files={values.teaser_images ?? []}
                                                    setFiles={onChangeByKey('teaser_images')}
                                                />
                                            </FieldVisibility>
                                            <FieldVisibility visibility="both" view="add">
                                                <FileUpload
                                                    name="images"
                                                    label="Images"
                                                    files={values.images ?? []}
                                                    setFiles={onChangeByKey('images')}
                                                />
                                                {validationErrors.images && showErrors === true ? (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            margin: '-12px 0 15px',
                                                        }}
                                                    >
                                                        {validationErrors.images}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                            </FieldVisibility>
                                            <FieldVisibility visibility="variant" view="add">
                                                <FileUpload
                                                    name="video"
                                                    label="Digital Video"
                                                    files={values.video ?? []}
                                                    validator={(file) => videoValidation(file, 15)}
                                                    allowVideoOnly
                                                    acceptedMimeTypes="video/*"
                                                    hintMessage="Only videos files will be accepted"
                                                    setFiles={onChangeByKey('video')}
                                                    maxFiles={1}
                                                />
                                            </FieldVisibility>
                                        </Col>
                                    </Row>
                                </Col>
                                <FieldVisibility visibility="both">
                                    <Col lg={4}>
                                        <h3 className="divider-title">Tags</h3>
                                        <Tags
                                            name="product_add_tags"
                                            submitted_tags={onChangeByKey('tags')}
                                            selected_tags={values.tags}
                                        />
                                    </Col>
                                </FieldVisibility>
                            </Row>
                            <FieldVisibility visibility="both">
                                <Row>
                                    <Col lg={6}>
                                        <Textarea
                                            name="description_ar"
                                            label="Description (arabic)"
                                            tooltip="Please proofread the description before you submit it"
                                            value={values.description_ar ?? ''}
                                            setValue={onChangeByKey('description_ar')}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <Textarea
                                            name="description_en"
                                            label="Description (english)"
                                            tooltip="Please proofread the description before you submit it"
                                            value={values.description_en ?? ''}
                                            setValue={onChangeByKey('description_en')}
                                        />
                                    </Col>
                                </Row>
                            </FieldVisibility>

                            <h3 className="divider-title">Inventory</h3>
                            <Row>
                                <Col lg={6}>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            name="vendor_code"
                                            label="Merchant code"
                                            value={values.vendor_code}
                                            setValue={onChangeByKey('vendor_code')}
                                        />
                                        <Input
                                            name="upc_code"
                                            label="Barcode (UPC)"
                                            value={values.upc_code}
                                            setValue={onChangeByKey('upc_code')}
                                        />
                                        <Input
                                            name="sku_code"
                                            label="SKU code"
                                            value={values.sku_code}
                                            setValue={onChangeByKey('sku_code')}
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Row>
                                            <Col>
                                                <Input
                                                    name="order_min_quantity"
                                                    label="Min order quantity"
                                                    type="number"
                                                    value={values.order_min_quantity}
                                                    setValue={onChangeByKey('order_min_quantity')}
                                                />
                                                {validationErrors.order_min_quantity &&
                                                showErrors === true ? (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            margin: '-12px 0 15px',
                                                        }}
                                                    >
                                                        {validationErrors.order_min_quantity}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                            </Col>
                                            <Col>
                                                <Input
                                                    name="order_max_quantity"
                                                    label="Max order quantity"
                                                    type="number"
                                                    value={values.order_max_quantity}
                                                    setValue={onChangeByKey('order_max_quantity')}
                                                />
                                                {validationErrors.order_max_quantity &&
                                                showErrors === true ? (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            margin: '-12px 0 15px',
                                                        }}
                                                    >
                                                        {validationErrors.order_max_quantity}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                            </Col>
                                        </Row>
                                    </FieldVisibility>
                                </Col>
                                <Col lg={6}>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            name="order_quantity_step"
                                            label="Quantity step"
                                            type="number"
                                            value={values.order_quantity_step}
                                            setValue={onChangeByKey('order_quantity_step')}
                                        />
                                        {validationErrors.order_quantity_step &&
                                        showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.order_quantity_step}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            name="stock"
                                            label="Stock"
                                            type="number"
                                            value={values.stock}
                                            setValue={onChangeByKey('stock')}
                                            disabled={values.status === 'old'}
                                            note={
                                                values.status === 'old' && (
                                                    <span>
                                                        You can not change the stock of in update
                                                        mode.
                                                        <br />
                                                        Please use the{' '}
                                                        <Link
                                                            href={`/products/${id}?tab_index=8`}
                                                            className="link-style"
                                                        >
                                                            Stock tab
                                                        </Link>{' '}
                                                        instead.
                                                    </span>
                                                )
                                            }
                                        />
                                        {validationErrors.stock && showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.stock}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="base">
                                        <DatePicker
                                            selectedDate={values.release_date}
                                            label="Release date"
                                            onChange={onChangeByKey('release_date')}
                                        />
                                        {validationErrors.release_date && showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validationErrors.release_date}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Select
                                            name="applied_stock_type"
                                            label="Applied stock type"
                                            value={
                                                APPLIED_STOCK_TYPES.find(
                                                    (type) =>
                                                        type.value === values.applied_stock_type,
                                                ) || null
                                            }
                                            options={APPLIED_STOCK_TYPES}
                                            getOptionValue={(option) => option.value}
                                            onChange={(val) => {
                                                onChangeByKey('applied_stock_type')(val.value);
                                            }}
                                        />
                                    </FieldVisibility>
                                </Col>
                            </Row>

                            <h3 className="divider-title">Product ribbons</h3>
                            <Row>
                                <Col lg={2}>
                                    <FieldVisibility visibility="variant">
                                        <Checkbox
                                            name="is_featured"
                                            checked={values.is_featured}
                                            onChange={onFeaturedChange}
                                            // disabled={values.is_exclusive}
                                            label="Featured"
                                        />
                                    </FieldVisibility>
                                </Col>
                                <Col lg={2}>
                                    <FieldVisibility visibility="variant">
                                        <Checkbox
                                            name="is_exclusive"
                                            checked={values.is_exclusive}
                                            onChange={onExclusiveChange}
                                            // disabled={values.is_featured}
                                            label="Exclusive"
                                        />
                                    </FieldVisibility>
                                </Col>
                                <Col lg={2}>
                                    <FieldVisibility visibility="base">
                                        <Checkbox
                                            name="has_taxes"
                                            checked={values.has_taxes}
                                            onChange={onChangeByKey('has_taxes')}
                                            label="Has taxes"
                                        />
                                    </FieldVisibility>
                                </Col>
                                <Col lg={2}>
                                    <FieldVisibility visibility="base">
                                        <Checkbox
                                            name="allow_backorder"
                                            checked={values.allow_backorder}
                                            onChange={onChangeByKey('allow_backorder')}
                                            label="Allow backorder"
                                        />
                                    </FieldVisibility>
                                </Col>
                                <Col lg={2}>
                                    <FieldVisibility visibility="base">
                                        <Checkbox
                                            name="is_returnable"
                                            checked={values.is_returnable}
                                            onChange={onChangeByKey('is_returnable')}
                                            label="Returnable"
                                        />
                                    </FieldVisibility>
                                </Col>
                            </Row>
                            <h3 className="divider-title">Product flags</h3>
                            <Row>
                                <Col lg={2}>
                                    <FieldVisibility visibility="variant">
                                        <Checkbox
                                            name="is_owned_by_disty"
                                            checked={values.is_owned_by_disty}
                                            onChange={onChangeByKey('is_owned_by_disty')}
                                            label="is owned by disty"
                                        />
                                    </FieldVisibility>
                                </Col>
                            </Row>
                            <h3 className="divider-title">
                                Specification
                                <i>
                                    (Please fill in as much information as you have. The more
                                    information we have, the better sales we get.)
                                </i>
                            </h3>
                            <Row>
                                <Col lg={6}>
                                    <FieldVisibility visibility="variant">
                                        <Select
                                            name="package_type_id"
                                            label="Package type"
                                            value={GlobalsSlice.packages_types.find(
                                                (item) => item.id === values.package_type_id,
                                            )}
                                            getOptionValue={(option) => option.id}
                                            onChange={(val) =>
                                                onChangeByKey('package_type_id')(val.id)
                                            }
                                            options={GlobalsSlice.packages_types}
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Select
                                            isMulti
                                            disabled={storage_loading}
                                            label="Storage Condition Types"
                                            value={values.storage_conditions}
                                            options={storage_res.map((x) => ({
                                                id: x.id,
                                                label: x.name_ar,
                                            }))}
                                            getOptionValue={(option) => option.id}
                                            onChange={onChangeByKey('storage_conditions')}
                                        />
                                        {validationErrors.storage_conditions &&
                                        showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.storage_conditions}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Unit amount per pack"
                                            type="number"
                                            value={values.units_per_pack}
                                            setValue={onChangeByKey('units_per_pack')}
                                        />
                                        {validationErrors.units_per_pack && showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.units_per_pack}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Packaging weight"
                                            type="number"
                                            value={values.packaging_weight}
                                            setValue={onChangeByKey('packaging_weight')}
                                            units={['KG', 'G']}
                                            unit={values.packaging_weight_unit}
                                            setUnit={onChangeByKey('packaging_weight_unit')}
                                        />

                                        {validationErrors.packaging_weight_unit &&
                                        showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.packaging_weight_unit}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Item weight"
                                            type="number"
                                            value={values.item_weight}
                                            setValue={onChangeByKey('item_weight')}
                                            units={['KG', 'G']}
                                            unit={values.item_weight_unit}
                                            setUnit={onChangeByKey('item_weight_unit')}
                                        />
                                        {validationErrors.item_weight_unit &&
                                        showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.item_weight_unit}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Item volume"
                                            type="number"
                                            value={values.item_volume}
                                            setValue={onChangeByKey('item_volume')}
                                            units={['L', 'ML']}
                                            unit={values.item_volume_unit}
                                            setUnit={onChangeByKey('item_volume_unit')}
                                        />
                                        {validationErrors.item_volume_unit &&
                                        showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.item_volume_unit}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Boxes per merchant carton"
                                            type="number"
                                            value={values.boxes_per_vendor_carton}
                                            setValue={onChangeByKey('boxes_per_vendor_carton')}
                                        />
                                        {validationErrors.boxes_per_vendor_carton &&
                                        showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.boxes_per_vendor_carton}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    {/* <FieldVisibility visibility="variant">
                                        <Input
                                            label="Shelf life"
                                            type="number"
                                            value={values.shelf_life}
                                            setValue={onChangeByKey('shelf_life')}
                                            units={['D', 'M', 'Y']}
                                            unit={values.shelf_life_unit}
                                            setUnit={onChangeByKey('shelf_life_unit')}
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Textarea
                                            name="shelf_life_details"
                                            label="Shelf life details"
                                            value={values.shelf_life_details}
                                            setValue={onChangeByKey('shelf_life_details')}
                                        />
                                        </FieldVisibility>*/}
                                </Col>
                                <Col lg={6}>
                                    <FieldVisibility visibility="variant">
                                        {values.vendor?.plan_type?.code == 'multiple' && (
                                            <Select
                                                label="Disty Take Rate"
                                                value={values.plan}
                                                options={GlobalsSlice.plans}
                                                getOptionValue={(option) => option.id}
                                                onChange={onChangeByKey('plan')}
                                            />
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Select
                                            name="delivery_type_id"
                                            label="Delivery type"
                                            disabled={delivery_loading}
                                            value={values?.delivery_type_id}
                                            getOptionValue={(option) => option.id}
                                            onChange={onChangeByKey('delivery_type_id')}
                                            options={delivery_res.map((x) => ({
                                                id: x.id,
                                                label: x.name_ar,
                                            }))}
                                        />
                                        {validationErrors.delivery_type_id &&
                                        showErrors === true ? (
                                            <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                                {validationErrors.delivery_type_id}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Textarea
                                            name="storage_instructions"
                                            label="Storage instructions"
                                            value={values.storage_instructions}
                                            setValue={onChangeByKey('storage_instructions')}
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Select
                                            isMulti
                                            disabled={accessories_loading}
                                            label="Accessories"
                                            value={values.accessories}
                                            options={active_accessories?.map((x) => ({
                                                id: x.id,
                                                label: x.name_ar,
                                            }))}
                                            getOptionValue={(option) => option.id}
                                            onChange={onChangeByKey('accessories')}
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            name="vendor_notes_ar"
                                            label="Merchant notes (arabic)"
                                            value={values.vendor_notes_ar}
                                            setValue={onChangeByKey('vendor_notes_ar')}
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            name="vendor_notes_en"
                                            label="Merchant notes (english)"
                                            value={values.vendor_notes_en}
                                            setValue={onChangeByKey('vendor_notes_en')}
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Package Length"
                                            name="packaging_length"
                                            value={values.packaging_length}
                                            setValue={onChangeByKey('packaging_length')}
                                            type="number"
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Package width"
                                            name="packaging_width"
                                            value={values.packaging_width}
                                            setValue={onChangeByKey('packaging_width')}
                                            type="number"
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Input
                                            label="Package height"
                                            name="packaging_height"
                                            value={values.packaging_height}
                                            setValue={onChangeByKey('packaging_height')}
                                            type="number"
                                        />
                                    </FieldVisibility>
                                    <FieldVisibility visibility="variant">
                                        <Checkbox
                                            label="Is Stackable"
                                            name="packaging_stackable"
                                            checked={values.packaging_stackable}
                                            onChange={onChangeByKey('packaging_stackable')}
                                        />
                                    </FieldVisibility>

                                    <FieldVisibility visibility="variant">
                                        <Checkbox
                                            label="Can be put with other products in the same box?"
                                            name="packaging_combinable"
                                            checked={values.packaging_combinable}
                                            onChange={onChangeByKey('packaging_combinable')}
                                        />
                                    </FieldVisibility>

                                    {values.packaging_stackable && (
                                        <FieldVisibility visibility="variant">
                                            <Input
                                                label="Max Stackable Level"
                                                name="packaging_max_stackable_level"
                                                value={values.packaging_max_stackable_level}
                                                setValue={onChangeByKey(
                                                    'packaging_max_stackable_level',
                                                )}
                                                type="number"
                                            />
                                        </FieldVisibility>
                                    )}
                                </Col>
                            </Row>

                            <FieldVisibility visibility="variant" view="add">
                                <CustomAttributes
                                    set_custom_attributes={onChangeByKey('custom_attributes')}
                                    showErrors={showErrors}
                                    validationErrors={validationErrors}
                                />
                            </FieldVisibility>
                            <FieldVisibility visibility="variant">
                                <h3 className="divider-title">Quantity discounts</h3>
                                {values.status === 'old' ? (
                                    <div className="alert text-center">
                                        Please use the &quot;
                                        <Link
                                            href={`/products/${id}?tab_index=4`}
                                            className="link-style"
                                        >
                                            Quantity discounts
                                        </Link>
                                        &quot; tab to edit quantity discounts.
                                    </div>
                                ) : (
                                    <div className="quantity-discounts-wrapper table-tag-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td className="action-col"></td>
                                                    <td className="quantity-col">Quantity</td>
                                                    <td className="value-col">Price</td>
                                                    <td className="empty-col">
                                                        <button
                                                            className="btn btn-info"
                                                            type="button"
                                                            onClick={() =>
                                                                onChange('quantity_prices', [
                                                                    ...values.quantity_prices,
                                                                    {
                                                                        id: randomId(),
                                                                        quantity: 0,
                                                                        price: 0,
                                                                    },
                                                                ])
                                                            }
                                                        >
                                                            + New quantity discount
                                                        </button>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values.quantity_prices.map((d, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            {i !== 0 && (
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'quantity_prices',
                                                                            values.quantity_prices.filter(
                                                                                (item) =>
                                                                                    item.id !==
                                                                                    d.id,
                                                                            ),
                                                                        )
                                                                    }
                                                                >
                                                                    -
                                                                </button>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                value={d.quantity}
                                                                setValue={(val) => {
                                                                    onChange('quantity_prices', [
                                                                        ...values.quantity_prices.map(
                                                                            (item) => {
                                                                                if (
                                                                                    item.id === d.id
                                                                                ) {
                                                                                    item[
                                                                                        'quantity'
                                                                                    ] = val;
                                                                                }
                                                                                return item;
                                                                            },
                                                                        ),
                                                                    ]);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                value={d.price}
                                                                setValue={(val) =>
                                                                    onChange('quantity_prices', [
                                                                        ...values.quantity_prices.map(
                                                                            (item) => {
                                                                                if (
                                                                                    item.id === d.id
                                                                                ) {
                                                                                    item['price'] =
                                                                                        val;
                                                                                }
                                                                                return item;
                                                                            },
                                                                        ),
                                                                    ])
                                                                }
                                                            />
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </FieldVisibility>
                            <FieldVisibility visibility="variant">
                                <Row className="my-3">
                                    <Col lg={12}>
                                        <h3 className="divider-title">SEO</h3>
                                        <Slug
                                            label="Product link"
                                            prefix="/"
                                            setValue={onChangeByKey('seo_link')}
                                            value={values.seo_link}
                                        />
                                    </Col>
                                </Row>
                            </FieldVisibility>
                            <FieldVisibility visibility="variant">
                                <Row>
                                    <Col lg={6}>
                                        <h3 className="divider-title">Arabic SEO</h3>
                                        <Input
                                            label="Product page"
                                            name="seo_page_title_ar"
                                            setValue={onChangeByKey('seo_page_title_ar')}
                                            value={values.seo_page_title_ar}
                                        />
                                        <CreatableTextSelect
                                            label="Meta tags"
                                            value={values.seo_meta_keywords_ar}
                                            setValue={(callback) =>
                                                onChangeByKey('seo_meta_keywords_ar')(
                                                    // function or value :)
                                                    typeof callback === 'function'
                                                        ? callback(
                                                              values.seo_meta_keywords_ar || [],
                                                          )
                                                        : callback,
                                                )
                                            }
                                        />
                                        <Textarea
                                            label="Meta description"
                                            name="seo_meta_description_ar"
                                            value={values.seo_meta_description_ar}
                                            setValue={onChangeByKey('seo_meta_description_ar')}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <h3 className="divider-title">English SEO</h3>
                                        <Input
                                            label="Product page"
                                            name="seo_page_title_en"
                                            setValue={onChangeByKey('seo_page_title_en')}
                                            value={values.seo_page_title_en}
                                        />
                                        <CreatableTextSelect
                                            label="Meta tags"
                                            value={values.seo_meta_keywords_en ?? ''}
                                            setValue={(callback) =>
                                                onChangeByKey('seo_meta_keywords_en')(
                                                    // function or value :)
                                                    typeof callback === 'function'
                                                        ? callback(
                                                              values.seo_meta_keywords_en || [],
                                                          )
                                                        : callback,
                                                )
                                            }
                                        />
                                        <Textarea
                                            label="Meta description"
                                            name="seo_meta_description_en"
                                            value={values.seo_meta_description_en}
                                            setValue={onChangeByKey('seo_meta_description_en')}
                                        />
                                    </Col>
                                </Row>
                            </FieldVisibility>
                            {showDiscountRanges && (
                                <DiscountRanges
                                    values={values}
                                    onChange={onChange}
                                    showErrors={showErrors}
                                    validationErrors={validationErrors}
                                />
                            )}
                            {showErrors && validationErrors && (
                                <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                    {validationErrors.range_percentage}
                                </p>
                            )}

                            <h3 className="divider-title">Product Details</h3>
                            <Row>
                                <Col lg={12}>
                                    <FieldVisibility>
                                        <Input
                                            name="model_number"
                                            label="model number"
                                            placeholder=" please enter model number"
                                            value={values.model_number}
                                            setValue={onChangeByKey('model_number')}
                                        />
                                    </FieldVisibility>
                                </Col>
                                <Col lg={6}>
                                    <FieldVisibility>
                                        <Input
                                            name="serial_number"
                                            label="serial number"
                                            placeholder=" please enter serial number"
                                            value={values.serial_number}
                                            setValue={onChangeByKey('serial_number')}
                                        />
                                        <Input
                                            name="screen_size"
                                            label="screen size"
                                            placeholder=" please enter screen size"
                                            value={values.screen_size}
                                            setValue={onChangeByKey('screen_size')}
                                        />
                                        <Input
                                            name="general_info_en"
                                            label="general info en"
                                            placeholder=" please enter general info en"
                                            value={values.general_info_en}
                                            setValue={onChangeByKey('general_info_en')}
                                        />
                                        <Input
                                            name="trade_mark_en"
                                            label="trade mark en"
                                            placeholder=" please enter trade mark en"
                                            value={values.trade_mark_en}
                                            setValue={onChangeByKey('trade_mark_en')}
                                        />
                                        <Input
                                            name="model_name_en"
                                            label="model name en"
                                            placeholder=" please enter model name en"
                                            value={values.model_name_en}
                                            setValue={onChangeByKey('model_name_en')}
                                        />
                                        <Input
                                            name="wireless_carrier_en"
                                            label="wireless carrier en"
                                            placeholder=" please enter  wireless carrier en"
                                            value={values.wireless_carrier_en}
                                            setValue={onChangeByKey('wireless_carrier_en')}
                                        />
                                        <Input
                                            name="cellular_technology_en"
                                            label="cellular technology en"
                                            placeholder=" please enter cellular technology en"
                                            value={values.cellular_technology_en}
                                            setValue={onChangeByKey('cellular_technology_en')}
                                        />
                                        <Input
                                            name="storage_capacity_en"
                                            label="storage capacity en"
                                            placeholder=" please enter storage capacity en"
                                            value={values.storage_capacity_en}
                                            setValue={onChangeByKey('storage_capacity_en')}
                                        />
                                        <Input
                                            name="communication_technology_en"
                                            label="communication technology en"
                                            placeholder=" please enter communication technology en"
                                            value={values.communication_technology_en}
                                            setValue={onChangeByKey('communication_technology_en')}
                                        />
                                        <Input
                                            name="color_en"
                                            label="color en"
                                            placeholder=" please enter color en"
                                            value={values.color_en}
                                            setValue={onChangeByKey('color_en')}
                                        />
                                        <Input
                                            name="network_technology_en"
                                            label="network technology en"
                                            placeholder=" please enter network technology en"
                                            value={values.network_technology_en}
                                            setValue={onChangeByKey('network_technology_en')}
                                        />
                                        <Input
                                            name="port_type_en"
                                            label="port type en"
                                            placeholder=" please enter port type en"
                                            value={values.port_type_en}
                                            setValue={onChangeByKey('port_type_en')}
                                        />
                                        <Input
                                            name="udf1_en"
                                            label="User defined value1 en"
                                            placeholder=" please enter User defined value1 en"
                                            value={values.udf1_en}
                                            setValue={onChangeByKey('udf1_en')}
                                        />
                                        <Input
                                            name="udf2_en"
                                            label="User defined value2 en"
                                            placeholder=" please enter User defined value2 en"
                                            value={values.udf2_en}
                                            setValue={onChangeByKey('udf2_en')}
                                        />
                                        <Input
                                            name="udf3_en"
                                            label="User defined value3 en"
                                            placeholder=" please enter User defined value3 en"
                                            value={values.udf3_en}
                                            setValue={onChangeByKey('udf3_en')}
                                        />
                                        <Input
                                            name="udf4_en"
                                            label="User defined value4 en"
                                            placeholder=" please enter User defined value4 en"
                                            value={values.udf4_en}
                                            setValue={onChangeByKey('udf4_en')}
                                        />
                                    </FieldVisibility>
                                </Col>
                                <Col lg={6}>
                                    <FieldVisibility>
                                        <Input
                                            name="processor"
                                            label="processor"
                                            placeholder=" please enter processor"
                                            value={values.processor}
                                            setValue={onChangeByKey('processor')}
                                        />

                                        <Input
                                            name="operating_system"
                                            label="operating system"
                                            placeholder=" please enter operating system"
                                            value={values.operating_system}
                                            setValue={onChangeByKey('operating_system')}
                                        />
                                        <Input
                                            name="general_info_ar"
                                            label="general info ar"
                                            placeholder=" please enter general info ar"
                                            value={values.general_info_ar}
                                            setValue={onChangeByKey('general_info_ar')}
                                        />
                                        <Input
                                            name="trade_mark_ar"
                                            label="trade mark ar"
                                            placeholder=" please enter trade mark ar"
                                            value={values.trade_mark_ar}
                                            setValue={onChangeByKey('trade_mark_ar')}
                                        />
                                        <Input
                                            name="model_name_ar"
                                            label="model name ar"
                                            placeholder=" please enter model name ar"
                                            value={values.model_name_ar}
                                            setValue={onChangeByKey('model_name_ar')}
                                        />
                                        <Input
                                            name="wireless_carrier_ar"
                                            label="wireless carrier ar"
                                            placeholder=" please enter wireless carrier ar"
                                            value={values.wireless_carrier_ar}
                                            setValue={onChangeByKey('wireless_carrier_ar')}
                                        />
                                        <Input
                                            name="cellular_technology_ar"
                                            label="cellular technology ar"
                                            placeholder=" please enter  cellular technology ar"
                                            value={values.cellular_technology_ar}
                                            setValue={onChangeByKey('cellular_technology_ar')}
                                        />
                                        <Input
                                            name="storage_capacity_ar"
                                            label="storage capacity ar"
                                            placeholder=" please enter storage capacity ar"
                                            value={values.storage_capacity_ar}
                                            setValue={onChangeByKey('storage_capacity_ar')}
                                        />
                                        <Input
                                            name="communication_technology_ar"
                                            label="communication technology ar"
                                            placeholder=" please enter communication technology ar"
                                            value={values.communication_technology_ar}
                                            setValue={onChangeByKey('communication_technology_ar')}
                                        />
                                        <Input
                                            name="color_ar"
                                            label="color ar"
                                            placeholder=" please enter color ar"
                                            value={values.color_ar}
                                            setValue={onChangeByKey('color_ar')}
                                        />
                                        <Input
                                            name="network_technology_ar"
                                            label="network technology ar"
                                            placeholder=" please enter network technology ar"
                                            value={values.network_technology_ar}
                                            setValue={onChangeByKey('network_technology_ar')}
                                        />
                                        <Input
                                            name="port_type_ar"
                                            label="port type ar"
                                            placeholder=" please enter  port type ar"
                                            value={values.port_type_ar}
                                            setValue={onChangeByKey('port_type_ar')}
                                        />
                                        <Input
                                            name="udf1_ar"
                                            label="User defined value1 ar "
                                            value={values.udf1_ar}
                                            placeholder=" please enter User defined value1 ar"
                                            setValue={onChangeByKey('udf1_ar')}
                                        />
                                        <Input
                                            name="udf2_ar"
                                            label="User defined value2 ar "
                                            value={values.udf2_ar}
                                            placeholder=" please enter User defined value2 ar"
                                            setValue={onChangeByKey('udf2_ar')}
                                        />
                                        <Input
                                            name="udf3_ar"
                                            label="User defined value3 ar"
                                            placeholder=" please enter User defined value3 ar"
                                            value={values.udf3_ar}
                                            setValue={onChangeByKey('udf3_ar')}
                                        />
                                        <Input
                                            name="udf4_ar"
                                            label="User defined value4 ar"
                                            placeholder=" please enter User defined value4 ar"
                                            value={values.udf4_ar}
                                            setValue={onChangeByKey('udf4_ar')}
                                        />
                                    </FieldVisibility>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                    {children}
                    <Modal
                        title={'Attention!'}
                        name="product__ribbon_modal"
                        onSubmit={(closeModal) => {
                            onExclusiveChange(true, true);
                            closeModal('product__ribbon_modal');
                        }}
                    >
                        When you select this ribbon, It will be the only ribbon to be displayed on
                        the product listing page. Are you sure you want to proceed?
                    </Modal>
                </CustomPrompt>
            </div>
        </VisibilityTypeProvider>
    );
}

const normalizeBaseFormValues = (values) => ({
    ...values,

    categories: values?.categories?.map(({ id, label, name_en }) => ({
        id,
        label: label || name_en,
    })),
    plan: !values?.plan?.id
        ? null
        : {
              id: values?.plan?.id,
              label: values?.plan?.name_ar,
          },
    storage_conditions: values?.storage_conditions?.map(({ id, label, name_ar }) => ({
        id,
        label: label || name_ar,
    })),
    delivery_type_id: !values?.delivery_type?.id
        ? null
        : {
              id: values?.delivery_type?.id,
              label: values?.delivery_type?.name_ar,
          },
    accessories: values?.accessories?.map(({ id, label, name_ar }) => ({
        id,
        label: label || name_ar,
    })),
});
