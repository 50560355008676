import { useState } from 'react';
import { Modal, FileUpload, Alert } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { bulkPayoutsPayment, resetBulkPayment } from '@slices/payouts.slice';

const BulkPayment = ({ payouts, onSuccess }) => {
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();
    const errors = PayoutsSlice.bulk_payment_errors;

    const [transfer_receipt, set_transfer_receipt] = useState({
        label: 'Transfer receipt (optional)',
        files: [],
        allowPDF: true,
        setFiles: (val) => set_transfer_receipt({ ...transfer_receipt, files: val }),
    });

    const onSubmit = (closeModal) => {
        dispatch(bulkPayoutsPayment({ payouts, receipts: transfer_receipt.files })).then(
            (isSuccess) => {
                if (isSuccess) {
                    closeModal();
                    onSuccess();
                }
            },
        );
    };

    const reset = () => {
        transfer_receipt.setFiles([]);
        dispatch(resetBulkPayment());
    };

    return (
        <Modal
            title="Bulk Payment"
            name="payouts_bulk_payment_modal"
            onSubmit={onSubmit}
            loading={PayoutsSlice.bulk_payment_loading}
            onClose={reset}
        >
            <Alert variant="warning">
                <Alert.Heading>Confirmation</Alert.Heading>
                Are you sure you want to change the status of the selected payouts to fully paid?,
                Be aware that all the selected payouts will have zero payment remaining
            </Alert>
            <FileUpload {...transfer_receipt} />

            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default BulkPayment;
