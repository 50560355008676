import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import VendorsLeadsListView from './MerchantsLeadsListView';
import { Helmet } from 'react-helmet';

export default function VendorsLeadsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/vendors-leads/list">
                <Helmet>
                    <title>Merchants Leads | Distydmin Dashboard</title>
                </Helmet>
                <VendorsLeadsListView />
            </ProtectedRoute>   
        </Switch>
    );
}
