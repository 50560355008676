import React from 'react';
import { DataTable } from '@components';
import { GET } from '@utils';

export default function CustomerAddressesTab({ id }) {
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: `/customers/${id}/addresses`,
            });
            set_data(res?.data || []);
            set_loading(false);
        };
        fetchData();
    }, [id]);

    //     dministrative_area_level2: "رماح"
    // city: null
    // contact_mobile: "533333333"
    // contact_name: "asd"
    // details: "ddd"
    // district: null
    // formatted_address: "15878، السعودية"
    // id: 175
    // is_default: false

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: 'name',
            cell: (row) => `${row?.name}${row.is_default ? ' (DEFAULT)' : ''}`,
        },
        {
            name: 'City/District/Area',
            selector: 'city',
            cell: (row) =>
                `${row.city?.name_ar || '-'}
                ${row.district?.name_ar ? `/${row.district?.name_ar}` : '-'}
                ${
    row.administrative_area_level2
        ? `/${row.administrative_area_level2}`
        : '-'
}
                `,
        },
        {
            name: 'Formatted address',
            selector: 'formatted_address',
        },
        {
            name: 'Contact',
            selector: 'contact_name',
            cell: (row) => (
                <div className="tr-name">
                    <h5>
                        {row.contact_name}
                        <small>{row.contact_mobile}</small>
                    </h5>
                </div>
            ),
        },
        {
            name: 'Suitable delivery time',
            cell: (row) => row.suitable_delivery_time?.name
        },
        {
            name: 'Special mark',
            selector: 'special_mark',
        },
        {
            name: 'Location',
            cell: (row) => (
                <a
                    // eslint-disable-next-line max-len
                    href={`https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`}
                    target="_blank"
                    rel="noreferrer"
                >
					Open in Google Maps
                </a>
            ),
        },
        {
            name: 'Details',
            selector: 'details',
        },
        {
            name: 'Special mark',
            selector: 'special_mark',
        },
    ];

    return (
        <DataTable
            data={data}
            columns={columns}
            loading={loading}
        />
    );
}
