import React from 'react';
import helpIcon from '@assets/icons/help.svg';

function Sop({ id , className = '' }) {
    return (
        <a className={className}
            // eslint-disable-next-line indent
href="#"

            target="blank">
            <img src={helpIcon} alt="help" id={`helpsectioninfo-${id}`} />
        </a>
    );

}

export default Sop;