/* eslint-disable */
import React from 'react';
import { Form, Modal } from '@components';
import PropTypes from 'prop-types';
import { POST, DELETE, PATCH } from '@utils';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '../../store/slices/ui.slice';

export default function StatusToggler({
    id,
    title,
    checked,
    path,
    pathOff = null,
    method = 'delete',
    callback = null,
    confirm_with_dialog = true,
    disabled = false,
}) {
    const [state, setState] = React.useState(checked);
    const [loading, set_loading] = React.useState(false);
    const dispatch = useDispatch();

    const onChange = () => {
        if (confirm_with_dialog) {
            dispatch(
                setModalStatus({
                    modal: `change_status_modal_${id}`,
                    status: true,
                }),
            );
        } else {
            onSubmit();
        }
    };

    const onSubmit = async () => {
        set_loading(true);
        let res;
        if (state) {
            if (method === 'post') {
                res = await POST({ endpoint: pathOff || path });
            } else if (method === 'patch') {
                res = await PATCH({ endpoint: pathOff || path });
            } else {
                res = await DELETE({ endpoint: pathOff || path });
            }
        } else {
            res = await (method === 'patch' ? PATCH : POST)({ endpoint: path });
        }
        const status = res?.status || res?.status_code || res?.data?.status_code;
        const message = res?.message || res?.data?.message || 'Something went wrong!';
        set_loading(false);
        dispatch(
            setModalStatus({
                modal: `change_status_modal_${id}`,
                status: false,
            }),
        );
        if (status >= 400) {
            toast.error(message);
        } else if (status === 201 || status === 200) {
            setState(!state);
            callback &&
                callback({
                    id: id,
                    status: !state,
                });
        }
    };

    return (
        <>
            <Form.Group className="flex__jc-between">
                <div className="toggler">
                    <input
                        onChange={onChange}
                        checked={state}
                        type="checkbox"
                        disabled={disabled || loading}
                    />
                    <label></label>
                </div>
                <Modal
                    title={
                        title ||
                        `Are you sure you want to
                        ${state ? 'deactivate' : 'activate'} this option?`
                    }
                    name={`change_status_modal_${id}`}
                    variant="warning"
                    onSubmit={onSubmit}
                    loading={loading}
                />
            </Form.Group>
        </>
    );
}

StatusToggler.propTypes = {
    callback: PropTypes.func,
    path: PropTypes.string.isRequired,
    pathOff: PropTypes.string,
    method: PropTypes.string,
    checked: PropTypes.bool,
};
