import { DatePicker } from '@shopify/polaris';
import { useState, useCallback } from 'react';
import { formatDate } from '@utils';

export default function DateFilter({
    value = null,
    set_value = () => {},
}) {
    const formatted_value = value ? new Date(
        value?.split('-').reverse().join('-')
    ) : new Date();
    
    const [{ month, year }, setDate] = useState({
        month: formatted_value.getMonth(),
        year: formatted_value.getFullYear(),
    });
    const [selectedDates, setSelectedDates] = useState({
        start: formatted_value,
        end: formatted_value,
    });

    const handleDateSelection = useCallback(
        (value) => {
            const { start } = value;
            setSelectedDates(value);
            set_value(formatDate(start, 'short'));
        },
        [set_value]
    );

    const handleMonthChange = useCallback((month, year) => {
        setDate({ month, year });
    }, []);

    return (
        <DatePicker
            month={month}
            year={year}
            onMonthChange={handleMonthChange}

            selected={selectedDates}
            onChange={handleDateSelection}
            // allowRange
        />
    );
}
