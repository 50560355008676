/* eslint-disable max-len */
import React from 'react';
import { setModalStatus } from '@slices/ui.slice';
import clearIcon from '@assets/icons/clear.svg';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { GET } from '@utils';
import { Card, Row, Modal, Input } from '@components';
function Tags({ selected_tags = [], name, submitted_tags, onTagSelected = null }) {
    const dispatch = useDispatch();
    const [all_tags, set_all_tags] = React.useState([]);

    const [selected_tags_state, set_selected_tags_state] = React.useState(selected_tags);
    const [search_results, set_search_results] = React.useState([]);

    const [tags_search, set_tags_search] = React.useState({
        value: '',
        type: 'search',
        placeholder: 'search for unselected tag',
        required: false,
        setValue: (val) => set_tags_search({ ...tags_search, value: val }),
    });
    const search_mode = tags_search.value.trim();

    const unSelected = (parent, sub) => {
        return parent.filter((parentObj) => {
            return !sub.some((subObj) => {
                return parentObj.id === subObj.id;
            });
        });
    };

    React.useEffect(() => {
        if (selected_tags_state.length && !search_mode) {
            set_search_results(unSelected(all_tags, selected_tags_state));
        }
        if (search_mode && selected_tags_state) {
            const results = unSelected(all_tags, selected_tags_state).filter((x) =>
                x.name_en.toLowerCase().includes(tags_search.value.toLowerCase().trim()),
            );
            set_search_results(results);
        }
        if (search_mode && !selected_tags_state) {
            const results = all_tags.filter((x) =>
                x.name_en.toLowerCase().includes(tags_search.value.toLowerCase().trim()),
            );
            set_search_results(results);
        }
    }, [tags_search.value, selected_tags_state]);

    React.useEffect(() => {
        const fetchData = async () => {
            const res = await GET({ endpoint: '/tags' });
            if (res?.status_code === 200) {
                set_all_tags(res?.data);
                set_search_results(res?.data);
            }
        };

        fetchData();
    }, []);

    const handelTags = (e, selected_tag) => {
        /** @todo check this with team */
        e.target.classList.add('tag-item-disable');
        const newFlags = [...selected_tags_state, selected_tag];
        set_selected_tags_state(newFlags);
        onTagSelected?.(selected_tag);
    };

    React.useEffect(() => {
        submitted_tags(selected_tags_state);
    }, [selected_tags_state]);

    return (
        <>
            <Card>
                <div className="search-tags-wrapper">
                    <Row className="align-items-center justify-content-between mb-1">
                        <label className="mx-3">Tags</label>
                        <label
                            className="tag-modal mx-3"
                            onClick={() => {
                                dispatch(
                                    setModalStatus({
                                        modal: `${name}_tags_model`,
                                        status: true,
                                    }),
                                );
                            }}
                        >
                            View all tags
                        </label>
                    </Row>

                    <Input {...tags_search} />

                    <div className="selected-tags">
                        {search_mode && (
                            <>
                                <h6>unselected tags</h6>
                                <div className="tags-wrapper mx-1 search">
                                    {search_results.length !== 0 ? (
                                        search_results.map((tag) => {
                                            return (
                                                <span
                                                    key={`${name}_${tag.id}`}
                                                    onClick={() => {
                                                        set_selected_tags_state([
                                                            ...selected_tags_state,
                                                            tag,
                                                        ]);
                                                        onTagSelected?.(tag);
                                                        set_search_results(
                                                            search_results.filter(
                                                                (x) => x.id !== tag.id,
                                                            ),
                                                        );
                                                        tags_search.setValue('');
                                                    }}
                                                    className="tag-item"
                                                >
                                                    {tag.name_en}
                                                </span>
                                            );
                                        })
                                    ) : (
                                        <h6 className="text-center mx-auto text-danger">
                                            No match found
                                        </h6>
                                    )}
                                </div>
                            </>
                        )}
                        {!search_mode && (
                            <>
                                {selected_tags_state.length !== 0 ? (
                                    <>
                                        <h6>selected tags</h6>
                                        <div className="tags-wrapper mx-1">
                                            {selected_tags_state.map((tag) => {
                                                return (
                                                    <span
                                                        key={`${name}_${tag.id}`}
                                                        className="tag-item"
                                                    >
                                                        {' '}
                                                        {tag.name_en}
                                                        <img
                                                            onClick={() => {
                                                                set_selected_tags_state(
                                                                    selected_tags_state.filter(
                                                                        (x) => x.id !== tag.id,
                                                                    ),
                                                                );
                                                            }}
                                                            src={clearIcon}
                                                            alt="clear"
                                                        />
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <h6>No tags selected</h6>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Card>
            <Modal
                title={'Edit tags'}
                name={`${name}_tags_model`}
                confirmBtn={false}
                onSubmit={(closeModal) => {
                    closeModal(`${name}_tags_model`);
                }}
            >
                {' '}
                {selected_tags_state.length !== 0 && (
                    <>
                        <div className="selected-tags">
                            <label className="mb-3">APPLIED TAGS</label>
                            <div className="tags-wrapper">
                                {selected_tags_state.map((tag) => {
                                    return (
                                        <>
                                            <span key={`${name}_${tag.id}`} className="tag-item">
                                                {' '}
                                                {tag.name_en}
                                                <img
                                                    onClick={() => {
                                                        document
                                                            .getElementById(`${name}_${tag.id}`)
                                                            .classList.remove('tag-item-disable');
                                                        set_selected_tags_state(
                                                            selected_tags_state.filter(
                                                                (x) => x.id !== tag.id,
                                                            ),
                                                        );
                                                    }}
                                                    src={clearIcon}
                                                    alt="clear"
                                                />
                                            </span>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                        <hr />
                    </>
                )}
                <div className="all-tags">
                    <label className="mb-3">All TAGS</label>
                    <div className="tags-wrapper">
                        {all_tags.map((tag) => {
                            let is_selected = false;

                            selected_tags_state.forEach((x) => {
                                if (x.id === tag.id) {
                                    return (is_selected = true);
                                }
                            });
                            return (
                                <>
                                    <span
                                        id={`${name}_${tag.id}`}
                                        key={`${name}_${tag.id}`}
                                        onClick={(e) => {
                                            handelTags(e, tag);
                                        }}
                                        className={`tag-item ${is_selected && 'tag-item-disable'}`}
                                    >
                                        {tag.name_en}
                                    </span>
                                </>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </>
    );
}
const tagObjShape = {
    id: PropTypes.number.isRequired,
    name_en: PropTypes.string.isRequired,
    name_ar: PropTypes.string.isRequired,
};
Tags.propTypes = {
    selected_tags: PropTypes.arrayOf(PropTypes.shape(tagObjShape)),
    name: PropTypes.string.isRequired,
    submitted_tags: PropTypes.func.isRequired,
};
export default Tags;
