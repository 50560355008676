import PropTypes from 'prop-types';
import { default as DP } from 'react-datepicker';
import { Form } from '@components';
import 'react-datepicker/dist/react-datepicker.css';

export default function DatePicker({
    label = '',
    selectedDate,
    onChange,
    disabled = false,
    dateOnly = false,
    minDate,
    maxDate,
}) {
    const handleOnChange = (date) => {
        const d = dateOnly ? new Date(date.setHours(15, 0, 0)) : date;
        onChange(d);
    };

    return (
        <Form.Group controlId={label || ''}>
            {label && (
                <Form.Label>{label}</Form.Label>
            )}
            <DP
                selected={selectedDate}
                onChange={handleOnChange}
                dateFormat="dd/MMM/yyyy"
                className="form-control"
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
            />
        </Form.Group>
    );
}

DatePicker.propTypes = {
    label: PropTypes.string,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
};
