import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
function BarChart({ labels , dataNum , label }) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
        },
    };
    
    const data = {
        labels,
        datasets: [
       
            {
                label,
                data:dataNum,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return (<div>
        <Bar style={{ maxHeight : '450px' }} options={options} data={data} />
    </div>);
}

export default BarChart;
