// Function to flatten an object with nested properties
function flattenObject(obj, parent = '', res = {}) {
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    for (let key in obj) {
        if (hasOwnProperty.call(obj, key)) {
            const propName = parent ? `${parent}.${key}` : key;

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((item, index) => {
                        flattenObject(item, `${propName}[${index}]`, res);
                    });
                } else {
                    flattenObject(obj[key], propName, res);
                }
            } else {
                res[propName] = obj[key];
            }
        }
    }
    return res;
}


function convertArrayOfObjectsToCSV(array) {
    if (!array.length) return '';
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(flattenObject(array[0]));
    let result = keys.join(columnDelimiter) + lineDelimiter;

    array.forEach(item => {
        let flattenedItem = flattenObject(item);
        let row = keys.map(key => {
            let value = flattenedItem[key];
            if (typeof value === 'string') {
                value = value.replace(/,/g, ' ');  // removing comma if exist to avoid creating new column
                if (value.includes(columnDelimiter) || value.includes('\n')) {
                    value = `"${value}"`; // Enclose fields containing comma or line breaks in double quotes
                }
            } else if (value !== null && typeof value === 'object') {
                value = JSON.stringify(value);
            }
            return value;
        }).join(columnDelimiter);
        result += row + lineDelimiter;
    });
    return result;
}
function downloadCSV(title, array, is_data_formatted = false) {
    const link = document.createElement('a');
    let csv;
    if(!is_data_formatted){
        csv = convertArrayOfObjectsToCSV(array);
    } else {
        csv = array;
    }
    if (csv == null) return;

    const filename = `${title || 'table'}-export.csv`;
    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    // replace all # with _ because it will break the download link
    csv = csv.replace(/#/g, '_');

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}

export default downloadCSV;