import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useSearchParams from '../../hooks/useSearchParams';
import { useHistory } from 'react-router-dom';

const Tabs = ({ tabs = [], onSelect, children, vertical, offsetBottom, setPage = () => {} }) => {
    const tabsWrapperRef = useRef(null);
    const history = useHistory();
    const [activeLineStyles, setActiveLineStyles] = useState(null);
    // read tab index from query param
    const queryParams = useSearchParams();
    const tab_index = queryParams.get('tab_index');

    const onTabChange = (tab) => {
        // update selected tab
        setPage(1);
        onSelect(
            tabs.map((t) => {
                t.isActive = t?.name === tab?.name;
                return t;
            }),
        );

        // update query param
        const index = tabs.findIndex((t) => t?.name === tab?.name);
        const params = new URLSearchParams({ tab_index: index + 1 });
        history.replace({ pathname: location.pathname, search: params.toString() });
    };

    useEffect(() => {
        const children = tabsWrapperRef.current.children;
        let activeTab = null;
        for (let i = 0; i < children.length; i++) {
            if (children[i].classList.contains('active')) {
                activeTab = children[i];
            }
        }
        activeTab &&
            setActiveLineStyles({
                top: offsetBottom ? `${activeTab.offsetBottom}px` : `${activeTab.offsetTop}px`,
                width: `${activeTab.offsetWidth}px`,
                transform: `translateX(${activeTab.offsetLeft}px)`,
            });
    }, [tabs]);

    useEffect(() => {
        if (tab_index) {
            const children = tabsWrapperRef?.current?.children;
            children?.[tab_index - 1]?.click();
        }
    }, []);

    return (
        <div className={`tabs ${vertical && 'vertical'}`}>
            <ul ref={tabsWrapperRef}>
                {tabs.map((tab, i) => (
                    <li
                        key={i}
                        className={`${tab.isActive && 'active'}`}
                        onClick={() => onTabChange(tab)}
                    >
                        <div>{tab?.name}</div>
                        {tab.vendor_name && <div>{tab.vendor_name}</div>}
                    </li>
                ))}
                <div className="active-line" style={activeLineStyles}></div>
            </ul>
            <div className="tabs-content">{children}</div>
        </div>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            component: PropTypes.element,
            isActive: PropTypes.bool.isRequired,
        }),
    ),
    onSelect: PropTypes.func.isRequired,
};

export default Tabs;
