import mime from 'mime-types';
import { GET } from './HTTP';

async function downloadFileFromAPI(url, filename = 'file', withExtension = false) {
    const resp = await GET({
        endpoint: url,
        options: {
            responseType: 'blob',
            return_full_response: true,
        },
    });
    if (!resp.headers) return;
    const type = resp?.headers?.['content-type'] || 'application/pdf';
    const file = new Blob([resp.data], { type });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = withExtension ? filename : `${filename}.${mime.extension(type)}`;
    link.click();
}

export default downloadFileFromAPI;
