export default function openFullScreen(element) {
    /* we must include prefixes for different browsers, 
		as they don't support the requestFullscreen property yet */

    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        /* Safari */
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
        /* IE11 */
        element.msRequestFullscreen();
    }
}
