import React from 'react';

function Dialer({ number }) {
    if (!number) return 'no number exist';

    let validate_number;

    validate_number = number.startsWith('+') ? number.slice(1) : number;
    validate_number = validate_number.startsWith('966') ? validate_number : `966${validate_number}`;

    return (
        <a
            href="#"
            className="maqsam-call"
            onClick={()=> window.open(
                `tel:=${validate_number}`,
                //`https://portal.maqsam.com/phone/dialer#autodial=${validate_number}`,
                null,
                'toolbar=no,menubar=no',
            )}
        >
            {validate_number}
        </a>
    );
}

export default Dialer;