export const POSITIONS = [
    { label: 'AWB Popup', value: 'awb_popup' },
    { label: 'Order Details', value: 'order_details' },
    { label: 'Quantity Preview Window', value: 'quantity_preview_window' },
    //{ label: 'Create CBV order', value: 'create_cbv_order' },
    { label: 'Upload Invoice Step', value: 'upload_invoice_step' },
    { label: 'Upload Invoice', value: 'upload_invoice' },
    { label: 'Delivery Option Step', value: 'delivery_option_step' },
    { label: 'Boxes Definition', value: 'boxes_definition' },
    { label: 'Collection Details', value: 'collection_details' },
    { label: 'Adding/Updating Product - Basic Info', value: 'product_form_basic_info' },
    { label: 'Adding/Updating Product - Product Images', value: 'product_form_product_images' },
    { label: 'Adding/Updating Product - Prices', value: 'product_form_prices' },
    { label: 'Adding/Updating Product - Packing', value: 'product_form_packing' },
    { label: 'Create Product Samples', value: 'create_product_samples' },
    { label: 'Create/Update Customer Group', value: 'customer_group_form' },
    { label: 'Customer Details', value: 'customer_details' },
    { label: 'Add New Customer Location', value: 'add_new_customer_location' },
    { label: 'Store Settings - Merchant Profile', value: 'store_settings_vendor_profile' },
    { label: 'Store Settings - Merchant Details', value: 'store_settings_bank_details' },
    { label: 'Store Settings - Merchant Addresses', value: 'store_settings_vendor_addresses' },
    { label: 'Store Settings - Invoice Settings', value: 'store_settings_invoice_settings' },
    {
        label: 'Store Settings - Create Order Settings',
        value: 'store_settings_create_order_settings',
    },
];

export const POSITION_LABELS = {};

POSITIONS.map((p) => {
    POSITION_LABELS[p.value] = p.label;
});
