import React from 'react';
import useQuery from '@hooks/useQuery';
import { DataTable, Card } from '@components';
import { useParams } from 'react-router';

function SuborderRating() {
    const { id } = useParams();
    const { data: { data } = { data: [] }, isLoading } = useQuery(`/order/${id}/suborder-ratings`);

    const columns = [
        {
            name: 'Rating ID',
            width: '100px',
            center: true,
            cell: (row) => row?.id,
        },
        {
            name: 'Pretty ID',
            width: '100px',
            center: true,
            cell: (row) => row?.pretty_id,
        },
        {
            name: 'Overall Rating',
            center: true,
            cell: (row) => row?.overall_rating,
        },
        {
            name: 'Product Quality Rating',
            center: true,
            cell: (row) => row?.product_quality_rating,
        },
        {
            name: 'Package Rating',
            center: true,
            cell: (row) => row?.packing_quality_rating,
        },
        {
            name: 'Price Rating',
            center: true,
            cell: (row) => row?.price_quality_rating,
        },
        {
            name: 'Delivery Rating',
            center: true,
            cell: (row) => row?.delivery_rating,
        },
        {
            name: 'Retailer Comment',
            center: true,
            cell: (row) => row?.comment,
        },
    ];

    return (
        <Card title="Child Order Review">
            <DataTable data={data} columns={columns} loading={isLoading} />
        </Card>
    );
}

export default SuborderRating;
