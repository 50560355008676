import React from 'react';
import {
    Row,
    Col,
    Input,
    Select,
    ApiAsyncSelect,
    Checkbox,
    CustomPrompt,
    CriticalActionButton,
    DatePicker,
} from '@components';
import { regexLib, GET, confirmationMessage, POST } from '@utils';
import { useSelector } from 'react-redux';
import serialize from '@utils/objectSerializer';
import CustomerPayLaterProvidersCheckboxes from './CustomerPayLaterProvidersCheckboxes';

export default function UpdateCustomerInfoTab({ id }) {
    const CustomersSlice = useSelector((state) => state.CustomersSlice);
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const [errors, setErrors] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);

    // Form Fields
    const [vendor, set_vendor] = React.useState();
    const [legal_name_ar, set_legal_name_ar] = React.useState({
        label: 'Legal name (ar)',
        value: '',
        setValue: (val) => set_legal_name_ar({ ...legal_name_ar, value: val }),
    });
    const [legal_name_en, set_legal_name_en] = React.useState({
        label: 'Legal name (en)',
        value: '',
        setValue: (val) => set_legal_name_en({ ...legal_name_en, value: val }),
    });
    const [store_name, set_store_name] = React.useState({
        label: 'Store name',
        value: '',
        setValue: (val) => set_store_name({ ...store_name, value: val }),
    });
    const [is_eligible, set_is_eligible] = React.useState({
        label: 'is_eligible',
        checked: false,
        onChange: (val) => set_is_eligible({ ...is_eligible, checked: val }),
    });
    const [is_eligible_for_pay_later, set_is_eligible_for_pay_later] = React.useState({
        label: 'Is eligible for pay later',
        checked: true,
        onChange: (val) =>
            set_is_eligible_for_pay_later({
                ...is_eligible_for_pay_later,
                checked: val,
            }),
    });
    const [allowed_pay_later_providers, set_allowed_pay_later_providers] = React.useState({
        label: 'allowed pay later providers',
        value: [],
        options: [
            { label: 'Disty', id: 'Disty' },
            //{ label: 'ldun', id: 'ldun' },
            //{ label: 'bynow', id: 'bynow' },
        ],
        onChange: (val) => set_allowed_pay_later_providers((prev) => ({ ...prev, value: val })),
    });
    const [commercial_registration_number, set_commercial_registration_number] = React.useState({
        label: 'CR number',
        value: '',
        type: 'number',
        setValue: (value) =>
            set_commercial_registration_number({
                ...commercial_registration_number,
                value,
            }),
    });
    const [monthly_target, set_monthly_target] = React.useState({
        label: 'monthly target',
        value: '',
        type: 'number',
        setValue: (val) =>
            set_monthly_target({
                ...monthly_target,
                value: val,
            }),
    });
    const [email, set_email] = React.useState({
        label: 'Business email',
        value: '',
        type: 'email',
        regex: regexLib['email'],
        setValue: (val) => set_email({ ...email, value: val }),
    });
    const [mobile, set_mobile] = React.useState({
        label: 'Business mobile',
        value: '',
        type: 'tel',
        setValue: (val) => set_mobile({ ...mobile, value: val }),
    });
    const [entity_types, set_entity_types] = React.useState({
        label: 'entity types',
        options: [],
        value: [],
        getOptionValue: (option) => option.id,
        onChange: (val) =>
            set_entity_types((prev) => {
                return { ...prev, value: val };
            }),
    });
    const [categories, set_categories] = React.useState({
        label: 'Categories',
        options: [],
        value: [],
        isMulti: true,
        getOptionValue: (option) => option.id,
        onChange: (val) => set_categories((prev) => ({ ...prev, value: val })),
    });
    const [account_managers, set_account_managers] = React.useState({
        label: 'Sales Account manager',
        options: [],
        value: {},
        getOptionValue: (option) => option.id,
        onChange: (val) => set_account_managers((prev) => ({ ...prev, value: val })),
    });
    const [acquisition_user, set_acquisition_user] = React.useState({});
    const [zatca_id, set_zatca_id] = React.useState({
        label: 'Zatca ID',
        tooltip: '10 Digits',
        type: 'number',
        value: '',
        setValue: (val) =>
            set_zatca_id({
                ...zatca_id,
                value: val,
            }),
    });
    const [cr_expires_at, set_cr_expires_at] = React.useState({
        label: 'CR Expires',
        selectedDate: null,
        onChange: (val) => set_cr_expires_at({ ...cr_expires_at, selectedDate: val }),
    });

    const [city, set_city] = React.useState({
        label: 'City',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_city((prev) => ({ ...prev, value: val })),
    });

    const [customer_types, set_customer_types] = React.useState({
        label: 'Customer types',
        options: [],
        value: [],
        getOptionValue: (option) => option.name_en,
        onChange: (val) =>
            set_customer_types((prev) => {
                return { ...prev, value: val };
            }),
    });

    const handleSubmit = async () => {
        const data = serialize({
            store_name: store_name?.value || null,
            monthly_target: monthly_target?.value || null,
            city_id: city.value?.id || null,
            legal_name_ar: legal_name_ar?.value || null,
            legal_name_en: legal_name_en?.value || null,
            // is_verified: is_verified.checked ? '1' : '0',
            is_eligible: is_eligible.checked ? '1' : '0',
            is_eligible_for_pay_later: is_eligible_for_pay_later.checked ? '1' : '0',
            allowed_pay_later_providers: is_eligible_for_pay_later.checked
                ? allowed_pay_later_providers.value?.map(({ id }) => id)
                : null,
            captured_by_vendor_id: vendor?.id || null,
            commercial_registration_number: commercial_registration_number.value || null,
            business_email: email?.value || null,
            business_mobile: mobile?.value || null,
            entity_type_id: entity_types?.value?.id || null,

            account_manager_id: account_managers?.value?.id || null,
            acquisition_user_id: acquisition_user?.id || null,
            zatca_id: zatca_id.value || null,
            customer_type: customer_types?.value?.id || null,
        });

        categories.value?.forEach((c, i) => {
            data.append('categories[' + i + ']', c.id);
        });
        data.append('cr_expires_at', cr_expires_at.selectedDate?.toISOString().split('T')[0]);
        data.append('_method', 'PUT');

        set_loading(true);
        const res = await POST({
            endpoint: `/customers/${id}`,
            data,
        });
        set_loading(false);

        setErrors([]);
        if (res.status === 422) {
            setErrors(res.data.errors);
        } else if (res.status_code === 200) {
            set_updated('Success');
            setTimeout(() => {
                set_updated(null);
            }, 2000);
        }
    };

    React.useEffect(() => {
        set_categories({ ...categories, options: GlobalsSlice.categories });
        set_entity_types({ ...entity_types, options: GlobalsSlice.entity_types });
        set_city({ ...city, options: GlobalsSlice.ksa_cities });
        set_customer_types({ ...customer_types, options: GlobalsSlice.customer_types });
    }, [GlobalsSlice]);

    React.useEffect(() => {
        if (CustomersSlice.customer?.id) {
            store_name.setValue(CustomersSlice.customer.store_name);
            legal_name_ar.setValue(CustomersSlice.customer.legal_name_ar);
            legal_name_en.setValue(CustomersSlice.customer.legal_name_en);
            // is_verified.onChange(CustomersSlice.customer.is_verified);
            is_eligible.onChange(CustomersSlice.customer.is_eligible);
            is_eligible_for_pay_later.onChange(CustomersSlice.customer.is_eligible_for_pay_later);
            allowed_pay_later_providers.onChange(
                CustomersSlice.customer.allowed_pay_later_providers?.map(
                    (allowed_pay_later_provider) => ({
                        id: allowed_pay_later_provider,
                        label: allowed_pay_later_provider,
                    }),
                ),
            );

            monthly_target.setValue(CustomersSlice.customer.monthly_target);
            email.setValue(CustomersSlice.customer.email);
            mobile.setValue(CustomersSlice.customer.mobile);
            entity_types.onChange({
                id: CustomersSlice.customer.entity_type.id,
                label: CustomersSlice.customer.entity_type.name_ar,
            });
            categories.onChange(
                CustomersSlice?.customer?.categories?.map((c) => ({
                    id: c.id,
                    label: c.name_ar,
                })),
            );
            zatca_id.setValue(CustomersSlice?.customer?.zatca_id);
            commercial_registration_number.setValue(
                CustomersSlice?.customer?.commercial_registration_number,
            );
            cr_expires_at.onChange(
                CustomersSlice?.customer?.cr_expires_at &&
                    new Date(CustomersSlice?.customer?.cr_expires_at),
            );
            if (CustomersSlice.customer?.account_manager) {
                account_managers.onChange({
                    id: CustomersSlice.customer?.account_manager?.id,
                    // eslint-disable-next-line max-len
                    label: `${CustomersSlice.customer?.account_manager?.first_name} ${CustomersSlice.customer?.account_manager?.last_name}`,
                });
            }

            if (CustomersSlice.customer?.acquisition_user) {
                set_acquisition_user({
                    id: CustomersSlice.customer?.acquisition_user?.id,
                    // eslint-disable-next-line max-len
                    label: `${CustomersSlice.customer?.acquisition_user?.first_name} ${CustomersSlice.customer?.acquisition_user?.last_name}`,
                });
            }

            if (CustomersSlice.customer?.vendor) {
                set_vendor(CustomersSlice.customer?.vendor);
            }

            if (CustomersSlice.customer?.city) {
                city.onChange({
                    id: CustomersSlice.customer.city.id,
                    label: CustomersSlice.customer.city.name,
                });
            }
        }
    }, [CustomersSlice.customer]);

    React.useEffect(() => {
        const fetchData = async () => {
            const res = await GET({
                endpoint: '/user/account-managers',
            });
            const formated_options = res?.data
                ? res.data.map((x) => ({
                      ...x,
                      label: `${x.first_name} ${x.last_name}`,
                      value: x.id,
                  }))
                : [];

            set_account_managers((prev) => ({
                ...prev,
                options: formated_options,
            }));
        };
        fetchData();
    }, []);

    return (
        <div className="customer-form px-3 py-2">
            <CustomPrompt>
                <div className="general-fields">
                    <h3 className="divider-title">General info</h3>
                    <div className="alert alert-warning">
                        Please register the customer with accurate shop details to avoid auto
                        deletion of the customer profile and records.
                    </div>
                    <Row>
                        <Col xs={12} md={6}>
                            <Input {...store_name} />
                            <Input {...email} />

                            <Input {...zatca_id} />
                            <Input {...mobile} />
                            <Select {...entity_types} />
                            <Select {...categories} />
                            {/* <Checkbox {...is_verified} /> */}
                            <Checkbox {...is_eligible} />
                            <Checkbox {...is_eligible_for_pay_later} />

                            <div>
                                {is_eligible_for_pay_later.checked && (
                                    <CustomerPayLaterProvidersCheckboxes
                                        onChange={allowed_pay_later_providers.onChange}
                                        options={allowed_pay_later_providers.options}
                                        value={allowed_pay_later_providers.value}
                                    />
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <Input {...legal_name_ar} />
                            <Input {...legal_name_en} />
                            <Input {...commercial_registration_number} />
                            <DatePicker {...cr_expires_at} />
                            <Select {...city} />

                            <ApiAsyncSelect
                                label="Captured By Merchant (CBV)"
                                api="/vendors"
                                onlyFetchActiveRecords={false}
                                placeholder="Type to Add Merchant..."
                                labelProperty={(r) => {
                                    return (
                                        <div className="custom-option-with-thumbnail">
                                            <img src={r.logo_ar} />
                                            <div className="content">
                                                <h4>{r.company_name_ar}</h4>
                                                <p>
                                                    {r.email}
                                                    {r.mobile ? ` | ${r.mobile}` : ''}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }}
                                onChange={(v) => set_vendor(v)}
                                value={
                                    vendor?.id
                                        ? {
                                              value: vendor?.id,
                                              label: vendor?.company_name_ar,
                                          }
                                        : null
                                }
                            />
                        </Col>
                        <Select {...customer_types} />
                    </Row>

                    <h3 className="divider-title">Account managers</h3>
                    <Row>
                        <Col xs={12} md={6}>
                            <Select {...account_managers} />
                        </Col>
                        <Col xs={12} md={6}>
                            <ApiAsyncSelect
                                label="Acquisition managers"
                                api="/acquisition/acquisitions"
                                onlyFetchActiveRecords={false}
                                placeholder="Type to add acquisition manager..."
                                labelProperty={(r) => `${r.first_name} ${r.last_name}`}
                                onChange={set_acquisition_user}
                                value={acquisition_user}
                            />
                        </Col>
                    </Row>

                    <h3 className="divider-title">Targets</h3>
                    <Input {...monthly_target} />

                    {!!Object.keys(errors).length && (
                        <div className="alert alert-danger">
                            <ul>
                                {Object.keys(errors).map((key) => {
                                    return <li key={key}>{errors[key]}</li>;
                                })}
                            </ul>
                        </div>
                    )}
                    {updated && <div className="alert alert-success">{updated}</div>}

                    <CriticalActionButton
                        loading={loading}
                        onSubmit={() => {
                            CustomersSlice?.customer?.status
                                ? handleSubmit()
                                : confirmationMessage(handleSubmit);
                        }}
                    >
                        Save changes
                    </CriticalActionButton>
                </div>
            </CustomPrompt>
        </div>
    );
}
