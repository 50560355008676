function useOmittedColumnsCache() {
    const url_path = window.location.pathname;
    const localStorageName = `cached_omitted_columns_${url_path}`;

    const setOmittedColumnsCache = (omitted_columns = []) => {
        const data_as_string = JSON.stringify(
            omitted_columns.map(({ name, omit }) => ({ name, omit }))
        );

        localStorage.setItem(localStorageName, data_as_string);
    };

    const getOmittedColumnsCache = () => {
        const data = localStorage.getItem(localStorageName);
        return data ? JSON.parse(data) : [];
    };

    return { setOmittedColumnsCache, getOmittedColumnsCache };
}

export default useOmittedColumnsCache;
