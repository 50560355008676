import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import ListView from './ListView';
import { Helmet } from 'react-helmet';

export default function Main() {
    return (
        <Switch>
            <ProtectedRoute exact path="/requested-merchants/list">
                <Helmet>
                    <title>Requested Merchants | Disty Admin Dashboard</title>
                </Helmet>
                <ListView />
            </ProtectedRoute>
        </Switch>
    );
}