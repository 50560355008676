import useMutation from '@hooks/useMutation';
import { POST } from '@utils';
import { normalizePayload, normalizeVariantPayload } from '../product-utils';
import serialize from '@utils/objectSerializer';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { loadProductData } from '@slices/products.slice';

export default function useUpdateProduct(
    product,
    payload,
    {
        // possible values: 'general', 'variants','details'
        view_name,
    },
) {
    const dispatch = useDispatch();
 
    const normalizedPayload = useMemo(
        () =>
            normalizePayload({
                ...product,
                ...payload,
                variants: (payload.variants || product.variants || []).map((variant) =>
                    normalizeVariantPayload(variant, true),
                ),
                images: [],
            }),
        [payload, product],
    );
    if (normalizedPayload.variants.length === 1) {
        if (view_name === 'general') {
            normalizedPayload.variants[0]['name_ar'] = normalizedPayload.name_ar;
            normalizedPayload.variants[0]['name_en'] = normalizedPayload.name_en;
            normalizedPayload.variants[0]['description_ar'] = normalizedPayload.description_ar;
            normalizedPayload.variants[0]['description_en'] = normalizedPayload.description_en;
        } else if (view_name === 'variants') {
            normalizedPayload.name_ar = normalizedPayload.variants[0]['name_ar'];
            normalizedPayload.name_en = normalizedPayload.variants[0]['name_en'];
            normalizedPayload.description_ar = normalizedPayload.variants[0]['description_ar'];
            normalizedPayload.description_en = normalizedPayload.variants[0]['description_en'];
        }
        else if (view_name === 'details') {
        normalizedPayload.variants[0]['model_number']=  normalizedPayload.model_number ;
        normalizedPayload.variants[0]['serial_number']=  normalizedPayload.serial_number ;
        normalizedPayload.variants[0]['screen_size']=  normalizedPayload.screen_size ;
        normalizedPayload.variants[0]['processor']=  normalizedPayload.processor ;
        normalizedPayload.variants[0]['operating_system']=  normalizedPayload.operating_system ;
        normalizedPayload.variants[0]['general_info_en']=  normalizedPayload.general_info_en ;
        normalizedPayload.variants[0]['general_info_ar']=  normalizedPayload.general_info_ar ;
        normalizedPayload.variants[0]['trade_mark_en']=  normalizedPayload.trade_mark_en ;
        normalizedPayload.variants[0]['trade_mark_ar']=  normalizedPayload.trade_mark_ar ;
        normalizedPayload.variants[0]['model_name_en']=  normalizedPayload.model_name_en ;
        normalizedPayload.variants[0]['model_name_ar']=  normalizedPayload.model_name_ar ;
        normalizedPayload.variants[0]['wireless_carrier_en']=  normalizedPayload.wireless_carrier_en ;
        normalizedPayload.variants[0]['wireless_carrier_ar']=  normalizedPayload.wireless_carrier_ar ;
        normalizedPayload.variants[0]['cellular_technology_en']=  normalizedPayload.cellular_technology_en ;
        normalizedPayload.variants[0]['cellular_technology_ar']=  normalizedPayload.cellular_technology_ar ;
        normalizedPayload.variants[0]['storage_capacity_en']=  normalizedPayload.storage_capacity_en ;
        normalizedPayload.variants[0]['storage_capacity_ar']=  normalizedPayload.storage_capacity_ar ;
        normalizedPayload.variants[0]['communication_technology_en']=  normalizedPayload.communication_technology_en ;
        normalizedPayload.variants[0]['communication_technology_ar']=  normalizedPayload.communication_technology_ar ;
        normalizedPayload.variants[0]['color_en']=  normalizedPayload.color_en ;
        normalizedPayload.variants[0]['color_ar']=  normalizedPayload.color_ar ;
        normalizedPayload.variants[0]['network_technology_en']=  normalizedPayload.network_technology_en ;
        normalizedPayload.variants[0]['network_technology_ar']=  normalizedPayload.network_technology_ar ;
        normalizedPayload.variants[0]['port_type_en']=  normalizedPayload.port_type_en ;
        normalizedPayload.variants[0]['port_type_ar']=  normalizedPayload.port_type_ar ;
        normalizedPayload.variants[0]['udf1_en']=  normalizedPayload.udf1_en ;
        normalizedPayload.variants[0]['udf1_ar']=  normalizedPayload.udf1_ar ;
        normalizedPayload.variants[0]['udf2_en']=  normalizedPayload.udf2_en ;
        normalizedPayload.variants[0]['udf2_ar']=  normalizedPayload.udf2_ar ;
        normalizedPayload.variants[0]['udf3_en']=  normalizedPayload.udf3_en ;
        normalizedPayload.variants[0]['udf3_ar']=  normalizedPayload.udf3_ar ;
        normalizedPayload.variants[0]['udf4_en']=  normalizedPayload.udf4_en ;
        normalizedPayload.variants[0]['udf4_ar']=  normalizedPayload.udf4_ar ;
     }
    }

    const mutationFn = () => {
        const formData = serialize(
            {
                _method: 'PUT',
                ...normalizedPayload,
            },
            { indices: true },
        );

        return POST({
            endpoint: `/base-product/${product.id}`,
            data: formData,
        });
    };

    const { mutate, isLoading, data, error } = useMutation(mutationFn, {
        onSuccess: (res) => {
            toast.info(res?.message);
            dispatch(loadProductData(product.id));
        },
    });

    return { mutate, isLoading, data, error };
}
