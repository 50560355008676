import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const NotificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
        notifications_pages: null,
        notifications_records: null,
        notification: {},
        notifications_loading: true,
        notification_loading: true,

        vendor_notifications: [],
        vendor_notifications_pages: null,
        vendor_notifications_records: null,
        vendor_notifications_loading: true,
    },
    reducers: {
        setNotifications(state, { payload }) {
            state.notifications = payload;
        },
        setNotification(state, { payload }) {
            state.product = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setNotificationsTotalPages(state, { payload }) {
            state.notifications_pages = payload;
        },
        setNotificationsRecords(state, { payload }) {
            state.notifications_records = payload;
        },
        setVendorNotifications(state, { payload }) {
            state.vendor_notifications = payload;
        },
        setVendorNotificationsTotalPages(state, { payload }) {
            state.vendor_notifications_pages = payload;
        },
        setVendorNotificationsRecords(state, { payload }) {
            state.vendor_notifications_records = payload;
        },
    },
});

// export actions
export const { 
    setNotifications, 
    setNotification,
    setLoading, 
    setNotificationsTotalPages,
    setNotificationsRecords,
    setVendorNotifications,
    setVendorNotificationsTotalPages,
    setVendorNotificationsRecords
} = NotificationsSlice.actions;

export const loadNotificationsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'notifications_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/notifications?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setNotifications(res?.data || []));
    if(res?.meta){
        dispatch(setNotificationsTotalPages(res?.meta?.last_page || 0));
        dispatch(setNotificationsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'notifications_loading',
            value: false,
        })
    );
};

export const loadNotificationData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'notification_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/notification/${id}`,
    });
    dispatch(setNotification(res?.data || {}));
    dispatch(
        setLoading({
            key: 'notification_loading',
            value: false,
        })
    );
};

export const loadVendorNotifications = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendor_notifications_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/notifications?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setVendorNotifications(res?.data || []));
    if(res?.meta){
        dispatch(setVendorNotificationsTotalPages(res?.meta?.last_page || 0));
        dispatch(setVendorNotificationsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'vendor_notifications_loading',
            value: false,
        })
    );
};

export default NotificationsSlice.reducer;
