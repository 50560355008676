import React from 'react';
import { ViewHeader, Link, DataTable, Card, Filters, Sop } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadUsersGroupsData } from '@slices/usersGroups.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function UsersGroupsListView() {
    const UsersGroupsSlice = useSelector((state) => state.UsersGroupsSlice);
    const dispatch = useDispatch();

    const [filters, set_filters] = React.useState('');
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    useDidMountEffect(() => {
        dispatch(loadUsersGroupsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            center: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/users-groups/${row.id}`} className="tr-name">
                    <h5>{row.name}</h5>
                </Link>
            ),
        },
    ];

    return (
        <div className="users-groups-list-view">
            <ViewHeader title="Users Groups">
                <Sop id="30163" />
                <Link
                    href="/users-groups/add"
                    className="btn btn-primary"
                >New users group</Link>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by group name..."
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={UsersGroupsSlice.usersGroups}
                    pages={UsersGroupsSlice.usersGroups_pages}
                    page={page}
                    records={UsersGroupsSlice.usersGroups_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={UsersGroupsSlice.usersGroups_loading}
                />
            </Card>
        </div>
    );

}
