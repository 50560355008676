import React from 'react';
import PropTypes from 'prop-types';
import { randomId } from '@utils';

export default function Radio({
    name,
    value,
    onChange,
    option,
    label = '',
    disabled = false,
    labelStyle,
}) {
    const id = randomId();

    return (
        <>
            <input
                type="radio"
                id={`radio-${id}`}
                name={name}
                value={value}
                checked={option === value}
                onChange={({ target }) => onChange(target.value)}
                disabled={disabled}
            />
            <label style={labelStyle} htmlFor={`radio-${id}`}>
                {label}
            </label>
        </>
    );
}

Radio.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    onChange: PropTypes.func.isRequired,
    option: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Allow string or JSX node
};
