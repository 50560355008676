import React from 'react';

import { Input, Row, Col, Button, CustomPrompt } from '@components';
import { obj2formdata, POST, confirmationMessage } from '@utils';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-input-slider';
import { loadVendorData } from '@slices/merchant.slice';
const DistyPayConfig = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [is_loading, set_is_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const [errors, set_errors] = React.useState([]);
    const vendor = useSelector((state) => state.VendorsSlice.vendor);
    const [take_rate, set_take_rate] = React.useState({
        label: 'Disty take rate',
        value: '',
        setValue: (val) => set_take_rate({ ...take_rate, value: val }),
    });

    // state of down payment percentage like take_rate
    const [down_payment_percentage, set_down_payment_percentage] = React.useState({
        label: 'Down payment percentage',
        value: '',
        setValue: (val) => set_down_payment_percentage({ ...down_payment_percentage, value: val }),
    });

    const [ratio, set_ratio] = React.useState(50);
    React.useEffect(() => {
        if (vendor.id) {
            set_ratio(vendor?.disty_pay_down_payment);
            take_rate.setValue(vendor?.disty_pay_app_take_rate);
        }
    }, [vendor]);

    const handleSubmit = async () => {
        const data = obj2formdata({
            disty_pay_down_payment: ratio,
            disty_pay_app_take_rate: take_rate.value,
        });

        data.append('_method', 'PATCH');
        set_is_loading(true);
        const res = await POST({
            endpoint: `/vendors/${id}`,
            data,
        });
        set_is_loading(false);

        set_errors([]);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 200) {
            dispatch(loadVendorData(vendor.id));
            set_updated('Success');
            setTimeout(() => {
                set_updated(null);
            }, 2000);
        }
    };

    return (
        <div>
            <CustomPrompt>
                {!!Object.keys(errors)?.length && (
                    <div className="alert alert-danger">
                        <ul>
                            {Object.keys(errors).map((key) => {
                                return <li key={key}>{errors[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <Row>
                    <Col sm={6}>
                        <Input {...take_rate} />
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <h1>Invoice financing percentage</h1>
                        <div className="my-3">
                            <label className="flex__jc-between">
                                <span>Down payment &lsquo;s Ratio ({ratio})</span>
                                <span>Merchant remaining payment&lsquo;s Ratio ({100 - ratio})</span>
                            </label>
                            <Slider
                                axis="x"
                                x={ratio}
                                onChange={({ x }) => set_ratio(x)}
                                xmin={0}
                                xmax={100}
                                xstep={0.5}
                                styles={{
                                    track: {
                                        width: '100%',
                                    },
                                }}
                            />
                        </div>
                        {updated && <div className="alert alert-success">{updated}</div>}
                        <div className="flex__jc-end">
                            <Button
                                onClick={() => {
                                    vendor?.status
                                        ? handleSubmit()
                                        : confirmationMessage(handleSubmit);
                                }}
                                disabled={is_loading}
                                style={{ minWidth: 120 }}
                            >
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </CustomPrompt>
        </div>
    );
};

export default DistyPayConfig;
