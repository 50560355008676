import React from 'react';
import { DataTable, Button, Modal, Input, ShowErrors, Toggler } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { POST, PUT } from '@utils';
import editIcon from '@assets/icons/edit.svg';
import useMutation from '@hooks/useMutation';
import useQuery from '@hooks/useQuery';

const AcquisitionChannels = () => {
    const dispatch = useDispatch();
    const [edit_id, set_edit_id] = React.useState(null);
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });
    const [is_active, set_is_active] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_is_active({ ...is_active, checked: val }),
    });

    const { data: { data } = { data: [] }, isLoading, refetch } = useQuery('/acquisition_channel');

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'name_en',
        },
        {
            name: 'Status',
            center: true,
            cell: (row) => (
                <div className={`status-indicator ${row?.is_active && 'active-indicator'}`}>
                    {row?.is_active ? 'Active' : 'Inactive'}
                </div>
            ),
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            name_ar.setValue(row.name_ar);
                            name_en.setValue(row.name_en);
                            is_active.onChange(row.is_active);
                            dispatch(
                                setModalStatus({
                                    modal: 'acquisition_channel__modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];
    const createOrUpdateMutations = useMutation(
        () =>
            (edit_id ? PUT : POST)({
                endpoint: edit_id ? `/acquisition_channel/${edit_id}` : '/acquisition_channel',
                data: {
                    name_ar: name_ar.value,
                    name_en: name_en.value,
                    is_active: is_active?.checked ? 1 : 0,
                },
            }),
        {
            onSuccess: () => {
                refetch();
                dispatch(
                    setModalStatus({
                        modal: 'acquisition_channel__modal',
                        status: false,
                    }),
                );
                name_ar.setValue('');
                name_en.setValue('');
                is_active.onChange(true);
            },
        },
    );

    return (
        <>
            <DataTable data={data} columns={columns} loading={isLoading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        name_ar.setValue('');
                        name_en.setValue('');
                        is_active.onChange(true);
                        dispatch(
                            setModalStatus({
                                modal: 'acquisition_channel__modal',
                                status: true,
                            }),
                        );
                    }}
                >
                    Add new acquisition channel
                </Button>
            </div>

            <Modal
                title={edit_id ? 'Update acquisition channel' : 'Add new acquisition channel'}
                variant={edit_id ? 'warning' : 'primary'}
                name="acquisition_channel__modal"
                onSubmit={() => createOrUpdateMutations.mutate()}
                loading={createOrUpdateMutations.isLoading}
            >
                <Input {...name_ar} />
                <Input {...name_en} />
                <Toggler {...is_active} />

                {createOrUpdateMutations.error && (
                    <ShowErrors errors={createOrUpdateMutations.error} />
                )}
            </Modal>
        </>
    );
};

export default AcquisitionChannels;
