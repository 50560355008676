/* eslint-disable no-unused-vars */
import { useState, useCallback, useEffect, useMemo } from 'react';
import { Filters as PolarisFilters } from '@shopify/polaris';
import { isEmpty } from '@utils';
import { useDebounce, formatStringWithUnderscores, toType } from '@utils';
import useFiltersCache from '@hooks/useFiltersCache';
import _pick from 'lodash.pick';
import getAllPossibleOptions from './FilterOptions';
import { SortBy } from './FiltersComponents';
import FormattedLabel from './FormattedLabel';

// list of possible value types
const VALUE_TYPES = {
    string: '',
    boolean: null,
    array: [],
    number: null,
    date: null,
    object: {},
};

export default function Filters({
    filters = [],
    onFilterChange = () => {},
    initialFiltersValues = {},
    // render custom filters
    renderFilters = () => [],
    sort_options = [],
    hide_query_field = false,
    queryPlaceholder = 'Search data...',
    disableFiltersCaching = false,
}) {
    const { getFiltersCache, setFiltersCache } = useFiltersCache();
    const [q, set_q] = useState('');

    const initialValues = useMemo(
        () => ({
            ...initialFiltersValues,
            ...(!disableFiltersCaching ? getFiltersCache() : {}),
        }),
        [initialFiltersValues],
    );
    const [filters_values, set_filters_values] = useState(initialValues);
    const [filters_labels, set_filters_labels] = useState({});

    const handleFilterValueRemove = useCallback((filter_name) => {
        set_filters_values((prev) => {
            const updated_filters_values = {
                ...prev,
                [filter_name]: VALUE_TYPES[toType(prev[filter_name])] || null,
            };

            onFilterChange(updated_filters_values);
            if (!disableFiltersCaching) {
                setFiltersCache(updated_filters_values);
            }
            return updated_filters_values;
        });
        if (filter_name === 'q') set_q('');
    }, []);

    const handleFilterValueChange = useCallback(
        (filter_name, value, label) => {
            set_filters_values((prev) => {
                let new_filter = {};
                if (Array.isArray(filter_name)) {
                    new_filter = {
                        ...prev,
                        ...filter_name.reduce((acc, filter_name, i) => {
                            acc[filter_name] = value[i];
                            return acc;
                        }, {}),
                    };
                } else {
                    new_filter = {
                        ...prev,
                        [filter_name]: value,
                    };
                }

                if (!disableFiltersCaching) {
                    setFiltersCache(new_filter);
                }
                onFilterChange(new_filter);
                return new_filter;
            });

            set_filters_labels((prev) => ({
                ...prev,
                [filter_name]: label,
            }));
        },
        [onFilterChange],
    );

    const handleFiltersClearAll = useCallback(() => {
        set_filters_values((prev) => {
            const updated_filters_values = {};

            Object.keys(prev).forEach((key) => {
                updated_filters_values[key] = VALUE_TYPES[toType(prev[key])] || null;
            });

            onFilterChange(updated_filters_values);
            if (!disableFiltersCaching) {
                setFiltersCache(updated_filters_values);
            }
            return updated_filters_values;
        });
        set_q('');
    }, [set_filters_values]);

    // debounce the query field
    const debounced_q = useDebounce(q, 650);
    useEffect(() => {
        handleFilterValueChange('q', debounced_q);
    }, [debounced_q]);

    const filters_components_definitions = useMemo(
        () =>
            getAllPossibleOptions(filters_values, handleFilterValueChange)
                .filter((x) => filters.includes(x.key))
                // the first 3 filters passed to filters prop
                // will be the ones displayed outside of the sheet
                .map((x) => {
                    const i = filters.indexOf(x.key);
                    x['shortcut'] = i < 5;
                    x.key = x.parmKey || x.key;
                    return x;
                })
                // sort the filters by the order they were passed to the filters prop
                .sort((a, b) => {
                    const i_a = filters.indexOf(a.key);
                    const i_b = filters.indexOf(b.key);
                    return i_a - i_b;
                }),
        [filters],
    );

    const appliedFilters = useMemo(
        () =>
            Object.keys(filters_values)
                ?.map((filter_key) => {
                    const filter_value = filters_values[filter_key];
                    if (isEmpty(filter_value)) return false;

                    const label = filters_labels[filter_key];

                    return {
                        key: filter_key,
                        label: FormattedLabel(filter_key, filter_value, label),
                        onRemove: () => handleFilterValueRemove(filter_key),
                    };
                })
                .filter(Boolean),
        [filters_values, filters_values],
    );

    return (
        <div className="custom-filters-wrapper">
            <PolarisFilters
                queryValue={q}
                onQueryChange={set_q}
                onQueryClear={() => handleFilterValueRemove('q')}
                queryPlaceholder={queryPlaceholder}
                filters={[
                    ...filters_components_definitions,
                    ...(renderFilters
                        ? renderFilters(filters_values, handleFilterValueChange)
                        : []),
                ]}
                appliedFilters={appliedFilters}
                onClearAll={handleFiltersClearAll}
                hideQueryField={hide_query_field}
            />

            {!!sort_options?.length && (
                <SortBy
                    value={
                        filters_values.sort_by &&
                        `${filters_values.sort_by}${
                            filters_values.sort_direction && `__${filters_values.sort_direction}`
                        }`
                    }
                    set_value={(val) => {
                        const selected_sort_option = val?.[0]?.split('__');
                        const sort_by = selected_sort_option?.[0] || null;
                        const sort_direction = selected_sort_option?.[1] || null;
                        handleFilterValueChange(
                            ['sort_by', 'sort_direction'],
                            [sort_by, sort_direction],
                        );
                    }}
                    sort_options={sort_options}
                />
            )}
        </div>
    );
}
