import { Card, Stack, TextField, Button } from '@shopify/polaris';
import { useState, useCallback } from 'react';

export default function WalletBalance({
    value = { min: 0, max: 0 },
    set_value = () => {},
}) {
    const [values, setValues] = useState(value);

    const handleOnChange = useCallback(
        (key, value) => {
            setValues({
                ...values,
                [key]: value,
            });
        }
    );

    return (
        <Card sectioned>
            <div>
                <Stack vertical>
                    <TextField
                        label="Min"
                        type="number"
                        value={values.min}
                        onChange={val => handleOnChange('min', val)}
                        autoComplete="off"
                    />
                    <TextField
                        label="Max"
                        type="number"
                        value={values.max}
                        onChange={val => handleOnChange('max', val)}
                        autoComplete="off"
                    />
                </Stack>
                <br />
                <Button
                    onClick={() => set_value(values)}
                >
					        Apply
                </Button>
            </div>
        </Card>
    );
}
