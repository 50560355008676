import React from 'react';
import {
    Select,
    ViewHeader,
    Button,
    Row,
    Col,
    Card,
    Input,
    ShippingAddress,
    Checkbox,
    FileUpload,
    CustomPrompt,
    Sop,
    ApiAsyncSelect,
    DatePicker,
} from '@components';
import AddressModal from './AddressModal';
import { useSelector, useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { regexLib, POST } from '@utils';
import useMutation from '@hooks/useMutation';
import { Redirect } from 'react-router';
import downloadIcon from '@assets/icons/download.svg';
import serialize from '@utils/objectSerializer';
import CustomerPayLaterProvidersCheckboxes from './CustomersSignleView/Tabs/CustomerPayLaterProvidersCheckboxes';

export default function CustomersAddView() {
    const dispatch = useDispatch();
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const [created, set_created] = React.useState(false);

    const [errors, set_errors] = React.useState({});
    const [address, set_address] = React.useState();

    const [city, set_city] = React.useState({
        label: 'City',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_city((prev) => ({ ...prev, value: val })),
    });

    // contact info
    const [first_name, set_first_name] = React.useState({
        label: 'First name',
        value: '',
        setValue: (val) => set_first_name({ ...first_name, value: val }),
    });

    const [last_name, set_last_name] = React.useState({
        label: 'Last name',
        value: '',
        setValue: (val) => set_last_name({ ...last_name, value: val }),
    });

    const [mobile, set_mobile] = React.useState({
        label: 'Personal mobile number',
        value: '',
        prefix: '+966',
        setValue: (val) => set_mobile({ ...mobile, value: val }),
    });

    const [email, set_email] = React.useState({
        label: 'Personal email',
        value: '',
        type: 'email',
        regex: regexLib['email'],
        setValue: (val) => set_email({ ...email, value: val }),
    });

    const [occupations, set_occupations] = React.useState({
        label: 'Occupations',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_occupations((prev) => ({ ...prev, value: val })),
    });

    const [vendor, set_vendor] = React.useState();

    // shop info
    const [store_name, set_store_name] = React.useState({
        label: 'Shop Name',
        value: '',
        setValue: (val) => set_store_name({ ...store_name, value: val }),
    });

    const [entity_types, set_entity_types] = React.useState({
        label: 'Shop type',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_entity_types((prev) => ({ ...prev, value: val })),
    });

    const [allowed_pay_later_providers, set_allowed_pay_later_providers] = React.useState({
        label: 'allowed pay later providers',
        isMulti: true,
        options: [
            { label: 'Disty', id: 'Disty' },
            //{ label: 'ldun', id: 'ldun' },
            //{ label: 'bynow', id: 'bynow' },
        ],
        value: [],
        getOptionValue: (option) => option.id,
        onChange: (val) => set_allowed_pay_later_providers((prev) => ({ ...prev, value: val })),
    });

    const [commercial_registration_number, set_commercial_registration_number] = React.useState({
        label: 'Commercial Registration Number',
        tooltip: '10 Digits',
        value: '',
        type: 'number',
        setValue: (val) =>
            set_commercial_registration_number({
                ...commercial_registration_number,
                value: val,
            }),
    });

    const [customer_vat_number, set_customer_vat_number] = React.useState({
        label: 'VAT number',
        tooltip: '15 Digits',
        value: '',
        type: 'number',
        setValue: (val) =>
            set_customer_vat_number({
                ...customer_vat_number,
                value: val,
            }),
    });
    const [zatca_id, set_zatca_id] = React.useState({
        label: 'Zatca ID',
        tooltip: '10 Digits',
        type: 'number',
        value: '',
        setValue: (val) =>
            set_zatca_id({
                ...zatca_id,
                value: val,
            }),
    });
    const [cr_expires_at, set_cr_expires_at] = React.useState({
        label: 'CR Expires',
        selectedDate: null,
        onChange: (val) => set_cr_expires_at({ ...cr_expires_at, selectedDate: val }),
    });
    const [cr_certificate, set_cr_certificate] = React.useState({
        label: 'CR Certificate',
        files: [],
        setFiles: (val) => set_cr_certificate({ ...cr_certificate, files: val }),
    });
    const [vat_certificate, set_vat_certificate] = React.useState({
        label: 'VAT Certificate',
        files: [],
        setFiles: (val) => set_vat_certificate({ ...vat_certificate, files: val }),
    });
    const [categories, set_categories] = React.useState({
        label: 'Categories',
        options: [],
        value: [],
        isMulti: true,
        getOptionValue: (option) => option.id,
        onChange: (val) => set_categories((prev) => ({ ...prev, value: val })),
    });

    const [has_tax_number, set_has_tax_number] = React.useState({
        label: 'Has tax number',
        checked: false,
        onChange: (val) => set_has_tax_number({ ...has_tax_number, checked: val }),
    });
    const [is_eligible_for_pay_later, set_is_eligible_for_pay_later] = React.useState({
        label: 'Is eligible for pay later',
        checked: true,
        onChange: (val) =>
            set_is_eligible_for_pay_later({
                ...is_eligible_for_pay_later,
                checked: val,
            }),
    });
    const [business_mobile, set_business_mobile] = React.useState({
        label: 'Business mobile number',
        value: '',
        prefix: '+966',
        setValue: (val) => set_business_mobile({ ...business_mobile, value: val }),
    });

    const [business_email, set_business_email] = React.useState({
        label: 'Business email',
        value: '',
        type: 'email',
        regex: regexLib['email'],
        setValue: (val) => set_business_email({ ...business_email, value: val }),
    });
    // create customers via file uploading
    const [selected_file, set_selected_file] = React.useState({
        files: [],
        // eslint-disable-next-line max-len
        acceptedMimeTypes:
            // eslint-disable-next-line max-len
            'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        hintMessage: 'Only *.xls, *.xlsx and cvs files will be accepted',
        setFiles: (val) => set_selected_file({ ...selected_file, files: val }),
    });

    const [customer_types, set_customer_types] = React.useState({
        label: 'Customer types',
        options: [],
        value: [],
        getOptionValue: (option) => option.name_en,
        onChange: (val) =>
            set_customer_types((prev) => {
                return { ...prev, value: val };
            }),
    });

    const [validation_errors, set_validation_errors] = React.useState({});
    const [loading, set_loading] = React.useState(false);
    const validate = () => {
        const newErrors = {};
      //  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}-\u{1F0CF}]/u;

        if (!first_name.value || !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(first_name.value))
            newErrors.first_name =
                'The first name field is required and must be contained only letters and spaces.';
        if (!last_name.value || !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(last_name.value))
            newErrors.last_name =
                'The last name field is required and must be contained only letters and spaces.';
        if (!mobile.value || !/^5[0-9]{1}[0-9]{7}$/i.test(mobile.value)) {
            newErrors.mobile =
                'The mobile field is required. It should start with 5 followed by 8 digits.';
        }
        if (!email.value) {
            newErrors.email = 'The email field is required.';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value)) {
            newErrors.email = 'Invalid email address.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(email.value)) {
            newErrors.email = 'Email should not contain Arabic letters';
        }
        if (!occupations.value) {
            newErrors.occupations = 'The Occupation field is required.';
        }
        if (!city.value) {
            newErrors.city = 'The city id field is required.';
        }

        if (!cr_expires_at.selectedDate) {
            newErrors.cr_expires_at = 'The CR Expires field is required.';
        } else {
            const selectedDate = new Date(cr_expires_at.selectedDate);
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            selectedDate.setHours(0, 0, 0, 0);
            tomorrow.setHours(0, 0, 0, 0);
            if (selectedDate < tomorrow) {
                newErrors.cr_expires_at =
                    'The cr expires at must be a date after or equal to tomorrow.';
            }
        }
        if (!commercial_registration_number.value) {
            newErrors.commercial_registration_number =
                'The Commercial Registration Number field is required.';
        } else if (!/^\d{10}$/.test(commercial_registration_number.value)) {
            newErrors.commercial_registration_number =
                'Commercial Registration Number must be exactly 10 digits.';
        }
        if (!entity_types.value) {
            newErrors.entity_types = 'The Entity Type field is required.';
        }
        if (!store_name.value) {
            newErrors.store_name = 'The store name is required.';
        }
        if (allowed_pay_later_providers.value.length === 0 && is_eligible_for_pay_later.checked) {
            newErrors.allowed_pay_later_providers =
                'The allowed pay later providers field is required';
        }
        if (categories.value.length === 0) {
            newErrors.categories = 'The Categories field is required.';
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [
        first_name,
        last_name,
        mobile,
        email,
        occupations,
        cr_expires_at,
        store_name,
        entity_types,
        allowed_pay_later_providers,
        categories,
        commercial_registration_number,
        city,
    ]);

    const addCustomer = (is_bulk) => {
        const endpoint = is_bulk ? '/customers/file' : '/customers';

        const obj = is_bulk
            ? {}
            : {
                  first_name: first_name?.value || null,
                  last_name: last_name?.value || null,
                  email: email?.value || null,
                  mobile: mobile?.value || null,
                  business_email: business_email?.value || null,
                  business_mobile: business_mobile?.value || null,
                  occupation: occupations?.value?.label || null,
                  captured_by_vendor_id: vendor?.id || null,
                  store_name: store_name?.value || null,
                  entity_type_id: entity_types?.value?.id || null,
                  commercial_registration_number: commercial_registration_number?.value || null,
                  customer_vat_number: customer_vat_number?.value || null,
                  has_tax_number: has_tax_number.checked ? '1' : '0',
                  is_eligible_for_pay_later: is_eligible_for_pay_later.checked ? '1' : '0',
                  allowed_pay_later_providers: is_eligible_for_pay_later.checked
                      ? allowed_pay_later_providers.value.map(({ id }) => id)
                      : null,
                  city_id: city.value?.id || null,
                  zatca_id: zatca_id.value || null,
                  customer_type: customer_types?.value?.id || null,
              };

        const data = serialize(obj, { nullsAsUndefineds: true });

        if (is_bulk) {
            selected_file.files.length && data.append('customers', selected_file.files[0]);
        } else {
            categories.value?.forEach((c, i) => {
                data.append('categories[' + i + ']', c.id);
            });

            address &&
                Object.keys(address)?.length &&
                Object.keys(address)?.map((key) => {
                    if (key === 'suitable_delivery_time_id') {
                        data?.append('address[' + key + ']', address[key]?.value);
                    } else {
                        data?.append('address[' + key + ']', address[key]);
                    }
                });
            cr_certificate.files.length && data.append('cr_certificate', cr_certificate.files[0]);

            vat_certificate.files.length &&
                data.append('vat_certificate', vat_certificate.files[0]);
            const date = cr_expires_at.selectedDate;
            const isoDate = date
                ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                      .toISOString()
                      .split('T')[0]
                : '';
            data.append('cr_expires_at', isoDate);
        }

        return POST({
            endpoint,
            data,
        });
    };

    const createMutation = useMutation(addCustomer, {
        onSuccess: (res) => {
            if (res?.status_code === 200) {
                set_created(true);
            } else {
                set_errors(res?.data?.errors || res?.data?.message || res?.errors);
            }
        },
        onError: (errors) => set_errors(errors),
    });

    const getAddress = (close, address) => {
        close('customer_address_modal');
        set_address(address);
    };

    React.useEffect(() => {
        if (GlobalsSlice?.entity_types?.length) {
            set_entity_types({
                ...entity_types,
                options: GlobalsSlice.entity_types,
            });
        }
        if (GlobalsSlice?.occupations?.length) {
            set_occupations({ ...occupations, options: GlobalsSlice.occupations });
        }
        if (GlobalsSlice?.categories?.length) {
            set_categories({ ...categories, options: GlobalsSlice.categories });
        }
        if (GlobalsSlice?.ksa_cities?.length) {
            set_city({ ...city, options: GlobalsSlice.ksa_cities });
        }

        if (GlobalsSlice?.ksa_cities?.length) {
            set_customer_types({ ...customer_types, options: GlobalsSlice.customer_types });
        }
    }, [
        GlobalsSlice.entity_types,
        GlobalsSlice.occupations,
        GlobalsSlice.categories,
        GlobalsSlice.customer_types,
    ]);

    const address_data = {
        data: [
            {
                key: 'Name',
                value: address?.name,
            },
            {
                key: 'Contact name',
                value: address?.contact_name,
            },
            {
                key: 'Contact mobile',
                value: address?.contact_mobile,
            },
            {
                key: 'Preferred delivery time',
                value: address?.suitable_delivery_time_id?.label,
            },
        ],
        coordinates:
            address?.latitude && address?.latitude
                ? {
                      latitude: address?.latitude,
                      longitude: address?.longitude,
                  }
                : null,
    };

    if (created) {
        return <Redirect to={'/customers/list'} />;
    }

    return (
        <div className="customer-form">
            <ViewHeader title="Creating a new customer" renderBackArrow>
                <Sop id="25183" />
            </ViewHeader>
            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {typeof errors === 'string' ? (
                            <li>{errors}</li>
                        ) : (
                            Object.keys(errors).map((key) => {
                                return <li key={key}>{errors[key]}</li>;
                            })
                        )}
                    </ul>
                </div>
            )}

            <CustomPrompt>
                <Card
                    title="Create a group of customers via file uploading"
                    className="customers-header-card"
                    headerContent={
                        <>
                            <div className="download-btns">
                                <a
                                    // eslint-disable-next-line max-len
                                    href="https://assets.disty.sa/cbv/bulk_customers_sample_file.xlsx"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-info btn-sm"
                                >
                                    Download Retailers Sample File &nbsp;
                                    <img width={15} src={downloadIcon} alt="download" />
                                </a>
                                <a
                                    // eslint-disable-next-line max-len
                                    href="https://assets.disty.sa/cbv/bulk_customers_sample_file_CBV.xlsx"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-info btn-sm"
                                >
                                    Download Retailers Sample File CBV &nbsp;
                                    <img width={15} src={downloadIcon} alt="download" />
                                </a>
                            </div>
                            <div>
                                <Button
                                    onClick={() => createMutation.mutate(true)}
                                    disabled={
                                        !selected_file?.files?.length || createMutation.isLoading
                                    }
                                >
                                    Save
                                </Button>
                            </div>
                        </>
                    }
                >
                    <FileUpload {...selected_file} />
                </Card>
                <Card
                    title="OR Create customer manually"
                    headerContent={
                        <div>
                            <Button
                                onClick={() => {
                                    set_loading(true);
                                    if (validate()) {
                                        createMutation.mutate(false);
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    }
                >
                    <div className="px-3 py-2">
                        <div className="general-fields">
                            <h3 className="divider-title">Retail user details</h3>
                            <Row>
                                <Col lg={6}>
                                    <Input {...first_name} />
                                    {loading && validation_errors.first_name && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.first_name}
                                        </p>
                                    )}
                                    <Input {...mobile} />
                                    {loading && validation_errors.mobile && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.mobile}
                                        </p>
                                    )}
                                    <Select {...occupations} />
                                    {loading && validation_errors.occupations && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.occupations}
                                        </p>
                                    )}
                                </Col>
                                <Col lg={6}>
                                    <Input {...last_name} />
                                    {loading && validation_errors.last_name && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.last_name}
                                        </p>
                                    )}
                                    <Input {...email} />
                                    {loading && validation_errors.email && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.email}
                                        </p>
                                    )}
                                    <ApiAsyncSelect
                                        label="Captured By Merchant (CBV)"
                                        api="/vendors"
                                        onlyFetchActiveRecords={false}
                                        placeholder="Type to Add Merchantnt..."
                                        labelProperty={(r) => {
                                            return (
                                                <div className="custom-option-with-thumbnail">
                                                    <img src={r.logo_ar} />
                                                    <div className="content">
                                                        <h4>{r.company_name_ar}</h4>
                                                        <p>
                                                            {r.email}
                                                            {r.mobile ? ` | ${r.mobile}` : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        onChange={(v) => set_vendor(v)}
                                        value={
                                            vendor?.id
                                                ? {
                                                      value: vendor?.id,
                                                      label: vendor?.company_name_ar,
                                                  }
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3 className="divider-title">Retail details</h3>
                            <div className="alert alert-warning">
                                Please register the customer with accurate shop details to avoid
                                auto deletion of the customer profile and records.
                            </div>
                            <Row>
                                <Col lg={6}>
                                    <Input {...store_name} />
                                    {loading && validation_errors.store_name && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.store_name}
                                        </p>
                                    )}
                                    <Input {...business_mobile} />

                                    <Input {...commercial_registration_number} />
                                    {loading &&
                                        validation_errors.commercial_registration_number && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.commercial_registration_number}
                                            </p>
                                        )}
                                    <Input {...customer_vat_number} />
                                    <Select {...entity_types} />
                                    {loading && validation_errors.entity_types && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.entity_types}
                                        </p>
                                    )}
                                    <Select {...customer_types} />
                                    <Input {...zatca_id} />
                                    <Checkbox {...has_tax_number} />
                                    <Checkbox {...is_eligible_for_pay_later} />

                                    <div>
                                        {is_eligible_for_pay_later.checked && (
                                            <CustomerPayLaterProvidersCheckboxes
                                                onChange={allowed_pay_later_providers.onChange}
                                                options={[
                                                    { label: 'Disty', id: 'disty' },
                                                    // { label: 'Ldun', id: 'ldun' },
                                                    // { label: 'Bynow', id: 'bynow' },
                                                    // { label: 'None', id: 'none' },
                                                ]}
                                                value={allowed_pay_later_providers.value}
                                            />
                                        )}
                                    </div>
                                    {loading &&
                                        is_eligible_for_pay_later.checked &&
                                        validation_errors.allowed_pay_later_providers && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.allowed_pay_later_providers}
                                            </p>
                                        )}
                                </Col>
                                <Col lg={6}>
                                    <Select {...categories} />
                                    {loading && validation_errors.categories && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.categories}
                                        </p>
                                    )}
                                    <Input {...business_email} />
                                    <DatePicker {...cr_expires_at} />
                                    {loading && validation_errors.cr_expires_at && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.cr_expires_at}
                                        </p>
                                    )}
                                    <FileUpload {...cr_certificate} />
                                    <FileUpload {...vat_certificate} />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3 className="divider-title">Address</h3>
                            <Row>
                                <Col lg={6}>
                                    <Select {...city} />
                                    {loading && validation_errors.city && (
                                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                            {validation_errors.city}
                                        </p>
                                    )}
                                </Col>
                                <Col lg={6}>
                                    <Button
                                        style={{ marginTop: 24 }}
                                        onClick={() => {
                                            dispatch(
                                                setModalStatus({
                                                    modal: 'customer_address_modal',
                                                    status: true,
                                                }),
                                            );
                                        }}
                                    >
                                        {address ? 'Edit selected address' : 'Add address +'}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <AddressModal getAddress={getAddress} />
                    {address && <ShippingAddress shippingAddressInfo={address_data} map />}
                </Card>
            </CustomPrompt>
        </div>
    );
}
