import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import UISlice from './slices/ui.slice';
import AuthSlice from './slices/auth.slice';
import VendorsSlice from './slices/merchant.slice';
import VendorCategoriesSlice from './slices/merchantCategories.slice';
import VendorUsersSlice from './slices/merchantUsers.slice';
import VendorAcquisitionsSlice from './slices/merchantAcquisitions.slice';
import ProductsSlice from './slices/products.slice';
import GlobalsSlice from './slices/globals.slice';
import CustomersSlice from './slices/customers.slice';
import CategoriesSlice from './slices/categories.slice';
import BrandsSlice from './slices/brands.slice';
import CollectionsSlice from './slices/collections.slice';
import BannersSlice from './slices/banners.slice';
import DiscountsSlice from './slices/discounts.slice';
import UsersGroupsSlice from './slices/usersGroups.slice';
import AdminsSlice from './slices/admins.slice';
import LiveCartsSlice from './slices/liveCarts.slice';
import OrdersSlice from './slices/orders.slice';
import ChildOrdersSlice from './slices/childOrders.slice';
import BanksSlice from './slices/banks.slice';
import LogsSlice from './slices/logs.slice';
import NotificationsSlice from './slices/notifications.slice';
import RatingSlice from './slices/ratings.slice';
import InvoicesSlice from './slices/invoices.slice';
import SelfInvoicesSlice from './slices/selfInvoices.slice';
import SettingsSlice from './slices/settings.slice';
// import VendorsOrders from './slices/vendorsOrders.slice';
import InvoiceFinancingOrders from './slices/invoiceFinancingOrders.slice';
import AppFeedbackSlice from './slices/appFeedback.slice';
import VendorRequestsSlice from './slices/merchantRequests.slice';
import VendorProductRequestsSlice from './slices/merchantProductRequests.slice';
import VirtualIbansSlice from './slices/virtualIbans.slice';
import OnboardingScreensSlice from './slices/onboardingScreens.slice';
import AdjustmentTypesSlice from './slices/adjustmentTypes.slice';
import PayoutsSlice from './slices/payouts.slice';
import HelpMappingSlice from './slices/helpMapping.slice';
import WireTransactionsSlice from './slices/wireTransactions.slice';
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['AuthSlice'],
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        AuthSlice,
        UISlice,
        VendorsSlice,
        VendorCategoriesSlice,
        VendorUsersSlice,
        VendorAcquisitionsSlice,
        ProductsSlice,
        GlobalsSlice,
        CustomersSlice,
        CategoriesSlice,
        BrandsSlice,
        CollectionsSlice,
        BannersSlice,
        DiscountsSlice,
        UsersGroupsSlice,
        AdminsSlice,
        LiveCartsSlice,
        OrdersSlice,
        ChildOrdersSlice,
        BanksSlice,
        LogsSlice,
        NotificationsSlice,
        InvoicesSlice,
        RatingSlice,
        SelfInvoicesSlice,
        SettingsSlice,
        // VendorsOrders,
        InvoiceFinancingOrders,
        AppFeedbackSlice,
        VendorRequestsSlice,
        VendorProductRequestsSlice,
        VirtualIbansSlice,
        OnboardingScreensSlice,
        AdjustmentTypesSlice,
        PayoutsSlice,
        HelpMappingSlice,
     WireTransactionsSlice 

    }),
);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);
