import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const HelpMappingSlice = createSlice({
    name: 'topic_mapping',
    initialState: {
        help_mapping_list: [],
        help_mapping_pages: null,
        help_mapping: {},
        help_mapping_list_loading: true,
        help_mapping_loading: true,
    },
    reducers: {
        setHelpMappingList(state, { payload }) {
            state.help_mapping_list = payload;
        },
        setHelpMapping(state, { payload }) {
            state.help_mapping = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setHelpMappingListTotalPages(state, { payload }) {
            state.help_mapping_list = payload;
        },
    },
});

// export actions
export const { setHelpMappingList, setHelpMapping, setLoading, setHelpMappingListTotalPages } =
    HelpMappingSlice.actions;

export const loadHelpMappingListData =
    (params = {}) =>
    async (dispatch) => {
        dispatch(
            setLoading({
                key: 'help_mapping_list_loading',
                value: true,
            }),
        );
        const res = await GET({
            endpoint: `/vendors/help-center-articles?${stringify(params, {
                skipNull: true,
                skipEmptyString: true,
            })}`,
        });
        dispatch(setHelpMappingList(res?.data || []));
        if (res?.meta) {
            dispatch(setHelpMappingListTotalPages(res?.meta?.last_page || 0));
        }
        dispatch(
            setLoading({
                key: 'help_mapping_list_loading',
                value: false,
            }),
        );
    };

export const loadHelpMappingData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'help_mapping_loading',
            value: true,
        }),
    );
    const res = await GET({
        endpoint: `/vendors/help-center-articles/${id}`,
    });
    dispatch(setHelpMapping(res?.data || {}));
    dispatch(
        setLoading({
            key: 'help_mapping_loading',
            value: false,
        }),
    );
};

export default HelpMappingSlice.reducer;
