import clsx from 'clsx';
import { Link } from 'react-router-dom';

const WarehouseLayout = ({
    children,
    heading,
    isLoading =false,
    className,
    linkUrl='/orders/list',
    linkText='Go back to Dashboard',
}) => {
    return (
        <div className={clsx('warehouse-grid', className)}>
            <Link href={linkUrl} className="back-btn">
                {linkText}
            </Link>
            {heading}
            {isLoading ? 'loading...' : children}
        </div>);
};

export default WarehouseLayout;
