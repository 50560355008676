import { createSlice } from '@reduxjs/toolkit';
import { GET } from '@utils';

export const AdjustmentTypesSlice = createSlice({
    name: 'adjustment-types',
    initialState: {
        adjustment_types: [],
        adjustment_types_loaded: false,
    },
    reducers: {
        setAdjustmentTypes(state, { payload }) {
            state.adjustment_types = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
    },
});

// export actions
export const {
    setAdjustmentTypes,
    setLoading,
} = AdjustmentTypesSlice.actions;

export const loadAdjustmentTypes = () => async (dispatch) => {
    const res = await GET({
        endpoint: '/payment/transactions/adjustment-type',
    });
    dispatch(setAdjustmentTypes(res?.data || []));
    dispatch(
        setLoading({
            key: 'adjustment_types_loaded',
            value: true,
        })
    );
};

export default AdjustmentTypesSlice.reducer;
