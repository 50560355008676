import {
    ApiAsyncSelect,
    Button,
    Card,
    Checkbox,
    ConfirmationModal,
    Modal,
    ViewHeader,
} from '@components';
import useMutation from '@hooks/useMutation';
import useQuery from '@hooks/useQuery';
import { setModalStatus } from '@slices/ui.slice';
import { POST } from '@utils';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export default function MergingAccounts() {
    const [source, setSource] = useState(null);
    const [target, setTarget] = useState(null);
    const [canMerge, setCanMerge] = useState(false);

    const sourceQuery = useQuery(`/customers/${source?.id}`, { stop: !source });
    const targetQuery = useQuery(`/customers/${target?.id}`, { stop: !target });

    const { mutate, isLoading } = useMutation(
        (data) => POST({ endpoint: '/customers/merge', data }),
        {
            onError: () => dispatch(setModalStatus({ modal: 'error-merging-modal', status: true })),
            onSuccess: () =>
                dispatch(setModalStatus({ modal: 'success-merging-modal', status: true })),
        },
    );

    const dispatch = useDispatch();

    const handleSubmit = () => mutate({ target_retailer: target, source_retailer: source });

    const handleOpenModal = () =>
        dispatch(
            setModalStatus({
                modal: 'confirmation_modal',
                status: true,
            }),
        );

    console.log('sourceQuery', sourceQuery, targetQuery);

    return (
        <>
            <div className="customers-list-view">
                <ViewHeader title="Merging Accounts">
                    <div className="flex__ai-center"></div>
                </ViewHeader>

                <Card className="px-4 py-4">
                    <div style={{ gap: 16 }} className="flex">
                        <h2 style={{ flex: 'auto' }}>Merging two accounts</h2>
                        <div>
                            <p className="mb-4">
                                The source account and the target account will be merged together,
                                and all the data from the source account will be transferred to the
                                target account. Then, the source account will be permanently
                                deleted.
                            </p>
                            <div className="flex w-100">
                                <div
                                    className="flex__ai-center w-100 p-2"
                                    style={{
                                        backgroundColor: source
                                            ? 'hsla(210, 20%, 98%, 1)'
                                            : undefined,
                                    }}
                                >
                                    <div className="w-100">
                                        <ApiAsyncSelect
                                            label="Source Account"
                                            api="/customers"
                                            onlyFetchActiveRecords={false}
                                            placeholder="Type to add Retailer..."
                                            labelProperty={(r) => r.store_name}
                                            onChange={(v) => setSource(v)}
                                            value={
                                                source?.id
                                                    ? {
                                                          value: source?.id,
                                                          label: source?.store_name,
                                                      }
                                                    : null
                                            }
                                        />

                                        {source?.id && (
                                            <div>
                                                <h3>
                                                    <strong>
                                                        Data to be transferred from source to target
                                                        account:
                                                    </strong>
                                                </h3>
                                                <AccountData source={sourceQuery.data?.data} />
                                                <DeletedData />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center w-25">
                                    <p>Merging With</p>
                                    {mergingIcon}
                                </div>
                                <div
                                    className="w-100 p-2"
                                    style={{
                                        backgroundColor: target
                                            ? 'hsla(210, 20%, 98%, 1)'
                                            : undefined,
                                    }}
                                >
                                    <ApiAsyncSelect
                                        label="Target Account"
                                        api="/customers"
                                        onlyFetchActiveRecords={false}
                                        placeholder="Type to add Retailer..."
                                        labelProperty={(r) => r.store_name}
                                        onChange={(v) => setTarget(v)}
                                        value={
                                            target?.id
                                                ? {
                                                      value: target?.id,
                                                      label: target?.store_name,
                                                  }
                                                : null
                                        }
                                    />
                                    {target?.id && (
                                        <div className="flex gap-4">
                                            <div>
                                                <h3>
                                                    <strong>
                                                        Total data of target account after the
                                                        merge:
                                                    </strong>
                                                </h3>
                                                <AccountData
                                                    source={sourceQuery.data?.data}
                                                    target={targetQuery.data?.data}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    padding: 6,
                                                    backgroundColor: 'hsla(216, 24%, 96%, 1)',
                                                }}
                                            >
                                                <h3>
                                                    <strong>Before merge</strong>
                                                </h3>
                                                <AccountData
                                                    source={targetQuery.data?.data}
                                                    showText={false}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex__jc-end">
                        <Button disabled={!target || !source} onClick={handleOpenModal}>
                            Submit
                        </Button>
                    </div>
                </Card>
            </div>

            <ConfirmationModal
                disabled={!canMerge}
                open={open}
                onSubmit={handleSubmit}
                loading={isLoading}
            >
                <h4 style={{ fontWeight: 700, fontSize: 22 }}>Merging two accounts</h4>
                <p className="py-4">
                    Are you sure you want to merge the sources account ({source?.store_name}) with
                    the target account ({target?.store_name})?the sources account (
                    {source?.store_name}) will be permanently deleted.
                </p>
                <Checkbox
                    label={
                        <span>
                            I understand and confirm that I want to merge the sources account (
                            {source?.store_name}) into the target account ({target?.store_name}) and
                            that the source account will be deleted permanently after the merge is
                            completed successfully.
                        </span>
                    }
                    onChange={(checked) => setCanMerge(checked)}
                />
            </ConfirmationModal>
            <Modal name="success-merging-modal" confirmBtn={false}>
                <h4 style={{ fontWeight: 700, fontSize: 22 }}>Merging process was successful</h4>

                <p>
                    The source account ({source?.store_name}) and the target account (
                    {target?.store_name}) have been successfully merged, and all data from the
                    source account ({source?.store_name}) has been transferred to the target account
                    ({target?.store_name}). The source account ({source?.store_name}) has been
                    permanently deleted
                </p>
            </Modal>
            <Modal name="error-merging-modal" confirmBtn={false}>
                <h4 style={{ fontWeight: 700, fontSize: 22 }}>Merging process has failed</h4>

                <p>
                    The merging process of the source account ({source?.store_name}) and target
                    account ({target?.store_name}) was not successful. There is an error in the data
                    of the source account. (This is an example of the issue, and it is considered a
                    placeholder only.)
                </p>
            </Modal>
        </>
    );
}

const AccountData = ({ source, target, showText = true }) => {
    const getValue = (key) => {
        const targetValue = target?.[key] || 0;
        const sourceValue = source?.[key] || 0;

        return targetValue + sourceValue;
    };

    return (
        <div>
            <div>
                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.33329 1.51303V4.26673C9.33329 4.64009 9.33329 4.82678 9.40596 4.96938C9.46987 5.09483 9.57186 5.19681 9.6973 5.26073C9.83991 5.33339 10.0266 5.33339 10.4 5.33339H13.1537M10.6666 8.66668H5.33329M10.6666 11.3333H5.33329M6.66663 6.00001H5.33329M9.33329 1.33334H5.86663C4.74652 1.33334 4.18647 1.33334 3.75865 1.55133C3.38232 1.74308 3.07636 2.04904 2.88461 2.42536C2.66663 2.85319 2.66663 3.41324 2.66663 4.53334V11.4667C2.66663 12.5868 2.66663 13.1468 2.88461 13.5747C3.07636 13.951 3.38232 14.2569 3.75865 14.4487C4.18647 14.6667 4.74652 14.6667 5.86663 14.6667H10.1333C11.2534 14.6667 11.8135 14.6667 12.2413 14.4487C12.6176 14.2569 12.9236 13.951 13.1153 13.5747C13.3333 13.1468 13.3333 12.5868 13.3333 11.4667V5.33334L9.33329 1.33334Z"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <ValueWithText
                        showText={showText}
                        value={getValue('total_orders')}
                        text="orders and suborders"
                    />
                </div>

                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11 9.33333H11.0067M2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V6C14 5.26362 13.403 4.66667 12.6667 4.66667L3.33333 4.66667C2.59695 4.66667 2 4.06971 2 3.33333ZM2 3.33333C2 2.59695 2.59695 2 3.33333 2H11.3333M11.3333 9.33333C11.3333 9.51743 11.1841 9.66667 11 9.66667C10.8159 9.66667 10.6667 9.51743 10.6667 9.33333C10.6667 9.14924 10.8159 9 11 9C11.1841 9 11.3333 9.14924 11.3333 9.33333Z"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <ValueWithText
                        showText={showText}
                        value={getValue('wallet_balance')}
                        SAR
                        text="allet and wallet transactions"
                    />
                </div>

                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.99996 8.33334C9.10453 8.33334 9.99996 7.43791 9.99996 6.33334C9.99996 5.22877 9.10453 4.33334 7.99996 4.33334C6.89539 4.33334 5.99996 5.22877 5.99996 6.33334C5.99996 7.43791 6.89539 8.33334 7.99996 8.33334Z"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M7.99996 14.6667C9.33329 12 13.3333 10.2789 13.3333 6.66668C13.3333 3.72116 10.9455 1.33334 7.99996 1.33334C5.05444 1.33334 2.66663 3.72116 2.66663 6.66668C2.66663 10.2789 6.66663 12 7.99996 14.6667Z"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <ValueWithText
                        showText={showText}
                        value={getValue('addresses_count')}
                        text="addresses"
                    />
                </div>

                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.6667 14V12.6667C14.6667 11.4241 13.8169 10.38 12.6667 10.084M10.3334 2.19384C11.3107 2.58943 12 3.54754 12 4.66667C12 5.78579 11.3107 6.7439 10.3334 7.13949M11.3334 14C11.3334 12.7575 11.3334 12.1362 11.1304 11.6462C10.8597 10.9928 10.3406 10.4736 9.6872 10.203C9.19714 10 8.57589 10 7.33337 10H5.33337C4.09086 10 3.46961 10 2.97955 10.203C2.32614 10.4736 1.80701 10.9928 1.53636 11.6462C1.33337 12.1362 1.33337 12.7575 1.33337 14M9.00004 4.66667C9.00004 6.13943 7.80613 7.33333 6.33337 7.33333C4.86061 7.33333 3.66671 6.13943 3.66671 4.66667C3.66671 3.19391 4.86061 2 6.33337 2C7.80613 2 9.00004 3.19391 9.00004 4.66667Z"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <ValueWithText
                        showText={showText}
                        value={source?.users?.length + (target?.users?.length || 0)}
                        text="retail users"
                    />
                </div>

                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.52181 2.3022C7.67547 1.99091 7.75229 1.83526 7.85659 1.78553C7.94734 1.74227 8.05277 1.74227 8.14351 1.78553C8.24781 1.83526 8.32464 1.99091 8.4783 2.3022L9.93608 5.25551C9.98144 5.34741 10.0041 5.39336 10.0373 5.42904C10.0666 5.46063 10.1018 5.48622 10.1409 5.5044C10.1851 5.52494 10.2358 5.53235 10.3372 5.54717L13.598 6.02379C13.9414 6.07398 14.1131 6.09908 14.1926 6.18295C14.2617 6.25592 14.2942 6.35619 14.281 6.45584C14.2659 6.57038 14.1416 6.69144 13.893 6.93358L11.5343 9.23094C11.4608 9.30255 11.424 9.33836 11.4003 9.38096C11.3793 9.41869 11.3658 9.46013 11.3607 9.50299C11.3548 9.5514 11.3635 9.60199 11.3808 9.70315L11.9373 12.9481C11.996 13.2903 12.0254 13.4614 11.9702 13.563C11.9222 13.6513 11.8369 13.7133 11.7381 13.7316C11.6245 13.7527 11.4708 13.6719 11.1634 13.5103L8.24829 11.9772C8.15746 11.9294 8.11205 11.9056 8.0642 11.8962C8.02184 11.8879 7.97827 11.8879 7.9359 11.8962C7.88806 11.9056 7.84264 11.9294 7.75181 11.9772L4.83666 13.5103C4.52932 13.6719 4.37565 13.7527 4.26202 13.7316C4.16316 13.7133 4.07786 13.6513 4.02987 13.563C3.97471 13.4614 4.00406 13.2903 4.06276 12.9481L4.6193 9.70315C4.63665 9.60199 4.64532 9.5514 4.63945 9.50299C4.63426 9.46013 4.62078 9.41869 4.59978 9.38096C4.57606 9.33836 4.53929 9.30255 4.46576 9.23094L2.10708 6.93358C1.85848 6.69144 1.73418 6.57038 1.71906 6.45584C1.7059 6.35619 1.73841 6.25592 1.80754 6.18295C1.887 6.09908 2.05869 6.07398 2.40207 6.02379L5.66291 5.54717C5.76432 5.53235 5.81503 5.52494 5.85919 5.5044C5.89828 5.48622 5.93348 5.46063 5.96283 5.42904C5.99598 5.39336 6.01866 5.34741 6.06402 5.25551L7.52181 2.3022Z"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <ValueWithText
                        showText={showText}
                        value={getValue('order_ratings_count')}
                        text="orders ratings"
                    />
                </div>

                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.99548 3.42388C6.66257 1.8656 4.43987 1.44643 2.76984 2.87334C1.0998 4.30026 0.864686 6.68598 2.17617 8.3736C3.26659 9.77674 6.56656 12.7361 7.64811 13.6939C7.76911 13.801 7.82961 13.8546 7.90018 13.8757C7.96178 13.8941 8.02917 13.8941 8.09077 13.8757C8.16134 13.8546 8.22184 13.801 8.34284 13.6939C9.42439 12.7361 12.7244 9.77674 13.8148 8.3736C15.1263 6.68598 14.9199 4.28525 13.2211 2.87334C11.5224 1.46144 9.32838 1.8656 7.99548 3.42388Z"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <ValueWithText
                        showText={showText}
                        value={getValue('wishlists_count')}
                        text="wishlist"
                    />
                </div>
            </div>
        </div>
    );
};

const ValueWithText = ({ value, text, showText = true }) => {
    return (
        <span>
            {' '}
            <strong>{value}</strong> {showText && text}
        </span>
    );
};

const DeletedData = () => {
    return (
        <div className="mt-4">
            <h3>
                <strong>Data to be deleted from source account:</strong>
            </h3>

            <div>
                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15.1333 9L13.8004 7.66667L12.4666 9M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C10.2013 2 12.1257 3.18542 13.1697 4.95273M8 4.66667V8L10 9.33333"
                            stroke="#6C6E73"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>{' '}
                    account status history
                </div>

                <div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_303_4895)">
                            <path
                                d="M3.33347 9.33334H12.0906C12.7658 9.33334 13.1034 9.33334 13.3722 9.20746C13.609 9.09656 13.8079 8.91851 13.9443 8.69545C14.0991 8.44223 14.1363 8.10668 14.2109 7.4356L14.6009 3.92557C14.6237 3.7206 14.6351 3.61811 14.6021 3.53878C14.5732 3.46911 14.5214 3.41132 14.4554 3.37486C14.3802 3.33334 14.2771 3.33334 14.0708 3.33334H3.00013M1.33337 1.33334H2.16567C2.34208 1.33334 2.43029 1.33334 2.4993 1.36689C2.56005 1.39643 2.6104 1.44373 2.64367 1.50252C2.68145 1.5693 2.68696 1.65734 2.69796 1.83341L3.30212 11.4999C3.31312 11.676 3.31863 11.7641 3.35642 11.8308C3.38968 11.8896 3.44003 11.9369 3.50078 11.9665C3.56979 12 3.658 12 3.83441 12H12.6667M5.00004 14.3333H5.00671M11 14.3333H11.0067M5.33337 14.3333C5.33337 14.5174 5.18414 14.6667 5.00004 14.6667C4.81595 14.6667 4.66671 14.5174 4.66671 14.3333C4.66671 14.1492 4.81595 14 5.00004 14C5.18414 14 5.33337 14.1492 5.33337 14.3333ZM11.3334 14.3333C11.3334 14.5174 11.1841 14.6667 11 14.6667C10.8159 14.6667 10.6667 14.5174 10.6667 14.3333C10.6667 14.1492 10.8159 14 11 14C11.1841 14 11.3334 14.1492 11.3334 14.3333Z"
                                stroke="#6C6E73"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_303_4895">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>{' '}
                    current live cart
                </div>
            </div>
        </div>
    );
};
const mergingIcon = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13V11ZM21.627 12L22.3342 12.7071C22.7247 12.3166 22.7247 11.6834 22.3342 11.2929L21.627 12ZM17.3342 6.29289C16.9436 5.90237 16.3105 5.90237 15.9199 6.29289C15.5294 6.68342 15.5294 7.31658 15.9199 7.70711L17.3342 6.29289ZM15.9199 16.2929C15.5294 16.6834 15.5294 17.3166 15.9199 17.7071C16.3105 18.0976 16.9436 18.0976 17.3342 17.7071L15.9199 16.2929ZM4.36561 4.49927C4.08906 4.02121 3.47734 3.85785 2.99927 4.13439C2.52121 4.41094 2.35785 5.02266 2.63439 5.50073L4.36561 4.49927ZM2.63439 18.4993C2.35785 18.9773 2.52121 19.5891 2.99927 19.8656C3.47734 20.1421 4.08906 19.9788 4.36561 19.5007L2.63439 18.4993ZM15 13H21.627V11H15V13ZM15.9199 7.70711L20.9199 12.7071L22.3342 11.2929L17.3342 6.29289L15.9199 7.70711ZM20.9199 11.2929L15.9199 16.2929L17.3342 17.7071L22.3342 12.7071L20.9199 11.2929ZM15.627 11C10.8165 11 6.61488 8.38759 4.36561 4.49927L2.63439 5.50073C5.22646 9.98163 10.0737 13 15.627 13V11ZM15.627 11C10.0737 11 5.22646 14.0184 2.63439 18.4993L4.36561 19.5007C6.61488 15.6124 10.8165 13 15.627 13V11Z"
            fill="#6C6E73"
        />
    </svg>
);
