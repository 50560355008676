import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const ProductsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        products_pages: null,
        products_records: null,
        product: {},
        products_loading: true,
        product_loading: true,
    },
    reducers: {
        setProducts(state, { payload }) {
            state.products = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.products.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.products[index] = {
                    ...state.products[index],
                    ...payload,
                };
            }
        },
        setProduct(state, { payload }) {
            state.product = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setProductsTotalPages(state, { payload }) {
            state.products_pages = payload;
        },
        setProductsRecords(state, { payload }) {
            state.products_records = payload;
        },
    },
});

// export actions
export const {
    setProducts,
    updateSingleRow,
    setProduct,
    setLoading,
    setProductsTotalPages,
    setProductsRecords,
} = ProductsSlice.actions;

export const loadProductsData =
    (params = {}) =>
    async (dispatch) => {
        dispatch(
            setLoading({
                key: 'products_loading',
                value: true,
            }),
        );
        const res = await GET({
            endpoint: `/base-product?${stringify(params, {
                skipNull: true,
                skipEmptyString: true,
            })}`,
        });
        dispatch(setProducts(res?.data || []));
        if (res?.meta) {
            dispatch(setProductsTotalPages(res?.meta?.last_page || 0));
            dispatch(
                setProductsRecords({
                    from: res?.meta?.from,
                    to: res?.meta?.to,
                    total: res?.meta?.total,
                }),
            );
        }
        dispatch(
            setLoading({
                key: 'products_loading',
                value: false,
            }),
        );
    };

export const loadProductData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'product_loading',
            value: true,
        }),
    );
    const res = await GET({
        endpoint: `/base-product/${id}`,
    });
    dispatch(setProduct({
        ...res?.data,
        status: 'old'
    } || {}));
    dispatch(setProductsTotalPages(res?.meta?.last_page || 0));
    dispatch(
        setLoading({
            key: 'product_loading',
            value: false,
        }),
    );
};

export default ProductsSlice.reducer;
