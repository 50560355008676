import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { useCallback } from 'react';

export default function IsCustomerFirstOrderFilter({
    value = null,
    set_value = () => {},
}) {
    const options = [
        { label: 'Is customer first order', value: '1' },
        { label: 'Is not customer first order', value: '0' },
    ];

    const onChange = useCallback(
        (value) => set_value(value),
        [],
    );
    
    return (
        <ChoiceList
            choices={options}
            selected={value || []}
            onChange={onChange}
        />
    );
}
