import React from 'react';
import { Modal, Input, Select } from '@components';
import { useSelector } from 'react-redux';
import { Map } from '@components';
import { POST } from '@utils';
import { toast } from 'react-toastify';
const NewShippingAddressModal = ({ id, onSave }) => {
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);

    const [google_maps_address, set_google_maps_address] = React.useState(null);
    const center_of_SA = {
        lat: 24.804219,
        lng: 45.8601,
    };
    const [marker, set_marker] = React.useState(center_of_SA);
    const [center, set_center] = React.useState(center_of_SA);
    const [coords, set_coords] = React.useState({});
    const [submitting, set_submitting] = React.useState(false);

    const [data, setData] = React.useState({
        name: '',
        details: '',
        contact_name: '',
        contact_mobile: '',
        special_mark: '',
    });

    const [suitable_delivery_time_id, set_suitable_delivery_time_id] =
		React.useState({
		    label: 'Suitable delivery time',
		    value: '',
		    onChange: (val) =>
		        set_suitable_delivery_time_id({
		            ...suitable_delivery_time_id,
		            value: val,
		        }),
		});

    // const [coordinates, setCoordinates] = React.useState({
    //     latitude: 23.885942,
    //     longitude: 45.079162,
    // });

    const [errors, setErrors] = React.useState([]);
    const [submitted, setSubmitted] = React.useState(false); // State to track if form is submitted

    const validate = () => {
        const newErrors = {};
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}-\u{1F0CF}]/u;

        if (!data.name || emojiRegex.test(!data.name)) newErrors.name = 'The name field is required ';
        if (!data.contact_name ||emojiRegex.test(!data.contact_name))
         newErrors.contact_name = 'The contact name field is required';
        if (!data.contact_mobile || !/^5[0-9]{1}[0-9]{7}$/i.test(data.contact_mobile)) {
            newErrors.contact_mobile ='The mobile field is required. It should start with 5 followed by 8 digits.';
        }
        if (!coords.lat) newErrors.latitude = 'The latitude field is required.';
        if (!coords.lng) newErrors.longitude = 'The longitude field is required.';
        if (!data.special_mark || typeof data.special_mark !== 'string' || /^[0-9]+$/.test(data.special_mark)) 
            newErrors.special_mark = 'The special mark must be a non-empty string.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
validate() ;
    }, [data,coords]);

    const onSubmit = async (close) => {
        setSubmitted(true) ;
        if (!validate()) return;
        set_submitting(true);
        const res = await POST({
            endpoint: `/customers/${id}/addresses`,
            data: {
                name: data.name,
                details: data.details,
                contact_name: data.contact_name,
                contact_mobile: data.contact_mobile,
                special_mark: data.special_mark,
                latitude: coords.lat,
                longitude: coords.lng,
                suitable_delivery_time_id: suitable_delivery_time_id.value?.value,
            },
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            close();
            // reset form
            setData({
                name: '',
                details: '',
                contact_name: '',
                contact_mobile: '',
                special_mark: '',
            });
            set_suitable_delivery_time_id((prev) => ({
                ...prev,
                value: '',
            }));
            set_coords({});
            setSubmitted(false) ;
            if (onSave) {
                onSave(res?.data);
            }
        }else if(res?.status === 400){
           toast.error(res?.data.message);
            }
        
    };

    return (
        <Modal
            title="New Shipping Address"
            name="new_shipping_address_modal"
            onSubmit={onSubmit}
            loading={submitting}
        >
          
            <label>Location</label>
            <Map
                onCoordsChange={set_coords}
                coords={coords}
                set_center={set_center}
                center={center}
                set_marker={set_marker}
                marker={marker}
                google_maps_address={google_maps_address}
                set_google_maps_address={set_google_maps_address}
                allow_search={true}
                height="600px"
            />
            <hr />
            <Input
                label="Name"
                setValue={(val) => {
                    setData({ ...data, name: val });
                }}
                value={data.name}
            />
            <Input
                label="Details"
                setValue={(val) => {
                    setData({ ...data, details: val });
                }}
                value={data.details}
            />
            <Input
                label="Contact name"
                setValue={(val) => {
                    setData({ ...data, contact_name: val });
                }}
                value={data.contact_name}
            />
            <Input
                label="Contact mobile"
                type="number"
                prefix="+966"
                setValue={(val) => {
                    setData({ ...data, contact_mobile: val });
                }}
                value={data.contact_mobile}
            />
            <Input
                label="Special mark"
                setValue={(val) => {
                    setData({ ...data, special_mark: val });
                }}
                value={data.special_mark}
            />
            <Select
                options={GlobalsSlice.delivery_times?.map((i) => ({
                    value: i.id,
                    label: i.label,
                }))}
                {...suitable_delivery_time_id}
            />
              {!!submitted  && !!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
        </Modal>
    );
};

export default NewShippingAddressModal;
