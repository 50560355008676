import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import WarehouseListView from './WarehouseListView';
import WarehouseEditView from './WarehouseEditView';
import { Helmet } from 'react-helmet';

export default function VendorUsersModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/warehouse/list">
                <Helmet>
                    <title>Warehouse Grid | Disty Admin Dashboard</title>
                </Helmet>
                <WarehouseListView />
            </ProtectedRoute>

            <ProtectedRoute exact path="/warehouse/edit">
                <Helmet>
                    <title>Edit Warehouse Grid | Disty Admin Dashboard</title>
                </Helmet>
                <WarehouseEditView />
            </ProtectedRoute>
        </Switch>
    );
}
