import React from 'react';

export default function Table({ data = [], columns = [] }) {
    return (
        <table>
            <thead>
                <tr>
                    {columns?.map((column, i) => (
                        <th key={i}
                            width={column.width}
                            style={{
                                textAlign: column.center ? 'center' : 'inherit',
                            }}
                        >{column.name}</th>
                    ))}
                </tr>
            </thead> 
            <tbody>
                {data?.map((row, i) => (
                    <tr key={i}>
                        {columns?.map((col, i) => {
                            if(col.cell) return <td key={i}>{col.cell(row)}</td>;

                            return <td key={i}>{row[col.selector]}</td>;
                                
                        
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
