import React from 'react';
// import PropTypes from 'prop-types';
import { Form, InputGroup, Row, Col } from '@components';
import { randomId } from '@utils';

export default function Input({
    setValue,
    value,
    name,
    label = '',
    type = 'text',
    className = '',
    disabled = false,
    style,
    placeholder = '',
    note = '',
    icon = '',
    prefix = '',
    units = [],
    unit = '',
    setUnit,
    dir,
    required = true,
    errorMsg = '',
    tooltip = '',
    acceptDecimal = false,
    min,
    max,
    wrapperClassName,
    maxLength,
    onBlur,
    showError = false,
}) {
    const [valid, setValid] = React.useState(true);
    const hasError = valid === false || showError;

    return (
        <Form.Group controlId={`input-${randomId()}`} className={wrapperClassName}>
            {label && <Form.Label>{label}</Form.Label>}
            <div className="input-wrapper">
                <InputGroup>
                    {prefix && (
                        <InputGroup.Prepend>
                            <InputGroup.Text>{prefix}</InputGroup.Text>
                        </InputGroup.Prepend>
                    )}
                    <Form.Control
                        value={value}
                        onChange={(e) => {
                            setValue?.(e.target.value, e.target.name);
                            setValid(e.target.checkValidity());
                        }}
                        onBlur={onBlur}
                        type={type}
                        name={name}
                        className={`${className} ${units.length ? 'unit-input' : ''}`}
                        placeholder={placeholder}
                        disabled={disabled}
                        style={style}
                        isInvalid={hasError}
                        step={acceptDecimal ? 'any' : null}
                        // isValid={valid === true}
                        dir={dir}
                        required={required}
                        min={min}
                        max={max}
                        maxLength={maxLength}
                        onWheel={type === 'number' ? (event) => event.currentTarget.blur() : null}
                    />
                    {tooltip && <div className="tooltip">{tooltip}</div>}
                </InputGroup>

                {icon && (
                    <div className="icon">
                        <img src={icon} alt="Icon" />
                    </div>
                )}

                {units.length ? (
                    <div className="input-units">
                        {units.map((x, i) => (
                            <span
                                className={unit === x ? 'active' : ''}
                                onClick={() => {
                                    setUnit(x);
                                    if (unit === x) {
                                        setUnit(null);
                                    }
                                }}
                                key={i}
                            >
                                {x}
                            </span>
                        ))}
                    </div>
                ) : (
                    ''
                )}
            </div>

            <Row>
                {note && (
                    <Col>
                        <Form.Text className="text-muted">{note}</Form.Text>
                    </Col>
                )}
                {hasError && (
                    <Col
                        style={{
                            textAlign: note ? 'right' : 'left',
                        }}
                    >
                        <Form.Text className="text-danger">{errorMsg}</Form.Text>
                    </Col>
                )}
            </Row>
        </Form.Group>
    );
}

// Input.propTypes = {
//     setValue: PropTypes.func.isRequired,
//     value: PropTypes.oneOfType([
//         PropTypes.string.isRequired,
//         PropTypes.number.isRequired,
//     ]),
//     label: PropTypes.string,
//     className: PropTypes.string,
//     disabled: PropTypes.bool,
//     type: PropTypes.string,
//     placeholder: PropTypes.string,
//     note: PropTypes.string,
//     icon: PropTypes.string,
//     prefix: PropTypes.string,

//     units: PropTypes.array,
//     unit: PropTypes.string,
//     setUnit: PropTypes.func,
// };
