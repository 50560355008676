import React from 'react';
// import { Col, Row } from 'react-bootstrap';
import { formatStringWithUnderscores, renderValue } from '@utils';

export const imagesKeys = ['logo', 'images'];

const CreateRequest = ({ data }) => {
    return (
    
        <div className="vendor-request-single">   
            <table>
                <tr className="table-head">
                    <th>Key</th>
                    <th>Value</th>
                </tr>   
                {Object.entries(data).map(([key, value]) => {
                    return (
                    // <Col className="mb-4" xs={12} lg={6} xl={4} key={key}>
                    //     <strong>{formatStringWithUnderscores(key)}</strong>
                    //     <p>{renderValue(value, {
                    //         isImg: imagesKeys.includes(key),
                    //         imgAlt: key
                    //     })}</p>
                    // </Col>
                        <React.Fragment key={key}>
                            <tr>
                                <td className="key"> 
                                    {formatStringWithUnderscores(key)}</td>
                                <td>{renderValue(value, {
                                    isImg: imagesKeys.includes(key),
                                    imgAlt: key
                                })}</td>
                            </tr>
                        </React.Fragment>
                    );
                })}
            </table>
        </div>
    );
};

export default CreateRequest;