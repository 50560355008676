import React from 'react';
import { ViewHeader, DataTable, Card, Filters, Link } from '@components';
import { GET, formatDate, formatStringWithUnderscores } from '@utils';
import { stringify } from 'query-string';
import useDidMountEffect from '@hooks/useDidMountEffect';

function VerificationRecordsListView() {
    const [data, set_data] = React.useState([]);
    const [page, set_page] = React.useState(1);
    const [total, set_total] = React.useState(null);
    const [loading, set_loading] = React.useState(true);
    const [filters, set_filters] = React.useState({});

    const getRecordData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/customers/verifications?${stringify(
                { ...filters, page, records_per_page: 40 },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                },
            )}`,
        });
        set_loading(false);
        if (res?.status_code == 200) {
            set_data(res?.data);
            set_total(res?.meta?.last_page);
        }
    };

    const conditionalRowStyles = [
        {
            // highlight new records
            when: (row) => row.verification_decision?.state === 'pending',
            style: {
                background: '#dc35452b !important',
            },
        },
    ];

    useDidMountEffect(() => {
        getRecordData();
    }, [filters, page]);

    const columns = [
        {
            name: 'Shop Name',
            cell: (row) => (
                <Link href={`/verification-center/${row.id}`} className="tr-name">
                    <h5>{row?.shop_name}</h5>
                </Link>
            ),
        },
        {
            name: 'Retailer Name',
            cell: (row) => (
                <Link href={`/verification-center/${row.id}`} className="tr-name">
                    <h5>
                        {row?.first_name} {row?.last_name}
                    </h5>
                </Link>
            ),
        },
        {
            name: 'Registration Date',
            cell: (row) => (
                <Link href={`/verification-center/${row.id}`} className="tr-name">
                    {formatDate(row?.registration_date)}
                </Link>
            ),
        },
        {
            name: 'Total Live Cart',
            cell: (row) => (
                <Link href={`/verification-center/${row.id}`} className="tr-name">
                    {row?.live_cart_total}
                </Link>
            ),
        },
        {
            name: 'Verification Decision',
            cell: (row) => (
                <Link href={`/verification-center/${row.id}`} className="tr-name">
                    {row?.verification_decision?.description}
                </Link>
            ),
        },
        {
            name: 'Verification Status',
            cell: (row) => (
                <Link href={`/verification-center/${row.id}`} className="tr-name">
                    {formatStringWithUnderscores(row?.verification_state)}
                </Link>
            ),
        },
        {
            name: 'Verification Date',
            cell: (row) => (
                <Link href={`/verification-center/${row.id}`} className="tr-name">
                    {formatDate(row?.verification_date)}
                </Link>
            ),
        },
    ];

    const handleRowClicked = (row) => {
        window.location.href = `/verification-center/${row?.id}`;
    };

    return (
        <div className="verification_center_list">
            <ViewHeader title="Verification Center"></ViewHeader>
            <Card>
                <Filters
                    queryPlaceholder="Search by Retailer Name, Shop Name, CR Number,
                     VAT number, Phone number, Email"
                    filters={[
                        'verification_state',
                        'verification_decision',

                        'verification_date_from',
                        'verification_date_to',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    // initialFiltersValues={{
                    //     sort_by: 'created_at',
                    // }}
                    sort_options={[
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Verification date (Newest first)',
                            value: 'verification_date__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Verification date (Oldest first)',
                            value: 'verification_date__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Live cart total (Newest first)',
                            value: 'live_cart_total__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Live cart total (Oldest first)',
                            value: 'live_cart_total__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={data}
                    columns={columns}
                    loading={loading}
                    pages={total}
                    page={page}
                    handlePageChange={set_page}
                    searchable={false}
                    onRowClicked={handleRowClicked}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </Card>
        </div>
    );
}

export default VerificationRecordsListView;
