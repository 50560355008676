import React from 'react';
import { Card, DataTable } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import AdjustmentDetailModal from './AdjustmentDetailModal';
import formatMoney from '@utils/formatMoney';
import formatDate from '@utils/formatDate';
import { useParams } from 'react-router';
import { GET } from '@utils';
import AddAdjustmentTransactionsModal from './AddAdjustmentTransactionsModal';
const Adjustments = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const AuthSlice = useSelector((state) => state.AuthSlice);
    const [adjustment_data , set_adjustment_data]=React.useState([]);
    const [loading , set_loading]=React.useState([]);
    const view_adjustment_transactions = AuthSlice.user.permissions.length
        ? AuthSlice.user.permissions.includes(
            'payment.view_adjustment_transactions'
        )
        : true;
    const create_adjustment_transactions = AuthSlice.user.permissions.length
        ? AuthSlice.user.permissions.includes(
            'payment.create_adjustment_transaction'
        )
        : true;
    const [detailsRow, setDetailsRow] = React.useState(null);
    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/payment/orders/${id}/transactions?method=adjustment`,
        });
        set_loading(false);
        if(res.status_code === 200) set_adjustment_data(res?.data || []);
    };
    React.useEffect(() => {
        fetchData();
    }, [id]);
    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            width: '80px'
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: false,
            format: (row) =>
                formatMoney(row.amount) + ' ' + row.currency.toUpperCase()
        },
        {
            name: 'Description',
            sortable: false,
            cell: (row) => row?.adjustmentTransaction?.description
        },
        {
            name: 'Adjustment type',
            sortable: false,
            cell: (row) => row?.adjustmentTransaction?.type?.type_en
        },
        {
            name: 'Date & Time',
            selector: 'created_at',
            sortable: false,
            format: (r) => formatDate(r.created_at)
        },
        {
            name: 'Status',
            selector: 'status', // TODO status is not present in the API so I added type
            sortable: false,
            cell: (row) => {
                const status_message = row.statuses.length
                    ? row.statuses[row.statuses.length - 1]?.status
                    : '-';
                return status_message;
            }
        },
        {
            name: 'Details',
            sortable: false,
            cell: (row) => (
                <div
                    className="more-details-btn"
                    onClick={() => {
                        setDetailsRow(row);
                        dispatch(
                            setModalStatus({
                                modal: 'adjustment_detail_modal',
                                status: true
                            })
                        );
                    }}
                >
                    Show details
                </div>
            )
        }
    ];

    return (
        <>
            {view_adjustment_transactions && ( <Card
                title="Adjustment Transactions"
                className="payment-info"
            >
                <AdjustmentDetailModal details={detailsRow} />
                <DataTable
                    data={adjustment_data}
                    loading={loading}
                    columns={columns}
                />     
                <br />
                {create_adjustment_transactions && (
                    <div className="flex__jc-end px-3 pb-2">     
                        <button
                            className="btn btn-primary"
                            onClick={() =>
                                dispatch(
                                    setModalStatus({
                                        modal: 'add_adjustment_transaction',
                                        status: true
                                    })
                                )
                            }
                        >
                        Add Adjustment Transaction
                        </button>
                    </div>
                )}
              
            </Card>)}
            <AddAdjustmentTransactionsModal id={id} fetch_adjustments={fetchData} />
        </>
    );
};

export default Adjustments ;
