import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { useCallback } from 'react';
import useQuery from '@hooks/useQuery';

export default function InvoicingModelsFilter({ value = null, set_value = () => {} }) {
    const { data: { data } = { data: [] } } = useQuery('/order/static-data');
    const options = data?.invoicing_models?.map((o) => ({
        label: o.description,
        value: o.code,
    }));
    const onChange = useCallback((value) => set_value(value), []);

    return (
        <>
            <ChoiceList
                choices={options || []}
                selected={value || []}
                onChange={onChange}
                allowMultiple
            />
        </>
    );
}
