import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import LiveCartsListView from './LiveCartsListView';
import LiveCartsSingleView from './LiveCartsSingleView';
import { Helmet } from 'react-helmet';

export default function LiveCartsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/live-carts/list">
                <Helmet>
                    <title>Live Carts | Disty Admin Dashboard</title>
                </Helmet>
                <LiveCartsListView />
            </ProtectedRoute>   
            <ProtectedRoute exact path="/live-carts/:id">
                <Helmet>
                    <title>Live Cart | Disty Admin Dashboard</title>
                </Helmet>
                <LiveCartsSingleView />
            </ProtectedRoute>   
        </Switch>
    );
}
