import React from 'react';
import { DataTable, Button, Modal, Textarea, ShowErrors } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { GET, POST, DELETE, obj2formdata, randomId } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';

const InvoicesRejectionReasonsTab = () => {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [rerender, set_rerender] = React.useState(0);
    const [errors, set_errors] = React.useState([]);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/vendors/invoice-requests-reasons',
            });
            set_data(res?.data || []);
            set_loading(false);
        };
        fetchData();
    }, [rerender]);

    const [description_ar, set_description_ar] = React.useState({
        label: 'Description (arabic)',
        value: '',
        setValue: (val) => set_description_ar({ ...description_ar, value: val }),
    });
    const [description_en, set_description_en] = React.useState({
        label: 'Description (english)',
        value: '',
        setValue: (val) => set_description_en({ ...description_en, value: val }),
    });

    const submit = async (closeModal, id) => {
        set_loading(true);
        const data = obj2formdata({
            description_ar: description_ar.value,
            description_en: description_en.value,
        });

        if (id) {
            data.append('_method', 'PUT');
        }

        const res = await POST({
            endpoint: id ? `/vendors/invoice-requests-reasons/${id}` 
                : '/vendors/invoice-requests-reasons',
            data,
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('rejection-reason__modal');
            set_rerender(randomId());
            description_ar.setValue('');
            description_en.setValue('');
            set_errors([]);
        }
    };

    const columns = [
        {
            name: 'Description (AR)',
            selector: 'description_ar',
        },
        {
            name: 'Description (EN)',
            selector: 'description_en',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger" 
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'rejection-reason__delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>

					&nbsp;&nbsp;
                    
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            description_ar.setValue(row.description_ar);
                            description_en.setValue(row.description_en);
                            set_edit_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'rejection-reason__modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const deleteRejectionReason = async closeModal => {
        set_loading(true);
        const res = await DELETE({
            endpoint: `/vendors/invoice-requests-reasons/${delete_id}`
        });
        set_loading(false);
        
        closeModal('rejection-reason__delete_modal');

        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('rejection-reason__delete_modal');
            set_rerender(randomId());
            set_delete_id(null);
        }
    };

    return (
        <>
            <DataTable data={data} columns={columns} loading={loading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        description_ar.setValue('');
                        description_en.setValue('');
                        set_edit_id(null);
                        set_errors([]);
                        dispatch(
                            setModalStatus({
                                modal: 'rejection-reason__modal',
                                status: true,
                            })
                        );
                    }}
                >
						Add new rejection reason
                </Button>
            </div>

            <Modal
                title={edit_id ? 'Update rejection reason' : 'Add new rejection reason'}
                variant={edit_id ? 'warning' : 'primary'}
                name="rejection-reason__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
                loading={loading}
            >
                <Textarea {...description_ar} />
                <Textarea {...description_en} />
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>

            <Modal
                title="Delete rejection reason?"
                name="rejection-reason__delete_modal"
                variant="danger"
                onSubmit={deleteRejectionReason}
                loading={loading}
            />
        </>
    );
};

export default InvoicesRejectionReasonsTab;
