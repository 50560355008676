import React from 'react';
import {
    ViewHeader,
    Link,
    DataTable,
    Card,
    StatusToggler,
    WhatsApp,
    Dialer,
    Filters,
    Sop,
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadAdminsData, updateSingleRow } from '@slices/admins.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function AdminsListView() {
    const AdminsSlice = useSelector((state) => state.AdminsSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({
        status: null,
    });

    useDidMountEffect(() => {
        dispatch(loadAdminsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <Link href={`/admins/${row.id}`} className="tr-name">
                    <h5>
                        {row.first_name} {row.last_name}
                    </h5>
                </Link>
            ),
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />,
        },
        {
            name: 'Email',
            selector: 'email',
            minWidth: '180px',
            cell: (row) => <a href={`mailto:${row?.email}`}>{row?.email}</a>,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row.is_active}
                    path={`/user/${row.id}/status`}
                    callback={({ id, status }) => {
                        dispatch(updateSingleRow({ id, is_active: status }));
                    }}
                />
            ),
        },
    ];

    return (
        <div className="admins-list-view">
            <ViewHeader title="Admins">
                <Sop id="30183" />
                <Link href="/admins/add" className="btn btn-primary">
                    New admin
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    filters={['status']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={AdminsSlice.admins}
                    pages={AdminsSlice.admins_pages}
                    page={page}
                    records={AdminsSlice.admins_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={AdminsSlice.admins_loading}
                />
            </Card>
        </div>
    );
}
