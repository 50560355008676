import React from 'react';
import {
    ViewHeader,
    Button,
    FileUpload,
    Card,
    Row,
    Col,
    // Toggler,
    Input,
    ShowErrors,
    Sop,
} from '@components';
import { obj2formdata, POST, isArabic, isEnglish } from '@utils';
import { Redirect } from 'react-router';

export default function BrandsAddView() {
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(null);
    const [errors, setErrors] = React.useState([]);

    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => (isArabic(val) ? set_name_ar({ ...name_ar, value: val }) : ''),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_name_en({ ...name_en, value: val }) : ''),
    });

    const [logo_ar, set_logo_ar] = React.useState({
        label: 'Logo (arabic)',
        files: [],
        setFiles: (val) => set_logo_ar({ ...logo_ar, files: val }),
    });
    const [logo_en, set_logo_en] = React.useState({
        label: 'Logo (english)',
        files: [],
        setFiles: (val) => set_logo_en({ ...logo_en, files: val }),
    });

    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name_ar.value) newErrors.name_ar = 'The name (arabic) field is required.';
        if (!name_en.value) newErrors.name_en = 'The name (english) field is required.';
        if (logo_ar.files.length === 0) newErrors.logo_ar = 'The logo (arabic) field is required.';
        if (logo_en.files.length === 0) newErrors.logo_en = 'The logo (english) field is required.';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name_ar, name_en, logo_ar, logo_en]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const data = obj2formdata({
                name_ar: name_ar.value,
                name_en: name_en.value,
            });

            logo_ar.files.length && data.append('logo_ar', logo_ar.files[0]);
            logo_en.files.length && data.append('logo_en', logo_en.files[0]);

            const res = await POST({
                endpoint: '/brand',
                data,
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_created(res?.data.id);
            }
        }
    };

    if (created) {
        return <Redirect to="/brands/list" />;
    }

    return (
        <div className="brands-form-view">
            <ViewHeader title="Create new brand" renderBackArrow>
                <Sop id="30023" />
                <Button onClick={submit} variant="primary">
                    Create
                </Button>
            </ViewHeader>

            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

            <Card>
                <div className="px-3 py-2">
                    <Row>
                        <Col lg={6}>
                            <Input {...name_ar} />
                            {loading && validation_errors.name_ar && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.name_ar}
                                </p>
                            )}

                            <Input {...name_en} />
                            {loading && validation_errors.name_en && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.name_en}
                                </p>
                            )}
                        </Col>
                        <Col lg={6}>
                            <FileUpload {...logo_ar} />
                            {loading && validation_errors.logo_ar && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.logo_ar}
                                </p>
                            )}

                            <FileUpload {...logo_en} />
                            {loading && validation_errors.logo_en && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.logo_en}
                                </p>
                            )}
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    );
}
