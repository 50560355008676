import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import HelpMapping from './HelpMapping';
import { Helmet } from 'react-helmet';

export default function ConfigsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/help_mapping">
                <Helmet>
                    <title>Configs | Help Topic Mapping</title>
                </Helmet>
                <HelpMapping />
            </ProtectedRoute>
        </Switch>
    );
}
