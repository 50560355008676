/**
 * @todo initlized the form values from API response
 */

import { Checkbox, Select } from '@components';
import { MARKETPLACE_INVOICING_TRIGGERS } from '@components/shared/Filters/constants';
import { useState } from 'react';

const MarketplaceInvoicing = ({ onChange, initialValues }) => {
    const [tax_invoice_trigger, set_tax_invoice_trigger] = useState({
        options: MARKETPLACE_INVOICING_TRIGGERS,
        getOptionValue: (option) => option.value,
        label: 'Marketplace invoicing triggers',
        value: MARKETPLACE_INVOICING_TRIGGERS.find(
            ({ value }) => value == initialValues?.tax_invoice_trigger,
        ),
        isMulti: false,
        onChange: (val) => set_tax_invoice_trigger((prev) => ({ ...prev, value: val })),
    });
    const [cbv_invoice_issuance_allowed, set_cbv_invoice_issuance_allowed] = useState(
        !!initialValues?.cbv_invoice_issuance_allowed,
    );
    const [cbv_invoices_available_for_customers, set_cbv_invoices_available_for_customers] =
        useState(!!initialValues?.cbv_invoices_available_for_customers);
    const [invoice_management_enabled, set_invoice_management_enabled] = useState(
        !!initialValues?.invoice_management_enabled,
    );
    const handleChange = ({ trigger, issueInvoices, makeInvoicesAvailable, managedInvoices }) => {
        const _issueInvoices =
            typeof issueInvoices !== 'undefined' ? issueInvoices : cbv_invoice_issuance_allowed;
        const _makeInvoicesAvailable =
            typeof makeInvoicesAvailable !== 'undefined'
                ? makeInvoicesAvailable
                : cbv_invoices_available_for_customers;
        const _managedInvoices =
            typeof managedInvoices !== 'undefined' ? managedInvoices : invoice_management_enabled;
        onChange({
            tax_invoice_trigger: trigger?.value || tax_invoice_trigger?.value?.value,
            cbv_invoice_issuance_allowed: _issueInvoices,
            cbv_invoices_available_for_customers: _makeInvoicesAvailable,
            invoice_management_enabled: _managedInvoices,
        });

        if (trigger?.value) tax_invoice_trigger.onChange(trigger);
        set_cbv_invoice_issuance_allowed(_issueInvoices);
        set_cbv_invoices_available_for_customers(_makeInvoicesAvailable);
        set_invoice_management_enabled(_managedInvoices);
    };

    return (
        <div>
            <hr />
            <div
                style={{ maxWidth: 600, alignItems: 'last baseline' }}
                className="flex__jc-between"
            >
                <div style={{ width: '50%' }}>
                    <Select
                        {...tax_invoice_trigger}
                        onChange={(val) => handleChange({ trigger: val })}
                        disabled={invoice_management_enabled}
                    />
                </div>
                <Checkbox
                    label="Merchant Managed Invoices"
                    checked={invoice_management_enabled}
                    onChange={() => handleChange({ managedInvoices: !invoice_management_enabled })}
                />
            </div>

            <hr />
            <div>
                <h3 className="mb-3">CBV settings</h3>
                <Checkbox
                    label="Issue invoices for CBV - Merchant source"
                    checked={cbv_invoice_issuance_allowed}
                    onChange={() => handleChange({ issueInvoices: !cbv_invoice_issuance_allowed })}
                />
                <p style={{ fontSize: 14, color: '#7d8083' }}>
                    Enabling this flag will make the system issue tax invoices/credit notes for CBV
                    orders created from Merchant app
                </p>
                <br />
                <Checkbox
                    label="Make invoices available for customer for CBV - Merchant source"
                    checked={cbv_invoices_available_for_customers}
                    onChange={() =>
                        handleChange({
                            makeInvoicesAvailable: !cbv_invoices_available_for_customers,
                        })
                    }
                />
                <p style={{ fontSize: 14, color: '#7d8083', marginBottom: 16 }}>
                    Enabling this flag will make the system make the tax invoices/ credit notes
                    available for download by the customers in the marketplace app, and will send
                    push notifications when the invoices are available
                </p>
            </div>
        </div>
    );
};

export default MarketplaceInvoicing;
