/* eslint-disable max-len */
import React from 'react';
import {
    ViewHeader,
    Button,
    Card,
    Row,
    Col,
    Select,
    CustomerInfo,
    ShippingAddress,
    Receipt,
    ApiAsyncSelect,
    PaymentMethodIcons,
    Radio,
    CustomPrompt,
    Sop,
    DatePicker,
    Input,
    ConfirmationModal,
} from '@components';
import defaultLogo from '@assets/img/default.jpg';
import { GET, POST, formatMoney, quantityOptions, randomId } from '@utils';
import { toast } from 'react-toastify';
import NewShippingAddressModal from './OrdersSignleView/NewShippingAddressModal';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import useScheduledOrdersQuery from '../../hooks/useScheduledOrdersQuery';
import { setNewOrderFromLiveCart } from '../../store/slices/orders.slice';
import { Redirect } from 'react-router';

export default function OrdersFormView() {
    const dispatch = useDispatch();
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const idempotencyKey = randomId();
    const [from_live_cart, set_from_live_cart] = React.useState(false);
    const [loading, set_loading] = React.useState(false);
    const [loading_summary, set_loading_summary] = React.useState(false);
    const [shipment_preferences, set_shipment_preferences] = React.useState([]);
    const [selected_shipment_preference, set_selected_shipment_preference] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [customer, setCustomerInfo] = React.useState(null);
    const [addresses, setAddresses] = React.useState([]);
    const [address, setAddressInfo] = React.useState(null);
    const { data: scheduled_orders } = useScheduledOrdersQuery();
    const [selected_scheduled_order, set_selected_scheduled_order] = React.useState(null);
    const [selected_scheduled_order_options, set_selected_scheduled_order_options] = React.useState(
        {
            scheduled_order_delivery_option_id: null,
            scheduled_order_payment_option_id: null,
        },
    );
    const [errors, setErrors] = React.useState([]);
    const [summary, set_summary] = React.useState([]);
    const [total, set_total] = React.useState(0);
    const [created, set_created] = React.useState({
        success: false,
        id: null,
    });

const [payment_types,set_payment_types]=React.useState([]);
React.useEffect(() => {
    const fetchData = async () => {
        const payment_types = await GET({
            endpoint: '/customers/customer-payment-types',
        });

        set_payment_types(payment_types?.data.map((b) => {
            return {
                value: b.name_en,
                label: b.name_en,
            };
        }) || []);
    };

    fetchData();
}, []);
    React.useEffect(() => {
        const fetchData = async () => {
            const shipment_preferences = await GET({
                endpoint: '/order/shipment-preferences',
            });

            set_shipment_preferences(shipment_preferences?.data || []);
        };

        fetchData();
    }, []);

    React.useEffect(() => {
        set_selected_business_model({
            ...selected_business_model,
            options: OrdersSlice?.order_static_data?.business_models?.map((b) => ({
                id: b?.code,
                label: b?.description,
            })),
        });
    }, [OrdersSlice?.order_static_data?.business_models]);

    const [selected_business_model, set_selected_business_model] = React.useState({
        label: 'Business Model',
        options: OrdersSlice?.order_static_data?.business_models?.map((b) => ({
            id: b?.code,
            label: b?.description,
        })),
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_selected_business_model((prev) => ({ ...prev, value: val })),
    });

    const [business_payment_mode, set_business_payment_mode] = React.useState({
        label: 'Business Payment Mode',
         value: '',
        onChange: (val) => set_business_payment_mode((prev) => ({ ...prev, value: val })),
    });
    const [amount, set_amount] = React.useState({
        label: 'Paid Amount',
        value: '',
        type: 'number',
        acceptDecimal:true,
        setValue: (val) => set_amount({ ...amount, value: val.replace(/[^\d.-]+/g, '') }),
    });
    const is_cbv = selected_business_model?.value?.id === 'cbv';

    const [creation_date, set_creation_date] = React.useState({
        label: 'Creation Date',
        selectedDate: '',
        dateOnly: true,
        maxDate: new Date(),
        onChange: (val) => set_creation_date((prev) => ({ ...prev, selectedDate: val })),
    });

    React.useEffect(() => {
        if (OrdersSlice.new_order_from_live_cart) {
            const business_model = { id: 'marketplace', label: 'Marketplace' };
            selected_business_model.onChange(business_model);

            const fetchData = async () => {
                set_loading(true);
                const [customer_addresses, customer] = await Promise.all([
                    GET({
                        endpoint: `/customers/${OrdersSlice.new_order_from_live_cart.customer_id}/addresses`,
                    }),
                    GET({
                        endpoint: `/customers/${OrdersSlice.new_order_from_live_cart.customer_id}`,
                    }),
                ]);

                fetchOrderDraft(OrdersSlice.new_order_from_live_cart.products, business_model);

                if (OrdersSlice.new_order_from_live_cart.scheduled_order) {
                    set_selected_scheduled_order(
                        OrdersSlice.new_order_from_live_cart.scheduled_order,
                    );
                    set_selected_scheduled_order_options({
                        scheduled_order_delivery_option_id:
                            OrdersSlice.new_order_from_live_cart.scheduled_order_delivery_option_id,
                        scheduled_order_payment_option_id:
                            OrdersSlice.new_order_from_live_cart.scheduled_order_payment_option_id,
                    });
                }

                if (customer_addresses.data) {
                    const selected_address = customer_addresses.data.find(
                        (a) => a.id === OrdersSlice.new_order_from_live_cart.address_id,
                    );
                    if (selected_address) {
                        setAddress({
                            ...selected_address,
                            value: selected_address?.id,
                            label: selected_address?.name,
                        });
                    }
                    setAddresses(
                        customer_addresses.data?.map((d) => ({
                            ...d,
                            value: d.id,
                            label: d.name,
                        })),
                    );
                }

                if (customer.data) {
                    setCustomerInfo(customer.data);
                }

                set_loading(false);
            };

            set_from_live_cart(true);
            fetchData();
        }
        return () => {
            dispatch(setNewOrderFromLiveCart(null));
        };
    }, []);

    const fetchOrderDraft = async (arr, bm) => {
        const business_model = bm || selected_business_model.value;
        if (!business_model?.id || !arr.length) return;
        set_loading_summary(true);
        setErrors([]);
        const draft = await POST({
            endpoint: '/order/calculate-draft',
            data: {
                business_model: business_model?.id,
                address_id: address?.id,
                products: arr?.map((p) => ({
                    id: p.id,
                    quantity: p.quantity,
                    ...(is_cbv
                        ? {
                              price: p.price_changeable || p.price,
                          }
                        : {}),
                })),
            },
        });

        if (draft.status_code === 200) {
            const products = [];

            draft.data.sub_orders.forEach((s) => {
                s.products.forEach((p) => {
                    products.push({
                        ...p,
                        total: is_cbv ? p.subtotal : p.total,
                        price: is_cbv ? p.unit_price : p.price,
                        min_amount: s.min_amount,
                    });
                });
            });

            setData(products);
            set_summary([
                {
                    subject: { en: 'Subtotal', ar: 'المنتجات' },
                    note: 'Subtotal without VAT (بدون ضريبة القيمة المضافة)',
                    price: draft.data.subtotal,
                },
                ...[
                    ...draft.data.fees.map((fee) => ({
                        subject: { en: fee.name, ar: fee.name },
                        price: fee.amount,
                    })),
                ],
                {
                    subject: {
                        en: 'VAT',
                        ar: 'ضريبة القيمة المضافة',
                    },
                    price: draft.data.vat,
                },
                {
                    subject: { en: 'Delivery fees', ar: 'رسوم التوصيل' },
                    price: draft.data?.delivery_fees,
                },
                {
                    subject: { en: 'Discount', ar: 'الخصم' },
                    price: draft.data.discount,
                },
            ]);

            set_total(draft.data.total);
        } else if (draft.status === 422) {
            setErrors(draft.data.errors);
        }
        set_loading_summary(false);
    };

    React.useEffect(() => {
        if (!data.length) {
            set_summary([
                {
                    subject: { en: 'Subtotal', ar: 'المنتجات' },
                    note: 'Subtotal without VAT (بدون ضريبة القيمة المضافة)',
                    price: 0,
                },
                {
                    subject: {
                        en: 'VAT',
                        ar: 'ضريبة القيمة المضافة',
                    },
                    price: 0,
                },
                {
                    subject: { en: 'Discount', ar: 'الخصم' },
                    price: 0,
                },
            ]);

            set_total(0);
        }
    }, [data]);

    React.useEffect(() => {
        fetchOrderDraft(data);
    }, [selected_business_model.value]);

    var delayTimer;
    const setRowProperty = (key, id, val) => {
        clearTimeout(delayTimer);

        const new_data = data.map((product) => {
            if (product.id === id) {
                product[key] = val;
            }
            return product;
        });

        setData(new_data);

        delayTimer = setTimeout(function () {
            fetchOrderDraft(new_data);
        }, 1000);
    };

    const addDataProduct = (p) => {
        const prev = data.map((v) => v);
        if (prev.map((v) => v.id).indexOf(p.id) >= 0) {
            return;
        }
        prev.push({
            ...p,
            quantity: p.order_min_quantity || 0,
            total: p.order_min_quantity * p.price_including_vat,
        });
        setData(prev);
        fetchOrderDraft(prev);
    };

    const deleteDataProduct = (id) => {
        const prev = data.filter((v) => v.id !== id);
        setData(prev);
    };

    const setCustomer = async (c) => {
        setCustomerInfo(c);
        const res = await GET({
            endpoint: `/customers/${c.id}/addresses`,
        });
        const addresses = res?.data.map((d) => ({
            ...d,
            value: d.id,
            label: d.name,
        }));
        // addresses.push();
        setAddresses(addresses);
        setAddress(null);
    };

    const addNewAddress = (d) => {
        const newAdds = addresses.filter((v) => v.value !== 'new');
        const v = {
            ...d,
            value: d.id,
            label: d.name,
        };
        newAdds.push(v);
        // newAdds.push({ value: 'new', label: 'Add new...' });
        setAddresses(newAdds);
        setAddress(v);
    };

    const setAddress = (v) => {
        if (v && v.value === 'new') {
            dispatch(
                setModalStatus({
                    modal: 'new_shipping_address_modal',
                    status: true,
                }),
            );
        } else {
            setAddressInfo(v);
        }
    };

    const customerInfo = [
        {
            key: 'Company name',
            value: customer?.store_name,
            className: 'btn btn-link',
            onClick: () => {
                window.open(`/customers/${customer.id}`);
            },
        },
        {
            key: 'Customer email',
            value: customer?.email,
        },
        {
            key: 'Customer mobile',
            value: customer?.mobile,
        },
        {
            key: 'Customer Type',
            value: customer?.payment_type,
        },
        {
            key: 'Monthly target',
            value:  (
                <span>
                  {formatMoney(customer?.monthly_target)} <span>SAR</span>
                </span>
              ),
        }, {
            key: 'Balance',
            value:  (
                <span>
                  {formatMoney(customer?.balance)} <span>SAR</span>
                </span>
              ),
        }, {
            key: 'This month achieved percentage',
            value:   (
                <span>
                  {formatMoney(customer?.achieved_percentage)} %
                </span>
              ),
        }, {
            key: 'This month achieved',
            value:   (
                <span>
                  {formatMoney(customer?.month_achieved)}  <span>SAR</span>
                </span>
              ),
        },{
            key: 'Total orders',
            value:   customer?.total_orders 
               
        },{
            key: 'Total sales',
            value:   (
                <span>
                  {formatMoney(customer?.total_spent)}  <span>SAR</span>
                </span>
              ),
        }, {
            key: 'Status',
            value:   (
           
                <span className={`${customer?.status?"text-success":"text-danger"} font-weight-bold`}>
               { customer?.status?"Active":"Blocked" }
                </span>
              ),
        },
    ];

    const shippingAddresses = {
        data: [
            {
                key: 'Branch name',
                value: address?.name,
            },
            {
                key: 'Contact name',
                value: address?.contact_name,
            },
            {
                key: 'Contact mobile',
                value: address?.contact_mobile,
            },
            {
                key: 'Preferred delivery time',
                value: address?.suitable_delivery_time?.name,
                className: 'badge badge-light',
            },
            {
                key: 'Special Mark',
                value: address?.special_mark,
            },
            {
                key: 'Details',
                value: address?.details,
            },
            {
                key: 'Courier',
                value: '', // TODO
            },
            {
                key: 'Tracking number',
                value: '', // TODO
            },
        ],
        address: {
            country: 'Saudi Arabia',
        },
        coordinates: {
            latitude: address?.latitude,
            longitude: address?.longitude,
        },
    };
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};

        if (!selected_business_model.value) {
            newErrors.selected_business_model = 'The business model field is required.';
        }

        if (!address) {
            newErrors.address = 'The address id field is required.';
        }
        if (!customer) {
            newErrors.address = 'The customer id field is required.';
        }
        if (data.length === 0) {
            newErrors.products = 'The products field is required.';
        } 
        if(!business_payment_mode.value){
            newErrors.payment_mode = 'Payment mode field is required.'; }
        if(!amount.value){
            newErrors.paidAmount = 'Paid amount field is required.';
 }
       else if(business_payment_mode.value.value ==='CASH' && Number(amount.value) !== Number(total)){
            newErrors.paidAmount = 'Paid amount must equal total amount when payment mode is cash';
        }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [selected_business_model, address, customer, data ,amount,business_payment_mode]);
console.log(amount.value);
    const onSubmit = async () => {
        set_loading(true);
        if (validate()) {
            const res = await POST({
                endpoint: '/order',
                data: {
                    paid_amount:amount.value,
                    payment_type:business_payment_mode.value.value,
                    products: data.map((j) => ({
                        id: j.id,
                        quantity: j.quantity,
                        ...(is_cbv
                            ? {
                                  price: j.price_changeable || j.price,
                              }
                            : {}),
                    })),
                    customer_id: customer?.id,
                    address_id: address?.id,

                    ...(selected_scheduled_order
                        ? {
                              scheduled_order_id: selected_scheduled_order.id,
                              ...selected_scheduled_order_options,
                          }
                        : {}),

                    ...(selected_shipment_preference
                        ? {
                              shipment_preference: selected_shipment_preference,
                          }
                        : {}),

                    ...(from_live_cart
                        ? {
                              live_cart_id: OrdersSlice.new_order_from_live_cart.live_cart_id,
                          }
                        : {}),
                    business_model: selected_business_model.value.id,
                
                    ...(is_cbv
                        ? {
                              creation_date: creation_date.selectedDate,
                          }
                        : {}),
                },
                headers: {
                    'Idempotency-Key': idempotencyKey,
                },
            });

            if (res?.status_code === 201 || res?.status_code === 200) {
                if (res?.data.warning_messages?.length) {
                    res?.data.warning_messages.forEach((msg) => {
                        toast.warn(msg);
                    });
                }
                set_created({ success: true, id: res?.data.order.id || null });
            } else {
                let errors = res?.data.errors;
                errors = !errors ? { error: [res?.data?.message] } : errors;
                errors = !errors ? { error: ['Something went wrong'] } : errors;

                setErrors(errors);
            }
        }
    };

    if (created.success) {
        return <Redirect to={created.id ? `/orders/${created.id}` : '/orders/list'} />;
    }
    // if (loading) {
    //     return 'loading...';
    // }

    return (
        <div className="orders-add-view">
            <CustomPrompt>
                <NewShippingAddressModal id={customer?.id} onSave={(v) => addNewAddress(v)} />

                <ViewHeader renderBackArrow title="Add New Order">
                    <ConfirmationModal
                        title="Are you sure you want to create the order"
                        onSubmit={onSubmit}
                    />
                    <div className="flex__ai-center">
                        <Sop id="25443" />
                     
                    </div>
                </ViewHeader>

                <Row>
                    <Col lg={8}>
                        {!!Object.keys(errors).length && (
                            <div className="alert alert-danger">
                                <ul>
                                    {Object.keys(errors).map((key) => {
                                        return <li key={key}>{errors[key]}</li>;
                                    })}
                                </ul>
                            </div>
                        )}
                        <Card>
                            <div className="px-3" style={{ zIndex: 5 }}>
                                <ApiAsyncSelect
                                    api="/product"
                                    placeholder="Type to add product..."
                                    labelProperty={(r) => {
                                        return (
                                            <div className="custom-option-with-thumbnail">
                                                <img src={r.image} />
                                                <div className="content">
                                                    <h4>{r.name}</h4>
                                                    <p>{r.vendor?.name}</p>
                                                    <p>
                                                        {r.variant_options.map(
                                                            ({ option_name, value }, index) => (
                                                                <span key={value}>
                                                                    {option_name}: {value}
                                                                    {index + 1 !==
                                                                        r.variant_options
                                                                            .length && (
                                                                        <span>, </span>
                                                                    )}
                                                                </span>
                                                            ),
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    }}
                                    onChange={(v) => addDataProduct(v)}
                                    isDisabled={!selected_business_model?.value?.id || !address?.id}
                                />
                                {loading && validation_errors.products && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.products}
                                    </p>
                                )}
                            </div>
                            <div className="table-tag-wrapper">
                                <table className="table-component-wrapper">
                                    <thead>
                                        <tr>
                                            <td>Logo</td>
                                            <td>Product</td>
                                            <td>Price</td>
                                            <td width="200">Quantity</td>
                                            <td>Subtotal</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="tr-logo">
                                                        <img
                                                            src={row.image || defaultLogo}
                                                            alt="Logo"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="tr-name">
                                                        <h5>{row.name}</h5>
                                                        <h6
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            <small>CODE: {row.id}</small>
                                                            {!!row.min_amount && (
                                                                <small className="text-danger">
                                                                    Minimum Order Amount is
                                                                    {formatMoney(row.min_amount)}
                                                                </small>
                                                            )}
                                                        </h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    {is_cbv ? (
                                                        <Input
                                                            value={
                                                                row.price_changeable || row.price
                                                            }
                                                            setValue={(value) => {
                                                                setRowProperty(
                                                                    'price_changeable',
                                                                    row.id,
                                                                    parseFloat(value),
                                                                );
                                                            }}
                                                            type="number"
                                                            style={{ width: 80 }}
                                                        />
                                                    ) : (
                                                        <div className="tr-price">
                                                            <span>SAR</span>
                                                            <div>{formatMoney(row.price)}</div>
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <Select
                                                        options={quantityOptions({
                                                            order_min_quantity:
                                                                row.order_min_quantity,
                                                            order_max_quantity:
                                                                row.order_max_quantity,
                                                            order_quantity_step:
                                                                row.order_quantity_step,
                                                        })}
                                                        value={quantityOptions({
                                                            order_min_quantity:
                                                                row.order_min_quantity,
                                                            order_max_quantity:
                                                                row.order_max_quantity,
                                                            order_quantity_step:
                                                                row.order_quantity_step,
                                                        }).find(
                                                            (option) =>
                                                                option.value == row.quantity,
                                                        )}
                                                        onChange={(val) => {
                                                            setRowProperty(
                                                                'quantity',
                                                                row.id,
                                                                val.value,
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="tr-price">
                                                        <span>SAR</span>
                                                        <div>{formatMoney(row.total)}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Button
                                                        onClick={() => {
                                                            deleteDataProduct(row.id);
                                                            fetchOrderDraft(
                                                                data.filter((p) => p.id !== row.id),
                                                            );
                                                        }}
                                                        variant="danger"
                                                        size="sm"
                                                    >
                                                        &times;
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <Receipt data={summary} total={total} loading={loading_summary} />
                        </Card>

                        <Card>
                            <div className="px-3" style={{ zIndex: 5 }}>
                                {scheduled_orders?.map((scheduled_order, i) => {
                                    return (
                                        <Radio
                                            key={i}
                                            className="custom-option-with-thumbnail"
                                            name="scheduled_order"
                                            value={scheduled_order.id}
                                            option={selected_scheduled_order?.id}
                                            label={scheduled_order.name}
                                            onChange={(val) => {
                                                set_selected_scheduled_order(
                                                    scheduled_orders.find((x) => x.id == val),
                                                );
                                                set_selected_scheduled_order_options({
                                                    scheduled_order_delivery_option_id: null,
                                                    scheduled_order_payment_option_id: null,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            <hr />
                            {selected_scheduled_order && (
                                <>
                                    <div
                                        className="px-3 flex__jc-between__ai-center__fd-row"
                                        style={{ zIndex: 5 }}
                                    >
                                        <h2>
                                            {selected_scheduled_order.name}
                                            <p>
                                                {/* {selected_scheduled_order.title_ar} | {selected_scheduled_order.title_en}  */}
                                                Order minimum amount :
                                                {selected_scheduled_order.order_minimum_amount}
                                            </p>
                                        </h2>
                                        <div
                                            className="flex__jc-between__ai-center__fd-row"
                                            style={{ gap: 10 }}
                                        >
                                            {selected_scheduled_order.allowed_payment_methods?.map(
                                                (m) => (
                                                    <PaymentMethodIcons key={m} state={m} />
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="px-3" style={{ zIndex: 5 }}>
                                        <br />
                                        <div>
                                            <h3>Select payment method :</h3>
                                            {selected_scheduled_order?.payment_options?.map(
                                                (payment_option, i) => (
                                                    <Radio
                                                        key={i}
                                                        name="payment_option"
                                                        value={payment_option.id}
                                                        option={
                                                            selected_scheduled_order_options.scheduled_order_payment_option_id
                                                        }
                                                        label={
                                                            payment_option.down_payment_percentage +
                                                            '% down payment'
                                                        }
                                                        onChange={(val) => {
                                                            set_selected_scheduled_order_options(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    scheduled_order_payment_option_id:
                                                                        +val,
                                                                }),
                                                            );
                                                        }}
                                                    />
                                                ),
                                            )}
                                        </div>

                                        <div>
                                            <h3>Select a delivery option :</h3>
                                            {selected_scheduled_order?.delivery_options?.map(
                                                (delivery_option, i) => (
                                                    <Radio
                                                        key={i}
                                                        name="delivery_option"
                                                        value={delivery_option.id}
                                                        option={
                                                            selected_scheduled_order_options.scheduled_order_delivery_option_id
                                                        }
                                                        label={delivery_option.title_en}
                                                        onChange={(val) => {
                                                            set_selected_scheduled_order_options(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    scheduled_order_delivery_option_id:
                                                                        +val,
                                                                }),
                                                            );
                                                        }}
                                                    />
                                                ),
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </Card>

                        <Card>
                            <div className="px-3" style={{ zIndex: 5 }}>
                                <h2>
                                    <span>Shipment preference :</span>
                                </h2>
                            </div>
                            <hr />
                            <div className="px-3" style={{ zIndex: 5 }}>
                                {shipment_preferences?.map((shipment_preference, i) => (
                                    <Radio
                                        key={i}
                                        name="shipment_preference"
                                        value={shipment_preference.code}
                                        option={selected_shipment_preference}
                                        label={
                                            <>
                                                <h4>
                                                    <b>{shipment_preference.title}</b>
                                                </h4>
                                                {shipment_preference.description}
                                            </>
                                        }
                                        onChange={(val) => {
                                            set_selected_shipment_preference(val);
                                        }}
                                    />
                                ))}
                            </div>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <div className="pt-3">
                            <ApiAsyncSelect
                                api="/customers"
                                placeholder="Type to select customer..."
                                labelProperty={(r) => `${r.store_name}`}
                                value={
                                    customer
                                        ? {
                                              value: customer?.id,
                                              label: `${customer?.store_name}`,
                                          }
                                        : null
                                }
                                onChange={(v) => {console.log(v);
                                    setCustomer(v);
                                }}
                            />
                            {loading && validation_errors.customer && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.customer}
                                </p>
                            )}
                        </div>
                        {customer && <CustomerInfo data={customerInfo} />}
                        {customer && addresses && (
                            <div className="pt-3">
                                <Select
                                    placeholder="Select shipping address..."
                                    value={address}
                                    options={[...addresses, { value: 'new', label: 'Add new...' }]}
                                    onChange={(v) => setAddress(v)}
                                />
                            </div>
                        )}
                        {address && <ShippingAddress shippingAddressInfo={shippingAddresses} map />}
                        {loading && validation_errors.address && (
                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                {validation_errors.address}
                            </p>
                        )}
                       <div className='mt-3'> <Select {...selected_business_model} /></div>
                        {loading && validation_errors.selected_business_model && (
                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                {validation_errors.selected_business_model}
                            </p>
                        )}
                        {is_cbv && <DatePicker {...creation_date} />}
                        <Select {...business_payment_mode
                             } options={payment_types}/>
                              {loading && validation_errors.payment_mode && (
                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                {validation_errors.payment_mode}
                            </p>
                        )}
                              {customer?.status === false &&<div className="alert alert-warning my-4">Customers with outstanding dues exceeding their credit limit cannot place new orders</div>}

                        <div className='flex justify-content-between align-items-center'>
                      <Input {...amount}/>
                      
                        <Button
                            onClick={() => {
                                dispatch(
                                    setModalStatus({
                                        modal: 'confirmation_modal',
                                        status: true,
                                    }),
                                );
                            }}
                            disabled={data.length === 0 || !customer || customer?.status===false}
                        >
                            Create
                        </Button>
                        </div>
                        {loading && validation_errors.paidAmount && (
                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                {validation_errors.paidAmount}
                            </p>
                        )}
                    </Col>
                </Row>
            </CustomPrompt>
        </div>
    );
}
