import { Modal } from '@components';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';

export default function ConfirmationModal({
    title,
    variant = 'danger',
    onSubmit,
    disabled,
    loading,
    ...props
}) {
    const dispatch = useDispatch();

    return (
        <Modal
            {...props}
            name="confirmation_modal"
            title={title}
            variant={variant}
            onSubmit={async () => {
                await onSubmit?.();
                dispatch(
                    setModalStatus({
                        modal: 'confirmation_modal',
                        status: false,
                    }),
                );
            }}
            submitBtnDisabled={disabled || loading}
        />
    );
}
