import React from 'react';
import useQuery from '@hooks/useQuery';
import { DataTable, Link } from '@components';
import { formatDate } from '@utils';

function SuborderRatingTab({ id }) {
    const { data: { data } = { data: [] }, isLoading } = useQuery(
        `/vendors/${id}/suborder-ratings`,
    );

    const columns = [
        {
            name: 'Suborder ID',
            width: '100px',
            center: true,
            cell: (row) => (
                <Link
                    href={`/orders/${row?.pretty_id.slice(0, row?.pretty_id.indexOf('-'))}/${
                        row?.pretty_id
                    }`}
                >
                    #{row?.suborder_id}
                </Link>
            ),
        },
        {
            name: 'Pretty ID',
            width: '100px',
            center: true,
            cell: (row) => (
                <Link
                    href={`/orders/${row?.pretty_id.slice(0, row?.pretty_id.indexOf('-'))}/${
                        row?.pretty_id
                    }`}
                >
                    #{row?.pretty_id}
                </Link>
            ),
        },
        {
            name: 'Overall Rating',
            center: true,
            cell: (row) => row?.overall_rating,
        },
        {
            name: 'Product Quality Rating',
            center: true,
            cell: (row) => row?.product_quality_rating,
        },
        {
            name: 'Package Rating',
            center: true,
            cell: (row) => row?.packing_quality_rating,
        },
        {
            name: 'Price Rating',
            center: true,
            cell: (row) => row?.price_quality_rating,
        },
        {
            name: 'Delivery Rating',
            center: true,
            cell: (row) => row?.delivery_rating,
        },
        {
            name: 'Retailer Comment',
            center: true,
            cell: (row) => row?.comment,
        },
        {
            name: 'Created At',
            cell: (row) => formatDate(row?.created_at),
        },
        {
            name: 'Updated At',
            cell: (row) => formatDate(row?.updated_at),
        },
    ];

    return <DataTable data={data} columns={columns} loading={isLoading} />;
}

export default SuborderRatingTab;
