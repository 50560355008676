import React from 'react';
import { Row, Col, Input, Checkbox, CustomPrompt, FileUpload, Button } from '@components';
import { useSelector } from 'react-redux';
import { POST, obj2formdata, errorsNormalizer, downloadFileFromAPI } from '@utils';
import useMutation from '@hooks/useMutation';
import { toast } from 'react-toastify';

function AccountVerificationTab({ id }) {
    const CustomersSlice = useSelector((state) => state.CustomersSlice);
    const AuthSlice = useSelector((state) => state.AuthSlice);
    const is_admin_allowed_to_send_request = AuthSlice.user.permissions.length
        ? AuthSlice.user.permissions.includes('verification_requests.create')
        : true;
    const [commercial_registration_number, set_commercial_registration_number] = React.useState({
        label: 'commercial registration number',
        value: '',
        tooltip: '10 Digits',
        setValue: (val) =>
            set_commercial_registration_number({
                ...commercial_registration_number,
                value: val,
            }),
    });
    const [customer_vat_number, set_customer_vat_number] = React.useState({
        label: 'VAT number',
        value: '',
        tooltip: '15 Digits',
        setValue: (val) =>
            set_customer_vat_number({
                ...customer_vat_number,
                value: val,
            }),
    });
    const [has_tax_number, set_has_tax_number] = React.useState({
        label: 'Has tax number',
        checked: false,
        onChange: (val) => set_has_tax_number({ ...has_tax_number, checked: val }),
    });
    const [cr_certificate, set_cr_certificate] = React.useState({
        label: 'CR Certificate',
        allowPDF: true,
        files: [],
        setFiles: (val) => set_cr_certificate({ ...cr_certificate, files: val }),
    });
    const [vat_certificate, set_vat_certificate] = React.useState({
        label: 'VAT Certificate',
        allowPDF: true,
        files: [],
        setFiles: (val) => set_vat_certificate({ ...vat_certificate, files: val }),
    });
    React.useEffect(() => {
        console.log(CustomersSlice.customer.cr_certificate);
        if (CustomersSlice.customer?.id) {
            has_tax_number.onChange(CustomersSlice.customer.has_tax_number);
            customer_vat_number.setValue(CustomersSlice.customer.customer_vat_number);
            commercial_registration_number.setValue(
                CustomersSlice.customer.commercial_registration_number,
            );
            set_current_images({
                vat_certificate: CustomersSlice.customer.vat_certificate,
                cr_certificate: CustomersSlice.customer.cr_certificate,
            });console.log(current_images);
        }
    }, [CustomersSlice.customer]);
    const [current_images, set_current_images] = React.useState({
        cr_certificate: '',
        vat_certificate: '',
    });
    console.log(current_images.cr_certificate);
    const handleVerification = () => {
        const endpoint = `/customers/${id}/verification`;
        const data = obj2formdata({
            has_tax_number: has_tax_number.checked ? '1' : '0',
            commercial_registration_number: commercial_registration_number?.value || null,
            customer_vat_number: customer_vat_number?.value || null,
        });
        cr_certificate.files.length && data.append('cr_certificate', cr_certificate.files[0]);
        vat_certificate.files.length && data.append('vat_certificate', vat_certificate.files[0]);
        return POST({
            endpoint,
            data,
        });
    };
    const { mutate, isLoading } = useMutation(handleVerification, {
        onSuccess: (res) => {
            console.log(res.data.cr_certificate) ;
            toast.success(res?.message);
            set_current_images({
                vat_certificate: res.data.vat_certificate,
                cr_certificate: res.data.cr_certificate,
            });
        },
        onError: (errors) => toast.error(errorsNormalizer(errors).join(', ')),
    });

    return (
        <div className="customer-form px-3 py-2">
            <CustomPrompt>
                <Row className="ml-1 mb-1">
                    <Checkbox {...has_tax_number} />
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Input {...commercial_registration_number} />
                    </Col>
                    <Col xs={12} md={6}>
                        <Input {...customer_vat_number} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FileUpload {...cr_certificate} />
                        {current_images.cr_certificate && (
                            <Button
                                variant="link"
                                onClick={() => {
                                    downloadFileFromAPI(
                                        current_images.cr_certificate,
                                        'cr_certificate',
                                    );
                                }}
                            >
                                download attachment
                            </Button>
                        )}
                    </Col>
                    <Col xs={12} md={6}>
                        <FileUpload {...vat_certificate} />
                        {current_images.vat_certificate && (
                            <Button
                                variant="link"
                                onClick={() => {
                                    downloadFileFromAPI(
                                        current_images.vat_certificate,
                                        'vat_certificate',
                                    );
                                }}
                            >
                                download attachment
                            </Button>
                        )}
                    </Col>
                </Row>
                {CustomersSlice.customer.state === 'not_verified' ? (
                    <>
                        {is_admin_allowed_to_send_request && (
                            <Button disabled={isLoading} onClick={() => mutate()}>
                                Send to Verification Center
                            </Button>
                        )}
                    </>
                ) : (
                    <Button disabled={isLoading} onClick={() => mutate()}>
                        Save changes
                    </Button>
                )}
            </CustomPrompt>
        </div>
    );
}

export default AccountVerificationTab;
