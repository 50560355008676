import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import AdminsListView from './AdminsListView';
import AdminsAddView from './AdminsAddView';
import AdminsUpdateView from './AdminsUpdateView';
import { Helmet } from 'react-helmet';

export default function AdminsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/admins/list">
                <Helmet>
                    <title>Admins | Disty Admin Dashboard</title>
                </Helmet>
                <AdminsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admins/add">
                <Helmet>
                    <title>Create Admin | Disty Admin Dashboard</title>
                </Helmet>
                <AdminsAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/admins/:id">
                <Helmet>
                    <title>Edit Admin | Disty Admin Dashboard</title>
                </Helmet>
                <AdminsUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
