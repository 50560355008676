import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import CollectionsListView from './CollectionsListView';
import CollectionsAddView from './CollectionsAddView';
import CollectionsUpdateView from './CollectionsUpdateView';
import CollectionsSortProductsView from './CollectionsSortProductsView';
import ManageSubCollectionsView from './SubCollections/ManageSubCollectionsView';
import { Helmet } from 'react-helmet';

export default function CollectionsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/collections/list">
                <Helmet>
                    <title>Collections | Disty Admin Dashboard</title>
                </Helmet>
                <CollectionsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/collections/add">
                <Helmet>
                    <title>Create Collection | Disty Admin Dashboard</title>
                </Helmet>
                <CollectionsAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/collections/:id">
                <Helmet>
                    <title>Edit Collection | Disty Admin Dashboard</title>
                </Helmet>
                <CollectionsUpdateView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/collections/:id/sort">
                <Helmet>
                    <title>Sort Collection products | Disty Admin Dashboard</title>
                </Helmet>
                <CollectionsSortProductsView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/collections/:id/sub-collections">
                <Helmet>
                    <title>Manage sub-collections | Disty Admin Dashboard</title>
                </Helmet>
                <ManageSubCollectionsView />
            </ProtectedRoute>
        </Switch>
    );
}
