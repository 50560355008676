import React from 'react';
import { ChoiceList, Icon, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function VendorTypes({
    value = null,
    set_value = () => {},
}) {
    const [q, set_q] = React.useState('');
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const entity_types =
		GlobalsSlice?.vendors_types?.map((x) => ({
		    label: x.label,
		    value: x.id,
		})) || [];

    const onChange = useCallback((value) => set_value(value), []);

    return (
        <>
            <TextField
                value={q}
                onChange={set_q}
                autoComplete="off"
                placeholder="Search"
                prefix={<Icon source={SearchMinor} />}
            />
            <br />
            <ChoiceList
                choices={
                    entity_types?.filter((item) =>
                        item?.label?.toLowerCase()?.includes(q?.toLowerCase())
                    ) || []
                }
                selected={value || []}
                onChange={onChange}
                allowMultiple
            />
        </>
    );
}
