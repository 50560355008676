/**
 *
 * @param {string} value text to be checked
 * @returns {boolean} isDate or not true|false
 */
const isDate = (value) => {
    /** @TODO add regex validation to check that value meet this xxxx-xx-xxTxx:xx:xx.xxxxxxxx */
    if (!value || typeof value ==='number' || value.length !== 27) return false;
    const date = new Date(value);

    return  date instanceof Date && !isNaN(date.valueOf());
};

export default isDate;