// diacritic list 
const diacritics = [
    '\u064B', // FATHATAN
    '\u064C', // DAMMATAN
    '\u064D', // KASRATAN
    '\u064E', // FATHA
    '\u064F', // DAMMA
    '\u0650', // KASRA
    '\u0651', // SHADDA
    '\u0652', // SUKUN
    '\u0653', // MADDAH ABOVE
    '\u0654', // HAMZA ABOVE
    '\u0655', // HAMZA BELOW
    '\u0670', // SUPERSCRIPT ALEF
    '\u06D6', // SMALL HIGH LIGATURE SAD WITH LAM WITH ALEF MAKSURA
    '\u06D7', // SMALL HIGH LIGATURE QAF WITH LAM WITH ALEF MAKSURA
    '\u06D8', // SMALL HIGH MEEM INITIAL FORM
    '\u06D9', // SMALL HIGH LAM ALEF
    '\u06DA', // SMALL HIGH JEEM
    '\u06DB', // SMALL HIGH THREE DOTS
    '\u06DC', // SMALL HIGH SEEN
    '\u06DF', // SMALL HIGH ROUNDED ZERO
    '\u06E0', // SMALL HIGH UPRIGHT RECTANGULAR ZERO
    '\u06E1', // SMALL HIGH DOTLESS HEAD OF KHAH
    '\u06E2', // SMALL HIGH MEEM ISOLATED FORM
    '\u06E3', // SMALL LOW SEEN
    '\u06E4', // SMALL HIGH MADDA
    '\u06E7', // SMALL HIGH YEH
    '\u06E8', // SMALL HIGH NOON
    '\u06EA', // EMPTY CENTRE LOW STOP
    '\u06EB', // EMPTY CENTRE HIGH STOP
    '\u06EC', // ROUNDED HIGH STOP WITH FILLED CENTRE
    '\u06ED' // SMALL LOW MEEM
];
export default function isArabic(userInput) {
    if (userInput === '') return true;
    const regex = /^[\u0621-\u064A\u0660-\u0669\0-9]+$/; // arabic letters

    // check if the input contains any diacritics
    const hasDiacritics = userInput.split('').some((char) => diacritics.includes(char));

    if (regex.test(userInput) || hasDiacritics) {
        return true;
    }
 
}
