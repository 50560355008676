import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import LogsListView from './LogsListView';
import { Helmet } from 'react-helmet';

export default function LogsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/logs">
                <Helmet>
                    <title>Logs | Disty Admin Dashboard</title>
                </Helmet>
                <LogsListView />
            </ProtectedRoute>   
        </Switch>
    );
}
