import React from 'react';
import { ViewHeader, Button, Row, Col, Modal, ShowErrors, ConfirmationModal,Textarea , StatusBlock } from '@components';
import { downloadFileFromAPI, POST, randomId ,confirmationMessage } from '@utils';
import { formatMoney } from '@utils';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import  { loadWireTransactionData } from '@slices/wireTransactions.slice';
import { setModalStatus } from '@slices/ui.slice';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
function WireTransactionUpdateView() {
    const { id } = useParams();
    const [created, set_created] = React.useState(null);
    const wireTransactionsSlice = useSelector((state) => state.WireTransactionsSlice);
    const wireTransaction = useSelector((state) => state.WireTransactionsSlice.wire_transaction);
    const loading=wireTransactionsSlice.wire_transaction_loading  ;
    const dispatch = useDispatch();
    const [errors, setErrors] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);

    const [comments, set_comments] = React.useState({
        label: 'rejection reason',
        value: '',
        setValue: (val) => set_comments({ ...comments, value: val }),
    });
    
    React.useEffect(() => {
        // dispatch(setWireTransaction({}));
        dispatch(loadWireTransactionData(id));
    },[id, dispatch]);

    React.useEffect(() => {
        const customerOrdersMap = {};
        if (Array.isArray(wireTransaction)){
        wireTransaction?.forEach((transaction) => {
            transaction.newWireTransactionOrderDetails.forEach((orderDetail) => {
                const customerId = orderDetail.customer_id;
                const customerName = orderDetail.customer.store_name;
                if (!customerOrdersMap[customerId]) {
                    customerOrdersMap[customerId] = {
                        customer: orderDetail.customer,
                        store_name: customerName,
                        orders: [],
                        showCustomer: false,
                    };
                }
                customerOrdersMap[customerId].orders.push({
                    ...orderDetail.order,
                    amount: orderDetail.amount,
                });
            });
        });
        setCustomers(Object.values(customerOrdersMap));}
    }, [wireTransaction]);
   

    const onApprove = async () => {
     const res = await POST({
            endpoint: `/payment/transactions/new-wire/${id}/approved`,
        });
        
        setErrors([]);
        if (res?.status_code === 201 || res?.status_code === 200) {
            toast.success(res?.message);
            set_created('created');
        } else {
            toast.error(res?.data.message || 'something went wrong');
           // setErrors(res?.data.errors);
        } 
    };

    const onPending = async (closeModal) => {
       
        const data = {
            reject_reason: comments.value,
        };
        const res = await POST({
            endpoint: `/payment/transactions/new-wire/${id}/reject`,
            data,
            headers: {
                'Idempotency-Key': randomId(),
            },
        });
       setErrors([]);
        if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('rejected_wire_transaction_reason__modal');
            set_created('created');
            toast.success(res?.message);
        } else {
            setErrors(res?.data.errors || { message: [res?.data.message] } || {});
        }
    };
   const handleCustomerToggle = (index) => {
        const newCustomers = customers.map((customer, i) =>
            i === index ? { ...customer, showCustomer: !customer.showCustomer } : customer,
        );
        setCustomers(newCustomers);
    };
    const getStatusColor = (status) => {
        switch (status) {
            case 'PENDING':
                return '#e59332';
            case 'SUCCESS':
                return '#43aa2b';
            case 'REJECTED':
                return '#cd5959';

            default:
                return '#6c757d';
        }
    };
   
    if (created) {
        return <Redirect to="/wire-transactions/list" />;
    }
    return (
        <div>
            <ViewHeader renderBackArrow title="Preview wire transaction">
           {wireTransaction?.[0]?.status  ==='PENDING' && !loading &&(
            <div className='d-flex '>
                <ConfirmationModal
                        title="Are you sure that you want to approve this wire transaction?"
                        onSubmit={onApprove}
                    />
                 
                    <Button
                    variant='success'
                            onClick={() => {
                                dispatch(
                                    setModalStatus({
                                        modal: 'confirmation_modal',
                                        status: true,
                                    }),
                                );
                            }}
                        >
                              Approve
                        </Button>
                <Button
                    variant="danger"
                    onClick={() => {
                        dispatch(
                            setModalStatus({
                                modal: 'rejected_wire_transaction_reason__modal',
                                status: true,
                            }),
                        );
                    }}
                >
                    Reject
                </Button>
            </div>
           )}
               
            </ViewHeader>
            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

            <Row className="py-4">
                <Col lg={5}>
                    <div style={{ color: '#596780' }} className='mb-4'>
                        <h2>Bank Name</h2>
                        <div
                            className="rounded border border-gray p-2 my-2"
                            style={{ background: '#f2f2f2' }}
                        >
                            {wireTransaction[0]?.bank.name}
                        </div>
                    </div>
                    <div style={{ color: '#596780' }}  className='mb-4'>
                        <h2> Transfer date </h2>
                        <div
                            className="rounded border border-gray p-2 my-2"
                            style={{ background: '#f2f2f2' }}
                        >
                            {wireTransaction[0]?.transfer_date}
                        </div>
                    </div>
                    <div style={{ color: '#596780' }}  className='mb-4'>
                        <h2> Status </h2>
                        <div
                            className="rounded border border-gray p-2 my-2"
                            style={{ background: '#f2f2f2' , color: getStatusColor(wireTransaction[0]?.status) }}
                        >
                           {wireTransaction[0]?.status} 
                        </div>
                    </div>
                  {wireTransaction[0]?.comments && (<div style={{ color: '#596780' }}  className='mb-4'>
                        <h2> Comments </h2>
                        <div
                            className="rounded border border-gray p-2 my-2"
                            style={{ background: '#f2f2f2'  }}
                        >
                           {wireTransaction[0]?.comments} 
                        </div>
                    </div>)}
                </Col>
                <Col lg={5} >
                    <div style={{ color: '#596780' }}  className='mb-4'>
                        <h2>Total Received Amount</h2>
                        <div
                            className="rounded border border-gray p-2 my-2"
                            style={{ background: '#f2f2f2' }}
                        >
                            {wireTransaction[0]?.amount}  {wireTransaction[0]?.currency.toUpperCase()}
                        </div>
                    </div>

                   {wireTransaction[0]?.bank_transaction_ref_number && (
                     <div style={{ color: '#596780' }}  className='mb-4'>
                     <h2> Bank transaction reference number</h2>
                     <div
                         className="rounded border border-gray p-2 my-2"
                         style={{ background: '#f2f2f2' }}
                     >
                        {wireTransaction[0]?.bank_transaction_ref_number } 
                     </div>
                 </div>
                   )}
                    
                    {wireTransaction[0]?.transfer_receipt && (
                        <div>
                            <Button
                                variant="link"
                                onClick={() => {
                            downloadFileFromAPI(
                                        wireTransaction[0]?.transfer_receipt,
                                        'attach_deposit',
                                    );
                                }}
                                className="mt-5"
                            >
                                download deposit
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
            {customers.map((customer, index) => (
                <div key={index} className="p-2">
                    <div
                        className="p-2 d-flex align-items-center cursor-pointer bg-white text-border rounded border border-gray "
                        onClick={() => handleCustomerToggle(index)}
                    >
                        <svg
                            style={{
                                transform: customer.showCustomer
                                    ? 'rotate(0deg)'
                                    : 'rotate(-90deg)',
                                transition: 'transform 0.3s',
                            }}
                            fill="currentColor"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                            <path d="M0-.75h24v24H0z" fill="none"></path>
                        </svg>
                        <span> {`Customer ${index + 1}`}</span>
                    </div>
                    {customer.showCustomer && (
                        <>
                            <Row>
                                <Col lg={6}>
                                    <div style={{ color: '#596780' }}>
                                        <div
                                            className="rounded border border-gray p-2 my-2"
                                            style={{ background: '#f2f2f2' }}
                                        >
                                            Customer Name: {customer.store_name}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {customer.orders && (
                                <div className="table-tag-wrapper">
                                    <table className="table-component-wrapper">
                                        <thead
                                            style={{
                                                color: '#232323',
                                                background: '#f0f0f0',
                                                fontWeight: 700,
                                            }}
                                        >
                                            <tr>
                                                <td>Order ID</td>
                                                <td>Payment type</td>
                                                <td>Payment status</td>
                                                <td>Order status</td>
                                                <td>Total amount</td>
                                                <td>Paid amount</td>
                                                <td>Received amount</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customer.orders.map((row) => (
                                                <tr key={row.id}>
                                                    <td>{row.id}</td>
                                                    <td>{row.payment_type}</td>
                                                    <td>{row.payment_status}</td>
                                                    <td> <StatusBlock state={row?.state} /></td>
                                                    <td>
                                                        <span
                                                            style={{
                                                                color: '#4356fd',
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            {formatMoney(row.total_due)}
                                                        </span> SAR
                                                      
                                                    </td>
                                                    <td>
                                                        <span
                                                            style={{
                                                                color: '#4356fd',
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            {formatMoney(row.paid_amount)}  
                                                        </span>   SAR
                                                 
                                                    </td>
                                                    <td width={200}>
                                                        <span>{formatMoney(row.amount || 0)}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    )}
                </div>
            ))}
            <Modal
                title="Please write the reason of Rejection!!"
                name="rejected_wire_transaction_reason__modal"
                // onSubmit={(closeModal) => onPending(closeModal)}
                onSubmit={(closeModal) => {
                     confirmationMessage(() =>
                        onPending(closeModal),
                          );
                }}
                variant="danger"
            >
                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
                <Textarea {...comments} />
            </Modal>
        </div>
    );
}

export default WireTransactionUpdateView;
