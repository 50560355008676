import { createSlice } from '@reduxjs/toolkit';
import { GET } from '@utils';

export const SettingsSlice = createSlice({
    name: 'settings',
    initialState: {
        ribbonSettings: {},
        ribbonSettings_loading: true,
        vendorSettings: {},
        vendorSettings_loading: true,
        generalSettings: {},
        generalSettings_loading: true,
        orderSettings: {},
        orderSettings_loading: true,
        notificationSettings: {},
        notificationSettings_loading: true,
        pushNotificationSettings: {},
        pushNotificationSettings_loading: true,
    },
    reducers: {
        setRibbonSettings(state, { payload }) {
            state.ribbonSettings = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVendorSettings(state, { payload }) {
            state.vendorSettings = payload;
        },
        setGeneralSettings(state, { payload }) {
            state.generalSettings = payload;
        },
        setOrderSettings(state, { payload }) {
            state.orderSettings = payload;
        },
        setNotificationSettings(state, { payload }) {
            state.notificationSettings = payload;
        },
        setPushNotificationSettings(state, { payload }) {
            state.pushNotificationSettings = payload;
        },
    },
});

// export actions
export const {
    setRibbonSettings,
    setLoading,
    setVendorSettings,
    setGeneralSettings,
    setOrderSettings,
    setNotificationSettings,
    setPushNotificationSettings
} = SettingsSlice.actions;

export const loadRibbonSettingsData = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'ribbonSettings_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: '/ribbons-control-settings'
    });
    dispatch(setRibbonSettings(res?.data || {}));
    dispatch(
        setLoading({
            key: 'ribbonSettings_loading',
            value: false,
        })
    );
};

export const loadVendorSettingsData = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendorSettings_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: '/vendors/vendor-settings'
    });
    dispatch(setVendorSettings(res?.data || {}));
    dispatch(
        setLoading({
            key: 'vendorSettings_loading',
            value: false,
        })
    );
    return res;
};
export const loadGeneralSettingsData = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'generalSettings_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: '/general-settings'
    });
    dispatch(setGeneralSettings(res?.data || {}));
    dispatch(
        setLoading({
            key: 'generalSettings_loading',
            value: false,
        })
    );
};

export const loadOrderSettingsData = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'orderSettings_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: '/order-settings'
    });
    dispatch(setOrderSettings(res?.data || {}));
    dispatch(
        setLoading({
            key: 'orderSettings_loading',
            value: false,
        })
    );
};

export const loadNotificationSettingsData = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'notificationSettings_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: '/notifications/notification-settings'
    });
    dispatch(setNotificationSettings(res?.data || {}));
    dispatch(
        setLoading({
            key: 'notificationSettings_loading',
            value: false,
        })
    );
};

export const loadPushNotificationSettingsData = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'pushNotificationSettings_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: '/notifications/push-notification'
    });
    dispatch(setPushNotificationSettings(res?.data || {}));
    dispatch(
        setLoading({
            key: 'pushNotificationSettings_loading',
            value: false,
        })
    );
};

export default SettingsSlice.reducer;