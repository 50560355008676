import useQuery from '@hooks/useQuery';
import { Button, DataTable, Modal, ShowErrors } from '@components';
import ProductVariantOptionForm from './ProductVariantOptionForm';
import useMutation from '@hooks/useMutation';
import { DELETE, POST, PUT } from '@utils';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { useState } from 'react';

const createVariantVariant = (payload) =>
    POST({
        endpoint: '/product-option',
        data: payload,
    });

const updateVariantOption = (payload, oldData) => {
    if (!payload.id) return;
    const oldDataValuesIds = oldData.values.map(({ id }) => id);
    const newDataValuesIds = payload.option_values.map(({ id }) => id).filter(Boolean);

    /** @todo reduce the filter into one map */
    const newOptionsValues = payload.option_values.filter(({ id }) => !id);
    const removeValues = oldDataValuesIds.filter((id) => !newDataValuesIds.includes(id));
    const updatableValues = payload.option_values.filter(({ id }) => !!id);

    return Promise.all([
        PUT({
            endpoint: `/product-option/${payload?.id}`,
            data: payload,
        }),
        newOptionsValues.length &&
            POST({
                endpoint: `/product-option/${payload?.id}/values`,
                data: {
                    option_values: newOptionsValues,
                },
            }),
        removeValues.length &&
            DELETE({
                endpoint: `/product-option/${payload?.id}/values`,
                data: {
                    option_values: removeValues,
                },
            }),
        ...(updatableValues.length
            ? updatableValues.map((value) =>
                  PUT({
                      endpoint: `/product-option/${payload?.id}/values/${value.id}`,
                      data: value,
                  }),
              )
            : []),
    ]);
};

const ProductVariantOptionsTab = () => {
    // states
    const [editErrors, setEditErrors] = useState([]);
    const [editData, setEditData] = useState(undefined);

    // vars
    const dispatch = useDispatch();
    const modalTitle = editData ? `Edit Option #${editData.id}` : 'Create new Option';
    const columns = [
        {
            name: 'ID',
            selector: 'id',
        },
        {
            name: 'Name',
            selector: 'name_en',
        },
        {
            name: 'Description',
            selector: 'description_en',
            minWidth: '130px',
        },
        {
            name: 'Values',
            cell: (item) => (
                <span>{item.values.reduce((prev, { value_en }) => `${prev} ${value_en}`, '')}</span>
            ),
        },
        {
            name: 'Actions',
            cell: (item) => (
                <Button
                    onClick={() => {
                        handleOpenModal();
                        setEditData(item);
                    }}
                >
                    Edit
                </Button>
            ),
        },
    ];

    // handlers
    const handleSubmit = (data) => {
        const isUpdate = data.id;

        if (isUpdate) return updateVariantOptionMutation.mutate(data, editData);
        return createVariantVariantMutation.mutate(data);
    };

    const handleOpenModal = () =>
        dispatch(
            setModalStatus({
                modal: 'ProductVariantOptionFormModal',
                status: true,
            }),
        );

    const handleCloseModal = () =>
        dispatch(
            setModalStatus({
                modal: 'ProductVariantOptionFormModal',
                status: false,
            }),
        );

    const onMutationSuccess = (res) => {
        const errors = [];
        if (Array.isArray(res)) {
            res.forEach((response) => {
                if (Object.keys(response || {}).length && response?.data?.success === false) {
                    errors.push(response?.data);
                }
            });
            // check if all APIs success
            // otherwise show error
        }
        if (errors.length) {
            setEditErrors(errors);
            return;
        }
        setEditData(undefined);
        handleCloseModal();
        refetch();
    };

    // Effects
    const { data, isLoading, refetch } = useQuery('/product-option');
    const createVariantVariantMutation = useMutation(createVariantVariant, {
        onSuccess: onMutationSuccess,
    });
    const updateVariantOptionMutation = useMutation(updateVariantOption, {
        onSuccess: onMutationSuccess,
    });

    const isSubmitting =
        createVariantVariantMutation.isLoading || updateVariantOptionMutation.isLoading;
    const errors = createVariantVariantMutation.error || updateVariantOptionMutation.error;

    return (
        <div>
            <div className="flex__jc-between p-4">
                <h1 className="h4">Product Variant Options</h1>
                <Button
                    onClick={() => {
                        setEditData(undefined);
                        handleOpenModal();
                    }}
                >
                    Add Option
                </Button>
            </div>
            <DataTable loading={isLoading} columns={columns} data={data?.data || []} />
            <Modal title={modalTitle} footer={false} name="ProductVariantOptionFormModal">
                <div>
                    {(errors || !!editErrors.length) && (
                        <ShowErrors
                            errors={
                                errors ||
                                editErrors.reduce(
                                    (prev, { errors }) => ({ ...prev, ...errors }),
                                    {},
                                )
                            }
                        />
                    )}
                    <ProductVariantOptionForm
                        defaultValues={editData}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default ProductVariantOptionsTab;
