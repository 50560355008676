import React from 'react';
import { Card, DataTable } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrderPayments } from '@slices/orders.slice';
import TransactionDetailsModal from './TransactionDetailsModal';
import formatMoney from '@utils/formatMoney';
import formatDate from '@utils/formatDate';
import { useParams } from 'react-router';
import { setOrderPayments } from '../../store/slices/orders.slice';

const PaymentInfo = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const AuthSlice = useSelector((state) => state.AuthSlice);
    const is_admin_allowed_to_confirm_cod = AuthSlice.user.permissions.length
        ? AuthSlice.user.permissions.includes(
            'orders.confirmCashOnDeliveryPayment'
        )
        : true;
    const [detailsRow, setDetailsRow] = React.useState(null);

    React.useEffect(() => {
        dispatch(setOrderPayments([]));
        dispatch(loadOrderPayments(id));
    }, []);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            width: '80px'
        },
        {
            name: 'Method',
            selector: 'method',
            sortable: false
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: false,
            format: (row) =>
                formatMoney(row.amount) + ' ' + row.currency.toUpperCase()
        },
        {
            name: 'Date & Time',
            selector: 'created_at',
            sortable: false,
            format: (r) => formatDate(r.created_at)
        },
        {
            name: 'Type',
            selector: 'type', // TODO status is not present in the API so I added type
            sortable: false
        },
        {
            name: 'Status',
            selector: 'status', // TODO status is not present in the API so I added type
            sortable: false,
            cell: (row) => {
                const status_message = row.statuses.length
                    ? row.statuses[row.statuses.length - 1]?.status
                    : '-';
                return status_message;
            }
        },
        {
            name: 'Details',
            sortable: false,
            cell: (row) => (
                <div
                    className="more-details-btn"
                    onClick={() => {
                        setDetailsRow(row);
                        dispatch(
                            setModalStatus({
                                modal: 'transaction_details_modal',
                                status: true
                            })
                        );
                    }}
                >
                    Show details
                </div>
            )
        }
    ];

    return (
        <>
            <Card
                title="Payment Information"
                className="payment-info"
            >
                <TransactionDetailsModal details={detailsRow} />
                <DataTable
                    data={OrdersSlice.order_payments}
                    columns={columns}
                />
                <br />
                <div className="flex__jc-end px-3 pb-2">
                    {is_admin_allowed_to_confirm_cod &&
                        OrdersSlice.order.customer_payment_choice ===
                            'cash_on_delivery' &&
                        OrdersSlice.order.payable_amount > 0 && (
                        <button
                            className="btn btn-primary"
                            onClick={() =>
                                dispatch(
                                    setModalStatus({
                                        modal: 'confirm_cod_payment_modal',
                                        status: true
                                    })
                                )
                            }
                        >
                                Confirm COD payment
                        </button>
                    )}
                    &nbsp;
                    {process.env.REACT_APP_FEATURE__ALLOW_ADMIN_TO_ADD_NEW_WIRE_TRANSACTION 
                        === 'true' && (
                        <button
                            className="btn btn-primary"
                            onClick={() =>
                                dispatch(
                                    setModalStatus({
                                        modal: 'add_new_wire_transaction_modal',
                                        status: true
                                    })
                                )
                            }
                        >
                            Add New Wire Transaction
                        </button>
                    )}
                </div>
            </Card>
        </>
    );
};

export default PaymentInfo;
