import React from 'react';
import formatMoney from '@utils/formatMoney';
import { Spinner } from '@components';

const Receipt = ({ data, total, payable_amount, loading , total_due }) => {
    if(loading) return (
        <div className="receipt loading">
            <Spinner animation="border" role="status" />
        </div>
    );
    return (
        <div className="receipt">
            <ul>
                {data.map((itm, i) => (
                    <li key={i}>
                        <div>
                            {itm.subject.en} <span>({itm.subject.ar})</span>
                            {itm.note && (
                                <small>{itm.note}</small>
                            )}
                        </div>

                        <div>
                            {formatMoney(itm.price)} <span>SAR</span>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="receipt__total">
                <div>
                    Total <span>(الاجمالى)</span>
                </div>
                <b>
                    {formatMoney(total)} <span>SAR</span>
                </b>
            </div>
            {!isNaN(total_due) && (
                <div className="receipt__total_due">
                    <div>
                        Total due <span>(الإجمالي المستحق)</span>
                        <p>الإجمالي بعد الطلبات الملغيه والمعدله</p>
                        <p>Total due after deducting the cancelled & edited orders</p>
                    </div>
                    <b>
                        {formatMoney(total_due)} <span>SAR</span>
                    </b>
                </div>
            )}
            {!isNaN(payable_amount) && (
                <div className="receipt__payable_amount">
                    <div>
                    Payable amount <span>(متبقي الدفع)</span>
                    </div>
                    <b>
                        {formatMoney(payable_amount)} <span>SAR</span>
                    </b>
                </div>
            )}
        </div>
    );
};

export default Receipt;
