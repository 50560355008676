import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import ListView from './ListView';
import SingleView from './SingleView';
import { Helmet } from 'react-helmet';

export default function Main() {
    return (
        <Switch>
            <ProtectedRoute exact path="/invoices/list">
                <Helmet>
                    <title>Invoices | Disty Admin Dashboard</title>
                </Helmet>
                <ListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/invoices/:id">
                <Helmet>
                    <title>
                        Invoice | Disty Admin Dashboard
                    </title>
                </Helmet>
                <SingleView />
            </ProtectedRoute>
        </Switch>
    );
}
