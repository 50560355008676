import React from 'react';
import { DataTable, InviteUserModal, Button, WhatsApp, Dialer, StatusToggler } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import { loadCustomerData } from '@slices/customers.slice';
export default function RetailUsersTab({ id }) {
    const dispatch = useDispatch();
    const CustomersSlice = useSelector((state) => state.CustomersSlice);
    const refetch = () => {
        dispatch(loadCustomerData(id));
    };
    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: 'name',
            cell: (row) => `${row?.first_name} ${row?.last_name}`,
        },
        {
            name: 'Personal Mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Whatsapp',
            width: '70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />,
        },
        {
            name: 'Personal Email',
            cell: (row) => row?.email,
        },
        {
            name: 'Occupation',
            cell: (row) => row?.occupation,
        },
        {
            name: 'Is admin',
            cell: (row) =>
                row?.is_account_admin ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Status',
            cell: (row) => (row.status ? 'active' : 'inactive'),
        },
        {
            name: 'Actions',
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    method="patch"
                    checked={row?.status}
                    path={`/customers/retailer_users/${row.id}/${
                        row.status ? 'deactivate' : 'activate'
                    }`}
                    callback={() => {
                        refetch();
                    }}
                />
            ),
        },
    ];

    return (
        <div>
            <div className="flex__ai__center__jc-end mr-2">
                <Button
                    onClick={() =>
                        dispatch(
                            setModalStatus({
                                modal: 'invite_new_user_modal',
                                status: true,
                            }),
                        )
                    }
                >
                    Invite new user
                </Button>
            </div>
            <DataTable
                data={CustomersSlice?.customer?.users}
                columns={columns}
                loading={CustomersSlice?.customer_loading}
            />
            <InviteUserModal retailer_data={CustomersSlice?.customer} refetch={refetch} />
        </div>
    );
}
