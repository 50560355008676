import React from 'react';
import { FileUpload } from '@components';

export default function AttachmentsTab() {
    const [files, setFiles] = React.useState([]);

    return (
        <FileUpload
            files={files}
            setFiles={setFiles}
        />
    );
}
