import React from 'react';
import { GET } from '@utils';
import PieChart from './charts/PieChart';
import BarChart from './charts/BarChart';
import { Row, Col } from 'react-bootstrap';
import { Spinner, Card, ViewHeader } from '@components';
import formatMoney from '../../utils/formatMoney';
function StatsView() {
    const [vendors, set_vendors] = React.useState({
        vendorsData: {},
        loading: true,
    });
    const [customers, set_customers] = React.useState({
        customersData: {},
        loading: true,
    });
    const [categories, set_categories] = React.useState({
        categoriesData: {},
        loading: true,
    });
    const [products, set_products] = React.useState({
        productsData: {},
        loading: true,
    });
    const [cities, set_cities] = React.useState({
        citiesData: {},
        loading: true,
    });
    const [merchandise, set_merchandise] = React.useState({
        merchandiseData: {},
        loading: true,
    });
    const [cart, set_cart] = React.useState({ cartData: {}, loading: true });
    const [order, set_order] = React.useState({ orderData: {}, loading: true });
    const [payments, set_payments] = React.useState({
        paymentsData: {},
        loading: true,
    });
    const [wallets, set_wallets] = React.useState({
        walletsData: {},
        loading: true,
    });
    const getVendors = async () => {
        const res = await GET({ endpoint: '/vendors/stats' });
        set_vendors({ vendorsData: res?.data, loading: false });
    };
    const getCustomers = async () => {
        const res = await GET({ endpoint: '/customers/stats' });
        set_customers({ customersData: res?.data, loading: false });
    };
    const getCategories = async () => {
        const res = await GET({ endpoint: '/category/stats' });
        set_categories({ categoriesData: res?.data, loading: false });
    };
    const getProducts = async () => {
        const res = await GET({ endpoint: '/product/stats' });
        set_products({ productsData: res?.data, loading: false });
    };
    const getCities = async () => {
        const res = await GET({ endpoint: '/suborder/stats/cities' });
        set_cities({ citiesData: res?.data, loading: false });
    };
    const getMerchandise = async () => {
        const res = await GET({ endpoint: '/suborder/stats/gross-merchandise' });
        set_merchandise({ merchandiseData: res?.data, loading: false });
    };
    const getCart = async () => {
        const res = await GET({ endpoint: '/live-cart/stats' });
        set_cart({ cartData: res?.data, loading: false });
    };
    const getOrder = async () => {
        const res = await GET({ endpoint: '/order/stats/statuses' });
        set_order({ orderData: res?.data, loading: false });
    };
    const getPayments = async () => {
        const res = await GET({
            endpoint: '/order/stats/payments?from_date=12-11-2021',
        });
        set_payments({ paymentsData: res?.data, loading: false });
    };
    const getWallets = async () => {
        const res = await GET({ endpoint: '/payment/wallets/stats' });
        set_wallets({ walletsData: res?.data, loading: false });
    };

    React.useEffect(() => {
        getVendors();
        getCustomers();
        getCategories();
        getProducts();
        getCities();
        getMerchandise();
        getCart();
        getOrder();
        getPayments();
        getWallets();
    }, []);
    return (
        <div className="stats">
            <ViewHeader title="Stats" />
            <Row>
                {/* Vendors */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>
                            Merchants
                            <span> (total {formatMoney(vendors?.vendorsData?.total)})</span>
                        </h3>
                        <hr />
                        {vendors.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="Merchants Statistics"
                                    backgroundColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    labels={['Active Merchants', 'In Active Merchants']}
                                    dataNum={[
                                        vendors?.vendorsData?.active,
                                        vendors?.vendorsData?.in_active,
                                    ]}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Customers */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>
                            Customers
                            <span> (total {formatMoney(customers?.customersData?.total)})</span>
                        </h3>
                        <hr />
                        {customers.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="Retailers Statistics"
                                    backgroundColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    labels={['Active Retailers', 'In Active Retailers']}
                                    dataNum={[
                                        customers?.customersData?.active,
                                        customers?.customersData?.in_active,
                                    ]}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Cart & Wallet */}
                <Col sm={6} md={4}>
                    <div className="flex__fd-column__jc-streach">
                        {/* Cart */}
                        <Card>
                            <h3>Cart</h3>
                            <hr />
                            {cart.loading ? (
                                <div className="stats_spinner">
                                    <Spinner animation="grow" />
                                </div>
                            ) : (
                                <div>
                                    <h5>
                                        carts count :
                                        <span> {formatMoney(cart?.cartData?.carts_count)}</span>
                                    </h5>
                                    <h5>
                                        carts amount :
                                        <span>
                                            {formatMoney(cart?.cartData?.total_carts_amount)}
                                        </span>
                                    </h5>
                                </div>
                            )}
                        </Card>
                        {/* Wallet */}
                        <Card>
                            <h3>Wallets</h3>
                            <hr />
                            {wallets.loading ? (
                                <div className="stats_spinner">
                                    <Spinner animation="grow" />
                                </div>
                            ) : (
                                <div>
                                    <h5>
                                        liabilities :
                                        <span>
                                            {' '}
                                            {formatMoney(wallets?.walletsData?.liabilities)}
                                        </span>
                                    </h5>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
                {/* Categories */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>
                            Categories
                            <span> (total {formatMoney(categories?.categoriesData?.total)})</span>
                        </h3>
                        <hr />
                        {categories.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="Categories Statistics"
                                    backgroundColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    labels={['Active Categories', 'In Active Categories']}
                                    dataNum={[
                                        categories?.categoriesData?.active,
                                        categories?.categoriesData?.in_active,
                                    ]}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Products */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>
                            Products
                            <span> (total {formatMoney(products?.productsData?.total)})</span>
                        </h3>
                        <hr />
                        {products.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="Products Statistics"
                                    backgroundColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    labels={['Active Products', 'In Active Products']}
                                    dataNum={[
                                        products?.productsData?.active,
                                        products?.productsData?.in_active,
                                    ]}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Order */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>
                            Orders
                            <span>
                                {' '}
                                (total {formatMoney(order?.orderData?.total_orders_count)})
                            </span>
                        </h3>
                        <hr />
                        {order.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="OrdersStatistics"
                                    backgroundColor={[
                                        'rgba(255, 203, 119, 0.7)',
                                        'rgba(0, 53, 102, 0.7)',
                                        'rgba(255, 221, 210, 0.7)',
                                        'rgba(53, 162, 235, 0.7)',
                                        'rgba(229, 56, 59, 0.7)',
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 255, 119, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(255, 203, 119, 0.7)',
                                        'rgba(0, 53, 102, 0.7)',
                                        'rgba(255, 221, 210, 0.7)',
                                        'rgba(53, 162, 235, 0.7)',
                                        'rgba(229, 56, 59, 0.7)',
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 255, 119, 0.7)',
                                    ]}
                                    labels={order?.orderData?.orders_data?.map(
                                        (singleOrder) => singleOrder.state,
                                    )}
                                    dataNum={order?.orderData?.orders_data?.map(
                                        (singleOrder) => singleOrder.orders_count,
                                    )}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Cities */}
                <Col sm={12} md={6}>
                    <Card>
                        <h3>
                            cities
                            <span>
                                (total{formatMoney(cities?.citiesData?.total_suborders_count)})
                            </span>
                        </h3>
                        <hr />
                        {cities.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="bar-chart-wrapper">
                                <BarChart
                                    labels={cities?.citiesData?.suborders_data?.map(
                                        (city) => city.city_name_ar,
                                    )}
                                    dataNum={cities?.citiesData?.suborders_data?.map(
                                        (city) => city.suborders_count,
                                    )}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Gross Merchandise */}
                <Col sm={12} md={6}>
                    <Card>
                        <h3>
                            Gross Merchandise
                            <span>
                                (value{' '}
                                {formatMoney(merchandise?.merchandiseData?.gross_merchandise_value)}
                                )
                            </span>
                        </h3>
                        <hr />
                        {cities.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="bar-chart-wrapper">
                                <BarChart
                                    labels={merchandise?.merchandiseData?.suborders_data?.map(
                                        (mer) => mer.orders_state,
                                    )}
                                    dataNum={merchandise?.merchandiseData?.suborders_data?.map(
                                        (mer) => mer.suborders_total,
                                    )}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Payment status */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>Payment status</h3>
                        <hr />
                        {payments.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="Payment Status"
                                    backgroundColor={[
                                        'rgba(229, 56, 59, 0.7)',
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                        'rgba(0, 53, 102, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(229, 56, 59, 0.7)',
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                        'rgba(0, 53, 102, 0.7)',
                                    ]}
                                    labels={payments?.paymentsData?.payment_status?.map(
                                        (payment) => payment.payment_status,
                                    )}
                                    dataNum={payments?.paymentsData?.payment_status?.map(
                                        (payment) => payment.orders_count,
                                    )}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Payment ways */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>Payment Ways</h3>
                        <hr />
                        {payments.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="Payment Way"
                                    backgroundColor={[
                                        'rgba(255, 203, 119, 0.7)',
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(229, 56, 59, 0.7)',
                                        'rgba(0, 53, 102, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(255, 203, 119, 0.7)',
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(229, 56, 59, 0.7)',
                                        'rgba(0, 53, 102, 0.7)',
                                    ]}
                                    labels={payments?.paymentsData?.payment_method?.map(
                                        (payment) => payment.customer_payment_choice,
                                    )}
                                    dataNum={payments?.paymentsData?.payment_method?.map(
                                        (payment) => payment.orders_count,
                                    )}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
                {/* Payed later */}
                <Col sm={6} md={4}>
                    <Card>
                        <h3>Payed later</h3>
                        <hr />
                        {payments.loading ? (
                            <div className="stats_spinner">
                                <Spinner animation="grow" />
                            </div>
                        ) : (
                            <div className="pie-chart-wrapper">
                                <PieChart
                                    label="Payed Later"
                                    backgroundColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    borderColor={[
                                        'rgba(23, 195, 178, 0.7)',
                                        'rgba(255, 203, 119, 0.7)',
                                    ]}
                                    labels={['payed later', 'not payed later']}
                                    dataNum={[
                                        payments?.paymentsData?.is_payed_later?.is_pay_later,
                                        payments?.paymentsData?.is_payed_later?.is_not_pay_later,
                                    ]}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default StatsView;
