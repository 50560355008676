import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import OnboardingScreensList from './OnboardingScreensList';
import { Helmet } from 'react-helmet';

export default function LogsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/onboarding-screens/list">
                <Helmet>
                    <title>Onboarding Screens | Disty Admin Dashboard</title>
                </Helmet>
                <OnboardingScreensList />
            </ProtectedRoute>
        </Switch>
    );
}
