import { Button, ConfirmationModal } from '@components';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';

export default function CriticalActionButton({
    onSubmit = () => { },
    variant = 'primary',
    loading,
    disabled,
    confirm_message = 'Are you sure that you want to save the changes ?',
    children,
    className = '',
}) {
    const dispatch = useDispatch();
    return (
        <>
            <Button 
                variant={variant} 
                onClick={() => {
                    dispatch(
                        setModalStatus({
                            modal: 'confirmation_modal',
                            status: true,
                        })
                    );
                }} 
                disabled={disabled || loading}
                className={className}
            >
                {children}
            </Button>
            <ConfirmationModal
                title={confirm_message}
                onSubmit={onSubmit}
                loading={loading}
                disabled={disabled}
                variant={variant}
            />
        </>
    );
}