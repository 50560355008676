import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function OrderSources({
    value = null,
    set_value = () => {},
}) {
    const OrdersSlice = useSelector((state) => state.OrdersSlice);

    const entity_types =
		OrdersSlice?.order_static_data?.order_sources?.map((x) => ({
		    label: x.description,
		    value: x.code,
		})) || [];

    const onChange = useCallback((value) => set_value(value), []);

    return (
        <ChoiceList
            choices={
                entity_types?.filter((item) =>
                    item?.label?.toLowerCase()
                ) || []
            }
            selected={value || []}
            onChange={onChange}
            allowMultiple
        />
    );
}
