import { ProtectedRoute } from '@components';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import MergingAccounts from './MergingAccounts';

export default function index() {
    return (
        <Switch>
            <ProtectedRoute exact path="/merging-accounts/">
                <Helmet>
                    <title>Managing Accounts | Disty Admin Dashboard</title>
                </Helmet>
                <MergingAccounts />
            </ProtectedRoute>
        </Switch>
    );
}
