/* eslint-disable max-len */
import React from 'react';
import { DataTable, Button, Modal } from '@components';
import { GET, DELETE } from '@utils';
import NewVendorAddressModal from './NewVendorAddressModal';
import { setModalStatus } from '../../../../store/slices/ui.slice';
import { useDispatch } from 'react-redux';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';

export default function VendorAddressesTab({ id }) {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [delete_id, set_delete_id] = React.useState(null);
    const [address_to_be_edited, set_address_to_be_edited] = React.useState(null);
    const [submitting, set_submitting] = React.useState(false);

    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/vendors/${id}/addresses`,
        });
        set_data(res?.data || []);
        set_loading(false);
    };

    React.useEffect(() => {
        fetchData();
    }, [id]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: 'name',
        },
        {
            name: 'City/District/Area',
            selector: 'city',
            cell: (row) =>
                `${row.city?.name_ar || '-'}
                        ${
    row.district?.name_ar
        ? `/${row.district?.name_ar}`
        : '-'
}
                        ${
    row.administrative_area_level2
        ? `/${row.administrative_area_level2}`
        : '-'
}
                        `,
        },
        {
            name: 'Formatted address',
            selector: 'formatted_address',
        },
        {
            name: 'Contact',
            selector: 'contact_name',
            cell: (row) => (
                <div className="tr-name">
                    <h5>
                        {row.contact_name}
                        <small>{row.contact_mobile}</small>
                    </h5>
                </div>
            ),
        },
        {
            name: 'Location',
            cell: (row) => (
                <a
                    // eslint-disable-next-line max-len
                    href={`https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`}
                    target="_blank"
                    rel="noreferrer"
                >
					Open in Google Maps
                </a>
            ),
        },
        {
            name: 'Details',
            selector: 'details',
        },
        {
            name: 'Special mark',
            selector: 'special_mark',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            dispatch(
                                setModalStatus({
                                    modal: 'vendor_address__delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
					&nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_address_to_be_edited(row);

                            dispatch(
                                setModalStatus({
                                    modal: 'new_vendor_address_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const deleteCustomerEntity = async (closeModal) => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/vendors/${id}/addresses/${delete_id}`,
        });
        set_submitting(false);


        closeModal('vendor_address__delete_modal');

        if (res?.status_code === 201 || res?.status_code === 200) {
            fetchData();
            set_delete_id(null);
        }
    };

    return (
        <>
            <Button
                onClick={() => {
                    set_address_to_be_edited(null);
                    dispatch(
                        setModalStatus({ modal: 'new_vendor_address_modal', status: true })
                    );
                }}
            >
				Create new merchant address
            </Button>
            <DataTable data={data} columns={columns} loading={loading} />

            <NewVendorAddressModal
                id={id}
                address_to_be_edited={address_to_be_edited}
                onSave={fetchData}
            />

            <Modal
                title="Delete merchant address?"
                name="vendor_address__delete_modal"
                variant="danger"
                onSubmit={deleteCustomerEntity}
                loading={submitting}
            />
        </>
    );
}
