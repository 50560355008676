import { Comments } from '@components';
import useMutation from '@hooks/useMutation';
import useQuery from '@hooks/useQuery';
import { POST } from '@utils';
import { useParams } from 'react-router-dom';

const VerificationRecordComments = () => {
    const { id } = useParams();
    const { data: { data: comments } = { data: { data: [] } }, refetch } = useQuery(
        `/customers/verifications/${id}/comments`,
    );
    const { mutate } = useMutation(
        (data) => POST({ endpoint: `/customers/verifications/${id}/comments`, data }),
        { onSuccess: () => refetch() },
    );

    const handleCreateComment = (comment) => mutate({ comment });

    return (
        <Comments
            title="Notes"
            noDateMessage="No notes yet!"
            textareaPlaceholder="Leave a note"
            comments={comments}
            onSubmit={handleCreateComment}
        />
    );
};

export default VerificationRecordComments;
