import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import VendorsListView from './MerchantsListView';
import VendorsAddView from './MerchantsAddView';
import { VendorsUpdateView } from './VendorsUpdateView';
import { Helmet } from 'react-helmet';

export default function VendorsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/merchants/list">
                <Helmet>
                    <title>Merchants | Disty Admin Dashboard</title>
                </Helmet>
                <VendorsListView />
            </ProtectedRoute>   
            <ProtectedRoute exact path="/merchants/add">
                <Helmet>
                    <title>Add Merchant | Disty Admin Dashboard</title>
                </Helmet>
                <VendorsAddView />
            </ProtectedRoute>    
            <ProtectedRoute exact path="/merchants/:id">
                <Helmet>
                    <title>Edit Vendor | Disty Admin Dashboard</title>
                </Helmet>
                <VendorsUpdateView />
            </ProtectedRoute>    
        </Switch>
    );
}
