import { Input } from '@components';
import React from 'react';
import { randomId } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
function DiscountRanges({ values, onChange, showErrors, validationErrors }) {
    return (
        <div>
            <h3 className="divider-title">Discount Ranges</h3>
            <div className="quantity-discounts-wrapper table-tag-wrapper mb-4">
                <table>
                    <thead>
                        <tr>
                            <td className="action-col"></td>
                            <td className="value-col">From</td>
                            <td className="value-col">To</td>
                            <td className="value-col">Percentage</td>
                            <td className="empty-col">
                                <button
                                    className="btn btn-info"
                                    type="button"
                                    onClick={() =>
                                        onChange('range_percentage', [
                                            ...values.range_percentage,
                                            {
                                                id: randomId(),
                                                min_quantity: 0,
                                                max_quantity: 0,
                                                percentage: 0,
                                            },
                                        ])
                                    }
                                >
                                    + New discount range
                                </button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {values?.range_percentage.map((d, i) => (
                            <tr key={i}>
                                <td>
                                 
                                    <button
                                        className="btn btn-secondary"
                                        type="button"
                                        onClick={() =>
                                            onChange(
                                                'range_percentage',
                                                values.range_percentage.filter(
                                                    (item) => item.id !== d.id,
                                                ),
                                            )
                                        }
                                    >
                                        <img src={deleteIcon} alt="Delete" width="16px" />
                                    </button>
                                
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        value={d.min_quantity}
                                        setValue={(val) => {
                                            onChange('range_percentage', [
                                                ...values.range_percentage.map((item) => {
                                                    if (item.id === d.id) {
                                                        item['min_quantity'] = val;
                                                    }
                                                    return item;
                                                }),
                                            ]);
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        value={d.max_quantity}
                                        setValue={(val) =>
                                            onChange('range_percentage', [
                                                ...values.range_percentage.map((item) => {
                                                    if (item.id === d.id) {
                                                        item['max_quantity'] = val;
                                                    }
                                                    return item;
                                                }),
                                            ])
                                        }
                                        // setValue={(val) => handleChange(d.id, 'max_quantity', val)}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        value={d.percentage}
                                        setValue={(val) => {
                                            onChange('range_percentage', [
                                                ...values.range_percentage.map((item) => {
                                                    if (item.id === d.id) {
                                                        item['percentage'] = val;
                                                    }
                                                    return item;
                                                }),
                                            ]);
                                        }}
                                    />
                                </td>
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showErrors && validationErrors && (
                <p style={{ color: 'red', margin: '-17px 0 15px' }}>
                    {validationErrors.range_values}
                </p>
            )}
        </div>
    );
}
export default DiscountRanges;