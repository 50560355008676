import React from 'react';
import { Link, DataTable } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrdersData } from '@slices/orders.slice';
import { formatDate, formatMoney } from '@utils';

export default function OrdersTab({ id }) {
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);

    React.useEffect(() => {
        dispatch(loadOrdersData({ customers: [id], page }));
    }, [id, page]);


    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            width: '100px',
            cell: (row) => <Link href={`/orders/${row.id}`}>#{row.id}</Link>,
        },
        {
            name: 'Payment',
            selector: 'payment_status.description',
            sortable: false,
            cell: (row) => (
                <div
                    className={`status-indicator ${
                        row.payment_status.description.toLowerCase() === 'paid' &&
                        'active-indicator'
                    }`}
                >
                    {row.payment_status.description}
                </div>
            ),
        },
        {
            name: 'State',
            selector: 'state.description',
            sortable: false,
            cell: (row) => (
                <div
                    className={`status-indicator ${
                        row.state.description.toLowerCase() === 'completed' &&
                        'active-indicator'
                    }`}
                >
                    {row.state.description}
                </div>
            ),
        },
        {
            name: 'Date',
            selector: 'created_at',
            sortable: false,
            format: (r) => formatDate(r.created_at),
        },
        {
            name: 'Amount',
            selector: 'total',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total)}</div>
                </div>
            ),
        },
    ];

    return (
        <DataTable
            data={OrdersSlice.orders}
            pages={OrdersSlice.orders_pages}
            page={page}
            handlePageChange={set_page}
            columns={columns}
            searchable={false}
            loading={OrdersSlice.orders_loading}
        />
    );
}
