import React from 'react';
import {
    Row,
    Radio,
    Col,
    ApiAsyncSelect,
    Select
} from '@components';

export default function Selector({
    vendors_apply_method,
    set_vendors_apply_method,
    selected_vendors,
    set_selected_vendors,
    selected_category,
    set_selected_category
}) {
    const categories = [
        {
            id: 'order',
            label: 'Order'
        },
        {
            id: 'release',
            label: 'Release'
        },
        {
            id: 'important',
            label: 'Important'
        },
        {
            id: 'general',
            label: 'General'
        }
    ];

    return (
        <div>
            <Row>
                <Col lg={6}>
                    <Row>
                        <Col xs={4}>Send to merchants :</Col>
                        <Col>
                            <Radio
                                onChange={() => {
                                    set_vendors_apply_method(null);
                                }}
                                option={vendors_apply_method}
                                label="All"
                                value={null}
                                name="vendor_apply_method"
                            />
                            <Radio
                                onChange={set_vendors_apply_method}
                                option={vendors_apply_method}
                                label="Specific"
                                value="allow"
                                name="vendor_apply_method"
                            />
                            <Radio
                                onChange={set_vendors_apply_method}
                                option={vendors_apply_method}
                                label="Except"
                                value="except"
                                name="vendor_apply_method"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {vendors_apply_method && 
								<ApiAsyncSelect
								    label="Merchants"
								    api="/vendors"
								    onlyFetchActiveRecords={false}
								    placeholder="Type to Add Merchants..."
								    labelProperty={(r) => r.company_name_ar}
								    onChange={set_selected_vendors}
								    value={selected_vendors}
								    isMulti
								/>}
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Select
                        label='Category'
                        options={categories}
                        value={selected_category}
                        getOptionValue= {(option) => option.id}
                        onChange= {(val) => set_selected_category(val)}
					 />
                </Col>
                

            </Row>
        </div>
    );
}
