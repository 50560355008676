import React from 'react';
import {
    ViewHeader,
    Card,
    Input,
    CriticalActionButton,
    Toggler,
    Row,
    Col,
    Select,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Modal,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { GET, PUT, DELETE, POST, confirmationMessage } from '@utils';
import { useParams, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadAdminData } from '@slices/admins.slice';
import { setModalStatus } from '@slices/ui.slice';
import { setAdmin } from '../../store/slices/admins.slice';

export default function AdminsUpdateView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const AdminsSlice = useSelector((state) => state.AdminsSlice);
    const [loading, set_loading] = React.useState(false);
    const [submitting, set_submitting] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [deleted, set_deleted] = React.useState(false);

    React.useEffect(() => {
        dispatch(setAdmin({}));
        dispatch(loadAdminData(id));
    }, []);

    React.useEffect(() => {
        if (AdminsSlice.admin.id) {
            status.onChange(AdminsSlice.admin.is_active);
            first_name.setValue(AdminsSlice.admin.first_name);
            last_name.setValue(AdminsSlice.admin.last_name);
            email.setValue(AdminsSlice.admin.email);
            phone.setValue(AdminsSlice.admin.mobile);
            users_group.onChange(
                AdminsSlice.admin.roles?.map((r) => ({
                    id: r.id,
                    label: r.name,
                })),
            );
        }
    }, [AdminsSlice.admin.id]);

    React.useEffect(() => {
        const fetchData = async () => {
          
            const res = await GET({
                endpoint: '/user/roles',
            });
            set_loading(false);
            set_users_group((prev) => {
                return {
                    ...prev,
                    options:
                        res?.data?.map?.((r) => ({
                            id: r.id,
                            label: r.name,
                        })) || [],
                };
            });
        };
        fetchData();
    }, []);

    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val }),
    });
    const [first_name, set_first_name] = React.useState({
        label: 'First name',
        value: '',
        setValue: (val) => set_first_name({ ...first_name, value: val }),
    });
    const [last_name, set_last_name] = React.useState({
        label: 'Last name',
        value: '',
        setValue: (val) => set_last_name({ ...last_name, value: val }),
    });
    const [email, set_email] = React.useState({
        label: 'email',
        value: '',
        setValue: (val) => set_email({ ...email, value: val }),
    });
    const [phone, set_phone] = React.useState({
        label: 'Phone',
        value: '',
        // prefix:'+966',
        setValue: (val) => set_phone({ ...phone, value: val }),
    });
    const [new_password, set_new_password] = React.useState({
        label: 'New Password',
        value: '',
        type: 'password',
        setValue: (val) => set_new_password({ ...new_password, value: val }),
    });
    const [confirm_new_password, set_confirm_new_password] = React.useState({
        label: 'Confirm new password',
        value: '',
        type: 'password',
        setValue: (val) => set_confirm_new_password({ ...confirm_new_password, value: val }),
    });
    const [users_group, set_users_group] = React.useState({
        label: 'Users group',
        options: [],
        value: [],
        getOptionValue: (option) => option.id,
        isMulti: true,
        onChange: (val) => set_users_group((prev) => ({ ...prev, value: val })),
    });
    const [password_validation_errors, set_password_validation_errors] = React.useState({});
   const password_validate=()=>{
    const newErrors = {};
    if (!new_password.value ) {
        newErrors.password = 'The password field is required.';
    } else if (new_password.value.length < 8 || new_password.value.length > 16) {
        newErrors.password = 'Password must be between 8 and 16 characters long.';
    } else if (
        !/[A-Z]/.test(new_password.value) ||
        !/[a-z]/.test(new_password.value) ||
        !/\d/.test(new_password.value)
    ) {
        newErrors.password =
            'Password must include at least one uppercase letter, one lowercase letter, and one digit.';
    }
    if (new_password.value !== confirm_new_password.value) {
        newErrors.confirm_password = 'The password confirmation does not match.';
    }
    set_password_validation_errors(newErrors);
    return Object.keys(newErrors).length === 0;
   };
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (
            !first_name.value ||
            first_name.value.length < 3 ||
            !/^[a-zA-Z\s]*$/.test(first_name.value)
        )
            newErrors.first_name =
                'The first name must be at least 3 characters and must be contained only letters and spaces';
        if (!last_name.value || !/^[a-zA-Z\s]*$/.test(last_name.value))
            newErrors.last_name =
                'The last name field is required and must be contained only letters and spaces.';
        if (!email.value) {
            newErrors.email = 'The email field is required.';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value)) {
            newErrors.email = 'Invalid email address.';
        } else if (/[\u0600-\u06FF\u0750-\u077F]/.test(email.value)) {
            newErrors.email = 'Email should not contain Arabic letters';
        }
        if (!phone.value || !/^5[0-9]{1}[0-9]{7}$/i.test(phone.value)) {
            newErrors.phone =
                'The mobile field is required. It should start with 5 followed by 8 digits.';
        }
      
        if (users_group.value.length === 0) newErrors.users_group = 'The roles field is required.';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    React.useEffect(() => {
        password_validate();
    }, [ confirm_new_password,new_password]);
    React.useEffect(() => {
        validate();
    }, [first_name, last_name, phone, email, users_group]);
    const submit = async () => {
       set_loading(true);
        if (validate()) {
            const res = await PUT({
                endpoint: `/user/${id}`,
                data: {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    mobile: phone.value,
                    is_active: status.checked ? 1 : 0,
                    roles: users_group.value.length ? users_group.value?.map((g) => g.id) : [],
                },
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_updated(true);
            }
        }
    };

    const deleteAdmin = async () => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/user/${id}`,
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status === 500) {
            setErrors({ message: [res?.data.message] });
        } else if (res?.status_code === 200 || res?.status_code === 201) {
            set_deleted(true);
        }
    };
    console.log(AdminsSlice.admin.first_name + " " + AdminsSlice.admin.last_name  );
    const [password_changed, set_password_changed] = React.useState(false);
    const changeAdminPassword = async () => {
        if (!password_validate()) return;
        set_submitting(true);
        const res = await POST({
            endpoint: `/user/${id}/password`,
            data: {
                password: new_password.value,
                password_confirmation: confirm_new_password.value,
            },
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status === 500) {
            setErrors({ message: [res?.data.message] });
        } else if (res?.status_code === 200 || res?.status_code === 201) {
            set_deleted(true);
        }
    };
 
    if (updated || deleted) {
        return <Redirect to="/admins/list" />;
    }

    if (AdminsSlice.admin_loading) {
        return 'loading...';
    }

    return (
        <div className="admins-form-view">
            <CustomPrompt>
                <ViewHeader
                    title={`Update admin : ${AdminsSlice.admin.first_name}`}
                    renderBackArrow
                >
                    <Sop id="30183" />
                    <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Actions"
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() =>
                                    dispatch(
                                        setModalStatus({
                                            modal: 'single_admin_view__change_password_modal',
                                            status: true,
                                        }),
                                    )
                                }
                            >
                                Change password
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                onClick={() =>
                                    dispatch(
                                        setModalStatus({
                                            modal: 'single_admin_view__delete_modal',
                                            status: true,
                                        }),
                                    )
                                }
                            >
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>

                    <CriticalActionButton
                        onSubmit={() => {
                            AdminsSlice?.admin?.is_active ? submit() : confirmationMessage(submit);
                        }}
                    >
                        Save changes
                    </CriticalActionButton>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-3">
                        <Row>
                            <Col xs={12} md={8} lg={6}>
                                <Toggler {...status} />
                                <Row>
                                    <Col xs={12}>
                                        <Input {...first_name} />
                                        {loading && validation_errors.first_name && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.first_name}
                                            </p>
                                        )}
                                    </Col>
                                    <Col xs={12}>
                                        <Input {...last_name} />
                                        {loading && validation_errors.last_name && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.last_name}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <Input {...email} />
                                {loading && validation_errors.email && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.email}
                                    </p>
                                )}
                                <Input {...phone} />
                                {loading && validation_errors.phone && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.phone}
                                    </p>
                                )}
                                <Select {...users_group} />
                                {loading && validation_errors.users_group && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.users_group}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card>

                <Modal
                    title={`Delete ${AdminsSlice.admin.first_name} ${AdminsSlice.admin.last_name}?`}
                    name="single_admin_view__delete_modal"
                    variant="danger"
                    onSubmit={(closeModal) => {
                        deleteAdmin();
                        closeModal('single_admin_view__delete_modal');
                    }}
                    loading={submitting}
                />

                <Modal
                    title={`Change password for ${AdminsSlice.admin.first_name} ${AdminsSlice.admin.last_name}?`}
                    name="single_admin_view__change_password_modal"
                    variant="warning"
                    onSubmit={(closeModal) => {
                        set_password_changed(true);
                        if (password_validate()) {
                            changeAdminPassword();
                            closeModal('single_admin_view__change_password_modal');
                        }
                    }}
                >
                    <Input {...new_password} />
                    {password_changed && password_validation_errors.password && (
                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                            {password_validation_errors.password}
                        </p>
                    )}
                    <Input {...confirm_new_password} />
                    {password_changed && password_validation_errors.confirm_password && (
                        <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                            {password_validation_errors.confirm_password}
                        </p>
                    )}
                </Modal>
            </CustomPrompt>
        </div>
    );
}
