import { Button, Input } from '@components';
import { useEffect, useState } from 'react';
import removeIcon from '@assets/icons/delete.svg';

const ProductVariantOptionForm = ({ onSubmit, defaultValues = {}, isSubmitting }) => {
    const [form, setForm] = useState({
        ...defaultValues,
        option_values: defaultValues.values || [],
    });

    const handleUpdateField = (fieldName) => (value) => {
        setForm((state) => ({
            ...state,
            [fieldName]: value,
        }));
    };

    const handleUpdateOptionValue = (val, local, index) => {
        if (!local)
            throw new Error(
                'Error: local is not passed, you should pass ar or en as second argument',
            );

        const newVal = form.option_values.map((option, optionIndex) => {
            if (optionIndex === index)
                return {
                    ...option,
                    [`value_${local}`]: val,
                };
            return option;
        });

        handleUpdateField('option_values')([...newVal]);
    };

    const handleRemoveOptionValue = (index) => {
        const newVal = form.option_values.filter((_, optionIndex) => !(optionIndex === index));

        handleUpdateField('option_values')([...newVal]);
    };

    useEffect(() => {
        if (Object.keys(defaultValues || {}).length) {
            const { values, ...rest } = defaultValues;
            setForm({ ...rest, option_values: values });
        }
    }, [defaultValues]);

    return (
        <form
            className="p-2"
            onSubmit={(event) => {
                event.preventDefault();
                onSubmit?.(form);
            }}
        >
            <div className="grid grid-cols-2 gap-4">
                <Input
                    label="Name (Arabic)"
                    name="name_ar"
                    value={form.name_ar}
                    setValue={handleUpdateField('name_ar')}
                />
                <Input
                    label="Name (English)"
                    name="name_en"
                    value={form.name_en}
                    setValue={handleUpdateField('name_en')}
                />
                <Input
                    label="Description (Arabic)"
                    name="description_ar"
                    required={false}
                    value={form.description_ar}
                    setValue={handleUpdateField('description_ar')}
                />
                <Input
                    label="Description (English)"
                    name="description_en"
                    required={false}
                    value={form.description_en}
                    setValue={handleUpdateField('description_en')}
                />
            </div>

            <div className="col">
                <div className="flex__jc-between mb-4">
                    <h2>Option Values</h2>

                    <Button
                        variant="link"
                        outlined
                        onClick={() =>
                            setForm((state) => ({
                                ...state,
                                option_values: [
                                    ...state.option_values,
                                    { value_ar: undefined, value_en: undefined },
                                ],
                            }))
                        }
                        type="button"
                    >
                        Add Value
                    </Button>
                </div>
                {form.option_values.map(({ value_ar, value_en }, index) => (
                    <div className="flex__ai-center gap-2" key={index}>
                        <div className="flex gap-1" style={{ flex: 1 }}>
                            <Input
                                label="Value(Arabic)"
                                value={value_ar}
                                setValue={(val) => handleUpdateOptionValue(val, 'ar', index)}
                                name={'option_values[][value_ar]'}
                            />
                            <Input
                                label="Value(English)"
                                value={value_en}
                                setValue={(val) => handleUpdateOptionValue(val, 'en', index)}
                                name={'option_values[][value_en]'}
                            />
                        </div>
                        <Button
                            variant="secondary"
                            size="sm"
                            style={{ height: 40 }}
                            onClick={() => handleRemoveOptionValue(index)}
                        >
                            <img style={{ width: 15 }} src={removeIcon} alt="x" />
                        </Button>
                    </div>
                ))}
            </div>
            <div className="flex__jc-end col">
                <Button disabled={isSubmitting} block type="submit">
                    Submit
                </Button>
            </div>
        </form>
    );
};

export default ProductVariantOptionForm;
