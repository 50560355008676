/* eslint-disable max-len */
import React from 'react';
import {
    ViewHeader,
    Card,
    Row,
    Col,
    DataTable,
    StatusBlock,
    Link,
    Button,
    Modal,
    Radio,
    Input,
    Sop,
    ShowErrors,
    // DatePicker,
    // DateRangePicker,
    // Select,
} from '@components';
import { formatMoney, GET, POST, formatDate, getPercentage, PUT, errorMessages } from '@utils';
import { useParams, Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
// import downloadIcon from '@assets/icons/download.svg';
import deleteIcon from '@assets/icons/delete.svg';
import UpdateChildOrder from './UpdateChildOrder';
import Recalculate from './Recalculate';
import { toast } from 'react-toastify';
import PoInvoiceComments from './PoInvoiceComments';
export default function PoInvoiceUpdateView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [partial_amount, set_partial_amount] = React.useState();
    const [invoice_data, set_invoice_data] = React.useState({});
    const [errors, set_errors] = React.useState({});
    const [loading, set_loading] = React.useState(false);
    const [state_code, set_state_code] = React.useState(null);
    const [reasons, set_reasons] = React.useState([]);
    const [reason_id, set_reason_id] = React.useState(null);
    const [submitting, set_submitting] = React.useState(false);
    const [audits, set_audits] = React.useState([]);
    const [payout_number, set_payout_number] = React.useState({
        label: 'Payout number',
        value: '',
        setValue: (val) => set_payout_number({ ...payout_number, value: val }),
    });
    const [payout_receipt, set_payout_receipt] = React.useState({
        label: 'Upload payout receipt',
        files: [],
        setFiles: (val) => set_payout_receipt({ ...payout_receipt, files: val }),
    });
    const [delete_id, set_delete_id] = React.useState(null);
    const [modal_loading, set_modal_loading] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const [deleted_purchase_order, set_deleted_purchase_order] = React.useState(null);
    const renderErrors = !!Object.keys(errors ?? {}).length && (
        <div className="alert alert-danger">
            <ul>
                {Object.keys(errors).map((key) => {
                    return <li key={key}>{errors[key]}</li>;
                })}
            </ul>
        </div>
    );

    const reasonsList = (
        <div className="reasons-list">
            {reasons?.map((r, i) => (
                <Radio
                    key={i}
                    onChange={(val) => set_reason_id(val)}
                    option={reason_id || ''}
                    label={r.description_en}
                    value={r.id.toString()}
                    name="reasons-list"
                />
            ))}
        </div>
    );

    const partialPaid = (
        <div>
            {renderErrors}
            <Input
                label="Partial amount"
                placeholder="Partial amount"
                value={partial_amount}
                setValue={(val) => set_partial_amount(val)}
                type="number"
            />
        </div>
    );

    const states_modal_data = {
        1: {
            modal_title: 'Pending Purchase Order upload by vendor',
            request_body: {
                state: 1,
            },
        },
        2: {
            modal_title: 'submitted by vendor',
            request_body: {
                state: 2,
            },
        },
        4: {
            modal_title: 'Rejected with re upload ability',
            modal_body: reasonsList,
            submit_modal_btn_disabled: !reason_id,
            request_body: {
                state: 4,
                reason_id,
            },
        },
        8: {
            modal_title: 'Rejected without re upload ability',
            modal_body: reasonsList,
            submit_modal_btn_disabled: !reason_id,
            request_body: {
                state: 8,
                reason_id,
            },
        },
        16: {
            modal_title: 'Change Purchase Order state to approved',
            request_body: {
                state: 16,
            },
            modal_body: !!Object.keys(errors).length && <ShowErrors errors={errors} />,
        },
        32: {
            modal_title: 'Change Purchase Order state to paid',
            request_body: {
                state: 32,
            },
            // modal_body: uploadPayout,
            // submit_modal_btn_disabled:
            // 	!payout_number.value || !payout_receipt.files.length > 0,
            // request_body: obj2formdata({
            //     payout_receipt: payout_receipt.files[0],
            //     payout_number: payout_number.toString(),
            //     state: 32,
            // }),
        },
        64: {
            modal_title: 'Are you sure that you want to hold the Purchase Order?',
            request_body: {
                state: 64,
            },
        },
        128: {
            modal_title: 'Are you sure that you want to cancel the Purchase Order?',
            request_body: {
                state: 128,
            },
        },
        256: {
            modal_title: 'Change Purchase Order state to partial paid',
            request_body: {
                state: 256,
                paid_amount: partial_amount,
            },
            modal_body: partialPaid,
        },
    };

    const modalData = states_modal_data[state_code];
    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/vendors/invoice-requests/${id}`,
        });
        set_loading(false);
        set_invoice_data(res?.data || []);
    };
    const fetchReasons = async () => {
        const res = await GET({
            endpoint: '/vendors/invoice-requests-reasons',
        });
        set_reasons(res.data);
    };
    const fetchAudits = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/vendors/invoice-requests/${id}/audits`,
        });
        set_loading(false);
        set_audits(res?.data?.audits || []);
    };
    React.useEffect(() => {
        fetchData();
        fetchReasons();
        fetchAudits();
    }, []);

    const changeState = async (closeModal) => {
        set_submitting(true);
        const res = await POST({
            endpoint: `/vendors/invoice-requests/${id}/state`,
            data: states_modal_data[state_code].request_body,
        });
        set_submitting(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_invoice_data(res?.data);
            closeModal('change_invoice_state_confirmation_modal');
            if (state_code === 256) set_partial_amount('');
        } else {
            set_errors(errorMessages(res));
        }
    };

    const headerTitle = (
        <div className="flex__ai-center">
            <h1>Purchase Order #{id}</h1>
            {invoice_data?.state && <StatusBlock state={invoice_data?.state} />}
        </div>
    );

    const columns = [
        {
            name: 'Order ID',
            selector: 'pretty_id',
            width: '100px',
            cell: (row) => (
                <Link target="_blank" href={`/orders/${row.pretty_id.split('-')[0]}`}>
                    #{row.pretty_id}
                </Link>
            ),
        },
        {
            name: 'Creation Date',
            selector: 'created_at',
            sortable: false,
            format: (r) => formatDate(r.created_at),
        },
        {
            name: 'Vendor ID',
            cell: (row) => (
                <Link target="_blank" href={`/vendors/${row.vendor_id}`}>
                    {row.vendor_id}
                </Link>
            ),
        },
        {
            name: 'Amount Due',
            selector: 'amount_due',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.amount_due)}</div>
                </div>
            ),
        },
        {
            name: 'Subtotal',
            selector: 'subtotal',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.subtotal)}</div>
                </div>
            ),
        },
        {
            name: 'VAT',
            selector: 'vat',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vat)}</div>
                </div>
            ),
        },
        {
            name: 'Vendor commission',
            selector: 'vendor_commission',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vendor_commission)}</div>
                </div>
            ),
        },
        {
            name: 'Delivery fees (Including Vat)',
            selector: 'delivery_fees_with_vat',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.delivery_fees_with_vat)}</div>
                </div>
            ),
        },
        {
            name: 'Vendor discount amount',
            selector: 'vendor_discount_amount',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vendor_discount_amount)}</div>
                </div>
            ),
        },
        {
            name: 'Actions',
            cell: (row) =>
                invoice_data?.updatable ? (
                    <>
                        <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                                set_delete_id(row?.suborder_id);
                                set_errors([]);
                                dispatch(
                                    setModalStatus({
                                        modal: 'purchase_orders_delete_child_order_modal',
                                        status: true,
                                    }),
                                );
                            }}
                        >
                            <img src={deleteIcon} alt="Delete" width="16px" />
                        </Button>
                    </>
                ) : (
                    'Not updatable'
                ),
        },
    ];

    if (loading) {
        return 'loading...';
    }
    if (deleted_purchase_order) {
        return <Redirect to="/purchase-orders/list" />;
    }
    if (Object.keys(invoice_data).length == 0 && !loading) {
        return 'Something went wrong, please try to reload the page!';
    }
    const deleteChildOrder = async (closeModal) => {
        set_modal_loading(true);
        const filterDeleteOrder = invoice_data?.suborders?.filter(
            (sub) => sub?.suborder_id !== delete_id,
        );
        const res = await PUT({
            endpoint: `/vendors/invoice-requests/${id}`,
            data: {
                ...(filterDeleteOrder.length && {
                    suborders: filterDeleteOrder?.map(({ suborder_id }) => suborder_id),
                }),
            },
        });
        set_modal_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            if (filterDeleteOrder.length) {
                fetchData();
                fetchAudits();
                set_updated(res?.message);
                setTimeout(() => {
                    set_updated(null);
                }, 2000);
            } else {
                toast.success('purchase order deleted successfully');
                set_deleted_purchase_order(true);
            }
            set_errors([]);
            closeModal('purchase_orders_delete_child_order_modal');
        } else {
            set_errors(errorMessages(res));
        }
    };
    return (
        <div className="invoices-update-view">
            <ViewHeader title={headerTitle} renderBackArrow>
                <div className="flex__ai-center">
                    <Sop id="25263" />
                    {invoice_data?.actions?.map((a) => {
                        if (invoice_data?.is_payout_managed && [256, 32].includes(a.code))
                            return null;

                        return (
                            <Button
                                key={a.code}
                                variant={a.code === 128 ? 'light' : 'secondary'}
                                onClick={() => {
                                    set_state_code(a.code);
                                    set_errors([]);
                                    dispatch(
                                        setModalStatus({
                                            modal: 'change_invoice_state_confirmation_modal',
                                            status: true,
                                        }),
                                    );
                                }}
                            >
                                {a.action}
                            </Button>
                        );
                    })}
                </div>
            </ViewHeader>
            <Card>
                <section>
                    {updated && <div className="alert alert-success">{updated}</div>}
                    <h3 className="divider-title">Purchase Order info</h3>
                    <div className="invoice-base-info">
                        <div className="invoice-details">
                            <h4>Purchase Order details</h4>
                            <ul>
                                <li>
                                    <p>Merchant</p>
                                    <Link href={`/merchants/${invoice_data?.vendor?.id}`}>
                                        {invoice_data?.vendor?.company_name_en}
                                    </Link>
                                </li>
                                {/*<li>
                                    <p>Is HORECA</p>
                                    {invoice_data?.is_horeca ? 'Yes' : 'No'}
                </li>*/}
                                <li>
                                    <p>Invoice number</p>
                                    <p>{invoice_data?.invoice_number}</p>
                                </li>
                                <li>
                                    <p>Subtotal</p>
                                    <p>
                                        {formatMoney(invoice_data?.subtotal)} <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>VAT</p>
                                    <p>
                                        {formatMoney(invoice_data?.vat)} <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Vendor commission</p>
                                    <p>
                                        {formatMoney(invoice_data?.vendor_commission)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Vendor discount amount</p>
                                    <p>
                                        {formatMoney(invoice_data?.vendor_discount_amount)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Delivery Fees (Including Vat)</p>
                                    <p>
                                        {formatMoney(invoice_data?.delivery_fees_with_vat)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Amount Due</p>
                                    <p>
                                        {formatMoney(invoice_data?.amount_due)} <span>SAR</span>
                                    </p>
                                </li>
                                {invoice_data?.is_invoice_financing && (
                                    <>
                                        <li>
                                            <p>
                                                {`Down Payment 
										${getPercentage(invoice_data?.amount_due, invoice_data?.down_payment_amount)}%`}
                                            </p>
                                            <p>
                                                {formatMoney(invoice_data?.down_payment_amount)}{' '}
                                                <span>SAR</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {`Second Payment 
										${getPercentage(
                                            invoice_data?.amount_due,
                                            invoice_data?.remaining_invoice_amount_after_down_payment,
                                        )}%`}
                                            </p>
                                            <p>
                                                {formatMoney(
                                                    invoice_data?.remaining_invoice_amount_after_down_payment,
                                                )}{' '}
                                                <span>SAR</span>
                                            </p>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <p>Paid amount</p>
                                    <p>
                                        {formatMoney(invoice_data?.paid_amount)} <span>SAR</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="states-timeline">
                            <h4>Purchase Order states timeline</h4>
                            <div className="timeline">
                                <ul className="timeline__list">
                                    {invoice_data?.states?.map((itm, i) => (
                                        <li key={i}>
                                            <span>
                                                {itm.actor_name} {itm.actor_type}{' '}
                                                {formatDate(itm.created_at)}
                                            </span>
                                            <h4>{itm.description}</h4>
                                            {itm.invoice_number && (
                                                <p>Purchase Order number : {itm.invoice_number}</p>
                                            )}
                                            {itm.receipt && (
                                                <p>
                                                    receipt :{' '}
                                                    <a
                                                        href={itm.receipt}
                                                        download
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Download receipt
                                                    </a>
                                                </p>
                                            )}
                                            {itm.reason && (
                                                <>
                                                    <p>
                                                        Reason (Arabic):
                                                        {itm.reason?.description_ar}
                                                    </p>
                                                    <p>
                                                        Reason (English):
                                                        {itm.reason?.description_en}
                                                    </p>
                                                </>
                                            )}
                                            {itm.notes && <p>Notes : {itm.notes}</p>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="bank-details">
                            <h4>Bank details</h4>
                            <ul>
                                <li>
                                    <p>Bank name</p>
                                    <p>{invoice_data?.vendor?.bank_account?.bank_name}</p>
                                </li>
                                <li>
                                    <p>Holder name</p>
                                    <p>{invoice_data?.vendor?.bank_account?.holder_name}</p>
                                </li>
                                <li>
                                    <p>Account number</p>
                                    <p>{invoice_data?.vendor?.bank_account?.account_number}</p>
                                </li>
                                <li>
                                    <p>IBAN</p>
                                    <p>{invoice_data?.vendor?.bank_account?.iban}</p>
                                </li>
                            </ul>
                        </div>
                        {/* updates */}
                        <div className="states-timeline order-updates">
                            <h4>Purchase Order updates</h4>
                            <div className="timeline">
                                <ul className="timeline__list">
                                    {audits?.map((audit, i) => (
                                        <li key={i}>
                                            <span>{formatDate(audit?.date)}</span>
                                            <h4>{audit?.user}</h4>
                                            <p>{audit?.message}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <h3 className="divider-title">Suborders</h3>
                    <div className="px-3 py-2">
                        <Row>
                            <Col xs={12}>
                                <DataTable data={invoice_data?.suborders || []} columns={columns} />
                            </Col>
                        </Row>
                        {invoice_data.updatable && (
                            <div className="flex__ai-center__jc-between">
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        dispatch(
                                            setModalStatus({
                                                modal: 'purchase_orders_add_child_order_modal',
                                                status: true,
                                            }),
                                        );
                                    }}
                                >
                                    Add child order(s)
                                </Button>
                                <Recalculate
                                    id={id}
                                    fetch_invoice={fetchData}
                                    fetch_audits={fetchAudits}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </Card>

            {modalData?.modal_title && state_code && (
                <Modal
                    title={modalData.modal_title}
                    name="change_invoice_state_confirmation_modal"
                    variant="danger"
                    loading={submitting}
                    onSubmit={(closeModal) => {
                        changeState(closeModal);
                    }}
                    submitBtnDisabled={modalData.submit_modal_btn_disabled}
                >
                    {modalData.modal_body && modalData.modal_body}
                </Modal>
            )}
            <Modal
                title="Delete child order?"
                name="purchase_orders_delete_child_order_modal"
                confirmBtnName="Confirm and Recalculate Purchase order"
                variant="danger"
                onSubmit={deleteChildOrder}
                loading={modal_loading}
            >
                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            </Modal>
            <UpdateChildOrder
                fetch_invoice={fetchData}
                invoice_data={invoice_data}
                fetch_audits={fetchAudits}
                set_updated={set_updated}
            />
            <PoInvoiceComments />
        </div>
    );
}
