import React from 'react';
import { Modal, Button } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import BackArrowIcon from '@assets/icons/back-arrow.svg';
import LanguageIcon from '@assets/icons/language.svg';
import { setModalStatus } from '@slices/ui.slice';

export default function OnboardingScreensLivePreviewModal() {
    const dispatch = useDispatch();
    const OnboardingScreensSlice = useSelector((state) => state.OnboardingScreensSlice);
    const active_screens = OnboardingScreensSlice?.screens_list?.filter(s => s.is_active);
    const [index, set_index] = React.useState(0);
    const [current_screen, set_current_screen] = React.useState(null);
    const [is_english, set_is_english] = React.useState(true);

    React.useEffect(() => {
        set_index(0);
    }, []);

    React.useEffect(() => {
        set_current_screen(active_screens[index]);
    }, [index]);

    const closeAndResetModal = () => {
        dispatch(
            setModalStatus({
                modal: 'onboarding_screens_live_preview_modal',
                status: true
            })
        );
        setTimeout(() => {
            set_index(0);
        }, 500);
    };

    return (
        <Modal
            name="onboarding_screens_live_preview_modal"
            title="Live preview of active onboarding screens"
            footer={false}
            onClose={closeAndResetModal}
        >
            <div className="onboarding-screens-live-preview">
                {!active_screens?.length ? (
                    <p>No onboarding screens found!</p>
                ) : (

                    <div className="phone-wrapper">
                        <div className="screen-header">
                            <button
                                className="back-btn"
                                onClick={() => set_index(prev => prev -= 1)}
                                disabled={index === 0}
                            >
                                <img src={BackArrowIcon} alt="" />
                            </button>
                            <div className="language" onClick={() => set_is_english(!is_english)}>
                                <span>{is_english ? 'العربية' : 'English'}</span>
                                <img src={LanguageIcon} alt="" />
                            </div>
                        </div>
                        <div className="screen-content">
                            <img
                                src={current_screen?.[is_english ? 'image_en' : 'image_ar']}
                                alt=""
                            />
                            <h2>{current_screen?.[is_english ? 'title_en' : 'title_ar']}</h2>
                            <p>{current_screen?.[is_english ? 'content_en' : 'content_ar']}</p>
                        </div>
                        <div className="screen-cta">
                            <Button
                                variant="primary"
                                onClick={() => set_index(prev => prev += 1)}
                                block
                                disabled={index + 1 === active_screens?.length}
                            >
                                {index + 1 === active_screens?.length ? 'Ready to go!' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}