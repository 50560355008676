import { formatStringWithUnderscores } from '@utils';

export default function ShowErrors({ errors = {}, error }) {
    const noErrors = !Object.keys(errors || {}).length;

    if (noErrors && !error) return <></>;

    return (
        <div className="alert alert-danger">
            {noErrors ? (
                <>{error}</>
            ) : (
                <ul>
                    {Object.keys(errors)
                        .sort((a, b) => {
                            // sort by key
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        })
                        .map((key) => {
                            return (
                                <li key={key}>
                                    {!!key && isNaN(key) && (
                                        <strong>{formatStringWithUnderscores(key)}</strong>
                                    )}
                                    {errors[key]?.map?.((e, i) => (
                                        <p key={i}>{e}</p>
                                    ))}
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );
}
