/**
 * Possible types are:
 * Update Vendor profile, Update product, Create new product, Create Banner,
 * Push notifications, Top list, and Discount
 *
 */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import CreateRequest from './CreateRequest';
import UpdateRequest from './UpdateRequest';

const VendorChanges = ({ data, changesType }) => {
    if (!data) return <Spinner />;
    if (changesType === 'update_profile') {
        const {  old_profile = {}, ...vendor  } = data;
        return (
            <UpdateRequest oldData={old_profile} newData={vendor} />
        );
    }

    if (changesType === 'update_product') {
        // eslint-disable-next-line no-unused-vars
        const {  old_product: { name, ...old_product } = {}, ...rest  } = data;
        return (
            <UpdateRequest oldData={{  ...old_product }} newData={rest} />
        );
    }

    if (changesType === 'product_activation') {
        const { old_product: { is_active } } = data;
        return (
            <UpdateRequest
                oldData={{ is_active }}
                newData={{ is_active: !is_active }}
            />
        );
    }

    if (changesType === 'update_bank_details') {
        const { old_account_details, ...rest } = data;
        return (
            <UpdateRequest oldData={old_account_details} newData={rest} />
        );

    }

    if (changesType?.startsWith('create') || changesType === 'top_list') {
        return <CreateRequest data={data} />;
    }

    return (
        <div>
            {JSON.stringify(data)}
        </div>
    );
};

export default VendorChanges;