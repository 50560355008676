import React from 'react';
import { DeleteBtn, EditBtn } from '@components';
import { useDispatch } from 'react-redux';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import { setModalStatus } from '@slices/ui.slice';
import {
    setScreen
} from '@slices/onboardingScreens.slice';

export default function ScreenCard({ screen, minimal = false }) {
    const dispatch = useDispatch();

    const onEdit = () => {
        dispatch(setScreen(screen));
        dispatch(
            setModalStatus({
                modal: 'onboarding_screen_modal',
                status: true,
            })
        );
    };
    const onDelete = () => {
        dispatch(setScreen(screen));
        dispatch(
            setModalStatus({
                modal: 'confirmation_modal',
                status: true,
            })
        );
    };

    return (
        <>
            <div className="screen-card">
                {!minimal && (
                    <div className="card-head">
                        <div>
                            <p>Is Active: </p>
                            <img
                                src={screen?.is_active ? checkedIcon : cancelIcon}
                                className={!screen?.is_active && 'dimmed-icon'}
                                alt=""
                            />
                        </div>
                        <div className="actions">
                            <EditBtn onClick={onEdit} />
                            <DeleteBtn onClick={onDelete} />
                        </div>

                    </div>
                )}
                <div className="screen-img">
                    <img src={screen?.image_en} alt="" />
                </div>
                <div className="content">
                    <h2>{screen?.title_en}</h2>
                    <p>{screen?.content_en}</p>
                </div>
            </div>
        </>
    );
}