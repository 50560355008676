import React from 'react';
import {
    ViewHeader,
    DataTable,
    Card,
    WhatsApp,
    Dialer,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Filters,
    Sop,
} from '@components';
import { formatDate, POST } from '@utils';
import { loadVendorsLeadsData, updateLeadInListing } from '@slices/merchant.slice';
import { useDispatch, useSelector } from 'react-redux';
import useDidMountEffect from '@hooks/useDidMountEffect';
import { toast } from 'react-toastify';

const LEADS_STATUSES = 'OPEN,ATTEMPTING_CONTACT,DISQUALIFIED,WORKING,NOT_ENGAGED,QUALIFIED'.split(
    ',',
);

export default function VendorsLeadsListView() {
    const VendorsSlice = useSelector((state) => state.VendorsSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});

    useDidMountEffect(() => {
        dispatch(loadVendorsLeadsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const updateStatus = async (id, status) => {
        const res = await POST({
            endpoint: `/vendors/vendor-leads/${id}`,
            data: {
                status: status,
                _method: 'patch',
            },
        });

        if (res?.success) {
            toast.success('Status updated successfully');
            dispatch(updateLeadInListing(res.data));
        } else {
            const errMsg = res?.data?.message || 'Error: status not updated';
            toast.error(errMsg);
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            // sortable: false,
            center: true,
            width: '100px',
        },
        {
            name: 'Company Name',
            selector: 'company_name',
            sortable: false,
            maxWidth: '300px',
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            minWidth: '130px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />,
        },
        {
            name: 'Email',
            selector: 'email',
            minWidth: '250px',
            cell: (row) => <a href={`mailto:${row?.email}`}>{row?.email}</a>,
        },
        {
            name: 'Platform',
            selector: 'platform',
            sortable: false,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => <div className="tr-status-changer">{row.status}</div>,
        },
        {
            name: 'Created at',
            selector: 'created_date',
            sortable: false,
            format: (r) => formatDate(r.created_date),
        },
        {
            name: 'Actions',
            selector: 'id',
            cell: (row) => (
                <ButtonGroup>
                    <DropdownButton
                        as={ButtonGroup}
                        title="Actions"
                        id="bg-nested-dropdown"
                        variant="light"
                    >
                        {LEADS_STATUSES.map((status, i) => (
                            <Dropdown.Item
                                key={i}
                                eventKey={i}
                                onClick={() => {
                                    updateStatus(row.id, status);
                                }}
                            >
                                {status}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </ButtonGroup>
            ),
        },
    ];

    return (
        <div className="vendors-leads-list-view">
            <ViewHeader title="Merchants leads">
                <Sop id="29883" />
            </ViewHeader>

            <Card>
                <Filters
                    filters={['leads_statuses']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    queryPlaceholder="Search by ID, Company Name, Mobile, Email..."
                    initialFiltersValues={{
                        sort_by: 'id',
                        sort_direction: 'desc',
                    }}
                    disableFiltersCaching={true}
                    sort_options={[
                        {
                            label: 'Order ID (DESC)',
                            value: 'id__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Order ID (ASC)',
                            value: 'id__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Status (DESC)',
                            value: 'status__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Status (ASC)',
                            value: 'status__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Company Name (DESC)',
                            value: 'company_name__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Company Name (ASC)',
                            value: 'company_name__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Name (DESC)',
                            value: 'name__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Name (ASC)',
                            value: 'name__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Mobile (DESC)',
                            value: 'mobile__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Mobile (ASC)',
                            value: 'mobile__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Email (DESC)',
                            value: 'email__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Email (ASC)',
                            value: 'email__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Platform (DESC)',
                            value: 'platform__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Platform (ASC)',
                            value: 'platform__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={VendorsSlice.vendors_leads}
                    pages={VendorsSlice.vendors_leads_pages}
                    page={page}
                    records={VendorsSlice.vendors_leads_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={VendorsSlice.vendors_leads_loading}
                />
            </Card>
        </div>
    );
}
