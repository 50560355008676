import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function CustomerRefundReasons({
    value = null,
    set_value = () => {},
}) {
    const CustomersSlice = useSelector((state) => state.CustomersSlice);
    const entity_types =
		CustomersSlice?.customer_refund_reasons?.map((x) => ({
		    label: x.label,
		    value: x.id,
		})) || [];

    const onChange = useCallback((value) => set_value(value), []);

    return (
        <ChoiceList
            choices={
                entity_types?.filter((item) =>
                    item?.label?.toLowerCase()
                ) || []
            }
            selected={value || []}
            onChange={onChange}
        />
    );
}
