import React from 'react';
import { Link, DataTable, ButtonGroup, Button } from '@components';
import defaultLogo from '@assets/img/default.jpg';
import { formatMoney, formatDate } from '@utils';

import useMutation from '@hooks/useMutation';
import { POST, DELETE } from '@utils/HTTP';
import { toast } from 'react-toastify';
import errorsNormalizer from '../../../../utils/errorsNormalizer';
import useQuery from '@hooks/useQuery';

export default function ProductsTab({ id }) {
    const [q, set_q] = React.useState('');
    const [page, set_page] = React.useState(1);
    const [sort_by, set_sort_by] = React.useState();
    const [selected_products, set_selected_products] = React.useState([]);
    const [sort_direction, set_sort_direction] = React.useState();
    const [clear_rows, set_clear_rows] = React.useState(false);

    const { data: { data: products, meta } = {}, refetch, isLoading: products_loading } = useQuery(
        '/product',
        {
            q,
            page,
            vendor_id: id,
            sort_by,
            sort_direction,
        },
    );

    const { isLoading, mutate } = useMutation(handleActivationDeactivation, {
        onSuccess: ({ message }) => {
            toast.success(message);
            set_selected_products([]);
            set_clear_rows(false);
            refetch();
        },
        onError: errors => toast.error(errorsNormalizer(errors).join(', ')),
    });

    const handleSorting = (rows, selector, direction) => {
        set_sort_by(selector);
        set_sort_direction(direction);
        return rows;
    };

    const handleSelectedRows = ({ selectedRows }) => {
        set_selected_products(selectedRows.map(({ id }) => id));
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
            center: true,
            sortable: false,
            cell: row => (
                <Link href={`/products/${row.base_product_id}`}>#{row.base_product_id}</Link>
            ),
        },
        {
            name: 'Image',
            selector: 'image',
            center: true,
            width: '100px',
            cell: row => (
                <Link href={`/products/${row.base_product_id}`} className="tr-logo">
                    <img src={row.image || row.variants?.[0].image || defaultLogo} alt="Logo" />
                </Link>
            ),
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
            width: '280px',
            cell: row => (
                <Link href={`/products/${row.base_product_id}`} className="tr-name">
                    <h5 style={{ margin: 5 }}>
                        <strong>{row.name}</strong>
                        <div className='flex' >
                            {row.variant_options.map(({ value, option_name }) => (
                                <div key={value}>
                                    <small>{option_name}</small>
                                    <strong>
                                        {value}
                                        {' '}
                                    </strong>
                                </div>
                            ))}
                        </div>
                    </h5>
                </Link>
            ),
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: false,
            cell: row => {
                return (
                    <div className="tr-price">
                        <span>SAR</span>
                        <div>
                            <span>{formatMoney(row.price)}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            name: 'Take rate',
            cell: row => {
                return (
                    <div className="tr-price">
                        <span>%</span>

                        <div>
                            <span>{formatMoney(row.take_rate)}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            name: 'Take rate amount',

            cell: row => {
                return (
                    <div className="tr-price">
                        <span>SAR</span>

                        <div>
                            <span>{formatMoney(row.take_rate_amount)}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            name: 'Merchant',
            selector: 'vendor.name',
            sortable: false,
            cell: row => row?.vendor?.name,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: row => {
                if (row.is_active) return 'Active';
                return 'Inactive';
            },
        },
        {
            name: 'Created At',
            selector: 'created_date',
            sortable: false,
            cell: row => formatDate(row?.variants?.[0]?.created_date),
        },
        {
            name: 'Updated At',
            selector: 'updated_date',
            sortable: false,
            cell: row => formatDate(row?.variants?.[0]?.updated_date),
        },
    ];

    return (
        <>
            {selected_products.length > 0 && (
                <div className="alert alert-secondary flex__jc-between__ai-center" role="alert">
                    {selected_products.length} Products Selected
                    <ButtonGroup>
                        <Button
                            disabled={isLoading}
                            onClick={() => mutate({ productsIds: selected_products })}
                        >
                            Activate
                        </Button>
                        <Button
                            disabled={isLoading}
                            onClick={() =>
                                mutate({
                                    productsIds: selected_products,
                                    activation: false,
                                })
                            }
                            variant="secondary"
                        >
                            Deactivate
                        </Button>
                    </ButtonGroup>
                </div>
            )}
            <DataTable
                data={products || []}
                pages={meta?.last_page || 0}
                page={page}
                selectableRows
                handleSelectedRows={handleSelectedRows}
                clearSelectedRows={clear_rows}
                sortFunction={handleSorting}
                handlePageChange={set_page}
                columns={columns}
                searchable={true}
                onSearch={set_q}
                loading={products_loading}
            />
        </>
    );
}

const handleActivationDeactivation = ({ productsIds = [], activation = true }) => {
    const data = { products_ids: productsIds };
    const endpoint = '/product/status';

    if (activation) {
        return POST({ endpoint, data });
    }

    return DELETE({ endpoint, data });
};
