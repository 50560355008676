import { useEffect, useState } from 'react';
import { setModalStatus } from '@slices/ui.slice';
import { ApiAsyncSelect, Button, Modal, Select } from '@components';
import { useDispatch } from 'react-redux';
import { GET, POST, randomId } from '@utils';
import { stringify } from 'query-string';
import { toast } from 'react-toastify';

import useMutation from '../../../hooks/useMutation';

const MODAL_NAME = 'change_invoice_days_modal';

const AddPoInvoice = ({ fetchData }) => {
    const dispatch = useDispatch();
    const [vendor, set_vendor] = useState();

    const [selected_suborders, set_selected_suborders] = useState({
      label: 'suborders',
      placeholder: 'Select suborders',
      options : [],
      value: [],
      isMulti: true,
      disabled: true,
      getOptionValue: (option) => option.id,
      onChange: (val) => set_selected_suborders((prev) => ({ ...prev, value: val })),
    });

    const loadSuborders = async () => {
      const vendor_id = vendor?.id;
      if (!vendor_id) return false;

      set_selected_suborders(prev => ({ ...prev,
        disabled: true,
        value: [],
        options: [],
      }));

      const res = await GET({
        endpoint: `/suborder/expired?${stringify(
            { vendor_id: vendor_id },
            {
                skipNull: true,
                skipEmptyString: true,
            }
        )}`,
      });

      if(res?.status_code === 200) {
        const list = (res?.data || []).map(s => ({
          id: s.id,
          label: `${s.pretty_id}(${s.id})`,
        }));

        set_selected_suborders(prev => ({
          ...prev,
          disabled: false,
          options: list,
        }));
      }
    };

    const openModal = () => 
        dispatch(
            setModalStatus({
                modal: MODAL_NAME,
                status: true
            })
        );

    const closeModal = () => {
        dispatch(
            setModalStatus({
                modal: MODAL_NAME,
                status: false
            })
        );
        set_vendor(null);
        set_selected_suborders(prev => ({
            ...prev,
            value: [],
            disabled: true,
        }));
    };

    const { mutate, error, isLoading } = useMutation(
        () =>
            POST({
                endpoint: '/suborder/reset-invoice-days',
                data: {
                    vendor_id: vendor?.id,
                    suborders: selected_suborders?.value?.map(({ id }) => id)
                },
                headers: {
                    'Idempotency-Key': randomId()
                }
            }),
        {
            onSuccess: (res) => {
              toast.success(res.message);
              closeModal();
              fetchData?.();
            }
        }
    );

    useEffect(() => {
      loadSuborders();
    }, [vendor]);

    return (
        <>
            <Button onClick={openModal}>
              Change invoice days
            </Button>
            <Modal
                name={MODAL_NAME}
                title="Change invoice days for child order (s)"
                onSubmit={() => mutate()}
                loading={isLoading}
                onClose={closeModal}
            >
                {!!Object.keys(error ?? {}).length && (
                    <div className="alert alert-danger">
                        <ul className="errors-list">
                            {Object.keys(error).map((key) => error[key]?.map((errMsg, _i) => (
                                <li key={_i}>{errMsg}</li>
                            )))}
                        </ul>
                    </div>
                )}

                <ApiAsyncSelect
                    label="Merchant"
                    api="/vendors"
                    onlyFetchActiveRecords={false}
                    placeholder="Type to Add Merchant..."
                    labelProperty={(r) => r.company_name_ar}
                    onChange={set_vendor}
                    value={vendor}
                />
                <Select {...selected_suborders} />
            </Modal>
        </>
    );
};

export default AddPoInvoice;
