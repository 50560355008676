import React from 'react';
import Modal from './Modal';
import { useSelector } from 'react-redux';
import { Button } from '@components';
import { downloadImageFromURL } from '@utils';
import ReactImageMagnify from 'react-image-magnify';
import downloadIcon from '@assets/icons/download.svg';

export default function ImagePreviewModal() {
    const image_preview_src = useSelector(
        (state) => state.UISlice.image_preview_src
    );
    const title = (
        <p>
			Image Preview
            <span style={{ fontSize: '14px', color: '#db9509' }}>
				(hover to zoom in)
            </span>
        </p>
    );

    return (
        <Modal title={title} name="image_preview__modal" confirmBtn={false}>
            <ReactImageMagnify
                {...{
                    enlargedImagePosition: 'over',
                    smallImage: {
                        alt: 'image preview',
                        isFluidWidth: true,
                        src: image_preview_src,
                    },
                    largeImage: {
                        src: image_preview_src,
                        width: 800,
                        height: 1000,
                    },
                }}
            />

            <br />
            <Button
                variant="info"
                block
                onClick={() => {
                    downloadImageFromURL(image_preview_src); 
                }}
            >
                Download
                &nbsp;
                <img width={15} src={downloadIcon} alt="download" />
            </Button>
        </Modal>
    );
}
