import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import BannersListView from './BannersListView';
import BannersAddView from './BannersAddView';
import BannersUpdateView from './BannersUpdateView';
import BannersSortView from './BannersSortView';
import { Helmet } from 'react-helmet';


export default function BannersModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/banners/list">
                <Helmet>
                    <title>Banners | Disty Admin Dashboard</title>
                </Helmet>
                <BannersListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/banners/add">
                <Helmet>
                    <title>Add Banners | Disty Admin Dashboard</title>
                </Helmet>
                <BannersAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/banners/sort">
                <Helmet>
                    <title>Sort Banners | Disty Admin Dashboard</title>
                </Helmet>
                <BannersSortView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/banners/:id">
                <Helmet>
                    <title>Edit Banner | Disty Admin Dashboard</title>
                </Helmet>
                <BannersUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
