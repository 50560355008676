import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { default as D } from 'react-data-table-component';
import { Input, Pagination, Select, OmitTableColumnsModal } from '@components';
import { useDebounce, mergeArrayOfObjects } from '@utils';
import searchIcon from '@assets/icons/search.svg';
import useOmittedColumnsCache from '../../hooks/useOmittedColumnsCache';
import { useLocation, useHistory } from 'react-router-dom';
export default function DataTable({
    data,
    columns,
    handleSelectedRows,
    searchInputPlaceholder = 'Search...',
    searchable = false,
    highlightOnHover = true,
    fixedHeader = true,
    clearSelectedRows = false,
    selectableRows = false,
    tableFilters,
    expandableRowsComponent,
    expandableRowDisabled,
    onSearch = () => {},
    records = {},
    loading,
    pages = 1,
    page: currentPage,
    records_per_page = 100,
    set_records_per_page,
    handlePageChange: onPageChange = () => {},
    disablePageHistory,
    sortFunction,
    showOmitColumnsOption = false,
    onRowClicked = () => {},
    conditionalRowStyles = [],
    rowDisabledCriteria,
}) {
    const [searchTerm, setSearchTerm] = React.useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const { getOmittedColumnsCache } = useOmittedColumnsCache();
    const initial_columns = [...columns].map((c) => ({ ...c, omit: false }));
    const [controlled_columns, set_controlled_columns] = React.useState([...initial_columns]);
    const [records_count_select, set_records_count_select] = React.useState({
        label: 'Per Page:',
        options: [
            { value: 20, label: 20 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
           // { value: 200, label: 200 },
        ],
        value: { value: records_per_page || 100, label: records_per_page || 100 },
        onChange: (val) => {
            set_records_count_select({ ...records_count_select, value: val });
            set_records_per_page(val.value);
            handlePageChange(1); 
        },
    });

    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get('page') || currentPage || '1');

    const handlePageChange = (p) => {
        if (!disablePageHistory) {
            queryParams.set('page', p);
            history.push(`?${queryParams.toString()}`);
        }
        onPageChange(p);
    };

    React.useEffect(() => {
        onPageChange(page);
    }, []);

    React.useEffect(() => {
        set_controlled_columns(
            mergeArrayOfObjects(initial_columns, getOmittedColumnsCache(), 'name'),
        );
    }, [columns]);

    React.useEffect(() => {
        setSearchTerm(debouncedSearchTerm);
        onSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    const TableSearchComponentMemo = React.useMemo(() => {
        return searchable ? (
            <div className="table-search-box" key={2}>
                <Input
                    placeholder={searchInputPlaceholder}
                    setValue={(val) => setSearchTerm(val)}
                    value={searchTerm}
                    icon={searchIcon}
                    required={false}
                />
                {searchTerm.length ? (
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setSearchTerm('')}
                    >
                        X
                    </button>
                ) : (
                    ''
                )}
            </div>
        ) : (
            ''
        );
    }, [searchTerm]);

    const paginationItems = [];
    const from = Math.max(page - 5, 1);
    const to = Math.min(page + 5, pages);

    if (from > 1) {
        paginationItems.push(
            <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                1
            </Pagination.Item>,
        );
        if (from > 2) {
            paginationItems.push(
                <Pagination.Item key={2} disabled={true}>
                    ...
                </Pagination.Item>,
            );
        }
    }

    for (let i = from; i <= to; i++) {
        paginationItems.push(
            <Pagination.Item key={i} active={i === page} onClick={() => handlePageChange(i)}>
                {i}
            </Pagination.Item>,
        );
    }

    if (to < pages) {
        if (to < pages - 1) {
            paginationItems.push(
                <Pagination.Item key={pages - 1} disabled={true}>
                    ...
                </Pagination.Item>,
            );
        }
        paginationItems.push(
            <Pagination.Item key={pages} onClick={() => handlePageChange(pages)}>
                {pages}
            </Pagination.Item>,
        );
    }

    const SubHeaderComponent = (
        <>
            {TableSearchComponentMemo}
            {tableFilters}
        </>
    );

    const TableHeadWithTooltip = ({ name }) => (
        <div className="custom-table-head">
            {name}
            <div className="table-head-tooltip">{name}</div>
        </div>
    );

    const updated_columns = useMemo(
        () =>
            [...columns].map((col) => {
                col.name = <TableHeadWithTooltip name={col?.name || ''} />;
                return col;
            }),
        [columns],
    );

    return (
        <div className={`table-component-wrapper ${loading && 'loading-indicator'}`}>
            {/* ${(data.length === 1 || data.length === 2 ) && 'last_row'} */}
            <D
                columns={updated_columns}
                data={data}
                selectableRows={selectableRows}
                Clicked
                clearSelectedRows={clearSelectedRows}
                highlightOnHover={highlightOnHover}
                fixedHeader={fixedHeader}
                style={{ maxHeight: '80vh', overflowY: 'auto' }}
                striped={true}
                onSelectedRowsChange={handleSelectedRows}
                sortFunction={sortFunction}
                subHeaderComponent={SubHeaderComponent}
                subHeader={!!tableFilters || !!searchable}
                expandableRows={!!expandableRowsComponent}
                expandableRowsComponent={expandableRowsComponent}
                expandableRowDisabled={expandableRowDisabled}
                progressPending={loading}
                onRowClicked={onRowClicked}
                conditionalRowStyles={conditionalRowStyles}
                selectableRowDisabled={rowDisabledCriteria}
            />

            <div className="flex__jc-between__ai-center">
                {!!set_records_per_page && <Select {...records_count_select} />}

                {!loading && !!data.length && pages && paginationItems?.length > 1 && (
                    <div className="table-footer">
                        {records?.total && (
                            <div className="text-right">
                                Showing {records.from} to {records.to} of {records.total} records
                            </div>
                        )}
                        <div className="flex__jc-center">
                            {paginationItems?.length > 1 && (
                                <Pagination>{paginationItems}</Pagination>
                            )}
                        </div>
                    </div>
                )}

                {showOmitColumnsOption && <OmitTableColumnsModal columns={controlled_columns} />}
            </div>
        </div>
    );
}

DataTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    handleSelectedRows: PropTypes.func,
    searchable: PropTypes.bool,
    highlightOnHover: PropTypes.bool,
    fixedHeader: PropTypes.bool,
    searchInputPlaceholder: PropTypes.string,
    selectableRows: PropTypes.bool,
    tableFilters: PropTypes.element,
    expandableRowsComponent: PropTypes.element,
};
