import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const BannersSlice = createSlice({
    name: 'banners',
    initialState: {
        banners: [],
        active_banners: [],
        banners_pages: null,
        banners_records: null,
        banner: {},
        active_banners_loading: true,
        banners_loading: true,
        banner_loading: true,
    },
    reducers: {
        setBanners(state, { payload }) {
            state.banners = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.banners.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.banners[index] = {
                    ...state.banners[index],
                    ...payload,
                };
            }
        },
        setActiveBanners(state, { payload }) {
            state.active_banners = payload;
        },
        setBanner(state, { payload }) {
            state.banner = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setBannersTotalPages(state, { payload }) {
            state.banners_pages = payload;
        },
        setBannersRecords(state, { payload }) {
            state.banners_records = payload;
        },
    },
});

// export actions
export const {
    setBanner,
    updateSingleRow,
    setBanners,
    setActiveBanners,
    setLoading,
    setBannersTotalPages,
    setBannersRecords,
} = BannersSlice.actions;

export const loadActiveBannersData = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'active_banners_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/marketing/banners?${stringify({ status: true }, {
            skipNull: true,
            skipEmptyString: true,
        })}`,
    });
    dispatch(setActiveBanners(res?.data || []));
    dispatch(
        setLoading({
            key: 'active_banners_loading',
            value: false,
        })
    );
};

export const loadBannersData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'banners_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/marketing/banners?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setBanners(res?.data || []));
    if(res?.meta){
        dispatch(setBannersTotalPages(res?.meta?.last_page || 0));
        dispatch(setBannersRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'banners_loading',
            value: false,
        })
    );
};

export const loadBannerData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'banner_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/marketing/banners/${id}`,
    });
    dispatch(setBanner(res?.data || {}));
    dispatch(
        setLoading({
            key: 'banner_loading',
            value: false,
        })
    );
};

export default BannersSlice.reducer;
