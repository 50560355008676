import useMutation from '@hooks/useMutation';
import { loadVendorData } from '@slices/merchant.slice';
import { POST } from '@utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShippingFulfillmentSettingsField from '../ShippingFulfillmentSettingsField';
import { toast } from 'react-toastify';
import { Button, Input, Checkbox, ShowErrors, Radio } from '@components';
import DeliveryCartSizes from './DeliveryCartSizes';

const DELIVERY_OPTIONS = [
    { name: 'Offer free delivery', id: 'free' },
    { name: 'Free Delivery on orders above', id: 'free_with_threshold' },
    { name: 'No free delivery', id: 'non_free' },
];

const ShippingFulfillmentSettings = ({ id }) => {
    const [threshold, setThreshold] = useState(0);
    const [deliveryOptions, setDeliveryOptions] = useState(null);
    const [deliveryFees, setDeliveryFees] = useState('default');
    const [order_delivery_fees_inside_riyadh, set_order_delivery_fees_inside_riyadh] =
        useState(null);
    const [order_delivery_fees_outside_riyadh, set_order_delivery_fees_outside_riyadh] =
        useState(null);
    const [delivery_fees_calculation_method, set_delivery_fees_calculation_method] =
        useState('flat_fees');
    const [vendor_cart_delivery_fees_configurations, set_vendor_cart_delivery_fees_configurations] =
        useState([]);

    const vendor = useSelector((state) => state.VendorsSlice.vendor);
    const dispatch = useDispatch();

    const { mutate, isLoading, error } = useMutation(
        () =>
            POST({
                endpoint: `/vendors/${id}`,
                data: {
                    _method: 'PATCH',
                    delivery_options: deliveryOptions,
                    order_delivery_fees_type: deliveryFees,
                    order_free_delivery_fees_threshold: threshold,
                    delivery_fees_calculation_method,
                    ...(delivery_fees_calculation_method === 'flat_fees'
                        ? {
                              order_delivery_fees_inside_riyadh,
                              order_delivery_fees_outside_riyadh,
                          }
                        : {
                              vendor_cart_delivery_fees_configurations,
                          }),
                },
            }),
        {
            onSuccess: (res) => {
                dispatch(loadVendorData(id));
                toast.success(res.message);
            },
            onError: (error, res) => {
                toast.error(res.message);
            },
        },
    );

    const handleDeliveryFeesChange = (next) => {
        setDeliveryFees((prev) => {
            if (prev === 'free_with_threshold') setThreshold(null);

            if (next !== prev) return next;
            return null;
        });
    };

    useEffect(() => {
        if (vendor?.id) {
            set_order_delivery_fees_inside_riyadh(vendor.order_delivery_fees_inside_riyadh);
            set_order_delivery_fees_outside_riyadh(vendor.order_delivery_fees_outside_riyadh);
            setDeliveryFees(vendor.order_delivery_fees_type?.code);
            setDeliveryFees(vendor.order_delivery_fees_type?.code);
            setThreshold(vendor.order_free_delivery_fees_threshold || null);
            setDeliveryOptions(vendor.delivery_options?.map(({ code }) => code) || []);
            set_delivery_fees_calculation_method(vendor.delivery_fees_calculation_method?.code);
            set_vendor_cart_delivery_fees_configurations(
                vendor.vendor_cart_delivery_fees_configurations,
            );
        }
    }, [vendor?.id]);

    if (!vendor?.id) return <span>Loading...</span>;

    const isButtonDisabled =
        isLoading ||
        (deliveryFees === 'free_with_threshold' && threshold < 150) ||
        (delivery_fees_calculation_method === 'cart_size' &&
            !vendor_cart_delivery_fees_configurations?.length);

    return (
        <>
            <ShowErrors errors={error} />
            <ShippingFulfillmentSettingsField
                onChange={setDeliveryOptions}
                initialOptions={deliveryOptions}
            />
            <div>
                <h2 style={{ marginBottom: 8, marginTop: 16 }}>Delivery Fees</h2>
                <div className="flex__fw-wrap gap-2">
                    {DELIVERY_OPTIONS.map(({ id, name }) => (
                        <div key={id}>
                            <Checkbox
                                label={name}
                                checked={deliveryFees === id}
                                onChange={() => handleDeliveryFeesChange(id)}
                            />
                            {id === 'free_with_threshold' && (
                                <Input
                                    min={150}
                                    prefix={<span>SAR</span>}
                                    type="number"
                                    disabled={deliveryFees !== 'free_with_threshold'}
                                    value={threshold || 0}
                                    setValue={setThreshold}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="my-4">
                <h3 className="h5">Delivery Fees Charged to retailer/ child order (Without VAT)</h3>
                <div className="my-4 flex gap-10">
                    <Radio
                        name="delivery_fees_calculation_method"
                        value="flat_fees"
                        option={delivery_fees_calculation_method}
                        label="Flat Fees"
                        onChange={(val) => set_delivery_fees_calculation_method(val)}
                        labelStyle={{ color: '#000', fontSize: 16 }}
                    />
                    <Radio
                        name="delivery_fees_calculation_method"
                        value="cart_size"
                        option={delivery_fees_calculation_method}
                        label="Based On Cart Size"
                        onChange={(val) => set_delivery_fees_calculation_method(val)}
                        labelStyle={{ color: '#000', fontSize: 16 }}
                    />
                </div>

                {delivery_fees_calculation_method === 'flat_fees' && (
                    <div className="flex">
                        <Input
                            type="number"
                            label="Inside Riyadh"
                            prefix={<span>SAR</span>}
                            value={order_delivery_fees_inside_riyadh}
                            setValue={set_order_delivery_fees_inside_riyadh}
                        />
                        <Input
                            type="number"
                            label="Outside Riyadh"
                            prefix={<span>SAR</span>}
                            value={order_delivery_fees_outside_riyadh}
                            setValue={set_order_delivery_fees_outside_riyadh}
                        />
                    </div>
                )}

                {delivery_fees_calculation_method === 'cart_size' && (
                    <DeliveryCartSizes
                        value={vendor_cart_delivery_fees_configurations}
                        onChange={set_vendor_cart_delivery_fees_configurations}
                    />
                )}
            </div>
            <Button disabled={isButtonDisabled} onClick={mutate}>
                Save
            </Button>
        </>
    );
};

export default ShippingFulfillmentSettings;
