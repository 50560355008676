import React, { useState } from 'react';
import logo from '@assets/img/logo.svg';
import { Card, Input, Button } from '@components';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { setToken, setUser } from '@slices/auth.slice';
import { loadGlobalsData } from '@slices/globals.slice';
import { POST } from '@utils';


const Login = () => {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const query = new URLSearchParams(window.location.search);
    const [error , set_error]= useState('');
    const [loading , set_loading] = useState(false);

    const login = async () => {
        // doing the actual login
        set_loading(true);
        const res = await POST({
            endpoint: '/login',
            auth: false,
            data: { email, password }
            // data: { email: 'user@Disty.sa', password: 'superadminpassword' },
        });
        set_loading(false);
        if (res?.status_code === 200) {
            set_error('');
            window.localStorage.setItem('Disty_admin_token', res?.data.token);
            dispatch(setToken(res?.data.token));
            dispatch(
                setUser({
                    name: res?.data.name,
                    email: res?.data.email,
                    permissions: res?.data.permissions
                })
            );

            // load global data that will be used across the app
            dispatch(loadGlobalsData());
            setIsLoggedIn(true);
        } else{set_error(res?.data?.message);}
    };
    if (isLoggedIn) {
        return (
            <Redirect
                to={
                    query.get('redirect') && query.get('redirect') !== '/login'
                        ? query.get('redirect')
                        : '/'
                }
            />
        );
    }

    return (
        <div className="login">
            <div className="login-bg"></div>
            <Card>
                <img src={logo} alt="Disty logo" className="logo" />
                
                <form onSubmit={(e)=> { e.preventDefault(); login();}}>
                    <Input
                        label="Email"
                        type="email"
                        value={email}
                        setValue={setEmail}
                    />
                    <Input
                        label="Password"
                        type="password"
                        value={password}
                        setValue={setPassword}
                    />
                    {error && (<div className='text-danger'>
                        <span>{error}</span>
                    </div>)}
            
                    <Button disabled={loading} type="submit">
                    Login
                    </Button>
                </form>
            </Card>
        </div>
    );
};

export default Login;
