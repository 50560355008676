import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET, POST, isNumericId, formateFiltersOptions } from '@utils';

export const OrdersSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        suborders: [],

        orders_pages: null,
        orders_records: null,
        suborders_pages: null,

        order: {},
        order_timeline: [],
        suborders_timeline: [],
        order_comments: [],
        order_finance_comments: [],
        order_payments: [],
        orders_loading: true,
        suborders_loading: true,
        order_loading: true,

        new_order_from_live_cart: null,
        order_static_data: [],
        filters_options: {
            order_states: [],
            suborder_states: [],
            order_payment_states: [],
            payment_methods: [],
            shipment_preferences: [],
            platforms: [],
            sources: [],

            states: [],
        },
    },
    reducers: {
        setOrders(state, { payload }) {
            state.orders = payload;
        },
        setSubOrders(state, { payload }) {
            state.suborders = payload;
        },
        setOrder(state, { payload }) {
            state.order = payload;
        },
        setOrderTimeline(state, { payload }) {
            state.order_timeline = payload?.length ? payload : [];
        },
        setSubordersTimeline(state, { payload }) {
            state.suborders_timeline = payload?.length ? payload : [];
        },
        setOrderComments(state, { payload }) {
            state.order_comments = payload;
        },
        addOrderComment(state, { payload }) {
            state.order_comments.push(payload);
        },
        setOrderFinanceComments(state, { payload }) {
            state.order_finance_comments = payload;
        },
        addOrderFinanceComment(state, { payload }) {
            state.order_finance_comments.push(payload);
        },
        setOrderPayments(state, { payload }) {
            state.order_payments = payload;
        },
        addOrderPayment(state, { payload }) {
            state.order_payments.push(payload);
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setOrdersTotalPages(state, { payload }) {
            state.orders_pages = payload;
        },
        setOrdersRecords(state, { payload }) {
            state.orders_records = payload;
        },
        setSubOrdersTotalPages(state, { payload }) {
            state.suborders_pages = payload;
        },
        updateShippingAddress(state, { payload }) {
            const o = state.order.shipping_address || {};
            state.order.shipping_address = { ...o, ...payload };
        },
        setOrderAsCancelled(state) {
            state.order.state = { code: 700, description: 'Cancelled' };
            state.order.sub_orders.map((i) => {
                i.state = { code: 2048, description: 'Cancelled by admin' };
            });
            state.order.can_cancel = false;
        },

        setNewOrderFromLiveCart(state, { payload }) {
            state.new_order_from_live_cart = payload;
        },
        setOrderStaticData(state, { payload }) {
            state.order_static_data = payload;
            state.filters_options = {
                suborder_states: formateFiltersOptions(payload?.suborder_states),
                order_payment_states: formateFiltersOptions(
                    payload?.order_payment_states
                ),
                payment_methods: formateFiltersOptions(payload?.payment_methods),
                order_states: formateFiltersOptions(payload?.order_states),
                shipment_preferences: formateFiltersOptions([
                    {
                        code: 'ship_separately',
                        description: 'Ship separately',
                    },
                    {
                        code: 'one_shipment',
                        description: 'One shipment',
                    },
                ]),
                platforms: formateFiltersOptions(payload?.platforms),
                sources: formateFiltersOptions(payload?.order_sources),

                // states: formateFiltersOptions([
                //     {
                //         code: 1 << 0,
                //         description: 'Pending Purchase Orders upload by merchant',
                //     },
                //     {
                //         code: 1 << 1,
                //         description: 'Submitted by merchant',
                //     },
                //     {
                //         code: 1 << 2,
                //         description: 'Rejected with re-upload ability',
                //     },
                //     {
                //         code: 1 << 3,
                //         description: 'Rejected without re-upload ability',
                //     },
                //     {
                //         code: 1 << 4,
                //         description: 'Approved',
                //     },
                //     {
                //         code: 1 << 5,
                //         description: 'Paid',
                //     },
                //     {
                //         code: 1 << 8,
                //         description: 'Partially paid',
                //     },
                //     {
                //         code: 1 << 6,
                //         description: 'Hold',
                //     },
                //     {
                //         code: 1 << 7,
                //         description: 'Canceled',
                //     },
                // ]),
            };
        },
        setOrderShipmentPreference(state, { payload }) {
            state.shipment_preferences = payload;
        },
    }
});

// export actions
export const {
    setOrder,
    setSubOrders,
    setOrderTimeline,
    setSubordersTimeline,
    setOrderComments,
    addOrderComment,
    setOrderFinanceComments,
    addOrderFinanceComment,
    setOrderPayments,
    addOrderPayment,
    setOrders,
    setLoading,
    setOrdersTotalPages,
    setOrdersRecords,
    updateShippingAddress,
    setSubOrdersTotalPages,
    setOrderAsCancelled,
    setNewOrderFromLiveCart,
    setOrderStaticData,
    setOrderShipmentPreference,
} = OrdersSlice.actions;

export const loadOrdersData =
    (params = {}) =>
        async (dispatch) => {
            dispatch(
                setLoading({
                    key: 'orders_loading',
                    value: true,
                })
            );
            const res = await GET({
                endpoint: `/order?${stringify(params, {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                })}`,
            });
            dispatch(setOrders(res?.data || []));
            dispatch(setOrdersTotalPages(res?.meta?.last_page || 0));
            dispatch(
                setOrdersRecords({
                    from: res?.meta?.from,
                    to: res?.meta?.to,
                    total: res?.meta?.total,
                })
            );
            dispatch(
                setLoading({
                    key: 'orders_loading',
                    value: false,
                })
            );
        };

export const loadSubOrdersData =
    (params = {}) =>
        async (dispatch) => {
            dispatch(
                setLoading({
                    key: 'suborders_loading',
                    value: true,
                })
            );
            const res = await GET({
                endpoint: `/suborder?${stringify(params, { 
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                })}`,
            });
            dispatch(setSubOrders(res?.data || []));
            dispatch(setSubOrdersTotalPages(res?.meta?.last_page || 0));
            dispatch(
                setLoading({
                    key: 'suborders_loading',
                    value: false,
                })
            );
        };

export const loadOrderData = (id, permissions) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'order_loading',
            value: true,
        })
    );
    const [res, timeline, comments, finance_comments] = await Promise.all([
        GET({
            endpoint: `/order/${id}`,
        }),
        GET({
            endpoint: `/order/${id}/audits`,
        }),
        GET({
            endpoint: `/order/${id}/comments`,
        }),
        ...(permissions?.canShowFinanceComments
            ? [GET({ endpoint: `/order/${id}/comments/finance` })]
            : []
        ),
    ]);
    dispatch(setOrder(res?.data || {}));
    dispatch(setSubordersTimeline(timeline?.data?.suborders || []));
    dispatch(setOrderTimeline(timeline?.data?.audits || []));
    dispatch(setOrderComments(comments?.data || []));
    dispatch(setOrderFinanceComments(finance_comments?.data || []));
    dispatch(
        setLoading({
            key: 'order_loading',
            value: false,
        })
    );
};

export const publishOrderComment = (id, comment, uuid) => async (dispatch) => {
    const res = await POST({
        endpoint: `/order/${id}/comments`,
        data: {
            comment,
        },
        headers: {
            'Idempotency-Key': uuid,
        },
    });
    if (res?.status_code === 200) {
        dispatch(addOrderComment(res?.data));
    }
};

export const publishOrderFinanceComment = (id, comment, type_id, uuid) => async (dispatch) => {
    const res = await POST({
        endpoint: `/order/${id}/comments/finance`,
        data: {
            comment,
            type_id,
        },
        headers: {
            'Idempotency-Key': uuid,
        },
    });
    if (res?.status_code === 200) {
        dispatch(addOrderFinanceComment(res?.data));
    }
    return res;
};

export const loadOrderPayments = (id) => async (dispatch) => {
    if (!isNumericId(id)) return;

    const res = await GET({
        endpoint: `/payment/orders/${id}/transactions`,
    });
    dispatch(setOrderPayments(res?.data || []));
};

export const loadOrderStaticData = () =>
    async (dispatch) => {
        const res = await GET({
            endpoint: '/order/static-data'
        });
        dispatch(setOrderStaticData(res?.data || []));
    };

export const loadOrderShippingPreferencesData = () =>
    async (dispatch) => {
        const res = await GET({
            endpoint: '/order/shipment-preferences'
        });

        dispatch(setOrderShipmentPreference(res?.data || []));
    };

export default OrdersSlice.reducer;
