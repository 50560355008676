import React from 'react';
import { ChoiceList, Icon, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useCallback } from 'react';

function VendorRequestTypeFilter({
    value = null,
    set_value = () => {},
}) {
    const [q, set_q] = React.useState('');
    const onChange = useCallback(
        (value) => set_value(value),
        [],
    );
    const options = [
        { label: 'Update Profile', value: 'update_profile' },
        { label: 'Update Bank Details', value: 'update_bank_details' },
        // { label: 'Create Product', value: 'create_product' },
        { label: 'Create Banner', value: 'create_banner' },
        // { label: 'Update Product', value: 'update_product' },
        // { label: 'Product Activation', value: 'product_activation' },
        { label: 'Create Notification', value: 'create_notification' },
        { label: 'Top List', value: 'top_list' },
        { label: 'Create Discount', value: 'create_discount' },
    ];
    return (
        <>
            <TextField
                value={q}
                onChange={set_q}
                autoComplete="off"
                placeholder='Search'
                prefix={<Icon source={SearchMinor} />}
            />
            <br />
            <ChoiceList
                choices={options.filter((item) =>
                    item?.label?.toLowerCase()?.includes(q?.toLowerCase())
                ) || []}
                selected={value || []}
                onChange={onChange}
                allowMultiple
            />

        </>
    );
}

export default VendorRequestTypeFilter;