/**
 * @typedef {{
 *  id: number,
 *  active: boolean;
 *  name_ar: string;
 *  name_en: string;
 *  description_ar: string;
 *  description_en: string;
 *  values: { id: number; active: boolean; value_ar: string; value_en: string; }[]
 * }[]} Options
 */

/**
 * @param {Options} options
 * @returns
 */
export default function generateVariantsByOptions(options) {
    const activeOptionsValues = options
        .map(({ values }) => values.filter(({ active }) => active))
        .filter(optionValues=> optionValues.length);

    if (!activeOptionsValues.length) return [];

    const variants = mergeOptions(activeOptionsValues);

    return variants.map((variant) => {
        const name = variant.reduce(
            (prev, { value_en }) => (prev = prev ? `${prev} /  ${value_en}` : value_en),
            '',
        );

        return { name, options: variant };
    });
}

function mergeOptions(options) {
    return options.reduce(
        (accumulator, currentValue) =>
            accumulator
                .map((x) => currentValue.map((y) => x.concat(y)))
                .reduce((nestedAcc, nestedCurr) => nestedAcc.concat(nestedCurr), []),
        [[]],
    );
}
