import { useEffect } from 'react';
import { useParams } from 'react-router';
import { ViewHeader, StatusBlock, Button } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import dollarIcon from '@assets/icons/dollar.svg';
import {
    loadPayoutData,
    loadPayoutReceipts,
    loadPayoutSuborders,
    loadPayoutAudits,
} from '@slices/payouts.slice';
import { ACTIONABLE_TABS } from '../MerchantPayouts';
import PayPayout from '../Modals/PayPayout';
import {
    PayoutSummary,
    PayoutTimeline,
    PayoutReceipts,
    BankDetails,
    PayoutComments,
    ChildOrders,
} from './components';

const PayoutDetails = () => {
    const PayoutsSlice = useSelector((state) => state.PayoutsSlice);
    const dispatch = useDispatch();
    const { id } = useParams();

    const payout = PayoutsSlice.payout || {};
    const loading = PayoutsSlice.payout_loading;
    const errors = PayoutsSlice.payout_errors;

    const fetchPayout = () => dispatch(loadPayoutData(id));

    const reloadAllData = () => {
        fetchPayout();
        dispatch(loadPayoutReceipts(id));
        dispatch(loadPayoutAudits(id));
        dispatch(loadPayoutSuborders(id, { records_per_page: 1000000 }));
    };

    useEffect(fetchPayout, []);

    return (
        <div>
            <ViewHeader
                title={
                    <div className="flex__ai-center">
                        <h1>{`Payout #${id}`}</h1>
                        {payout.state && <StatusBlock state={payout.state} />}
                    </div>
                }
                renderBackArrow
            >
                <div className="flex__ai-center">
                    <Button
                        onClick={() =>
                            dispatch(
                                setModalStatus({
                                    modal: 'pay_payout_modal',
                                    status: true,
                                    payload: payout,
                                }),
                            )
                        }
                        variant="info"
                        disabled={!ACTIONABLE_TABS.includes(payout.state?.code)}
                    >
                        <img src={dollarIcon} style={{ marginRight: 5, width: 20 }} />
                        Pay
                    </Button>
                </div>
            </ViewHeader>

            <section className="row">
                <section className="col-lg-6">
                    <PayoutSummary payout={payout} loading={loading} errors={errors} />
                    <BankDetails
                        bank_account={payout.vendor?.bank_account || {}}
                        loading={loading}
                        errors={errors}
                    />
                </section>
                <section className="col-lg-6 flex__fd-column">
                    <PayoutReceipts payout_id={id} />
                    <PayoutTimeline payout_id={id} />
                    <PayoutComments payout_id={id} />
                </section>
            </section>

            <section className="row">
                <section className="col-lg-12">
                    <ChildOrders payout_id={id} vendor={payout.vendor} />
                </section>
            </section>

            <PayPayout onSuccess={reloadAllData} />
        </div>
    );
};

export default PayoutDetails;
