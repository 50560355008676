import { useState, useEffect } from 'react';
import { Input } from '@components';

const INIT_SIZES = {
    min_purchased_amount: 0,
    delivery_fees_inside_riyadh: 0,
    delivery_fees_outside_riyadh: 0,
};

const DeliveryCartSizes = ({ onChange, value }) => {
    const [cart_sizes, set_cart_sizes] = useState(value?.length ? value : [INIT_SIZES]);
    const [errors, set_errors] = useState({});

    const addSize = () => {
        const last_amount = cart_sizes[cart_sizes.length - 1]?.min_purchased_amount || 0;
        set_cart_sizes((prev) => [
            ...prev,
            {
                ...INIT_SIZES,
                min_purchased_amount: last_amount + 1,
            },
        ]);
    };

    const removeSize = (index) => {
        const sizes = [...cart_sizes];
        sizes.splice(index, 1);
        set_cart_sizes(sizes);
        set_errors((prev) => ({
            ...prev,
            [`${index}_min_purchased_amount`]: null,
        }));
        validate(sizes);
    };

    const validate = (values) => {
        values.map((size, _i) => {
            const last_amount = values[_i - 1 || 0]?.min_purchased_amount || 0;
            const isInvalidAmount = _i > 0 && size.min_purchased_amount <= last_amount;

            set_errors((prev) => ({
                ...prev,
                [`${_i}_min_purchased_amount`]: isInvalidAmount
                    ? `must be grater than ${last_amount}`
                    : null,
            }));
        });
    };

    const updateValue = (key, index, value) => {
        const temp = [...cart_sizes];
        const val = parseFloat(value) || 0;

        temp[index] = { ...temp[index], [key]: val };
        set_cart_sizes(temp);

        validate(temp);
    };

    useEffect(() => {
        const is_any_error = !!Object.keys(errors).find((k) => !!errors[k]);
        onChange(is_any_error ? [] : cart_sizes);
    }, [cart_sizes, errors]);

    return (
        <div className="quantity-discounts-wrapper table-tag-wrapper">
            <table>
                <thead>
                    <tr>
                        <td className="action-col"></td>
                        <td className="quantity-col">Cart Amount</td>
                        <td className="value-col">Inside Riyadh</td>
                        <td className="value-col">Outside Riyadh</td>
                        <td className="empty-col">
                            <button className="btn btn-info" type="button" onClick={addSize}>
                                + New Range
                            </button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {cart_sizes.map((size, _i) => (
                        <tr key={_i}>
                            <td>
                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    disabled={_i === 0}
                                    onClick={() => removeSize(_i)}
                                >
                                    -
                                </button>
                            </td>

                            {Object.keys(size).map((key, _j) => (
                                <td key={`${key}_${_i}`}>
                                    <Input
                                        type="number"
                                        showError={!!errors[`${_i}_${key}`]}
                                        errorMsg={errors[`${_i}_${key}`]}
                                        disabled={_j === 0 && _i === 0}
                                        value={size[key]}
                                        setValue={(val) => updateValue(key, _i, val)}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DeliveryCartSizes;
