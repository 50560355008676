/* eslint-disable max-len */
import React from 'react';
import {
    ViewHeader,
    DataTable,
    Card,
    Link,
    WhatsApp,
    Dialer,
    Filters,
    Sop
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadLiveCartsData } from '@slices/liveCarts.slice';
import formatDate from '@utils/formatDate';
import { formatMoney, GET } from '@utils';
import { stringify } from 'query-string';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function LiveCartsListView() {
    const LiveCartsSlice = useSelector((state) => state.LiveCartsSlice);

    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});
    const [loading_totals, set_loading_totals] = React.useState(false);
    const [live_carts_totals, set_live_carts_totals] = React.useState({
        live_carts_count: 0,
        live_carts_total: null,
    });

    useDidMountEffect(() => {
        dispatch(loadLiveCartsData({ ...filters, page, records_per_page }));
    }, [page, filters, records_per_page]);

    React.useEffect(() => {
        fetchLiveCartsTotals(filters.account_manager_id);
    }, [filters.account_manager_id]);


    const fetchLiveCartsTotals = async (account_manager_id) => {
        set_loading_totals(true);

        const res = await GET({
            endpoint: `/live-cart/total-amount?${stringify(
                { account_manager_id },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                }
            )}`,
        });

        set_loading_totals(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            set_live_carts_totals(res?.data);
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
            center: true,
            cell: (row) => <Link href={`/live-carts/${row.id}`}>#{row.id}</Link>,
        },
        {
            name: 'Company',
            selector: 'customer.store_name',
            cell: (row) => <h5>{row.customer.store_name}</h5>
        },
        {
            name: 'Name',
            selector: 'customer.name',
        },
        {
            name: 'Updated at',
            selector: 'updated_at',
            minWidth: '150px',
            format: (r) => formatDate(r.updated_at),
        },
        {
            name: 'Mobile',
            selector: 'customer.mobile',
            minWidth: '130px',
            cell:(row)=> <Dialer number={row?.customer?.mobile}/>
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width:'70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.customer?.mobile}/>
        },
        {
            name: 'Cart',
            selector: 'productsCount',
        },
        {
            name: 'Account manager',
            selector: 'customer.account_manager',
            cell: (row) => row?.customer?.account_manager?.name,
        },
        {
            name: 'Total',
            selector: 'total',
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total)}</div>
                </div>
            ),
        },
    ];

    const LiveCartsTotals = () => {
        if (loading_totals) return 'loading...';

        return (
            <div className="totals-wrapper">
                {formatMoney(live_carts_totals?.live_carts_total)} SAR
                &nbsp; / &nbsp;
                <small>{live_carts_totals?.live_carts_count || 0} live carts</small>
            </div>
        );
    };

    return (
        <div className="live-carts-list-view">
            <ViewHeader title="Live carts">
                <div className="flex__ai-center">
                    <LiveCartsTotals />
                    <Sop id="25123" className="ml-2" />
                </div>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by company, customer name, mobile"
                    filters={[
                        'account_managers',
                        'scheduled_order',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'created_at',
                    }}
                    sort_options={[
                        {
                            label: 'Date (Newest first)',
                            value: 'order_by_date',
                            direction: 'desc',
                        },
                        {
                            label: 'Date (Oldest first)',
                            value: 'order_by_date',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={LiveCartsSlice.liveCarts}
                    pages={LiveCartsSlice.liveCarts_pages}
                    records={LiveCartsSlice.liveCarts_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    page={page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={LiveCartsSlice.liveCarts_loading}
                />
            </Card>
        </div>
    );
}
