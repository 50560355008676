import React from 'react';
import {
    ViewHeader,
    Textarea,
    Button,
    Card,
    Row,
    Col,
    Input,
    Checkbox,
    CustomPrompt,
    ShowErrors,
    Sop
} from '@components';
import { POST, randomId } from '@utils';
import { Redirect, useLocation } from 'react-router-dom';
import Selector from './MerchantContent';

const IdempotencyKey = randomId();

export default function PushNotificationsAddView() {
    const { pathname } = useLocation();
    const isVendorNotifications = pathname.includes('/vendors-notifications');

    const [vendors_apply_method, set_vendors_apply_method] = React.useState(null);
    const [selected_vendors, set_selected_vendors] = React.useState([]);
    const [selected_category, set_selected_category] = React.useState('');
    const [created, set_created] = React.useState(false);

    const [title_en, set_title_en] = React.useState({
        label: 'Title',
        value: '',
        dir: 'auto',
        setValue: (val) => set_title_en({ ...title_en, value: val }),
    });
    const [body_en, set_body_en] = React.useState({
        label: 'Body',
        value: '',
        dir: 'auto',
        setValue: (val) => set_body_en({ ...body_en, value: val }),
    });
    const [title_ar, set_title_ar] = React.useState({
        label: 'Title',
        dir: 'auto',
        value: '',
        setValue: (val) => set_title_ar({ ...title_ar, value: val }),
    });
    const [body_ar, set_body_ar] = React.useState({
        label: 'Body',
        value: '',
        dir: 'auto',
        setValue: (val) => set_body_ar({ ...body_ar, value: val }),
    });
    const [CTA, set_CTA] = React.useState({
        label: 'Call to action (Link)',
        value: '',
        setValue: (val) => set_CTA({ ...CTA, value: val }),
    });
    const [cta_is_external, set_cta_is_external] = React.useState({
        label: 'Is CTA external?',
        checked: false,
        onChange: (val) =>
            set_cta_is_external({
                ...cta_is_external,
                checked: val,
            }),
    });
    const [is_actionable, set_is_actionable] = React.useState(false);
    const [loading, set_loading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);

    const handleSubmit = async () => {
        const endpoint = isVendorNotifications
            ? '/vendors/notifications'
            : '/notifications';
        set_loading(true);
        const data = {
            title_ar: title_ar.value,
            title_en: title_en.value,
            body_ar: body_ar.value,
            body_en: body_en.value,

            ...(is_actionable && {
                cta: CTA.value,
            }),
            ...(isVendorNotifications && {
                category: selected_category.id,
            }),
            ...(isVendorNotifications &&
				!vendors_apply_method && {
                is_for_all: 1,
            }),
            ...(isVendorNotifications &&
				vendors_apply_method && {
                vendors: selected_vendors.map((v) => v.id),
                operation: vendors_apply_method,
            }),
            cta_is_external: cta_is_external.checked ? '1' : '0',
        };

        const res = await POST({
            endpoint,
            data,
            headers: {
                'Idempotency-Key': IdempotencyKey,
            },
        });
        set_loading(false);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            set_created(true);
        }
    };

    if (created) {
        return (
            <Redirect
                to={
                    isVendorNotifications
                        ? '/vendors-notifications/list'
                        : '/customers-notifications/list'
                }
            />
        );
    }

    return (
        <div className="notifications-form-view">
            <CustomPrompt>
                <ViewHeader title="Create new notifications" renderBackArrow>
                    <div className="flex__ai-center">
                        <Sop id="25403"/>
                        <Button
                            disabled={loading || (is_actionable && CTA.value === '')}
                            onClick={handleSubmit}
                        >
						Create notification
                        </Button>
                    </div>
              
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        {isVendorNotifications && (
                            <Selector
                                vendors_apply_method={vendors_apply_method}
                                set_vendors_apply_method={set_vendors_apply_method}
                                selected_vendors={selected_vendors}
                                set_selected_vendors={set_selected_vendors}
                                selected_category={selected_category}
                                set_selected_category={set_selected_category}
                            />
                        )}
                        <Row>
                            <Col sm={12} md={6}>
                                <h3 className="divider-title">Arabic content</h3>
                                <Input {...title_ar} />
                                <Textarea {...body_ar} />
                            </Col>
                            <Col sm={12} md={6}>
                                <h3 className="divider-title">English content</h3>
                                <Input {...title_en} />
                                <Textarea {...body_en} />
                            </Col>
                            <Col sm={12}>
                                <Checkbox
                                    label="Is Actionable"
                                    checked={is_actionable}
                                    onChange={set_is_actionable}
                                />
                            </Col>
                            {is_actionable && (
                                <Col sm={12}>
                                    <Input {...CTA} />
                                </Col>
                            )}
                            <Col sm={12} md={6}>
                                <Checkbox {...cta_is_external} />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
