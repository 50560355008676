/* eslint-disable max-len */
import React from 'react';

export default function StatusBlock({
    state = {},
    noIcon = false,
    time_spent = null,
    ...props
}) {
    const [is_fulfillment_status, set_is_fulfillment_status] =
		React.useState(false);

    React.useEffect(() => {
        if (!isNaN(state?.code)) {
            set_is_fulfillment_status(true);
        } else {
            set_is_fulfillment_status(false);
        }
    }, [state]);

    return (
        <div className={`status-block status-${state.code}`} {...props}>
            {!noIcon && (
                <>
                    {is_fulfillment_status ? (
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 392.6 392.6"
                        >
                            <path
                                d="M387.1,99.4c-0.4-0.8-0.4-1.2-1.2-2c-0.4-0.8-1.2-1.2-2.4-1.6l-91.6-47.6L199.1,0.6c-1.6-0.8-3.6-0.8-5.2,0l-93.2,47.6
                        l-92,47.6c-1.2,0.4-2.4,1.6-2.8,2.8c-0.4,0.8-0.4,1.2-0.8,2v0.4v190.4c0,2,1.2,4,3.2,5.2l185.2,94.8c0,0,0.4,0,0.4,0.4
                        c0,0,0.4,0,0.4,0.4c0.8,0.4,1.2,0.4,2,0.4s1.2,0,2-0.4c0,0,0.4,0,0.4-0.4c0,0,0.4,0,0.4-0.4l185.2-94.8c2-1.2,3.2-3.2,3.2-5.2V101
                        C387.5,100.2,387.5,99.8,387.1,99.4z M196.3,12.2l90,46.4l82.8,42.4l-56.8,29.2L139.5,41.4L196.3,12.2z M271.5,163.8l20-10.4
                        l13.2-6.8v24.8l-10.8-4.8c-2.4-1.2-5.2-0.4-6.8,1.6L271.9,187v-23.2H271.5z M106.3,58.6l20.4-10.4l172.8,88.4l-33.6,17.6
                        L93.1,65.4L106.3,58.6z M190.7,377.4l-174-89.6V110.6l84.4,43.2l89.6,46V377.4z M196.3,189.8l-90-46.4L23.5,101l56.8-29.2
                        l172.8,88.8L196.3,189.8z M375.9,288.2l-174,88.8V199.8l58.4-30v33.6c0,3.2,2.4,5.6,5.6,5.6c2,0,3.6-0.8,4.8-2.4l22.4-27.6
                        l15.2,6.4c2.8,1.2,6.4,0,7.6-3.2c0.4-0.4,0.4-1.2,0.4-2V141l59.6-30.4V288.2z"
                            />
                        </svg>
                    ) : (
                        <svg
                            id="Layer_1"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="m256 512c-68.38 0-132.668-26.628-181.02-74.98s-74.98-112.64-74.98-181.02 26.629-132.667 74.98-181.02 112.64-74.98 181.02-74.98 132.668 26.628 181.02 74.98 74.98 112.64 74.98 181.02-26.629 132.667-74.98 181.02-112.64 74.98-181.02 74.98zm0-480c-123.514 0-224 100.486-224 224s100.486 224 224 224 224-100.486 224-224-100.486-224-224-224z" />
                            <path d="m256 240c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40c0 8.836 7.163 16 16 16s16-7.164 16-16c0-34.201-23.978-62.888-56-70.186v-17.814c0-8.836-7.163-16-16-16s-16 7.164-16 16v17.814c-32.022 7.298-56 35.985-56 70.186 0 39.701 32.299 72 72 72 22.056 0 40 17.944 40 40s-17.944 40-40 40-40-17.944-40-40c0-8.836-7.163-16-16-16s-16 7.164-16 16c0 34.201 23.978 62.888 56 70.186v17.814c0 8.836 7.163 16 16 16s16-7.164 16-16v-17.814c32.022-7.298 56-35.985 56-70.186 0-39.701-32.299-72-72-72z" />
                        </svg>
                    )}
                </>
            )}
            <div>
                {state.description || state.code}
                {time_spent && ` for ${time_spent}`}
            </div>
        </div>
    );
}
