import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const ChildOrdersSlice = createSlice({
    name: 'child-orders',
    initialState: {
        child_orders: [],
        child_order: {},
        child_order_boxes: { actions: [], boxes: [] },
        child_orders_pages: null,
        child_orders_records: null,
        child_orders_loading: true,
        child_order_actions_loading: true,
    },
    reducers: {
        set_child_orders(state, { payload }) {
            state.child_orders = payload;
        },
        set_child_order(state, { payload }) {
            state.child_order = payload;
        },
        set_child_boxes_order(state, { payload }) {
            state.child_order_boxes = payload;
        },
        update_single_order_from_list(state, { payload }) {
            state.child_orders = state.child_orders.map((order) => {
                if (order.id === payload.id) {
                    return {
                        ...order,
                        ...payload,
                    };
                }
                return order;
            });
        },
        set_child_orders_pages(state, { payload }) {
            state.child_orders_pages = payload;
        },
        set_child_orders_records(state, { payload }) {
            state.child_orders_records = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
    },
});

// export actions
export const {
    set_child_order,
    set_child_boxes_order,
    set_child_orders,
    update_single_order_from_list,
    set_child_orders_pages,
    set_child_orders_records,
    setLoading,
} = ChildOrdersSlice.actions;

export const loadChildOrdersData =
    (params = {}) =>
    async (dispatch) => {
        dispatch(
            setLoading({
                key: 'child_orders_loading',
                value: true,
            }),
        );
        const res = await GET({
            endpoint: `/suborder?${stringify(params, {
                skipNull: true,
                skipEmptyString: true,
                arrayFormat: 'comma',
            })}`,
        });
        dispatch(set_child_orders(res?.data || []));
        dispatch(set_child_orders_pages(res?.meta?.last_page || 0));
        dispatch(
            set_child_orders_records({
                from: res?.meta?.from,
                to: res?.meta?.to,
                total: res?.meta?.total,
            }),
        );
        dispatch(
            setLoading({
                key: 'child_orders_loading',
                value: false,
            }),
        );
    };

export const loadChildOrderData =
    (id = {}) =>
    async (dispatch) => {
        dispatch(
            setLoading({
                key: 'child_order_loading',
                value: true,
            }),
        );
        const res = await GET({
            endpoint: `/suborder/${id}`,
        });
        dispatch(set_child_order(res?.data || {}));
        dispatch(
            setLoading({
                key: 'child_order_loading',
                value: false,
            }),
        );
    };

export const loadChildOrderBoxesData =
    (id = {}) =>
    async (dispatch) => {
        dispatch(
            setLoading({
                key: 'child_order_boxes_loading',
                value: true,
            }),
        );
        const res = await GET({
            endpoint: `/suborder/${id}/boxes`,
        });
        dispatch(set_child_boxes_order(res?.data || { boxes: [], actions: [] }));
        dispatch(
            setLoading({
                key: 'child_order_boxes_loading',
                value: false,
            }),
        );
    };

export default ChildOrdersSlice.reducer;
