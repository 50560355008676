/** @todo fixing linting issues */
/* eslint-disable quotes */
import React from 'react';
import SingleOrderHeader from './SingleOrderHeader';
import {
    Row,
    Col,
    Card,
    Tabs,
    Checkbox,
    CustomerInfo,
    ShippingAddress,
    Comments,
    DataTable,
    Modal,
    Receipt,
    WhatsApp,
    Dialer,
    OrderStateModal,
    FinanceComments,
} from '@components';
import Suborder from './Suborder';
import NewWireTransactionModal from '../NewWireTransactionModal';
import PaymentInfo from '../PaymentInfo';
import Adjustments from '../Adjustments';
import PayLater from '../PayLater';
// import searchIcon from '@assets/icons/search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'query-string';
import {
    loadOrderData,
    publishOrderComment,
    publishOrderFinanceComment,
} from '@slices/orders.slice';
import { DELETE, GET, POST, PUT, errorsNormalizer } from '@utils';
import formatDate from '@utils/formatDate';
import { useParams } from 'react-router';
import { setModalStatus } from '@slices/ui.slice';
import { toast } from 'react-toastify';
import OrderTimeline from './OrderTimeline';
import EditShippingAddressModal from './EditShippingAddressModal';
import EditOrderProductsModal from '../EditOrderProductsModal';
import { Helmet } from 'react-helmet';
import { setOrder } from '../../../store/slices/orders.slice';
import { useHistory } from 'react-router-dom';
import ConfirmCODModal from '../ConfirmCODModal';
import formatStringWithUnderscores from '../../../utils/formatStringWithUnderscores';
import _ from 'lodash';
import SuborderRating from './SuborderRating';
import ReOrderModal from './ReOrderModal';

const OrdersSingleView = () => {
    const { id, child_id } = useParams();
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const { user, is_super_admin } = useSelector((state) => state.AuthSlice);

    const permissions = {
        canShowFinanceComments:
            is_super_admin || user?.permissions?.includes('orderComments.list_finance_comments'),
    };

    const dispatch = useDispatch();
    const [comments, set_comments] = React.useState([]);
    const [finance_comments, set_finance_comments] = React.useState([]);
    const [updating_business_model, set_updating_business_model] = React.useState(false);
    const [active_tab, set_active_tab] = React.useState(child_id || 'All');
    const [submitting, set_submitting] = React.useState(false);

    const [paymentMethod, setPaymentMethod] = React.useState({
        label: 'Payment method',
        options: [
            { value: '1', label: 'Bank transfer' },
            { value: '2', label: 'Visa' },
            { value: '3', label: 'Mada' },
        ],
        value: '',
        onChange: (val) => setPaymentMethod({ ...paymentMethod, value: val }),
    });
    // const [discount, setDiscount] = React.useState('');
    const [suborderModal, setSuborderModal] = React.useState(null);
    const [loadingPDF, setLoadingPDF] = React.useState(false);
    const [
        selected_suborders_to_download_delivery_note,
        set_selected_suborders_to_download_delivery_note,
    ] = React.useState([]);
    const [device_details, set_device_details] = React.useState({});

    const fetchDeviceDetailsByOrder = async (id) => {
        const res = await GET({
            endpoint: `/order/${id}/device`,
        });
        if (res.status_code === 200) {
            const d = {
                app_version: res?.data?.app_version,
                ip_address: res?.data?.ip_address,
                order_id: res?.data?.order_id,
                created_at: formatDate(res?.data?.created_at),
                updated_at: formatDate(res?.data?.updated_at),
                device_model: res?.data?.device_detail.device_model,
                device_uuid: res?.data?.device_detail.device_uuid,
                platform: res?.data?.device_detail.platform,
                user_agent: res?.data?.device_detail.user_agent,
            };

            set_device_details(d);
        }
    };

    React.useEffect(() => {
        dispatch(setOrder({}));
        dispatch(loadOrderData(id, permissions));
        fetchDeviceDetailsByOrder(id);
    }, []);

    const history = useHistory();
    React.useEffect(() => {
        if (active_tab === 'All') {
            history.replace(`/orders/${id}`);
        } else {
            history.replace(`/orders/${id}/${active_tab}`);
        }
    }, [active_tab]);

    React.useEffect(() => {
        if (OrdersSlice?.order_comments?.length) {
            set_comments(
                OrdersSlice?.order_comments?.map((c) => {
                    return {
                        user: c.admin.name,
                        comment: c.comment,
                        date: c.created_at,
                    };
                }),
            );
        }
    }, [OrdersSlice?.order_comments]);

    React.useEffect(() => {
        if (OrdersSlice?.order_finance_comments?.length) {
            set_finance_comments(
                OrdersSlice?.order_finance_comments?.map((c) => {
                    return {
                        user: c.admin.name,
                        comment: c.comment,
                        date: c.created_at,
                        type: c.type?.type_en,
                    };
                }),
            );
        }
    }, [OrdersSlice?.order_finance_comments]);

    const cancelOrder = async (selected_reasons) => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/order/${id}/state`,
            data: {
                parent_reason: selected_reasons?.key,
                child_reason: selected_reasons?.sub_key,
                details: selected_reasons?.other_details,
            },
        });
        set_submitting(false);

        if (res?.status_code === 200) {
            dispatch(loadOrderData(id, permissions));
            toast.success('The order has been canceled successfully');
        } else {
            toast.error(errorsNormalizer(res?.data?.errors).join(', '));
        }
    };

    const downloadBlobAsFile = async (url, filename) => {
        setLoadingPDF(true);
        const resp = await GET({
            endpoint: url,
            options: {
                responseType: 'blob',
            },
        });

        if (resp?.size) {
            const file = new Blob([resp], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = filename;
            link.click();
        }
        setLoadingPDF(false);
    };

    const downloadSalesOrderInvoice = async () => {
        downloadBlobAsFile(`/order/${id}/sales-order`, `SO-${id}.pdf`);
    };
    const onDownloadTaxInvoice = async (tax_invoice_id) => {
        downloadBlobAsFile(`/order/tax-invoice/${tax_invoice_id}`, `tax-invoice-${id}.pdf`);
    };
    const onDownloadCreditNote = async (credit_note_download_url) => {
        downloadBlobAsFile(credit_note_download_url, `credit-note-${id}.pdf`);
    };
    const downloadDeliveryNote = async () => {
        if (!selected_suborders_to_download_delivery_note.length) return;
        set_submitting(true);

        await downloadBlobAsFile(
            `/order/${id}/delivery-note?${stringify(
                {
                    suborders: selected_suborders_to_download_delivery_note,
                },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'bracket',
                },
            )}`,
            `delivery-note-${id}.pdf`,
        );
        set_submitting(false);

        set_selected_suborders_to_download_delivery_note([]);
    };
    const runSuborderAction = async (sid, state, reasons, selected_reasons) => {
        set_submitting(true);
        const data = reasons?.length
            ? {
                  state,
                  parent_reason: selected_reasons?.key,
                  child_reason: selected_reasons?.sub_key,
                  details: selected_reasons?.other_details,
              }
            : { state };
        const res = await POST({
            endpoint: `/suborder/${sid}/state`,
            data,
        });
        set_submitting(false);

        if (res?.status_code === 200) {
            let action = /\s+/.test(suborderModal.title)
                ? `marked as ${suborderModal.title.toLowerCase()}`
                : suborderModal.title.toLowerCase().replace(/e?$/, 'ed');
            toast.info(`Suborder ${extractPrettyId(suborderModal)} is ${action}`);
            dispatch(loadOrderData(id, permissions));
        } else {
            toast.error(errorsNormalizer(res?.data?.errors).join(', '));
        }
    };

    if (OrdersSlice.order_loading && Object.keys(OrdersSlice.order).length === 0) {
        return 'loading...';
    }

    const extractPrettyId = (t) => t?.pretty_id || t?.id?.toString();

    const sub_orders = _.uniqBy(
        [...(OrdersSlice.order.sub_orders || []), ...(OrdersSlice?.suborders_timeline || [])],
        (s) => s.pretty_id,
    );

    const tabs = sub_orders?.map((sub) => ({
        name: extractPrettyId(sub),
        vendor_name: sub?.vendor ? sub?.vendor?.company_name || 'Merchant :' + sub?.vendor?.id : null,
        isActive: active_tab === extractPrettyId(sub),
    }));
    tabs?.unshift({
        name: 'All',
        isActive: active_tab === 'All',
    });

    const setTabs = (tabs) => {
        set_active_tab(
            tabs
                .filter((t) => t.isActive)
                .slice(0, 1)
                .map((t) => t.name)
                .join(),
        );
    };

    let summaryDataOrder = OrdersSlice.order;
    if (active_tab !== 'All') {
        OrdersSlice?.order?.sub_orders?.map((t) => {
            if (extractPrettyId(t) === active_tab) {
                summaryDataOrder = t;
            }
        });
    }
    const summaryData = [
        {
            subject: { en: 'Subtotal', ar: 'المنتجات' },
            price: summaryDataOrder.subtotal,
        },
        {
            subject: {
                en: 'Delivery Fees Without VAT',
                ar: 'رسوم الشحن والتوصيل بدون ضريبة',
            },
            price: summaryDataOrder.customer_delivery_fees,
        },
        ...(summaryDataOrder?.fees
            ? [
                  ...summaryDataOrder?.fees?.map((fee) => ({
                      subject: { en: fee.name, ar: fee.name },
                      price: fee.amount,
                  })),
              ]
            : []),
        {
            subject: {
                en: 'VAT',
                ar: 'ضريبة القيمة المضافة',
            },
            price: summaryDataOrder.vat,
        },
        {
            subject: { en: 'Discount', ar: 'الخصم' },
            price: summaryDataOrder.discount,
        },
        {
            subject: { en: "Disty's ratio", ar: 'حصة دستي' },
            price: summaryDataOrder.app_discount_amount,
        },
        {
            subject: { en: "Merchant's ratio", ar: 'حصة المورد' },
            price: summaryDataOrder.vendor_discount_amount,
        },
        {
            subject: { en: 'Wallet discount', ar: 'خصم المحفظة' },
            price: summaryDataOrder.wallet_discount,
        },
    ];
    const summaryDataTotal = summaryDataOrder.total;
    const summaryDataTotalDue = summaryDataOrder.total_due;
    const summaryDataPayableAmount = summaryDataOrder.payable_amount;

    const customerInfo = [
        {
            key: 'Company name',
            value: OrdersSlice.order.customer?.store_name,
            className: 'btn btn-link',
            onClick: () => {
                window.open(`/customers/${OrdersSlice.order.customer.id}`);
            },
        },
        {
            key: 'Retailer name',
            value: OrdersSlice.order.customer?.name,
        },
        {
            key: 'Retailer email',
            value: (
                <a href={`mailto:${OrdersSlice.order.customer?.email}`}>
                    {OrdersSlice.order.customer?.email}
                </a>
            ),
        },
        //{ key: 'Retailer IP address', value: '41.38.59.33' },
        // TODO IP is not presented at API
        {
            key: 'Retailer mobile',
            value: (
                <div className="flex__jc-between__ai-center">
                    <div className="mr-5">
                        <Dialer number={OrdersSlice.order.customer?.mobile} />
                    </div>
                    <div className="whatsApp__wrapper">
                        <WhatsApp number={OrdersSlice.order.customer?.mobile} />
                    </div>
                </div>
            ),
        },
        {
            key: 'Device information',
            value: (
                <div
                    onClick={() => {
                        dispatch(
                            setModalStatus({
                                modal: 'single_order_view__device_info',
                                status: true,
                            }),
                        );
                    }}
                    className="btn btn-link flex__jc-between__ai-center"
                >
                    Click to view device details
                </div>
            ),
        },
    ];
    const shippingAddresses = {
        data: [
            {
                key: 'Branch name',
                value: OrdersSlice.order.shipping_address?.name,
            },
            {
                key: 'Contact name',
                value: OrdersSlice.order.shipping_address?.contact_name,
            },
            {
                key: 'Contact mobile',
                value: OrdersSlice.order.shipping_address?.contact_mobile,
            },
            {
                key: 'Preferred delivery time',
                value: OrdersSlice.order.shipping_address?.suitable_delivery_time,
                className: 'badge badge-light',
            },
            {
                key: 'Special Mark',
                value: OrdersSlice.order.shipping_address?.special_mark,
            },
            {
                key: 'Details',
                value: OrdersSlice.order.shipping_address?.details,
            },
            {
                key: 'Courier',
                value: '', // TODO
            },
            {
                key: 'Tracking number',
                value: '', // TODO
            },
        ],
        address: {
            country: 'Saudi Arabia',
            city: OrdersSlice.order.shipping_address?.city,
            address: OrdersSlice.order.shipping_address?.district, // TODO address
        },
        coordinates: {
            latitude: OrdersSlice.order.shipping_address?.latitude,
            longitude: OrdersSlice.order.shipping_address?.longitude,
        },
    };

    let editProducts = [];
    let canEditProducts = false;
    OrdersSlice?.order.sub_orders?.map((o) => {
        if (o.can_edit) {
            canEditProducts = true;
        }
        o.products.map((p) => {
            editProducts.push({
                editable: o.can_edit,
                min_amount: o.min_amount,
                ...p,
            });
        });
    });

    const columns = [
        {
            name: 'Questions',
            selector: 'question',
            cell: (row) => row?.question_en,
        },
        {
            name: 'Answers',
            selector: 'answer',
            cell: (row) => row?.rate?.description,
        },
    ];

    const updateBusinessModel = async (business_model) => {
        set_updating_business_model(true);
        const endpoint = `/order/${id}/business-model`;
        const res = await PUT({
            endpoint,
            data: { business_model: business_model },
        });

        set_updating_business_model(false);
        if (res?.status_code === 200) {
            dispatch(loadOrderData(id, permissions));
        } else {
            toast.error(errorsNormalizer(res?.data?.errors || res?.data?.message).join(', '));
        }
    };

    const isSuborderDeleted = sub_orders.find((s) => s.pretty_id === active_tab)?.is_deleted;

    return (
        <div className="orders-single-view">
            <Helmet>
                <title>{`Order #${id} | Disty Admin Dashboard`}</title>
            </Helmet>
            <NewWireTransactionModal id={id} />
            <ConfirmCODModal id={id} />
            <ReOrderModal customerId={OrdersSlice.order.customer.id} orderId={id} />
            {OrdersSlice.order.can_edit_shipping_address && <EditShippingAddressModal id={id} />}

            <EditOrderProductsModal
                id={id}
                products={editProducts}
                business_model={OrdersSlice.order.business_model}
                reloadOrder={() => dispatch(loadOrderData(id, permissions))}
            />
            <SingleOrderHeader
                onCancel={() => {
                    setSuborderModal({
                        id: OrdersSlice.order.id,
                        title: 'Cancel by admin',
                        reasons: OrdersSlice.order.cancellation_reasons,
                        isParent: true,
                    });

                    dispatch(
                        setModalStatus({
                            modal: 'single_order_view__suborder_modal',
                            status: true,
                        }),
                    );
                }}
                isCancelDisabled={!OrdersSlice.order.can_cancel}
                tax_invoice_issued={OrdersSlice.order.tax_invoice_issued}
                tax_invoices={OrdersSlice.order.tax_invoices}
                credit_notes={OrdersSlice.order.credit_notes || []}
                onDownloadCreditNote={onDownloadCreditNote}
                onBusinessModelUpdate={updateBusinessModel}
                updating_business_model={updating_business_model}
                onEdit={() => {
                    dispatch(
                        setModalStatus({
                            modal: 'edit_order_products_modal',
                            status: true,
                        }),
                    );
                }}
                isEditDisabled={!canEditProducts}
                onDownloadSalesInvoice={() => downloadSalesOrderInvoice()}
                onDownloadDeliveryNote={() => {
                    dispatch(
                        setModalStatus({
                            modal: 'single_order_view__select_suborders_to_download_delivery_note',
                            status: true,
                        }),
                    );
                }}
                onDownloadTaxInvoice={onDownloadTaxInvoice}
                loadingPDF={loadingPDF}
            />
            <Row>
                <Col lg={8}>
                    {!!OrdersSlice.order?.warnings?.length && (
                        <div className="alert alert-warning fs-sm py-3" role="alert">
                            <ol>
                                {OrdersSlice.order?.warnings?.map((war, i) => (
                                    <li key={i}>{war}</li>
                                ))}
                            </ol>
                        </div>
                    )}
                    <Card>
                        <Tabs tabs={tabs} onSelect={setTabs}>
                            {isSuborderDeleted && (
                                <div className="alert alert-danger text-center">
                                    Suborder Deleted
                                </div>
                            )}
                            <Suborder
                                active_tab={active_tab}
                                data={OrdersSlice?.order?.sub_orders?.filter(
                                    (t) =>
                                        extractPrettyId(t) === active_tab || active_tab === 'All',
                                )}
                                warehouse_allocation={
                                    OrdersSlice?.order?.warehouse_allocation || {}
                                }
                                onSuborderAction={(id, pretty_id, state, title, reasons) => {
                                    setSuborderModal({
                                        id,
                                        pretty_id,
                                        state,
                                        title,
                                        reasons,
                                    });
                                    dispatch(
                                        setModalStatus({
                                            modal: 'single_order_view__suborder_modal',
                                            status: true,
                                        }),
                                    );
                                }}
                            />
                        </Tabs>
                        <Receipt
                            data={summaryData}
                            total={summaryDataTotal}
                            total_due={summaryDataTotalDue}
                            payable_amount={summaryDataPayableAmount}
                        />
                    </Card>
                    <PaymentInfo />
                    <Adjustments />
                    <PayLater
                        onSuccess={() => {
                            dispatch(loadOrderData(id, permissions));
                        }}
                    />
                    <SuborderRating />

                    {/*customer rating*/}
                    {OrdersSlice.order?.rating && (
                        <Card title="Customer Rating">
                            <div className="rating p-3 flex__jc-between">
                                <div>
                                    {[...Array(Math.round(OrdersSlice.order?.rating?.average))].map(
                                        (key) => {
                                            return (
                                                <span key={key} className="star">
                                                    &#9733;
                                                </span>
                                            );
                                        },
                                    )}
                                    <div className="text-center">
                                        {OrdersSlice.order?.rating?.average?.toFixed(2)}
                                    </div>
                                </div>
                                <div>{formatDate(OrdersSlice.order?.rating?.created_at)}</div>
                            </div>
                            {OrdersSlice.order?.rating?.comments && (
                                <div className="rating-comments p-3">
                                    Retailer Comments: {OrdersSlice.order?.rating?.comments}
                                </div>
                            )}
                            <DataTable
                                data={OrdersSlice.order?.rating?.ratings}
                                columns={columns}
                            />
                        </Card>
                    )}
                </Col>
                <Col lg={4}>
                    <CustomerInfo data={customerInfo} />
                    <ShippingAddress
                        shippingAddressInfo={shippingAddresses}
                        map
                        canEdit={OrdersSlice.order.can_edit_shipping_address}
                    />
                    <OrderTimeline
                        active_tab={active_tab}
                        tabs={tabs}
                        setTabs={setTabs}
                        isSuborderDeleted={isSuborderDeleted}
                    />
                    <Comments
                        comments={comments}
                        onSubmit={(c, u) => dispatch(publishOrderComment(id, c, u))}
                    />
                    <FinanceComments
                        comments={finance_comments}
                        onSubmit={(c, t, u) => dispatch(publishOrderFinanceComment(id, c, t, u))}
                    />
                    {/* <Card title="Payment method & Discount">
                        <div className="px-3">
                            <Select {...paymentMethod} />
                            <Input
                                label="Discount"
                                setValue={(val) => {
                                    setDiscount(val);
                                }}
                                value={discount}
                            />
                        </div>
                    </Card> */}
                </Col>
            </Row>
            <OrderStateModal
                suborderModal={suborderModal}
                onSubmit={(closeModal, selected_reasons) => {
                    if (suborderModal?.isParent) {
                        cancelOrder(selected_reasons);
                    } else {
                        runSuborderAction(
                            suborderModal.id,
                            suborderModal.state,
                            suborderModal.reasons,
                            selected_reasons,
                        );
                    }

                    closeModal('single_order_view__suborder_modal');
                }}
            />

            <Modal
                title="Select suborders to download delivery note"
                name="single_order_view__select_suborders_to_download_delivery_note"
                loading={submitting}
                onSubmit={(closeModal) => {
                    downloadDeliveryNote();
                    closeModal('single_order_view__select_suborders_to_download_delivery_note');
                }}
                size="lg"
            >
                {OrdersSlice?.order?.sub_orders?.map((s) => (
                    <Checkbox
                        key={s.id}
                        label={s.pretty_id}
                        onChange={(val) => {
                            if (val) {
                                set_selected_suborders_to_download_delivery_note((prev) => [
                                    ...prev,
                                    s.id,
                                ]);
                            } else {
                                set_selected_suborders_to_download_delivery_note((prev) =>
                                    prev.filter((id) => id !== s.id),
                                );
                            }
                        }}
                        checked={selected_suborders_to_download_delivery_note.includes(s.id)}
                    />
                ))}
            </Modal>

            <Modal title="Device info" name="single_order_view__device_info" confirmBtn={false}>
                <div className="data-pairs">
                    {device_details == null ? (Object.keys(device_details).map((key) => (
                        <div className="data-pairs__item" key={key}>
                            <div className="key">{formatStringWithUnderscores(key)}</div>
                            <div className="value">{device_details[key] || 'NULL'}</div>
                        </div>
                    ))):<p style={{ margin:"20px " }}>No details are available for this device</p>}
                </div>
            </Modal>
        </div>
    );
};

export default OrdersSingleView;
