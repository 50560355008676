import React from 'react';
import { ViewHeader, DataTable, Card, Link } from '@components';
import { formatMoney, formatDate } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { loadVirtualIbansTransactionsData } from '../../store/slices/virtualIbans.slice';
import { useParams } from 'react-router-dom';

export default function SingleView() {
    const VirtualIbansSlice = useSelector((state) => state.VirtualIbansSlice);
    const dispatch = useDispatch();
    const { id } = useParams();

    React.useEffect(() => {
        dispatch(loadVirtualIbansTransactionsData(id));
    }, [id]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            center: true,
            width: '100px',
        },
        {
            name: 'Wallet ID',
            selector: 'wallet_id',
            center: true,
            width: '100px',
        },
        {
            name: 'Retailer ID',
            selector: 'customer_id',
            sortable: false,
            center: true,
            width: '100px',
            cell: (row) => <Link href={`/customers/${row.customer_id}`}>{row.customer_id}</Link>,
        },
        {
            name: 'Payment reference',
            selector: 'Payment_reference',
            sortable: false,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
        },
        {
            name: 'Pay channel',
            selector: 'pay_channel',
            sortable: false,
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.amount)}</div>
                </div>
            ),
        },
        {
            name: 'Invoice number',
            selector: 'invoice_number',
            sortable: false,
        },
        {
            name: 'Payment remarks',
            selector: 'payment_remarks',
            sortable: false,
        },
        {
            name: 'Date',
            selector: 'value_date',
            sortable: false,
            cell: (row) => <div>{formatDate(row.value_date)}</div>,
        },
    ];

    return (
        <div>
            <ViewHeader title={`Virtual IBAN transactions: ${id}`} renderBackArrow />

            <Card>
                <DataTable
                    columns={columns}
                    data={VirtualIbansSlice.virtualIban_transactions}
                    pages={VirtualIbansSlice.virtualIbans_transactions_pages}
                    records={VirtualIbansSlice.virtualIbans_transactions_records}
                    searchable={true}
                    loading={VirtualIbansSlice.virtualIbans_transactions_loading}
                />
            </Card>
        </div>
    );
}
