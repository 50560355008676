export const ORDER_STATUS = {
    Pending: 100,
    'Partially confirmed': 200,
    Confirmed: 300,
    'In progress': 400,
    'Partially completed': 500,
    Completed: 600,
    Cancelled: 700,
};

export const SUB_ORDER_STATUS = {
    Pending: 1,
    Confirmed: 2,
    'Cancelled by merchant': 4,
    'Cancelled timeout': 8,
    'Ready for pickup from merchant': 16,
    'Picked up by courier': 32,
    'Received in warehouse': 64,
    'Under fulfillment': 128,
    'Ready to dispatch': 256,
    'Out for delivery': 512,
    Fulfilled: 1024,
    'Cancelled by admin': 2048,
    'Pending Customer Confirmation': 4096,
    'Cancelled payment timeout': 8192,
    'Courier rejected to pickup': 16384,
    'Client rejected to collect': 32768,
    'Client is not reachable': 65536,
    'Cancelled by customer': 131072,
    Delivered: 262144,
};

export const ISSUE_TYPES = [
    { label: 'Damaged', value: 'damaged' },
    { label: 'Expired', value: 'expired' },
];

export const STATUS_OPTIONS = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 },
];

export const MARKETPLACE_INVOICING_TRIGGERS = [
    { label: 'Once confirmed by the merchant', value: SUB_ORDER_STATUS.Confirmed },
    { label: 'Once Picked up', value: SUB_ORDER_STATUS['Picked up by courier'] },
    { label: 'Once Fulfilled', value: SUB_ORDER_STATUS.Fulfilled },
];

export const DEFAULT_DELIVERY_OPTIONS = ['DBQ', 'DBV'];

export const VERIFICATION_STATUSES = [
    { label: 'Not Verified', value: 'not_verified' },
    { label: 'Verification In Progress', value: 'verification_in_progress' },
    { label: 'Verified', value: 'verified' },
];
export const VERIFICATION_DECISION = [
    { label: 'Pending', value: 'pending' },
    { label: 'Accepted', value: 'accepted' },
    { label: 'Rejected', value: 'rejected' },
];

export const LEADS_STATUSES = [
    { label: 'Open', value: 'OPEN' },
    { label: 'Attempting Contact', value: 'ATTEMPTING_CONTACT' },
    { label: 'Disqualified', value: 'DISQUALIFIED' },
    { label: 'Working', value: 'WORKING' },
    { label: 'Not Engaged', value: 'NOT_ENGAGED' },
    { label: 'Qualified', value: 'QUALIFIED' },
];

export const REQUEST_STATUSES = [
    { label: 'Successfully Fulfilled', value: 'successfully_fulfilled' },
    { label: 'Contacting The Customer', value: 'contacting_the_customer' },
    { label: 'Product Found', value: 'product_found' },
    { label: 'Merchant Found', value: 'vendor_found' },
    { label: 'Under Planning', value: 'under_planning' },
    { label: 'Request Received', value: 'request_received' },
    { label: 'Request Rejected', value: 'request_rejected' },
];

export const YES_NO = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
];

export const INVOICE_STATUSES = [
    { label: 'Pending Purchase Orders upload by merchant', value: 1 << 0 },
    { label: 'Submitted by merchant', value: 1 << 1 },
    { label: 'Rejected with re-upload ability', value: 1 << 2 },
    { label: 'Rejected without re-upload ability', value: 1 << 3 },
    { label: 'Approved', value: 1 << 4 },
    { label: 'Paid', value: 1 << 5 },
    { label: 'Partially paid', value: 1 << 8 },
    { label: 'Hold', value: 1 << 6 },
    { label: 'Canceled', value: 1 << 7 },
];

export const PAYMENT_STATUS = [
    { label: 'Paid', value: 1 },
    { label: 'Not Paid', value: 0 },
];

export const WALLET_WITHDRAWAL_REQUESTS_STATES = [
    { label: 'Paid', value: 'paid' },
    { label: 'Processing', value: 'processing' },
    { label: 'Rejected', value: 'rejected' },
];

export const STOCK_TYPES = [
    { label: 'Merchant', value: 'vendor' },
    { label: 'Warehouse', value: 'warehouse' },
];

export const TAGGED_OPTIONS = [
    { label: 'Tagged Collections', value: 1 },
    { label: 'Non tagged collections', value: 0 },
];

export const COLLECTION_AVAILABILITY = [
    { label: 'Available On Market', value: 1 },
    { label: 'Not Available', value: 0 },
];

export const DEDUCTED_FROM = [
    { label: 'Distyccount', value: 'disty_account' },
    { label: 'Merchant account', value: 'vendor_account' },
];

export const AMOUNT_TYPES = [
    { label: 'Refund', value: 'order_refund' },
    { label: 'Appeasement', value: 'appeasement' },
    { label: 'Order created', value: 'order_created' },
    { label: 'Admin created', value: 'admin_created' },
    { label: 'Order refund for cancel', value: 'order_refund_for_cancel' },
    { label: 'Order refund for edit', value: 'order_refund_for_edit' },
];

export const REQUEST_VENDOR_STATUSES = [
    { label: 'Request Received', value: 'request_received' },
    { label: 'Merchant Shortlisted', value: 'vendor_shortlisted' },
    { label: 'Merchant Contacted', value: 'vendor_contacted' },
    { label: 'Merchant Negotiation', value: 'vendor_negotiation' },
    { label: 'Agreement Failed', value: 'agreement_failed' },
    { label: 'Merchant Joined', value: 'vendor_joined' },
    { label: 'Customer Contacted', value: 'customer_contacted' },
];

export const VENDOR_REQUEST_STATUSES = [
    { label: 'Pending', value: 'pending' },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Approved', value: 'approved' },
    { label: 'Needs Changes', value: 'needs_changes' },
    { label: 'Rejected', value: 'rejected' },
];

export const VENDOR_PRODUCT_REQUEST_STATUSES = [
    { label: 'Pending Disty Approval', value: 'pending_disty_approval' },
    { label: 'Approved', value: 'approved' },
    { label: 'Needs Changes', value: 'needs_changes' },
    { label: 'Rejected', value: 'rejected' },
];

export const SEARCH_BY_OPTIONS = [
    { label: 'Product Name', value: 'product_name' },
    { label: 'Customer Name', value: 'customer_name' },
];

export const VENDOR_STATES = [
    { label: 'Not Verified', value: 'not_verified' },
    { label: 'Verification In Progress', value: 'verification_in_progress' },
    { label: 'Verified', value: 'verified' },
];

export const APP_FEEDBACK_TYPES = [
    { label: 'Feature', value: 'feature' },
    { label: 'Bug', value: 'bug' },
];

export const COLLECTION_TYPES = [
    { label: 'Previewed', value: 'previewed' },
    { label: 'Hidden', value: 'hidden' },
    { label: 'Bannered', value: 'bannered' },
];

export const BUSINESS_MODELS = [
    { label: 'Marketplace', value: 'marketplace' },
    //{ label: 'Fmcg', value: 'fmcg' },
    { label: 'Invoice Financing', value: 'invoice_financing' },
    //{ label: 'CBV', value: 'cbv' },
];

export const VENDOR_PRODUCT_REQUEST_TYPES = [
    { label: 'Create Product', value: 'create' },
    { label: 'Update Product', value: 'update' },
];

export const DELIVERY_PAYERS = [
    { label: 'On Merchant', value: 'vendor' },
    { label: 'On Retailer', value: 'customer' },
];
export const  WIRE_TRANSACTION_STATES = [
    { label: 'Success', value: 'SUCCESS' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Pending', value: 'PENDING' },
];
