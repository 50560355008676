
import React, { useState, useEffect } from 'react';
import { GET } from '@utils';
import { stringify } from 'query-string';
import { Row, Col } from '@components';

function CustomAttributes({ set_custom_attributes, validationErrors = {}, showErrors }) {
    const [data, set_data] = useState([]);
    const [attributes, set_attributes] = useState([]);
    const [localValidationErrors, setLocalValidationErrors] = useState({});
  
    const validateAttributes = (attributes) => {
        const errors = {};
        attributes.forEach((attribute, index) => {
            if (!attribute.value_ar) {
                errors[`value_ar_${index}`] = 'Value AR is required';
            }
            if (!attribute.value_en) {
                errors[`value_en_${index}`] = 'Value EN is required';
            }
        });
        setLocalValidationErrors(errors);
    };

    useEffect(() => {
        set_custom_attributes(attributes);
        validateAttributes(attributes);
    }, [attributes]);

    const fetchData = async () => {
        const res = await GET({
            endpoint: `/product-custom-attribute?${stringify(
                { is_active: true },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                },
            )}`,
        });
        if (res?.status_code === 200) {
            set_data(res?.data || []);
            set_attributes(
                res?.data.map((a) => ({
                    product_custom_attribute_id: a?.id,
                    name: a.name_en,
                    value_ar: '',
                    value_en: '',
                    unit_ar: '',
                    unit_en: '',
                })) || [],
            );
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

   
    const handleChange = (index, field, value) => {
        set_attributes((prev) =>
            prev.map((attr, i) => (i === index ? { ...attr, [field]: value } : attr)),
        );
    };

    return (
        <div className="section-custom-attribute">
            <h3 className="divider-title">Custom Attributes</h3>
            {attributes.map((a, i) => (
                <div className="attribute-form" key={a.product_custom_attribute_id}>
                    <h4>Attribute Name ({data[i]?.name_en})</h4>
                    <Row>
                        <Col lg={6}>
                            <label htmlFor={`value_ar_${i}`}>Value AR</label>
                            <input
                                value={a.value_ar}
                                id={`value_ar_${i}`}
                                type="text"
                                onChange={(e) => handleChange(i, 'value_ar', e.target.value)}
                            />
                            {showErrors &&
                                (validationErrors[`value_ar_${i}`] ||
                                    localValidationErrors[`value_ar_${i}`]) && (
                                    <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                        {validationErrors[`value_ar_${i}`] ||
                                            localValidationErrors[`value_ar_${i}`]}
                                    </p>
                                )}
                            <label htmlFor={`unit_ar_${i}`}>Unit AR</label>
                            <input
                                value={a.unit_ar}
                                id={`unit_ar_${i}`}
                                type="text"
                                onChange={(e) => handleChange(i, 'unit_ar', e.target.value)}
                            />
                        </Col>
                        <Col lg={6}>
                            <label htmlFor={`value_en_${i}`}>Value EN</label>
                            <input
                                value={a.value_en}
                                id={`value_en_${i}`}
                                type="text"
                                onChange={(e) => handleChange(i, 'value_en', e.target.value)}
                            />
                            {showErrors &&
                                (validationErrors[`value_en_${i}`] ||
                                    localValidationErrors[`value_en_${i}`]) && (
                                    <p style={{ color: 'red', margin: '-12px 0 15px' }}>
                                        {validationErrors[`value_en_${i}`] ||
                                            localValidationErrors[`value_en_${i}`]}
                                    </p>
                                )}
                            <label htmlFor={`unit_en_${i}`}>Unit EN</label>
                            <input
                                value={a.unit_en}
                                id={`unit_en_${i}`}
                                type="text"
                                onChange={(e) => handleChange(i, 'unit_en', e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
}

export default CustomAttributes;