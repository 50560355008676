/* eslint-disable no-undef */
// https://www.npmjs.com/package/@react-google-maps/api
import React from 'react';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    Autocomplete,
    // InfoWindow
} from '@react-google-maps/api';

import Geocode from 'react-geocode';
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GECODING_KEY);
Geocode.setLanguage('ar');
Geocode.setLocationType('ROOFTOP');

import locationIcon from '@assets/icons/location-gray.svg';
import searchIcon from '@assets/icons/search-gray.svg';
import closeIcon from '@assets/icons/close.svg';

const defaultMapOptions = {
    mapTypeControl: false,
    panControl: false,
    scaleControl: false,
    streetViewControl: false,
    overviewMapControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    zoomControl: false
};

const libraries = ['places'];

export const center_of_SA = {
    lat: 24.804219,
    lng: 45.8601
};

function Map({
    coords,

    center = center_of_SA,
    marker,
    markers,
    zoom,
    google_maps_address = null,

    allow_search = false,
    height = '200px',

    onCoordsChange = null,
    set_center = null,
    set_marker = null,
    set_google_maps_address = null,
}) {
    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries
    });

    const [map, set_map] = React.useState(null);
    const [autocomplete, set_autocomplete] = React.useState(null);
    const [show_selected_address, set_show_selected_address] =
        React.useState(false);

    const inputRef = React.useRef();

    React.useEffect(() => {
        if (coords?.lat && coords?.lng) {
            set_marker && set_marker(coords);
        } else {
            set_center && set_center(center);
        }
    }, [coords]);

    React.useEffect(() => {
        if (!show_selected_address) {
            inputRef.current?.focus();
        }
    }, [show_selected_address]);

    React.useEffect(() => {
        if (coords?.lat && coords?.lng) {
            set_google_maps_address && set_google_maps_address('جاري التحميل..');
            fetchAddress();
        }
    }, [coords]);

    const fetchAddress = async () => {
        Geocode.fromLatLng(coords.lat, coords.lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                set_google_maps_address && set_google_maps_address(address);
                set_show_selected_address(true);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        set_map(map);
    }, []);

    const onUnmount = React.useCallback(function callback() {
        set_map(null);
    }, []);

    // auto complete
    const onAutocompleteLoad = (autocomplete) => {
        set_autocomplete(autocomplete);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            try {
                const place = autocomplete.getPlace();
                const bounds = new google.maps.LatLngBounds();

                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
                set_center && set_center({
                    lat: place.geometry?.location?.lat(),
                    lng: place.geometry?.location?.lng()
                });

                map.fitBounds(bounds);
            } catch (error) {
                console.log('map error', error);
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{
                height: height,
                maxHeight: '50vh'
            }}
            mapContainerClassName="map-element"
            center={center}
            zoom={zoom || 6}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={defaultMapOptions}
            onClick={(e) => {
                const pos = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                };

                set_marker && set_marker(pos);
                if(onCoordsChange){
                    onCoordsChange(pos);
                    map.panTo(pos);
                    if (map.getZoom() < 10) {
                        map.setZoom(10);
                    }
                }
            }}
        >
            {allow_search && (

                <Autocomplete
                    onLoad={onAutocompleteLoad}
                    onPlaceChanged={onPlaceChanged}
                    className="search-input-wrapper"
                >
                    <div>
                        {show_selected_address ? (
                            <img
                                src={locationIcon}
                                className="location-icon"
                                alt="location-gray"
                            />
                        ) : (
                            <img
                                src={searchIcon}
                                className="search-icon"
                                alt="search"
                            />
                        )}
                        <input
                            type="text"
                            placeholder="Search for an address.."
                            data-hj-allow
                            data-clarity-unmask="true"
                            style={{
                                display: show_selected_address ? 'none' : 'block'
                            }}
                            ref={inputRef}
                        />
                        <div
                            className="selected-address"
                            style={{
                                display: !show_selected_address ? 'none' : 'flex'
                            }}
                            onClick={() => {
                                set_show_selected_address(false);
                            }}
                        >
                            <span>{google_maps_address}</span>
                            <img
                                src={closeIcon}
                                className="close-icon"
                                alt="close"
                            />
                        </div>
                    </div>
                </Autocomplete>
            )}


            {(marker || coords) && (
                <Marker position={marker || coords} />
            )}

            {markers && markers.map((marker, index) => (
                <>
                    <Marker
                        key={index}
                        position={marker}
                        icon={{
                            url: marker.icon,
                            scaledSize: new window.google.maps.Size(30, 30)
                        }}
                        onClick={() => {
                            set_center && set_center(marker);
                            map.panTo(marker);
                            if (map.getZoom() < 10) {
                                map.setZoom(10);
                            }
                        }}
                    />
                    {/* window info */}
                    {/* {marker.window && (
                        <InfoWindow
                            position={marker}
                            onCloseClick={() => {
                                marker.window = false;
                            }}
                        >
                            <div className="info-window">
                                <div className="info-window-content">
                                    <div className="info-window-title">
                                        {marker.title}
                                    </div>
                                </div>
                            </div>
                        </InfoWindow>
                    )} */}
                </>
            ))}

        </GoogleMap>
    ) : (
        <></>
    );
}

export default React.memo(Map);
