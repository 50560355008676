import { Card, Stack, TextField, Button } from '@shopify/polaris';
import { useState, useCallback } from 'react';

export default function FollowFilter({
    value = { from: 0, to: 0 },
    set_value = () => {},
}) {
    const [values, setValues] = useState(value);

    const handleOnChange = useCallback(
        (key, value) => {
            setValues({
                ...values,
                [key]: value,
            });
        }
    );

    return (
        <Card sectioned>
            <div>
                <Stack vertical>
                    <TextField
                        label="Count from"
                        type="number"
                        value={values.from}
                        onChange={val => handleOnChange('from', val)}
                        autoComplete="off"
                    />
                    <TextField
                        label="Count to"
                        type="number"
                        value={values.to}
                        onChange={val => handleOnChange('to', val)}
                        autoComplete="off"
                    />
                </Stack>
                <br />
                <Button
                    onClick={() => set_value(values)}
                >
					Apply
                </Button>
            </div>
        </Card>
    );
}
