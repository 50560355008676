import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import RatedOrdersListView from './RatedOrdersListView';
import { Helmet } from 'react-helmet';

export default function BrandsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/rating/list">
                <Helmet>
                    <title>Rating | Disty Admin Dashboard</title>
                </Helmet>
                <RatedOrdersListView />
            </ProtectedRoute>
        </Switch>
    );
}
