import React from 'react';
import { DataTable, Button, Modal, Input, ShowErrors, Select } from '@components';
import { PUT, randomId, formatStringWithUnderscores } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadVendorSettingsData } from '@slices/settings.slice';
import useQuery from '@hooks/useQuery';
const excludedFields = [
    'cbv_take_rate',
    'vendor_cbv_take_rate',
    'vendor_marketplace_payout_option',
    'vendor_retailer_payout_option',
];

const VendorSettingsTab = () => {
    const SettingsSlice = useSelector((state) => state.SettingsSlice);
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const { data: { data: payoutOptions } = { data: [] } } = useQuery(
        '/vendors/vendor-payout-options',
    );
    const keysData = {
        cbv_take_rate: {
            label: 'CBV - Admin Source',
            value: (val) => GlobalsSlice.all_plans.find((p) => p.percentage === val)?.name_en,
        },
        vendor_cbv_take_rate: {
            label: 'CBV -Merchant Source',
            value: (val) => GlobalsSlice.all_plans.find((p) => p.percentage === val)?.name_en,
        },
        vendor_retailer_payout_option: {
            label: 'Global Payout 2.0',
            value: (val) => payoutOptions?.find((p) => p.id === val)?.name_en,
        },
        vendor_marketplace_payout_option: {
            label: 'Global Payout 3.0',
            value: (val) => payoutOptions?.find((p) => p.id === val)?.name_en,
        },
    };
    const dispatch = useDispatch();
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    // const [invoice_request_days, set_invoice_request_days] = React.useState({
    //     label: 'Invoice Request Days',
    //     value: '',
    //     setValue: (val) => set_invoice_request_days({ ...invoice_request_days, value: val }),
    // });
    // const [invoice_request_payout_days, set_invoice_request_payout_days] = React.useState({
    //     label: 'Invoice Request Payout Days',
    //     value: '',
    //     setValue: (val) => set_invoice_request_payout_days(
    //         { ...invoice_request_payout_days, value: val }),
    // });
    // const [first_reminder_minutes, set_first_reminder_minutes] = React.useState({
    //     label: 'First Reminder Minutes',
    //     value: '',
    //     setValue: (val) => set_first_reminder_minutes({ ...first_reminder_minutes, value: val }),
    // });
    // const [last_reminder_minutes, set_last_reminder_minutes] = React.useState({
    //     label: 'Last Reminder Minutes',
    //     value: '',
    //     setValue: (val) => set_last_reminder_minutes({ ...last_reminder_minutes, value: val }),
    // });
    // const [home_screen_number_of_vendors, set_home_screen_number_of_vendors] = React.useState({
    //     label: 'home screen number of vendors',
    //     value: '',
    //     setValue: (val) => set_home_screen_number_of_vendors(
    //         { ...home_screen_number_of_vendors, value: val }),
    // });

    const [data, set_data] = React.useState([]);
    const [data_keys, set_data_keys] = React.useState({});
    const [fields, set_fields] = React.useState([]);

    const [cbv_take_rate, set_cbv_take_rate] = React.useState({
        label: 'CBV - Admin Source',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_cbv_take_rate((prev) => ({ ...prev, value: val })),
    });
    const [vendor_cbv_take_rate, set_vendor_cbv_take_rate] = React.useState({
        label: 'CBV - Merchant Source',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_cbv_take_rate((prev) => ({ ...prev, value: val })),
    });
    const [vendor_retailer_payout_option, set_vendor_retailer_payout_option] = React.useState({
        label: 'Payout 2.0',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_vendor_retailer_payout_option((prev) => ({ ...prev, value: val })),
    });
    const [vendor_marketplace_payout_option, set_vendor_marketplace_payout_option] = React.useState(
        {
            label: 'Payout 3.0',
            options: [],
            value: '',
            getOptionValue: (option) => option.id,
            onChange: (val) =>
                set_vendor_marketplace_payout_option((prev) => ({ ...prev, value: val })),
        },
    );
    const fetchData = async () => {
        const res = await dispatch(loadVendorSettingsData());
        const keysList = Object.keys(res?.data).filter((k) => !excludedFields.includes(k));
        const formatted_fields = keysList.map((key) => {
            return {
                label: formatStringWithUnderscores(key),
                key,
                value: res?.data[key],
            };
        });

        set_fields(formatted_fields);
        set_data_keys(res.data);
    };
    // React.useEffect(() => {
    //     vendor_marketplace_payout_option.onChange(
    //         payoutOptions.find((x) => x.id === data_keys.vendor_marketplace_payout_option),
    //     );
    //     vendor_retailer_payout_option.onChange(
    //         payoutOptions.find((x) => x.id === data_keys.vendor_retailer_payout_option),
    //     );
    // }, [data_keys, payoutOptions]);
    React.useEffect(() => {
        fetchData();
    }, [rerender]);
    React.useEffect(() => {
        set_cbv_take_rate({
            ...cbv_take_rate,
            options: GlobalsSlice.all_plans.map((p) => ({
                id: p.percentage,
                label: p.name_en,
            })),
        });
        set_vendor_cbv_take_rate({
            ...vendor_cbv_take_rate,
            options: GlobalsSlice.all_plans.map((p) => ({
                id: p.percentage,
                label: p.name_en,
            })),
        });
        set_vendor_retailer_payout_option({
            ...vendor_retailer_payout_option,
            options: payoutOptions?.map((p) => ({
                id: p?.id,
                label: p?.name_en,
            })),
        });
        set_vendor_marketplace_payout_option({
            ...vendor_marketplace_payout_option,
            options: payoutOptions?.map((p) => ({
                id: p?.id,
                label: p?.name_en,
            })),
        });
        set_data(
            Object.keys(data_keys).map((key) => {
                const label = keysData[key]?.label || formatStringWithUnderscores(key);
                const value = keysData[key]?.value(data_keys[key]) || data_keys[key];

                return {
                    label,
                    key,
                    value,
                };
            }),
        );
    }, [GlobalsSlice, data_keys, payoutOptions]);

    React.useEffect(() => {
        if (data_keys.cbv_take_rate >= 0) {
            const value = cbv_take_rate.options.find((o) => o.id === data_keys.cbv_take_rate);
            set_cbv_take_rate({
                ...cbv_take_rate,
                value,
            });
        }
    }, [data_keys, cbv_take_rate.options.length]);
    React.useEffect(() => {
        if (data_keys.vendor_cbv_take_rate >= 0) {
            const value = vendor_cbv_take_rate?.options.find(
                (o) => o.id === data_keys.vendor_cbv_take_rate,
            );
            set_vendor_cbv_take_rate({
                ...vendor_cbv_take_rate,
                value,
            });
        }
    }, [data_keys, vendor_cbv_take_rate.options.length]);
    React.useEffect(() => {
        if (data_keys?.vendor_retailer_payout_option) {
            const value = vendor_retailer_payout_option?.options?.find(
                (o) => o.id === data_keys.vendor_retailer_payout_option,
            );
            set_vendor_retailer_payout_option({
                ...vendor_retailer_payout_option,
                value,
            });
        }
    }, [data_keys, vendor_retailer_payout_option?.options?.length]);
    React.useEffect(() => {
        if (data_keys?.vendor_marketplace_payout_option) {
            const value = vendor_marketplace_payout_option?.options?.find(
                (o) => o.id === data_keys.vendor_marketplace_payout_option,
            );
            set_vendor_marketplace_payout_option({
                ...vendor_marketplace_payout_option,
                value,
            });
        }
    }, [data_keys, vendor_marketplace_payout_option?.options?.length]);
    const submit = async (closeModal) => {
        set_loading(true);
        set_errors([]);

        const data = fields.reduce(
            (acc, field) => ({
                ...acc,
                [field.key]: field.value,
            }),
            {},
        );

        data['cbv_take_rate'] = cbv_take_rate?.value?.id;
        data['vendor_cbv_take_rate'] = vendor_cbv_take_rate?.value?.id;
        data['vendor_retailer_payout_option'] = vendor_retailer_payout_option?.value?.id;
        data['vendor_marketplace_payout_option'] = vendor_marketplace_payout_option?.value?.id;

        const res = await PUT({
            endpoint: '/vendors/vendor-settings',
            data,
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
            set_loading(false);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('edit_vendor_settings__modal');
            set_rerender(randomId());
        }
    };

    const columns = [
        {
            name: 'Setting',
            cell: (row) => row?.label,
        },
        {
            name: 'Value',
            center: true,
            cell: (row) => row?.value,
        },
    ];

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                loading={SettingsSlice?.vendorSettings_loading || GlobalsSlice.loading}
            />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_errors([]);
                        dispatch(
                            setModalStatus({
                                modal: 'edit_vendor_settings__modal',
                                status: true,
                            }),
                        );
                    }}
                >
                    Edit merchant settings
                </Button>
            </div>
            <Modal
                title={'Update Merchant Settings'}
                variant={'warning'}
                name="edit_vendor_settings__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal);
                }}
                submitBtnDisabled={loading}
            >
                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
                {fields.map((field) => {
                    return (
                        <Input
                            key={field.label}
                            {...field}
                            setValue={(val) => {
                                const new_fields = fields.map((f) => {
                                    if (f.label === field.label) {
                                        return {
                                            ...f,
                                            value: val,
                                        };
                                    }
                                    return f;
                                });
                                set_fields(new_fields);
                            }}
                        />
                    );
                })}
                <Select {...cbv_take_rate} />
                <p style={{ marginTop: -15, fontSize: 12, marginBottom: 12 }}>
                    This take rate will be applied by default on all merchants on the system unless it
                    is changed for a specific merchant
                </p>
                <Select {...vendor_cbv_take_rate} />
                <p style={{ marginTop: -15, fontSize: 12 }}>
                    This is the take rate for the Created By merchant Business model that is taken by
                    Disty when we enable the merchant to add his sales to the app outside Disty
                    marketplace, This take rate will be applied by default on all merchants on the
                    system unless it is changed for a specific merchant
                </p>
                <h3 style={{ margin: '10px 0', fontWeight: 'bold', fontSize: 16 }}>
                    Global Payout Settings
                </h3>
                <Select {...vendor_retailer_payout_option} />
                <Select {...vendor_marketplace_payout_option} />
            </Modal>
        </>
    );
};

export default VendorSettingsTab;
