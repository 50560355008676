import React from 'react';
import {
    Modal,
    Textarea,
    Input,
    Select,
    FileUpload,
    ShowErrors,
} from '@components';
import { obj2formdata, POST } from '@utils';
import { loadVendorData } from '@slices/merchant.slice';
import { loadCustomerData } from '@slices/customers.slice';
import { useDispatch } from 'react-redux';
function ActivationReasonModal({ status, reasons_option, name, id }) {
    const dispatch = useDispatch();
    const [reason, set_reason] = React.useState({
        label: 'Select the reason',
        options : [],
        value: {},
        getOptionValue: (option) => option.id,
        onChange: (val) => set_reason((prev) => ({ ...prev, value: val })),
    });
    const [other_reason, set_other_reason] = React.useState({
        label: 'Other Reason',
        value: '',
        setValue: (val) => set_other_reason({ ...other_reason, value: val }),
    });
    const [comments, set_comments] = React.useState({
        label: 'Comments',
        value: '',
        setValue: (val) => set_comments({ ...comments, value: val }),
    });
    const [attachment, set_attachment] = React.useState({
        label: 'Attachment',
        maxFiles: 1,
        allowPDF: true,
        files: [],
        setFiles: (val) => set_attachment({ ...attachment, files: val }),
    });
    const [errors, set_errors] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const resetData = () => {
        attachment.setFiles([]);
        comments.setValue('');
        other_reason.setValue('');
        reason.onChange({});
        set_errors([]);
    };
    const submit = async (closeModal) => {
        set_loading(true);
        const data = obj2formdata({
            status: status ? '0' : '1',
            reason: reason?.value?.id,
            other_reason: other_reason?.value,
            comments: comments?.value,
        });
        attachment.files.length && data.append('attachment', attachment.files[0]);
        const res = await POST({
            endpoint: name === 'customer' ? `/customers/${id}/status` : `/vendors/${id}/status`,
            data,
        });
        set_loading(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            name === 'customer' ? dispatch(loadCustomerData(id)) :  dispatch(loadVendorData(id));
            closeModal('activation_reason_modal');
            set_errors([]);
            resetData();
        } else {
            set_errors(res?.data?.errors || {});
        }
    };
    React.useEffect(()=>{
        set_reason({ ...reason , 
            options : reasons_option?.map(x => ({ id : x.code , label:x.reason })) });
    },[reasons_option]);
    return (
        <Modal
            name="activation_reason_modal"
            title={
                status
                    ? `If you disable this account,
             the user will not be able to use the app until you reactivate it`
                    : `If you enable this account,
              the user will be able to use the app until you deactivate it`
            }
            confirmBtnName={status ? 'Disable' : 'Enable'}
            loading={loading}
            onSubmit={(closeModal) => submit(closeModal)}
            onClose={resetData}
        >
            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            <div>
                <Select {...reason} />
                <Input {...other_reason} />
                <Textarea {...comments} />
                <FileUpload {...attachment} />
            </div>
        </Modal>
    );
}

export default ActivationReasonModal;
