import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const VendorCategoriesSlice = createSlice({
    name: 'vendorCategories',
    initialState: {
        vendorCategories: [],
        vendorCategories_pages: null,
        vendorCategory: {},
        vendorCategories_loading: true,
        vendorCategory_loading: true,
    },
    reducers: {
        setVendorCategories(state, { payload }) {
            state.vendorCategories = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.vendorCategories.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.vendorCategories[index] = {
                    ...state.vendorCategories[index],
                    ...payload,
                };
            }
        },
        setVendorCategory(state, { payload }) {
            state.vendorCategory = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVendorCategoriesTotalPages(state, { payload }) {
            state.vendorCategories_pages = payload;
        },
    },
});

// export actions
export const {
    setVendorCategory,
    updateSingleRow,
    setVendorCategories,
    setLoading,
    setVendorCategoriesTotalPages,
} = VendorCategoriesSlice.actions;

export const loadVendorCategoriesData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendorCategories_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/category?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setVendorCategories(res?.data || []));
    if(res?.meta){
        dispatch(setVendorCategoriesTotalPages(res?.meta?.last_page || 1));
    }
    dispatch(
        setLoading({
            key: 'vendorCategories_loading',
            value: false,
        })
    );
};

export const loadVendorCategoryData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendorCategory_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/category/${id}`,
    });
    dispatch(setVendorCategory(res?.data || {}));
    dispatch(
        setLoading({
            key: 'vendorCategory_loading',
            value: false,
        })
    );
};

export default VendorCategoriesSlice.reducer;
