import { useReducer } from 'react';
import { stringify } from 'query-string';
import { GET } from '@utils';

const ACTIONS = {
    FETCH_START: 'fetch_started',
    FETCH_SUCCESS: 'fetch_succeed',
    FETCH_ERROR: 'fetch_error',
};

const initialState = {
    res: null,
    isLoading: false,
    errors: null,
};

function reducer(state, action) {
    switch (action.type) {
    case ACTIONS.FETCH_START: {
        return {
            ...state,
            isLoading: true,
            errors: null,
        };
    }
    case ACTIONS.FETCH_SUCCESS: {
        return {
            ...state,
            res: action.payload,
            isLoading: false,
        };
    }
    case ACTIONS.FETCH_ERROR: {
        return {
            ...state,
            isLoading: false,
            errors: action.payload,
        };
    }
    default: {
        throw Error('Unknown action: ' + action.type);
    }
    }
}

const defaultOptions = {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: 'comma',
};

export default function useFetch({ endpoint, options = defaultOptions }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetch = async (params = {}, config = {}) => {
        try {
            dispatch({ type: ACTIONS.FETCH_START });
            const res = await GET({
                endpoint: `${endpoint}?${stringify(params, options)}`,
            });

            if (
                res?.status_code === 200 || (!res?.data?.errors &&
        		!res?.data?.message && !res?.errors)
            ) {
                dispatch({
                    type: ACTIONS.FETCH_SUCCESS,
                    payload: res,
                });
                config?.onSuccess?.(res);
            } else {
                dispatch({
                    type: ACTIONS.FETCH_ERROR,
                    payload: res?.data?.errors || res?.data?.message || res?.errors,
                });
                config?.Error?.(res);
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.FETCH_ERROR,
                payload: error,
            });
            config?.Error?.(error);

        }
    };
    return {
        state,
        fetch,
    };
}
