/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import { set_is_full_screen_view } from '../../store/slices/ui.slice';
import { GET, diffTimes } from '@utils';
import {
    Filters,
    Link,
    OverlayTrigger,
    Tooltip
} from '@components';
import lockIcon from '@assets/icons/lock.svg';
import useScheduledOrdersQuery from '../../hooks/useScheduledOrdersQuery';
import renderFilters from './components/renderFilters';

const table_legend = [
    {
        code: '1',
        label: 'Pending'
    },
    {
        code: '4096',
        label: 'Confirmed, Partially confirmed'
    },
    {
        code: '16',
        label: 'Ready for pickup'
    },
    {
        code: '32',
        label: 'Picked up'
    },
    {
        code: '64',
        label: 'Received in warehouse'
    },
    {
        code: '128',
        label: 'Under fulfillment'
    },
    {
        code: '256',
        label: 'Ready to dispatch'
    },
    {
        code: '512',
        label: 'Out for delivery'
    },
    {
        code: '1024',
        label: 'Fulfilled'
    },
    {
        code: '2048',
        label: 'Cancelled timeout, Cancelled by merchant, Cancelled by admin'
    }
];


export default function WarehouseListView() {
    const dispatch = useDispatch();
    const [warehouseFilters, setWarehouseFilters] = React.useState({
        cities: [],
        regions: [],
        suborder_status: [],
        scheduled_orders: [],
    });
    const [loading, set_loading] = React.useState(true);

    const [grid_data, set_grid_data] = React.useState([]);

    const { data: scheduled_orders } = useScheduledOrdersQuery();

    const fetchData = async () => {
        const res = await GET({
            endpoint: '/warehouse/locations'
        });
        set_loading(false);

        if (res?.status_code === 200) {
            set_grid_data(filterInactiveCells(res?.data || []));
        }
    };

    const filterInactiveCells = (grid_data) => {
        return grid_data
            .map((row) => {
                row['locations'] = row['locations'].filter(
                    (cell) => cell.is_active || cell.is_allocated
                );
                return row;
            })
            .filter((row) => row.locations?.length > 0);
    };

    React.useEffect(() => {
        fetchData();

        const id = setInterval(() => {
            fetchData();
        }, 1000 * 30); // refetch data every 30

        return () => {
            clearInterval(id);
        };
    }, []);

    React.useEffect(() => {
        dispatch(set_is_full_screen_view(true));

        return () => {
            dispatch(set_is_full_screen_view(false));
        };
    }, []);

    const getGridCols = (grid = []) => {
        const biggest_lane = grid?.reduce((acc, curr) => {
            if (!acc?.locations?.length) {
                acc = curr;
                return acc;
            }
            if (curr.locations?.length > acc?.locations?.length) {
                acc = curr;
            }
            return acc;
        }, {});

        return biggest_lane?.locations?.map((l) => l.shelf) || [];
    };

    const stripParentOrderId = (pretty_id) => {
        return pretty_id.split('-')[1] || '';
    };

    const isAllSubordersReadyToDispatchOrAfter = (suborders = []) => {
        if (!suborders?.length) return false;
        let bool = true;
        suborders.forEach((s) => {
            if (
                s.state.code !== 256 &&
                s.state.code !== 512 &&
                s.state.code !== 1024 &&
                s.state.code !== 4 &&
                s.state.code !== 8 &&
                s.state.code !== 2048 &&
                s.state.code !== 8192
            ) {
                bool = false;
            }
        });

        return bool;
    };

    const checkIfCityMatch = (shipping_address, cities) => {
        if (!cities?.length) return false;
        if (cities?.indexOf(String(shipping_address?.city)) > -1) return true;
        return false;
    };

    const checkIfRegionMatch = (shipping_address, regions) => {
        if (!regions?.length) return false;
        if (regions?.indexOf(String(shipping_address?.region?.name)) > -1)
            return true;
        return false;
    };

    // const checkIfAtLeastOneSuborderStatusMatch = (suborders, selected_statuses) => {
    //     if(!selected_statuses?.length) return false;
    //     let bool = false;
    //     suborders?.forEach(s => {
    //         if(selected_statuses.indexOf(s.state.code) > -1){
    //             bool = true;
    //         }
    //     });
    //     return bool;
    // };


    return (
        <div className="warehouse-grid">
            <Link href="/orders/list" className="back-btn">
                Go back to Dashboard
            </Link>
            <h1>
                Warehouse Grid
                <small>
                    <Link href="/warehouse/edit">Edit warehouse grid</Link>
                </small>
            </h1>

            {loading ? (
                'loading...'
            ) : (
                <>
                    <Filters
                        hide_query_field
                        onFilterChange={(filters) =>  setWarehouseFilters(filters)}
                        renderFilters={(filter, setFilter) => renderFilters({ filter, setFilter, grid: grid_data, scheduled_orders })}
                    />
                    <table>
                        <thead>
                            <tr>
                                <th />
                                {getGridCols(grid_data)?.map((col, i) => (
                                    <th className="cell-label" key={i}>
                                        {col}
                                    </th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {grid_data?.map((row, i) => (
                                <tr key={i}>
                                    <th className="cell-label">{row?.lane}</th>
                                    {row?.locations?.map((loc, i) => (
                                        <td
                                            key={i}
                                            className={
                                                'cell ' +
                                                (loc.is_allocated
                                                    ? 'allocated'
                                                    : 'not-allocated') +
                                                ' ' +
                                                (isAllSubordersReadyToDispatchOrAfter(
                                                    loc?.allocation?.order
                                                        ?.suborders
                                                )
                                                    ? 'cell-ready-to-dispatch'
                                                    : '') +
                                                ' ' +
                                                (warehouseFilters.cities?.length
                                                    ? checkIfCityMatch(
                                                        loc?.allocation?.order
                                                            ?.shipping_address,
                                                        warehouseFilters.cities
                                                    )
                                                        ? 'cell-city-match'
                                                        : ''
                                                    : '') +
                                                ' ' +
                                                (warehouseFilters.regions?.length
                                                    ? checkIfRegionMatch(
                                                        loc?.allocation?.order
                                                            ?.shipping_address,
                                                        warehouseFilters.regions
                                                    )
                                                        ? 'cell-city-match'
                                                        : ''
                                                    : '') +
                                                ' ' +
                                                (warehouseFilters.scheduled_orders?.length
                                                    ? // TODO: complete this
                                                    checkIfRegionMatch(
                                                        loc?.allocation?.order
                                                            ?.scheduled_order,
                                                        warehouseFilters.scheduled_orders
                                                    )
                                                        ? 'cell-city-match'
                                                        : ''
                                                    : '') +
                                                ' ' +
                                                (warehouseFilters.suborder_status?.length
                                                    ? 'hide-suborders-by-status'
                                                    : '')

                                                // (
                                                //     warehouseFilters.suborder_status?.length ?
                                                //         checkIfAtLeastOneSuborderStatusMatch(loc?.allocation?.order?.suborders, warehouseFilters.suborder_status) ? 'cell-suborders-status-match' : '' : ''
                                                // )
                                            }
                                        >
                                            {!loc.is_active && (
                                                <>
                                                    <img
                                                        src={lockIcon}
                                                        alt="locked"
                                                        className="cell-locked-icon"
                                                    />
                                                </>
                                            )}
                                            {loc?.is_allocated ? (
                                                <>
                                                    <div className="suborders-ids">
                                                        {loc?.allocation?.order?.suborders?.map(
                                                            (s) => (
                                                                <OverlayTrigger
                                                                    key={s.id}
                                                                    placement={
                                                                        'top'
                                                                    }
                                                                    overlay={
                                                                        <Tooltip
                                                                            className={
                                                                                'status-' +
                                                                                s
                                                                                    .state
                                                                                    .code
                                                                            }
                                                                        >
                                                                            {
                                                                                s
                                                                                    .state
                                                                                    .description
                                                                            }{' '}
                                                                            -{' '}
                                                                            {
                                                                                s
                                                                                    ?.vendor
                                                                                    ?.company_name
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Link
                                                                        href={
                                                                            '/orders/' +
                                                                            loc
                                                                                ?.allocation
                                                                                ?.order
                                                                                ?.id
                                                                        }
                                                                        target="_blank"
                                                                        key={
                                                                            s.id
                                                                        }
                                                                        className={
                                                                            'suborder status-' +
                                                                            s
                                                                                .state
                                                                                .code +
                                                                            (warehouseFilters.suborder_status?.indexOf(
                                                                                s
                                                                                    .state
                                                                                    .code
                                                                            ) >
                                                                            -1
                                                                                ? ' suborder-status-matched'
                                                                                : '')
                                                                        }
                                                                    >
                                                                        {stripParentOrderId(
                                                                            s.pretty_id
                                                                        )}
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            )
                                                        )}
                                                    </div>

                                                    <span className="parent-order-id">
                                                        {
                                                            loc?.allocation
                                                                ?.order?.id
                                                        }
                                                        &nbsp;- {loc?.name}-{' '}
                                                        {loc?.allocation?.order
                                                            ?.shipping_address
                                                            ?.city || 'NULL'}
                                                        -{' '}
                                                        <br/>
                                                        {loc?.allocation?.allocated_at ? (
                                                            diffTimes(loc?.allocation?.allocated_at)
                                                        ) : ('NULL')}
                                                        &nbsp;
                                                        <span className="badge bg-info">
                                                            {loc?.allocation?.order?.last_update ? (
                                                                diffTimes(loc?.allocation?.order?.last_update)
                                                            ) : ('NULL')}
                                                        </span>
                                                    </span>
                                                </>
                                            ) : (
                                                <>{loc?.name}</>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="table-legend">
                        {table_legend.map((t) => (
                            <div className="type" key={t.code}>
                                <div className={`color status-${t.code}`} />
                                <div className="label">{t.label}</div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}