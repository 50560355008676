import useFetch from './useFetch';
import { useEffect } from 'react';

export default function usePaginationList({
    endpoint,
    params = {},
    options,
    deps = [],
}) {
    const { state, fetch } = useFetch({
        endpoint,
        options
    });
    const { isLoading, res, errors } = state;
    
    useEffect(() => {
        fetch(params);
    }, deps);

    return { 
        dataList: res?.data || [],
        pages: res?.meta?.last_page || 0,
        records: {
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total,
        },
        isLoading,
        errors,
        mutate: fetch
    };
}
