/* eslint-disable no-unused-vars */
import React from 'react';
import {
    ViewHeader,
    Link,
    DataTable,
    Card,
    Filters,
    PaymentMethodIcons,
    StatusBlock,
    WhatsApp,
    Dialer,
    Sop,
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrdersData } from '@slices/orders.slice';
import { formatDate, formatMoney } from '@utils';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function OrdersListView() {
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const dispatch = useDispatch();
    // const [waiting_for_local_cache] = React.useState(true);

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});
    
 
    useDidMountEffect(() => {
        dispatch(loadOrdersData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
            center: true,
            cell: (row) => <Link href={`/orders/${row.id}`}>#{row.id}</Link>,
        },
        {
            name: 'Payment',
            selector: 'payment_status.description',
            minWidth: '130px',
            cell: (row) => <StatusBlock state={row?.payment_status} />,
        },
        {
            name: 'Payment Method',
            selector: 'customer_payment_choice',
            center: true,
            cell: (row) => (
                <PaymentMethodIcons
                    state={row?.customer_payment_choice}
                    bnpl={row.pay_later_provider.code}
                />
            ),
        },
        {
            name: 'Pay later provider',
            selector: 'pay_later_provider',
            width: '80px',
            allowOverflow: false,
            cell: (row) => <p>{row.pay_later_provider.description}</p>,
        },
        {
            name: 'Has availability issue',
            selector: 'has_availability_issue',
            center: true,
            width: '80px',
            cell: (row) =>
                row.has_availability_issue ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Created from live cart',
            selector: 'created_from_live_cart',
            center: true,
            width: '80px',
            cell: (row) =>
                row.created_from_live_cart ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Scheduled',
            selector: 'scheduled_order_id',
            center: true,
            width: '80px',
            cell: (row) =>
                row?.scheduled_order_delivery_option?.id ? (
                    row?.scheduled_order_delivery_option?.title_en
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Delivered',
            selector: 'is_delivered',
            center: true,
            width: '80px',
            cell: (row) =>
                row?.is_delivered ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'State',
            selector: 'state.description',
            width: '180px',
            cell: (row) => <StatusBlock state={row?.state} />,
        },
        {
            name: 'Customer Name',
            selector: 'customer.name',
            cell: (row) => (
                <Link
                    href={`/customers/${row.customer?.id}`}
                    target="_blank"
                    className="tr-name tr-customer-info"
                >
                    <h5>{row?.customer?.name}</h5>
                </Link>
            ),
        },
        {
            name: 'Customer first order',
            selector: 'is_customer_first_order',
            center: true,
            width: '80px',
            cell: (row) =>
                row?.is_customer_first_order ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Store Name',
            selector: 'customer.store_name',
            cell: (row) => (
                <Link
                    href={`/customers/${row.customer?.id}`}
                    target="_blank"
                    className="tr-name tr-customer-info"
                >
                    <div>{row?.customer?.store_name}</div>
                </Link>
            ),
        },
        {
            name: 'Account manager',
            selector: 'customer.account_manager',
            cell: (row) => <div>{row.customer.account_manager?.name}</div>,
        },
        {
            name: 'Mobile',
            selector: 'customer.mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.customer?.mobile} />,
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.customer?.mobile} />,
        },
        {
            name: 'City',
            selector: 'city',
            width: '80px',
            cell: (row) => row?.city,
        },
        {
            name: 'Region',
            selector: 'region',
            width: '80px',
            cell: (row) => row?.region,
        },
        {
            name: 'Amount',
            selector: 'total',
            minWidth: '120px',
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.total)}</div>
                </div>
            ),
        },
        {
            name: 'Warehouse cell',
            selector: 'warehouse_allocation',
            cell: (row) => (
                <Link href="/warehouse/list">{row?.warehouse_allocation?.location_name}</Link>
            ),
        },
        {
            name: 'Shipping preference',
            cell: (row) =>
                row?.shipment_preference ? (
                    <StatusBlock
                        state={
                            row?.shipment_preference
                                ? {
                                      code: row?.shipment_preference.code,
                                      description: row?.shipment_preference?.code,
                                  }
                                : {}
                        }
                        noIcon={true}
                        style={{
                            backgroundColor:
                                row.shipment_preference?.code === 'one_shipment'
                                    ? '#27ae60'
                                    : '#3498db',
                        }}
                    />
                ) : (
                    ''
                ),
        },
        {
            name: 'Average Rating',
            cell: (row) => row?.average_rating?.toFixed(1),
        },
        {
            name: 'Suborders count',
            selector: 'suborders_count',
        },
        {
            name: 'Created At',
            selector: 'created_at',
            minWidth: '150px',
            format: (r) => formatDate(r?.created_at),
        },
        {
            name: 'Last Updated',
            selector: 'updated_at',
            minWidth: '150px',
            format: (r) => formatDate(r?.updated_at),
        },
    ];

    return (
        <div className="customers-list-view">
            <ViewHeader title="Orders">
                <div className="flex__ai-center">
                    <Sop id="25443" />
                    <Link href="/orders/add" className="btn btn-primary">
                        Create order
                    </Link>
                </div>
            </ViewHeader>

            <Card>
                {/* {JSON.stringify(filters, null, 2)} */}
                <Filters
                    queryPlaceholder="Search by id, customer name, store name, mobile"
                    filters={[
                        'business_model',
                        'order_states',
                        'order_payment_states',
                        'payment_methods',
                        'account_managers',
                        'created_at_from',
                        'created_at_to',
                        'customers',
                        'entity_types',
                        'total_from',
                        'total_to',
                        'regions',
                        'cities',
                        'platforms',
                        'sources',
                        'shipment_preferences',
                        'pay_later_providers',
                        'scheduled_order_delivery_option_id',
                        'is_customer_first_order',
                        'total',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'created_at',
                    }}
                    sort_options={[
                        { label: 'Order ID', value: 'id' },
                        {
                            label: 'Updated at (Newest first)',
                            value: 'updated_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Updated at (Oldest first)',
                            value: 'updated_at__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Created at (Newest first)',
                            value: 'created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'created_at__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Total (Biggest first)',
                            value: 'total__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Total (Smallest first)',
                            value: 'total__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={OrdersSlice.orders}
                    pages={OrdersSlice.orders_pages}
                    page={page}
                    records={OrdersSlice.orders_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={OrdersSlice.orders_loading}
                />
            </Card>
        </div>
    );
}
