import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import { Helmet } from 'react-helmet';
import OrdersMap from './OrdersMap';

export default function OrderMapModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/orders-map">
                <Helmet>
                    <title>Orders Map | Disty Admin Dashboard</title>
                </Helmet>
                <OrdersMap />
            </ProtectedRoute>
        </Switch>
    );
}
