import ListOptions from '../../../components/shared/Filters/FiltersComponents/ListOptions';

const suborder_states = [
    {
        value: 1,
        label: 'Pending'
    },
    {
        value: 2,
        label: 'Confirmed'
    },
    {
        value: 4,
        label: 'Cancelled by merchant'
    },
    {
        value: 8,
        label: 'Cancelled timeout'
    },
    {
        value: 16,
        label: 'Ready for pickup'
    },
    {
        value: 32,
        label: 'Picked up'
    },
    {
        value: 64,
        label: 'Received in warehouse'
    },
    {
        value: 128,
        label: 'Under fulfillment'
    },
    {
        value: 256,
        label: 'Ready to dispatch'
    },
    {
        value: 512,
        label: 'Out for delivery'
    },
    {
        value: 1024,
        label: 'Fulfilled'
    },
    {
        value: 2048,
        label: 'Cancelled by admin'
    },
    {
        value: 4096,
        label: 'Partially confirmed'
    },
    {
        value: 8192,
        label: 'Cancelled payment timeout'
    }
];

const warehouseFiltersOptions = ({ grid = [],  scheduled_orders = [] })=> ({
    'cities': {
        label: 'Highlight by City',
        options: getCitiesOptions(grid)
    },
    'regions': {
        label: 'Highlight by Region',
        options: getRegionsOptions(grid)
    },
    'suborder_status': {
        label: 'Highlight by suborder status',
        options: suborder_states,
    },
    'scheduled_orders': {
        label: 'Highlight by scheduled Orders',
        options: scheduled_orders.map(({ id, title_en, name }) =>
            ({ label: title_en || name, value: id })
        )
    },
});

const renderFilters = ({ filter, setFilter, grid, scheduled_orders }) => {
    return Object.entries(warehouseFiltersOptions({ grid, scheduled_orders }))
        .map(([key, { label, options }]) => ({
            key,
            label,
            shortcut: true,
            filter: (
                <ListOptions
                    searchable
                    isMulti
                    options={options}
                    value={filter[key]}
                    set_value={(val) => setFilter(key, val)}
                />
            )
        }));

};

const mapKeyIntoObject = (objectKeys) => objectKeys.map((key) => ({ label: key, value:key }));

const getCitiesOptions = (grid) => {
    const cities = grid.reduce((acc, lane) => {
        lane?.locations?.forEach(loc => {
            if(loc?.allocation?.order){
                const city = loc?.allocation?.order?.shipping_address?.city;
                if(acc[city]){
                    acc[city] += 1;
                }else{
                    acc[city] = 1;
                }
            }
        });

        return acc;
    }, {});

    return mapKeyIntoObject(Object.keys(cities));
};

const getRegionsOptions = (grid) => {
    const regions = grid.reduce((acc, lane) => {
        lane?.locations?.forEach(loc => {
            if(loc?.allocation?.order){
                const region = loc?.allocation?.order?.shipping_address?.region?.name;
                if(acc[region]){
                    acc[region] += 1;
                }else{
                    acc[region] = 1;
                }
            }
        });

        return acc;
    }, {});

    return mapKeyIntoObject(Object.keys(regions));
};

export default renderFilters;