import React, { useEffect } from 'react';
import { default as M } from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '@store/slices/ui.slice';
import { Button, Spinner } from '@components';

export default function Modal({
    title,
    name,
    children,
    variant = 'primary',
    onSubmit = () => {},
    onClose,
    confirmBtn = true,
    confirmBtnName = 'Confirm',
    submitBtnDisabled = false,
    loading,
    size = null,
    footer = true,
    blank = false,
    bodyStyle,
    className,
    setPayload,
}) {
    const modals_status = useSelector((state) => state.UISlice.modals_status);

    const modals_payload = useSelector((state) => state.UISlice.modal_payload);
    const dispatch = useDispatch();

    const closeModal = () => {
        onClose && onClose();
        dispatch(setModalStatus({ modal: name, status: false }));
    };

    useEffect(() => {
        setPayload?.(modals_payload[name]);
    }, [modals_payload[name]]);

    return (
        <M show={modals_status[name]} onHide={closeModal} size={size} className={className}>
            {!blank && (
                <M.Header closeButton>
                    <M.Title>{title}</M.Title>
                </M.Header>
            )}
            {children && <M.Body style={bodyStyle}>{children}</M.Body>}
            {(blank === true || footer === true) && (
                <M.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Close
                    </Button>

                    {confirmBtn && (
                        <Button
                            variant={variant}
                            onClick={() => {
                                onSubmit(closeModal);
                            }}
                            disabled={submitBtnDisabled || loading}
                        >
                            {confirmBtnName}
                            {loading && (
                                <>
                                    &nbsp;
                                    <Spinner size="sm" animation="border" />
                                </>
                            )}
                        </Button>
                    )}
                </M.Footer>
            )}
        </M>
    );
}

Modal.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element, // Accepts JSX element as well
    ]),
    name: PropTypes.string.isRequired,
    children: PropTypes.any,
    footer: PropTypes.any,
    confirmBtn: PropTypes.bool,
};
