/* eslint-disable max-len */
import React from 'react';
import {
    ViewHeader,
    Button,
    Card,
    Input,
    Checkbox,
    Row,
    Col,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { GET, POST } from '@utils';
import { Redirect } from 'react-router';

export default function UsersGroupsAddView() {
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(false);
    const [name, set_name] = React.useState('');
    const [errors, setErrors] = React.useState([]);
    const [selected_roles, set_selected_roles] = React.useState([]);
    const [roles, set_roles] = React.useState({});
    const [roles_array, set_roles_array] = React.useState([]);
    const [selected_modules, set_selected_modules] = React.useState([]);
    const [is_all_selected, set_is_all_selected] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            // set_loading(true);
            const res = await GET({
                endpoint: '/role/permissions',
            });
            set_loading(false);
            set_roles(res?.data || {});
        };
        fetchData();
    }, []);

    React.useEffect(() => {
        const rolesArray = [];
        if (Object.keys(roles).length !== 0) {
            Object.keys(roles).forEach((role) => {
                Object.keys(roles[role]).forEach((permission) => {
                    rolesArray.push(roles[role][permission]);
                });
            });
        }
        set_roles_array(rolesArray);
    }, [roles]);

    React.useEffect(() => {
        Object.keys(roles).forEach((role) => {
            // check if all module checkboxs has been selected
            const module = Object.keys(roles).find((r) => roles[r] === roles[role]);
            if (Object.values(roles[role]).every((r) => selected_roles.includes(r))) {
                set_selected_modules((prev) => [...prev, module]);
            } else {
                set_selected_modules((prev) => [...prev.filter((m) => m !== module)]);
            }
        });
        // Check if all checkboxs of all modules has been selected
        if (selected_roles.length && roles_array.every((r) => selected_roles.includes(r))) {
            set_is_all_selected(true);
        } else {
            set_is_all_selected(false);
        }
    }, [selected_roles]);

    const onSelectAll = (status) => {
        if (status) {
            set_selected_roles([...roles_array]);
        } else {
            set_selected_roles([]);
        }
    };

    const onSelectModule = (status, module) => {
        if (status) {
            set_selected_roles((prev) => [...prev, ...module]);
        } else {
            set_selected_roles((prev) => [...prev.filter((r) => !module.includes(r))]);
        }
    };

    const onSelectRole = (status, role) => {
        if (status) {
            set_selected_roles((prev) => [...prev, role]);
        } else {
            set_selected_roles((prev) => [...prev.filter((r) => r !== role)]);
        }
    };
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name || !/^[a-zA-Z\u0600-\u06FF\s]*$/.test(name))
            newErrors.name = 'The name field is required and must be contained only letters and spaces.';
        if (selected_roles.length === 0)
            newErrors.permissions = 'The permissions field is required.';

        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name, selected_roles]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const res = await POST({
                endpoint: '/role',
                data: {
                    name,
                    permissions: selected_roles,
                },
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_created(true);
            }
        }
    };

    if (created) {
        return <Redirect to="/users-groups/list" />;
    }

    return (
        <div className="users-groups-form-view">
            <CustomPrompt>
                <ViewHeader title="Create users group">
                    <Sop id="30163" />
                    <Button variant="primary" onClick={submit}>
                        Create
                    </Button>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        <Row>
                            <Col xs={12} md={6}>
                                <Input label="Group name" setValue={set_name} value={name} />
                                {loading && validation_errors.name && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.name}
                                    </p>
                                )}
                            </Col>
                            <Col xs={12} md={6} className="flex__jc-end__ai-center select-all ">
                                <Checkbox
                                    label="Select all modules permessions"
                                    checked={is_all_selected}
                                    onChange={(status) => onSelectAll(status)}
                                />
                                {loading && validation_errors.permissions && (
                                    <p style={{ color: 'red' }}>
                                        {validation_errors.permissions}
                                    </p>
                                )}
                            </Col>
                           
                        </Row>
                        <Row>
                            <Col>
                                {Object.keys(roles).length
                                    ? Object.keys(roles).map((role, i) => (
                                          <div key={i} className="single-module-permissions">
                                              <div className="head">
                                                  <span>{role}</span>
                                                  <div className="select-all">
                                                      <Checkbox
                                                          label={`Select all ${role} permissions`}
                                                          checked={selected_modules.includes(role)}
                                                          onChange={(status) =>
                                                              onSelectModule(
                                                                  status,
                                                                  Object.values(roles[role]),
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                              <Row>
                                                  {Object.keys(roles[role]).map((permission, i) => {
                                                      return (
                                                          <Col key={i} xs={6} md={4} lg={3}>
                                                              <Checkbox
                                                                  label={permission}
                                                                  checked={selected_roles.includes(
                                                                      roles[role][permission],
                                                                  )}
                                                                  onChange={(status) =>
                                                                      onSelectRole(
                                                                          status,
                                                                          roles[role][permission],
                                                                      )
                                                                  }
                                                              />
                                                          </Col>
                                                      );
                                                  })}
                                              </Row>
                                          </div>
                                      ))
                                    : 'loading....'}
                            </Col>
                        </Row>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
