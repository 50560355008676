import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const LogsSlice = createSlice({
    name: 'logs',
    initialState: {
        logs: [],
        logs_pages: null,
        logs_records: null,
        logs_loading: true,
        logs_filter_options: {},
        logs_filters_options_loading: true,
    },
    reducers: {
        setLogs(state, { payload }) {
            state.logs = payload;
        },
        setLogsFilterOptions(state, { payload }) {
            state.logs_filter_options = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setLogsTotalPages(state, { payload }) {
            state.logs_pages = payload;
        },
        setLogsRecords(state, { payload }) {
            state.logs_records = payload;
        },
    },
});

// export actions
export const { setLogs, setLogsFilterOptions, setLogsTotalPages, setLoading, setLogsRecords } =
    LogsSlice.actions;

export const loadLogsData =
    (params = {}) =>
    async (dispatch) => {
        dispatch(
            setLoading({
                key: 'logs_loading',
                value: true,
            }),
        );
        const res = await GET({
            endpoint: `/logs?${stringify(
                {
                    ...params,
                    records_per_page: 20,
                },
                {
                    skipNull: true,
                    skipEmptyString: true,
                },
            )}`,
        });
        dispatch(setLogs(res?.data || []));
        if (res?.meta) {
            dispatch(setLogsTotalPages(res?.meta?.last_page || 0));
            dispatch(
                setLogsRecords({
                    from: res?.meta?.from,
                    to: res?.meta?.to,
                    total: res?.meta?.total,
                }),
            );
        }
        dispatch(
            setLoading({
                key: 'logs_loading',
                value: false,
            }),
        );
    };

export const loadLogsFilterOptions = () => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'logs_filters_options_loading',
            value: true,
        }),
    );

    const res = await GET({
        endpoint: '/logs/filter-options',
    });
    res;

    dispatch(
        setLogsFilterOptions({
            auditable_types: [
                {
                    code: 'VendorCreateTopListRequest',
                    label: 'Merchant create top list request',
                },
                {
                    code: 'VendorChangeRequestState',
                    label: 'Merchant change request state',
                },
                {
                    code: 'VendorUpdateBankDetailsRequest',
                    label: 'Merchant update bank details request',
                },
                {
                    code: 'Suborder',
                    label: 'Suborder',
                },
                {
                    code: 'User',
                    label: 'User',
                },
                {
                    code: 'VendorUpdateProfileRequest',
                    label: 'Merchant update profile request',
                },
                {
                    code: 'Discount',
                    label: 'Discount',
                },
                {
                    code: 'SuborderChangeRequestProducts',
                    label: 'Suborder change request products',
                },
                {
                    code: 'Product',
                    label: 'Product',
                },
                {
                    code: 'SelfInvoiceCreditNote',
                    label: 'Self invoice credit note',
                },
                {
                    code: 'VendorProductActivationRequest',
                    label: 'Merchant product activation request',
                },
                {
                    code: 'Vendor',
                    label: 'Merchant',
                },
                {
                    code: 'Order',
                    label: 'Order',
                },
                {
                    code: 'VendorNotification',
                    label: 'Merchant notification',
                },
                {
                    code: 'SelfInvoice',
                    label: 'Self invoice',
                },
                {
                    code: 'VendorCreateDiscountRequest',
                    label: 'Merchant create discount request',
                },
                {
                    code: 'VendorInvoiceRequestState',
                    label: 'Merchant invoice request state',
                },
                {
                    code: 'VendorCreateProductRequest',
                    label: 'Merchant create product request',
                },
                {
                    code: 'VendorCreateBannerRequest',
                    label: 'Merchant create banner request',
                },
                {
                    code: 'VendorUpdateProductRequest',
                    label: 'Merchant update product request',
                },
                {
                    code: 'SuborderChangeRequest',
                    label: 'Suborder change request',
                },
                {
                    code: 'PaymentCycle',
                    label: 'Payment cycle',
                },
                {
                    code: 'RequestVendor',
                    label: 'Request merchant',
                },
                {
                    code: 'VendorDeactivateProductRequest',
                    label: 'Merchant deactivate product request',
                },
                {
                    code: 'VendorChangeRequest',
                    label: 'Merchant change request',
                },
                {
                    code: 'VendorCreateNotificationRequest',
                    label: 'Merchant create notification request',
                },
                {
                    code: 'Customer',
                    label: 'Retailer',
                },
                {
                    code: 'VendorInvoiceRequest',
                    label: 'Merchant invoice request',
                },
            ],
            user_types: [
                {
                    code: 'system',
                    label: 'System',
                },
                {
                    code: 'VendorUser',
                    label: 'Merchant user',
                },
                {
                    code: 'Customer',
                    label: 'Retailer',
                },
                {
                    code: 'User',
                    label: 'User',
                },
            ],
            events: ['deleted', 'created', 'updated'],
        }),
    );

    dispatch(
        setLoading({
            key: 'logs_filters_options_loading',
            value: false,
        }),
    );
};

export default LogsSlice.reducer;
