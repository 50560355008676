/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import { set_is_full_screen_view } from '../../store/slices/ui.slice';
import { Button, ButtonGroup } from '@components';
import { POST, DELETE } from '@utils';
import WarehouseLayout from './components/Layout';
import WarehouseTable from './components/WarehouseTable';
import { getGridCols } from '../../utils/warehouse/functions';
import useWarehouseQuery from '../../hooks/useWarehouseQuery';

export default function WarehouseEditView() {
    const dispatch = useDispatch();
    const [selected_cells, set_selected_cells] = React.useState([]);

    const {
        data: grid_data,
        loading,
        refetch
    } = useWarehouseQuery({ stopRefetching: true });

    React.useEffect(() => {
        dispatch(set_is_full_screen_view(true));

        return () => {
            dispatch(set_is_full_screen_view(false));
        };
    }, []);

    const gridCols = React.useMemo(() => getGridCols(grid_data), [grid_data]);

    const toggleCellStatus = async (status) => {
        await (status === 'open' ? POST : DELETE)({
            endpoint: '/warehouse/locations/status',
            data: { locations: selected_cells }
        });

        refetch();
        set_selected_cells([]);
    };

    return (
        <WarehouseLayout
            className="warehouse-grid-edit-mode"
            linkText="Go back warehouse grid"
            linkUrl="/warehouse/list"
            heading={<h1>Edit Warehouse Grid</h1>}
            isLoading={loading}
        >
            <div className="selected-cells-actions">
                <h2>Selected Cells ({selected_cells.length})</h2>
                <ButtonGroup>
                    <Button
                        onClick={() => toggleCellStatus('close')}
                        disabled={!selected_cells.length}
                        variant="danger"
                    >
                        Close selected cells
                    </Button>
                    <Button
                        onClick={() => toggleCellStatus('open')}
                        disabled={!selected_cells.length}
                        variant="success"
                    >
                        Open selected cells
                    </Button>
                </ButtonGroup>
            </div>

            <WarehouseTable
                cols={gridCols}
                data={grid_data}
                renderItem={(item, index) =>
                    renderItem(item, index, {
                        set_selected_cells,
                        selected_cells
                    })
                }
            />
        </WarehouseLayout>
    );
}

const renderItem = (loc, i, { set_selected_cells, selected_cells }) => {
    return (
        <td key={i} className="cell">
            <div
                onClick={() => {
                    if (selected_cells.includes(loc.id)) {
                        set_selected_cells(
                            selected_cells.filter((id) => id !== loc.id)
                        );
                    } else {
                        set_selected_cells([...selected_cells, loc.id]);
                    }
                }}
                className={
                    'cell-active-status-indicator ' +
                    (loc.is_active ? 'is-active' : 'is-inactive') +
                    ' ' +
                    (loc.is_allocated ? 'is-allocated' : 'is-not-allocated') +
                    ' ' +
                    (selected_cells.includes(loc.id) ? 'is-selected' : '')
                }
            />
        </td>
    );
};
