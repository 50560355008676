import { isDate, formatDate } from '@utils';

import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import defaultImg from '@assets/img/default.jpg';
import { Image } from '@components';

const renderValue = (value, { isImg, isVideo, imgAlt, isAttribute } = { isImg: false, imgAlt: '' }) => {
    if (isImg) return renderImage(value, imgAlt);

    if (isVideo) return renderVideo(value);

    if (isAttribute) return renderAttributes(value);

    if (Array.isArray(value)) return value.map((val) => renderValue(val)).join(' | ');

    if (value instanceof Object)
        return Object.entries(value)
            .map(([key, val]) =>
                ['name', 'name_en', 'description'].includes(key)
                    ? `${val}  `
                    : ['quantity', 'price'].includes(key)
                    ? `${key} : ${val}`
                    : null,
            )
            .filter(Boolean)
            .join(', ');

    if (typeof value === 'boolean') return <img src={value ? checkedIcon : cancelIcon} />;

    if (isDate(value)) return formatDate(value);

    return value || '--';
};

const renderImage = (value, alt) => {
    if (Array.isArray(value))
    
        return (
            <div className="grid grid-cols-5 gap-4">
                {value.map((image, index) => (
                    <div key={`${alt}-${index}`} className="flex__fd-column" style={{ border: '1px solid #000' }}>
                        {(image.action === 'delete' && !image?.asset && !image?.affected_media?.url) 
                            ?   <img src={defaultImg} width={150} height={100} style={{ margin: 'auto' }} />
                            :
                                <Image
                                    alt={`${alt}-${index}`}
                                    src={image?.asset?.url || image?.affected_media?.url || image.image || image || defaultImg}
                                    style={{ maxHeight: '120px', maxWidth: '150px', padding: '10px' }}
                                    showPreview
                                />
                        }
                        {image.action && <div>{image.action.replace('_', ' ')}</div>}
                    </div>
                ))}
            </div>
        );

    return (
        <Image
            src={value}
            showPreview
            style={{
                maxHeight: '120px',
                maxWidth: '150px',
                padding: '10px',
            }}
            alt={alt}
        />
    );
};

const renderVideo = (value) => {
    if (Array.isArray(value)) {
        return (
            <div className="grid grid-cols-5 gap-4">
                {value.map(video => {
                    const url = video?.asset?.url || video?.affected_media?.url;

                    if (url) {
                        return (
                            <a
                                key={video.id}
                                href={video?.asset?.url || video?.affected_media?.url}
                                className="video-card"
                                target='_blank'
                                rel="noreferrer"
                            >
                                VIDEO
                                <span>{video.action}</span>
                            </a>
                        );
                    }
                    
                    return (
                        <div className="video-card" key={video.id}>
                            VIDEO
                            <span>{video.action}</span>
                        </div>
                    );
                })}
            </div>
        );
    }

    if (value?.id) {
        return (
            <a
                href={value?.video}
                target='_blank'
                rel="noreferrer"
            >
                Play The Video
            </a>
        );
    }

    return (<div>--</div>);
};

const renderAttributes = (attributes) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Field</th>
                    <th>New Value</th>
                    <th>Old Value</th>
                </tr>
            </thead>
            <tbody>
                {attributes.map(attr => (
                    <tr key={attr.id}>
                        <td>{attr.name.replace('_', ' ')}</td>
                        <td>{attr.new_value}</td>
                        <td>{attr.old_value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default renderValue;
