import {
    ChildOrderStateFilter,
    PaymentStatusFilter,
    PaymentMethodsFilter,
    OrderStateFilter,
    ShipmentPreferencesFilter,
    CitiesFilter,
    AccountManagersFilter,
    PayLaterTypeFilter,
    IsCustomerFirstOrderFilter,
    DateFilter,
    APIAsyncFilter,
    PlatformsFilter,
    SourcesFilter,
    ScheduledOrderDeliveryOptionFilter,
    EntityTypesFilter,
    TotalFilter,
    ListOptions,
    VendorTypes,
    OrderSources,
    CustomerRefundReasons,
    TakeRateFilter,
    TotalOrders,
    WalletBalance,
    RegionsFilter,
    VendorRequestTypeFilter,
    AuditableTypeFilter,
    EventFilter,
    UserTypeFilter,
    InputFilter,
    FollowFilter,
    IsExclusiveFilter,
    CategoriesFilter,
    InvoicingModelsFilter,
} from './FiltersComponents';

import {
    ISSUE_TYPES,
    STATUS_OPTIONS,
    VERIFICATION_STATUSES,
    LEADS_STATUSES,
    REQUEST_STATUSES,
    REQUEST_VENDOR_STATUSES,
    YES_NO,
    INVOICE_STATUSES,
    PAYMENT_STATUS,
    STOCK_TYPES,
    TAGGED_OPTIONS,
    COLLECTION_AVAILABILITY,
    DEDUCTED_FROM,
    AMOUNT_TYPES,
    VENDOR_REQUEST_STATUSES,
    SEARCH_BY_OPTIONS,
    VENDOR_STATES,
    APP_FEEDBACK_TYPES,
    WALLET_WITHDRAWAL_REQUESTS_STATES,
    COLLECTION_TYPES,
    BUSINESS_MODELS,
    VERIFICATION_DECISION,
    VENDOR_PRODUCT_REQUEST_STATUSES,
    VENDOR_PRODUCT_REQUEST_TYPES,
    DELIVERY_PAYERS,
    WIRE_TRANSACTION_STATES
} from './constants';

const getAllPossibleOptions = (filter, setFilter) => [
    {
        key: 'order_states',
        label: 'Order State',
        filter: (
            <OrderStateFilter
                value={filter.order_states}
                set_value={(val) => setFilter('order_states', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'child_order_state',
        label: 'Child Order State',
        parmKey: 'suborder_states',
        filter: (
            <ChildOrderStateFilter
                value={filter.suborder_states}
                set_value={(val) => setFilter('suborder_states', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'order_payment_states',
        label: 'Payment Status',
        filter: (
            <PaymentStatusFilter
                value={filter.order_payment_states}
                set_value={(val) => setFilter('order_payment_states', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'payment_methods',
        label: 'Payment methods',
        filter: (
            <PaymentMethodsFilter
                value={filter.payment_methods}
                set_value={(val) => setFilter('payment_methods', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'shipment_preferences',
        label: 'Shipment preferences',
        filter: (
            <ShipmentPreferencesFilter
                value={filter.shipment_preferences}
                set_value={(val) => setFilter('shipment_preferences', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'cities',
        label: 'Cities',
        filter: (
            <CitiesFilter value={filter.cities} set_value={(val) => setFilter('cities', val)} />
        ),
        shortcut: false,
    },
    {
        key: 'city_id',
        label: 'Cities',
        filter: (
            <CitiesFilter value={filter.city_id} set_value={(val) => setFilter('city_id', val)} />
        ),
        shortcut: false,
    },
    {
        key: 'account_managers',
        label: 'Account Managers',
        filter: (
            <AccountManagersFilter
                value={filter.account_managers}
                set_value={(val) => setFilter('account_managers', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'account_manager_ids',
        label: 'Account Managers',
        filter: (
            <AccountManagersFilter
                value={filter.account_manager_ids}
                set_value={(val) => setFilter('account_manager_ids', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'pay_later_providers',
        label: 'Pay later providers',
        filter: (
            <PayLaterTypeFilter
                value={filter.pay_later_providers}
                set_value={(val) => setFilter('pay_later_providers', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'is_exclusive',
        label: 'Is Exclusive',
        filter: (
            <IsExclusiveFilter
                value={filter.is_exclusive}
                set_value={(val) => setFilter('is_exclusive', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'is_customer_first_order',
        label: 'Is Customer First Order',
        filter: (
            <IsCustomerFirstOrderFilter
                value={filter.is_customer_first_order}
                set_value={(val) => setFilter('is_customer_first_order', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'created_at_from',
        label: 'Created At From',
        filter: (
            <DateFilter
                value={filter.created_at_from}
                set_value={(val) => setFilter('created_at_from', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'created_at_to',
        label: 'Created At To',
        filter: (
            <DateFilter
                value={filter.created_at_to}
                set_value={(val) => setFilter('created_at_to', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'verification_date_from',
        label: 'Verification date From',
        filter: (
            <DateFilter
                value={filter.verification_date_from}
                set_value={(val) => setFilter('verification_date_from', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'verification_date_to',
        label: 'Verification date To',
        filter: (
            <DateFilter
                value={filter.verification_date_to}
                set_value={(val) => setFilter('verification_date_to', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'from',
        label: 'Created At From',
        filter: <DateFilter value={filter.from} set_value={(val) => setFilter('from', val)} />,
        shortcut: false,
    },
    {
        key: 'to',
        label: 'Created At To',
        filter: <DateFilter value={filter.to} set_value={(val) => setFilter('to', val)} />,
        shortcut: false,
    },
    {
        key: 'customers',
        label: 'Customers',
        filter: (
            <APIAsyncFilter
                api="/customers"
                placeholder="Type to add customers..."
                labelProperty={(r) => r.store_name}
                value={filter.customers}
                set_value={(val) => setFilter('customers', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'customer',
        label: 'Customer',
        filter: (
            <APIAsyncFilter
                api="/customers"
                placeholder="Type to add customer..."
                labelProperty={(r) => r.store_name}
                value={filter.customer_id}
                set_value={(val) => setFilter('customer_id', val)}
                isMulti={false}
              
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendors',
        label: 'Merchant',
        filter: (
            <APIAsyncFilter
                api="/vendors"
                placeholder="Search by merchant name or id..."
                labelProperty={(r) => r.company_name_en}
                value={filter.vendors}
                set_value={(val) => setFilter('vendors', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor',
        label: 'Merchant',
        filter: (
            <APIAsyncFilter
                api="/vendors"
                placeholder="Type to Add Merchant..."
                labelProperty={(r) => r.company_name_en}
                value={filter.vendor_id}
                set_value={(val) => setFilter('vendor_id', val)}
                isMulti={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor_user_id',
        label: 'Merchants Users',
        filter: (
            <APIAsyncFilter
                api="/vendorUsers"
                placeholder="Search by merchant name or id..."
                labelProperty={(r) => `${r.first_name} ${r.last_name}`}
                value={filter.vendor_user_id}
                set_value={(val) => setFilter('vendor_user_id', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'product',
        label: 'Product',
        filter: (
            <APIAsyncFilter
                api="/product"
                placeholder="Type to add product..."
                labelProperty={(r) => r.name}
                value={filter.product_id}
                set_value={(val) => setFilter('product_id', val)}
                isMulti={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'suborder',
        label: 'Child ID',
        filter: (
            <APIAsyncFilter
                api="/suborder"
                placeholder="Type to add child order..."
                labelProperty={(r) => r.pretty_id}
                value={filter.suborder_id}
                set_value={(val) => setFilter('suborder_id', val)}
                isMulti={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'acquisition_users',
        label: 'Acquisition Users',
        filter: (
            <APIAsyncFilter
                api="/acquisition/acquisitions"
                placeholder="Type to add acquisition users..."
                labelProperty={(r) => `${r.first_name} ${r.last_name}`}
                value={filter.acquisition_users}
                set_value={(val) => setFilter('acquisition_users', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'platforms',
        label: 'Platforms',
        filter: (
            <PlatformsFilter
                value={filter.platforms}
                set_value={(val) => setFilter('platforms', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'sources',
        label: 'Sources',
        filter: (
            <SourcesFilter value={filter.sources} set_value={(val) => setFilter('sources', val)} />
        ),
        shortcut: false,
    },
    {
        key: 'scheduled_order_delivery_option_id',
        label: 'Scheduled Order Delivery Option',
        filter: (
            <ScheduledOrderDeliveryOptionFilter
                value={filter.scheduled_order_delivery_option_id}
                set_value={(val) => setFilter('scheduled_order_delivery_option_id', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'entity_types',
        label: 'Entity Types',
        filter: (
            <EntityTypesFilter
                value={filter.entity_types}
                set_value={(val) => setFilter('entity_types', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'total',
        label: 'Total',
        filter: (
            <TotalFilter
                value={filter.entity_types}
                set_value={({ from, to }) => {
                    setFilter(['total_from', 'total_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'total_spent',
        label: 'Total Spent',
        filter: (
            <TotalFilter
                value={{ from: filter.total_spent_from, to: filter.total_spent_to }}
                set_value={({ from, to }) => {
                    setFilter(['total_spent_from', 'total_spent_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'issue_type',
        label: 'Issue type',
        filter: (
            <ListOptions
                value={filter.issue_type}
                set_value={(val, label) => setFilter('issue_type', val, label)}
                options={ISSUE_TYPES}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'status',
        label: 'Status',
        filter: (
            <ListOptions
                value={filter.status}
                set_value={(val, label) => setFilter('status', val, label)}
                options={STATUS_OPTIONS}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'verification_statuses',
        label: 'Verification Statuses',
        filter: (
            <ListOptions
                value={filter.verification_statuses}
                set_value={(val, label) => setFilter('verification_statuses', val, label)}
                options={VERIFICATION_STATUSES}
                searchable={false}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'verification_state',
        label: 'Verification State',
        filter: (
            <ListOptions
                value={filter.verification_state}
                set_value={(val, label) => setFilter('verification_state', val, label)}
                options={VERIFICATION_STATUSES}
                searchable={false}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'verification_decision',
        label: 'Verification Decision',
        filter: (
            <ListOptions
                value={filter.verification_decision}
                set_value={(val, label) => setFilter('verification_decision', val, label)}
                options={VERIFICATION_DECISION}
                searchable={false}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'has_wallet_balance',
        label: 'Has wallet balance',
        filter: (
            <ListOptions
                value={filter.has_wallet_balance}
                set_value={(val, label) => setFilter('has_wallet_balance', val, label)}
                options={YES_NO}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor_types',
        label: 'Merchant Types',
        filter: (
            <VendorTypes
                value={filter.vendor_types}
                set_value={(val) => setFilter('vendor_types', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'categories',
        label: 'Categories',
        filter: (
            <CategoriesFilter
                value={filter.categories}
                set_value={(val) => setFilter('categories', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'invoicing_models',
        label: 'Invoice Model',
        filter: (
            <InvoicingModelsFilter
                value={filter.invoicing_models}
                set_value={(val) => setFilter('invoicing_models', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'leads_statuses',
        parmKey: 'status',
        label: 'Status',
        filter: (
            <ListOptions
                value={filter.status}
                set_value={(val, label) => setFilter('status', val, label)}
                options={LEADS_STATUSES}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'request_statuses',
        parmKey: 'status',
        label: 'Status',
        filter: (
            <ListOptions
                value={filter.status}
                set_value={(val, label) => setFilter('status', val, label)}
                options={REQUEST_STATUSES}
            />
        ),
        shortcut: false,
    },
    {
        key: 'request_vendor_statuses',
        parmKey: 'states',
        label: 'Status',
        filter: (
            <ListOptions
                value={filter.states}
                set_value={(val) => setFilter('states', val)}
                options={REQUEST_VENDOR_STATUSES}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'has_picture',
        label: 'Has Picture',
        filter: (
            <ListOptions
                value={filter.has_picture}
                set_value={(val, label) => setFilter('has_picture', val, label)}
                options={YES_NO}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'invoice_states',
        parmKey: 'states',
        label: 'States',
        filter: (
            <ListOptions
                value={filter.states}
                set_value={(val, label) => setFilter('states', val, label)}
                options={INVOICE_STATUSES}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'wallet_withdrawal_requests_states',
        parmKey: 'states',
        label: 'States',
        filter: (
            <ListOptions
                value={filter.states}
                set_value={(val, label) => setFilter('states', val, label)}
                options={WALLET_WITHDRAWAL_REQUESTS_STATES}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'order_sources',
        parmKey: 'sources',
        label: 'Sources',
        filter: (
            <OrderSources value={filter.sources} set_value={(val) => setFilter('sources', val)} />
        ),
        shortcut: false,
    },
    {
        key: 'is_paid',
        label: 'Payment Status',
        filter: (
            <ListOptions
                value={filter.is_paid}
                set_value={(val, label) => setFilter('is_paid', val, label)}
                options={PAYMENT_STATUS}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'ready_to_pay',
        label: 'Ready To Pay',
        filter: (
            <ListOptions
                value={filter.ready_to_pay}
                set_value={(val, label) => setFilter('ready_to_pay', val, label)}
                options={YES_NO}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'has_credit_notes',
        label: 'Has Credit Notes',
        filter: (
            <ListOptions
                value={filter.has_credit_notes}
                set_value={(val, label) => setFilter('has_credit_notes', val, label)}
                options={YES_NO}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'stock_type',
        label: 'Stock Type',
        filter: (
            <ListOptions
                value={filter.stock_type}
                set_value={(val, label) => setFilter('stock_type', val, label)}
                options={STOCK_TYPES}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'tagged',
        label: 'Tagged',
        filter: (
            <ListOptions
                value={filter.tagged}
                set_value={(val, label) => setFilter('tagged', val, label)}
                options={TAGGED_OPTIONS}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'market_availability',
        label: 'Collection availability',
        filter: (
            <ListOptions
                value={filter.market_availability}
                set_value={(val, label) => setFilter('market_availability', val, label)}
                options={COLLECTION_AVAILABILITY}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'deducted_from',
        label: 'Deducted From',
        filter: (
            <ListOptions
                value={filter.deduct_from}
                set_value={(val, label) => setFilter('deduct_from', val, label)}
                options={DEDUCTED_FROM}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'amount_type',
        label: 'Amount Type',
        filter: (
            <ListOptions
                value={filter.reason}
                set_value={(val, label) => setFilter('reason', val, label)}
                options={AMOUNT_TYPES}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'refund_reason',
        label: 'Reasons',
        filter: (
            <CustomerRefundReasons
                value={filter.refund_reason}
                set_value={(val) => setFilter('refund_reason', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'scheduled_order',
        parmKey: 'scheduled_order_id',
        label: 'Scheduled Order',
        filter: (
            <APIAsyncFilter
                api="/scheduled-order"
                placeholder="Type to add acquisition users..."
                labelProperty={(r) => r.title_en}
                value={filter.scheduled_order_id}
                set_value={(val) => setFilter('scheduled_order_id', val)}
                isMulti={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'take_rate',
        label: 'Take Rate',
        filter: (
            <TakeRateFilter
                value={filter.entity_types}
                set_value={({ from, to }) => {
                    setFilter(['take_rate_from', 'take_rate_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'take_rate_amount',
        label: 'Take Rate Amount',
        filter: (
            <TakeRateFilter
                value={filter.entity_types}
                set_value={({ from, to }) => {
                    setFilter(['take_rate_amount_from', 'take_rate_amount_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'price',
        label: 'price',
        filter: (
            <TakeRateFilter
                value={filter.price}
                set_value={({ from, to }) => {
                    setFilter(['price_from', 'price_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'profit_margin',
        label: 'Profit margin',
        filter: (
            <TakeRateFilter
                value={filter.profit_margin}
                set_value={({ from, to }) => {
                    setFilter(['profit_margin_from', 'profit_margin_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'discount_value',
        label: 'Discount value',
        filter: (
            <TakeRateFilter
                value={filter.discount_value}
                set_value={({ from, to }) => {
                    setFilter(['discount_value_from', 'discount_value_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'discount_percentage',
        label: 'Discount percentage',
        filter: (
            <TakeRateFilter
                value={filter.discount_percentage}
                set_value={({ from, to }) => {
                    setFilter(['discount_percentage_from', 'discount_percentage_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'total_orders',
        label: 'Total Orders',
        filter: (
            <TotalOrders
                value={{ from: filter.min_total_orders, to: filter.max_total_orders }}
                set_value={({ from, to }) => {
                    setFilter(['min_total_orders', 'max_total_orders'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'fulfilled_orders',
        label: 'Fulfilled Orders',
        filter: (
            <TotalOrders
                value={{
                    from: filter.min_fulfilled_orders,
                    to: filter.max_fulfilled_orders,
                }}
                set_value={({ from, to }) => {
                    setFilter(['min_fulfilled_orders', 'max_fulfilled_orders'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor_request_type',
        parmKey: 'types',
        label: 'Request Type',
        filter: (
            <VendorRequestTypeFilter
                value={filter.types}
                set_value={(val) => setFilter('types', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'search_by',
        label: 'Search By',
        filter: (
            <ListOptions
                value={filter.search_by || 'product_name'}
                set_value={(val, label) => setFilter('search_by', val, label)}
                options={SEARCH_BY_OPTIONS}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'average_rating',
        label: 'Average Rating',
        filter: (
            <TotalOrders
                value={{
                    from: filter.min_average_rating,
                    to: filter.max_average_rating,
                }}
                set_value={({ from, to }) => {
                    setFilter(['min_average_rating', 'max_average_rating'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor_request_status',
        parmKey: 'state',
        label: 'Status',
        filter: (
            <ListOptions
                value={filter.state}
                set_value={(val) => setFilter('state', val)}
                options={VENDOR_REQUEST_STATUSES}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor_product_request_status',
        parmKey: 'state',
        label: 'Status',
        filter: (
            <ListOptions
                value={filter.state}
                set_value={(val) => setFilter('state', val)}
                options={VENDOR_PRODUCT_REQUEST_STATUSES}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'wallet_balance',
        label: 'Wallet Balance',
        filter: (
            <WalletBalance
                value={{ min: filter.min, max: filter.max }}
                set_value={({ min, max }) => {
                    setFilter(['min_wallet_balance', 'max_wallet_balance'], [min, max]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor_states',
        parmKey: 'states',
        label: 'Merchant State',
        filter: (
            <ListOptions
                value={filter.states}
                set_value={(val, label) => setFilter('states', val, label)}
                options={VENDOR_STATES}
                searchable={false}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'is_active',
        parmKey: 'active',
        label: 'Is Active',
        filter: (
            <ListOptions
                value={filter.active}
                set_value={(val, label) => setFilter('active', val, label)}
                options={YES_NO}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'regions',
        label: 'Regions',
        filter: (
            <RegionsFilter value={filter.regions} set_value={(val) => setFilter('regions', val)} />
        ),
        shortcut: false,
    },
    {
        key: 'app_feedback_type',
        label: 'Issue type',
        parmKey: 'type',
        filter: (
            <ListOptions
                value={filter.type}
                set_value={(val) => setFilter('type', val)}
                options={APP_FEEDBACK_TYPES}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'event',
        label: 'Event',
        filter: <EventFilter value={filter.event} set_value={(val) => setFilter('event', val)} />,
        shortcut: false,
    },
    {
        key: 'auditable_type',
        label: 'Auditable Type',
        filter: (
            <AuditableTypeFilter
                value={filter.auditable_type}
                set_value={(val) => setFilter('auditable_type', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'user_type',
        label: 'User Type',
        filter: (
            <UserTypeFilter
                value={filter.user_type}
                set_value={(val) => setFilter('user_type', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'auditable_id',
        label: 'Search by Auditable Id',
        filter: (
            <InputFilter
                value={filter.auditable_id}
                set_value={(val) => setFilter('auditable_id', val)}
            />
        ),
        shortcut: false,
    },
    {
        key: 'user_id',
        label: 'Search by User Id',
        filter: (
            <InputFilter value={filter.user_id} set_value={(val) => setFilter('user_id', val)} />
        ),
        shortcut: false,
    },
    {
        key: 'collection_type',
        label: 'Type',
        parmKey: 'preview_type',
        filter: (
            <ListOptions
                value={filter.preview_type}
                set_value={(val) => setFilter('preview_type', val)}
                options={COLLECTION_TYPES}
                searchable={false}
            />
        ),
    },
    {
        key: 'follows_count',
        label: 'Follows Count',
        hideClearButton: true,
        filter: (
            <FollowFilter
                value={{ from: filter.follows_count_from, to: filter.follows_count_to }}
                set_value={({ from, to }) => {
                    setFilter(['follows_count_from', 'follows_count_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'followers_count',
        label: 'Followers Count',
        hideClearButton: true,
        filter: (
            <FollowFilter
                value={{ from: filter.followers_count_from, to: filter.followers_count_to }}
                set_value={({ from, to }) => {
                    setFilter(['followers_count_from', 'followers_count_to'], [from, to]);
                }}
            />
        ),
        shortcut: false,
    },
    {
        key: 'business_model',
        label: 'Business Model',
        parmKey: 'business_models',
        filter: (
            <ListOptions
                value={filter.business_models}
                set_value={(val, label) => setFilter('business_models', val, label)}
                options={BUSINESS_MODELS}
                searchable={false}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'vendor_product_request_types',
        label: 'Request Type',
        parmKey: 'type',
        filter: (
            <ListOptions
                value={filter.type}
                set_value={(val, label) => setFilter('type', val, label)}
                options={VENDOR_PRODUCT_REQUEST_TYPES}
                searchable={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'delivery_fees_payer',
        label: 'Delivery Charge',
        parmKey: 'delivery_fees_payer',
        filter: (
            <ListOptions
                value={filter.delivery_fees_payer}
                set_value={(val, label) => setFilter('delivery_fees_payer', val, label)}
                options={DELIVERY_PAYERS}
                searchable={false}
                isMulti
            />
        ),
        shortcut: false,
    },
    {
        key: 'purchase_order',
        label: 'Purchase order',
        filter: (
            <APIAsyncFilter
                api="/vendors/invoice-requests"
                placeholder="Search by purchase order number..."
                labelProperty={(r) => r.pretty_id}
                value={filter.purchase_order}
                set_value={(val) => setFilter('purchase_order', val)}
                isMulti={false}
            />
        ),
        shortcut: false,
    },

    {
        key: 'bannerPosition',
        label: 'Banner Type',
        filter: (
          <APIAsyncFilter
                api="/marketing/banners/banner-position-list"
                labelProperty={(r) => r.name_en}
                value={filter.bannerPosition}
                set_value={(val) => setFilter('bannerPosition', val) } 
                isMulti={false}
            />
        ),
        shortcut: false,
    },
    {
        key: 'wire_transaction_states',
        label: 'Status',
        parmKey: 'status',
        filter: (
            <ListOptions
                value={filter.status}
                set_value={(val) => setFilter('status', val)}
                options={WIRE_TRANSACTION_STATES}
                searchable={false}
            />
        ),
    },
   
];

export default getAllPossibleOptions;
