/* eslint-disable no-unused-vars */
import React from 'react';
import {
    ViewHeader,
    Link,
    DataTable,
    Card,
    Filters,
    PaymentMethodIcons,
    StatusBlock,
    WhatsApp,
    Dialer,
} from '@components';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadWireTransactionsData } from '@slices/wireTransactions.slice';
import { formatDate, formatMoney, GET } from '@utils';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function WireTranscationListView() {
    const WireTransactionsSlice = useSelector((state) => state.WireTransactionsSlice);
    const dispatch = useDispatch();
    const AuthSlice = useSelector((state) => state.AuthSlice);
    const [wire_transactions, set_wire_transactions] = React.useState([]);
    React.useEffect(() => {
        const fetchData = async () => {
            const data = await GET({
                endpoint: '/payment/transactions/new-wire',
            });

            set_wire_transactions(data?.data || []);
        };
          fetchData();
     }, []);

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});
    const location = useLocation();
    const history = useHistory();
    const handleFilterChange = (filters_values) => {
        set_filters(filters_values);
        set_page(1); // Reset page to 1 when filters change
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', 1);
        history.push({ search: queryParams.toString() });
    };

    useDidMountEffect(() => {
        dispatch(loadWireTransactionsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);
    const getStatusColor = (status) => {
        switch (status) {
            case 'PENDING':
                return '#e59332';
            case 'SUCCESS':
                return '#43aa2b';
            case 'REJECTED':
                return '#cd5959';

            default:
                return '#6c757d';
        }
    };
    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
            center: true,
            cell: (row) => <Link href={`/wire-transactions/${row.id}`}>#{row.id}</Link>,
        },

        {
            name: 'Bank name',
            selector: 'bank.name',
        },
        {
            name: 'Total amount',
            selector: 'amount',
            minWidth: '120px',
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.amount)}</div>
                </div>
            ),
        },

        {
            name: 'Status',
            selector: 'status',
            width: '180px',
            cell: (row) => <p style={{ color: getStatusColor(row?.status) }}>{row?.status} </p>,
        },

        {
            name: 'Transaction Date',
            selector: 'transfer_date',
            minWidth: '150px',
            format: (r) => formatDate(r?.transfer_date),
        },
        {
            name: 'Show details',
            cell: (row) => (
                <Link style={{ color: '#4356fd' }} href={`/wire-transactions/${row.id}`}>
                    Preview details
                </Link>
            ),
        },
    ];

    return (
        <div className="customers-list-view">
            <ViewHeader title=" Wire transactions">
                <div className="flex__ai-center">
                    <Link href="/wire-transactions/add" className="btn btn-primary">
                        New Wire Transaction
                    </Link>
                </div>
            </ViewHeader>

            <Card>
                {/* {JSON.stringify(filters, null, 2)} */}
                <Filters
                    queryPlaceholder="Search by id"
                    filters={['wire_transaction_states', 'from', 'to']}
                    // onFilterChange={(filters_values) => {
                    //     set_filters(filters_values);
                    // }}
                    onFilterChange={handleFilterChange} // Use the handleFilterChange function here

                    initialFiltersValues={{
                        sort_by: 'id',
                    }}
                    sort_options={[
                        { label: 'ID', value: 'id', sort_direction: 'asc' },
                        {
                            label: 'Status',
                            value: 'status',
                            sort_direction: 'asc',
                        },
                        {
                            label: 'Amount',
                            value: 'amount',
                            sort_direction: 'desc',
                        },
                        // {
                        //     label: ' Total received amount.',
                        //     value: ' total_received_amount.',
                        //     sortDirection: 'asc',
                        // },
                    ]}
                />
                <DataTable
                    data={WireTransactionsSlice.wire_transactions}
                    pages={WireTransactionsSlice.wire_transactions_pages}
                    page={page}
                    records={WireTransactionsSlice.wire_transactions_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={WireTransactionsSlice.wire_transactions_loading}
                   
                />
            </Card>
        </div>
    );
}
