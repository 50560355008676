import React from 'react';
import { Editor, ViewHeader, Input, Card, Sop } from '@components';
import { POST, EditorStateToRawHTML } from '@utils';
import { Redirect } from 'react-router';

function AppInstructionsAddView() {
    const [instruction_ar, set_instruction_ar] = React.useState('');
    const [instruction_en, set_instruction_en] = React.useState('');
    const [errors, set_errors] = React.useState([]);
    const [created, set_created] = React.useState(null);
    const [title, set_title] = React.useState({
        label: 'Title',
        value: '',
        setValue: (val) => set_title({ ...title, value: val })
    });

    const submit = async () => {
        const res = await POST({
            endpoint: '/app-instruction',
            data: {
                title: title.value,
                content_ar: EditorStateToRawHTML(instruction_ar),
                content_en: EditorStateToRawHTML(instruction_en)
            }
        });
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_errors([]);
            set_created(res?.data);
        } else {
            set_errors(res?.data?.errors);
        }
    };

    if (created) {
        return <Redirect to="/app-instructions/list" />;
    }
    return (
        <>
            <ViewHeader title="Create new instruction">
                <Sop id="30123" />
                <button className="btn btn-primary" onClick={submit}>
                    Submit
                </button>
            </ViewHeader>
            <Card>
                <div className="px-3 py-2">
                    {!!Object.keys(errors).length && (
                        <div className="alert alert-danger">
                            <ul>
                                {Object.keys(errors).map((key) => {
                                    return <li key={key}>{errors[key]}</li>;
                                })}
                            </ul>
                        </div>
                    )}
                    <Input {...title} />
                    <div className="">
                        <h3 className="divider-title">
                            Instructions Content ( AR )
                        </h3>
                        <Editor
                            setValue={set_instruction_ar}
                            value={instruction_ar}
                        />
                    </div>
                    <div className="mt-2">
                        <h3 className="divider-title">
                            Instructions Content ( EN )
                        </h3>
                        <Editor
                            setValue={set_instruction_en}
                            value={instruction_en}
                        />
                    </div>
                </div>
            </Card>
        </>
    );
}

export default AppInstructionsAddView;
