import React from 'react';
import {
    ViewHeader,
    Link,
    Card,
    DataTable,
    StatusToggler,
    WhatsApp,
    Dialer,
    Filters,
    Sop
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadVendorUsersData, updateSingleRow } from '@slices/merchantUsers.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function VendorUsersListView() {

    const VendorUsersSlice = useSelector((state) => state.VendorUsersSlice);
    const dispatch = useDispatch();

    const [filters, set_filters] = React.useState({});
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    useDidMountEffect(() => {
        dispatch(loadVendorUsersData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'First name',
            selector: 'first_name',
            cell: (row) => (
                <Link href={`/merchant-users/${row.id}`} className="tr-name">
                    <h5>{row.first_name}</h5>
                </Link>
            ),
        },
        {
            name: 'Last name',
            selector: 'last_name',
        },
        {
            name: 'Mobile',
            selector: 'mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />
        },
        {
            name: 'Whatsapp',
            selector: 'whatsapp',
            width: '70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />
        },
        {
            name: 'Email',
            selector: 'email',
            minWidth: '180px',
            cell: (row) => (<a href={`mailto:${row?.email}`}>
                {row?.email}</a>)
        },
        {
            name: 'Card Number',
            selector: 'id_card_number',
        },
        {
            name: 'Number of Merchants',
            selector: 'number_of_vendors',
            cell: (row) => (
                <>
                    <p>{row.vendors.length}</p>
                </>
            )
        },
        {
            name: 'Super user',
            selector: 'is_super_vendor_user',
            sortable: false,
            center: true,
            cell: (row) => row.is_super_vendor_user ? 'Yes' : ''
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row.is_active}
                    path={`/vendorUsers/${row.id}/activate`}
                    pathOff={`/vendorUsers/${row.id}/deactivate`}
                    method="post"
                    callback={({ id, status }) => {
                        dispatch(updateSingleRow({ id, is_active: status }));
                    }}
                />
            ),
        },
    ];

    return (
        <div className="vendor-users-list-view">
            <ViewHeader title="Merchant Users">
                <Sop id="29863" />
                <Link href="/merchant-users/add" className="btn btn-primary">
                    New user
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    queryPlaceholder="Search by First name, Last name, Mobile, E-mail"
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={VendorUsersSlice.vendorUsers}
                    pages={VendorUsersSlice.vendorUsers_pages}
                    page={page}
                    records={VendorUsersSlice.vendorUsers_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={VendorUsersSlice.vendorUsers_loading}
                />
            </Card>
        </div>
    );
}
