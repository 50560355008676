import React from 'react';
import {
    ViewHeader,
    FileUpload,
    Card,
    Row,
    Col,
    Input,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Modal,
    ShowErrors,
    CriticalActionButton,
    Sop,
} from '@components';
import { obj2formdata, POST, DELETE, isEnglish, isArabic } from '@utils';
import { useParams, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadBrandData } from '@slices/brands.slice';
import { setModalStatus } from '@slices/ui.slice';
import { setBrand } from '../../store/slices/brands.slice';

export default function BrandsUpdateView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const BrandsSlice = useSelector((state) => state.BrandsSlice);
    const [loading, set_loading] = React.useState(false);
    const [submitting, set_submitting] = React.useState(false);
    const [updated, set_updated] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [deleted, set_deleted] = React.useState(false);
    const [current_logo_ar, set_current_logo_ar] = React.useState('');
    const [current_logo_en, set_current_logo_en] = React.useState('');

    React.useEffect(() => {
        dispatch(setBrand({}));
        dispatch(loadBrandData(id));
    }, []);

    React.useEffect(() => {
        if (BrandsSlice.brand.id) {
            name_ar.setValue(BrandsSlice.brand.name_ar);
            name_en.setValue(BrandsSlice.brand.name_en);
            set_current_logo_ar(BrandsSlice.brand.logo_ar);
            set_current_logo_en(BrandsSlice.brand.logo_en);
        }
    }, [BrandsSlice.brand.id]);

    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => (isArabic(val) ? set_name_ar({ ...name_ar, value: val }) : ''),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_name_en({ ...name_en, value: val }) : ''),
    });

    const [logo_ar, set_logo_ar] = React.useState({
        label: 'Logo (arabic)',
        files: [],
        setFiles: (val) => set_logo_ar({ ...logo_ar, files: val }),
    });
    const [logo_en, set_logo_en] = React.useState({
        label: 'Logo (english)',
        files: [],
        setFiles: (val) => set_logo_en({ ...logo_en, files: val }),
    });
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name_ar.value) newErrors.name_ar = 'The name (arabic) field is required.';
        if (!name_en.value) newErrors.name_en = 'The name (english) field is required.';
        if (!current_logo_ar && !logo_ar.files.length) newErrors.logo_ar = 'The logo (arabic) field is required.';
        if (!current_logo_en && !logo_en.files.length) newErrors.logo_en = 'The logo (english) field is required.';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    React.useEffect(() => {
        validate();
    }, [name_ar, name_en, logo_ar, logo_en,current_logo_ar, current_logo_en]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const data = obj2formdata({
                name_ar: name_ar.value,
                name_en: name_en.value,
            });

            logo_ar.files.length && data.append('logo_ar', logo_ar.files[0]);
            logo_en.files.length && data.append('logo_en', logo_en.files[0]);

            data.append('_method', 'PUT');

            const res = await POST({
                endpoint: `/brand/${id}`,
                data,
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_updated(true);
            }
        }
    };

    const deleteBrand = async () => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/brand/${id}`,
        });
        set_submitting(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status === 500) {
            setErrors({ message: [res?.data.message] });
        } else if (res?.status_code === 200 || res?.status_code === 201) {
            set_deleted(true);
        }
    };

    if (updated || deleted) {
        return <Redirect to="/brands/list" />;
    }

    if (BrandsSlice.brand_loading) {
        return 'loading...';
    }

    return (
        <div className="brands-form-view">
            <ViewHeader title={`Update brand ${BrandsSlice.brand.name_ar}`} renderBackArrow>
                <Sop id="30023" />
                <ButtonGroup>
                    <DropdownButton
                        as={ButtonGroup}
                        title="Actions"
                        id="bg-nested-dropdown"
                        variant="light"
                    >
                        <Dropdown.Item
                            eventKey="2"
                            onClick={() =>
                                dispatch(
                                    setModalStatus({
                                        modal: 'single_brand_view__delete_modal',
                                        status: true,
                                    }),
                                )
                            }
                        >
                            Delete
                        </Dropdown.Item>
                    </DropdownButton>
                </ButtonGroup>

                <CriticalActionButton onSubmit={submit}>Save changes</CriticalActionButton>
            </ViewHeader>

            {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

            <Card>
                <div className="px-3 py-2">
                    <Row>
                        <Col lg={6}>
                            <Input {...name_ar} />
                            {loading && validation_errors.name_ar && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.name_ar}
                                </p>
                            )}
                            <Input {...name_en} />
                            {loading && validation_errors.name_en && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.name_en}
                                </p>
                            )}
                        </Col>
                        <Col lg={6}>
                            <FileUpload {...logo_ar} />
                            {loading && validation_errors.logo_ar && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.logo_ar}
                                </p>
                            )}
                            <ul className="thumpnails">
                                <li>
                                    <img src={current_logo_ar} />
                                </li>
                            </ul>

                            <FileUpload {...logo_en} />
                            {loading && validation_errors.logo_en && (
                                <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                    {validation_errors.logo_en}
                                </p>
                            )}
                            <ul className="thumpnails">
                                <li>
                                    <img src={current_logo_en} />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Card>

            <Modal
                title={`Delete ${BrandsSlice.brand.name_ar}?`}
                name="single_brand_view__delete_modal"
                variant="danger"
                onSubmit={(closeModal) => {
                    deleteBrand();
                    closeModal('single_brand_view__delete_modal');
                }}
                loading={submitting}
            />
        </div>
    );
}
