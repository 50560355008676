import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import VendorCategoriesListView from './MerchantCategoriesListView';
import VendorCategoriesAddView from './MerchantCategoriesAddView';
import VendorCategoriesUpdateView from './MerchantCategoriesUpdateView';
import { Helmet } from 'react-helmet';

export default function VendorCategoriesModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/merchant-categories/list">
                <Helmet>
                    <title>Merchant Categories | Distydmin Dashboard</title>
                </Helmet>
                <VendorCategoriesListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/merchant-categories/add">
                <Helmet>
                    <title>Add Merchant Category | Distydmin Dashboard</title>
                </Helmet>
                <VendorCategoriesAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/merchant-categories/:id">
                <Helmet>
                    <title>Edit Merchant Category | Distydmin Dashboard</title>
                </Helmet>
                <VendorCategoriesUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
