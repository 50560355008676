import React from 'react';
import { Spinner } from '@components';

export default function ListComponent({
    title = '',
    data = [],
    loading = false,
    show_tiers_widget = false,
    set_show_tiers_widget,
}) {
    return (
        <div
            className={`tiers-widget ${
                !show_tiers_widget && !!set_show_tiers_widget ? 'closed' : ''
            }`}
        >
            <div
                className="tiers-widget__header"
                onClick={() => set_show_tiers_widget(!show_tiers_widget)}
            >
                <h3>{title}</h3>
                {set_show_tiers_widget && (
                    <span className="hide-toggler">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-chevron-down"
                        >
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </span>
                )}
            </div>
            <ul>
                {loading ? (
                    <Spinner />
                ) : (
                    data.slice(0, 10).map((c) => (
                        <li key={c.id}>
                            <span className="name">{c.name}</span>
                            <span className="count">{c.total_orders} طلب</span>
                        </li>
                    ))
                )}

                {/* {set_show_tiers_widget && (
                    <li
                        onClick={() => {
                            set_show_tiers_widget(!show_tiers_widget);
                            document.querySelector('.stats').scrollIntoView({
                                behavior: 'smooth',
                            });
                        }}
                        className="show-more"
                    >
					إظهار الكل ({data.length})
                    </li>
                )} */}
            </ul>
        </div>
    );
}
