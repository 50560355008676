import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const VendorUsersSlice = createSlice({
    name: 'vendorUsers',
    initialState: {
        vendorUsers: [],
        vendorUsers_pages: null,
        vendorUsers_records: null,
        vendorUsers_loading: true,
        vendorUser: {},
        vendorUser_loading: true,
    },
    reducers: {
        setVendorUsers(state, { payload }) {
            state.vendorUsers = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.vendorUsers.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.vendorUsers[index] = {
                    ...state.vendorUsers[index],
                    ...payload,
                };
            }
        },
        setVendorUser(state, { payload }) {
            state.vendorUser = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVendorUsersTotalPages(state, { payload }) {
            state.vendorUsers_pages = payload;
        },
        setVendorUsersRecords(state, { payload }) {
            state.vendorUsers_records = payload;
        },
    },
});

// export actions
export const {
    setVendorUsers,
    updateSingleRow,
    setVendorUser,
    setLoading,
    setVendorUsersTotalPages,
    setVendorUsersRecords,
} = VendorUsersSlice.actions;

export const loadVendorUsersData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendorUsers_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendorUsers?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`
    });
    dispatch(setVendorUsers(res?.data || []));
    dispatch(setVendorUsersTotalPages(res?.meta?.last_page || 1));
    if(res?.meta){
        dispatch(setVendorUsersRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'vendorUsers_loading',
            value: false,
        })
    );
};

export const loadVendorUserData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendorUser_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendorUsers/${id}`,
    });
    dispatch(setVendorUser(res?.data || {}));
    dispatch(
        setLoading({
            key: 'vendorUser_loading',
            value: false,
        })
    );
};

export default VendorUsersSlice.reducer;
