import { Link } from 'react-router-dom';
import { formatDate } from '@utils';
import { StatusBlock, Price } from '@components';

const useColumns = ({ isSubordersInRetail }) => [
    {
        name: 'Child Order ID',
        width: '120px',
        cell: (row) => (
            <Link
                to={`/orders/${row.suborder_pretty_id.split('-')[0]}/${row.suborder_pretty_id}`}
                target="_blank"
            >
                #{row.suborder_pretty_id}
            </Link>
        ),
    },
    {
        name: 'Creation Date',
        width: '160px',
        cell: (row) => formatDate(row.created_at),
    },
    {
        name: 'Merchant ID',
        width: '120px',
        cell: (row) => (
            <Link to={`/merchants/${row.vendor?.id}`} target="_blank">
                <span>{row.vendor?.id}</span>
            </Link>
        ),
    },
    {
        name: 'Sub Total with VAT',
        width: '170px',
        cell: (row) => <Price amount={row?.subtotal_including_vat} />,
    },
    {
        name: 'Disty Commission with VAT',
        width: '170px',
        cell: (row) => <Price amount={row?.app_commission_with_vat} />,
    },
    {
        name: 'Delivery Commission with VAT',
        width: '170px',
        cell: (row) => <Price amount={row?.delivery_commission_with_vat} />,
    },
    {
        name: 'Disty Delivery Fees with VAT',
        width: '170px',
        cell: (row) => <Price amount={row?.boxes_delivery_fees_with_vat} />,
    },
    {
        name: 'Delivery Fees Paid To Merchant with VAT',
        width: '170px',
        cell: (row) => <Price amount={row?.delivery_fees_paid_to_vendor_with_vat} />,
    },
    {
        name: 'Merchant Ratio Discount with VAT',
        width: '170px',
        cell: (row) => <Price amount={row?.vendor_discount_with_vat} />,
    },
    {
        name: 'Merchant Payout with VAT',
        width: '170px',
        cell: (row) => <Price amount={row?.total_payout} />,
    },
    {
        name: 'Paid Amount',
        width: '120px',
        cell: (row) => <Price amount={row?.paid_amount} />,
    },
    {
        name: 'Remaining Amount',
        width: '170px',
        cell: (row) => <Price amount={row?.total_remaining} />,
    },
    {
        name: 'Payment Status',
        width: '180px',
        cell: (row) => <StatusBlock state={row.state} />,
    },
    // {
    //     name: 'VAT',
    //     width: '150px',
    //     cell: (row) => <Price amount={row?.vat} />,
    // },
    {
        name: 'Purchase Order',
        width: '150px',
        omit: !isSubordersInRetail,
        cell: (row) => (
            <Link to={`/purchase-orders/${row.vendor_invoice_request_id}`} target="_blank">
                {row.vendor_invoice_request_id}
            </Link>
        ),
    },
];

export default useColumns;
