import { Link } from 'react-router-dom';
import { formatDate } from '@utils';
import { Card, Price } from '@components';

const PayoutSummary = ({ payout, loading, errors }) => (
    <Card title="Payout Summary">
        {loading && <div className="p-3">Loading...</div>}

        {!!Object.keys(errors).length && (
            <div className="mx-3 alert alert-danger">
                <ul>
                    {Object.keys(errors).map((key) => {
                        return <li key={key}>{errors[key]}</li>;
                    })}
                </ul>
            </div>
        )}

        {!loading && !Object.keys(errors).length && (
            <ul className="flex__fd-column">
                <SummaryItem label="Payout ID" value={payout.id} />
                <SummaryItem
                    label="Merchant ID"
                    value={
                        <Link to={`/merchants/${payout.vendor?.id}`} target="_blank">
                            <span>{payout.vendor?.id}</span>
                        </Link>
                    }
                />
                <SummaryItem
                    label="Vendor name"
                    value={
                        <Link to={`/merchants/${payout.vendor?.id}`} target="_blank">
                            <div>
                                {payout.vendor?.company_name_en} ({payout.vendor?.company_name_ar})
                            </div>
                        </Link>
                    }
                />
                <SummaryItem
                    label="Taxable/Untaxable"
                    value={<input type="checkbox" checked={payout.is_vendor_taxable} disabled />}
                />

                <SummaryItem label="Payout Type" value={`${payout.payout_option?.days} days`} />
                <SummaryItem
                    label="Settlement period"
                    value={`${formatDate(payout.from, false)} - ${formatDate(payout.to, false)}`}
                />
                <SummaryItem
                    label="Outstanding for"
                    value={
                        payout.outstanding_for_days !== null
                            ? `${payout.outstanding_for_days} days`
                            : '-'
                    }
                />
                <SummaryItem label="Number Of child Orders" value={payout.suborders_count || '-'} />
                <SummaryItem
                    label="Sub Total amount for the settlement period"
                    value={<Price amount={payout?.subtotal_including_vat} />}
                />
                <SummaryItem
                    label="Total Disty Commission for the settlement period"
                    value={<Price amount={payout?.app_commission_with_vat} />}
                />
                <SummaryItem
                    label="Total Delivery Commission for the settlement period"
                    value={<Price amount={payout?.delivery_commission_with_vat} />}
                />
                <SummaryItem
                    label="Total Disty Delivery fees for the settlement period"
                    value={<Price amount={payout?.boxes_delivery_fees_with_vat} />}
                />
                <SummaryItem
                    label="Total Delivery Fees Paid To Vendor (Including Vat)"
                    value={<Price amount={payout?.delivery_fees_paid_to_vendor_with_vat} />}
                />
                <SummaryItem
                    label="Total Vendor Discount for the settlement period"
                    value={<Price amount={payout?.vendor_discount_with_vat} />}
                />
                <SummaryItem
                    label="Total Vendor Payouts for the settlement period"
                    value={<Price amount={payout?.total_payout} />}
                />
                <SummaryItem
                    label="Total Paid Amount"
                    value={<Price amount={payout?.paid_amount} />}
                />
                <SummaryItem
                    label="Total Remaining"
                    value={<Price amount={payout?.total_remaining} />}
                />
            </ul>
        )}
    </Card>
);

const SummaryItem = ({ label, value }) => (
    <li className="flex__jc-between hover-bg flex__ai-center py-2">
        <b>{label}</b>
        <div>{value}</div>
    </li>
);

export default PayoutSummary;
