import React from 'react';
import { formatStringWithUnderscores, renderValue } from '@utils';
import isEqual from 'lodash.isequal';

export const imagesKeys = ['logo_ar', 'logo_en', 'images'];

const UpdateRequest = ({ oldData, newData }) => {
    return (
        <div className="vendor-request-single">
            <table>
                <tr>
                    <th>Key</th>
                    <th>Old Data</th>
                    <th>New Data</th>
                </tr>

                {Object.entries(oldData).map(([key, oldValue]) => {
                    const isImg = imagesKeys.includes(key);
                    const newValue = newData[key];

                    if ((
                        isImg
                        && !oldValue
                        && !newValue) || isEqual(newValue, oldValue)
                    ) return <></>;

                    return (
                        <React.Fragment key={key}>
                            <tr>
                                <td className="key">
                                    {formatStringWithUnderscores(key)}</td>
                                <td>{renderValue(oldValue, {
                                    isImg: isImg,
                                    imgAlt: key,
                                })}</td>
                                <td>{renderValue(newValue, {
                                    isImg: isImg,
                                    imgAlt: key,
                                })}</td>
                            </tr>
                        </React.Fragment>
                    );
                })}
            </table>
        </div>

    );
};

export default UpdateRequest;