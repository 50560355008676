/** @todo fix eslint here */
/* eslint-disable quotes */
import React from 'react';
import {
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Table,
    StatusBlock,
    Modal,
    DataTable,
} from '@components';
import defaultLogo from '@assets/img/default.jpg';
import barcodeIcon from '@assets/icons/barcode.svg';
import avatarIcon from '@assets/icons/avatar.svg';
import warningIcon from '@assets/icons/warning.svg';
import fullscreenIcon from '@assets/icons/fullscreen.svg';
import historyClockIcon from '@assets/icons/history-clock.svg';
import configIcon from '@assets/icons/sidebar/config.svg';
import {
    formatDate,
    diffTimes,
    formatMoney,
    generateSuborderBarcode,
    openFullScreen,
    closeFullScreen,
    formatStringWithUnderscores,
    downloadFileFromAPI,
} from '@utils';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import useMutation from '@hooks/useMutation';
import Awb from './Awb';

const Suborder = ({ data = [], onSuborderAction, warehouse_allocation, active_tab }) => {
    const dispatch = useDispatch();
    const [fullscreen, set_fullscreen] = React.useState(false);
    const { mutate: downloadFile } = useMutation((url, filename) =>
        downloadFileFromAPI(url, filename, true),
    );
    React.useEffect(() => {
        const screenHandler = () => {
            if (fullscreen && !document.fullscreenElement) set_fullscreen(false);
        };
        document.addEventListener('fullscreenchange', screenHandler);
        return () => {
            document.removeEventListener('fullscreenchange', screenHandler);
        };
    }, [fullscreen]);
    const suborder_end_states = [4, 8, 1024, 2048, 8192, 131072, 262144];
    const [suborder_states_history, set_suborder_states_history] = React.useState(null);
    const [suborder_pretty_id, set_suborder_pretty_id] = React.useState(null);
    const columns = [
        {
            name: 'Logo',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.image || defaultLogo} alt="Logo" />
                </div>
            ),
        },
        {
            name: 'Product',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <div className="tr-name">
                    <h5>{row.name}</h5>
                    <h6 style={{ whiteSpace: 'nowrap' }}>
                        <small>CODE: {row.id}</small>
                    </h6>
                </div>
            ),
        },
        {
            name: 'Variant Options',
            cell: (row) => (
                <div>
                    {row?.variant_options?.map((o, i) => (
                        <div key={i}>
                            <span>{o.option_name}: </span>
                            <span>{o.value}</span>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            name: 'Has availability issue',
            selector: 'has_availability_issue',
            sortable: false,
            cell: (row) => (row.has_availability_issue ? 'Yes' : 'No'),
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.price)}</div>
                </div>
            ),
        },
        {
            name: 'Quantity',
            selector: 'quantity',
            sortable: false,
            center: true,
        },
        {
            name: 'Discount',
            selector: 'discount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.discount)}</div>
                </div>
            ),
        },
        {
            name: "Disty's ratio",
            selector: 'app_discount_amount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.app_discount_amount)}</div>
                </div>
            ),
        },
        {
            name: "Vendor's ratio",
            selector: 'vendor_discount_amount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vendor_discount_amount)}</div>
                </div>
            ),
        },
        {
            name: 'Subtotal (no vat)',
            selector: 'subtotal',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.subtotal)}</div>
                </div>
            ),
        },
        {
            name: 'VAT',
            selector: 'vat',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vat)}</div>
                </div>
            ),
        },
        {
            name: 'Total',
            selector: 'total',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total)}</div>
                </div>
            ),
        },
        {
            name: 'Applied stock type',
            cell: (row) => row.applied_stock_type || 'None',
        },
        {
            name: 'Storage instructions',
            cell: (row) => row?.storage_instructions || 'None',
        },
        {
            name: 'Storage conditions',
            cell: (row) =>
                row?.storage_conditions
                    ? row.storage_conditions?.map((x) => x.name_en).join(' , ')
                    : 'None',
        },
        {
            name: 'Delivery type',
            cell: (row) => row?.delivery_type?.name_en || 'None',
        },
        {
            name: 'Warehouse stock',
            selector: 'warehouse_stock',
        },
        {
            name: 'Taken warehouse stock',
            selector: 'taken_warehouse_stock',
        },
        {
            name: 'Warnings',
            selector: 'warnings',
            cell: (row) => (
                <div>
                    {row?.warnings?.length ? (
                        <div className="alert alert-warning fs-sm py-3" role="alert">
                            <ol>
                                {row?.warnings?.map((war, i) => (
                                    <li key={i}>{war}</li>
                                ))}
                            </ol>
                        </div>
                    ) : (
                        'none'
                    )}
                </div>
            ),
        },
    ];

    const states_columns = [
        {
            name: 'User type',
            cell: (row) => row?.author?.type,
        },
        {
            name: 'Name',
            cell: (row) => row?.author?.name,
        },
        {
            name: 'States',
            cell: (row) => (
                <div>
                    <h4>{row?.description}</h4>
                    <span>{row?.time_spent}</span>
                </div>
            ),
        },
        {
            name: 'Date & time',
            cell: (row) => formatDate(row?.created_at),
        },
    ];
    return (
        <>
            {data.map((suborder) => {
                const childOrderDownloadableDocs = [];

                if (suborder?.tax_invoice?.id)
                    childOrderDownloadableDocs.push({
                        docType: 'Tax invoice',
                        id: suborder.tax_invoice.id,
                        date: formatDate(suborder.tax_invoice.issued_at),
                        downloadLink: suborder.tax_invoice.links.self,
                        fileName: suborder.tax_invoice.filename,
                        number: suborder.tax_invoice.invoice_number,
                    });

                if (suborder.credit_note?.id)
                    childOrderDownloadableDocs.push({
                        docType: 'Credit note',
                        id: suborder.credit_note.id,
                        date: formatDate(suborder.credit_note.issued_at),
                        downloadLink: suborder.credit_note.links.self,
                        fileName: suborder.credit_note.filename,
                        number: suborder.credit_note.credit_note_number,
                    });

                if (suborder?.vendor_tax_invoice?.id)
                    childOrderDownloadableDocs.push({
                        docType: 'Commission',
                        id: suborder.vendor_tax_invoice.id,
                        date: formatDate(suborder.vendor_tax_invoice.issued_at),
                        downloadLink: suborder.vendor_tax_invoice.links.self,
                        fileName: suborder.vendor_tax_invoice.filename,
                        number: suborder.vendor_tax_invoice.invoice_number,
                    });

                if (suborder?.vendor_credit_note?.id)
                    childOrderDownloadableDocs.push({
                        docType: 'Credit note for commission invoice',
                        id: suborder.vendor_credit_note.id,
                        date: formatDate(suborder.vendor_credit_note.issued_at),
                        downloadLink: suborder.vendor_credit_note.links.self,
                        fileName: suborder.vendor_credit_note.filename,
                        number: suborder.vendor_credit_note.invoice_number,
                    });

                if (suborder?.delivery_fees_tax_invoice?.id)
                    childOrderDownloadableDocs.push({
                        docType: 'Shipping tax invoice',
                        id: suborder.delivery_fees_tax_invoice.id,
                        date: formatDate(suborder.delivery_fees_tax_invoice.issued_at),
                        downloadLink: suborder.delivery_fees_tax_invoice.links.self,
                        fileName: suborder.delivery_fees_tax_invoice.filename,
                        number: suborder.delivery_fees_tax_invoice.invoice_number,
                    });

                if (suborder?.delivery_fees_credit_note?.id)
                    childOrderDownloadableDocs.push({
                        docType: 'CN/ Shipping tax invoice',
                        id: suborder.delivery_fees_credit_note.id,
                        date: formatDate(suborder.delivery_fees_credit_note.issued_at),
                        downloadLink: suborder.delivery_fees_credit_note.links.self,
                        fileName: suborder.delivery_fees_credit_note.filename,
                        number: suborder.delivery_fees_credit_note.credit_note_number,
                    });

                return (
                    <div className="suborder" key={suborder.id}>
                        {!!suborder?.warnings?.length && (
                            <div className="alert alert-warning fs-sm py-3" role="alert">
                                <ol>
                                    {suborder?.warnings?.map((war, i) => (
                                        <li key={i}>{war}</li>
                                    ))}
                                </ol>
                            </div>
                        )}
                        <div className="suborder-header">
                            <div className="row">
                                <section className="baso-info-section">
                                    <div>
                                        ID: <b>{suborder?.pretty_id || suborder?.id}</b>
                                    </div>
                                    <div>
                                    Merchant:
                                        <b>
                                            {suborder.vendor.company_name || suborder?.vendor?.id}
                                        </b>
                                    </div>

                                    <div>
                                        Delivery option:{' '}
                                        <b>{suborder?.delivery_option || 'None'}</b>
                                    </div>
                                    <div>
                                        Invoicing model: <b>{suborder.invoicing_model}</b>
                                    </div>
                                    {active_tab !== 'All' && suborder?.delivery_fees_payer && (
                                        <div>
                                            Delivery charge on{' '}
                                            <b>
                                                {suborder?.delivery_fees_payer}
                                                {suborder?.delivery_fees !== null &&
                                                    ` (${formatMoney(
                                                        suborder?.delivery_fees,
                                                    )} SAR)`}
                                            </b>
                                        </div>
                                    )}

                                    {/*<div>
                                        HORECA <b>{suborder?.is_horeca ? 'True' : 'False'}</b>
                                </div>*/}
                                </section>
                                <section>
                                    {warehouse_allocation?.location_name && (
                                        <button
                                            className="btn btn-light download-barcode"
                                            onClick={() =>
                                                generateSuborderBarcode({
                                                    pretty_id: suborder.pretty_id,
                                                    cell: warehouse_allocation?.location_name,
                                                    vendor_name: suborder.vendor.company_name,
                                                })
                                            }
                                        >
                                            <img src={barcodeIcon} alt="" />
                                        </button>
                                    )}

                                    <ButtonGroup>
                                        {suborder.actions && suborder.actions.length > 0 && (
                                            <DropdownButton
                                                as={ButtonGroup}
                                                title="Actions"
                                                id="bg-nested-dropdown"
                                                variant="light"
                                            >
                                                {suborder.actions.map((action, i) => (
                                                    <Dropdown.Item
                                                        key={i}
                                                        eventKey={i}
                                                        onClick={() =>
                                                            onSuborderAction(
                                                                suborder.id,
                                                                suborder?.pretty_id,
                                                                action.code,
                                                                action.action,
                                                                action.reasons,
                                                            )
                                                        }
                                                    >
                                                        {action.action}
                                                    </Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        )}

                                        {!!childOrderDownloadableDocs.length && (
                                            <DropdownButton
                                                as={ButtonGroup}
                                                title="Docs"
                                                id="bg-nested-dropdown"
                                                variant="light"
                                            >
                                                {childOrderDownloadableDocs.map((doc, i) => {
                                                    const fileName = `${doc.docType} ${doc.number}, #${suborder.pretty_id}, ${doc.date}`;
                                                    return (
                                                        <Dropdown.Item
                                                            key={i}
                                                            eventKey={i}
                                                            onClick={() =>
                                                                downloadFile(
                                                                    doc.downloadLink,
                                                                    doc.fileName,
                                                                )
                                                            }
                                                        >
                                                            {fileName}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </DropdownButton>
                                        )}
                                        <Awb id={suborder?.id} />
                                    </ButtonGroup>
                                </section>
                            </div>
                            <div className="row">
                                <section className="state-section">
                                    <StatusBlock
                                        state={suborder.state}
                                        time_spent={
                                            !suborder_end_states.find(
                                                (s) => s === suborder.state.code,
                                            )
                                                ? diffTimes(suborder.state.time)
                                                : null
                                        }
                                    />
                                    {!!suborder?.states?.length && (
                                        <div
                                            className="history-state-icon"
                                            onClick={() => {
                                                set_suborder_states_history(
                                                    suborder.states.map((s, i) => ({
                                                        ...s,
                                                        // eslint-disable-next-line max-len
                                                        // eslint-disable-next-line no-constant-condition
                                                        ...(!suborder_end_states.find(
                                                            (e) => e === s.code,
                                                        )
                                                            ? {
                                                                  time_spent:
                                                                      i + 1 ===
                                                                      suborder.states.length
                                                                          ? diffTimes(s.created_at)
                                                                          : diffTimes(
                                                                                s.created_at,
                                                                                suborder.states[
                                                                                    i + 1
                                                                                ].created_at,
                                                                            ),
                                                              }
                                                            : {}),
                                                    })),
                                                );
                                                set_suborder_pretty_id(suborder.pretty_id);
                                                dispatch(
                                                    setModalStatus({
                                                        modal: 'suborder_states_history_modal',
                                                        status: true,
                                                    }),
                                                );
                                            }}
                                        >
                                            <div className="icon">
                                                <img src={historyClockIcon} alt="" />
                                            </div>
                                            <span>State history</span>
                                        </div>
                                    )}
                                    <div className="author-name">
                                        <img
                                            src={
                                                suborder?.state?.author?.name === 'SYSTEM'
                                                    ? configIcon
                                                    : avatarIcon
                                            }
                                            alt={suborder?.state?.author?.name}
                                        />
                                        <p>{suborder?.state?.author?.name}</p>
                                    </div>
                                </section>
                                <section>
                                    {suborder.has_availability_issue && (
                                        <div className="has-issue">
                                            <img src={warningIcon} alt="warning" />
                                            <span>Has availability issue!</span>
                                        </div>
                                    )}
                                </section>
                            </div>
                        </div>
                        {!Array.isArray(suborder?.state?.reason) && (
                            <div className="alert alert-warning" style={{ margin: '0 20px 16px' }}>
                                <b className="text-danger">Cancellation Reasons: </b>
                                <div className="cancellation-reasons">
                                    {suborder?.state?.reason?.parent_reason && (
                                        <p>
                                            parent reason:
                                            <span>
                                                {formatStringWithUnderscores(
                                                    suborder?.state?.reason?.parent_reason,
                                                )}
                                            </span>
                                        </p>
                                    )}
                                    {suborder?.state?.reason?.child_reason && (
                                        <p>
                                            child reason:
                                            <span>
                                                {formatStringWithUnderscores(
                                                    suborder?.state?.reason?.child_reason,
                                                )}
                                            </span>
                                        </p>
                                    )}
                                    {suborder?.state?.reason?.details && (
                                        <p>
                                            other details:
                                            <span>{suborder?.state?.reason?.details}</span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                        <b className="px-4">Products: </b>
                        <Table data={suborder?.products} columns={columns} />
                        <div className="flex__jc-end">
                            <div
                                className="toggle-fullscreen"
                                onClick={({ target }) => {
                                    set_fullscreen(!fullscreen);
                                    if (fullscreen) {
                                        closeFullScreen();
                                    } else {
                                        openFullScreen(target.closest('.suborder'));
                                    }
                                }}
                            >
                                <span>Toggle fullscreen</span>
                                <img src={fullscreenIcon} alt="" />
                            </div>
                        </div>
                    </div>
                );
            })}
            <Modal
                title={`Child order ${suborder_pretty_id} states history`}
                name="suborder_states_history_modal"
                confirmBtn={false}
                bodyStyle={{ overflowY: 'auto' }}
                size="lg"
                onClose={() => {
                    set_suborder_states_history(null);
                }}
            >
                {/* <ul className="state-history-timeline">
                    {suborder_states_history?.map((s, i) => (
                        <li key={i}>
                            <div>
                                <h4>{s.description}</h4>
                                <span>{s.time_spent}</span>
                            </div>
                            <div>{formatDate(s.created_at)}</div>
                        </li>
                    ))}
                </ul> */}
                {!!suborder_states_history?.length && (
                    <DataTable
                        data={suborder_states_history}
                        columns={states_columns}
                        searchable={false}
                    />
                )}
            </Modal>
        </>
    );
};

export default Suborder;
