import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const InvoicesSlice = createSlice({
    name: 'invoices',
    initialState: {
        invoices: [],
        invoices_loading: true,
        invoices_pages: null,
        invoices_records: null,
        // invoice: {},
        // invoiceAddress: null,
        // invoice_loading: true,
    },
    reducers: {
        setInvoices(state, { payload }) {
            state.invoices = payload;
        },
        setInvoicesTotalPages(state, { payload }) {
            state.invoices_pages = payload;
        },
        setInvoicesRecords(state, { payload }) {
            state.invoices_records = payload;
        },
        // setLiveCart(state, { payload }) {
        //     state.liveCart = payload;
        // },
        // setLiveCartAddress(state, { payload }) {
        //     state.liveCartAddress = payload;
        // },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
    },
});

// export actions
export const {
    setInvoices,
    setInvoicesRecords,
    setInvoicesTotalPages,
    setLiveCart,
    setLiveCartAddress,
    setLoading,
} = InvoicesSlice.actions;

export const loadInvoicesData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'invoices_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/invoice-requests?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setInvoices(res?.data || []));
    if(res?.meta){
        dispatch(setInvoicesTotalPages(res?.meta?.last_page || 0));
        dispatch(setInvoicesRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'invoices_loading',
            value: false,
        })
    );
};

export const loadLiveCartData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'liveCart_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/live-cart/${id}`,
    });
    dispatch(setLiveCart(res?.data || {}));
    if (res?.data.address_id && res?.data.customer?.id) {
        const addressed = await GET({
            endpoint: `/customers/${res?.data.customer.id}/addresses`,
        });
        // TODO there is no API endpoint to get single address by ID
        // so we have to get all addresses of customer
        if (addressed.data) {
            addressed.data.map((i) => {
                if (i.id === res?.data.address_id) {
                    dispatch(setLiveCartAddress(i));
                }
            });
        }
    } else {
        dispatch(setLiveCartAddress(null));
    }
    dispatch(
        setLoading({
            key: 'liveCart_loading',
            value: false,
        })
    );
};

export default InvoicesSlice.reducer;
