import React from 'react';
import card from '@assets/icons/orders_payment/card.png';
import apple_pay from '@assets/icons/orders_payment/apple_pay.png';
import wire_transfer from '@assets/icons/orders_payment/wire_transfer.png';
import v_iban_icon from '@assets/icons/sidebar/bank.svg';
import sadad from '@assets/icons/orders_payment/sadad.svg';
import cash_on_delivery from '@assets/icons/orders_payment/cash_on_delivery.svg';
import bynow from '@assets/icons/orders_payment/bynow.png';
import ldun from '@assets/icons/orders_payment/ldun.png';

function PaymentMethodIcons({ state, bnpl }) {
    let icon = getIconByStatusOrBNPLProvider({ state, bnpl });

    return <>{icon ? <img style={{ width: '30px' }} src={icon} alt="" /> : <h3>Null</h3>}</>;
}

const getIconByStatusOrBNPLProvider = ({ state, bnpl }) => {
    switch (state) {
        case 'wire_transfer':
            return wire_transfer;

        case 'wire_transfer_virtual_iban':
            return v_iban_icon;

        case 'card':
            return card;

        case 'apple_pay':
            return apple_pay;

        case 'sadad':
            return sadad;

        case 'cash_on_delivery':
            return cash_on_delivery;

        default:
            if (bnpl === 'ldun') return ldun;
            if (bnpl === 'bynow') return bynow;

            return '';
    }
};
export default PaymentMethodIcons;
