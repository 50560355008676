import React, { useMemo } from 'react';
import { Button, Checkbox, Select, ShowErrors, OverlayTrigger, Tooltip } from '@components';
// import SelfInvoiced from './SelfInvoiced';
import helpIcon from '@assets/icons/help.svg';
import useMutation from '@hooks/useMutation';
import { useSelector, useDispatch } from 'react-redux';
import { POST } from '@utils';
import { loadVendorData } from '@slices/merchant.slice';
import MarketplaceInvoicing from './MarketplaceInvoicing';
import serialize from '@utils/objectSerializer';
import { toast } from 'react-toastify';
import useQuery from '@hooks/useQuery';
import { DEFAULT_DELIVERY_OPTIONS, SUB_ORDER_STATUS } from '@components/shared/Filters/constants';

export default function InvoiceSettingsTab({ id }) {
    const dispatch = useDispatch();
    const vendor = useSelector((state) => state.VendorsSlice?.vendor);
    const [invoice_issuance_type, set_invoice_issuance_type] = React.useState();
    const [invoicing_3_data, set_invoicing_3_data] = React.useState(null);
    const { data: { data: payoutOptions } = { data: [] } } = useQuery(
        '/vendors/vendor-payout-options',
    );
    const [payout_retailer_option_id, set_payout_retailer_option_id] = React.useState({
        label: 'Payout 2.0',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_payout_retailer_option_id((prev) => ({ ...prev, value: val })),
    });
    const [payout_marketplace_option_id, set_payout_marketplace_option_id] = React.useState({
        label: 'Payout 3.0',
        options: [],
        value: '',
        getOptionValue: (option) => option.id,
        onChange: (val) => set_payout_marketplace_option_id((prev) => ({ ...prev, value: val })),
    });

    const [is_horeca, set_is_horeca] = React.useState({
        label: (
            <>
                <span>HORECA Merchant</span>
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip id="tooltip-right" width="300px">
                            HORECA is an acronym for HOtel, REstaurant, CAfé. If you enable this
                            flag for this Merchant, Purchase orders will be created on every child
                            order once it is confirmed and paid or pay later
                        </Tooltip>
                    }
                >
                    <img
                        src={helpIcon}
                        alt="i"
                        width={20}
                        style={{ marginInlineStart: 5, marginBottom: 3 }}
                    />
                </OverlayTrigger>
            </>
        ),
        checked: false,
        onChange: (checked) => set_is_horeca((prev) => ({ ...prev, checked })),
    });

    React.useEffect(() => {
        set_payout_retailer_option_id({
            ...payout_retailer_option_id,
            options: payoutOptions?.map((p) => ({
                id: p?.id,
                label: p?.name_en,
            })),
        });
        set_payout_marketplace_option_id({
            ...payout_marketplace_option_id,
            options: payoutOptions?.map((p) => ({
                id: p?.id,
                label: p?.name_en,
            })),
        });
    }, [payoutOptions]);

    const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [days, set_days] = React.useState({
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
    });

    const invoices = [
        {
            code: 'purchase_order',
            label: 'Purchase Order Invoiced',
        },
        {
            code: 'self',
            label: 'Self Invoiced',
        },
        {
            code: 'invoicing_3',
            label: 'Marketplace invoicing 3.0',
        },
    ];

    let daysPayload = useMemo(() => {
        let initialDaysPayload = {};
        for (const key in days) {
            if (days[key]) initialDaysPayload[key] = days[key];
        }

        return initialDaysPayload;
    }, [days]);

    const { isLoading, mutate, error } = useMutation(
        () =>
            POST({
                endpoint: `/vendors/${id}`,
                data: serialize(
                    {
                        _method: 'PATCH',
                        invoice_issuance_type,
                        ...(invoice_issuance_type === 'purchase_order' && {
                            ...(is_horeca.checked
                                ? { is_horeca: true }
                                : { days: daysPayload, is_horeca: false }),
                        }),
                        ...(invoice_issuance_type === 'invoicing_3' && {
                            ...invoicing_3_data,
                            payout_option_id: payout_marketplace_option_id?.value?.id || null,
                            // init deps fields in other tabs
                            ...(vendor?.invoice_issuance_type?.code !== 'invoicing_3' && {
                                order_delivery_fees_type: 'free',
                                delivery_options: DEFAULT_DELIVERY_OPTIONS,
                            }),
                        }),
                        ...(invoice_issuance_type === 'purchase_order' && {
                            payout_option_id: payout_retailer_option_id?.value?.id || null,
                        }),
                    },
                    { booleansAsIntegers: true, nullsAsUndefineds: true },
                ),
            }),
        {
            onSuccess: (res) => {
                dispatch(loadVendorData(vendor?.id));
                toast.success(res.message);
            },
            onError: (_, res) => {
                toast.error(res.message || 'Something went wrong!');
            },
        },
    );

    React.useEffect(() => {
        if (!!Object.keys(vendor || {}).length && !invoicing_3_data) {
            set_invoicing_3_data({
                cbv_invoice_issuance_allowed: vendor.cbv_invoice_issuance_allowed,
                cbv_invoices_available_for_customers: vendor.cbv_invoices_available_for_customers,
                tax_invoice_trigger: vendor.tax_invoice_trigger || SUB_ORDER_STATUS.Fulfilled,
                invoice_management_enabled: vendor.invoice_management_enabled,
            });
        }

        if (vendor?.payout_option?.id) {
            if (vendor?.invoice_issuance_type?.code === 'purchase_order') {
                payout_retailer_option_id.onChange({
                    id: vendor?.payout_option?.id,
                    label: vendor?.payout_option?.name_en,
                });
            } else {
                payout_retailer_option_id.onChange('');
            }

            if (vendor?.invoice_issuance_type?.code === 'invoicing_3') {
                payout_marketplace_option_id.onChange({
                    id: vendor?.payout_option?.id,
                    label: vendor?.payout_option?.name_en,
                });
            } else {
                payout_marketplace_option_id.onChange('');
            }
        }
        if (vendor?.invoice_issuance_type?.code && !invoice_issuance_type) {
            set_invoice_issuance_type(vendor?.invoice_issuance_type?.code);
            set_days((prev) => {
                vendor?.invoice_days?.forEach((day) => {
                    const indexOfTheDay = DAYS.findIndex(
                        (dayName) =>
                            dayName.toLocaleLowerCase() === day.description.toLocaleLowerCase(),
                    );
                    if (indexOfTheDay >= 0) {
                        prev[indexOfTheDay] = day.code;
                    }
                });

                return { ...prev };
            });
            is_horeca.onChange(vendor.is_horeca);
        }
    }, [vendor, payoutOptions]);

    if (!vendor?.id) return <>Loading...</>;

    return (
        <div>
            <ShowErrors errors={error} />

            <h3 className="divider-title">Invoice issuance setting</h3>
            {invoices.map((inv, i) => (
                <Checkbox
                    key={i}
                    disabled={isLoading}
                    label={inv.label}
                    onChange={() => {
                        set_invoice_issuance_type(inv?.code);
                    }}
                    checked={invoice_issuance_type === inv?.code}
                />
            ))}

            {invoice_issuance_type === 'purchase_order' && (
                <div style={{ margin: '20px 0' }}>
                    <Checkbox {...is_horeca} disabled={isLoading} />
                </div>
            )}

            {isLoading
                ? 'loading...'
                : invoice_issuance_type === 'purchase_order' && (
                      <div className="vendors-days-config">
                          {!is_horeca.checked && (
                              <>
                                  <h3 className="divider-title">
                                      Days that the Merchant is allowed to upload an invoice
                                  </h3>
                                  {DAYS.map((d, i) => (
                                      <Checkbox
                                          key={i}
                                          label={d}
                                          disabled={is_horeca.checked}
                                          checked={!!days[i]}
                                          onChange={(bool) => {
                                              set_days((prev) => ({
                                                  ...prev,
                                                  [i]: bool ? 1 << i : null,
                                              }));
                                          }}
                                      />
                                  ))}
                              </>
                          )}
                          <Select {...payout_retailer_option_id} />
                      </div>
                  )}
            {invoice_issuance_type === 'invoicing_3' && (
                <>
                    <MarketplaceInvoicing
                        initialValues={invoicing_3_data}
                        onChange={set_invoicing_3_data}
                    />
                    <Select {...payout_marketplace_option_id} />
                </>
            )}

            <Button onClick={mutate}>Save</Button>
        </div>
    );
}
