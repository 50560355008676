import React from 'react';
import PropTypes from 'prop-types';
import { Link as L } from 'react-router-dom';

export default function Link({
    href,
    className,
    children,
    ...props
}) {
    return (
        <L to={href} className={className} {...props}>
            {children}
        </L>
    );
}

Link.propTypes = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.any,
};