import React from 'react';
import { Row , Col , Card ,  Modal } from '@components';


function StoreImagesTab({ images=[] }) {
    const [preview_img, set_preview_img] = React.useState('');
    const [show_modal, set_show_modal] = React.useState(false);
    
    return (
        <>
            {images.length === 0 ? <div className='text-center'> 
            There are no images to display </div> :
                <div className='customer-images'>
            
                    <Row className='mx-1 align-items-center justify-content-between '>
                        {images.map(img =>{
                            return <>    <Col lg={4} md={6}>
                                <Card className='customer-img__wrapper'>
                                    
                                    <img className='customer-img' onClick={()=>{
                                        set_preview_img(img); 
                                        set_show_modal(true);
                                    }}  src={img} alt="" />
                                  
                                </Card>
                            </Col></>;
                        })}
                    </Row>
                    <Modal size="lg" show={show_modal} onHide={() => set_show_modal(false)}>
                        <Modal.Header closeButton>
                      
                        </Modal.Header>
                        <Modal.Body>
                            
                            <img className='modal-img'  src={preview_img} />
                           
                        </Modal.Body>
                  
                    </Modal>
                </div>
            }
         
        </>
    );
}

export default StoreImagesTab;
