import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import CustomersListView from './CustomersListView';
import CustomersAddView from './CustomersAddView';
import { CustomersSingleView } from './CustomersSignleView';
import { Helmet } from 'react-helmet';

export default function CustomersModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/customers/list">
                <Helmet>
                    <title>Retailers | Disty Admin Dashboard</title>
                </Helmet>
                <CustomersListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/customers/add">
                <Helmet>
                    <title>Add Retailer | Disty Admin Dashboard</title>
                </Helmet>
                <CustomersAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/customers/:id">
                <Helmet>
                    <title>Edit Retailer | Disty Admin Dashboard</title>
                </Helmet>
                <CustomersSingleView />
            </ProtectedRoute>
        </Switch>
    );
}
