import React from 'react';
import {
    DataTable,
    Card,
    Link,
    StatusBlock,
    Spinner,
    Modal,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Filters,
    ViewHeader,
    Sop
} from '@components';
import { formatDate, formatMoney, POST, GET } from '@utils';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    loadInvoiceFinancingOrdersData,
    update_single_vendors_order_from_list
} from '@slices/invoiceFinancingOrders.slice';
import { setModalStatus } from '@slices/ui.slice';
import { toast } from 'react-toastify';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import useDidMountEffect from '@hooks/useDidMountEffect';

const InvoiceFinancingOrdersListView = () => {
    const [page, set_page] = useState(1);

    const [records_per_page, set_records_per_page] = React.useState(null);
    const [
        updating_vendor_suborder_status_id,
        set_updating_vendor_suborder_status_id] = React.useState(null);
    const [suborderModal, setSuborderModal] = React.useState(null);
    const [submitting, set_submitting] = React.useState(false);
    const [filters, set_filters] = useState({});
    const InvoiceFinancingOrders = useSelector(state => state.InvoiceFinancingOrders);
    const dispatch = useDispatch();

    useDidMountEffect(() => {
        dispatch(loadInvoiceFinancingOrdersData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'Child ID',
            selector: 'pretty_id',
            width: '110px',
            center: true,
            cell: (row) => (
                <Link
                    target="_blank"
                    href={`/orders/${row.order.id}/${row.pretty_id}`}
                >
                    #{row.pretty_id}
                </Link>
            )
        },
        {
            name: 'Parent Payment status',
            minWidth: '150px',
            selector: 'order.payment_status.description',
            cell: (row) => <StatusBlock state={row.order.payment_status} />
        },
        {
            name: 'Pay later provider',
            cell: (row) => (
                <>
                    {row?.order?.pay_later_provider?.description}
                </>
            )
        },
        {
            name: 'Is scheduled',
            selector: 'order.scheduled_order_id',
            center: true,
            cell: (row) =>
                row?.order?.scheduled_order_delivery_option?.id
                    ? row?.order?.scheduled_order_delivery_option?.title_en
                    : <img src={cancelIcon} alt="No" className='dimmed-icon' />
        },
        {
            name: 'Is parent order delivered',
            center: true,
            cell: (row) => row?.order?.is_delivered
                ? <img src={checkedIcon} alt="Yes" />
                : <img src={cancelIcon} alt="No" className='dimmed-icon' />
        },
        {
            name: 'Parent State',
            minWidth: '150px',
            selector: 'order.state.description',
            cell: (row) => <StatusBlock state={row.order.state} />
        },
        {
            name: 'Child Fulfillment Status',
            minWidth: '150px',
            selector: 'state.description',
            cell: (row) => <StatusBlock state={row.state} />
        },
        {
            name: 'Customer',
            selector: 'customer',
            cell: (row) => (
                <Link
                    href={`/customers/${row.customer.id}`}
                    target="_blank"
                    className="tr-name tr-customer-info"
                >
                    <h5>
                        {row.customer.store_name}
                        <small>{row.customer.name}</small>
                    </h5>
                </Link>
            )
        },
        {
            name: 'Customer first order',
            selector: 'is_customer_first_order',
            center: true,
            cell: (row) =>
                row?.is_customer_first_order ?
                    <img src={checkedIcon} alt="Yes" /> :
                    <img src={cancelIcon} alt="No" className='dimmed-icon' />,
        },
        {
            name: 'Merchant',
            selector: 'vendor.company_name',
            cell: (row) => (
                <>
                    <Link
                        href={`/merchants/${row.vendor.id}`}
                        className="tr-name"
                    >
                        <h5>{row.vendor.company_name}</h5>
                    </Link>
                </>
            )
        },
        {
            name: 'Merchant User',
            cell: (row) => row?.order?.vendor_user
        },
        {
            name: 'Amount',
            selector: 'total',
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total)}</div>
                </div>
            )
        },

        {
            name: 'Creation Date',
            selector: 'created_at',
            minWidth: '150px',
            format: (r) => formatDate(r.created_at)
        },
        {
            name: 'Last Update Date',
            selector: 'updated_at',
            minWidth: '150px',
            format: (r) => formatDate(r.updated_at)
        },
        {
            name: 'Shipping preference',
            cell: (row) => row?.shipment_preference ? (
                <StatusBlock
                    state={
                        row?.shipment_preference
                            ? {
                                code: row?.shipment_preference.code,
                                description: row?.shipment_preference?.code,
                            }
                            : {}
                    }
                    noIcon={true}
                    style={{
                        backgroundColor:
                            row.shipment_preference?.code === 'one_shipment'
                                ? '#27ae60'
                                : '#3498db',
                    }}
                />
            ) : '',
        },
        {
            name: 'Actions',
            selector: 'actions',
            cell: (row) => {
                if (updating_vendor_suborder_status_id === row.id) {
                    return (
                        <Spinner size="xs" animation="border" />
                    );
                }

                return Array.isArray(row.actions) ? (
                    row.actions && row.actions.length > 0 && (
                        <ButtonGroup>
                            <DropdownButton
                                as={ButtonGroup}
                                title="Actions"
                                id="bg-nested-dropdown"
                                variant="light"
                            >
                                {row.actions.map((action, i) => (
                                    <Dropdown.Item
                                        key={i}
                                        eventKey={i}
                                        onClick={() => {
                                            const { id, pretty_id } = row;

                                            setSuborderModal({
                                                id,
                                                pretty_id,
                                                state: action.code,
                                                title: action.action
                                            });
                                            dispatch(
                                                setModalStatus({
                                                    modal: 'vendor_orders__suborder_modal',
                                                    status: true
                                                })
                                            );
                                        }}
                                    >
                                        {action.action}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </ButtonGroup>
                    )
                ) : (
                    <>
                        {typeof row.actions === 'string'
                            ? row.actions
                            : 'No actions'}
                    </>
                );
            }
        }
    ];

    const getVendorChildOrderActions = async (id) => {
        const res = await GET({
            endpoint: `/suborder/${id}`
        });

        if (res.status_code === 200) {
            return res?.data?.actions || [];
        }

        return [];
    };

    const runVendorSuborderAction = async (sid, state) => {
        set_updating_vendor_suborder_status_id(sid);
        set_submitting(true);
        const res = await POST({
            endpoint: `/suborder/${sid}/state`,
            data: { state }
        });
        set_submitting(false);

        if (res.status_code === 200) {
            let action = /\s+/.test(suborderModal.title)
                ? `marked as ${suborderModal.title.toLowerCase()}`
                : suborderModal.title.toLowerCase().replace(/e?$/, 'ed');

            toast.info(`Suborder ${suborderModal?.pretty_id} is ${action}`);

            const actions = await getVendorChildOrderActions(sid);

            dispatch(
                update_single_vendors_order_from_list({
                    id: sid,
                    actions: actions,
                    state: {
                        code: state,
                        description: suborderModal.title
                    }
                })
            );
        }

        set_updating_vendor_suborder_status_id(null);
    };

    return (
        <>
            <div className="customers-list-view">
                <ViewHeader title="Invoice Financing Orders" >
                    <Sop id="25083" />
                </ViewHeader>
                <Card>
                    <Filters
                        queryPlaceholder="Search by child id, customer name, store name, vendor"
                        filters={[
                            'child_order_state',
                            'order_states',
                            'payment_methods',
                            'shipment_preferences',
                            'cities',
                            'pay_later_providers',
                            'scheduled_order_delivery_option_id',
                            'entity_types',
                            'created_at_from',
                            'created_at_to',
                            'vendors',
                            'vendor_user_id',
                        ]}
                        onFilterChange={(filters_values) => {
                            set_filters(filters_values);
                        }}
                        initialFiltersValues={{
                            sort_by: 'created_at',
                        }}
                        sort_options={[
                            { label: 'Order ID', value: 'id' },
                            {
                                label: 'Updated at (Newest first)',
                                value: 'updated_at__desc',
                                direction: 'desc',
                            },
                            {
                                label: 'Updated at (Oldest first)',
                                value: 'updated_at__asc',
                                direction: 'asc',
                            },
                            {
                                label: 'Created at (Newest first)',
                                value: 'created_at__desc',
                                direction: 'desc',
                            },
                            {
                                label: 'Created at (Oldest first)',
                                value: 'created_at__asc',
                                direction: 'asc',
                            },
                            {
                                label: 'Total (Biggest first)',
                                value: 'total__desc',
                                direction: 'desc',
                            },
                            {
                                label: 'Total (Smallest first)',
                                value: 'total__asc',
                                direction: 'asc',
                            },
                        ]}
                    />
                    <DataTable
                        data={InvoiceFinancingOrders.vendors_orders || []}
                        pages={InvoiceFinancingOrders.vendors_orders_pages}
                        page={page}
                        records={InvoiceFinancingOrders.vendors_orders_records}
                        records_per_page={records_per_page}
                        set_records_per_page={set_records_per_page}
                        handlePageChange={set_page}
                        columns={columns}
                        searchable={false}
                        loading={InvoiceFinancingOrders.vendors_orders_loading}
                    />
                </Card>
            </div>
            <Modal
                title={`${suborderModal?.title} suborder ${suborderModal?.pretty_id}?`}
                name="vendor_orders__suborder_modal"
                variant={suborderModal?.title === 'Cancel' ? 'danger' : 'info'}
                onSubmit={(closeModal) => {
                    runVendorSuborderAction(suborderModal.id, suborderModal.state);
                    closeModal('vendor_orders__suborder_modal');
                }}
                loading={submitting}
            />
        </>
    );
};

export default InvoiceFinancingOrdersListView;