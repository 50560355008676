export default function downloadImageFromURL(url) {
    if (!url) return;

    try {
        fetch(url + '?cacheblock=true', { cache: 'no-store' })
            .then((response) => response.blob())
            .then((imageBlob) => {
                // Then create a local URL for that image and print it
                const imageObjectURL = URL.createObjectURL(imageBlob);
                // download it
                const element = document.createElement('a');
                element.setAttribute('href', imageObjectURL);
                const filename = url.split('/').pop();
                element.setAttribute('download', filename);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });
    } catch (e) {
        console.log(e);
    }
}
