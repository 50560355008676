import { useEffect, useRef } from 'react';

const defaultConfig = { runOnFirstRender: false, stop: false };

export const useInterval = (callback, delay, config = defaultConfig) => {
    const savedCallback = useRef();
    const _config = { ...defaultConfig, ...config };

    useEffect(() => {
        savedCallback.current = callback;
        _config.runOnFirstRender && savedCallback.current();
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (_config.stop) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};
