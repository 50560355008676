import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import WalletWithdrawalRequests from './WalletWithdrawalRequests';
import { Helmet } from 'react-helmet';

export default function UsersGroupsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/withdrawal-requests/list">
                <Helmet>
                    <title>Withdrawal Requests | Disty Admin Dashboard</title>
                </Helmet>
                <WalletWithdrawalRequests />
            </ProtectedRoute>
        </Switch>
    );
}
