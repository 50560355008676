import React from 'react';
import {
    DataTable,
    InviteUserModal,
    Button,
    ViewHeader,
    WhatsApp,
    Dialer,
    Link,
    Card,
    Filters,
} from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import { GET } from '@utils';
import { stringify } from 'query-string';

function RetailersUsersList() {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [page, set_page] = React.useState(1);
    const [pages, set_pages] = React.useState(0);
    const [filters, set_filters] = React.useState({});
  
    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/customers/retailer_users?${stringify(
                { q: filters?.q, status: filters?.active, page },
                {
                    skipNull: true,
                    skipEmptyString: true,
                },
            )}`,
        });
        set_loading(false);
        set_data(res?.data || []);
        set_pages(res?.meta?.last_page || 0);
    };
    React.useEffect(() => {
        fetchData();
    }, [page, filters]);
    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: 'name',
            cell: (row) => `${row?.first_name} ${row?.last_name}`,
        },
        {
            name: 'Personal Mobile',
            minWidth: '130px',
            cell: (row) => <Dialer number={row?.mobile} />,
        },
        {
            name: 'Whatsapp',
            width: '70px',
            center: true,
            cell: (row) => <WhatsApp number={row?.mobile} />,
        },
        {
            name: 'Personal email',
            selector: 'email',
        },
        {
            name: 'Occupation',
            cell: (row) => row?.occupation,
        },
        {
            name: 'Is admin',
            center: true,
            cell: (row) =>
                row?.is_account_admin ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Status',
            center: true,
            cell: (row) => (row.status ? 'active' : 'inactive'),
        },
        {
            name: 'Retailer',
            cell: (row) => (
                <Link
                    href={`/customers/${row?.retailer?.id}`}
                    target="_blank"
                    className="tr-name tr-customer-info"
                >
                    <h5>{row?.retailer?.store_name}</h5>
                </Link>
            ),
        },
    ];
    return (
        <div>
            <ViewHeader title="Retailers Users" renderBackArrow>
                <Button
                    onClick={() =>
                        dispatch(
                            setModalStatus({
                                modal: 'invite_new_user_modal',
                                status: true,
                            }),
                        )
                    }
                >
                    Invite new user
                </Button>
            </ViewHeader>
            <Card>
                <Filters
                    queryPlaceholder="Search by name, mobile, email or ID"
                    filters={['is_active']}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={data}
                    columns={columns}
                    loading={loading}
                    pages={pages}
                    page={page}
                    handlePageChange={set_page}
                />
            </Card>

            <InviteUserModal refetch={fetchData} />
        </div>
    );
}

export default RetailersUsersList;
