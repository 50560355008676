import React, { useEffect } from 'react';
import {
    ViewHeader,
    Button,
    Card,
    Row,
    Col,
    Input,
    Select,
    Modal,
    ShowErrors
} from '@components';
import { toast } from 'react-toastify';
import { POST, GET, PUT, DELETE, obj2formdata, isEnglish ,isArabic } from '@utils';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadCollectionData } from '@slices/collections.slice';
import { setCollection } from '@slices/collections.slice';
import defaultLogo from '@assets/img/default.jpg';
import { ReactSortable } from 'react-sortablejs';
import { useHistory } from 'react-router-dom';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
import lockIcon from '@assets/icons/lock.svg';
import { setModalStatus } from '@slices/ui.slice';

export default function ManageSubCollectionsView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const collection = useSelector(
        (state) => state.CollectionsSlice.collection
    );
    const [submitting, set_submitting] = React.useState(false);
    const [loading, set_loading] = React.useState(false);
    const [is_form_loading, set_is_form_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [sub_collections, set_sub_collections] = React.useState([]);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);
    const [selected_products, set_selected_products] = React.useState({
        options: [],
        getOptionValue: (option) => option.id,
        value: [],
        isMulti: true,
        onChange: (val) =>
            set_selected_products((prev) => ({ ...prev, value: val }))
    });
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => (isArabic(val) ? set_name_ar({ ...name_ar, value: val }) : ''),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_name_en({ ...name_en, value: val }) : ''),
    });
    React.useEffect(() => {
        if (collection.id) {
            set_selected_products({
                ...selected_products,
                options: collection?.products.map((product) => {
                    return { id: product?.id, label: product?.name };
                })
            });
        }
    }, [collection]);

    React.useEffect(() => {
        dispatch(setCollection({}));
        dispatch(loadCollectionData(id));
    }, []);
    const restForm = () => {
        name_ar.setValue('');
        name_en.setValue('');
        selected_products.onChange([]);
    };
    // get sub-collection detail data
    React.useEffect(() => {
        if (edit_id) {
            set_is_form_loading(true);
            const fetchDetail = async () => {
                const res = await GET({
                    endpoint: `/product-collection/${id}/filters/${edit_id}`
                });
                set_is_form_loading(false);
                if (res.status_code === 200) {
                    name_ar.setValue(res?.data?.name_ar);
                    name_en.setValue(res?.data?.name_en);
                    selected_products.onChange(
                        res.data.products.map((product) => {
                            return { id: product?.id, label: product?.name };
                        })
                    );
                }
            };
            fetchDetail();
        }
    }, [edit_id]);
    // get sub-collection list data
    const fetchData = async () => {
        // set_loading(true);
        const res = await GET({
            endpoint: `/product-collection/${id}/filters`
        });
        set_loading(false);
        if (res.status_code === 200) set_sub_collections(res?.data);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name_ar.value) newErrors.name_ar = 'The name (arabic) field is required.';
        if (!name_en.value) newErrors.name_en = 'The name (english) field is required.';
        if (selected_products.value.length === 0) newErrors.selected_products = 'The products field is required.';
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name_ar, name_en, selected_products]);
   
    // POST / PUT sub-collection
    const submitSubCollection = async () => {
        set_loading(true);
        if(!validate()) return ;
         const res = await (edit_id ? PUT : POST)({
            endpoint: edit_id
                ? `/product-collection/${id}/filters/${edit_id}`
                : `/product-collection/${id}/filters`,
            data: {
                name_ar: name_ar.value,
                name_en: name_en.value,
                products: selected_products?.value.map((product) => product?.id)
            }
        });
        set_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            fetchData();
            set_errors([]);
            restForm();
        } else {
            set_errors(
                res?.data?.errors || {
                    guardedFilter: 'Editing a guarded filter is not allowed'
                }
            );
        }
    };
    
    // POST / sort sub-collection list
    const submit = async () => {

        const data = obj2formdata({});

        sub_collections.forEach((p, i) => {
            data.append('filters[' + i + '][id]', p.id);
            data.append('filters[' + i + '][priority]', i + 1);
        });

        const res = await POST({
            endpoint: `/product-collection/${id}/filters/priority`,
            data
        });
        set_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            fetchData();
            toast.success(res?.message);
        }
    };

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // if (loading) {
    //     return 'loading...';
    // }

    const deleteSubCollection = async (closeModal) => {
        set_submitting(true);
        const res = await DELETE({
            endpoint: `/product-collection/${id}/filters/${delete_id}`
        });
        set_submitting(false);

        if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('sub_collection_delete_modal');
            set_delete_id(null);
            set_errors([]);
            fetchData();
        } else {
            set_errors(
                res?.data?.errors || {
                    guardedFilter: 'Removing a guarded filter is not allowed'
                }
            );
        }
    };
    return (
        <div className="sub-collection">
            <ViewHeader
                title={`${edit_id ? 'Edit' : 'Add new'}  sub-collection`}
            >
                {edit_id && (
                    <Button
                        variant="light"
                        onClick={() => {
                            set_edit_id(null);
                            set_errors([]);
                            restForm();
                        }}
                    >
                        Cancel
                    </Button>
                )}

                <Button
                    variant="info"
                    className="btn btn-primary"
                    onClick={submitSubCollection}
               
                >
                    {edit_id
                        ? 'Edit sub-collection '
                        : 'Submit New sub-collection'}
                </Button>
            </ViewHeader>
            <Card className="p-3">
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
                {is_form_loading && (
                    <div className="text-center my-2">loading...</div>
                )}
                <Row>
                    <Col lg={6}>
                        <Input {...name_ar} />
                        {loading && validation_errors.name_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_ar}
                                            </p>
                                        )}
                    </Col>
                    <Col lg={6}>
                        <Input {...name_en} />
                        {loading && validation_errors.name_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_en}
                                            </p>
                                        )}
                    </Col>
                </Row>
                <Select {...selected_products} />
                {loading && validation_errors.selected_products && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.selected_products}
                                            </p>
                                        )}
            </Card>
            <div className="sup-collections-sort-products-view">
                <h3 className="sort-title">Sort Sub-collections</h3>
                <ViewHeader title={`Parent collection name : ${collection?.name_en}`}>
                    <Button variant="light" onClick={() => history.goBack()}>
                        Cancel
                    </Button>
                    <Button
                        className="ml-2"
                        variant="primary"
                        onClick={submit}
                        
                    >
                        Save sorting changes
                    </Button>
                </ViewHeader>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <ReactSortable
                                tag="ul"
                                // list={products}
                                list={sub_collections.map((x) => ({
                                    ...x,
                                    chosen: true
                                }))}
                                setList={set_sub_collections}
                            >
                                {sub_collections.map((p) => (
                                    <li
                                        key={p.id}
                                        className="products-list-item"
                                    >
                                        <div className="handle">
                                            <span></span>
                                            <span></span>
                                        </div>
                                        <div className="logo">
                                            <img src={defaultLogo} alt="Logo" />
                                        </div>
                                        <div className="name">
                                            {p.name_en}
                                            {p.is_guarded && (
                                                <img
                                                    className="ml-2"
                                                    width="16px"
                                                    src={lockIcon}
                                                    alt="lock"
                                                />
                                            )}
                                        </div>
                                        <div className="actions">
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => {
                                                    set_delete_id(p.id);
                                                    restForm();
                                                    set_edit_id(null);
                                                    set_errors([]);
                                                    dispatch(
                                                        setModalStatus({
                                                            modal: 'sub_collection_delete_modal',
                                                            status: true
                                                        })
                                                    );
                                                }}
                                            >
                                                <img
                                                    src={deleteIcon}
                                                    alt="Delete"
                                                    width="16px"
                                                />
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                onClick={() => {
                                                    set_edit_id(p.id);
                                                    set_errors([]);
                                                    goToTop();
                                                }}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    width="16px"
                                                />
                                            </Button>
                                        </div>
                                    </li>
                                ))}
                            </ReactSortable>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                title="Delete sub-collection ?"
                name="sub_collection_delete_modal"
                variant="danger"
                onSubmit={deleteSubCollection}
                loading={submitting}
            >
                {delete_id && !!Object.keys(errors).length && (
                    <div className="alert alert-danger">
                        <ul>
                            {Object.keys(errors).map((key) => {
                                return <li key={key}>{errors[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
            </Modal>
        </div>
    );
}
