import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import UsersGroupsListView from './UsersGroupsListView';
import UsersGroupsAddView from './UsersGroupsAddView';
import UsersGroupsUpdateView from './UsersGroupsUpdateView';
import { Helmet } from 'react-helmet';

export default function UsersGroupsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/users-groups/list">
                <Helmet>
                    <title>Users Groups | Disty Admin Dashboard</title>
                </Helmet>
                <UsersGroupsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/users-groups/add">
                <Helmet>
                    <title>Add Users Group | Disty Admin Dashboard</title>
                </Helmet>
                <UsersGroupsAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/users-groups/:id">
                <Helmet>
                    <title>Edit Users Group | Disty Admin Dashboard</title>
                </Helmet>
                <UsersGroupsUpdateView />
            </ProtectedRoute>
        </Switch>
    );
}
