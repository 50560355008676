import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Row, Col, Modal, DataTable, ShowErrors } from '@components';
import { POST, DELETE, randomId, formatDate, confirmationMessage } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import walletDebit from '@assets/icons/wallet-debit.svg';
import walletCredit from '@assets/icons/wallet-credit.svg';
import SelectVariantOrBase from '../../components/SelectVariantOrBase';
import useQuery from '@hooks/useQuery';
import { loadProductData } from '@slices/products.slice';

export default function StockTab() {
    return (
        <SelectVariantOrBase>
            {({ selected, isBaseSelected }) => (
                <UpdateStock {...{ selectedId: selected, isBaseSelected }} />
            )}
        </SelectVariantOrBase>
    );
}

function UpdateStock({ selectedId }) {
    const product = useSelector((state) => state.ProductsSlice.product);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState({});
    const [increase_by, set_increase_by] = React.useState(0);
    const [decrease_by, set_decrease_by] = React.useState(0);
    const [idempotency_key, set_idempotency_key] = React.useState(0);
    const [submitting, set_submitting] = React.useState(false);
    const [page, set_page] = React.useState(1);
    const dispatch = useDispatch();
    const variant = product.variants.find(({ id }) => id === selectedId);

    const {
        data: { data: stock_transactions } = { data: [] },
        refetch: refetchTransactions,
        isLoading: stock_transactions_loading,
    } = useQuery(!!selectedId && `/product/${selectedId}/stock-transactions`, { page });

    /** @todo add pages from meta of {{stock_transactions}} */
    const pages = 1;

    React.useEffect(() => {
        if (product.id) {
            set_idempotency_key(randomId());
        }
    }, [page]);

    const increaseStock = async (cb) => {
        cb();
        if (increase_by == 0) return;

        set_loading(true);
        const res = await POST({
            endpoint: `/product/${selectedId}/stock`,
            data: {
                amount: +increase_by,
            },
            headers: {
                'Idempotency-Key': idempotency_key,
            },
        });
        set_loading(false);
        set_increase_by(0);
        if (res?.status_code === 201 || res?.status_code === 200) {
            set_idempotency_key(randomId());
            set_errors({});
            refetchTransactions();
            dispatch(loadProductData(product.id));
        }

        if (res?.status === 400) {
            set_errors({ stock_type: [res?.data?.message] });
        }
    };

    const decreaseStock = async (cb) => {
        cb();
        if (decrease_by == 0) return;

        set_loading(true);
        const res = await DELETE({
            endpoint: `/product/${selectedId}/stock`,
            data: {
                amount: +decrease_by,
            },
            headers: {
                'Idempotency-Key': idempotency_key,
            },
        });
        set_loading(false);
        set_decrease_by(0);
        if (res?.status_code === 201 || res?.status_code === 200) {
            set_idempotency_key(randomId());
            set_errors({});
            refetchTransactions();
            dispatch(loadProductData(product.id));
        }

        if (res?.status === 400) {
            set_errors({ stock_type: [res?.data?.message] });
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
        },
        {
            name: 'Type',
            selector: 'type',
            width: '50px',
            cell: (row) => (
                <>
                    <img
                        src={row.type === 'increase' ? walletCredit : walletDebit}
                        style={{
                            width: 30,
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    />
                </>
            ),
        },
        {
            name: 'Quantity',
            selector: 'quantity',
            center: true,
        },
        {
            name: 'Author',
            selector: 'author',
        },
        {
            name: 'Author type',
            selector: 'author_type',
        },
        {
            name: 'Stock type',
            selector: 'stock_type',
        },
        {
            name: 'Reason',
            selector: 'reason',
        },
        {
            name: 'Created at',
            selector: 'created_at',
            cell: (row) => formatDate(row.created_at),
        },
    ];

    return (
        <>
            <Row>
                <Col className="flex__jc-center__ai-center">
                    <Button
                        variant="success"
                        disabled={loading}
                        onClick={() =>
                            dispatch(
                                setModalStatus({
                                    modal: 'single_product_view__increase_stock',
                                    status: true,
                                }),
                            )
                        }
                    >
                        Add stock
                    </Button>
                </Col>
                <Col className="flex__jc-center__ai-center">
                    <Input
                        label="Stock"
                        type="number"
                        value={variant?.stock}
                        disabled={true}
                    />
                </Col>
                <Col className="flex__jc-center__ai-center">
                    <Button
                        variant="danger"
                        disabled={loading}
                        onClick={() =>
                            dispatch(
                                setModalStatus({
                                    modal: 'single_product_view__decrease_stock',
                                    status: true,
                                }),
                            )
                        }
                    >
                        decrease stock
                    </Button>
                </Col>
            </Row>

            <ShowErrors errors={errors} />

            <DataTable
                data={stock_transactions}
                columns={columns}
                loading={stock_transactions_loading}
                pages={pages}
                page={page}
                handlePageChange={set_page}
            />

            <Modal
                title={`Increase stock for ${product.name_ar}`}
                name="single_product_view__increase_stock"
                variant="success"
                onSubmit={(closeModal) => {
                    set_submitting(true);
                    product?.is_active
                        ? increaseStock(() => {
                              closeModal('single_product_view__increase_stock');
                              set_submitting(false);
                          })
                        : confirmationMessage(() =>
                              increaseStock(() => {
                                  closeModal('single_product_view__increase_stock');
                                  set_submitting(false);
                              }),
                          );
                }}
                loading={submitting}
            >
                <Input label="Stock" type="number" value={increase_by} setValue={set_increase_by} />
            </Modal>

            <Modal
                title={`Decrease stock for ${product.name_ar}`}
                name="single_product_view__decrease_stock"
                variant="danger"
                onSubmit={(closeModal) => {
                    set_submitting(true);
                    product?.is_active
                        ? decreaseStock(() => {
                              closeModal('single_product_view__decrease_stock');
                              set_submitting(false);
                          })
                        : confirmationMessage(() =>
                              decreaseStock(() => {
                                  closeModal('single_product_view__decrease_stock');
                                  set_submitting(false);
                              }),
                          );
                }}
                loading={submitting}
            >
                <Input label="Stock" type="number" value={decrease_by} setValue={set_decrease_by} />
            </Modal>
        </>
    );
}
