import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const BrandsSlice = createSlice({
    name: 'brands',
    initialState: {
        brands: [],
        brands_pages: null,
        brands_records: null,
        brand: {},
        brands_loading: true,
        brand_loading: true,
    },
    reducers: {
        setBrands(state, { payload }) {
            state.brands = payload;
        },
        setBrand(state, { payload }) {
            state.brand = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setBrandsTotalPages(state, { payload }) {
            state.brands_pages = payload;
        },
        setBrandsRecords(state, { payload }) {
            state.brands_records = payload;
        },
    },
});

// export actions
export const {
    setBrand,
    setBrands,
    setLoading,
    setBrandsTotalPages,
    setBrandsRecords,
} = BrandsSlice.actions;

export const loadBrandsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'brands_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/brand?${stringify(params, {
            skipNull: true,
            skipEmptyString: true
        })}`,
    });
    dispatch(setBrands(res?.data || []));
    if(res?.meta){
        dispatch(setBrandsTotalPages(res?.meta?.last_page || 0));
        dispatch(setBrandsRecords({
            from: res?.meta?.from,
            to: res?.meta?.to,
            total: res?.meta?.total
        }));
    }
    dispatch(
        setLoading({
            key: 'brands_loading',
            value: false,
        })
    );
};

export const loadBrandData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'brand_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/brand/${id}`,
    });
    dispatch(setBrand(res?.data || {}));
    dispatch(
        setLoading({
            key: 'brand_loading',
            value: false,
        })
    );
};

export default BrandsSlice.reducer;
