import React from 'react';
import { GET, obj2formdata, POST } from '@utils';
import { Row, Col, Select, ShowErrors, Input, FileUpload, CriticalActionButton } from '@components';

function BusinessInfoTab({ id }) {
    const [email, set_email] = React.useState('');
    const [mobile, set_mobile] = React.useState('');
    const [starting_hour, set_starting_hour] = React.useState('');
    const [ending_hour, set_ending_hour] = React.useState('');
    const [business_size, set_business_size] = React.useState('');
    const [employees_number, set_employees_number] = React.useState('');
    const [monthly_income, set_monthly_income] = React.useState('');
    const [monthly_purchases, set_monthly_purchases] = React.useState('');
    const [current_image, set_current_image] = React.useState('');
    const [image, set_image] = React.useState({
        label: 'store image',
        files: [],
        setFiles: (val) => set_image({ ...image, files: val })
    });
    const [links, set_links] = React.useState([
        {
            name: 'website',
            value: ''
        },
        {
            name: 'instagram',
            value: ''
        },
        {
            name: 'snapchat',
            value: ''
        },
        {
            name: 'telegram',
            value: ''
        },
        {
            name: 'facebook',
            value: ''
        },
        {
            name: 'twitter',
            value: ''
        },
    ]);
    const filterExistingLinks = (parent, sub) => {
        return parent.filter((parentObj) => {
            return !sub.some((subObj) => {
                return parentObj.name === subObj.website_name;
            });
        });
    };
    const filterSocialLinks = (parent, sub) => {
        let links_array = [];
        parent?.forEach((x) => {
            sub?.forEach((y) => {
                if (x.name === y?.website_name) {
                    links_array.push({ ...x, value: y.website_url });
                }
            });
        });
        return links_array;
    };
    const [static_data, set_static_data] = React.useState([]);
    const [errors, set_errors] = React.useState([]);
    const [updated, set_updated] = React.useState(null);
    const [loading, set_loading] = React.useState(false);
    const fetchData = async () => {
        set_loading(true);
        const [static_res, business_res] = await Promise.all([
            GET({
                endpoint: '/customers/business-info/static-data',
            }),
            GET({
                endpoint: `/customers/${id}/business-info`,
            }),
        ]);
        set_loading(false);
        if (static_res?.status_code === 200) {
            set_static_data(static_res?.data);
        }

        if (business_res?.status_code === 200) {
            const data = business_res.data;
            set_email(data?.email || '');
            set_mobile(data?.mobile || '');
            set_current_image(data?.image || '');
            set_ending_hour(
                data?.ending_hour
                    ? {
                        value: data.ending_hour.code,
                        label: data.ending_hour.description,
                    }
                    : ''
            );
            set_starting_hour(
                data?.starting_hour
                    ? {
                        value: data.starting_hour.code,
                        label: data.starting_hour.description,
                    }
                    : ''
            );
            set_monthly_income(
                data?.monthly_income
                    ? {
                        value: data.monthly_income.code,
                        label: data.monthly_income.description,
                    }
                    : ''
            );
            set_monthly_purchases(
                data?.monthly_purchases
                    ? {
                        value: data.monthly_purchases.code,
                        label: data.monthly_purchases.description,
                    }
                    : ''
            );
            set_business_size(
                data?.business_size
                    ? {
                        value: data.business_size.code,
                        label: data.business_size.description,
                    }
                    : ''
            );
            set_employees_number(
                data?.employees_number
                    ? {
                        value: data.employees_number.code,
                        label: data.employees_number.description,
                    }
                    : ''
            );
            if (data?.social_url?.length) {
                set_links([...filterSocialLinks(links, data.social_url)
                    , ...filterExistingLinks(links, data.social_url)]);
            }
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);
    const handleSubmit = async () => {

        const data = obj2formdata({
            email: email || null,
            mobile: mobile || null,
            starting_hour: starting_hour?.value || null,
            ending_hour: ending_hour?.value || null,
            business_size: business_size?.value || null,
            employees_number: employees_number?.value || null,
            monthly_income: monthly_income.value || null,
            monthly_purchases: monthly_purchases.value || null,
            _method: 'put',
        });
        links.forEach((link, i) => {
            if (link.value.trim()) {
                data.append(`social_url[${i}][website_name]`, link?.name);
                data.append(`social_url[${i}][website_url]`, link?.value);
            }

        });
        image.files.length && data.append('image', image.files[0]);
        set_loading(true);
        const res = await POST({
            endpoint: `/customers/${id}/business-info`,
            data,
        });
        set_loading(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_errors([]);
            fetchData();
            set_updated('Success');
            setTimeout(() => {
                set_updated(null);
            }, 2000);
            image.setFiles([]);
        } else { set_errors(res?.data.errors); }

    };
    return (
        <div className="customer-form px-3 py-2">
            <h3 className="divider-title">Store Info</h3>
            <Row>

                <Col lg={6}>
                    <Input
                        label="email"
                        placeholder="New email"
                        value={email}
                        setValue={set_email}
                    />
                </Col>
                <Col lg={6}>
                    <Input
                        label="phone"
                        placeholder="New phone number"
                        type='number'
                        value={mobile}
                        setValue={set_mobile}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Select
                        label="starting hour"
                        options={static_data?.starting_hour?.map((x) => ({
                            value: x.code,
                            label: x.description,
                        })) || []}
                        value={starting_hour}
                        onChange={set_starting_hour}
                        menuPlacement="top"
                    />
                </Col>
                <Col lg={6}>
                    <Select
                        label="ending hour"
                        options={static_data?.ending_hour?.map((x) => ({
                            value: x.code,
                            label: x.description,
                        })) || []}
                        value={ending_hour}
                        onChange={set_ending_hour}
                        menuPlacement="top"
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Select
                        label="employees number"
                        options={static_data?.employees_number?.map((x) => ({
                            value: x.code,
                            label: x.description,
                        })) || []}
                        value={employees_number}
                        onChange={set_employees_number}
                        menuPlacement="top"
                    />
                </Col>
                <Col lg={6}>
                    <Select
                        label="size"
                        options={static_data?.size?.map((x) => ({
                            value: x.code,
                            label: x.description,
                        })) || []}
                        value={business_size}
                        onChange={set_business_size}
                        menuPlacement="top"
                    />

                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Select
                        label="monthly purchases"
                        options={static_data?.monthly_money_range?.map((x) => ({
                            value: x.code,
                            label: x.description,
                        })) || []}
                        value={monthly_purchases}
                        onChange={set_monthly_purchases}
                        menuPlacement="top"
                    />
                </Col>
                <Col lg={6}>
                    <Select
                        label="monthly income"
                        options={static_data?.monthly_money_range?.map((x) => ({
                            value: x.code,
                            label: x.description,
                        })) || []}
                        value={monthly_income}
                        onChange={set_monthly_income}
                        menuPlacement="top"
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FileUpload {...image} />
                    {current_image && (
                        <ul className="thumpnails">
                            <li>
                                <img src={current_image} />
                            </li>
                        </ul>)}
                </Col>
            </Row>
            <h3 className="divider-title">Social Media</h3>
            <Row>
                {links.map((l, i) => (<Col key={i} lg={6}>
                    <Input label={l.name} value={l.value} setValue={(val => set_links((prev) =>
                        prev.map(x => x.name === l.name ? {
                            ...x,
                            value: val
                        } : x)))}
                    />
                </Col>))}

            </Row>
            {updated && <div className="alert alert-success">{updated}</div>}
            {!!Object.keys(errors).length && (
                <ShowErrors errors={errors} />
            )}
            <CriticalActionButton
                loading={loading}
                onSubmit={handleSubmit}
            >
                Save changes
            </CriticalActionButton>
        </div>
    );
}

export default BusinessInfoTab;