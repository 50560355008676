import React from 'react';
import { ViewHeader, DataTable, Card, Link, Filters } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadVirtualIbansListData } from '../../store/slices/virtualIbans.slice';
import useDidMountEffect from '@hooks/useDidMountEffect';
import { formatMoney } from '@utils';

export default function ListView() {
    const VirtualIbansSlice = useSelector((state) => state.VirtualIbansSlice);
    const dispatch = useDispatch();

    const [filters, set_filters] = React.useState('');
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    useDidMountEffect(() => {
        dispatch(loadVirtualIbansListData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            center: true,
            width: '100px',
            cell: (row) => <Link href={`/v-iban/${row.id}`}>{row.id}</Link>,
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: false,
        },
        {
            name: 'Shop name',
            selector: 'store_name',
            sortable: false,
        },
        {
            name: 'Total orders',
            selector: 'total_orders',
            sortable: false,
        },
        {
            name: 'Total spent',
            selector: 'total_spent',
            sortable: false,
            center: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total_spent)}</div>
                </div>
            ),
        },
        {
            name: 'Virtual IBAN',
            selector: 'virtual_iban',
            sortable: false,
            cell: (r) => <div>{r.virtual_iban}</div>,
        },
    ];

    return (
        <div>
            <ViewHeader title="Virtual IBANs" />

            <Card>
                <Filters
                    queryPlaceholder="Search by customer id, virtual iban"
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    sort_options={[
                        {
                            label: 'Retailer ID (Newest first)',
                            value: 'id__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Retailer ID (Oldest first)',
                            value: 'id__asc',
                            direction: 'asc',
                        },
                        {
                            label: 'Created at (Newest first)',
                            value: 'alrajhi_virtual_iban_created_at__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Created at (Oldest first)',
                            value: 'alrajhi_virtual_iban_created_at__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    columns={columns}
                    data={VirtualIbansSlice.virtualIbans_list}
                    pages={VirtualIbansSlice.virtualIbans_pages}
                    page={page}
                    records={VirtualIbansSlice.virtualIbans_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    searchable={false}
                    loading={VirtualIbansSlice.virtualIbans_loading}
                />
            </Card>
        </div>
    );
}
