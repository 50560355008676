const CONTENTFUL_PREVIEW_ACCESS_TOKEN = 'rdO2G3EXBPOgRdzXsnxsiOQzcduHAzgoI27cnnEzJ-Q';
const CONTENTFUL_ACCESS_TOKEN = 'yHGHYMu4fiqrYQLxeaUn36bnK8G0KTSrJbvJm-vO1rQ';

async function fetchContentfulGraphQL(query, preview = false) {
    return fetch(
        `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRMENT}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Switch the Bearer token depending on whether the fetch is supposed to retrieve live
                // Contentful content or draft content
                Authorization: `Bearer ${
                    preview ? CONTENTFUL_PREVIEW_ACCESS_TOKEN : CONTENTFUL_ACCESS_TOKEN
                }`,
            },
            body: JSON.stringify({ query }),
            // Associate all fetches for articles with an "articles" cache tag so content can
            // be revalidated or updated from Contentful on publish
            next: { tags: ['articles'] },
        },
    ).then((response) => response.json());
}

async function getAllArticles() {
    const articles = await fetchContentfulGraphQL(
        `query {
          articleCollection(limit: 0){
            items{
              title
              sys{
                id
              }
            }
          }
        }`,
    );

    const data = flatten(articles?.data?.articleCollection?.items) || [];

    return data;
}

const flatten = (articles) => {
    return articles?.map((c) => ({
        id: c.sys?.id,
        title: c.title,
    }));
};

export default {
    getAllArticles,
};
