import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewHeader, Link, Card, DataTable, Filters, PriceHistoryModal, Sop } from '@components';
import { downloadCSV, randomId, formatDate, formatMoney } from '@utils';
import defaultLogo from '@assets/img/default.jpg';
import useDidMountEffect from '@hooks/useDidMountEffect';
import { loadProductsData } from '@slices/products.slice';
import { getRange, getTakeRateRange } from './product-utils';

export default function ProductsListView() {
    const ProductsSlice = useSelector(state => state.ProductsSlice);
    const dispatch = useDispatch();
    const [active_product, set_active_product] = React.useState(null);

    // TODO: missing filters
    // const [selected_vendors, set_selected_vendors] = React.useState([]);
    // const [take_rate_from, set_take_rate_from] = React.useState(null);
    // const [take_rate_to, set_take_rate_to] = React.useState(null);
    // const [take_rate_amount_from, set_take_rate_amount_from] = React.useState(null);
    // const [take_rate_amount_to, set_take_rate_amount_to] = React.useState(null);

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);

    const [filters, set_filters] = React.useState({});

    useDidMountEffect(() => {
        dispatch(loadProductsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = useMemo(
        () => [
            {
                name: 'ID',
                selector: 'id',
                center: true,
                sortable: false,
                cell: row => <Link href={`/products/${row.id}`}>#{row.id}</Link>,
            },
            {
                name: 'Image',
                selector: 'image',
                center: true,
                cell: row => (
                    <Link href={`/products/${row.id}`} className="tr-logo">
                        <img src={row.image || row.variants?.[0].image || defaultLogo} alt="Logo" />
                    </Link>
                ),
            },
            {
                name: 'Name',
                selector: 'name',
                sortable: false,
                width: '200px',
                cell: row => (
                    <Link href={`/products/${row.id}`} className="tr-name">
                        <h5>
                            {row.company}
                            <small>{row.name_en}</small>
                        </h5>
                    </Link>
                ),
            },
            {
                name: 'Price',
                selector: 'price',
                sortable: false,
                cell: row => (
                    <>
                        <div className="tr-price">
                            <span>SAR</span>
                            <div>{formatMoney(row.price)}</div>
                        </div>
                    </>
                ),
            },
            {
                name: 'Take rate',
                cell: row => {
                    const [min, max] = getTakeRateRange(row.variants);

                    return (
                        <div className="tr-price">
                            <span>%</span>

                            <div>
                                {min === max ? (
                                    <span>{formatMoney(max)}</span>
                                ) : (
                                    <span>
                                        {formatMoney(min)} - {formatMoney(max)}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                name: 'Take rate amount',

                cell: row => {
                    const [min, max] = getRange(row.variants, 'take_rate_amount');

                    return (
                        <div className="tr-price">
                            <span>SAR</span>

                            <div>
                                {min === max ? (
                                    <span>{formatMoney(max)}</span>
                                ) : (
                                    <span>
                                        {formatMoney(min)} - {formatMoney(max)}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                name: 'Vendor',
                selector: 'vendor.name',
                sortable: false,
                cell: row => row?.vendor?.name,
            },
            {
                name: 'Stock',
                selector: 'stock',
                sortable: false,
            },
            {
                name: 'Stock type',
                selector: 'applied_stock_type',
                sortable: false,
            },
            {
                name: 'Tags',
                cell: row => {
                    const tags = row?.tags?.map(tag => tag.name_ar) || [];
                    return tags?.map(tag => (
                        <>
                            <span className="tag-item" key={randomId()}>
                                {tag}
                            </span>
                            &nbsp;
                        </>
                    ));
                },
            },
            {
                name: 'Status',
                selector: 'status',
                sortable: false,
                cell: row => {
                    let active = 0;
                    let inActive = 0;
                    let length = row.variants?.length;

                    row.variants?.forEach(variant => {
                        if (variant.is_active) active += 1;
                        else inActive += 1;
                    });

                    if (active === length) return 'Active';
                    if (inActive === length) return 'Inactive';

                    return `${active} of ${length} active`;
                },
            },
            {
                name: 'Created At',
                selector: 'created_date',
                sortable: false,
                cell: row => formatDate(row?.variants?.[0]?.created_date),
            },
            {
                name: 'Updated At',
                selector: 'updated_date',
                sortable: false,
                cell: row => formatDate(row?.variants?.[0]?.updated_date),
            },
        ],
        [ProductsSlice.products],
    );

    return (
        <div className="products-list-view">
            <ViewHeader title="Products">
                <Sop id="29963" />
                <button
                    className="btn btn-info"
                    onClick={() => downloadCSV('Products-exported', ProductsSlice.products)}
                >
                    Export
                </button>

                <Link href="/products/add" className="btn btn-primary">
                    New product
                </Link>
            </ViewHeader>

            <Card>
                <Filters
                    filters={[
                        'status',
                        'is_exclusive',
                        'stock_type',
                        'vendor',
                        'categories',
                        'price',
                        'profit_margin',
                        'take_rate',
                        'take_rate_amount',
                        'discount_value',
                        'discount_percentage',
                    ]}
                    onFilterChange={filters_values => {
                        set_filters(filters_values);
                    }}
                />
                <DataTable
                    data={ProductsSlice.products}
                    pages={ProductsSlice.products_pages}
                    page={page}
                    records={ProductsSlice.products_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={ProductsSlice.products_loading}
                    expandableRowsComponent={<Variants set_active_product={set_active_product} />}
                />
            </Card>

            <PriceHistoryModal
                productID={active_product}
                onClose={() => set_active_product(null)}
            />
        </div>
    );
}

const Variants = props => {
    const { data } = props;

    return (
        <>
            <div className="flex custom_expand_rows">
                <div style={{ width: 50 }}></div>
                <div style={{ flex: 1 }}>
                    {data?.variants?.map(
                        ({
                            id,
                            image,
                            name,
                            price,
                            stock,
                            applied_stock_type,
                            take_rate,
                            take_rate_amount,
                            tags,
                            created_date,
                            updated_date,
                            is_active,
                        }) => (
                            <div
                                className="grid grid-cols-14"
                                style={{ minHeight: 60, alignItems: 'center' }}
                                key={id}
                            >
                                <div className="rdt_TableCell">#{id}</div>
                                <div className="tr-logo rdt_TableCell variant_product_logo">
                                    <img src={image || defaultLogo} alt="Logo" />
                                </div>
                                <div className="rdt_TableCell" style={{ columnSpan:2 }}>
                                    <h5>
                                        <small>
                                            {name}
                                        </small>
                                    </h5>
                                </div>
                                <div className="rdt_TableCell">
                                    <>
                                        <div className="tr-price">
                                            <span>SAR</span>
                                            <div>{formatMoney(price)}</div>
                                        </div>
                                        {/* <div
                                            className="price-history-icon"
                                            onClick={() => handlePriceHistoryModal(id)}
                                        >
                                            <img src={HistoryIcon} alt="history" />
                                            <span>History</span>
                                        </div> */}
                                    </>
                                </div>
                                <div className="rdt_TableCell">
                                    <div className="tr-price">
                                        <span>%</span>
                                        <dir><span>{formatMoney(take_rate)}</span></dir>
                                    </div>
                                </div>
                                <div className="rdt_TableCell">
                                    <div className="tr-price">
                                        <span>SAR</span>
                                        <div><span>{formatMoney(take_rate_amount)}</span></div>
                                    </div>
                                </div>
                                <div style={{ width : '80px' }}>{data?.vendor?.name}</div>
                                <div className="rdt_TableCell">{stock}</div>
                                <div className="rdt_TableCell">{applied_stock_type}</div>
                                <div className="rdt_TableCell">
                                    {tags?.map(tag => (
                                        <>
                                            <span className="tag-item" key={randomId()}>
                                                {tag?.name_en}
                                            </span>
                                            &nbsp;
                                        </>
                                    ))}
                                </div>
                                <div className="rdt_TableCell">
                                    {is_active ? 'Active' : 'Inactive'}
                                </div>
                                <div className="rdt_TableCell">{formatDate(created_date)}</div>
                                <div className="rdt_TableCell">{formatDate(updated_date)}</div>
                            </div>
                        ),
                    )}
                </div>
            </div>
        </>
    );
};
