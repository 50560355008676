import React from 'react';
import { Row, Col, Select, Input, Button, Toggler, ShowErrors, Textarea } from '@components';
import { useSelector  } from 'react-redux';
import { POST ,GET } from '@utils';
import { toast } from 'react-toastify';
function ErpIntegrationTap() {
    const vendor = useSelector((state) => state.VendorsSlice?.vendor);
    const [is_update, set_is_update] =React.useState(false);

    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val }),
    });
    const [erp_integration_type, set_erp_integration_type] = React.useState({
        label: 'ERP integration type',
        options: [
            {
                value: 'SAP',
                label: 'SAP',
            },
            {
                value: 'ODOO',
                label: 'ODOO',
            },
            {
                value: 'MICROSOFT_DYNAMICS',
                label: 'Microsoft dynamics',
            },
            {
                value: 'CUSTOM',
                label: 'Custom',
            },
        ],
        value: '',
        onChange: (val) => set_erp_integration_type((prev) => ({ ...prev, value: val })),
    });
    const [host, set_host] = React.useState({
        label: 'Host',
        type: 'text',
        value: '',
        setValue: (val) => set_host({ ...host, value: val }),
    });
    const [auth, set_auth] = React.useState({
        label: 'Auth',
        options: [
            {
                value: 'Token',
                label: 'Token',
            },
            {
                value: 'UserPass',
                label: 'UserPass',
            },
            {
                value: null,
                label: 'None',
            },
        ],
        value: '',

        onChange: (val) => set_auth((prev) => ({ ...prev, value: val })),
    });
    const [api_version, set_api_version] = React.useState({
        label: 'API version',
        type: 'text',
        value: '',
        setValue: (val) => set_api_version({ ...api_version, value: val }),
    });
    const [bidirectional_changes, set_bidirectional_changes] = React.useState({
        label: 'Bidirectional changes enabled',
        options: [
            {
                value: true,
                label: 'Enabled',
            },
            {
                value: false,
                label: 'Disabled',
            },
        ],
        value: '',
        onChange: (val) => set_bidirectional_changes((prev) => ({ ...prev, value: val })),
    });
    const [invoice_generation, set_invoice_generation] = React.useState({
        label: 'Invoice generation',
        options: [
            {
                value: 'ERP',
                label: 'ERP',
            },
            {
                value: 'DISTY',
                label: 'Disty',
            },
        ],
        value: '',
        onChange: (val) => set_invoice_generation((prev) => ({ ...prev, value: val })),
    });
    const [header, set_header] = React.useState({
        label: 'Header Auth (JSON object)',
        value: '',
        setValue: (val) => set_header({ ...header, value: val }),
      });
    const [validation_errors, set_validation_errors] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [loading, set_loading] = React.useState(false);
    // Validate all fields are filled and header is valid JSON
    const validate = () => {
        const newErrors = {};
        if (!erp_integration_type.value)
            newErrors.erp_integration_type = 'ERP integration type is required';
        if (!host.value) newErrors.host = 'Host is required';
        if (!auth.value) newErrors.auth = 'Auth is required';
        if (!api_version.value) newErrors.api_version = 'API version is required';
        if (!bidirectional_changes.value)
            newErrors.bidirectional_changes = 'Bidirectional changes enabled is required';
        if (!invoice_generation.value)
            newErrors.invoice_generation = 'Invoice generation is required';
        try {
      
            if (typeof JSON.parse(header.value) !== 'object' || Array.isArray(JSON.parse(header.value)) || Object.keys(JSON.parse(header.value)).length === 0) {
                throw new Error();}
          } catch (e) {
            newErrors.header = 'Header must be a valid JSON object like this {"token" : "Hello"}';
          }
        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Validate on value change
    React.useEffect(() => {
        validate();
    }, [
        erp_integration_type,
        host,
        auth,
        api_version,
        bidirectional_changes,
        invoice_generation,
        header,
    ]);
    const [vendor_erp_config,set_vendor_erp_config]=React.useState({});
    const fetchData = async () => {
     
        if ( vendor?.id) {
        const res = await GET({ endpoint:`/vendors/vendor-erp-config/${vendor?.id}` });
        if (res.success) {
         set_is_update(true) ;
         set_vendor_erp_config(res.data);
        }}else{
            set_is_update(false);
        }};
    React.useEffect(()=>{
        fetchData();
    },[vendor.id]);
    React.useEffect(()=>{
        if (vendor_erp_config.id && vendor?.id) {
            set_is_update(true) ;
            status.onChange(vendor_erp_config.active);
            erp_integration_type.onChange( erp_integration_type.options.find(
                (o) => o.value === vendor_erp_config.integration_type
            ));
            invoice_generation.onChange(invoice_generation.options.find(
                (o) => o.value === vendor_erp_config.invoice_generation
            ));
            bidirectional_changes.onChange(bidirectional_changes.options.find(
                (o) => o.value === vendor_erp_config.is_bidirectional
            )) ;
            host.setValue(vendor_erp_config.host);
            api_version.setValue(vendor_erp_config.api_version);
            header.setValue( JSON.stringify(vendor_erp_config.header));
            auth.onChange(auth.options.find(
                (o) => o.value === vendor_erp_config.auth_type
            )) ;
        }else{
            set_is_update(false);
        }
    },[vendor_erp_config,vendor?.id]);

    const submit = async () => {
        set_loading(true);
        if (validate()) {
            console.log(`/vendors/vendor-erp-config/${vendor.id}`);
          const endpoint = is_update ? `/vendors/vendor-erp-config/${vendor.id}` : '/vendors/vendor-erp-config';
          const res = await POST({
            endpoint,
            data: {
                merchant_id:vendor.id,
                integration_type: erp_integration_type.value.value,
                invoice_generation: invoice_generation.value.value,
                host: host.value,
                api_version: api_version.value,
                is_bidirectional: bidirectional_changes.value.value,
                active: status.checked ? 1 : 0,
                header: JSON.parse(header.value),
               auth_type:auth.value.value
            }
        });
      
        set_loading(false);
        setErrors([]);
        if (res?.status_code === 422) {
            setErrors(res?.data.errors);
        } if (res?.status_code === 201 || res?.status_code === 200) {
         toast.success(res?.message);
            fetchData();
        }
    }
    };

    return (
        <div>
            {!!Object.keys(errors).length && (
                  <ShowErrors errors={errors} />
                  )}
             
            <Toggler {...status} />
            <Row>
                <Col lg={6}>
                    <Select {...erp_integration_type} />
                    {loading && validation_errors.erp_integration_type && (
                        <p style={{ color: 'red' ,margin:'-10px 0 10px' }}>
                            {validation_errors.erp_integration_type}
                        </p>
                    )}
                    <Select {...auth} />
                    {loading && validation_errors.auth && <p style={{ color: 'red'  ,margin:'-10px 0 10px' }}>{validation_errors.auth}</p>}
                    <Input {...host} />
                    {loading && validation_errors.host && <p style={{ color: 'red'  ,margin:'-10px 0 10px' }}>{validation_errors.host}</p>}
                </Col>
                <Col lg={6}>
                    <Select {...bidirectional_changes} />
                    {loading && validation_errors.bidirectional_changes && (
                        <p style={{ color: 'red'  ,margin:'-10px 0 10px'  }}>{validation_errors.bidirectional_changes}</p>
                    )}
                    <Select {...invoice_generation} />
                    {loading && validation_errors.invoice_generation && (
                        <p style={{ color: 'red'  ,margin:'-10px 0 10px' }}>{validation_errors.invoice_generation}</p>
                    )}
                    <Input {...api_version} />
                    {loading && validation_errors.api_version && (
                        <p style={{ color: 'red'  ,margin:'-10px 0 10px' }}>{validation_errors.api_version}</p>
                    )}
                </Col>
            </Row>
            <Row>
        <Col lg={12}>
          <Textarea {...header}/>
          {loading && validation_errors.header && (
            <p style={{ color: 'red', margin: '-10px 0 10px' }}>{validation_errors.header}</p>
          )}
        </Col>
      </Row>
            <Button onClick={submit}>{is_update?"Update":"Save"}</Button>
        </div>
    );
}
export default ErpIntegrationTap;
