import React from 'react';
import {
    ButtonGroup,
    DropdownButton,
    Dropdown,
    StatusBlock,
    ViewHeader,
    Link,
    DataTable,
    Card,
    Spinner,
    //Button,
    Filters,
    OrderStateModal,
    Sop,
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadChildOrdersData, update_single_order_from_list } from '@slices/childOrders.slice';
import { setModalStatus } from '@slices/ui.slice';
import { toast } from 'react-toastify';
import {
    generateSuborderBarcode,
    formatDate,
    formatMoney,
    POST,
    GET,
    errorsNormalizer,
} from '@utils';
import barcodeIcon from '@assets/icons/barcode.svg';
import cancelIcon from '@assets/icons/cancel.png';
import checkedIcon from '@assets/icons/checked.png';
import useDidMountEffect from '@hooks/useDidMountEffect';

import BoxesForm, { printBoxes } from './Ops2/BoxesForm';

export default function ChildOrdersListView() {
    const ChildOrdersSlice = useSelector((state) => state.ChildOrdersSlice);
    const dispatch = useDispatch();
    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [submitting, set_submitting] = React.useState(false);
    const [updating_suborder_status_id, set_updating_suborder_status_id] = React.useState(null);
    const [suborderModal, setSuborderModal] = React.useState(null);
    const [boxes_child_order_id, set_boxes_child_order_id] = React.useState(false);
    const [filters, set_filters] = React.useState({
        // child order level filters
        suborder_states: [],
        vendors: [],
        cities: [],
        created_at_from: null,
        created_at_to: null,
        sort_by: 'id',
        sort_direction: 'desc',
        scheduled_order_delivery_option_id: [],
        entity_types: [],
        delivery_fees_payer: [],
        // parent order level filters
        shipment_preferences: [],
        order_states: [],
        order_payment_states: [],
        pay_later_providers: null,
        is_customer_first_order: null,
    });

    const loadData = () => {
        dispatch(loadChildOrdersData({ ...filters, page, records_per_page }));
    };

    const submitBoxesCount = (closeModal) => async (data) =>
        await POST({ endpoint: `/suborder/${boxes_child_order_id}/boxes`, data }).then((res) => {
            if (res.status_code === 200) {
                set_boxes_child_order_id(null);
                toast.info('Child Order Boxes Created Successfully');
                dispatch(
                    update_single_order_from_list({
                        id: res.data.id,
                        boxes: {
                            boxes: res?.data?.boxes_count,
                            actions: res?.data?.actions,
                        },
                    }),
                );
                closeModal('child_order_boxes_count_modal');
                printBoxes(boxes_child_order_id);
            } else {
                toast.error(res.data?.message);
            }
        });

    useDidMountEffect(() => {
        loadData();
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'Child ID',
            selector: 'pretty_id',
            width: '110px',
            center: true,
            cell: (row) => (
                <Link target="_blank" href={`/orders/${row.order.id}/${row.pretty_id}`}>
                    #{row.pretty_id}
                </Link>
            ),
        },
        {
            name: 'Child Fulfillment Status',
            minWidth: '300px',
            selector: 'state.description',
            cell: (row) => {
                if (updating_suborder_status_id === row.id) {
                    return <Spinner size="xs" animation="border" />;
                }

                return Array.isArray(row.actions) && row.actions.length > 0 ? (
                    <ButtonGroup>
                        <DropdownButton
                            as={ButtonGroup}
                            title={<StatusBlock state={row.state} />}
                            id="bg-nested-dropdown"
                            variant="transparent"
                            className="child-fulfillment-status"
                        >
                            {row.actions.map((action, i) => (
                                <Dropdown.Item
                                    key={i}
                                    eventKey={i}
                                    onClick={() => {
                                        const { id, pretty_id } = row;

                                        setSuborderModal({
                                            id,
                                            pretty_id,
                                            state: action.code,
                                            title: action.action,
                                            reasons: action.reasons,
                                        });
                                        dispatch(
                                            setModalStatus({
                                                modal: 'single_order_view__suborder_modal',
                                                status: true,
                                            }),
                                        );
                                    }}
                                >
                                    {action.action}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </ButtonGroup>
                ) : (
                    <>
                        <StatusBlock state={row.state} />
                    </>
                );
            },
        },
        /*{
            name: 'Boxes',
            center: true,
            cell: (row) => {
                const hasActions = row.boxes?.actions?.length;
                const hasPostAction = row.boxes?.actions?.some(
                    ({ action }) => action.toLowerCase() === 'post',
                );
                const boxesCount = row.boxes?.boxes;

                if ((hasActions && !hasPostAction) || boxesCount > 0)
                    return (
                        <Link
                            className={!row.boxes?.boxes && 'disabled-link'}
                            to={row.boxes?.boxes && `/child-orders/${row.id}/boxes`}
                        >
                            {boxesCount} Boxes
                        </Link>
                    );

                return (
                    <Button
                        disabled={!hasPostAction}
                        className="text-white"
                        variant="primary"
                        onClick={() => {
                            dispatch(
                                setModalStatus({
                                    modal: 'child_order_boxes_count_modal',
                                    status: true,
                                }),
                            );
                            set_boxes_child_order_id(row.id);
                        }}
                    >
                        + Create
                    </Button>
                );
            },
        },*/
        {
            name: 'Parent Payment status',
            selector: 'order.payment_status.description',
            minWidth: '200px',
            cell: (row) => <StatusBlock state={row.order.payment_status} />,
        },
        {
            name: 'Pay later provider',
            cell: (row) => <>{row?.order?.pay_later_provider?.description}</>,
        },
        {
            name: 'Is scheduled',
            selector: 'order.scheduled_order_id',
            center: true,
            cell: (row) =>
                row?.order?.scheduled_order_delivery_option?.id ? (
                    row?.order?.scheduled_order_delivery_option?.title_en
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Is parent order delivered',
            center: true,
            cell: (row) =>
                row?.order?.is_delivered ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Parent State',
            minWidth: '180px',
            selector: 'order.state.description',
            cell: (row) => <StatusBlock state={row.order.state} />,
        },
        {
            name: 'Delivery Charge On',
            width: '150px',
            cell: (row) =>
                row?.delivery_fees_payer && (
                    <span>
                        {row?.delivery_fees_payer}
                        {row?.delivery_fees !== null
                            ? ` (${formatMoney(row?.delivery_fees)} SAR)`
                            : ''}
                    </span>
                ),
        },
        {
            name: 'Invoice model',
            cell: (row) => row?.invoicing_model?.description,
        },
        {
            name: 'Customer',
            selector: 'customer',
            cell: (row) => (
                <Link
                    href={`/customers/${row.customer.id}`}
                    target="_blank"
                    className="tr-name tr-customer-info"
                >
                    <h5>
                        {row.customer.store_name}
                        <small>{row.customer.name}</small>
                    </h5>
                </Link>
            ),
        },
        {
            name: 'Region',
            selector: 'region',
            width: '80px',
            cell: (row) => row?.region,
        },
        {
            name: 'Customer first order',
            selector: 'is_customer_first_order',
            center: true,
            cell: (row) =>
                row?.is_customer_first_order ? (
                    <img src={checkedIcon} alt="Yes" />
                ) : (
                    <img src={cancelIcon} alt="No" className="dimmed-icon" />
                ),
        },
        {
            name: 'Merchant',
            selector: 'vendor.company_name',
            cell: (row) => (
                <>
                    <Link href={`/merchants/${row.vendor.id}`} className="tr-name">
                        <h5>{row.vendor.company_name}</h5>
                    </Link>
                </>
            ),
        },
        {
            name: 'Amount',
            selector: 'total',
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total)}</div>
                </div>
            ),
        },

        {
            name: 'Creation Date',
            selector: 'created_at',
            minWidth: '150px',
            format: (r) => formatDate(r.created_at),
        },
        {
            name: 'Last Update Date',
            selector: 'updated_at',
            minWidth: '150px',
            format: (r) => formatDate(r.updated_at),
        },
        {
            name: 'Warehouse cell',
            cell: (row) => (
                <>
                    <Link href="/warehouse/list">
                        {row?.order?.warehouse_allocation?.location_name}
                    </Link>
                    {row?.order?.warehouse_allocation?.location_name && (
                        <button
                            className="btn download-barcode"
                            onClick={() =>
                                generateSuborderBarcode({
                                    pretty_id: row.pretty_id,
                                    cell: row?.order?.warehouse_allocation?.location_name,
                                    vendor_name: row.vendor.company_name,
                                })
                            }
                        >
                            <img src={barcodeIcon} alt="" />
                        </button>
                    )}
                </>
            ),
        },
        {
            name: 'Shipping preference',
            minWidth: '120px',
            cell: (row) =>
                row?.shipment_preference ? (
                    <StatusBlock
                        state={
                            row?.shipment_preference
                                ? {
                                      code: row?.shipment_preference.code,
                                      description: row?.shipment_preference?.code,
                                  }
                                : {}
                        }
                        noIcon={true}
                        style={{
                            backgroundColor:
                                row.shipment_preference?.code === 'one_shipment'
                                    ? '#27ae60'
                                    : '#3498db',
                        }}
                    />
                ) : (
                    ''
                ),
        },
    ];

    const getChildOrderActions = async (id) => {
        const res = await GET({
            endpoint: `/suborder/${id}`,
        });

        if (res.status_code === 200) {
            return res?.data?.actions || [];
        }

        return [];
    };

    const getChildOrderBoxes = async (id) => {
        const res = await GET({
            endpoint: `/suborder/${id}/boxes`,
        });

        if (res.status_code === 200) {
            return { boxes: res?.data.boxes_count, actions: res?.data?.actions };
        }
    };

    const runSuborderAction = async (sid, state, reasons, selected_reasons) => {
        set_updating_suborder_status_id(sid);
        set_submitting(true);
        const data = reasons?.length
            ? {
                  state,
                  parent_reason: selected_reasons?.key,
                  child_reason: selected_reasons?.sub_key,
                  details: selected_reasons?.other_details,
              }
            : { state };
        const res = await POST({
            endpoint: `/suborder/${sid}/state`,
            data,
        });
        set_submitting(false);

        if (res?.status_code === 200) {
            let action = /\s+/.test(suborderModal.title)
                ? `marked as ${suborderModal.title.toLowerCase()}`
                : suborderModal.title.toLowerCase().replace(/e?$/, 'ed');
            toast.info(`Suborder ${suborderModal?.pretty_id} is ${action}`);

            const [actions, boxes] = await Promise.all([
                getChildOrderActions(sid),
                getChildOrderBoxes(sid),
            ]);

            // update the order state in local state to reflect the change
            dispatch(
                update_single_order_from_list({
                    id: sid,
                    actions: actions,
                    ...(boxes ? { boxes } : {}),
                    state: {
                        code: state,
                        description: suborderModal.title,
                    },
                }),
            );
        } else {
            toast.error(errorsNormalizer(res?.data?.errors).join(', '));
        }
        set_updating_suborder_status_id(null);
    };

    return (
        <>
            <div className="customers-list-view">
                <ViewHeader title="Child Orders">
                    <Sop id="24443" />
                </ViewHeader>
                <Card>
                    <Filters
                        queryPlaceholder="Search by child id, customer name, store name, vendor"
                        filters={[
                            'child_order_state',
                            'order_states',
                            'order_payment_states',
                            'business_model',
                            'invoicing_models',
                            'payment_methods',
                            'account_managers',
                            'created_at_from',
                            'created_at_to',
                            'vendors',
                            'entity_types',
                            'total_from',
                            'total_to',
                            'regions',
                            'cities',
                            'platforms',
                            'sources',
                            'delivery_fees_payer',
                            'shipment_preferences',
                            'pay_later_providers',
                            'scheduled_order_delivery_option_id',
                            'is_customer_first_order',
                            'total',
                        ]}
                        onFilterChange={(filters_values) => {
                            set_filters(filters_values);
                        }}
                        initialFiltersValues={{
                            sort_by: 'created_at',
                        }}
                        sort_options={[
                            { label: 'Order ID', value: 'id' },
                            {
                                label: 'Updated at (Newest first)',
                                value: 'updated_at__desc',
                                direction: 'desc',
                            },
                            {
                                label: 'Updated at (Oldest first)',
                                value: 'updated_at__asc',
                                direction: 'asc',
                            },
                        ]}
                    />

                    <DataTable
                        data={ChildOrdersSlice.child_orders}
                        pages={ChildOrdersSlice.child_orders_pages}
                        records={ChildOrdersSlice.child_orders_records}
                        page={page}
                        records_per_page={records_per_page}
                        set_records_per_page={set_records_per_page}
                        handlePageChange={set_page}
                        columns={columns}
                        searchable={false}
                        loading={ChildOrdersSlice.child_orders_loading}
                    />
                </Card>
            </div>
            <OrderStateModal
                suborderModal={suborderModal}
                onSubmit={(closeModal, selected_reasons) => {
                    runSuborderAction(
                        suborderModal.id,
                        suborderModal.state,
                        suborderModal.reasons,
                        selected_reasons,
                    );

                    closeModal('single_order_view__suborder_modal');
                }}
                loading={submitting}
            />
            {!!boxes_child_order_id && <BoxesForm onSubmit={submitBoxesCount} />}
        </>
    );
}
