import React from 'react';
import {
    ViewHeader,
    Link,
    Card,
    DataTable,
    StatusToggler,
    Filters,
    Sop
} from '@components';
import defaultLogo from '@assets/img/default.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { loadBannersData, updateSingleRow } from '@slices/banners.slice';
import { formatDate } from '@utils';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function BannersListView() {
    const BannersSlice = useSelector((state) => state.BannersSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({
        status: null,bannerPosition:null
    });

    useDidMountEffect(() => {
        dispatch(loadBannersData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'Image',
            selector: 'banner_image_ar',
            center: true,
            width: '100px',
            cell: (row) => (
                <Link href={`/banners/${row.id}`} className="tr-logo">
                    <img src={row.banner_image_ar || defaultLogo} alt="Logo" />
                </Link>
            )
        },
        {
            name: 'Arabic Name',
            selector: 'name_ar',
            sortable: false,
            cell: (row) => (
                <Link href={`/banners/${row.id}`} className="tr-name">
                    <h5>{row.name_ar}</h5>
                </Link>
            )
        },
        {
            name: 'English Name',
            selector: 'name_en',
            sortable: false,
            cell: (row) => (
                <Link href={`/banners/${row.id}`} className="tr-name">
                    <h5>{row.name_en}</h5>
                </Link>
            )
        },
        {
            name: 'Priority',
            selector: 'display_priority',
            sortable: false,
            cell: (row) => (
                 <h5>{row.banner_priority}</h5>
            )
        },
        {
            name: 'Banner Position',
            selector: 'banner_position',
            sortable: false,
            cell: (row) => (
                 <h5>{row.banner_position}</h5>
            )
        },
        {
            name: 'From',
            selector: 'starts_at',
            sortable: false,
            format: (r) => formatDate(r.starts_at)
        },
        {
            name: 'To',
            selector: 'ends_at',
            format: (r) => formatDate(r.ends_at)
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row.status}
                    path={`/marketing/banners/${row.id}/activate`}
                    pathOff={`/marketing/banners/${row.id}/deactivate`}
                    method="post"
                    callback={({ id, status }) => {
                        dispatch(updateSingleRow({ id, status: status }));
                    }}
                />
            )
        }
    ];

    return (
        <div className="banners-list-view">
            <ViewHeader title="Banners">
                <div className="flex__ai-center">
                    <Sop id="25323"/>
                    {/* <Link href="/banners/sort" className="btn btn-info">
                    Sort banners
                    </Link> */}
                    <Link href="/banners/add" className="btn btn-primary">
                    New banner
                    </Link>
                </div>
           
            </ViewHeader>

            <Card>
                <Filters
                    filters={[
                        'status' ,'bannerPosition'
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'status',
                        sort_direction: 'desc',
                    }}
                    sort_options={[
                        {
                            label: 'ID (DESC)',
                            value: 'id__desc',
                            direction: 'desc'
                        },
                        {
                            label: 'ID (ASC)',
                            value: 'id__asc',
                            direction: 'asc'
                        },
                        {
                            label: 'Status (DESC)',
                            value: 'status__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Status (ASC)',
                            value: 'status__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={BannersSlice.banners}
                    pages={BannersSlice.banners_pages}
                    page={page}
                    records={BannersSlice.banners_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={BannersSlice.banners_loading}
                />
            </Card>

        </div>
    );
}
