import React from 'react';
import { Card, Select, ShowErrors } from '@components';
import submitCommentIcon from '@assets/icons/submit-comment.svg';
import { randomId, formatDate, GET } from '@utils';
import { useSelector } from 'react-redux';
import styles from './FinanceComments.module.scss';

const FinanceComments = ({ comments, onSubmit }) => {
    const [comment, setComment] = React.useState('');
    const [comment_type, set_comment_type] = React.useState();
    const [types, set_types] = React.useState([]);
    const [uuid, setUuid] = React.useState('');
    const [sending, setSending] = React.useState(false);
    const [errors, set_errors] = React.useState({});

    const { user, is_super_admin } = useSelector((state) => state.AuthSlice);

    const permissions = {
        canShowComments:
            is_super_admin || user?.permissions?.includes('orderComments.list_finance_comments'),
        canCreateComment:
            is_super_admin || user?.permissions?.includes('orderComments.create_finance_comments'),
    };

    const fetchTypes = async () => {
        const res = await GET({
            endpoint: '/order/finance-comment-type',
        });

        const options = res?.data?.map((t) => ({
            label: t.type_en,
            value: t.id,
        }));

        set_types(options || []);
    };

    const submitComment = async () => {
        setSending(true);
        const req = await onSubmit(comment, comment_type.value, uuid);

        if (!req?.success) {
            const errors = req?.data.errors;
            const message = { error: [req?.data.message || 'something wrong'] };
            const defaultError = { error: ['something wrong'] };

            set_errors(errors || message || defaultError);
        } else {
            setComment('');
            set_comment_type(null);
            setUuid(randomId());
        }

        setSending(false);
    };

    React.useEffect(() => {
        if (!permissions.canShowComments) return;

        setUuid(randomId());
        fetchTypes();
    }, []);

    if (!permissions.canShowComments) return null;

    return (
        <Card title="Finance Comments" className={styles.comments}>
            {comments && comments.length > 0 ? (
                <div className={styles.comments__list}>
                    {comments.map((c, i) => (
                        <div className={styles.comments__item} key={i}>
                            <div className="avatar">JD</div>
                            <div className="content">
                                <div className="flex__jc-between">
                                    <h4>{c.user}</h4>
                                    <span>{formatDate(c.date)}</span>
                                </div>
                                <div className="comment_type">{c.type}</div>
                                <p>{c.comment}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="alert alert-light text-center" role="alert">
                    No comments yet!
                </div>
            )}

            {!!Object.keys(errors).length && (
                <div style={{ margin: '0 10px' }}>
                    <ShowErrors errors={errors} />
                </div>
            )}

            {permissions.canCreateComment && (
                <>
                    <div className={styles.comments__type}>
                        <Select
                            label="Comment Type"
                            value={comment_type}
                            options={types}
                            onChange={(v) => set_comment_type(v)}
                            disabled={sending}
                        />
                    </div>
                    <div className={styles.comments__addNew}>
                        <textarea
                            disabled={sending}
                            placeholder="Leave a comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <button disabled={sending || !comment_type} onClick={submitComment}>
                            <img src={submitCommentIcon} alt="Submit" />
                        </button>
                    </div>
                </>
            )}
        </Card>
    );
};

export default FinanceComments;
