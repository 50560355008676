import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import { Helmet } from 'react-helmet';
import StatsView from './Stats';

export default function StatsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/statistics">
                <Helmet>
                    <title>Stats | Disty Admin Dashboard</title>
                </Helmet>
                <StatsView />
            </ProtectedRoute>   
        
        </Switch>
    );
}