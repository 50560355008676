export const resizeImage = (file, cb) => {
    const img = new Image();
    img.onload = function () {
        const maxWidth = img.width / 2;
        const maxHeight = img.height / 2;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const ratio = img.width / img.height;
        if (ratio > 1) {
            canvas.width = maxWidth;
            canvas.height = maxWidth / ratio;
        } else {
            canvas.height = maxHeight;
            canvas.width = maxHeight * ratio;
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        cb(canvas.toDataURL('image/jpeg'));
    };
    img.src = URL.createObjectURL(file);
};

export const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    const byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
};

export const optimizeImage = async (image, maxSize = 3145728) => {
    return new Promise((resolve) => {
        let optimizeImage;
        if (image.size > maxSize && image.type.match('image.*')) {
            resizeImage(image, (rezisedFile) => {
                optimizeImage = dataURItoBlob(rezisedFile);
                if (optimizeImage) resolve(optimizeImage);
            });
        }
        else resolve(image);
    });
};

export default {};
