export const normalizeVariantPayload = (
    {
        id,
        plan,
        shelf_life,
        shelf_life_unit,
        package_type_id,
        seo_meta_keywords_ar,
        seo_meta_keywords_en,
        // custom_attributes,
        tags,
        categories,
        release_date,
        applied_stock_type,
        storage_conditions,
        range_percentage,
        accessories,
        delivery_type_id,
        images,
        video,
        options,
        boxes_per_vendor_carton,
        description_ar,
        description_en,
        item_volume,
        item_volume_unit,
        item_weight,
        item_weight_unit,
        name_ar,
        name_en,
        storage_instructions,
        order_max_quantity,
        order_min_quantity,
        order_quantity_step,
        packaging_height,
        packaging_length,
        packaging_weight,
        packaging_weight_unit,
        packaging_width,
        packaging_stackable,
        packaging_max_stackable_level,
        packaging_combinable,
        price,
        seo_link,
        seo_meta_description_ar,
        seo_meta_description_en,
        seo_page_title_ar,
        seo_page_title_en,
        shelf_life_details,
        sku_code,
        stock,
        suggested_retail_price,
        units_per_pack,
        upc_code,
        vendor_code,
        vendor_notes_ar,
        vendor_notes_en,
        status, // client side only field to check if the variant is old or newly added
        quantity_prices,
        delivery_type,

        // boolean
        out_of_stock,
        is_active,
        has_variants,
        is_returnable,
        allow_backorder,
        has_taxes,
        is_featured,
        is_exclusive,
        is_owned_by_disty,
        //product details
        serial_number,model_number, 
        screen_size,
        processor,
        operating_system,
        general_info_en,
        general_info_ar,
        trade_mark_en,
        trade_mark_ar,
        model_name_en,
        model_name_ar,
        wireless_carrier_en,
        wireless_carrier_ar,
        cellular_technology_ar,
        storage_capacity_en,
        storage_capacity_ar,
        communication_technology_en,
        communication_technology_ar,
        color_en,
        color_ar,
        network_technology_en,
        network_technology_ar,
        port_type_en,
        port_type_ar,
        udf1_en,
        udf1_ar,
        udf2_en,
        udf2_ar,
        udf3_en,
        udf3_ar,
        udf4_en,
        udf4_ar, 
    },
    isUpdate = false,
) => {
    return {
        // normal texts
        id,
        boxes_per_vendor_carton,
        description_ar,
        description_en,
        item_volume,
        item_volume_unit,
        item_weight,
        item_weight_unit,
        name_ar,
        storage_instructions,
        name_en,
        order_max_quantity,
        order_min_quantity,
        order_quantity_step,
        packaging_height,
        packaging_length,
        packaging_weight,
        packaging_weight_unit,
        packaging_width,
        price,
        seo_link,
        seo_meta_description_ar,
        seo_meta_description_en,
        seo_page_title_ar,
        seo_page_title_en,
        shelf_life_details,
        sku_code,
        suggested_retail_price,
        units_per_pack,
        upc_code,
        vendor_code,
        vendor_notes_ar,
        vendor_notes_en,
//product details
serial_number,
model_number , 
screen_size,
processor,
operating_system,
general_info_en,
general_info_ar,
trade_mark_en,
trade_mark_ar,
model_name_en,
model_name_ar,
wireless_carrier_en,
wireless_carrier_ar,
cellular_technology_ar,
storage_capacity_en,
storage_capacity_ar,
communication_technology_en,
communication_technology_ar,
color_en,
color_ar,
network_technology_en,
network_technology_ar,
port_type_en,
port_type_ar,
udf1_en,
udf1_ar,
udf2_en,
udf2_ar,
udf3_en,
udf3_ar,
udf4_en,
udf4_ar,
        // boolean
        has_variants: booleanToNumber(has_variants),
        is_returnable: booleanToNumber(is_returnable),
        allow_backorder: booleanToNumber(allow_backorder),
        is_owned_by_disty: booleanToNumber(is_owned_by_disty),
        has_taxes: booleanToNumber(has_taxes),
        is_featured: booleanToNumber(is_featured),
        is_exclusive: booleanToNumber(is_exclusive),
        out_of_stock: booleanToNumber(out_of_stock),
        packaging_stackable: booleanToNumber(nullToFalse(packaging_stackable)),
        packaging_combinable: booleanToNumber(nullToFalse(packaging_combinable)),

        // remove packaging_max_stackable_level if packaging_stackable is false
        ...(packaging_stackable && { packaging_max_stackable_level }),

        // objects and arrays
        plan_id: plan?.id,
        // custom_attributes: custom_attributes
        //     ?.filter(customAttributeHasValue)
        //     // eslint-disable-next-line no-unused-vars
        //     .map(({ name, product_custom_attribute_id, id, ...custom_attribute }) => ({
        //         ...custom_attribute,
        //         product_custom_attribute_id: product_custom_attribute_id || id,
        //     })),
        seo_meta_keywords_ar:
            seo_meta_keywords_ar && Array.isArray(seo_meta_keywords_ar)
                ? seo_meta_keywords_ar?.map((t) => t.label).join(',')
                : '',
        seo_meta_keywords_en:
            seo_meta_keywords_en && Array.isArray(seo_meta_keywords_en)
                ? seo_meta_keywords_en?.map((t) => t.label).join(',')
                : '',
        shelf_life:
            shelf_life && shelf_life_unit ? `${shelf_life} ${shelf_life_unit}` : shelf_life || '',
        release_date: updateDate(release_date),
        categories: categories?.map(({ id }) => id),
        tags: tags?.map(({ id }) => id),
        applied_stock_type:
            applied_stock_type === 'NONE' || !applied_stock_type ? null : applied_stock_type,
        package_type_id: package_type_id || null,
        delivery_type_id: delivery_type_id?.id || delivery_type?.id,
        storage_conditions: storage_conditions?.map(({ id }) => id),
        range_percentage: range_percentage?.map(({ min_quantity,max_quantity,percentage }) =>({ min_quantity,max_quantity,percentage }) ),
        accessories: accessories?.map(({ id }) => id),
        options: options?.map(
            ({ product_option_id, option_id, product_option_value_id, value_id }) => ({
                product_option_id: product_option_id || option_id,
                product_option_value_id: product_option_value_id || value_id,
            }),
        ),

        ...(status === 'new'
            ? {
                  is_active: booleanToNumber(is_active),
                  stock,
                  quantity_prices: quantity_prices?.map(({ quantity, price }) => ({
                      quantity,
                      price,
                  })),
                  video: video?.length ? video?.[0] : null,
              }
            : {}),

        // remove create only fields in update
        ...(isUpdate
            ? {}
            : {
                  has_sample: false,
                  images: images?.filter((img) => !img?.id),
                  video: video?.length ? video?.[0] : null,
              }),
    };
};

export const customAttributeHasValue = ({ value_ar, value_en, unit_ar, unit_en }) =>
    value_ar || value_en || unit_ar || unit_en;

export const booleanToNumber = (val) => (typeof val !== 'boolean' ? val : val ? 1 : 0);

export const normalizePayload = ({
    brand,
    categories,
    tags,
    vendor,
    package_type_id,
    plan,
    variants,
    vendor_id,
    storage_conditions,
    range_percentage,
    accessories,
    delivery_type_id,
    is_active,
    is_returnable,
    allow_backorder,
    has_taxes,
    is_featured,
    is_exclusive,
    is_owned_by_disty,

    ...payload
}) => {
    return {
        /** @todo remove unwanted keys */
        ...payload,

        is_active: booleanToNumber(is_active),
        has_variants: booleanToNumber(variants.length > 1),
        is_returnable: booleanToNumber(is_returnable),
        allow_backorder: booleanToNumber(allow_backorder),
        has_taxes: booleanToNumber(has_taxes),
        is_featured: booleanToNumber(is_featured),
        is_exclusive: booleanToNumber(is_exclusive),
        is_owned_by_disty: booleanToNumber(is_owned_by_disty),

        brand_id: brand?.id,
        tags: tags?.map(({ id }) => id),
        categories: categories?.map(({ id }) => id),
        package_type_id: package_type_id || null,
        plan_id: plan?.id,
        vendor_id: vendor_id || vendor?.id,
        /** @todo remove this as it's moved to normalizeVariantPayload function  */
        delivery_type_id: delivery_type_id?.id,
        storage_conditions: storage_conditions?.map(({ id }) => id),
        range_percentage: range_percentage?.map(({ min_quantity,max_quantity,percentage }) =>({ min_quantity,max_quantity,percentage }) ),
        accessories: accessories?.map(({ id }) => id),
        variants: variants?.map(({ options, ...variant }) => ({
            ...variant,
            /** @todo remove this as it's moved to normalizeVariantPayload function */
            options: options?.map(
                ({ product_option_id, option_id, product_option_value_id, value_id }) => ({
                    product_option_id: product_option_id || option_id,
                    product_option_value_id: product_option_value_id || value_id,
                }),
            ),
        })),
    };
};

const nullToFalse = (value) => (value === null ? false : value);

export const getRange = (variants, key) => {
    if (!Array.isArray(variants) || variants.length === 0) return [];
    const _variants = [...variants];

    _variants.sort((a, b) => a[key] - b[key]);

    const lowest = _variants[0][key];
    const highest = _variants[_variants.length - 1][key];
    return [lowest, highest];
};

export const getPriceRange = (variants) => {
    return getRange(variants, 'price');
};

export const getTakeRateRange = (variants) => {
    return getRange(variants, 'take_rate');
};

export const initBaseForm = ({
    categories = [],
    tags = [],
    storage_conditions,
    range_percentage =[],
    accessories,
    vendor,
    brand,
    name_en,
    name_ar,
    storage_instructions,
    description_en,
    description_ar,
    is_returnable,
    allow_backorder,
    has_taxes,
    is_owned_by_disty,
    serial_number,
    model_number,
    screen_size,
    processor,
    operating_system,
    general_info_en,
    general_info_ar,
    trade_mark_en,
    trade_mark_ar,
    model_name_en,
    model_name_ar,
    wireless_carrier_en,
    wireless_carrier_ar,
    cellular_technology_ar,
    storage_capacity_en,
    storage_capacity_ar,
    communication_technology_en,
    communication_technology_ar,
    color_en,
    color_ar,
    network_technology_en,
    network_technology_ar,
    port_type_en,
    port_type_ar,
    udf1_en,
    udf1_ar,
    udf2_en,
    udf2_ar,
    udf3_en,
    udf3_ar,
    udf4_en,
    udf4_ar,
}) => ({
    name_en,
    name_ar,
    storage_instructions,
    description_en,
    description_ar,
    is_returnable,
    has_taxes,
    allow_backorder,
    is_owned_by_disty,
    serial_number,
    model_number,
    screen_size,
    processor,
    operating_system,
    general_info_en,
    general_info_ar,
    trade_mark_en,
    trade_mark_ar,
    model_name_en,
    model_name_ar,
    wireless_carrier_en,
    wireless_carrier_ar,
    cellular_technology_ar,
    storage_capacity_en,
    storage_capacity_ar,
    communication_technology_en,
    communication_technology_ar,
    color_en,
    color_ar,
    network_technology_en,
    network_technology_ar,
    port_type_en,
    port_type_ar,
    udf1_en,
    udf1_ar,
    udf2_en,
    udf2_ar,
    udf3_en,
    udf3_ar,
    udf4_en,
    udf4_ar,
    range_percentage:range_percentage.map(({ min_quantity ,max_quantity,percentage }) => ({ min_quantity ,max_quantity,percentage })),
    categories: categories.map(({ id, name_en }) => ({ id, label: name_en })),
    tags: tags.map(({ id, name_en }) => ({ id, label: name_en })),
    storage_conditions: storage_conditions?.map(({ id, name_ar }) => ({ id, label: name_ar })),
    accessories: accessories?.map(({ id, name_ar }) => ({ id, label: name_ar })),
    vendor: vendor ? { ...vendor, label: vendor?.name } : null,
    brand: brand ? { ...brand, label: brand?.name_en } : null,
});

/**
 * Updates the product option by adding an active property
 * to both the product option and its values
 *
 * @param {Array} product_option - The original product option array
 * @param {Array} selected_values_ids - The array of selected value IDs
 * @returns {Array} The updated product option array
 */
export const updateProductOptionStatus = (product_option, selected_values_ids) => {
    const updatedProductOption = product_option.map((option) => {
        const updatedValues = option.values.map((value) => {
            return {
                ...value,
                active: selected_values_ids.includes(value.id),
            };
        });

        const isActive = updatedValues.some((value) => value.active === true);

        return {
            ...option,
            active: isActive,
            values: updatedValues,
        };
    });

    return updatedProductOption;
};

const updateDate = (date_field) =>
    date_field instanceof Date
        ? date_field?.toISOString().slice(0, 10).split('-').reverse().join('-')
        : date_field;
