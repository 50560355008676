import { Row, Col, Input,CustomPrompt , ShowErrors, CriticalActionButton } from '@components';
import FieldVisibility from '../../components/FieldVisibility';
import { useState,useEffect,  } from 'react';
import { useSelector } from 'react-redux';
import useUpdateProduct from '../../hooks/useUpdateProduct';
import { initBaseForm } from '../../product-utils';
const defaultData = {
    model_number: '',
    serial_number: '',
    screen_size: '',
    processor: '',
    operating_system: '',
    general_info_en: '',
    general_info_ar: '',
    trade_mark_en: '',
    trade_mark_ar: '',
    model_name_en: '',
    model_name_ar: '',
    wireless_carrier_en: '',
    wireless_carrier_ar: '',
    cellular_technology_ar: '',
    storage_capacity_en: '',
    storage_capacity_ar: '',
    communication_technology_en: '',
    communication_technology_ar: '',
    color_en: '',
    color_ar: '',
    network_technology_en: '',
    network_technology_ar: '',
    port_type_en: '',
    port_type_ar: '',
    udf1_en: '',
    udf1_ar: '',
    udf2_en: '',
    udf2_ar: '',
    udf3_en: '',
    udf3_ar: '',
    udf4_en: '',
    udf4_ar: '',
};
function  ProductDetailsTab() {
    const [values, setValues] = useState(defaultData);
    const onChange = (field, value) => {
        setValues((prev) => {
            const newValues = { ...prev, [field]: value };
         return newValues;
        });
    };

    /** @param {keyof typeof values} key an alias of onChange */
    const onChangeByKey = (key) => (val) => onChange(key, val);

    const product = useSelector((state) => state.ProductsSlice.product);
    const { allow_backorder,is_returnable,has_taxes    }=product ;
    const vendor_id=product.vendor.id ;
    const cleanPayload = (payload) => {
        if (Array.isArray(payload)) {
            return payload.map(item => cleanPayload(item));
        } else if (typeof payload === 'object' && payload !== null) {
            const cleanedObject = {};
            Object.keys(payload).forEach(key => {
                if (key !== 'range_percentage') {
                    cleanedObject[key] = cleanPayload(payload[key]);
                }
            });
            return cleanedObject;
        }
        return payload;
    };
    const cleanedValues = cleanPayload(product);
    const { mutate, data, isLoading } = useUpdateProduct(cleanedValues, { ...values,allow_backorder,is_returnable,has_taxes ,vendor_id }, {
        view_name: 'details',
    });
    const handleSubmit = () => mutate();
  
    useEffect(() => {
       if (product) {
       setValues((prev) => ({
               ...prev,
               ...initBaseForm(product.variants[0]),
           }));
       }
   }, [product]);

    
    return (
    
  <div>
        <CustomPrompt>
            <h3 className="divider-title">Product Details</h3>
            {data?.data?.errors && !!Object.keys(data?.data?.errors).length && (
                <ShowErrors errors={data?.data?.errors} />
            )}
            <Row>
                <Col lg={12}>
                    <FieldVisibility >
                        <Input
                            name="model_number"
                            label="model number"
                            placeholder="please enter model number"
                            value={values.model_number}
                            setValue={onChangeByKey('model_number')}
                        />
                    </FieldVisibility>
                </Col>
                <Col lg={6}>
                    <FieldVisibility >
                        <Input
                            name="serial_number"
                            label="serial number"
                            placeholder="please enter serial number"
                            value={values.serial_number}
                            setValue={onChangeByKey('serial_number')}
                        />
                        <Input
                            name="screen_size"
                            label="screen size"
                            placeholder="please enter screen size"
                            value={values.screen_size}
                            setValue={onChangeByKey('screen_size')}
                        />
                        <Input
                            name="general_info_en"
                            label="general info en"
                            placeholder="please enter general info en"
                            value={values.general_info_en}
                            setValue={onChangeByKey('general_info_en')}
                        />
                        <Input
                            name="trade_mark_en"
                            label="trade mark en"
                            placeholder='please enter trade mark en'
                            value={values.trade_mark_en}
                            setValue={onChangeByKey('trade_mark_en')}
                        />
                        <Input
                            name="model_name_en"
                            label="model name en"
                            placeholder='please enter model name en'
                            value={values.model_name_en}
                            setValue={onChangeByKey('model_name_en')}
                        />
                        <Input
                            name="wireless_carrier_en"
                            label="wireless carrier en"
                            placeholder='please enter wireless carrier en'
                            value={values.wireless_carrier_en}
                            setValue={onChangeByKey('wireless_carrier_en')}
                        />
                        <Input
                            name="cellular_technology_en"
                            label="cellular technology en"
                            placeholder='please enter cellular technology en'
                            value={values.cellular_technology_en}
                            setValue={onChangeByKey('cellular_technology_en')}
                        />
                        <Input
                            name="storage_capacity_en"
                            label="storage capacity en"
                            placeholder='please enter storage capacity en'
                            value={values.storage_capacity_en}
                            setValue={onChangeByKey('storage_capacity_en')}
                        />
                        <Input
                            name="communication_technology_en"
                            label="communication technology en"
                            placeholder='please enter communication technology en'
                            value={values.communication_technology_en}
                            setValue={onChangeByKey('communication_technology_en')}
                        />
                        <Input
                            name="color_en"
                            label="color en"
                            placeholder='please enter color en'
                            value={values.color_en}
                            setValue={onChangeByKey('color_en')}
                        />
                        <Input
                            name="network_technology_en"
                            label="network technology en"
                            placeholder='please enter network technology en'
                            value={values.network_technology_en}
                            setValue={onChangeByKey('network_technology_en')}
                        />
                        <Input
                            name="port_type_en"
                            label="port type en"
                            placeholder='please enter port type en'
                            value={values.port_type_en}
                            setValue={onChangeByKey('port_type_en')}
                        />
                        <Input
                            name="udf1_en"
                            label="User defined value1 en"
                            placeholder='please enter user defined value1 en'
                            value={values.udf1_en}
                            setValue={onChangeByKey('udf1_en')}
                        />
                        <Input
                            name="udf2_en"
                            label="User defined value2 en "
                            placeholder='please enter user defined value2 en'
                            value={values.udf2_en}
                            setValue={onChangeByKey('udf2_en')}
                        />
                        <Input
                            name="udf3_en"
                            label="User defined value3 en"
                            placeholder='please enter user defined value3 en'
                            value={values.udf3_en}
                            setValue={onChangeByKey('udf3_en')}
                        />
                        <Input
                            name="udf4_en"
                            label="User defined value4 en"
                            placeholder='please enter user defined value4 en'
                            value={values.udf4_en}
                            setValue={onChangeByKey('udf4_en')}
                        />
                    </FieldVisibility>
                </Col>
                <Col lg={6}>
                    <FieldVisibility >
                        <Input
                            name="processor"
                            label="processor"
                            placeholder='please enter processor'
                            value={values.processor}
                            setValue={onChangeByKey('processor')}
                        />

                        <Input
                            name="operating_system"
                            label="operating system"
                            placeholder='please enter operating system"'
                            value={values.operating_system}
                            setValue={onChangeByKey('operating_system')}
                        />
                        <Input
                            name="general_info_ar"
                            label="general info ar"
                            placeholder='please enter general info ar'
                            value={values.general_info_ar}
                            setValue={onChangeByKey('general_info_ar')}
                        />
                        <Input
                            name="trade_mark_ar"
                            label="trade mark ar"
                            placeholder='please enter trade mark ar'
                            value={values.trade_mark_ar}
                            setValue={onChangeByKey('trade_mark_ar')}
                        />
                        <Input
                            name="model_name_ar"
                            label="model name ar"
                            placeholder='please enter model name ar'
                            value={values.model_name_ar}
                            setValue={onChangeByKey('model_name_ar')}
                        />
                        <Input
                            name="wireless_carrier_ar"
                            label="wireless carrier ar"
                            placeholder='please enter wireless carrier ar'
                            value={values.wireless_carrier_ar}
                            setValue={onChangeByKey('wireless_carrier_ar')}
                        />
                        <Input
                            name="cellular_technology_ar"
                            label="cellular technology ar"
                            placeholder='please enter cellular technology ar'
                            value={values.cellular_technology_ar}
                            setValue={onChangeByKey('cellular_technology_ar')}
                        />
                        <Input
                            name="storage_capacity_ar"
                            label="storage capacity ar"
                            placeholder='please enter storage capacity ar'
                            value={values.storage_capacity_ar}
                            setValue={onChangeByKey('storage_capacity_ar')}
                        />
                        <Input
                            name="communication_technology_ar"
                            label="communication technology ar"
                            placeholder='please enter communication technology ar'
                            value={values.communication_technology_ar}
                            setValue={onChangeByKey('communication_technology_ar')}
                        />
                        <Input
                            name="color_ar"
                            label="color ar"
                            placeholder='please enter color ar'
                            value={values.color_ar}
                            setValue={onChangeByKey('color_ar')}
                        />
                        <Input
                            name="network_technology_ar"
                            label="network technology ar"
                            placeholder='please enter network technology ar'
                            value={values.network_technology_ar}
                            setValue={onChangeByKey('network_technology_ar')}
                        />
                        <Input
                            name="port_type_ar"
                            label="port type ar"
                            placeholder='please enter port type ar'
                            value={values.port_type_ar}
                            setValue={onChangeByKey('port_type_ar')}
                        />
                        <Input
                            name="udf1_ar"
                            label="User defined value1 ar"
                            placeholder='please enter user defined value1 ar'
                            value={values.udf1_ar}
                            setValue={onChangeByKey('udf1_ar')}
                        />
                        <Input
                            name="udf2_ar"
                            label="User defined value2 ar"
                            placeholder='please enter user defined value2 ar'
                            value={values.udf2_ar}
                            setValue={onChangeByKey('udf2_ar')}
                        />
                        <Input
                            name="udf3_ar"
                            label="User defined value3 ar"
                            placeholder='please enter user defined value3 ar'
                            value={values.udf3_ar}
                            setValue={onChangeByKey('udf3_ar')}
                        />
                        <Input
                            name="udf4_ar"
                            label="User defined value4 ar"
                         placeholder="please enter user defined value4 ar"
                            value={values.udf4_ar}
                            setValue={onChangeByKey('udf4_ar')}
                        />
                    </FieldVisibility>
                </Col>
            </Row>
            <div className="flex__jc-end">
                <CriticalActionButton loading={isLoading} onSubmit={handleSubmit}>
                    Submit
                </CriticalActionButton>
            </div>
        </CustomPrompt>
    </div>);

 
}

export default ProductDetailsTab;
