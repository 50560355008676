import React from 'react';
import {
    DataTable,
    Dialer,
    Link,
    StatusBlock,
    ApiAsyncSelect,
    SelectPayLaterProvider,
} from '@components';
import { formatDate } from '@utils';
import { useSelector } from 'react-redux';

const PayLaterTab = () => {
    const [order, set_order] = React.useState(null);
    const AuthSlice = useSelector((state) => state.AuthSlice);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            width: '100px',
            cell: (row) => <Link href={`/orders/${row.id}`}>#{row.id}</Link>,
        },
        {
            name: 'State',
            selector: 'state.description',
            cell: (row) => <StatusBlock state={row?.state} />,
        },
        {
            name: 'Retailer Name',
            selector: 'customer.name',
            cell: (row) => (
                <Link
                    href={`/customers/${row.customer?.id}`}
                    target="_blank"
                    className="tr-name tr-customer-info"
                >
                    <h5>{row?.customer?.name}</h5>
                </Link>
            ),
        },
        {
            name: 'Mobile',
            selector: 'customer.mobile',
            cell: (row) => <Dialer number={row?.customer?.mobile} />,
        },
        {
            name: 'Last Updated',
            selector: 'updated_at',
            format: (r) => formatDate(r?.updated_at),
        },
        {
            name: 'Pay later provider',
            selector: 'pay_later_provider',
            center: true,
            cell: (row) =>
                AuthSlice.user.permissions.length === 0 ||
                AuthSlice.user.permissions.indexOf('orders.togglePayLater') > -1 ? (
                    <>
                        <SelectPayLaterProvider
                            hideLabel
                            order_id={row?.id}
                            current_provider={row?.pay_later_provider}
                            pay_later_allowed_transitions={row?.pay_later_allowed_transitions}
                        />
                    </>
                ) : (
                    row?.pay_later_provider?.code
                ),
        },
    ];

    return (
        <div className="p-3">
            <div style={{ maxWidth: 400 }}>
                <ApiAsyncSelect
                    label="Search by order ID"
                    api="/order"
                    onlyFetchActiveRecords={false}
                    placeholder="Type to add an order..."
                    labelProperty={(r) => r.id}
                    onChange={set_order}
                    value={order}
                />
            </div>
            {order?.id && <DataTable data={[order]} columns={columns} />}
        </div>
    );
};

export default PayLaterTab;
