/* eslint-disable max-len */
import React from 'react';
import {
    DataTable,
    Button,
    Modal,
    Input,
    Toggler,
    ShowErrors,
    Col,
    Row,
    Select,
} from '@components';
import { PUT, randomId, formatStringWithUnderscores } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrderSettingsData } from '@slices/settings.slice';
import { MARKETPLACE_INVOICING_TRIGGERS } from '@components/shared/Filters/constants';

const SLA_SETTINGS_KEYS = [
    'suborder_sla_pending_vendor_confirmation',
    'suborder_sla_confirmed',
    'suborder_sla_cancelled_by_vendor',
    'suborder_sla_cancelled_timeout',
    'suborder_sla_ready_for_pickup',
    'suborder_sla_picked_up_by_courier',
    'suborder_sla_received_in_warehouse',
    'suborder_sla_under_fulfillment',
    'suborder_sla_ready_to_dispatch',
    'suborder_sla_out_for_delivery',
    'suborder_sla_fulfilled',
    'suborder_sla_cancelled_by_admin',
    'suborder_sla_pending_customer_confirmation',
    'suborder_sla_cancelled_payment_timeout',
    'suborder_sla_courier_rejected_to_pickup',
    'suborder_sla_client_rejected_to_collect',
    'suborder_sla_client_not_reachable',
    'suborder_sla_cancelled_by_customer',
    'suborder_sla_delivered',
];

const ORDER_PAYMENT_WALLET_TIMEOUT_KEYS = [
    'order_payment_wallet_timeout_hours',
    'order_payment_wallet_timeout_minutes',
    'order_payment_wallet_timeout_amount',
];
const ETA_SETTINGS_KEYS = [
    'min_eta_hours_for_both_inside_riyadh',
    'max_eta_hours_for_both_inside_riyadh',
    'min_eta_hours_for_vendor_outside_riyadh',
    'max_eta_hours_for_vendor_outside_riyadh',
    'min_eta_hours_for_retailer_outside_riyadh',
    'max_eta_hours_for_retailer_outside_riyadh',
    'min_eta_hours_for_both_outside_riyadh',
    'max_eta_hours_for_both_outside_riyadh',
];
const OrderSettingsTab = () => {
    const SettingsSlice = useSelector((state) => state.SettingsSlice);
    const dispatch = useDispatch();
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [order_min_amount, set_order_min_amount] = React.useState({
        label: 'Order Min Amount',
        value: '',
        setValue: (val) => set_order_min_amount({ ...order_min_amount, value: val }),
    });
    const [tax_invoice_trigger, set_tax_invoice_trigger] = React.useState({
        options: MARKETPLACE_INVOICING_TRIGGERS,
        getOptionValue: (option) => option.value,
        label: 'Marketplace invoicing triggers',
        value: null,
        isMulti: false,
        onChange: (val) => set_tax_invoice_trigger((prev) => ({ ...prev, value: val })),
    });

    const [vendor_cbv_order_auto_fulfillment_hours, set_vendor_cbv_order_auto_fulfillment_hours] =
        React.useState({
            label: 'Merchant CBV order auto fulfillment hours',
            value: '',
            setValue: (val) =>
                set_vendor_cbv_order_auto_fulfillment_hours({
                    ...vendor_cbv_order_auto_fulfillment_hours,
                    value: val,
                }),
        });
    const [suborder_cancelled_timeout_hours, set_suborder_cancelled_timeout_hours] = React.useState(
        {
            label: 'Confirmation Cancelled Timeout Hours',
            value: '',
            setValue: (val) =>
                set_suborder_cancelled_timeout_hours({
                    ...suborder_cancelled_timeout_hours,
                    value: val,
                }),
        },
    );
    const [suborder_cancelled_timeout_minutes, set_suborder_cancelled_timeout_minutes] =
        React.useState({
            label: 'Suborder Cancelled Timeout Minutes',
            value: '',
            setValue: (val) =>
                set_suborder_cancelled_timeout_minutes({
                    ...suborder_cancelled_timeout_minutes,
                    value: val,
                }),
        });
    const [ready_for_pickup_timeout_hours, set_ready_for_pickup_timeout_hours] = React.useState({
        label: 'Pick up Cancelled Timeout Hours',
        value: '',
        setValue: (val) =>
            set_ready_for_pickup_timeout_hours({
                ...ready_for_pickup_timeout_hours,
                value: val,
            }),
    });
    const [order_payment_timeout_feature, set_order_payment_timeout_feature] = React.useState({
        label: 'Order Payment Timeout Feature',
        checked: true,
        onChange: (val) =>
            set_order_payment_timeout_feature({
                ...order_payment_timeout_feature,
                checked: val,
            }),
    });
    const [order_payment_timeout_hours, set_order_payment_timeout_hours] = React.useState({
        label: 'Order Payment Timeout Hours',
        value: '',
        setValue: (val) =>
            set_order_payment_timeout_hours({
                ...order_payment_timeout_hours,
                value: val,
            }),
    });
    const [order_payment_timeout_minutes, set_order_payment_timeout_minutes] = React.useState({
        label: 'Order Payment Timeout Minutes',
        value: '',
        setValue: (val) =>
            set_order_payment_timeout_minutes({
                ...order_payment_timeout_minutes,
                value: val,
            }),
    });
    const [product_default_order_max_quantity, set_product_default_order_max_quantity] =
        React.useState({
            label: 'Product Default Order Max Quantity',
            value: '',
            setValue: (val) =>
                set_product_default_order_max_quantity({
                    ...product_default_order_max_quantity,
                    value: val,
                }),
        });
    const [
        scheduled_order_ready_for_pickup_vendor_days,
        set_scheduled_order_ready_for_pickup_vendor_days,
    ] = React.useState({
        label: 'Scheduled Order Ready For Pickup Vendor Days',
        value: '',
        setValue: (val) =>
            set_scheduled_order_ready_for_pickup_vendor_days({
                ...scheduled_order_ready_for_pickup_vendor_days,
                value: val,
            }),
    });
    const [enable_customer_verification_checkout, set_enable_customer_verification_checkout] =
        React.useState({
            label: 'Enable Retailer Verification Checkout',
            checked: true,
            onChange: (val) =>
                set_enable_customer_verification_checkout({
                    ...enable_customer_verification_checkout,
                    checked: val,
                }),
        });
    const [seller_name, set_seller_name] = React.useState({
        label: 'Seller Name',
        value: '',
        setValue: (val) => set_seller_name({ ...seller_name, value: val }),
    });
    const [seller_vat_number, set_seller_vat_number] = React.useState({
        label: 'Seller Vat Number',
        value: '',
        setValue: (val) => set_seller_vat_number({ ...seller_vat_number, value: val }),
    });
    const [issuing_tax_invoice_feature, set_issuing_tax_invoice_feature] = React.useState({
        label: 'Issuing Tax Invoice Feature',
        checked: true,
        onChange: (val) =>
            set_issuing_tax_invoice_feature({
                ...issuing_tax_invoice_feature,
                checked: val,
            }),
    });
    const [issuing_credit_note_feature, set_issuing_credit_note_feature] = React.useState({
        label: 'Issuing Credit Note Feature',
        checked: true,
        onChange: (val) =>
            set_issuing_credit_note_feature({
                ...issuing_credit_note_feature,
                checked: val,
            }),
    });
    const [refund_feature, set_refund_feature] = React.useState({
        label: 'Refund Feature',
        checked: true,
        onChange: (val) => set_refund_feature({ ...refund_feature, checked: val }),
    });
    const [
        suborder_delivered_to_auto_fulfilled_hours,
        set_suborder_delivered_to_auto_fulfilled_hours,
    ] = React.useState({
        label: 'Suborder Delivered To Auto Fullfilled Hours',
        value: '',
        setValue: (val) =>
            set_suborder_delivered_to_auto_fulfilled_hours({
                ...suborder_delivered_to_auto_fulfilled_hours,
                value: val,
            }),
    });
    const [
        suborder_delivered_to_auto_fulfilled_minutes,
        set_suborder_delivered_to_auto_fulfilled_minutes,
    ] = React.useState({
        label: 'Suborder Delivered To Auto Fulfilled Minutes',
        value: '',
        setValue: (val) =>
            set_suborder_delivered_to_auto_fulfilled_minutes({
                ...suborder_delivered_to_auto_fulfilled_minutes,
                value: val,
            }),
    });
    const [order_fulfillment_delay_days, set_order_fulfillment_delay_days] = React.useState({
        label: 'Order Fulfillment Delay Days',
        value: '',
        setValue: (val) =>
            set_order_fulfillment_delay_days({
                ...order_fulfillment_delay_days,
                value: val,
            }),
    });
    const [order_fulfillment_delay_hours, set_order_fulfillment_delay_hours] = React.useState({
        label: 'Order Fulfillment Delay Hours',
        value: '',
        setValue: (val) =>
            set_order_fulfillment_delay_hours({
                ...order_fulfillment_delay_hours,
                value: val,
            }),
    });
    const [order_fulfillment_delay_minutes, set_order_fulfillment_delay_minutes] = React.useState({
        label: 'Order Fulfillment Delay Minutes',
        value: '',
        setValue: (val) =>
            set_order_fulfillment_delay_minutes({
                ...order_fulfillment_delay_minutes,
                value: val,
            }),
    });
    const [show_free_shipping_label, set_show_free_shipping_label] = React.useState({
        label: 'Show free shipping label',
        checked: true,
        onChange: (val) =>
            set_show_free_shipping_label({
                ...show_free_shipping_label,
                checked: val,
            }),
    });

    const [sla_settings_values, set_sla_settings_values] = React.useState({});
    const [order_payment_wallet_timeout_values, set_order_payment_wallet_timeout_values] =
        React.useState({});
    const [eta_settings_values, set_eta_settings_values] = React.useState({});
    const [order_payment_wallet_timeout_feature, set_order_payment_wallet_timeout_feature] =
        React.useState({
            label: 'Order Payment Wallet Timeout Feature',
            checked: true,
            onChange: (val) =>
                set_order_payment_wallet_timeout_feature({
                    ...order_payment_wallet_timeout_feature,
                    checked: val,
                }),
        });
    const [remind_customers_to_pay_order_hours, set_remind_customers_to_pay_order_hours] =
        React.useState({
            label: 'Remind Retailers To Pay Order Hours',
            value: '',
            setValue: (val) =>
                set_remind_customers_to_pay_order_hours({
                    ...remind_customers_to_pay_order_hours,
                    value: val,
                }),
        });
    const [remind_customers_to_pay_order_minutes, set_remind_customers_to_pay_order_minutes] =
        React.useState({
            label: 'Remind Retailers To Pay Order Minutes',
            value: '',
            setValue: (val) =>
                set_remind_customers_to_pay_order_minutes({
                    ...remind_customers_to_pay_order_minutes,
                    value: val,
                }),
        });

    const [
        order_cancelled_payment_timeout_notification_delay_hours,
        set_order_cancelled_payment_timeout_notification_delay_hours,
    ] = React.useState({
        label: 'order cancelled payment timeout notification delay hours',
        value: '',
        setValue: (val) =>
            set_order_cancelled_payment_timeout_notification_delay_hours({
                ...order_cancelled_payment_timeout_notification_delay_hours,
                value: val,
            }),
    });
    const [
        order_cancelled_payment_timeout_notification_delay_minutes,
        set_order_cancelled_payment_timeout_notification_delay_minutes,
    ] = React.useState({
        label: 'order cancelled payment timeout notification delay minutes',
        value: '',
        setValue: (val) =>
            set_order_cancelled_payment_timeout_notification_delay_minutes({
                ...order_cancelled_payment_timeout_notification_delay_minutes,
                value: val,
            }),
    });
    React.useEffect(() => {
        dispatch(loadOrderSettingsData());
    }, [rerender]);

    const submit = async (closeModal) => {
        set_loading(true);
        set_errors([]);

        const res = await PUT({
            endpoint: '/order-settings',
            data: {
                vendor_cbv_order_auto_fulfillment_hours:
                    vendor_cbv_order_auto_fulfillment_hours.value,
                order_min_amount: order_min_amount?.value,
                suborder_cancelled_timeout_hours: suborder_cancelled_timeout_hours?.value,
                suborder_cancelled_timeout_minutes: suborder_cancelled_timeout_minutes?.value,
                ready_for_pickup_timeout_hours: ready_for_pickup_timeout_hours?.value,
                order_payment_timeout_feature: order_payment_timeout_feature?.checked ? 1 : 0,
                order_payment_timeout_hours: order_payment_timeout_hours?.value,
                order_payment_timeout_minutes: order_payment_timeout_minutes?.value,
                product_default_order_max_quantity: product_default_order_max_quantity?.value,
                scheduled_order_ready_for_pickup_vendor_days:
                    scheduled_order_ready_for_pickup_vendor_days?.value,
                enable_customer_verification_checkout_constraint:
                    enable_customer_verification_checkout?.checked ? 1 : 0,
                seller_name: seller_name?.value,
                seller_vat_number: seller_vat_number?.value,
                issuing_tax_invoice_feature: issuing_tax_invoice_feature?.checked ? 1 : 0,
                issuing_credit_note_feature: issuing_credit_note_feature?.checked ? 1 : 0,
                refund_feature: refund_feature?.checked ? 1 : 0,
                suborder_delivered_to_auto_fulfilled_hours:
                    suborder_delivered_to_auto_fulfilled_hours?.value,
                suborder_delivered_to_auto_fulfilled_minutes:
                    suborder_delivered_to_auto_fulfilled_minutes?.value,
                order_fulfillment_delay_days: order_fulfillment_delay_days?.value,
                order_fulfillment_delay_hours: order_fulfillment_delay_hours?.value,
                order_fulfillment_delay_minutes: order_fulfillment_delay_minutes?.value,
                show_free_shipping_label: show_free_shipping_label?.checked ? 1 : 0,
                remind_customers_to_pay_order_hours: remind_customers_to_pay_order_hours?.value,
                remind_customers_to_pay_order_minutes: remind_customers_to_pay_order_minutes?.value,
                ...SLA_SETTINGS_KEYS.reduce((acc, key) => {
                    acc[key] = +sla_settings_values[key] || 0;
                    return acc;
                }, {}),
                ...ORDER_PAYMENT_WALLET_TIMEOUT_KEYS.reduce((acc, key) => {
                    acc[key] = +order_payment_wallet_timeout_values[key] || 0;
                    return acc;
                }, {}),
                ...ETA_SETTINGS_KEYS.reduce((acc, key) => {
                    acc[key] = +eta_settings_values[key] || 0;
                    return acc;
                }, {}),
                order_payment_wallet_timeout_feature: order_payment_wallet_timeout_feature?.checked
                    ? 1
                    : 0,
                order_cancelled_payment_timeout_notification_delay_hours:
                    +order_cancelled_payment_timeout_notification_delay_hours.value,
                order_cancelled_payment_timeout_notification_delay_minutes:
                    +order_cancelled_payment_timeout_notification_delay_minutes.value,

                tax_invoice_trigger: tax_invoice_trigger.value.value,
            },
        });
        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
            set_loading(false);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('edit_order_settings__modal');
            set_rerender(randomId());
        }
    };

    const data = [
        {
            key: 'Order Min Amount',
            value: SettingsSlice?.orderSettings?.order_min_amount,
        },
        {
            key: vendor_cbv_order_auto_fulfillment_hours.label,
            value: SettingsSlice?.orderSettings?.vendor_cbv_order_auto_fulfillment_hours,
        },
        {
            key: 'Confirmation Cancelled Timeout Hours',
            value: SettingsSlice?.orderSettings?.suborder_cancelled_timeout_hours,
        },
        {
            key: 'Suborder Cancelled Timeout Minutes',
            value: SettingsSlice?.orderSettings?.suborder_cancelled_timeout_minutes,
        },
        {
            key: 'Pick up Cancelled Timeout Hours',
            value: SettingsSlice?.orderSettings?.ready_for_pickup_timeout_hours,
        },
        {
            key: 'Order Payment Timeout Feature',
            value: SettingsSlice?.orderSettings?.order_payment_timeout_feature,
        },
        {
            key: 'Order Payment Timeout Hours',
            value: SettingsSlice?.orderSettings?.order_payment_timeout_hours,
        },
        {
            key: 'Order Payment Timeout Minutes',
            value: SettingsSlice?.orderSettings?.order_payment_timeout_minutes,
        },
        {
            key: 'Product Default Order Max Quantity',
            value: SettingsSlice?.orderSettings?.product_default_order_max_quantity,
        },
        {
            key: 'Scheduled Order Ready For Pickup Vendor Days',
            value: SettingsSlice?.orderSettings?.scheduled_order_ready_for_pickup_vendor_days,
        },
        {
            key: 'Enable Retailer Verification Checkout',
            value: SettingsSlice?.orderSettings?.enable_customer_verification_checkout_constraint,
        },
        {
            key: 'Seller Name',
            value: SettingsSlice?.orderSettings?.seller_name,
        },
        {
            key: 'Seller Vat Number',
            value: SettingsSlice?.orderSettings?.seller_vat_number,
        },
        {
            key: 'Issuing Tax Invoice Feature',
            value: SettingsSlice?.orderSettings?.issuing_tax_invoice_feature,
        },
        {
            key: 'Issuing Credit Note Feature',
            value: SettingsSlice?.orderSettings?.issuing_credit_note_feature,
        },
        {
            key: 'Refund Feature',
            value: SettingsSlice?.orderSettings?.refund_feature,
        },
        {
            key: 'Suborder Delivered To Auto Fulfilled Hours',
            value: SettingsSlice?.orderSettings?.suborder_delivered_to_auto_fulfilled_hours,
        },
        {
            key: 'Suborder Delivered To Auto Fulfilled Minutes',
            value: SettingsSlice?.orderSettings?.suborder_delivered_to_auto_fulfilled_minutes,
        },
        {
            key: 'Order Fulfillment Delay Days',
            value: SettingsSlice?.orderSettings?.order_fulfillment_delay_days,
        },
        {
            key: 'Order Fulfillment Delay Hours',
            value: SettingsSlice?.orderSettings?.order_fulfillment_delay_hours,
        },
        {
            key: 'Order Fulfillment Delay Minutes',
            value: SettingsSlice?.orderSettings?.order_fulfillment_delay_minutes,
        },
        {
            key: 'Show Free Shipping Label',
            value: SettingsSlice?.orderSettings?.show_free_shipping_label,
        },
        {
            key: 'Remind Retailers To Pay Order Hours',
            value: SettingsSlice?.orderSettings?.remind_customers_to_pay_order_hours,
        },
        {
            key: 'Remind Retailers To Pay Order Minutes',
            value: SettingsSlice?.orderSettings?.remind_customers_to_pay_order_minutes,
        },
        ...SLA_SETTINGS_KEYS.map((key) => ({
            key: formatStringWithUnderscores(key),
            value: SettingsSlice?.orderSettings?.[key],
        })),
        {
            key: 'Order Payment Wallet Timeout Feature',
            value: SettingsSlice?.orderSettings?.order_payment_wallet_timeout_feature,
        },
        ...ORDER_PAYMENT_WALLET_TIMEOUT_KEYS.map((key) => ({
            key: formatStringWithUnderscores(key),
            value: SettingsSlice?.orderSettings?.[key],
        })),
        {
            key: 'Order Cancelled Payment Timeout Notification Delay Hours',
            value: SettingsSlice?.orderSettings
                ?.order_cancelled_payment_timeout_notification_delay_hours,
        },
        {
            key: 'Order Cancelled Payment Timeout Notification Delay Minutes',
            value: SettingsSlice?.orderSettings
                ?.order_cancelled_payment_timeout_notification_delay_minutes,
        },
        {
            key: tax_invoice_trigger.label,
            value: tax_invoice_trigger.options.find(
                (val) => val.value == SettingsSlice?.orderSettings?.tax_invoice_trigger,
            )?.label,
        },
        ...ETA_SETTINGS_KEYS.map((key) => ({
            key: formatStringWithUnderscores(key),
            value: SettingsSlice?.orderSettings?.[key],
        })),
    ];

    const columns = [
        {
            name: 'Setting',
            cell: (row) => row?.key,
        },
        {
            name: 'Value',
            center: true,
            cell: (row) =>
                typeof row?.value === 'boolean' ? (
                    <div className={`status-indicator ${row?.value && 'active-indicator'}`}>
                        {row?.value ? 'Active' : 'Inactive'}
                    </div>
                ) : (
                    row?.value
                ),
        },
    ];

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                loading={SettingsSlice?.orderSettings_loading}
            />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        vendor_cbv_order_auto_fulfillment_hours.setValue(
                            SettingsSlice?.orderSettings?.vendor_cbv_order_auto_fulfillment_hours,
                        );
                        order_min_amount.setValue(SettingsSlice?.orderSettings?.order_min_amount);
                        suborder_cancelled_timeout_hours.setValue(
                            SettingsSlice?.orderSettings?.suborder_cancelled_timeout_hours,
                        );
                        suborder_cancelled_timeout_minutes.setValue(
                            SettingsSlice?.orderSettings?.suborder_cancelled_timeout_minutes,
                        );
                        ready_for_pickup_timeout_hours.setValue(
                            SettingsSlice?.orderSettings?.ready_for_pickup_timeout_hours,
                        );
                        order_payment_timeout_feature.onChange(
                            SettingsSlice?.orderSettings?.order_payment_timeout_feature,
                        );
                        order_payment_timeout_hours.setValue(
                            SettingsSlice?.orderSettings?.order_payment_timeout_hours,
                        );
                        order_payment_timeout_minutes.setValue(
                            SettingsSlice?.orderSettings?.order_payment_timeout_minutes,
                        );
                        product_default_order_max_quantity.setValue(
                            SettingsSlice?.orderSettings?.product_default_order_max_quantity,
                        );
                        scheduled_order_ready_for_pickup_vendor_days.setValue(
                            SettingsSlice?.orderSettings
                                ?.scheduled_order_ready_for_pickup_vendor_days,
                        );
                        enable_customer_verification_checkout.onChange(
                            SettingsSlice?.orderSettings
                                ?.enable_customer_verification_checkout_constraint,
                        );
                        seller_name.setValue(SettingsSlice?.orderSettings?.seller_name);
                        seller_vat_number.setValue(SettingsSlice?.orderSettings?.seller_vat_number);
                        issuing_tax_invoice_feature.onChange(
                            SettingsSlice?.orderSettings?.issuing_tax_invoice_feature,
                        );
                        issuing_credit_note_feature.onChange(
                            SettingsSlice?.orderSettings?.issuing_credit_note_feature,
                        );
                        refund_feature.onChange(SettingsSlice?.orderSettings?.refund_feature);
                        suborder_delivered_to_auto_fulfilled_hours.setValue(
                            SettingsSlice?.orderSettings
                                ?.suborder_delivered_to_auto_fulfilled_hours,
                        );
                        suborder_delivered_to_auto_fulfilled_minutes.setValue(
                            SettingsSlice?.orderSettings
                                ?.suborder_delivered_to_auto_fulfilled_minutes,
                        );
                        order_fulfillment_delay_days.setValue(
                            SettingsSlice?.orderSettings?.order_fulfillment_delay_days,
                        );
                        order_fulfillment_delay_hours.setValue(
                            SettingsSlice?.orderSettings?.order_fulfillment_delay_hours,
                        );
                        order_fulfillment_delay_minutes.setValue(
                            SettingsSlice?.orderSettings?.order_fulfillment_delay_minutes,
                        );
                        show_free_shipping_label.onChange(
                            SettingsSlice?.orderSettings?.show_free_shipping_label,
                        );
                        remind_customers_to_pay_order_hours.setValue(
                            SettingsSlice?.orderSettings?.remind_customers_to_pay_order_hours,
                        );
                        remind_customers_to_pay_order_minutes.setValue(
                            SettingsSlice?.orderSettings?.remind_customers_to_pay_order_minutes,
                        );
                        set_sla_settings_values(
                            SLA_SETTINGS_KEYS.reduce((acc, key) => {
                                acc[key] = SettingsSlice?.orderSettings?.[key];
                                return acc;
                            }, {}),
                        );
                        set_order_payment_wallet_timeout_values(
                            ORDER_PAYMENT_WALLET_TIMEOUT_KEYS.reduce((acc, key) => {
                                acc[key] = SettingsSlice?.orderSettings?.[key];
                                return acc;
                            }, {}),
                        );
                        set_eta_settings_values(
                            ETA_SETTINGS_KEYS.reduce((acc, key) => {
                                acc[key] = SettingsSlice?.orderSettings?.[key];
                                return acc;
                            }, {}),
                        );
                        order_payment_wallet_timeout_feature.onChange(
                            SettingsSlice?.orderSettings?.order_payment_wallet_timeout_feature ||
                                false,
                        );
                        order_cancelled_payment_timeout_notification_delay_hours.setValue(
                            SettingsSlice?.orderSettings
                                ?.order_cancelled_payment_timeout_notification_delay_hours,
                        );
                        order_cancelled_payment_timeout_notification_delay_minutes.setValue(
                            SettingsSlice?.orderSettings
                                ?.order_cancelled_payment_timeout_notification_delay_minutes,
                        );
                        tax_invoice_trigger.onChange(
                            MARKETPLACE_INVOICING_TRIGGERS.find(
                                ({ value }) =>
                                    value == SettingsSlice?.orderSettings?.tax_invoice_trigger,
                            ),
                        );
                        set_errors([]);
                        dispatch(
                            setModalStatus({
                                modal: 'edit_order_settings__modal',
                                status: true,
                            }),
                        );
                    }}
                >
                    Edit Order settings
                </Button>
            </div>
            <Modal
                title={'Update Order Settings'}
                variant={'warning'}
                name="edit_order_settings__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal);
                }}
                loading={loading}
                size="lg"
            >
                <Row>
                    <Col>
                        <Input {...order_min_amount} type="number" />
                        <Input {...vendor_cbv_order_auto_fulfillment_hours} type="number" />
                        <Input {...suborder_cancelled_timeout_hours} type="number" />
                        <Input {...suborder_cancelled_timeout_minutes} type="number" />
                        <Input {...ready_for_pickup_timeout_hours} type="number" />
                        <Toggler {...order_payment_timeout_feature} />
                        <Input {...order_payment_timeout_hours} type="number" />
                        <Input {...order_payment_timeout_minutes} type="number" />
                        <Input {...product_default_order_max_quantity} type="number" />
                        <Input {...scheduled_order_ready_for_pickup_vendor_days} type="number" />
                        <Toggler {...enable_customer_verification_checkout} />
                        <Input {...seller_name} />
                        <Input {...seller_vat_number} />
                        <Toggler {...issuing_tax_invoice_feature} />
                        <Toggler {...issuing_credit_note_feature} />
                        <Toggler {...refund_feature} />
                        <Input {...suborder_delivered_to_auto_fulfilled_hours} type="number" />
                        <Input {...suborder_delivered_to_auto_fulfilled_minutes} type="number" />
                        <Input {...order_fulfillment_delay_days} type="number" />
                        <Input {...order_fulfillment_delay_hours} type="number" />
                        <Input {...order_fulfillment_delay_minutes} type="number" />
                        <Input {...remind_customers_to_pay_order_hours} type="number" />
                        <Input {...remind_customers_to_pay_order_minutes} type="number" />
                        <Input
                            {...order_cancelled_payment_timeout_notification_delay_hours}
                            type="number"
                        />
                        <Input
                            {...order_cancelled_payment_timeout_notification_delay_minutes}
                            type="number"
                        />

                        <Toggler {...show_free_shipping_label} />

                        <Toggler {...order_payment_wallet_timeout_feature} />
                        {ORDER_PAYMENT_WALLET_TIMEOUT_KEYS.map((key) => (
                            <div key={key}>
                                <Input
                                    type="number"
                                    label={formatStringWithUnderscores(key)}
                                    value={order_payment_wallet_timeout_values[key]}
                                    setValue={(val) => {
                                        set_order_payment_wallet_timeout_values({
                                            ...order_payment_wallet_timeout_values,
                                            [key]: val,
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </Col>
                    <Col>
                        {SLA_SETTINGS_KEYS.map((key) => (
                            <div key={key}>
                                <Input
                                    type="number"
                                    label={formatStringWithUnderscores(key)}
                                    value={sla_settings_values[key]}
                                    setValue={(val) => {
                                        set_sla_settings_values({
                                            ...sla_settings_values,
                                            [key]: val,
                                        });
                                    }}
                                />
                            </div>
                        ))}

                        <Select {...tax_invoice_trigger} />

                        {ETA_SETTINGS_KEYS.map((key) => (
                            <div key={key}>
                                <Input
                                    type="number"
                                    label={formatStringWithUnderscores(key)}
                                    value={eta_settings_values[key]}
                                    setValue={(val) => {
                                        set_eta_settings_values({
                                            ...eta_settings_values,
                                            [key]: val,
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </Col>
                </Row>
                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}
            </Modal>
        </>
    );
};

export default OrderSettingsTab;
