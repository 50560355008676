import { Checkbox } from '@components';
import { useMemo } from 'react';

const CustomerPayLaterProvidersCheckboxes = ({ options, value = [], onChange }) => {
    const list = useMemo(
        () =>
            options.map(({ id, label }) => {
                const checked = value.some((provider) => provider.id === id);

                const newItems = checked
                    ? value.filter((provider) => !(provider.id === id))
                    : [...value, { id, label }];

                return (
                    <Checkbox
                        label={label}
                        key={id}
                        checked={checked}
                        onChange={() => onChange(newItems)}
                    />
                );
            }),
        [value, options],
    );

    return <>{list}</>;
};

export default CustomerPayLaterProvidersCheckboxes;
