import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET } from '@utils';

export const VendorsSlice = createSlice({
    name: 'vendors',
    initialState: {
        vendors: [],
        vendors_pages: null,
        vendors_records: null,
        vendors_loading: false,

        vendor: {},
        vendor_loading: false,
        
        vendors_leads: [],
        vendors_leads_pages: null,
        vendors_leads_records: null,
        vendors_leads_loading: false,
    },
    reducers: {
        setVendors(state, { payload }) {
            state.vendors = payload;
        },
        updateSingleRow(state, { payload }) {
            const index = state.vendors.findIndex((r) => r.id === payload.id);
            if (index !== -1) {
                state.vendors[index] = {
                    ...state.vendors[index],
                    ...payload,
                };
            }
        },
        setVendorsLeads(state, { payload }) {
            state.vendors_leads = payload;
        },
        updateLeadInListing(state, { payload }) {
            state.vendors_leads = state.vendors_leads.map(lead => {
                if (lead.id === payload.id) {
                    return { ...lead, ...payload };
                }
                return lead;
            });
        },
        setVendor(state, { payload }) {
            state.vendor = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
        setVendorsTotalPages(state, { payload }) {
            state.vendors_pages = payload;
        },
        setVendorsRecords(state, { payload }) {
            state.vendors_records = payload;
        },
        setVendorsLeadsRecords(state, { payload }) {
            state.vendors_leads_records = payload;
        },
        setVendorsLeadsTotalPages(state, { payload }) {
            state.vendors_leads_pages = payload;
        },
    },
});

// export actions
export const {
    setVendors,
    updateSingleRow,
    setVendorsLeads,
    setVendor,
    updateLeadInListing,
    setLoading,
    setVendorsTotalPages,
    setVendorsRecords,
    setVendorsLeadsRecords,
    setVendorsLeadsTotalPages
} = VendorsSlice.actions;

export const loadVendorsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendors_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setVendors(res?.data || []));
    dispatch(setVendorsTotalPages(res?.meta?.last_page || 0));
    dispatch(setVendorsRecords({
        from: res?.meta?.from,
        to: res?.meta?.to,
        total: res?.meta?.total
    }));
    dispatch(
        setLoading({
            key: 'vendors_loading',
            value: false,
        })
    );
};

export const loadVendorsLeadsData = (params = {}) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendors_leads_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/vendor-leads?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma'
        })}`,
    });
    dispatch(setVendorsLeads(res?.data || []));
    dispatch(setVendorsLeadsTotalPages(res?.meta?.last_page || 0));
    dispatch(setVendorsLeadsRecords({
        from: res?.meta?.from,
        to: res?.meta?.to,
        total: res?.meta?.total
    }));
    dispatch(
        setLoading({
            key: 'vendors_leads_loading',
            value: false,
        })
    );
};

export const loadVendorData = (id) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'vendor_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/vendors/${id}`,
    });
    dispatch(setVendor(res?.data || {}));
    dispatch(
        setLoading({
            key: 'vendor_loading',
            value: false,
        })
    );
};

export default VendorsSlice.reducer;
