import styled from 'styled-components';

const BoxesDetailStyled = styled.div`
.boxes-header{
    margin-bottom: 50px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    .state {
        display:flex;
        align-items:center;
        gap:10px;
        h1{
            font-weight: 700;
        }
    }
    .boxes-actions{
        display:flex;
        gap:10px;
    }
}
.boxes-card{
    margin:10px;
    padding: 20px; 
.card-header{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom: 20px;
    font-weight: 700;
    font-size:20px;
    color: #555;
    border-bottom: 15px solid #eee;
    background-color:white;
    .logo{
        display:flex;
        align-items:center;
        gap:10px;
        span{
            height:30px;
            width:2px;
            border-radius:6px;
            background-color:#eee;
        }
    }
}
}
.card-body{
    padding:15px 0 ;
    .delivery-info{
        display:flex;
        justify-content:space-between;
        h3{
            font-weight:700;
        }
    }
    .box-code{
        text-align:center;
        font-weight: 700;
        font-size: 22px;
    }
    .box-shipping{
        border-top: 7px solid #000;
        border-radius:4px;
         padding-top: 10px;
         text-align:right;
         margin:10px 0;
    }
    .barcode-img{
        height: 250px;
        width:100%;
    }
}
.card-bottom{
    display:flex;
    justify-content:space-between;
    .box-order-status{
        min-width:50%;
    }
}
.barcode-edit{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin:10px;
    padding: 20px;
    height: 100%;
    cursor:pointer;
    p{
        font-size:38px;
    }
}
`;

export default BoxesDetailStyled;