import React from 'react';
import { ChoiceList, Icon, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function CitiesFilter({
    value = null,
    set_value = () => {},
}) {
    const [q, set_q] = React.useState('');
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const cities = GlobalsSlice?.cities?.map(c => ({
        label: c.label,
        value: c.id,
    })) || [];

    const onChange = useCallback(
        (value) => set_value(value),
        [],
    );
    
    return (
        <>
            <TextField
                value={q}
                onChange={set_q}
                autoComplete="off"
                placeholder='Search'
                prefix={<Icon source={SearchMinor} />}
            />
            <br />
            <ChoiceList
                choices={cities?.filter(
                    (item) => item?.label?.toLowerCase()?.includes(q?.toLowerCase()),
                ) || []}
                selected={value || []}
                onChange={onChange}
                allowMultiple
            />

        </>
    );
}
