import React from 'react';
import { Card, DataTable, SelectPayLaterProvider, Link } from '@components';
import { useSelector } from 'react-redux';
import { formatDate, GET } from '@utils';
import { useParams } from 'react-router';

const PayLater = ({ onSuccess }) => {
    const { id } = useParams();
    const AuthSlice = useSelector((state) => state.AuthSlice);
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const [loading, set_loading] = React.useState(false);
    const [data, set_data] = React.useState([]);
    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/order/${id}/audits/pay-later`,
        });
        set_loading(false);
        if (res?.status_code === 200) {
            set_data(res?.data?.audits);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            name: 'Action',
            cell: ({ message }) => <p>{message}</p>,
        },
        {
            name: 'Date & Time',
            selector: 'date',
            format: (r) => formatDate(r.date),
        },
        {
            name: 'User Type',
            selector: 'user_type',
        },
        {
            name: 'User',
            selector: 'user',
            cell: (row) => (
                <>
                    {row.user_type === 'Customer' ? (
                        <Link href={`/customers/${row.user_id}`} className="tr-name link-style">
                            {row.user}
                        </Link>
                    ) : (
                        <p>{row.user}</p>
                    )}
                </>
            ),
        },
    ];

    return (
        <>
            <Card
                title="Pay Later"
                className="pay-later"
                headerContent={
                    (AuthSlice.user.permissions.length === 0 ||
                        AuthSlice.user.permissions.indexOf('orders.togglePayLater') > -1) && (
                        <SelectPayLaterProvider
                            order_id={id}
                            current_provider={OrdersSlice?.order?.pay_later_provider}
                            pay_later_allowed_transitions={
                                OrdersSlice?.order?.pay_later_allowed_transitions
                            }
                            onSuccess={() => {
                                fetchData();
                                onSuccess?.();
                            }}
                        />
                    )
                }
            >
                <DataTable data={data} columns={columns} loading={loading} />
            </Card>
        </>
    );
};

export default PayLater;
