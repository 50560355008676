import React from 'react';

import { stringify } from 'query-string';
import { GET, formatMoney, POST, obj2formdata, confirmationMessage } from '@utils';
import { Row, Col, DataTable, Card, Button, CriticalActionButton } from '@components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import SelectVariantOrBase from '../../components/SelectVariantOrBase';

function ProductCustomAttributesTab() {
    return (
        <SelectVariantOrBase>
            {({ selected }) => selected && <ProductRecommendationTab selectedId={selected} />}
        </SelectVariantOrBase>
    );
}

function ProductRecommendationTab({ selectedId }) {
    // const { id } = useParams();
    const [products_loading, set_products_loading] = React.useState(false);
    const [recommendation_loading, set_recommendation_loading] = React.useState(false);
    const [show_save_button, set_show_save_button] = React.useState(false);
    const [products_pages, set_products_pages] = React.useState(0);
    const [products, set_products] = React.useState([]);
    const [selected_products, set_selected_products] = React.useState([]);
    const [page, set_page] = React.useState(1);
    const [q, set_q] = React.useState('');
    const [errors, set_errors] = React.useState([]);
    const product = useSelector(state => state.ProductsSlice.product);
    React.useEffect(() => {
        const fetchData = async () => {
            set_products_loading(true);
            const res = await GET({
                endpoint: `/product?${stringify(
                    {
                        q,
                        page,
                    },
                    {
                        skipNull: true,
                        skipEmptyString: true,
                    },
                )}`,
            });
            set_products(res?.data || []);
            set_products_pages(res?.meta.last_page || 0);
            set_products_loading(false);
        };
        if (q) {
            fetchData();
        } else {
            set_products([]);
        }
    }, [q, page]);

    const submit = async () => {
        const data = obj2formdata({});
        selected_products.forEach((p, i) => {
            data.append('product_recommendation_ids[' + i + ']', p.id);
        });
        const res = await POST({ endpoint: `/product/${selectedId}/recommendation`, data });
        if (res?.success === true) {
            set_errors([]);
            toast.info('Recommendation product updated successfully');
            fetchRecommendation();
        } else {
            set_errors(res?.data?.errors || []);
        }
    };
    const recommendationColumns = [
        {
            name: 'Product',
            selector: 'name',
            sortable: false,
            cell: row => <div className="tr-name">{row.name}</div>,
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: false,
            cell: row => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.price)}</div>
                </div>
            ),
        },
        {
            name: 'Stock',
            selector: 'stock',
            sortable: false,
        },
        {
            name: '',
            selector: 'select',
            sortable: false,
            cell: row => {
                if (selected_products?.find?.(p => p.id === row.id)) {
                    return (
                        <Button
                            onClick={() => {
                                const filtred = selected_products.filter(p => p.id !== row.id);
                                set_selected_products(filtred);
                                set_show_save_button(true);
                            }}
                            variant="danger"
                        >
                            -
                        </Button>
                    );
                }
            },
        },
    ];
    const columns = [
        {
            name: 'Product',
            selector: 'name',
            sortable: false,
            cell: row => <div className="tr-name">{row.name}</div>,
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: false,
            cell: row => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.price)}</div>
                </div>
            ),
        },
        {
            name: 'Variant Options',
            cell: row => (
                <div>
                    {row?.variant_options?.map((o, i) => (
                        <div key={i}>
                            <span>{o.option_name}: </span>
                            <span>{o.value}</span>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            name: 'Stock',
            selector: 'stock',
            sortable: false,
        },
        {
            name: '',
            selector: 'select',
            sortable: false,
            cell: row => {
                if (!selected_products?.find?.(p => p.id === row.id)) {
                    return (
                        <Button
                            onClick={() => {
                                set_selected_products(prev => [...prev, { ...row }]);
                                set_show_save_button(true);
                            }}
                            variant="success"
                        >
                            +
                        </Button>
                    );
                }
            },
        },
    ];

    const fetchRecommendation = async () => {
        set_recommendation_loading(true);
        const res = await GET({ endpoint: `/product/${selectedId}/recommendation` });
        set_recommendation_loading(false);
        if (res?.status_code === 200) {
            set_selected_products(res?.data);
            set_show_save_button(false);
        }
    };
    React.useEffect(() => {
        fetchRecommendation();
    }, [selectedId]);

    return (
        <div className="product-recommendation">
            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map(key => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
            <Row>
                <Col lg={6}>
                    <Card>
                        <h3 className="divider-title flex__jc-between__ai-center">
                            Recommendation Product
                            {show_save_button && (
                                <CriticalActionButton
                                    onSubmit={() => {
                                        product?.is_active ? submit() : confirmationMessage(submit);
                                    }}
                                >
                                    Save changes
                                </CriticalActionButton>
                            )}
                        </h3>
                        <DataTable
                            data={selected_products}
                            columns={recommendationColumns}
                            loading={recommendation_loading}
                        />
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <div className="m-3">
                            <span>Search for similar product</span>
                        </div>
                        <DataTable
                            data={products}
                            pages={products_pages}
                            page={page}
                            handlePageChange={set_page}
                            columns={columns}
                            searchable={true}
                            onSearch={set_q}
                            loading={products_loading}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ProductCustomAttributesTab;
