import React from 'react';
import {
    ViewHeader,
    Link,
    Card,
    DataTable,
    StatusToggler,
    Filters,
    Sop
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadDiscountsData, updateSingleRow } from '@slices/discounts.slice';
import { formatDate } from '@utils';
import useDidMountEffect from '@hooks/useDidMountEffect';

export default function DiscountsListView() {
    const DiscountsSlice = useSelector((state) => state.DiscountsSlice);
    const dispatch = useDispatch();

    const [page, set_page] = React.useState(1);
    const [records_per_page, set_records_per_page] = React.useState(null);
    const [filters, set_filters] = React.useState({});

    useDidMountEffect(() => {
        dispatch(loadDiscountsData({ ...filters, page, records_per_page }));
    }, [filters, page, records_per_page]);

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            width: '100px',
            cell: (row) => (
                <Link href={`/discounts/${row.id}`} className="tr-name">
                    <h5>#{row.id}</h5>
                </Link>
            ),
        },
        {
            name: 'Name (arabic)',
            selector: 'name_ar',
            sortable: false,
        },
        {
            name: 'Name (english)',
            selector: 'name_en',
            sortable: false,
        },
        {
            name: 'Discount type',
            selector: 'discount_type',
            sortable: false,
        },
        {
            name: 'Calculating type',
            selector: 'calculation_type',
            sortable: false,
        },
        {
            name: 'Discount value',
            selector: 'discount_value',
            sortable: false,
            center: true
        },
        {
            name: 'From',
            selector: 'starts_at',
            format: (r) => formatDate(r.starts_at)
        },
        {
            name: 'To',
            selector: 'ends_at',
            format: (r) => formatDate(r.ends_at)
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row.status}
                    path={`/marketing/discount/${row.id}/status`}
                    callback={({ id, status }) => {
                        dispatch(updateSingleRow({ id, status: status }));
                    }}
                />
            ),
        },
    ];

    return (
        <div className="discounts-list-view">
            <ViewHeader title="Discounts">
                <div className="flex__ai-center">
                    <Sop id="25343"/>
                    <Link href="/discounts/add" className="btn btn-primary">
					New discount
                    </Link>
                </div>
            </ViewHeader>
            <Card>
                <Filters
                    filters={[
                        'status',
                    ]}
                    onFilterChange={(filters_values) => {
                        set_filters(filters_values);
                    }}
                    initialFiltersValues={{
                        sort_by: 'status',
                        sort_direction: 'desc',
                    }}
                    sort_options={[
                        {
                            label: 'ID (DESC)',
                            value: 'id__desc',
                            direction: 'desc'
                        },
                        {
                            label: 'ID (ASC)',
                            value: 'id__asc',
                            direction: 'asc'
                        },
                        {
                            label: 'Status (DESC)',
                            value: 'status__desc',
                            direction: 'desc',
                        },
                        {
                            label: 'Status (ASC)',
                            value: 'status__asc',
                            direction: 'asc',
                        },
                    ]}
                />
                <DataTable
                    data={DiscountsSlice.discounts}
                    pages={DiscountsSlice.discounts_pages}
                    page={page}
                    records={DiscountsSlice.discounts_records}
                    records_per_page={records_per_page}
                    set_records_per_page={set_records_per_page}
                    handlePageChange={set_page}
                    columns={columns}
                    searchable={false}
                    loading={DiscountsSlice.discounts_loading}
                />
            </Card>
        </div>
    );
}
