import { Card, RangeSlider, Stack, TextField, Button } from '@shopify/polaris';
import { useState, useCallback } from 'react';
// import useDebounce from '../../../../utils/useDebounce';

export default function TotalFilter({
    value = { min: 0, max: 100 },
    set_value = () => {},
}) {
    const prefix = 'SAR';
    const min = value.min || 0;
    const max = value.max || 100000;
    const step = 100;
    const initialValue = [min, max];

    const [intermediateTextFieldValue, setIntermediateTextFieldValue] =
		useState(initialValue);
    const [rangeValue, setRangeValue] = useState(initialValue);

    const handleRangeSliderChange = useCallback((value) => {
        setRangeValue(value);
        setIntermediateTextFieldValue(value);
    }, []);

    const handleLowerTextFieldChange = useCallback(
        (value) => {
            const upperValue = rangeValue[1];
            setIntermediateTextFieldValue([parseInt(value, 10), upperValue]);
        },
        [rangeValue]
    );

    const handleUpperTextFieldChange = useCallback(
        (value) => {
            const lowerValue = rangeValue[0];
            setIntermediateTextFieldValue([lowerValue, parseInt(value, 10)]);
        },
        [rangeValue]
    );

    const handleLowerTextFieldBlur = useCallback(() => {
        const upperValue = rangeValue[1];
        const value = intermediateTextFieldValue[0];

        setRangeValue([parseInt(value, 10), upperValue]);
    }, [intermediateTextFieldValue, rangeValue]);

    const handleUpperTextFieldBlur = useCallback(() => {
        const lowerValue = rangeValue[0];
        const value = intermediateTextFieldValue[1];

        setRangeValue([lowerValue, parseInt(value, 10)]);
    }, [intermediateTextFieldValue, rangeValue]);

    const handleEnterKeyPress = useCallback(
        (event) => {
            const newValue = intermediateTextFieldValue;
            const oldValue = rangeValue;

            if (event.keyCode === 13 && newValue !== oldValue) {
                setRangeValue(newValue);
            }
        },
        [intermediateTextFieldValue, rangeValue]
    );

    const lowerTextFieldValue =
		intermediateTextFieldValue[0] === rangeValue[0]
		    ? rangeValue[0]
		    : intermediateTextFieldValue[0];

    const upperTextFieldValue =
		intermediateTextFieldValue[1] === rangeValue[1]
		    ? rangeValue[1]
		    : intermediateTextFieldValue[1];

    return (
        <Card sectioned>
            <div onKeyDown={handleEnterKeyPress}>
                <RangeSlider
                    output
                    label="Order total is between"
                    value={rangeValue}
                    prefix={prefix}
                    min={min}
                    max={max}
                    step={step}
                    onChange={handleRangeSliderChange}
                />
                <Stack vertical>
                    <TextField
                        label="Min order total"
                        type="number"
                        value={`${lowerTextFieldValue}`}
                        prefix={prefix}
                        min={min}
                        max={max}
                        step={step}
                        onChange={handleLowerTextFieldChange}
                        onBlur={handleLowerTextFieldBlur}
                        autoComplete="off"
                    />
                    <TextField
                        label="Max order total"
                        type="number"
                        value={`${upperTextFieldValue}`}
                        prefix={prefix}
                        min={min}
                        max={max}
                        step={step}
                        onChange={handleUpperTextFieldChange}
                        onBlur={handleUpperTextFieldBlur}
                        autoComplete="off"
                    />
                </Stack>
                <br />
                <Button
                    onClick={() => {
                        set_value({ from: rangeValue[0], to: rangeValue[1] });
                    }}
                >
					Apply
                </Button>
            </div>
        </Card>
    );
}
