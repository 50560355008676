import React from 'react';
import {
    DataTable,
    Button,
    Modal,
    Input,
    Toggler,
    StatusToggler,
    DropdownButton,
    ButtonGroup,
    Dropdown,
} from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { GET, POST, DELETE, PUT, randomId, obj2formdata } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
import { stringify } from 'query-string';
import { ReactSortable } from 'react-sortablejs';

function ConfigsCustomAttributesTab() {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);
    const [sorting_mode, set_sorting_mode] = React.useState(false);
    const [attributes, set_attributes] = React.useState([]);

    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });
    const [is_active, set_is_active] = React.useState({
        label: 'Status',
        checked: false,
        onChange: (val) => set_is_active({ ...is_active, checked: val }),
    });
    const [filters, set_filters] = React.useState({
        is_active: null,
    });

    const fetchData = async () => {
        set_loading(true);
        const res = await GET({
            endpoint: `/product-custom-attribute?${stringify(
                { ...filters },
                {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'comma',
                }
            )}`,
        });
        set_loading(false);
        if (res?.status_code === 200) {
            set_data(res?.data || []);
            set_attributes(res?.data || []);
        }
    };
    React.useEffect(() => {
        fetchData();
    }, [filters]);

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'name_en',
        },
        {
            name: 'Status',
            selector: 'is_active',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row?.is_active}
                    path={`/product-custom-attribute/${row.id}/status`}
                    pathOff={`/product-custom-attribute/${row.id}/status`}
                    method={row?.is_active ? 'delete' : 'post'}
                />
            ),
        },
        {
            name: 'Actions',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'custom__attributes__delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
					&nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row?.id);
                            set_errors([]);
                            name_ar.setValue(row?.name_ar);
                            name_en.setValue(row?.name_en);
                            is_active.onChange(row?.is_active);

                            dispatch(
                                setModalStatus({
                                    modal: 'custom__attributes__modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const submit = async (closeModal, id) => {
        const res = await (id ? PUT : POST)({
            endpoint: id
                ? `/product-custom-attribute/${id}`
                : '/product-custom-attribute',
            data: {
                name_ar: name_ar.value,
                name_en: name_en.value,
                is_active: is_active?.checked ? 1 : 0,
            },
        });
        if (res?.status_code === 201 || res?.status_code === 200) {
            fetchData();
            closeModal('custom__attributes__modal');
            name_ar.setValue('');
            name_en.setValue('');
            is_active.onChange(false);
            set_errors([]);
        } else {
            set_errors(res?.data.errors);
        }
    };

    const deleteAttribute = async (closeModal) => {
        const res = await DELETE({
            endpoint: `/product-custom-attribute/${delete_id}`,
        });
        if (res?.status_code === 201 || res?.status_code === 200) {
            fetchData();
            closeModal('custom__attributes__delete_modal');
            set_delete_id(null);
            set_errors([]);
        } else {
            set_errors(res?.data.errors);
        }
    };
    const handleSorting = async () => {
        set_loading(true);
        const data = obj2formdata({});

        attributes.forEach((p, i) => {
            data.append('attributes[' + i + '][id]', p.id);
            data.append('attributes[' + i + '][priority]', i + 1);
        });

        const res = await POST({
            endpoint: '/product-custom-attribute/priority',
            data,
        });
        set_loading(false);
        if (res?.status_code === 201 || res?.status_code === 200) {
            set_sorting_mode(false);
            fetchData();
        }
    };
    const tableFilters = (
        <>
            <ButtonGroup key={1}>
                <DropdownButton
                    variant="light"
                    as={ButtonGroup}
                    title={
                        <span>
							Status
                            {filters.is_active === '1' && '(Active)'}
                            {filters.is_active === '0' && '(Inactive)'}
                        </span>
                    }
                    id={`bg-nested-dropdown-${randomId()}`}
                >
                    <Dropdown.Item
                        eventKey="1"
                        onClick={() =>
                            set_filters({
                                ...filters,
                                is_active: '1',
                            })
                        }
                    >
						Active
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="2"
                        onClick={() =>
                            set_filters({
                                ...filters,
                                is_active: '0',
                            })
                        }
                    >
						Inactive
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey="3"
                        onClick={() => set_filters({ ...filters, is_active: null })}
                    >
						Clear
                    </Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>
        </>
    );

    return (
        <>
            <div className="flex__jc-between px-3 py-2">
                <div className="flex__ai-center">
                    {!sorting_mode && (
                        <Button onClick={() => set_sorting_mode(true)}>
							Attribute Sorting
                        </Button>
                    )}
                </div>
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        name_ar.setValue('');
                        name_en.setValue('');
                        is_active.onChange(false);
                        set_errors([]);

                        dispatch(
                            setModalStatus({
                                modal: 'custom__attributes__modal',
                                status: true,
                            })
                        );
                    }}
                >
					Add new custom attribute
                </Button>
            </div>
            {sorting_mode && (
                <div className="sort-attribute">
                    <h3 className="sort-title">Sort Custom Attributes</h3>
                    <div className="flex__jc-end__ai-center mr-4">
                        {sorting_mode && (
                            <Button
                                variant="light"
                                onClick={() => {
                                    set_sorting_mode(false);
                                    set_attributes(data);
                                }}
                            >
								Cancel
                            </Button>
                        )}
                        <Button
                            className="ml-2"
                            variant="primary"
                            onClick={handleSorting}
                            disabled={loading}
                        >
							Save changes
                        </Button>
                    </div>
                    <ReactSortable
                        tag="ul"
                        list={attributes?.map((x) => ({ ...x, chosen: true }))}
                        setList={set_attributes}
                    >
                        {attributes.map((a) => (
                            <li key={a.id}>
                                <div className="handle">
                                    <span></span>
                                    <span></span>
                                </div>

                                <div className="name">
                                    {a.name_en} &nbsp; || &nbsp; {a.name_ar}
                                </div>
                                <div className="attr_status">
                                    <span className={a?.is_active && 'active'}>
                                        {a?.is_active ? 'active' : 'inactive'}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ReactSortable>
                </div>
            )}
            <Modal
                title={edit_id ? 'Update custom attribute' : 'Add new custom attribute'}
                variant={edit_id ? 'warning' : 'primary'}
                name="custom__attributes__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
            >
                <Input {...name_ar} />
                <Input {...name_en} />
                <div className="mt-4">
                    <Toggler {...is_active} />
                </div>

                {!!Object.keys(errors).length && (
                    <div className="alert alert-danger">
                        <ul>
                            {Object.keys(errors).map((key) => {
                                return <li key={key}>{errors[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
            </Modal>

            <Modal
                title="Delete custom attribute?"
                name="custom__attributes__delete_modal"
                variant="danger"
                onSubmit={deleteAttribute}
            >
                {!!Object.keys(errors).length && (
                    <div className="alert alert-danger">
                        <ul>
                            {Object.keys(errors).map((key) => {
                                return <li key={key}>{errors[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
            </Modal>

            <DataTable
                data={data}
                columns={columns}
                loading={loading}
                tableFilters={tableFilters}
                showOmitColumnsOption={false}
            />
        </>
    );
}

export default ConfigsCustomAttributesTab;
