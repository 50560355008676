/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import {
    ViewHeader,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Modal,
    Row,
    Col,
    Card,
    StatusBlock,
} from '@components';
import useMutation from '@hooks/useMutation';
import { POST } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadVendorRequestData } from '@slices/merchantRequests.slice';
import VendorChanges from './components/VendorChanges';
import { setModalStatus } from '@slices/ui.slice';
import TextWitSub from './components/TextWitSub';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { formatStringWithUnderscores } from '../../utils';

const WITH_REASON = ['rejected','needs_changes'];

const VendorRequest = () => {
    const { id } = useParams();
    const MODAL_NAME =`vendor_${id}_request_modal`;
    const [action, setAction] = useState(null);
    const inputRef = useRef();
    const dispatch =useDispatch();
    const { vendor_request, vendor_request_loading } = useSelector((state) => state.VendorRequestsSlice);
    const shouldAddReason =WITH_REASON.includes(action?.code);

    useEffect(() => {
        dispatch(loadVendorRequestData(id));
    }, [id]);

    const { mutate, error } = useMutation((data) => POST({
        endpoint: `/vendors/vendor-change-requests/${id}/state`,
        data,
    }), {
        onSuccess: () => onCloseModal()
    });

    const onOpenModal = (action) => {
        setAction(action);
        dispatch(setModalStatus({ modal: MODAL_NAME, status: true }));
    };

    const onCloseModal = () => {
        setAction(null);
        dispatch(loadVendorRequestData(id));
        dispatch(setModalStatus({ modal: MODAL_NAME, status: false }));
    };

    const onSubmit = () => {
        mutate({ state: action.code, reason: inputRef.current?.value });
    };

    if (vendor_request_loading) return 'loading...';

    return (
        <div className="vendor-categories-update-view">
            <ViewHeader title={`Merchant Request #${id}`} renderBackArrow >
                {!!vendor_request?.actions?.length &&<ButtonGroup>
                    <DropdownButton
                        as={ButtonGroup}
                        title="Actions"
                        id="bg-nested-dropdown"
                        variant="light"
                    >
                        {vendor_request?.actions?.map((action, i) => (
                            <Dropdown.Item
                                key={i}
                                eventKey={i}
                                onClick={() => onOpenModal(action)}
                            >
                                {action.action}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </ButtonGroup>}
            </ViewHeader>

            <Card>
                <div className="px-3 py-2">
                    <h3 className="divider-title">Request Information</h3>
                    <Row>
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Merchant"
                                sub={
                                    <Link to={`/merchants/${vendor_request?.vendor.id}`}>
                                        {vendor_request?.vendor.company_name}
                                    </Link>
                                }
                            />
                        </Col>
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Account Manger"
                                sub={<>{vendor_request?.vendor.account_manager?.first_name} {vendor_request?.vendor.account_manager?.last_name}</>}
                            />
                        </Col>
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Type"
                                sub={formatStringWithUnderscores(vendor_request?.type)}
                            />
                        </Col>
                        {vendor_request?.reason && <Col lg={3} md={4}>
                            <TextWitSub
                                text="Reason"
                                sub={vendor_request?.reason}
                            />
                        </Col>}
                        <Col lg={3} md={4}>
                            <TextWitSub
                                text="Status"
                                sub={<StatusBlock noIcon state={vendor_request?.state} />}
                            />
                        </Col>

                        {['update_product','product_activation'].includes(vendor_request?.type) && <>
                            <Col lg={3} md={4}>
                                <TextWitSub
                                    text="Product ID"
                                    sub={
                                        <Link to={`/products/${vendor_request?.data?.product_id}`}>
                                            {vendor_request?.data?.product_id}
                                        </Link>
                                    }
                                />
                            </Col>
                            <Col lg={3} md={4}>
                                <TextWitSub
                                    text="Product Name"
                                    sub={vendor_request?.data?.old_product?.name}
                                />
                            </Col>
                        </>}
                    </Row>
                </div>
            </Card>

            <Card>
                <div className="px-3 py-2">
                    <h3 className="divider-title">Merchant Changes</h3>

                    <VendorChanges data={vendor_request?.data} changesType={vendor_request?.type} />
                </div>
            </Card>


            <Modal
                title={`Update request state to ${action?.action}?`}
                name={MODAL_NAME}
                variant={shouldAddReason ? 'danger' : undefined}
                onSubmit={() => {
                    onSubmit();
                }}
            >
                {!!Object.keys(error || {}).length && (
                    <div className="alert alert-danger">
                        <ul>
                            {Object.keys(error|| {}).map((key) => {
                                return <li key={key}>{error?.[key]}</li>;
                            })}
                        </ul>
                    </div>
                )}
                {shouldAddReason && <Form.Control ref={inputRef} required  />}
            </Modal>
        </div>
    );
};

export default VendorRequest;