import React from 'react';
import { useSelector } from 'react-redux';
import { StatusToggler, Modal, Button, Input, DataTable } from '@components';
import { obj2formdata, POST, randomId } from '@utils';
import useQuery from '@hooks/useQuery';
import editIcon from '@assets/icons/edit.svg';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
function DiscountRangesTab() {
    const dispatch = useDispatch();
    const [edit_id, set_edit_id] = React.useState(null);
    const [loading, set_loading] = React.useState(false);
    const product = useSelector((state) => state.ProductsSlice.product);
    const selectedId = product?.variants[0].id;
    const { data: { data } = {}, refetch } = useQuery(
        !!selectedId && `/product/${selectedId}/range-percentage`,
    );
    React.useEffect(() => {
        if (selectedId && data) {
            set_discount_ranges(data);
        }
    }, [selectedId, data]);

    const [discount_ranges, set_discount_ranges] = React.useState([]);
    const [min_quantity, set_min_quantity] = React.useState({
        label: 'From',
        value: '',
        type: 'number',
        setValue: (val) => {
            set_min_quantity({ ...min_quantity, value: val });
        },
    });
    const [max_quantity, set_max_quantity] = React.useState({
        label: 'To',
        value: '',
        type: 'number',
        setValue: (val) => {
            set_max_quantity({ ...max_quantity, value: val });
        },
    });

    const [percentage, set_percentage] = React.useState({
        label: 'Percentage',
        value: '',
        type: 'number',
        setValue: (val) => {
            set_percentage({ ...percentage, value: val });
        },
    });
    const idempotencyKey = randomId();
    const [errors, set_errors] = React.useState({});
    const validateRange = () => {
        let error = {};

        if (min_quantity.value <= 0) {
            error.min_quantity = 'Min quantity must be greater than zero.';
        }

        if (max_quantity.value <= 0) {
            error.max_quantity = 'Max quantity must be greater than zero.';
        }

        if (parseInt(max_quantity.value) <= parseInt(min_quantity.value)) {
            error.max_quantity = 'Max quantity must be greater than min quantity.';
        }

        if (percentage.value <= 0) {
            error.percentage = 'Percentage must be greater than zero.';
        }
        set_errors(error);
        return Object.keys(error).length === 0;
    };
    React.useEffect(() => {
        validateRange();
    }, [min_quantity, max_quantity, percentage]);
    const submit = async (closeModal) => {
        set_loading(true);
        if (!validateRange()) return;
        const to_be_created = obj2formdata({});
        const to_be_updated = discount_ranges.find((d) => d.id === edit_id);

        if (to_be_updated) {
            const data = obj2formdata({});
            data.append('_method', 'PUT');
            data.append('min_quantity', min_quantity.value);
            data.append('max_quantity', max_quantity.value);
            data.append('percentage', percentage.value);

            try {
                const res = await POST({
                    endpoint: `/product/${selectedId}/range-percentage/${edit_id}`,
                    data,
                });
                if (res.status_code === 200 || res.status_code === 201) {
                    toast.info(res.data.message);
                } else {
                    toast.error(res.data.message || 'Validation error');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            to_be_created.append('range_percentage[0][min_quantity]', min_quantity.value);
            to_be_created.append('range_percentage[0][max_quantity]', max_quantity.value);
            to_be_created.append('range_percentage[0][percentage]', percentage.value);

            try {
                const create_request = await POST({
                    endpoint: `/product/${selectedId}/range-percentage`,
                    data: to_be_created,
                    headers: {
                        'Idempotency-Key': idempotencyKey,
                    },
                });

                if (create_request.status === 422) {
                    toast.error(create_request.data.message);
                } else if (
                    create_request.status_code === 201 ||
                    create_request.status_code === 200
                ) {
                    toast.info('New discount range created successfully');
                }
            } catch (error) {
            console.error('Error:', error);
            }
        }

        set_loading(false);
        refetch();
        closeModal();
    };

    const columns = [
        {
            name: 'From',
            selector: 'min_quantity',
        },
        {
            name: 'To',
            selector: 'max_quantity',
        },
        {
            name: 'Percentage',
            selector: 'percentage',
        },
        {
            name: 'Status',
            selector: 'is_active',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row?.status}
                    path={`/product/${selectedId}/range-percentage/${row.id}/active`}
                    pathOff={`/product/${selectedId}/range-percentage/${row.id}/deactivate`}
                    method="post"
                    callback={({ id, status }) => {
                        set_discount_ranges((prev) =>
                            prev.map((item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        status: status,
                                    };
                                }
                                return item;
                            }),
                        );
                    }}
                />
            ),
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            min_quantity.setValue(row.min_quantity);
                            max_quantity.setValue(row.max_quantity);
                            percentage.setValue(row.percentage);
                            dispatch(
                                setModalStatus({
                                    modal: 'discount_range_modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];
  
    return (
        <div>
            <div className="flex__jc-between p-4">
                <h1 className="h4">Discount Ranges</h1>

                <Button
                    onClick={() => {
                        set_edit_id(null);
                        min_quantity.setValue('');
                        max_quantity.setValue('');
                        percentage.setValue('');
                        dispatch(
                            setModalStatus({
                                modal: 'discount_range_modal',
                                status: true,
                            }),
                        );
                    }}
                >
                    Add new discount range
                </Button>
            </div>
            <DataTable data={discount_ranges} columns={columns} />

            <Modal
                title={edit_id ? 'Update discount range' : 'Add new discount range'}
                variant={edit_id ? 'warning' : 'primary'}
                name="discount_range_modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
                // loading={loading}
            >
                <Input {...min_quantity} />
                {loading && errors.min_quantity && (
                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>{errors.min_quantity}</p>
                )}
                <Input {...max_quantity} />
                {loading && errors.max_quantity && (
                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>{errors.max_quantity}</p>
                )}
                <Input {...percentage} />
                {loading && errors.percentage && (
                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>{errors.percentage}</p>
                )}
            </Modal>
        </div>
    );
}
export default DiscountRangesTab;