import React from 'react';
import { Modal, Select, Button, ApiAsyncSelect } from '@components';
import defaultLogo from '@assets/img/default.jpg';
import formatMoney from '@utils/formatMoney';
import { PUT, quantityOptions, randomId } from '@utils';
import { setOrder } from '@slices/orders.slice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const EditOrderProductsModal = ({ id, products, business_model = {}, reloadOrder }) => {
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const isFMCG = business_model.code === 'fmcg';

    const setRowProperty = (key, id) => {
        return (value) => {
            setData((prev) => {
                return prev.map((j) => {
                    if (j.id === id) {
                        j[key] = value;
                        j.total = j.price * j.quantity - (parseFloat(j.discount) || 0);
                    }
                    return j;
                });
            });
        };
    };

    React.useEffect(() => {
        setData([...products]);
    }, [products]);

    const [errors, setErrors] = React.useState([]);
    const onSubmit = async (close) => {
        set_loading(true);
        const res = await PUT({
            endpoint: `/order/${id}`,
            data: {
                products: data.map((j) => ({
                    id: j.id,
                    quantity: j.quantity,
                    // discount: parseFloat(j.discount),
                })),
            },
            headers: {
                'Idempotency-Key': randomId()
            }
        });

        set_loading(false);

        setErrors([]);
        if (res?.status === 422) {
            setErrors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            reloadOrder();
            close();
            dispatch(setOrder(res?.data.order));
            if (res?.data.warning_messages?.length) {
                res?.data.warning_messages.forEach((msg) => {
                    toast.warn(msg);
                });
            }
        }
    };

    const addDataProduct = (p) => {
        const prev = data.map((v) => v);
        if (prev.map((v) => v.id).indexOf(p.id) >= 0) {
            return;
        }
        const quantity = p.order_min_quantity;
        const total = p.price * quantity;
        prev.push({ ...p, quantity, total, discount: 0, editable: true });
        setData(prev);
    };

    const deleteDataProduct = (id) => {
        const prev = data.filter((v) => v.id !== id);
        setData(prev);
    };

    const getProductQuantityOptions = (product) => {
        return quantityOptions({
            order_min_quantity: product.order_min_quantity || 1,
            order_max_quantity: product.order_max_quantity || 1000,
            order_quantity_step: product.order_quantity_step || 1,
            top_allowed_value: isFMCG ? null : product.quantity
        });
    };

    return (
        <Modal
            title={'Edit Order Products #' + id}
            name="edit_order_products_modal"
            size="xl"
            onSubmit={onSubmit}
            loading={loading}
        >
            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
            {isFMCG && (
                <div className="px-3" style={{ zIndex: 5 }}>
                    <ApiAsyncSelect
                        api="/product"
                        placeholder="Type to add product..."
                        labelProperty={(r) => {
                            return (
                                <div className="custom-option-with-thumbnail">
                                    <img src={r.image} />
                                    <div className="content">
                                        <h4>{r.name}</h4>
                                        <p>{r.vendor?.name}</p>
                                    </div>
                                </div>
                            );
                        }}
                        onChange={(v) => addDataProduct(v)}
                        onlyFetchActiveRecords={true}
                    />
                </div>
            )}
            <div className="edit-order-products" style={{
                minHeight: '50vh'
            }}>
                <div className="table-tag-wrapper">
                    <table className="table-component-wrapper">
                        <thead>
                            <tr>
                                <td>Logo</td>
                                <td>Product</td>
                                <td>Price</td>
                                <td width="200">Discount</td>
                                <td width="200">Quantity</td>
                                <td>Subtotal</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, i) => (
                                <tr key={i}>
                                    <td width={100}>
                                        <div className="tr-logo">
                                            <img src={row.image || defaultLogo} alt="Logo" />
                                        </div>
                                        
                                    </td>
                                    <td>
                                        <div className="tr-name">
                                            <h5>{row.name}</h5>
                                            <h6 style={{ whiteSpace: 'nowrap' }}>
                                                <small>CODE: {row.id}</small>
                                                <br />
                                                {!!+row.min_amount && (
                                                    <small className="text-danger">
                                                    Minimum Order Amount is&nbsp;
                                                        {formatMoney(+row.min_amount)}
                                                    </small>
                                                )}
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tr-price">
                                            <span>SAR</span>
                                            <div>{formatMoney(row.price)}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tr-price">
                                            <span>SAR</span>
                                            <div>{formatMoney(row.discount)}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <Select
                                            options={getProductQuantityOptions(row)}
                                            value={
                                                getProductQuantityOptions(row)
                                                    .find((option) => option.value == row.quantity)
                                            }
                                            onChange={(val) => {
                                                if(val.value > row.quantity && !isFMCG) return;
                                                setRowProperty('quantity', row.id)(val.value);
                                            }}
                                            disabled={!row.editable}
                                        />
                                    </td>
                                    <td>
                                        <div className="tr-price">
                                            <span>SAR</span>
                                            <div>{formatMoney(row.total)}</div>
                                        </div>
                                    </td>
                                    <td>
                                        {row.editable && (
                                            <Button
                                                onClick={() => deleteDataProduct(row.id)}
                                                variant="danger"
                                                size="sm"
                                            >&times;</Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    );
};

export default EditOrderProductsModal;
