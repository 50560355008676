import PropTypes from 'prop-types';
import { Radio, Select } from '@components';

const AllOrSpecificSelection = ({
    selectProps,
    onChange,
    value,
    selectSpecificValue = 'specific',
    name,
    label,
}) => {
    return (
        <div style={{ marginBottom: 15 }}>
            {label && <p className="lead">{label}</p>}
            <Radio
                onChange={onChange}
                option={value}
                label={`All ${name}`}
                value="none"
                name={name}
            />

            <Radio
                onChange={onChange}
                option={value}
                label={`Select specific ${name}`}
                value={selectSpecificValue}
                name={name}
            />

            {value === selectSpecificValue && <Select {...selectProps} />}
        </div>
    );
};

AllOrSpecificSelection.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    selectSpecificValue: PropTypes.string,
    selectProps: PropTypes.shape({
        label: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default AllOrSpecificSelection;
