import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';
import PushNotificationsListView from './PushNotificationsListView';
import PushNotificationsAddView from './PushNotificationsAddView';
import { Helmet } from 'react-helmet';
import PushNotificationsSingleView from './PushNotificationsSingleView/PushNotificationsSingleView';

export default function PushNotificationsModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/(customers-notifications|vendors-notifications)/list">
                <Helmet>
                    <title>Push Notifications | Disty Admin Dashboard</title>
                </Helmet>
                <PushNotificationsListView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/(customers-notifications|vendors-notifications)/add">
                <Helmet>
                    <title>Create Push Notification | Disty Admin Dashboard</title>
                </Helmet>
                <PushNotificationsAddView />
            </ProtectedRoute>
            <ProtectedRoute exact path="/(customers-notifications|vendors-notifications)/:id">
                <Helmet>
                    <title>Notification | Disty Admin Dashboard</title>
                </Helmet>
                <PushNotificationsSingleView />
            </ProtectedRoute>
        </Switch>
    );
}
