import React from 'react';
import { useParams } from 'react-router-dom';
import { DataTable, Card, ViewHeader, Sop } from '@components';
import { formatDate, formatMoney } from '@utils';
import { GET } from '@utils';
import { useSelector } from 'react-redux';

function PushNotificationsSingleView() {
    const { id } = useParams();
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const [notifications, set_notifications] = React.useState([]);
    const [is_loading, set_is_loading] = React.useState(true);
    const fetchData = async () => {
        const res = await GET({ endpoint: `/notifications/${id}/logs` });
        set_is_loading(false);
        if (res?.status_code === 200) {
            set_notifications(res?.data);
        }
    };
    const formatArray = (subArray, parentArray, key) => {
        let holderArray = [];
        parentArray.forEach((item) => {
            for (let i = 0; i < subArray.length; i++) {
                if (item.id == subArray[i]) {
                    holderArray.push(item[key]);
                }
            }
        });

        return holderArray;
    };
    React.useEffect(() => {
        fetchData();
    }, []);
    const columns = [
        {
            name: 'Id',
            selector: 'id',
            width: '50px',

            cell: (row) => row?.id,
        },
        {
            name: 'Send By',
            selector: 'sender.name',

            cell: (row) => row?.sender?.name,
        },
        {
            name: 'Sent At',
            selector: 'sent_at',
            cell: (row) => formatDate(row?.sent_at),
        },
        {
            name: 'Received Devices Count',
            selector: 'received_devices_count',
            cell: (row) => row?.received_devices_count,
        },

        {
            name: 'Status',
            selector: 'scope.Statue',
            cell: (row) => row?.scope?.status,
        },
        {
            name: 'Wallet Balance',
            selector: 'scope.has_wallet_balance',
            cell: (row) => (row?.scope?.has_wallet_balance ? 'yes' : '_'),
        },
        {
            name: 'Cities',
            selector: 'scope.cities',
            cell: (row) => {
                if (row?.scope?.cities) {
                    return formatArray(
                        row?.scope?.cities,
                        GlobalsSlice.ksa_cities,
                        'label'
                    ).join(' , ');
                }
            },
        },
        {
            name: 'Account Managers',
            selector: 'scope.account_managers',
            cell: (row) => {
                if (row?.scope?.account_managers) {
                    return formatArray(
                        row?.scope?.account_managers,
                        GlobalsSlice.accountManagers,
                        'label'
                    ).join(' , ');
                }
            },
        },
        {
            name: 'Entity Types',
            selector: 'scope.entity_types',
            cell: (row) => {
                if (row?.scope?.entity_types) {
                    return formatArray(
                        row?.scope?.entity_types,
                        GlobalsSlice.entity_types,
                        'label'
                    ).join(' , ');
                }
            },
        },
        {
            name: 'Min Total Orders',
            selector: 'scope.min_total_orders',
            width: '50px',
            cell: (row) => row?.scope?.min_total_orders,
        },
        {
            name: 'Max Total Orders',
            selector: 'scope.max_total_orders',
            width: '50px',
            cell: (row) => row?.scope?.max_total_orders,
        },
        {
            name: 'Verification Statuses',
            selector: 'scope.verification_statuses',
            cell: (row) => row?.scope?.verification_statuses,
        },
        {
            name: 'Created At From',
            selector: 'scope.created_at_from',
            cell: (row) => formatDate(row?.scope?.created_at_from),
        },
        {
            name: 'Created At To',
            selector: 'scope.created_at_to',
            cell: (row) => formatDate(row?.scope?.created_at_to),
        },
        {
            name: 'Total Spent From',
            selector: 'scope.total_spent_from',
            width: '50px',
            cell: (row) => formatMoney(row?.scope?.total_spent_from),
        },
        {
            name: 'Total Spent To',
            selector: 'scope.total_spent_to',
            width: '50px',
            cell: (row) => formatMoney(row?.scope?.total_spent_to),
        },
    ];
    return (
        <div className="notification">
            <ViewHeader title="Notification logs">
                <Sop id="25403"/>
            </ViewHeader>

            <Card>
                <DataTable
                    columns={columns}
                    data={notifications}
                    loading={is_loading}
                ></DataTable>
            </Card>
        </div>
    );
}

export default PushNotificationsSingleView;
