import { formatMoney, formatDate } from '@utils';
import { Button, StatusBlock } from '@components';
import dollarIcon from '@assets/icons/dollar.svg';
import { setModalStatus } from '@slices/ui.slice';
import { store } from '@store';
import { Link } from 'react-router-dom';

import { ACTIONABLE_TABS } from './MerchantPayouts';

const { dispatch } = store;

const COLUMNS = [
    {
        name: 'Payout ID',
        width: '60px',
        cell: (row) => <Link to={`/vendor-payouts/${row.id}`}>{row.id}</Link>,
    },
    {
        name: 'Merchant ID',
        width: '80px',
        cell: (row) => (
            <Link to={`/merchants/${row.vendor?.id}`} target="_blank">
                <span>{row.vendor?.id}</span>
            </Link>
        ),
    },
    {
        name: 'Merchant name',
        width: '170px',
        cell: (row) => (
            <Link to={`/merchants/${row.vendor?.id}`} target="_blank">
                <div>{row.vendor?.company_name_en}</div>
                <div>{row.vendor?.company_name_ar}</div>
            </Link>
        ),
    },
    {
        name: 'Taxable/Non taxable',
        width: '70px',
        center: true,
        cell: (row) => <input type="checkbox" checked={row.is_vendor_taxable} disabled />,
    },
    {
        name: 'Payout Type',
        width: '100px',
        center: true,
        cell: (row) => `${row.payout_option?.days || 0} days`,
    },
    {
        name: 'Settlement period',
        width: '170px',
        cell: (row) => `${formatDate(row.from, false)} - ${formatDate(row.to, false)}`,
    },
    {
        name: 'Outstanding for',
        width: '100px',
        center: true,
        cell: (row) =>
            row.outstanding_for_days !== null ? `${row.outstanding_for_days} days` : '-',
    },
    {
        name: 'Number Of child Orders',
        width: '70px',
        center: true,
        cell: (row) => row.suborders_count,
    },
    {
        name: 'Sub Total amount for the settlement period',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.subtotal_including_vat || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Disty Commission for the settlement period',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.app_commission_with_vat || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Delivery Commission for the settlement period',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.delivery_commission_with_vat || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Disty Delivery fees for the settlement period',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.boxes_delivery_fees_with_vat || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Delivery Fees Paid To Merchant (Including Vat)',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.delivery_fees_paid_to_vendor_with_vat || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Merchant Discount for the settlement period',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.vendor_discount_with_vat || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Merchant Payouts for the settlement period',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.total_payout || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Paid Amount',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.paid_amount || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Total Remaining',
        width: '150px',
        cell: (row) => (
            <div className="tr-price">
                <span>SAR</span>
                <div>{formatMoney(row?.total_remaining || 0)}</div>
            </div>
        ),
    },
    {
        name: 'Merchant bank details',
        width: '230px',
        cell: (row) => (
            <div>
                <div>{row.vendor?.bank_account?.bank?.name}</div>
                <div>{row.vendor?.bank_account?.holder_name}</div>
                <div>{row.vendor?.bank_account?.iban}</div>
            </div>
        ),
    },
    {
        name: 'Status',
        width: '170px',
        cell: (row) => <StatusBlock state={row.state} />,
    },
    {
        name: 'Action',
        width: '200px',
        cell: (row) => (
            <div className="flex gap-x-2">
                <Button
                    onClick={() =>
                        dispatch(
                            setModalStatus({
                                modal: 'pay_payout_modal',
                                status: true,
                                payload: row,
                            }),
                        )
                    }
                    size="sm"
                    variant="info"
                    disabled={!ACTIONABLE_TABS.includes(row.state.code)}
                >
                    <img src={dollarIcon} style={{ marginRight: 5, width: 20 }} />
                    Pay
                </Button>
                <Link to={`/vendor-payouts/${row.id}`}>
                    <Button size="sm">Details</Button>
                </Link>
            </div>
        ),
    },
];

export default COLUMNS;
