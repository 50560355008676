import React from 'react';
import {
    Link,
    DataTable,
    StatusBlock,
    Select,
    Row,
    Col
} from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { loadSubOrdersData } from '@slices/orders.slice';
import { formatMoney } from '@utils';

export default function OrdersTab({ id }) {
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const dispatch = useDispatch();
    const [page, set_page] = React.useState(1);
    const [filters, set_filters] = React.useState();

    const [business_model, set_business_model] = React.useState({
        label: 'Filter by Business Model',
        options: OrdersSlice?.order_static_data?.business_models?.map((b) => ({
            id: b?.code,
            label: b?.description
        })),
        value: [],
        isMulti: true,
        getOptionValue: (option) => option.id,
        onChange: (val) => {
            const business_models = val.map(bm => bm.id);
            set_business_model((prev) => ({ ...prev, value: val }));
            handleFilters({ business_models, page: 1 });
        },
    });

    const handleFilters = (fltrs) => {
        set_filters((prev) => ({ ...prev, ...fltrs }));
    };

    const loadData = (params) => {
        dispatch(loadSubOrdersData(params));
    };

    React.useEffect(() => {
        if (filters) loadData(filters);
    }, [filters]);

    React.useEffect(() => {
        handleFilters({ vendors: [id], page });
    }, [id, page]);

    React.useEffect(() => {
        set_business_model({ ...business_model,
            options: OrdersSlice?.order_static_data?.business_models?.map((b) => ({
                id: b?.code,
                label: b?.description
            }))
		 });
    }, [OrdersSlice?.order_static_data?.business_models]);

    const columns = [
        {
            name: 'ID',
            selector: 'pretty_id',
            // sortable: false,
            width: '100px',
            cell: (row) => <Link href={`/orders/${row?.order?.id}/${row?.pretty_id}`}>
                #{row?.pretty_id}</Link>,
        },
        {
            name: 'Payment',
            selector: 'payment_status.description',
            sortable: false,
            cell: (row) => (
                <StatusBlock state={row.order.payment_status} />
            ),
        },
        {
            name: 'Business model',
            selector: 'business_model',
            sortable: false,
            cell: (row) => row?.business_model?.description
        },
        {
            name: 'Pay later',
            selector: 'pay_later_provider',
            width: '60px',
            sortable: false,
            cell: (row) => row?.order?.pay_later_provider?.description,
        },
        {
            name: 'State',
            selector: 'state.description',
            sortable: false,
            cell: (row) => (
                <StatusBlock state={row.state} />
            ),
        },
        {
            name: 'Total',
            selector: 'total',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total)}</div>
                </div>
            ),
        },
        {
            name: 'Subtotal (no vat)',
            selector: 'subtotal',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.subtotal)}</div>
                </div>
            ),
        },
        {
            name: 'VAT',
            selector: 'vat',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vat)}</div>
                </div>
            ),
        },
        {
            name: 'Discount',
            selector: 'discount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.discount)}</div>
                </div>
            ),
        },
        {
            name: 'Disty discount amount',
            selector: 'app_discount_amount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.app_discount_amount)}</div>
                </div>
            ),
        },
        {
            name: 'Merchant discount amount',
            selector: 'vendor_discount_amount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.vendor_discount_amount)}</div>
                </div>
            ),
        },
    ];

    return (
        <>
            <Row>
                <Col md={{ span: 4, offset: 8 }}>
                    <Select {...business_model} />
                </Col>
            </Row>
            <DataTable
                data={OrdersSlice.suborders}
                pages={OrdersSlice.suborders_pages}
                page={page}
                handlePageChange={set_page}
                columns={columns}
                searchable={false}
                loading={OrdersSlice.suborders_loading}
            />
        </>
    );
}
