import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { GET, POST, DELETE, randomId } from '@utils';


export const OnboardingScreensSlice = createSlice({
    name: 'onboarding-screens',
    initialState: {
        screens_list: [],
        screens_list_loading: false,
        screens_list_errors: [],
        screen: null,
        screen_errors: null,
        screen_loading: false,
    },
    reducers: {
        setScreensList(state, { payload }) {
            state.screens_list = payload;
        },
        setScreensListErrors(state, { payload }) {
            state.screens_list_errors = payload;
        },
        setScreen(state, { payload }) {
            state.screen = payload;
        },
        setScreenErrors(state, { payload }) {
            state.screen_errors = payload;
        },
        setLoading(state, { payload }) {
            state[payload.key] = payload.value;
        },
    },
});
export const {
    setScreensList,
    setScreensListErrors,
    setScreen,
    setScreenErrors,
    setLoading,
} = OnboardingScreensSlice.actions;

// Show
export const loadOnboardingScreens = (params = {}) => async (dispatch) => {
    dispatch(setScreensListErrors(null));
    dispatch(
        setLoading({
            key: 'screens_list_loading',
            value: true,
        })
    );
    const res = await GET({
        endpoint: `/marketing/onboarding-screens?${stringify(params, {
            skipNull: true,
            skipEmptyString: true,
            arrayFormat: 'comma',
        })}`,
    });
    dispatch(
        setLoading({
            key: 'screens_list_loading',
            value: false,
        })
    );
    if (res.success) {
        dispatch(setScreensList(res?.data || []));
    } else {
        dispatch(setScreensListErrors(res?.data?.errors || res?.data?.message));
    }
};

// create
export const createOnboardingScreen = (
    data,
    onSuccess = () => { },
    onFailed = () => { }
) => async (dispatch) => {
    const idempotencyKey = randomId();
    dispatch(
        setLoading({
            key: 'screen_loading',
            value: true,
        })
    );
    const res = await POST({
        endpoint: '/marketing/onboarding-screens',
        data,
        headers: {
            'Idempotency-Key': idempotencyKey
        }
    });
    if (res.success) {
        dispatch(loadOnboardingScreens());
        onSuccess();
    } else {
        dispatch(setScreenErrors(res?.data?.errors || res?.data?.message));
        onFailed();
    }

    dispatch(
        setLoading({
            key: 'screen_loading',
            value: false,
        })
    );
};

// edit
export const editOnboardingScreen = (
    id,
    data,
    onSuccess = () => { },
    onFailed = () => { },
) => async (dispatch) => {
    dispatch(setScreenErrors(null));
    dispatch(
        setLoading({
            key: 'screen_loading',
            value: true,
        })
    );
    const res = await POST({
        endpoint: `/marketing/onboarding-screens/${id}`,
        data,
    });
    if (res?.success) {
        dispatch(loadOnboardingScreens());
        onSuccess();
    } else {
        dispatch(setScreenErrors(res?.data?.errors || res?.data?.message));
        onFailed();
    }
    dispatch(
        setLoading({
            key: 'screen_loading',
            value: false,
        })
    );
};

// delete
export const deleteOnboardingScreen = () => async (dispatch, getState) => {
    const id = getState().OnboardingScreensSlice.screen.id;
    if (!id) return;
    dispatch(
        setLoading({
            key: 'screen_loading',
            value: true,
        })
    );
    const res = await DELETE({
        endpoint: `/marketing/onboarding-screens/${id}`
    });
    if (res?.success) {
        dispatch(loadOnboardingScreens());
        dispatch(setScreen(null));
        console.log('YES', res);
    } else {
        console.log('NO', res);
    }
    dispatch(
        setLoading({
            key: 'screen_loading',
            value: false,
        })
    );
};

// sort
export const sortOnboardingScreens = (data) => async (dispatch) => {
    dispatch(
        setLoading({
            key: 'screens_loading',
            value: true,
        })
    );
    const res = await POST({
        endpoint: '/marketing/onboarding-screens/display-order',
        data
    });
    if (res.success) {
        dispatch(loadOnboardingScreens());
    }

    dispatch(
        setLoading({
            key: 'screens_loading',
            value: false,
        })
    );
};

export default OnboardingScreensSlice.reducer;