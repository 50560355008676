import React from 'react';
import { Modal, Row, Col, Checkbox } from '@components';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@store/slices/ui.slice';
import useOmittedColumnsCache from '@hooks/useOmittedColumnsCache';
import { mergeArrayOfObjects } from '@utils';

const OmitTableColumnsModal = ({ columns }) => {
    const dispatch = useDispatch();
    const { setOmittedColumnsCache, getOmittedColumnsCache } = useOmittedColumnsCache();
    const [columns_as_checkboxes, set_columns_as_checkboxes] = React.useState([]);

    React.useEffect(() => {
        set_columns_as_checkboxes(mergeArrayOfObjects(columns, getOmittedColumnsCache(), 'name'));
    }, [columns]);

    const onChange = (column) => {
        set_columns_as_checkboxes([...columns_as_checkboxes].map(c => {
            if(c.name === column.name) c.omit = !c.omit;
            return c;
        }));
    };
    const onSubmit = () => {
        setOmittedColumnsCache([...columns_as_checkboxes].filter(c => c.omit));
        dispatch(setModalStatus({ modal: 'omit_table_columns_modal', status: false }));
    };

    return (
        <Modal name="omit_table_columns_modal" title="Show/Hide Columns" onSubmit={onSubmit}>
            <Row>
                {columns_as_checkboxes.map((c, i) => (
                    <Col key={i} md={6}>
                        <Checkbox
                            label={c.name}
                            checked={!c.omit}
                            onChange={(val) => onChange(c, val)}
                        />
                    </Col>
                ))}
            </Row>
        </Modal>
    );
};
export default OmitTableColumnsModal;