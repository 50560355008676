import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

export default function Root() {
    const token = useSelector(state => state.AuthSlice.token);

    return token ? (
        <Redirect to="/orders/list" />
    ) : (
        <Redirect to="/login" />
    );
}
