import React from 'react';
import {
    ViewHeader,
    Button,
    FileUpload,
    Select,
    Card,
    Row,
    Col,
    Toggler,
    Input,
    Radio,
    CustomPrompt,
    ShowErrors,
    Sop,
} from '@components';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { isArabic, isEnglish, obj2formdata, POST } from '@utils';

export default function CategoriesAddView() {
    const GlobalsSlice = useSelector((state) => state.GlobalsSlice);
    const [loading, set_loading] = React.useState(false);
    const [created, set_created] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [is_all_entity_types_selected, set_is_all_entity_types_selected] = React.useState('all');
    const [select_all, set_select_all] = React.useState(false);
    const [entity_types, set_entity_types] = React.useState({
        options: [],
        getOptionValue: (option) => option.id,
        value: [],
        isMulti: true,
        onChange: (val) => set_entity_types((prev) => ({ ...prev, value: val })),
    });

    React.useEffect(() => {
        // set globals
        set_parent_category({ ...parent_category, options: GlobalsSlice.categories_flat });
        set_entity_types({ ...entity_types, options: GlobalsSlice.entity_types });
    }, [GlobalsSlice]);

    const [status, set_status] = React.useState({
        label: 'Status',
        checked: true,
        onChange: (val) => set_status({ ...status, checked: val }),
    });
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => (isArabic(val) ? set_name_ar({ ...name_ar, value: val }) : ''),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => (isEnglish(val) ? set_name_en({ ...name_en, value: val }) : ''),
    });
    const [parent_category, set_parent_category] = React.useState({
        label: 'Parent category',
        options: [],
        value: [],
        getOptionValue: (option) => option.id,
        onChange: (val) => set_parent_category((prev) => ({ ...prev, value: val })),
    });
    const [priority, set_priority] = React.useState({
        label: 'Priority',
        type: 'number',
        value: '',
        setValue: (val) => set_priority({ ...priority, value: val }),
    });
    const [images, set_images] = React.useState({
        label: 'Images',
        files: [],
        setFiles: (val) => set_images({ ...images, files: val }),
    });
    const [validation_errors, set_validation_errors] = React.useState({});

    const validate = () => {
        const newErrors = {};
        if (!name_ar.value) newErrors.name_ar = 'The name (arabic) field is required.';
        if (!name_en.value) newErrors.name_en = 'The name (english) field is required.';
        if (images.files.length === 0) newErrors.images = 'The image field is required.';

        set_validation_errors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    React.useEffect(() => {
        validate();
    }, [name_ar, name_en, images]);
    const submit = async () => {
        set_loading(true);
        if (validate()) {
            const data = obj2formdata({
                is_active: status.checked ? '1' : '0',
                name_ar: name_ar.value,
                name_en: name_en.value,
                parent_id: parent_category?.value?.id || '',
                priority: priority.value,
            });

            images.files.forEach((image) => {
                data.append('image', image);
            });
            if (is_all_entity_types_selected === 'selected') {
                entity_types?.value.forEach((e, i) => {
                    data.append('personalized_entity_types[' + i + ']', e.id);
                });
            }

            const res = await POST({
                endpoint: '/category',
                data,
            });

            set_loading(false);
            setErrors([]);
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_created(res?.data.id);
            }
        }
    };

    if (created) {
        return <Redirect to="/categories/list" />;
    }

    return (
        <div className="categories-form-view">
            <CustomPrompt>
                <ViewHeader title="Create new category">
                    <Sop id="29983" />
                    <Button variant="primary" onClick={submit}>
                        Create
                    </Button>
                </ViewHeader>

                {!!Object.keys(errors).length && <ShowErrors errors={errors} />}

                <Card>
                    <div className="px-3 py-2">
                        <h4 className="divider-title">Category info</h4>
                        <Row>
                            <Col lg={6}>
                                <Toggler {...status} />
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_ar} />
                                        {loading && validation_errors.name_ar && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_ar}
                                            </p>
                                        )}
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Input {...name_en} />
                                        {loading && validation_errors.name_en && (
                                            <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                                {validation_errors.name_en}
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                                <Select {...parent_category} />
                                <Input {...priority} />
                                <div className="my-3">
                                    <label htmlFor="">Entity types</label> <br />
                                    <Radio
                                        onChange={set_is_all_entity_types_selected}
                                        option={is_all_entity_types_selected}
                                        label="All Entity"
                                        value="all"
                                        name="is-all-category-entity-selected"
                                    />
                                    <Radio
                                        onChange={set_is_all_entity_types_selected}
                                        option={is_all_entity_types_selected}
                                        label="Select specific entity"
                                        value="selected"
                                        name="is-all-category-entity-selected"
                                    />
                                    {is_all_entity_types_selected === 'selected' && (
                                        <>
                                            <Select {...entity_types} />
                                            <Button
                                                onClick={() => {
                                                    set_select_all(!select_all);
                                                    select_all
                                                        ? entity_types.onChange([])
                                                        : entity_types.onChange(
                                                              GlobalsSlice.entity_types,
                                                          );
                                                }}
                                                variant={select_all ? 'danger' : 'primary'}
                                            >
                                                {select_all ? 'undo' : 'select all'}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <FileUpload {...images} />
                                {loading && validation_errors.images && (
                                    <p style={{ color: 'red', margin: '-10px 0 10px' }}>
                                        {validation_errors.images}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card>
            </CustomPrompt>
        </div>
    );
}
