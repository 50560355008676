import React from 'react';
import { Link } from '@components';
import iconPermission from '@assets/icons/key.svg';

export default function NotEnoughPermissionsView() {
    return (
        <div className="generic-view-403">
            <img src={iconPermission} alt="403" />
            <h4>Not enough permissions!</h4>
            <p>
				You don&apos;t have enough permissions to access this page. Please
				contact your administrator.
            </p>

            <div className="generic-view-403-actions">
                <Link href="/" className="btn btn-primary">
                    Back to Dashboard
                </Link>

                {/* go back */}
                <div onClick={() => history?.back?.()} className="btn btn-link">
                    Go back
                </div>
            </div>
        </div>
    );
}
