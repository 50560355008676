import React from 'react';
import { Modal } from '@components';
import formatDate from '@utils/formatDate';
import formatMoney from '@utils/formatMoney';
import downloadFileFromAPI from '@utils/downloadFileFromAPI';

const AdjustmentDetailModal = ({ details }) => {
    return (
        <Modal 
            title="Adjustment details"
            name="adjustment_detail_modal"
            confirmBtn={false}
        >
            <div className="data-pairs__item">
                <div className="key">Order ID</div>
                <div className="value">
                    <div>{details?.order_id}</div>
                </div>
            </div>
            <div className="data-pairs">
                <div className="data-pairs__item">
                    <div className="key">Transaction ID</div>
                    <div className="value">
                        <div>{details?.id}</div>
                    </div>
                </div>
                <div className="data-pairs__item">
                    <div className="key">Transaction type</div>
                    <div className="value">
                        <div>{details?.type}</div>
                    </div>
                </div>
                <div className="data-pairs__item">
                    <div className="key">Method</div>
                    <div className="value">
                        <div>{details?.method?.toUpperCase()}</div>
                    </div>
                </div>
                {!!details?.pg_provider && (
                    <div className="data-pairs__item">
                        <div className="key">Provider</div>
                        <div className="value">
                            <div>{details?.pg_provider?.toUpperCase()}</div>
                        </div>
                    </div>
                )}
           
                <div className="data-pairs__item">
                    <div className="key">Reference Number</div>
                    <div className="value">
                        <div>{details?.adjustmentTransaction?.reference_number}</div>
                    </div>
                </div>
                <div className="data-pairs__item">
                    <div className="key">Amount</div>
                    <div className="value">
                        <div className="tr-price">
                            <span>{details?.currency?.toUpperCase()}</span>
                            <div>{formatMoney(details?.amount)}</div>
                        </div>
                    </div>
                </div>
                {!!details?.adjustmentTransaction?.description && (
                    <div className="data-pairs__item">
                        <div className="key">Description</div>
                        <div className="value">
                            <div>{details?.adjustmentTransaction?.description}</div>
                        </div>
                    </div>
                )}
                <div className="data-pairs__item">
                    <div className="key">Created at</div>
                    <div className="value">
                        <div>{formatDate(details?.created_at)}</div>
                    </div>
                </div>
                <div className="data-pairs__item">
                    <div className="key">Created by</div>
                    <div className="value">
                        <div>{details?.created_by?.name}</div>
                    </div>
                </div>
                <div className="data-pairs__item transaction-statuses-timeline">
                    <div className="key">Transaction statuses</div>
                    <div className="value">
                        {details?.statuses?.map(s => (
                            <div key={s.id}>
                                Status : {s.status}
                                <br />
                                Message : {s.pg_status_message}
                            </div>
                        ))}
                    </div>
                </div>
                {!!details?.adjustmentTransaction?.attachment && (
                    <>
                        <div className="data-pairs__item">
                            <div className="key">Attachment</div>
                            <div className="value">
                                    
                                <div
                                    className="btn btn-link"
                                    onClick={() => {
                                        downloadFileFromAPI(
                                            details?.adjustmentTransaction?.attachment);
                                    }}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                                Download attachment 
                                </div>
                                  
                            </div>
                        </div>
                           
                    </>
                )}
            </div>
        </Modal>
    );
};

export default AdjustmentDetailModal;