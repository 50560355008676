import React from 'react';
import { DataTable, Button, Modal, Input, ShowErrors } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { GET, POST, DELETE, PUT, randomId } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';
function PlansTab() {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    
    const [rerender, set_rerender] = React.useState(0);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);
    
    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/vendors/plans',
            });
            set_data(res?.data || []);
            set_loading(false);
        };
        fetchData();
    }, [rerender]);

    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });
    const [percentage, set_percentage] = React.useState({
        label: 'Percentage',
        value: '',
        setValue: (val) => set_percentage({ ...percentage, value: val }),
    });

    const submit = async (closeModal, id) => {
        set_loading(true);

        const res = await (id ? PUT : POST)({
            endpoint: id ? `/vendors/plans/${id}` : '/vendors/plans',
            data: {
                name_ar: name_ar.value,
                name_en: name_en.value,
                percentage: percentage.value,
            },
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('plans_type__modal');
            set_rerender(randomId());
            name_ar.setValue('');
            name_en.setValue('');
            percentage.setValue('');
            set_errors([]);
        }
    };

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'name_en',
        },
        {
            name: 'Percentage',
            selector: 'percentage',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            set_delete_id(row.id);
                            set_errors([]);
                            dispatch(
                                setModalStatus({
                                    modal: 'plans_type__delete_modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" width="16px" />
                    </Button>
					&nbsp;&nbsp;
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            set_errors([]);
                            name_ar.setValue(row.name_ar);
                            name_en.setValue(row.name_en);
                            percentage.setValue(row.percentage);

                            dispatch(
                                setModalStatus({
                                    modal: 'plans_type__modal',
                                    status: true,
                                })
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    const deletePlanEntity = async (closeModal) => {
        set_loading(true);
        const res = await DELETE({
            endpoint: `/vendors/plans/${delete_id}`,
        });
        set_loading(false);

        closeModal('plans_type__delete_modal');
        if(res?.status === 400){
            set_errors({ planAssigned : res?.data?.message });
        }
        else if (res?.status === 422 ) {
            set_errors(res?.data?.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('plans_type__delete_modal');
            set_rerender(randomId());
            set_delete_id(null);
        }
    };
    return (
        <>
            {!!Object.keys(errors).length && (
                <div className="alert alert-danger">
                    <ul>
                        {Object.keys(errors).map((key) => {
                            return <li key={key}>{errors[key]}</li>;
                        })}
                    </ul>
                </div>
            )}
            <DataTable data={data} columns={columns} loading={loading} />
            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        name_ar.setValue('');
                        name_en.setValue('');
                        percentage.setValue('');
                        set_errors([]);

                        dispatch(
                            setModalStatus({
                                modal: 'plans_type__modal',
                                status: true,
                            })
                        );
                    }}
                >
                Add new plans type entity
                </Button>
            </div>

            <Modal
                title={
                    edit_id ? 'Update plans type entity' : 'Add new plans type entity'
                }
                variant={edit_id ? 'warning' : 'primary'}
                name="plans_type__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
                loading={loading}
            >
                <Input {...name_ar} />
                <Input {...name_en} />
                <Input {...percentage} type='number' />
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>
      

            <Modal
                title="Delete plans type entity?"
                name="plans_type__delete_modal"
                variant="danger"
                onSubmit={deletePlanEntity}
                loading={loading}
            />
        </>
    );
}

export default PlansTab;
