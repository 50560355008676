import React from 'react';
import {
    ViewHeader,
    Card,
    Row,
    Col,
    DataTable,
    CustomerInfo,
    ShippingAddress,
    // Input,
    Button,
    Receipt,
    WhatsApp,
    Dialer,
    Sop
} from '@components';
// import searchIcon from '@assets/icons/search.svg';
import defaultLogo from '@assets/img/default.jpg';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadLiveCartData } from '@slices/liveCarts.slice';
import { setNewOrderFromLiveCart } from '../../store/slices/orders.slice';
import { useHistory } from 'react-router-dom';
import { setLiveCart } from '../../store/slices/liveCarts.slice';
import { formatMoney } from '@utils';

export default function LiveCartsSingleView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const LiveCartsSlice = useSelector((state) => state.LiveCartsSlice);
    // const [active_tab, set_active_tab] = React.useState('All');
    const [data, set_data] = React.useState([]);
    // const [products_search_query, set_products_search_query] =
    //     React.useState('');
    // const extractPrettyId = (t) =>
    //     t.vendor?.company_name || t.vendor?.id.toString();
    React.useEffect(() => {
        dispatch(setLiveCart({}));
        dispatch(loadLiveCartData(id));
    }, []);

    React.useEffect(() => {
        if (LiveCartsSlice?.liveCart?.id) {
            const products = [];
            LiveCartsSlice.liveCart.sub_carts.forEach((t) => {
                t.products.forEach((p) => {
                    products.push(p);
                });
            });
            set_data(products);
        }
    }, [LiveCartsSlice.liveCart]);

    if (LiveCartsSlice.liveCart_loading) {
        return 'loading...';
    }

    // const tabs = LiveCartsSlice.liveCart.sub_carts.map((sub) => ({
    //     name: extractPrettyId(sub),
    //     isActive: active_tab === extractPrettyId(sub)
    // }));

    // tabs.unshift({
    //     name: 'All',
    //     isActive: active_tab === 'All'
    // });

    // const setTabs = (tabs) => {
    //     set_active_tab(
    //         tabs
    //             .filter((t) => t.isActive)
    //             .slice(0, 1)
    //             .map((t) => t.name)
    //             .join()
    //     );
    // };

    const columns = [
        {
            name: 'Logo',
            selector: 'image',
            center: true,
            width: '100px',
            cell: (row) => (
                <div className="tr-logo">
                    <img src={row.image || defaultLogo} alt="Logo" />
                </div>
            )
        },
        {
            name: 'Product',
            selector: 'name',
            sortable: false,
            cell: (row) => (
                <div className="tr-name">
                    <h5>{row.name}</h5>
                    <h6 style={{ whiteSpace: 'nowrap' }}>
                        <small>CODE: {row.id}</small>
                    </h6>
                </div>
            )
        },
        {
            name: 'Price',
            selector: 'price',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.price)}</div>
                </div>
            )
        },
        {
            name: 'Price after discount',
            selector: 'price_after_discount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.price_after_discount)}</div>
                </div>
            )
        },
        {
            name: 'Quantity',
            selector: 'quantity',
            sortable: false,
            center: true
            /*cell: () => (
                <QuantityCounter
                    counter={counter}
                    setCounter={setCounter}
                />
            )*/
        },
        {
            name: 'Subtotal',
            selector: 'total',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.total)}</div>
                </div>
            )
        },
        {
            name: 'Discount',
            selector: 'discount',
            sortable: false,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row.discount)}</div>
                </div>
            )
        }
    ];

    let summaryDataCart = LiveCartsSlice.liveCart;
    // if (active_tab !== 'All') {
    //     LiveCartsSlice.liveCart.sub_carts.map((t) => {
    //         if (extractPrettyId(t) === active_tab) {
    //             summaryDataCart = t;
    //         }
    //     });
    // }
    const summaryData = [
        {
            subject: { en: 'Subtotal', ar: 'المنتجات' },
            note: 'Subtotal without VAT (بدون ضريبة القيمة المضافة)',
            price: summaryDataCart.subtotal
        },
        ...[
            ...summaryDataCart?.fees?.map((fee) => ({
                subject: { en: fee.name, ar: fee.name },
                price: fee.amount
            }))
        ],
        {
            subject: { en: 'VA', ar: 'ضريبة القيمة المضافة' },
            price: summaryDataCart.vat || 0
        },
        {
            subject: { en: 'Discount', ar: 'الخصم' },
            price: summaryDataCart.discount
        }
    ];
    const customerInfo = [
        {
            key: 'Company name',
            value: LiveCartsSlice.liveCart.customer.store_name,
            className: 'btn btn-link',
            onClick: () => {
                window.open(
                    `/customers/${LiveCartsSlice.liveCart.customer.id}`
                );
            }
        },
        {
            key: 'Customer name',
            value: LiveCartsSlice.liveCart.customer.name
        },
        {
            key: 'Customer email',
            value: <a href={`mailto:${LiveCartsSlice.liveCart.customer.email}`}>
                {LiveCartsSlice.liveCart.customer.email}</a>
        },
        //{ key: 'Customer IP address', value: '41.38.59.33',  },
        {
            key: 'Customer mobile',
            value: <div className='flex__jc-between__ai-center'>
                <div className='mr-5'>
                    <Dialer number={LiveCartsSlice?.liveCart?.customer?.mobile}/>
                </div>
                <div className="whatsApp__wrapper">
                    <WhatsApp number={LiveCartsSlice?.liveCart?.customer?.mobile}/>
                </div>
            </div>

        }
    ];
    let shippingAddresses = null;
    if (LiveCartsSlice.liveCartAddress) {
        shippingAddresses = {
            data: [
                {
                    key: 'Branch name',
                    value: LiveCartsSlice.liveCartAddress.name
                },
                {
                    key: 'Contact name',
                    value: LiveCartsSlice.liveCartAddress.contact_name
                },
                {
                    key: 'Contact mobile',
                    value: LiveCartsSlice.liveCartAddress.contact_mobile
                },
                {
                    key: 'Preferred delivery time',
                    value: LiveCartsSlice.liveCartAddress.suitable_delivery_time
                        ?.name,
                    className: 'badge badge-light'
                },
                {
                    key: 'Special Mark',
                    value: LiveCartsSlice.liveCartAddress.special_mark
                },
                {
                    key: 'Details',
                    value: LiveCartsSlice.liveCartAddress.details
                },
                {
                    key: 'Courier',
                    value: '' // TODO
                },
                {
                    key: 'Tracking number',
                    value: '' // TODO
                }
            ],
            address: {
                country: 'Saudi Arabia'
            },
            coordinates: {
                latitude: LiveCartsSlice.liveCartAddress.latitude,
                longitude: LiveCartsSlice.liveCartAddress.longitude
            }
        };
    }

    const headerTitle = (
        <div className="order-info">
            <h1>
                Live cart{' '}
                <span className="c-text fs-md ml-1">
                    #{LiveCartsSlice.liveCart.id}
                </span>
            </h1>
        </div>
    );

    const createOrderFromLiveCart = () => {
        const order = {
            live_cart_id: LiveCartsSlice.liveCart?.id,
            customer_id: LiveCartsSlice.liveCart?.customer?.id,
            address_id: LiveCartsSlice.liveCart?.address_id,

            scheduled_order: LiveCartsSlice.liveCart?.scheduled_order,
            scheduled_order_id: LiveCartsSlice.liveCart?.scheduled_order_id,
            scheduled_order_delivery_option_id:
                LiveCartsSlice.liveCart?.scheduled_order_delivery_option?.id,
            scheduled_order_payment_option_id:
                LiveCartsSlice.liveCart?.scheduled_order_payment_option?.id,

            products: data.map((p) => ({
                id: p.id,
                quantity: p.quantity,
                discount: p.discount
            }))
        };

        dispatch(setNewOrderFromLiveCart(order));
        history.push('/orders/add');
    };

    return (
        <div className="live-carts-single-view">
            <ViewHeader title={headerTitle} renderBackArrow>
                <div className="flex__ai-center">
                    <Sop id="25123"/>
                    <Button
                        variant="outline-secondary"
                        onClick={createOrderFromLiveCart}
                    >
                    Create order from this live cart
                    </Button>
                </div>
            </ViewHeader>
            <Row>
                <Col lg="8">
                    <Card>
                        {/* <div className="px-3 py-2">
                            <Input
                                type="search"
                                placeholder="Search by product name"
                                required={false}
                                value={products_search_query}
                                setValue={set_products_search_query}
                                icon={searchIcon}
                            />
                        </div> */}
                        {/* <Tabs tabs={tabs} onSelect={setTabs}>
                            </Tabs> */}
                        {data.length > 0 ? (
                            <DataTable data={data} columns={columns} />
                        ) : (
                            <div className="d-block m-3 p-4 text-center c-subdued">
                                No products added yet!
                            </div>
                        )}
                        <Receipt
                            data={summaryData}
                            total={summaryDataCart.total}
                        />
                    </Card>
                </Col>
                <Col lg={4}>
                    <CustomerInfo data={customerInfo} />
                    {shippingAddresses && (
                        <ShippingAddress
                            shippingAddressInfo={shippingAddresses}
                            map
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
}
