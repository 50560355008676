import { POST, randomId } from '@utils';
import BaseProductForm from './components/BaseProductForm';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setModalStatus } from '@slices/ui.slice';
import { Redirect } from 'react-router';
import ProductVariantsForm from './components/ProductVariantsForm';
import { Button, Card, Checkbox, ViewHeader, Sop } from '@components';
import ProductReviewModal from './components/ProductReviewModal';
import pick from 'lodash.pick';
import omit from 'lodash.omit';
import serialize from '@utils/objectSerializer';
import { normalizePayload, normalizeVariantPayload } from './product-utils';

const ProductsAddView = () => {
    const [has_variants, set_has_variants] = useState(false);
    const [initial_data, set_initial_data] = useState({});
    const [created, set_created] = useState(null);
    const [errors, setErrors] = useState([]);
    const [loading, set_loading] = useState(false);
    const [variant_default_values, set_variant_default_values] = useState({});
    const [variantsState, setVariantsState] = useState([]);
    const dataWithoutUnWantedKeys = omit(initial_data, ['upc_code']);
    const dispatch = useDispatch();

    const idempotencyKey = randomId();
    const handleVariantChange = (variantName, data) => {
        set_variant_default_values((prev) => ({ ...prev, [variantName]: data }));
    };

    const [showErrors, setShowErrors] = useState(false);

    const handleSubmit = async (closeModal) => {
        try {
            set_loading(true);
            const payload = {
                ...initial_data,
                variants: (has_variants
                    ? Object.values(variant_default_values)
                    : [initial_data]
                ).map((variant) =>
                    normalizeVariantPayload({
                        ...dataWithoutUnWantedKeys,
                        ...variant,
                        status: 'new',
                    }),
                ),
            };

            const data = serialize(normalizePayload(payload), { indices: true });
            const res = await POST({
                endpoint: '/base-product',
                data,
                headers: {
                    'Idempotency-Key': idempotencyKey,
                },
            });

            setErrors([]);
            set_loading(false);
            dispatch(
                setModalStatus({
                    modal: 'product__review_modal',
                    status: false,
                }),
            );
            if (res?.status === 422) {
                setErrors(res?.data.errors);
            } else if (res?.status_code === 201 || res?.status_code === 200) {
                set_created(res?.data.id);
                closeModal('product__review_modal');
            }
        } catch (error) {
            set_loading(false);
        }
    };

    const [is_valid, set_is_valid] = useState(false);
    const handelOnClick = () => {
       if (is_valid) {
            setShowErrors(false);
            dispatch(
                setModalStatus({
                    modal: 'product__review_modal',
                    status: true,
                }),
            );
        } else {
            setShowErrors(true);
        }
    };
    const product_review = useMemo(() => {
        const product_review_values = pick(initial_data, [
            'name_en',
            'name_ar',
            'price',
            'description_ar',
            'description_en',
            'suggested_retail_price',
            'order_min_quantity',
            'order_max_quantity',
            'order_quantity_step',
            'units_per_pack',
            'item_weight',
            'item_weight_unit',
            'packaging_weight',
            'packaging_weight_unit',
            'item_volume',
            'item_volume_unit',
            'packaging_length',
            'packaging_width',
            'packaging_height',
            'packaging_stackable',
            'packaging_max_stackable_level',
            'packaging_combinable',
            'model_number',
            'serial_number',
        ]);
        return {
            ...product_review_values,
            video: initial_data.video?.files?.[0]?.preview,
            images: initial_data.images?.files?.map((img) => img.preview),
            vendor: initial_data.vendor?.company_name_en || '',
        };
    }, [initial_data]);

    if (created) {
        return <Redirect to={`/products/${created}`} />;
    }

    return (
        <div>
            <ViewHeader title="Add new product" renderBackArrow>
                <Sop id="29963" />

                <Button disabled={loading} onClick={handelOnClick}>
                    Create
                </Button>
            </ViewHeader>

            <ProductReviewModal
                data={product_review}
                handleSubmit={handleSubmit}
                loading={loading}
            />
            <BaseProductForm
                loading={loading}
                errors={errors}
                initialValues={{}}
                onFieldChange={set_initial_data}
                showErrors={showErrors}
                set_is_valid={set_is_valid}
                showDiscountRanges={true}
            >
                <Card className="p-3">
                    <div>
                        <h3 className="divider-title">Product variants</h3>
                        <Checkbox
                            onChange={(checked) => set_has_variants(checked)}
                            checked={has_variants}
                            id="has_variants"
                            label="This product has options, like size or color"
                        />
                    </div>
                    {has_variants && (
                        <ProductVariantsForm
                            onFieldChange={handleVariantChange}
                            onVariantChange={(variants) => set_variant_default_values(variants)}
                            baseProductData={dataWithoutUnWantedKeys}
                            variantsData={variant_default_values}
                            variants={variantsState}
                            setVariants={setVariantsState}
                        />
                    )}
                </Card>
            </BaseProductForm>
        </div>
    );
};

export default ProductsAddView;
