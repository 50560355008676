
import React from 'react';
import { GET, PATCH } from '@utils';
import { DataTable, StatusToggler, Modal, Input, Button, ShowErrors } from '@components';
import editIcon from '@assets/icons/edit.svg';
import { setModalStatus } from '@slices/ui.slice';
import useMutation from '@hooks/useMutation';
import { useDispatch } from 'react-redux';
function BannerPositionTab() {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [is_loading, set_is_loading] = React.useState(true);
    const [edit_id, set_edit_id] = React.useState(null);
    const[show_error,set_show_error]=React.useState(false);
    const [name_ar, set_name_ar] = React.useState({
        label: 'Name (arabic)',
        value: '',
        setValue: (val) => set_name_ar({ ...name_ar, value: val }),
    });
    const [name_en, set_name_en] = React.useState({
        label: 'Name (english)',
        value: '',
        setValue: (val) => set_name_en({ ...name_en, value: val }),
    });
    const [priority, set_priority] = React.useState({
        label: 'Priority',
        value: '',
        type: 'number',
        setValue: (val) => {set_priority({ ...priority, value: val });
        val?set_show_error(false):set_show_error(true) ;
    },
    });
    const fetchData = async () => {
        const res = await GET({
            endpoint: '/marketing/banners/banner-position-list-details',
        });
        set_data(res?.data || []);
        set_is_loading(false);
    };
    React.useEffect(() => {
        fetchData();
    }, [priority.value]);

    const updateMutations = useMutation(
        () =>
            PATCH({
                endpoint: `/marketing/banners/${edit_id}/priority-bannerposition`,
                data: {
                    priority: priority.value,
                },
            }),
        {
            onSuccess: () => {
                dispatch(
                    setModalStatus({
                        modal: 'banner_position__modal',
                        status: false,
                    }),
                );
                priority.setValue('');
            },
        },
    );

    const columns = [
        {
            name: 'Name (AR)',
            selector: 'name_ar',
        },
        {
            name: 'Name (EN)',
            selector: 'name_en',
        },
        {
            name: 'Priority',
            selector: 'priority',
        },
        {
            name: 'Status',
            selector: 'is_active',
            sortable: false,
            cell: (row) => (
                <StatusToggler
                    id={row.id}
                    checked={row?.status}
                    path={`/marketing/banners/${row.id}/activate-bannerposition`}
                    pathOff={`/marketing/banners/${row.id}/deactivate-bannerposition`}
                    method= 'post'
                    callback={({ id, status }) => {
                        set_data((prev) =>
                            prev.map((item) => {
                                if (item.id === id) {
                                    return {
                                        ...item,
                                        status:status,
                                    };
                                }
                                return item;
                            }),
                        );
                    }}
                />
            ),
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            set_edit_id(row.id);
                            priority.setValue(row.priority);
                            dispatch(
                                setModalStatus({
                                    modal: 'banner_position__modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <img src={editIcon} alt="Edit" width="16px" />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <>
            <DataTable data={data} columns={columns} loading={is_loading} />

            <Modal
                title="Update banner position"
                variant="warning"
                name="banner_position__modal"
                onSubmit={priority.value?() => {updateMutations.mutate();set_show_error(false);}:()=>set_show_error(true)}
                loading={updateMutations.isLoading}
            >
                <Input {...priority} />
                {show_error&&<p className='alert alert-danger'>The priority field is required</p>}
                {updateMutations.error && <ShowErrors errors={updateMutations.error} />}
            </Modal>
        </>
    );
}
export default BannerPositionTab;