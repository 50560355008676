import React from 'react';
import { useVisibilityType } from '../context/VisibilityType';
import { useLocation } from 'react-router-dom';

/**
 *
 * @param {{
 *  children: ReactNode;
 *  visibility?: "both" | "variant" | "base";
 *  view?: "both" | "add" | "edit"
 * }} props
 * @returns {JSX.Element}
 */
const FieldVisibility = ({ children, visibility = 'both', view = 'both' }) => {
    const { pathname } = useLocation();
    const currentVisibilityType = useVisibilityType();
    const currentView = pathname === '/products/add' ? 'add' : 'edit';
    const isViewAllowed = view === 'both' || view === currentView;
    const isFieldPermitted =
        ['both', 'variant'].includes(visibility) || currentVisibilityType === visibility;

    if (isViewAllowed && isFieldPermitted) return <>{children}</>;
    return <></>;
};

export default FieldVisibility;
