import React from 'react';
import { 
    ViewHeader,
    Button,
    StatusBlock,
    Card,
    Row,
    Col,
    DataTable,
    Modal,
    Input,
    Sop
} from '@components';
import {
    formatMoney,
    formatDate,
    POST,
    errorsNormalizer,
    getPercentage
} from '@utils';
import useMutation from '@hooks/useMutation';
import { toast } from 'react-toastify';
import { setModalStatus } from '@slices/ui.slice';
import downloadFileFromAPI from '@utils/downloadFileFromAPI';
import { useDispatch, useSelector } from 'react-redux';
import { loadSelfInvoiceData, loadAuditsData } from '@slices/selfInvoices.slice';
import { useParams } from 'react-router-dom';

export default function SingleView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const SelfInvoicesSlice = useSelector((state) => state.SelfInvoicesSlice);
    const [download_credit_note_id, set_download_credit_note_id] = React.useState(null);

    const [credit_note_amount, set_credit_note_amount] = React.useState({
        label: 'Amount',
        value: '',
        setValue: (val) => set_credit_note_amount({ ...credit_note_amount, value: val })
    });
    const [reason, set_reason] = React.useState({
        label: 'Reason',
        value: '',
        setValue: (val) => set_reason({ ...reason, value: val })
    });

    const fetchData = async () => {
        dispatch(loadSelfInvoiceData(id));
        dispatch(loadAuditsData(id));
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const self_invoice = SelfInvoicesSlice?.self_invoice;
    const audits = SelfInvoicesSlice?.audits?.audits;
    const receipt_url = `/vendors/self-invoices/${id}/receipt`;

    const { isLoading, mutate } = useMutation((url, file_name) =>
        downloadFileFromAPI(
            url,
            file_name ,
        ),
    );

    const createMutation = useMutation(
        () =>
            POST({
                endpoint: `/vendors/self-invoices/${id}/credit-notes`,
                data: { reason: reason?.value, amount: credit_note_amount?.value },
            }),

        {
            onSuccess: (res) => {
                if(res?.status_code === 201){
                    dispatch(
                        setModalStatus({
                            modal: 'create_self_invoice_credit_note_modal',
                            status: false
                        })
                    );
                    toast.success(res?.message);
                    credit_note_amount?.setValue('');
                    reason?.setValue('');
                    fetchData();
                } else {
                    toast.error(res?.data?.message);
                }
                
            },
            onError: (errors) => toast.error(errorsNormalizer(errors).join(', '))
        },
    );

    const updateMutation = useMutation(
        () =>
            POST({
                endpoint: `/vendors/self-invoices/pay?self_invoices[]=${id}`,
            }),

        {
            onSuccess: () => {   
                toast.success('payment status updated successfully');
                fetchData();          
            },
            onError: (errors) => toast.error(errorsNormalizer(errors).join(', '))
        },
    );

    const columns = [
        {
            name: 'Credit Note Id',
            selector: 'id',
            width: '100px',
            cell: (row) => row?.id,
        },
        {
            name: 'Pretty ID',
            selector: 'pretty_id',
            cell: (row) => row?.pretty_id,
        },
        {
            name: 'Creation date',
            selector: 'created_at',
            sortable: true,
            format: (row) => formatDate(row?.created_at),
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
            cell: (row) => (
                <div className="tr-price">
                    <span>SAR</span>
                    <div>{formatMoney(row?.amount)}</div>
                </div>
            ),
        },
        {
            name: 'Reason',
            selector: 'reason',
            cell: (row) => row?.reason
        },
        {
            name: 'Receipt',
            cell: (row) => (
                <div
                    onClick={() => {
                        mutate(
                            `/vendors/self-invoices/${id}/credit-notes/${row?.id}/receipt`,
                            row?.receipt
                        );
                        set_download_credit_note_id(row?.id);
                    }}
                    rel="noreferrer"
                    target="_blank"
                >
                    {isLoading && download_credit_note_id === row?.id ? 
                        'loading...' : 'Download receipt'}
                </div>
            )
        },
    ];

    const headerTitle = (
        <div className="flex__ai-center__gap-2">
            <h1>Invoice #{self_invoice?.pretty_id}</h1>
            <div className="flex__gap-2" style={{ gap: '0.6rem' }}>
                <StatusBlock state={{ 
                    code: 1, 
                    description: self_invoice?.is_paid ? 'Paid' : 'Not Paid' }} />
                <StatusBlock state={{ 
                    code: 0, 
                    description: self_invoice?.ready_to_pay ? 
                        'Ready to pay' : 'Not ready to pay' }} />
                <StatusBlock state={{ 
                    code: 400, 
                    description: self_invoice?.has_credit_notes ? 
                        'Has credit notes' : 'no credit notes' }} />
            </div>         
        </div>
    );

    if (SelfInvoicesSlice?.self_invoice_loading) {
        return 'loading...';
    }
    return (
        <div className="invoices-update-view">
            <ViewHeader title={headerTitle} renderBackArrow>
                <div className="flex__ai-center">
                    <Sop id="25283"/>
                    <Button 
                        variant="secondary"
                        onClick={() => {
                            dispatch(
                                setModalStatus({
                                    modal: 'create_self_invoice_credit_note_modal',
                                    status: true
                                })
                            );
                        }}
                    >
					Create Credit Note
                    </Button>
                    <Button 
                        variant="secondary"
                        onClick={() => {
                            mutate(
                                receipt_url,
                                `self-invoices_${id}_receipt`
                            );
                            set_download_credit_note_id(null);
                        }}
                        disabled={isLoading && 
						!download_credit_note_id}
                    >
					Download receipt
                    </Button>
                    {self_invoice?.ready_to_pay &&
					!self_invoice?.is_paid && 
					<Button 
					    variant="secondary"
					    onClick={() => {
					        dispatch(
					            setModalStatus({
					                modal: 'invoice_payment_status_confirm_modal',
					                status: true
					            })
					        );
					    }}
					    disabled={updateMutation?.isLoading}
					>
						Pay
					</Button>}
                </div>
            </ViewHeader>
            <Card>
                <section>
                    <h3 className="divider-title">Invoice info</h3>
                    <div className="invoice-base-info">
                        <div className="invoice-details">
                            <h4>Invoice details</h4>
                            <ul>
                                <li>
                                    <p>Invoice number</p>
                                    <p>{self_invoice?.id}</p>
                                </li>
                                <li>
                                    <p>Subtotal</p>
                                    <p>
                                        {formatMoney(self_invoice?.subtotal)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>VAT</p>
                                    <p>
                                        {formatMoney(self_invoice?.vat)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Merchant commission</p>
                                    <p>
                                        {formatMoney(
                                            self_invoice?.vendor_commission
                                        )}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Merchant discount amount</p>
                                    <p>
                                        {formatMoney(
                                            self_invoice?.vendor_discount_amount
                                        )}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Amount</p>
                                    <p>
                                        {formatMoney(self_invoice?.amount)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                {self_invoice?.is_invoice_financing && (
                                    <>
                                        <li>
                                            <p>{`Down Payment 
										${getPercentage(
            								self_invoice?.amount,
            								self_invoice?.down_payment_amount)}%`}
                                            </p>
                                            <p>
                                                {formatMoney(
                                                    self_invoice?.down_payment_amount)}{' '}
                                                <span>SAR</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p>{`Second Payment 
										${getPercentage(
            								self_invoice?.amount,
            								self_invoice?.
                								remaining_invoice_amount_after_down_payment)
        								}%`}
                                            </p>
                                            <p>
                                                {formatMoney(
                                                    self_invoice?.
                                                        remaining_invoice_amount_after_down_payment
                                                )}{' '}
                                                <span>SAR</span>
                                            </p>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <p>Payable amount</p>
                                    <p>
                                        {formatMoney(self_invoice?.payable_amount)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                                <li>
                                    <p>Credit note amount</p>
                                    <p>
                                        {formatMoney(self_invoice?.credit_note_amount)}{' '}
                                        <span>SAR</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        {SelfInvoicesSlice?.audits_loading ? (
                            'loading...'
                        ) : (
                            <div className="states-timeline">
                                <h4>Invoice states timeline</h4>
                                <div className="timeline">
                                    <ul className="timeline__list">
                                        {audits?.map((event, i) => (
                                            <li key={i}>
                                                <span>
                                                    {event?.event}{' '}
                                                    {event?.user}{' '}
                                                    {formatDate(event.date)}
                                                </span>
                                                <h4>{event.message}</h4>
                                                {event?.changes?.receipt && (
                                                    <p>
                                                    receipt :{' '}
                                                        <a
                                                            onClick={() => {
                                                                mutate(
                                                                    receipt_url,
                                                                    event?.changes?.receipt?.new
                                                                );
                                                                set_download_credit_note_id(null);
                                                            }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                        >
                                                            {isLoading && 
																!download_credit_note_id ? 
                                                                'loading...' : 'Download receipt'}
                                                        </a>
                                                    </p>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}					
                    </div>
                </section>
                {/* currently the endpoint does not return suborders*/}
                {/* <section>
                    <h3 className="divider-title">Suborders</h3>
                    <div className="px-3 py-2">
                        <Row>
                            <Col xs={12}>
                                <DataTable
                                    data={'' || []}
                                />
                            </Col>
                        </Row>
                    </div>
                </section> */}
                <section>
                    <h3 className="divider-title">Credit Notes</h3>
                    <div className="px-3 py-2">
                        <Row>
                            <Col xs={12}>
                                <DataTable
                                    data={self_invoice?.credit_notes || []}
                                    columns={columns}
                                />
                            </Col>
                        </Row>
                    </div>
                </section>
            </Card>
            <Modal
                title={`Create credit note against invoice ${self_invoice?.id}`}
                name="create_self_invoice_credit_note_modal"
                loading={createMutation?.isLoading}
                onSubmit={() => {
                    createMutation?.mutate();
                }}
            >
                <Input {...credit_note_amount} />
                <Input {...reason} />
            </Modal>
            <Modal
                title={`Are you sure you want to turn the invoice ${id} status to Paid?`}
                name="invoice_payment_status_confirm_modal"
                loading={updateMutation?.isLoading}
                onSubmit={(closeModal) => {
                    updateMutation?.mutate();
                    closeModal('invoice_payment_status_confirm_modal');
                }}
            />
        </div>
    );
}
