import React from 'react';
import {
    ViewHeader,
    Card,
    Row,
    Col,
    Button,
    DataTable,
    ApiAsyncSelect,
    ShowErrors,
} from '@components';
import { formatDate, POST, GET } from '@utils';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const LOG_COLUMNS = [
    {
        name: 'Time',
        cell: (row) => formatDate(row.created_at),
    },
    {
        name: 'User',
        cell: (row) => row.user?.name,
    },
    {
        name: 'Action',
        cell: (row) => row.action,
    },
];

const LOGS_PER_PAGE = 30;
const ReGenerateFinancialDocuments = () => {
    const [generating_tax_invoices, set_generating_tax_invoices] = React.useState(false);
    const [generating_commission_invoices, set_generating_commission_invoices] =
        React.useState(false);
    const [logs, set_logs] = React.useState([]);
    const [loading_logs, set_loading_logs] = React.useState();
    const [logs_page, set_logs_page] = React.useState(1);
    const [errors, set_errors] = React.useState({});
    const AuthSlice = useSelector((state) => state.AuthSlice);

    // permissions
    const can_regenerate_invoices =
        AuthSlice.user.permissions.length === 0 ||
        AuthSlice.user.permissions.includes('tax_invoices.reissue');

    const can_show_logs =
        AuthSlice.user.permissions.length === 0 ||
        AuthSlice.user.permissions.includes('manual_audit.list');

    const [tax_invoices_orders, set_tax_invoices_orders] = React.useState({
        label: 'Sub Orders',
        placeholder: 'Search by child id, customer name, store name, vendor',
        api: '/suborder',
        onlyFetchActiveRecords: false,
        labelProperty: (r) => r.pretty_id,
        params: { suborder_states: 1024 },
        value: [],
        isMulti: true,
        onChange: (val) => set_tax_invoices_orders((prev) => ({ ...prev, value: val })),
    });

    const [commission_invoices_orders, set_commission_invoices_orders] = React.useState({
        label: 'Sub Orders',
        placeholder: 'Search by child id, customer name, store name, merchant',
        api: '/suborder',
        onlyFetchActiveRecords: false,
        labelProperty: (r) => r.pretty_id,
        params: { suborder_states: 1024 },
        value: [],
        isMulti: true,
        onChange: (val) => set_commission_invoices_orders((prev) => ({ ...prev, value: val })),
    });

    const loadLogs = async () => {
        set_loading_logs(true);
        const res = await GET({
            endpoint: '/manual-audits/tax-invoices',
            options: { params: { page: logs_page, records_per_page: LOGS_PER_PAGE } },
        });

        if (res.data) {
            set_logs(res);
        }

        set_loading_logs(false);
    };

    // Generate Tax Invoices
    const generateTaxInvoices = async () => {
        set_generating_tax_invoices(true);

        const suborder_ids = tax_invoices_orders.value.map((s) => s.pretty_id);
        const res = await POST({
            endpoint: '/tax-invoices/reissue',
            data: { suborder_ids },
        });

        if (res?.success) {
            const { failed, succeeded } = res.data || {};
            const fails_suborders = tax_invoices_orders.value.filter((s) =>
                failed.includes(s.pretty_id),
            );

            if (failed?.length) {
                toast.error(
                    `Failed to re-generate tax/delivery invoices for orders: ${failed.toString()}`,
                );
            }

            if (succeeded?.length) {
                toast.success(
                    `Regenerated commission tax/delivery for orders: ${succeeded.toString()}`,
                );
                loadLogs();
            }

            tax_invoices_orders.onChange(fails_suborders);
        } else {
            set_errors((prev) => ({ ...prev, tax_invoice: res?.data?.errors }));
        }

        set_generating_tax_invoices(false);
    };

    // Generate Commission / Delivery Invoices
    const generateCommissionInvoices = async () => {
        set_generating_commission_invoices(true);

        const suborders = commission_invoices_orders.value.map((s) => s.pretty_id);
        const res = await POST({
            endpoint: '/vendors/vendor-tax-invoices/regenerate',
            data: { suborders },
        });

        if (res?.success) {
            toast.success(`Regenerated commission invoices for orders: ${suborders.toString()}`);
            commission_invoices_orders.onChange([]);
            loadLogs();
        } else {
            set_errors((prev) => ({ ...prev, commission_invoices: res?.data?.errors }));
        }

        set_generating_commission_invoices(false);
    };

    React.useEffect(() => {
        if (can_show_logs) loadLogs();
    }, [logs_page]);

    if (!can_regenerate_invoices && !can_show_logs)
        return (
            <ShowErrors errors={{ Unauthorized: ["you don't have permission for this page"] }} />
        );
    return (
        <div>
            <ViewHeader title="Re-Generate Financial Documents" />
            <Card className="p-3">
                {can_regenerate_invoices && (
                    <Row className="mb-4">
                        <Col>
                            <h3 className="divider-title">Generate Tax Invoices</h3>
                            <ApiAsyncSelect {...tax_invoices_orders} />

                            {errors.tax_invoice && <ShowErrors errors={errors.tax_invoice} />}

                            <Button
                                onClick={generateTaxInvoices}
                                disabled={
                                    generating_tax_invoices || !tax_invoices_orders.value?.length
                                }
                            >
                                Generate Financial Document
                            </Button>
                        </Col>
                        <Col>
                            <h3 className="divider-title">
                                Generate Commission / Delivery Invoices
                            </h3>
                            <ApiAsyncSelect {...commission_invoices_orders} />

                            {errors.commission_invoices && (
                                <ShowErrors errors={errors.commission_invoices} />
                            )}

                            <Button
                                onClick={generateCommissionInvoices}
                                disabled={
                                    generating_commission_invoices ||
                                    !commission_invoices_orders.value?.length
                                }
                            >
                                Generate Financial Document
                            </Button>
                        </Col>
                    </Row>
                )}

                {can_show_logs && (
                    <Row>
                        <Col>
                            <h3 className="divider-title">Log</h3>
                            <DataTable
                                data={logs?.data || []}
                                columns={LOG_COLUMNS}
                                loading={loading_logs}
                                pages={Math.ceil((logs.meta?.total || 0) / LOGS_PER_PAGE)}
                                page={logs_page}
                                handlePageChange={set_logs_page}
                                disablePageHistory
                            />
                        </Col>
                    </Row>
                )}
            </Card>
        </div>
    );
};

export default ReGenerateFinancialDocuments;
