import React from 'react';
import { Form, Row, Col } from '@components';
import { default as DP } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function TimeRangePicker({
    label = '',
    startDate = new Date(),
    setStartDate = () => {},
    endDate = new Date(),
    setEndDate = () => {},
    startDateLabel,
    endDateLabel,
    disabled = false,
}) {
    return (
        <Form.Group controlId={label}>
            {label && (
                <Form.Label>{label}</Form.Label>
            )}
            <Row>
                <Col>
                    {startDateLabel && <Form.Label>{startDateLabel}</Form.Label>}
                    <DP
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className="form-control"
                        placeholderText="From"
                        disabled={disabled}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat="h:mm aa"
                    />
                </Col>
                <Col>
                    {endDateLabel && <Form.Label>{endDateLabel}</Form.Label>}
                    <DP
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        className="form-control"
                        placeholderText="To"
                        disabled={!startDate || disabled}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat="h:mm aa"
                    />
                </Col>
            </Row>
        </Form.Group>
    );
}