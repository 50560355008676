import React from 'react';
import { DataTable, Button, Modal, Input, ShowErrors } from '@components';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import { GET, POST, DELETE, obj2formdata, randomId } from '@utils';
import deleteIcon from '@assets/icons/delete.svg';
import editIcon from '@assets/icons/edit.svg';

const ProductRejectionReasons = () => {
    const dispatch = useDispatch();
    const [data, set_data] = React.useState([]);
    const [loading, set_loading] = React.useState(false);
    const [errors, set_errors] = React.useState([]);
    const [rerender, set_rerender] = React.useState(0);
    const [edit_id, set_edit_id] = React.useState(null);
    const [delete_id, set_delete_id] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            set_loading(true);
            const res = await GET({
                endpoint: '/vendorUsers/vendor-product-request-state-reason',
            });
            set_data(res?.data || []);
            set_loading(false);
        };
        fetchData();
    }, [rerender]);

    const [reason_ar, set_reason_ar] = React.useState({
        label: 'Reason (arabic)',
        value: '',
        setValue: (val) => set_reason_ar({ ...reason_ar, value: val }),
    });
    const [reason_en, set_reason_en] = React.useState({
        label: 'Reason (english)',
        value: '',
        setValue: (val) => set_reason_en({ ...reason_en, value: val }),
    });

    const submit = async (closeModal, id) => {
        set_loading(true);

        const data = obj2formdata({
            reason_ar: reason_ar.value,
            reason_en: reason_en.value,
        });

        if (id) {
            data.append('_method', 'PUT');
        }

        const res = await POST({
            endpoint: id 
                ? `/vendorUsers/vendor-product-request-state-reason/${id}`
                : '/vendorUsers/vendor-product-request-state-reason',
            data,
            headers: {
                'Idempotency-Key': randomId(),
            }
        });

        set_loading(false);
        if (res?.status === 422) {
            set_errors(res?.data.errors);
        } else if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('rejection_reason__modal');
            set_rerender(randomId());
            reason_ar.setValue('');
            reason_en.setValue('');
            set_errors([]);
        }
    };

    const columns = [
        {
            name: 'Reason (AR)',
            selector: 'reason_ar',
        },
        {
            name: 'Reason (EN)',
            selector: 'reason_en',
        },
        {
            name: '',
            selector: 'actions',
            right: true,
            cell: (row) => (
                <>
                  <Button
                      variant="danger" 
                      size="sm"
                      onClick={() => {
                          set_delete_id(row.id);
                          set_errors([]);
                          dispatch(
                              setModalStatus({
                                  modal: 'rejection_reason__delete_modal',
                                  status: true,
                              })
                          );
                      }}
                  >
                      <img src={deleteIcon} alt="Delete" width="16px" />
                  </Button>
					        &nbsp;&nbsp;
                  <Button
                      variant="warning"
                      size="sm"
                      onClick={() => {
                          set_edit_id(row.id);
                          reason_ar.setValue(row.reason_ar);
                          reason_en.setValue(row.reason_en);
                          set_errors([]);

                          dispatch(
                              setModalStatus({
                                  modal: 'rejection_reason__modal',
                                  status: true,
                              })
                          );
                      }}
                  >
                      <img src={editIcon} alt="Edit" width="16px" />
                  </Button>
                </>
            ),
        },
    ];

    const deleteRejectionReason = async closeModal => {
        set_loading(true);
        const res = await DELETE({
            endpoint: `/vendorUsers/vendor-product-request-state-reason/${delete_id}`
        });
        set_loading(false);

        if (res?.status_code === 201 || res?.status_code === 200) {
            closeModal('rejection_reason__delete_modal');
            set_rerender(randomId());
            set_delete_id(null);
        } else {
            const errors = res?.data.errors;
            const message = { error: [res?.data.message || 'something wrong'] };
            const defaultError = { error: ['something wrong'] };

            set_errors(errors || message || defaultError);
        }
    };

    return (
        <>
            <DataTable data={data} columns={columns} loading={loading} />

            <div className="flex__jc-end px-3 py-2">
                <Button
                    onClick={() => {
                        set_edit_id(null);
                        reason_ar.setValue('');
                        reason_en.setValue('');
                        set_errors([]);

                        dispatch(
                            setModalStatus({
                                modal: 'rejection_reason__modal',
                                status: true,
                            })
                        );
                    }}
                >
                        Add new rejection reason
                </Button>
            </div>

            <Modal
                title={edit_id ? 'Update rejection reason' : 'Add new rejection reason'}
                variant={edit_id ? 'warning' : 'primary'}
                name="rejection_reason__modal"
                onSubmit={(closeModal) => {
                    submit(closeModal, edit_id);
                }}
                loading={loading}
            >
                <Input {...reason_ar} />
                <Input {...reason_en} />
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>

            <Modal
                title="Delete rejection reason?"
                name="rejection_reason__delete_modal"
                variant="danger"
                onSubmit={deleteRejectionReason}
                loading={loading}
            >
                {!!Object.keys(errors).length && (
                    <ShowErrors errors={errors}/>
                )}
            </Modal>
        </>
    );
};

export default ProductRejectionReasons;
