import React from 'react';
import { ViewHeader, Link, DataTable, Card, Button, Modal, Sop } from '@components';
import { GET, DELETE } from '@utils';
import { setModalStatus } from '@slices/ui.slice';
import { useDispatch } from 'react-redux';
import deleteIcon from '@assets/icons/delete.svg';
function AppInstructionsListView() {
    const dispatch = useDispatch();
    const [instructions, set_instructions] = React.useState([]);
    const [loading, set_loading] = React.useState(true);
    const [submitting, set_submitting] = React.useState(false);
    const [delete_id, set_delete_id] = React.useState(null);
    const [page, set_page] = React.useState(1);
    const [total, set_total] = React.useState(null);


    const fetchData = async () => {
        const res = await GET({ endpoint: '/app-instruction' });
        set_loading(false);
        if (res?.status_code === 200) {
            set_instructions(res?.data);
            set_total(res?.meta?.last_page);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const deleteInstruction = async (closeModal) => {
        set_submitting(true);
        const res = await DELETE({ endpoint: `/app-instruction/${delete_id}` });
        set_submitting(false);
        if (res?.success === true) {
            fetchData();
            closeModal('instructions__delete_modal');
            set_delete_id(null);
        }
    };
    const columns = [
        {
            name: 'ID',
            selector: 'id',
            width: '100px',
            cell: (row) => <Link href={`/app-instructions/${row?.slug}`}>{row?.id}</Link>
        },
        {
            name: 'Title',
            selector: 'title',
            cell: (row) => <Link href={`/app-instructions/${row?.slug}`}>{row?.title}</Link>
        },
        {
            name: 'Slug',
            selector: 'slug',
            cell: (row) => row?.slug
        },
        {
            name: 'CTA Link',
            center: true,
            cell: (row) => (
                <Button
                    onClick={() => {
                        navigator?.clipboard?.writeText(
                            process.env.REACT_APP_STOREFRONT_BASE_URL +
                            '/instructions/' +
                            row?.slug
                        );
                    }}
                    variant="secondary"
                    style={{ color: 'white' }}
                >
                    Copy CTA Link
                </Button>
            )
        },
        {
            name: 'Actions',
            cell: (row) => (<Button
                variant="danger"
                size="sm"
                onClick={() => {
                    set_delete_id(row?.id);
                    dispatch(
                        setModalStatus({
                            modal: 'instructions__delete_modal',
                            status: true,
                        })
                    );
                }}
            >
                <img src={deleteIcon} alt="Delete" width="16px" />
            </Button>)
        },


    ];
    return (
        <>
            <ViewHeader title="App Instructions">
                <Sop id="30123" />
                <Link href="/app-instructions/add" className="btn btn-primary">
                    create
                </Link>
            </ViewHeader>
            <Card>
                <DataTable
                    data={instructions}
                    columns={columns}
                    loading={loading}
                    pages={total}
                    page={page}
                    handlePageChange={set_page}
                />
            </Card>
            <Modal
                title="Delete this App Instruction ?"
                name="instructions__delete_modal"
                variant="danger"
                onSubmit={deleteInstruction}
                loading={submitting}
            />
        </>
    );
}

export default AppInstructionsListView;
