import React from 'react';
import { useParams } from 'react-router';
import {
    ViewHeader,
    ButtonGroup,
    DropdownButton,
    Dropdown,
    Spinner,
    StatusBlock,
    Radio,
    Sop,
    Modal,
    DataTable,
} from '@components';
import { loadOrderData } from '@slices/orders.slice';
import arrowIcon from '@assets/icons/next.svg';
import iosIcon from '@assets/icons/platform/ios.svg';
import androidIcon from '@assets/icons/platform/android.svg';
import dashboardIcon from '@assets/icons/platform/dashboard.svg';
import webIcon from '@assets/icons/platform/web.svg';
import cartIcon from '@assets/icons/sidebar/cart.svg';
import moonIcon from '@assets/icons/moon.svg';
import warningIcon from '@assets/icons/warning.svg';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate, PUT } from '@utils';
import { loadOrderStaticData, loadOrderShippingPreferencesData } from '@slices/orders.slice';
import useMutation from '@hooks/useMutation';
import { toast } from 'react-toastify';
import useQuery from '@hooks/useQuery';
import { setModalStatus } from '@slices/ui.slice';
import historyClockIcon from '@assets/icons/history-clock.svg';

const SingleOrderHeader = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const OrdersSlice = useSelector((state) => state.OrdersSlice);
    const [platform_icon, set_platform_icon] = React.useState(null);
    const { data: { data: payment_history } = { data: [] }, isLoading: payment_loading } = useQuery(
        `/order/${id}/audits/payments`,
    );
    const { data: { data: order_edits_history } = { data: [] }, isLoading: order_edits_loading } =
        useQuery(`/order/${id}/audits/edits`);
    const [history, set_history] = React.useState(null);
    React.useEffect(() => {
        switch (OrdersSlice?.order?.platform?.toLowerCase()?.replace('app', '')?.trim()) {
            case 'android':
                set_platform_icon(androidIcon);
                break;
            case 'ios':
                set_platform_icon(iosIcon);
                break;
            case 'web':
                set_platform_icon(webIcon);
                break;
            case 'dashboard':
                set_platform_icon(dashboardIcon);
                break;
        }
    }, [OrdersSlice.order.platform]);

    React.useEffect(() => {
        dispatch(loadOrderStaticData());
        dispatch(loadOrderShippingPreferencesData());
    }, []);

    const shipmentMutation = useMutation(
        (data) =>
            PUT({
                endpoint: `/order/${id}/shipment-preferences`,
                data: { shipment_preference: data },
            }),
        {
            onSuccess: () => {
                dispatch(loadOrderData(id));
            },
            onError: (err) => {
                toast.error(err);
            },
        },
    );

    const business_models = OrdersSlice?.order_static_data?.business_models || [];
    const shipment_preferences = OrdersSlice?.shipment_preferences || [];
    const isOrderable = !!OrdersSlice?.order?.is_reordable;
    const showActionButton=!props.isEditDisabled || !props.isCancelDisabled || isOrderable ;

    const orderInfo = (
        <div>
            <div className="order-info">
                <h1>Order #{id}</h1>
                <div className="order-status-blocks">
                    <StatusBlock state={OrdersSlice.order.payment_status} />
                    <StatusBlock state={OrdersSlice.order.state} />
                    {OrdersSlice.order.platform && (
                        <span className="badge badge-pill badge-light">
                            <img src={platform_icon} alt={OrdersSlice.order.platform} />
                            Platform: {OrdersSlice.order.platform}
                        </span>
                    )}
                    {OrdersSlice.order.created_from_live_cart && (
                        <span className="badge badge-pill badge-light">
                            <img src={cartIcon} alt={OrdersSlice.order.platform} />
                            Created from a live cart
                        </span>
                    )}
                    {!!OrdersSlice.order.scheduled_order?.id && (
                        <span className="badge badge-pill badge-light">
                            <img src={moonIcon} alt={'scheduled order'} />
                            Scheduled {
                                OrdersSlice.order.scheduled_order_delivery_option?.title_en
                            },{' '}
                            {
                                OrdersSlice.order.scheduled_order_payment_option
                                    ?.down_payment_percentage
                            }
                            % down payment
                        </span>
                    )}
                    {!!OrdersSlice?.order?.shipment_preference && (
                        <span
                            className="badge badge-pill"
                            style={{
                                backgroundColor:
                                    OrdersSlice.order.shipment_preference?.code === 'one_shipment'
                                        ? '#27ae60'
                                        : '#3498db',
                            }}
                        >
                            {OrdersSlice.order.shipment_preference.code}
                        </span>
                    )}
                </div>
                {!!order_edits_history?.length && (
                    <div
                        className="history-state-icon"
                        onClick={() => {
                            set_history('order_edit');
                            dispatch(
                                setModalStatus({
                                    modal: 'order_states_history_modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <div className="icon">
                            <img src={historyClockIcon} alt="" />
                        </div>
                        <span>Order Edit History</span>
                    </div>
                )}
                {!!payment_history?.audits?.length && (
                    <div
                        className="history-state-icon remove_dividing"
                        onClick={() => {
                            set_history('order_payment');
                            dispatch(
                                setModalStatus({
                                    modal: 'order_states_history_modal',
                                    status: true,
                                }),
                            );
                        }}
                    >
                        <div className="icon">
                            <img src={historyClockIcon} alt="" />
                        </div>
                        <span>Payment History</span>
                    </div>
                )}
            </div>
            <div className="date">
                <p>Created at: {formatDate(OrdersSlice.order.created_at)}</p>
                {OrdersSlice.order.updated_at && (
                    <p>Last update : {formatDate(OrdersSlice.order.updated_at)}</p>
                )}
                {OrdersSlice.order.has_availability_issue && (
                    <span className="badge badge-pill badge-warning warning-pill">
                        <img src={warningIcon} alt="warning" />
                        Has availability issue!
                    </span>
                )}
                {OrdersSlice.order.is_delivered && (
                    <span className="badge badge-pill badge-success success-pill color-white">
                        Parent order is delivered
                    </span>
                )}
            </div>
        </div>
    );
    const payment_columns = [
        {
            name: 'User type',
            width: '150px',
            cell: (row) => row?.user_type,
        },
        {
            name: 'Name',
            width: '100px',
            cell: (row) => row?.user,
        },
        {
            name: 'Update',
            center: true,
            cell: (row) => row?.message,
        },
        {
            name: 'Date & time',
            width: '150px',
            cell: (row) => formatDate(row?.date),
        },
    ];
    const order_edit_columns = [
        {
            name: 'User type',
            width: '150px',
            cell: (row) => row?.user_type,
        },
        {
            name: 'Name',
            width: '100px',
            cell: (row) => row?.user_name,
        },
        {
            name: 'Update',
            center: true,
            cell: (row) => (
                <ul>
                    {row?.product_suborder_logs.map((x, i) => (
                        <li style={{ margin: '8px 0' }} key={i}>
                            • {x?.message}
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            name: 'Date & time',
            width: '150px',
            cell: (row) => formatDate(row?.created_at),
        },
    ];
    return (
        <>
            <ViewHeader
                title={orderInfo}
                className="orders-single-view__header"
                renderBackArrow
                id={id}
            >
                {props.loadingPDF && (
                    <div className="downloading-pdf-loader">
                        <Spinner animation="border" role="status" size="sm" />
                        &nbsp; Downloading file..
                    </div>
                )}
                <div className="flex__ai-center">
                    {/* Hide delivery option from order details for now
            task link // https://app.clickup.com/t/8669m9wcc */}
                    <DropdownButton
                        style={{ display: 'none' }}
                        variant="light"
                        as={ButtonGroup}
                        title={
                            shipmentMutation.isLoading
                                ? 'updating...'
                                : `Shipping Preference ${
                                      OrdersSlice.order?.shipment_preference
                                          ? `(${OrdersSlice.order?.shipment_preference.title})`
                                          : ''
                                  }`
                        }
                        disabled={shipmentMutation.isLoading}
                    >
                        {!!shipment_preferences?.length &&
                            shipment_preferences?.map((b, index) => (
                                <Dropdown.Item key={index} eventKey={index} as="div">
                                    <Radio
                                        name="shipment_preference_options"
                                        value={b?.code}
                                        option={OrdersSlice?.order?.shipment_preference?.code}
                                        label={b?.title}
                                        onChange={(val) => shipmentMutation.mutate(val)}
                                    />
                                </Dropdown.Item>
                            ))}
                    </DropdownButton>
                    <DropdownButton
                        variant="light"
                        as={ButtonGroup}
                        title={
                            props.updating_business_model
                                ? 'updating...'
                                : `Business model ${
                                      OrdersSlice.order?.business_model
                                          ? `(${OrdersSlice.order?.business_model.description})`
                                          : ''
                                  }`
                        }
                        disabled={props.updating_business_model}
                    >
                        {!!business_models?.length &&
                            business_models?.map((b, index) => (
                                <Dropdown.Item key={index} eventKey={index} as="div">
                                    <Radio
                                        name="business-models-options"
                                        value={b?.code}
                                        option={OrdersSlice?.order?.business_model?.code}
                                        label={b?.description}
                                        onChange={(val) => props.onBusinessModelUpdate(val)}
                                    />
                                </Dropdown.Item>
                            ))}
                    </DropdownButton>
                    <ButtonGroup>
                        {showActionButton && 
                        <DropdownButton
                            as={ButtonGroup}
                            title="Actions"
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            {!props.isEditDisabled && (
                                <Dropdown.Item eventKey="2" onClick={props.onEdit}>
                                    Edit
                                </Dropdown.Item>
                            )}
                            {!props.isCancelDisabled && (
                                <Dropdown.Item eventKey="3" onClick={props.onCancel}>
                                    Cancel
                                </Dropdown.Item>
                            )}

                            {isOrderable && (
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={() =>
                                        dispatch(
                                            setModalStatus({
                                                modal: 'customer_re_order_modal',
                                                status: true,
                                            }),
                                        )
                                    }
                                >
                                    Re-fill cart
                                </Dropdown.Item>
                            )}
                        </DropdownButton>
                          }
                       
                        <DropdownButton
                            as={ButtonGroup}
                            title="Docs"
                            id="bg-nested-dropdown"
                            variant="light"
                        >
                            <Dropdown.Item eventKey="1" onClick={props.onDownloadSalesInvoice}>
                                Download sales order
                            </Dropdown.Item>

                            <Dropdown.Item eventKey="1" onClick={props.onDownloadDeliveryNote}>
                                Download delivery note / Tookan
                            </Dropdown.Item>
                            {props.credit_notes?.map((credit_note) => (
                                <Dropdown.Item
                                    key={credit_note.id}
                                    eventKey="3"
                                    onClick={() => {
                                        const { links } = credit_note;
                                        const download_link = links?.self?.split('v1')?.[1];
                                        if (!download_link) return;

                                        props.onDownloadCreditNote(download_link);
                                    }}
                                >
                                    Download credit note {credit_note.pretty_id}&nbsp; issued at{' '}
                                    {formatDate(credit_note?.issued_at)}
                                </Dropdown.Item>
                            ))}

                            {props.tax_invoice_issued && !!props.tax_invoices?.length && (
                                <>
                                    {props.tax_invoices.map((tax_invoice, i) => (
                                        <Dropdown.Item
                                            key={tax_invoice.id}
                                            eventKey="1"
                                            onClick={() =>
                                                props.onDownloadTaxInvoice(tax_invoice.id)
                                            }
                                        >
                                            Download tax invoice #{i + 1} (issued at{' '}
                                            {formatDate(tax_invoice.issued_at)})
                                        </Dropdown.Item>
                                    ))}
                                </>
                            )}
                        </DropdownButton>
                    </ButtonGroup>
                    <Sop id="24443" className="ml-2" />
                </div>
                <div className="view-header__pagination">
                    <div className="prev">
                        <img src={arrowIcon} className="prev-icon" alt="Previous" />
                    </div>
                    <div className="next">
                        <img src={arrowIcon} className="prev-icon" alt="Previous" />
                    </div>
                </div>
            </ViewHeader>
            <Modal
                title={`${history} history`}
                name="order_states_history_modal"
                confirmBtn={false}
                bodyStyle={{ overflowY: 'auto' }}
                size="lg"
                onClose={() => {
                    set_history(null);
                }}
            >
                <DataTable
                    data={history === 'order_edit' ? order_edits_history : payment_history?.audits}
                    columns={history === 'order_edit' ? order_edit_columns : payment_columns}
                    searchable={false}
                    loading={history === 'order_edit' ? order_edits_loading : payment_loading}
                />
            </Modal>
        </>
    );
};

export default SingleOrderHeader;
