import React from 'react';
import { Card, Input } from '@components';
import searchIcon from '@assets/icons/search.svg';

const CustomerInfo = ({ data }) => {
    const [find_customer_mode, set_find_customer_mode] = React.useState(false);
    return (
        <Card
            title="Customer Info"
            // headerBtn="Remove customer"
            onHeaderBtnClick={() => set_find_customer_mode(true)}
        >
            <div className="px-3">
                {find_customer_mode ? (
                    <div>
                        <Input
                            type="search"
                            placeholder="Find a customer.."
                            icon={searchIcon}
                        />
                    </div>
                ) : (
                    <div className="data-pairs">
                        {data.map((itm, i) => (
                            <div key={i} className="data-pairs__item">
                                <div className="key">{itm.key}</div>
                                {itm.value ? (
                                    <div className="value">
                                        <div
                                            className={itm.className}
                                            onClick={() => !!itm.onClick && itm.onClick()}
                                        >
                                            {itm.value}
                                        </div>
                                    </div>
                                ) : (
                                    'No value'
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default CustomerInfo;
