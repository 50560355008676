import React from 'react';
import { Button, Popover, Icon, ChoiceList } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { SortMinor } from '@shopify/polaris-icons';

export default function SortBy({
    value = null,
    set_value = () => {},
    sort_options = [],
}) {
    const [popoverActive, setPopoverActive] = useState(false);
    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        []
    );
    const onChange = useCallback((value) => set_value(value), []);

    const activator = (
        <Button
            onClick={togglePopoverActive}
            icon={<Icon source={SortMinor} />}
        >
			Sort by
            {value && (
                <span className="active-filter-indicator" />
            )}
        </Button>
    );

    return (
        <Popover
            active={popoverActive}
            activator={activator}
            onClose={togglePopoverActive}
        >
            <Popover.Section>
                <ChoiceList
                    choices={sort_options}
                    selected={value || []}
                    onChange={onChange}
                />
                
                <Button
                    plain
                    disabled={value?.length === 0 || value === null}
                    onClick={() => {
                        onChange([]);
                        togglePopoverActive();
                    }}
                >
                    Clear
                </Button>

            </Popover.Section>
        </Popover>
    );
}
