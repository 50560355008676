import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FileUpload, Row, Col, Modal } from '@components';
import { PATCH, DELETE, POST, obj2formdata, randomId, confirmationMessage } from '@utils';
import removeIcon from '@assets/icons/delete.svg';
import { ReactSortable } from 'react-sortablejs';
import { toast } from 'react-toastify';
import useQuery from '@hooks/useQuery';
import useUpdateProduct from '../../hooks/useUpdateProduct';
import style from '../../components/SelectVariantOrBase.module.scss';
import { setModalStatus } from '@slices/ui.slice';


export default function TeaserImagesTab() {
    const dispatch = useDispatch();
    const [selectedColor, setSelectedColor] = useState('');
    const { product } = useSelector((state) => state.ProductsSlice);

    const cleanPayload = (payload) => {
        if (Array.isArray(payload)) {
            return payload.map((item) => cleanPayload(item));
        } else if (typeof payload === 'object' && payload !== null) {
            const cleanedObject = {};
            Object.keys(payload).forEach((key) => {
                if (key !== 'range_percentage') {
                    cleanedObject[key] = cleanPayload(payload[key]);
                }
            });
            return cleanedObject;
        }
        return payload;
    };
    const cleanedValues = cleanPayload(product);
    const { mutate } = useUpdateProduct(
        cleanedValues,
        { teaser_background_color: selectedColor },
        {
            view_name: 'general',
        },
    );

    React.useEffect(() => {
        if (product.id) {
            setSelectedColor(product.teaser_background_color || '000000');
        }
    }, [product]);

    const handleColorChange = (e) => {
        const colorWithoutHash = e.target.value.slice(1);
        setSelectedColor(colorWithoutHash);
    };
    return (
        <>
            <div>
                <div className={style.wrapper}>
                    <p
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.06)',
                            padding: '0.5rem 1rem',
                            borderRadius: '0.5rem',
                            whiteSpace: 'nowrap',
                            opacity: '0.6',
                        }}
                    >
                        Teaser background color
                    </p>

                    <div
                        style={{
                            backgroundColor: `#${selectedColor}`,
                            cursor: 'pointer',
                            width: '100px',
                            height: '40px',
                            borderRadius: '0.5rem',
                        }}
                        onClick={() => {
                            dispatch(
                                setModalStatus({
                                    modal: 'teaser_background_color',
                                    status: true,
                                }),
                            );
                        }}
                    ></div>
                    <Modal
                        title="Update Teaser background color"
                        variant="warning"
                        name="teaser_background_color"
                        onSubmit={() => {
                            mutate();
                            dispatch(
                                setModalStatus({
                                    modal: 'teaser_background_color',
                                    status: false,
                                }),
                            );
                        }}
                        // loading={updateMutations.isLoading}
                    >
                        <input
                            style={{ cursor: 'pointer', width: '100%' }}
                            type="color"
                            value={`#${selectedColor}`}
                            onChange={handleColorChange}
                        />
                    </Modal>
                </div>
                <UpdateImages />
            </div>
        </>
    );
}

const UpdateImages = () => {
    const [files, set_files] = React.useState([]);
    const { product, product_loading } = useSelector((state) => state.ProductsSlice);
    const selectedID = product?.id;
    const [loading, set_loading] = React.useState(false);
    const [images, set_images] = React.useState([]);
    const endpoint = 'base-product';
    const { data: { data: selectedProductImages } = {}, refetch } = useQuery(
        `/${endpoint}/${selectedID}/teaser-image`,
    );
    React.useEffect(() => {
        if (Array.isArray(selectedProductImages)) {
            set_images([...(selectedProductImages || [])]);
        }
    }, [selectedProductImages, selectedID]);

    const deleteSlide = async (id) => {
        const res = await DELETE({
            endpoint: `/${endpoint}/${selectedID}/teaser-image/${id}`,
        });
        if (res?.status_code === 200) {
            refetch();
        } else {
            toast.error(res.data?.message);
        }
    };

    const submitSlider = async () => {
        const data = obj2formdata({});
        set_loading(true);

        files.forEach((file) => {
            data.append('teaser_images[]', file);
        });

        const res = await POST({
            endpoint: `/${endpoint}/${selectedID}/teaser-image`,
            data,
            headers: {
                'Idempotency-Key': randomId(),
            },
        });
        set_loading(false);
        if (res?.status_code === 200 || res?.status_code === 201) {
            set_files([]);
            refetch();
        }
    };

    const reorderProductImages = async () => {
        const res = await PATCH({
            endpoint: `/${endpoint}/${selectedID}/teaser-image`,
            data: {
                order: [...images.map((img) => img.id)],
            },
        });

        if (res?.status_code === 200) {
            refetch();
        }
    };

    return (
        <>
            <Row className="mb-4">
                <Col xs={12}>
                    <FileUpload files={files} setFiles={set_files} maxFiles={5} />
                    <Button
                        disabled={!files.length || loading}
                        onClick={() => {
                            product?.is_active ? submitSlider() : confirmationMessage(submitSlider);
                        }}
                    >
                        {loading ? 'Loading...' : 'Upload images'}
                    </Button>
                </Col>
            </Row>
            {images.length > 0 && (
                <h4>Reorderable images, 1st image is the main image of the teaser images</h4>
            )}{' '}
            {!product_loading && (
                <ReactSortable
                    group="product-thumbnails"
                    MultiDrag
                    list={images.map((x) => ({ ...x, chosen: true }))}
                    setList={(ordered_images) => {
                        set_images([...ordered_images.filter((x) => !!x)]);
                    }}
                    className="thumbnails-grid"
                    onEnd={reorderProductImages}
                >
                    {images?.map((image, i) => (
                        <div key={i} className="thumbnail">
                            <span className="remove-icon" onClick={() => deleteSlide(image.id)}>
                                <img src={removeIcon} alt="x" />
                            </span>
                            <span className="thumbnail-order">{i + 1}</span>
                            <img src={image.image} alt="Slide image" />
                        </div>
                    ))}
                </ReactSortable>
            )}
        </>
    );
};