import React from 'react';
import { Modal, Input } from '@components';
import { GET } from '@utils';

export const printBoxes = async (childOrderId) =>
    GET({
        endpoint: `/suborder/${childOrderId}/boxes/printed-version`,
    }).then((res) => {
        const printableBoxesWindow = window.open('', '', 'width=800,height=400');
        printableBoxesWindow.document.write(res.data?.html);
        printableBoxesWindow.document.close();
        setTimeout(() => {
            printableBoxesWindow.print();
        }, 500);
    });

function BoxesForm({ onSubmit }) {
    const [submitting, set_submitting] = React.useState(false);
    const [boxes_count, set_boxes_count] = React.useState({
        label: 'boxes count',
        value: '',
        setValue: (val) => set_boxes_count({ ...boxes_count, value: val }),
    });

    return (
        <Modal
            title={'child order boxes'}
            name="child_order_boxes_count_modal"
            onSubmit={(close) => {
                set_submitting(true);
                onSubmit(close)({ count: boxes_count.value }).then(() => {
                    set_submitting(false);
                    boxes_count.setValue('');
                });
            }}
            loading={submitting}
        >
            <Input {...boxes_count} />
        </Modal>
    );
}

export default BoxesForm;
