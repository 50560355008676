/* eslint-disable max-len */
import React from 'react';
import {
    ViewHeader,
    Card,
    Tabs,
    Row,
    Col,
    WhatsApp,
    Dialer,
    Sop,
    Button,
    ActivationReasonModal,
} from '@components';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadCustomerData, loadCustomerRefundReasons } from '@slices/customers.slice';
import { setModalStatus } from '@store/slices/ui.slice';

import OrdersTab from './Tabs/OrdersTab';
import VendorsTab from './Tabs/MerchantsTab';
import HistoryTab from './Tabs/HistoryTab';
import StoreImagesTab from './Tabs/StoreImagesTab';
import AttachmentsTab from './Tabs/AttachmentsTab';
import WalletTransactionsTab from './Tabs/WalletTransactionsTab';
import UpdateCustomerInfoTab from './Tabs/UpdateCustomerInfoTab';
import CustomerAddressesTab from './Tabs/CustomerAddressesTab';
import FollowedVendorsTab from './Tabs/FollowedVendorsTab';
import BankDetailsTab from './Tabs/BankDetailsTab';
import BusinessInfoTab from './Tabs/BusinessInfoTab';
import CustomerAccountStatusHistoryTab from './Tabs/CustomerAccountStatusHistoryTab';
import AccountVerificationTab from './Tabs/AccountVerificationTab';
import CreditModal from './CreditModal';
import arrowIcon from '@assets/icons/next.svg';
import defaultLogo from '@assets/img/default.jpg';
import formatMoney from '@utils/formatMoney';
import { setCustomer } from '../../../store/slices/customers.slice';
import copyIcon from '@assets/icons/copy.svg';
import CustomerRequestsTab from './Tabs/CustomerRequestsTab';
import RetailUsersTab from './Tabs/RetailUsersTab';

export default function CustomersSingleView() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const CustomersSlice = useSelector((state) => state.CustomersSlice);
    const [activeTab, setActiveTab] = React.useState('Orders');
    const [copied, setCopied] = React.useState(false);

    React.useEffect(() => {
        dispatch(setCustomer({}));
        dispatch(loadCustomerData(id));
        dispatch(loadCustomerRefundReasons());
    }, []);

    if (CustomersSlice?.customer_loading) {
        return 'loading...';
    }

    const tabNames = [
        'Orders',
        'History',
        'Attachments',
        'Retail Users',
        'Wallet transactions',
        'Retail Info',
        'Business Info',
        'Account Verification',
        "Retail's Addresses",
        'Store Images',
        'Followed Merchants',
        'Bank Details',
        'Account Status History',
        'Requests',
    ];
    const tabs = tabNames.map((v) => ({
        name: v,
        isActive: v === activeTab,
    }));
    const setTabs = (tabs) => {
        setActiveTab(
            tabs
                .filter((t) => t.isActive)
                .slice(0, 1)
                .map((t) => t.name)
                .join(),
        );
    };

    return (
        <div className="customers-single-view">
            <ViewHeader title={`Retail ID #${id}`} renderBackArrow>
                <Sop id="25183" />
                <div className="view-header__pagination">
                    <div className="prev">
                        <img src={arrowIcon} className="prev-icon" alt="Previous" />
                    </div>
                    <div className="next">
                        <img src={arrowIcon} className="prev-icon" alt="Previous" />
                    </div>
                </div>
            </ViewHeader>

            <Row>
                <Col sm={3}>
                    <Card>
                        <div className="customer-profile-overview">
                            <section className="info">
                                <img
                                    src={CustomersSlice?.customer?.logo || defaultLogo}
                                    alt="Logo"
                                />
                                <div>
                                    <h3>{CustomersSlice?.customer?.store_name}</h3>
                                    <h2>
                                        {CustomersSlice?.customer?.first_name}
                                        {CustomersSlice?.customer?.last_name}
                                    </h2>
                                </div>
                            </section>
                            <div
                                className={`verification-status ${CustomersSlice?.customer?.state}`}
                            >
                                {CustomersSlice?.customer?.state}
                            </div>
                            <section className="wallet">
                                <label>
                                    {/* <img src={walletIcon} alt="Wallet" /> */}
                                    <p>Total wallet balance</p>
                                    <span className="price">
                                        {formatMoney(CustomersSlice?.customer_balance?.balance)}
                                        {CustomersSlice?.customer_balance?.currency?.toUpperCase()}
                                    </span>
                                </label>
                                <div className="py-4">
                                    <div>
                                        <p className='d-inline-block pr-1 mb-1'>Refundable :</p>
                                        <span>
                                            {formatMoney(
                                                CustomersSlice?.customer_balance
                                                    ?.withdrawable_balance || 0,
                                            )}
                                            {CustomersSlice?.customer_balance?.currency?.toUpperCase()}
                                        </span>
                                    </div>
                                    <div>
                                        <p className='d-inline pr-1'>Non-refundable</p>
                                        <span>
                                            {formatMoney(
                                                CustomersSlice?.customer_balance
                                                    ?.non_withdrawable_balance || 0,
                                            )}
                                            {CustomersSlice?.customer_balance?.currency?.toUpperCase()}
                                        </span>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        dispatch(
                                            setModalStatus({
                                                modal: 'single_customer_view__credit_modal',
                                                status: true,
                                            }),
                                        );
                                    }}
                                >
                                    Add/Deduct Credit
                                </Button>
                                <Button
                                    className="activation"
                                    variant={
                                        CustomersSlice?.customer?.status ? 'danger' : 'primary'
                                    }
                                    onClick={() => {
                                        dispatch(
                                            setModalStatus({
                                                modal: 'activation_reason_modal',
                                                status: true,
                                            }),
                                        );
                                    }}
                                >
                                    {CustomersSlice?.customer?.status ? 'Disable' : 'Enable'}{' '}
                                    account
                                </Button>
                            </section>
                            <section className="details">
                                <ul>
                                    <li>
                                        <span>Email</span>
                                        <p>
                                            <a href={`mailto:${CustomersSlice?.customer?.email}`}>
                                                {CustomersSlice?.customer?.email}
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <span>Phone</span>
                                        <p>
                                            <div className="flex__jc-between__ai-center">
                                                <div>
                                                    {
                                                        <Dialer
                                                            number={
                                                                CustomersSlice?.customer?.mobile
                                                            }
                                                        />
                                                    }
                                                </div>
                                                <div className="whatsApp__wrapper">
                                                    {
                                                        <WhatsApp
                                                            number={
                                                                CustomersSlice?.customer?.mobile
                                                            }
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </p>
                                    </li>
                                    <li>
                                        <span>Total orders</span>
                                        <p>{CustomersSlice?.customer?.total_orders}</p>
                                    </li>
                                    <li>
                                        <span>Account manager</span>
                                        <p>
                                            {CustomersSlice?.customer?.account_manager
                                                ? `${CustomersSlice?.customer?.account_manager?.first_name} ${CustomersSlice?.customer?.account_manager?.last_name}`
                                                : 'NULL'}
                                        </p>
                                    </li>
                                    <li>
                                        <span>Acquisition manager</span>
                                        <p>
                                            {CustomersSlice?.customer?.acquisition_user
                                                ? `${CustomersSlice?.customer?.acquisition_user?.first_name} ${CustomersSlice?.customer?.acquisition_user?.last_name}`
                                                : 'NULL'}
                                        </p>
                                    </li>
                                    {/* Maybe we need label here to divide */}
                                    <li>
                                        <span>Total sales</span>
                                        <p>
                                            {formatMoney(CustomersSlice?.customer?.total_spent)} SAR
                                        </p>
                                    </li>
                                    <li>
                                        <span>Monthly target</span>
                                        <p>
                                            {formatMoney(CustomersSlice?.customer?.monthly_target)}
                                            SAR
                                        </p>
                                    </li>
                                    <li>
                                        <span>This month achieved</span>
                                        <p>
                                            {formatMoney(CustomersSlice?.customer?.month_achieved)}
                                            SAR
                                        </p>
                                    </li>
                                    <li>
                                        <span>This month achieved percentage</span>
                                        <p>
                                            {CustomersSlice?.customer?.achieved_percentage
                                                ? +CustomersSlice?.customer?.achieved_percentage?.toFixed(
                                                      2,
                                                  )
                                                : 0}
                                            %
                                        </p>
                                    </li>
                                    <li>
                                        <span>Balance</span>
                                        <p>{formatMoney(CustomersSlice?.customer?.balance)} SAR</p>
                                    </li>
                                    <li>
                                        <span>Virtual IBAN</span>
                                        <div
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    CustomersSlice?.customer?.virtual_iban,
                                                );
                                                setCopied(true);
                                                setTimeout(() => {
                                                    setCopied(false);
                                                }, 2000);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="flex__ai-center"
                                        >
                                            <p style={{ marginRight: 5 }}>
                                                {CustomersSlice?.customer?.virtual_iban
                                                    ? `${CustomersSlice?.customer?.virtual_iban}`
                                                    : 'NULL'}
                                            </p>
                                            {!copied ? (
                                                <img src={copyIcon} />
                                            ) : (
                                                <small style={{ padding: '2px' }}>Copied!</small>
                                            )}
                                        </div>
                                    </li>
                                    <li>
                                        <span>Verified at</span>
                                        <p>{CustomersSlice?.customer?.verified_at}</p>
                                    </li>
                                    <li>
                                        <span>Verified by</span>
                                        <p>{CustomersSlice?.customer?.verified_by?.name}</p>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </Card>
                </Col>
                <Col sm={9}>
                    <Card>
                        <Tabs tabs={tabs} onSelect={setTabs}>
                            {activeTab === 'Orders' && <OrdersTab id={id} />}
                            {activeTab === 'Vendors' && <VendorsTab />}
                            {activeTab === 'History' && <HistoryTab id={id} />}
                            {activeTab === 'Retail Users' && <RetailUsersTab id={id} />}
                            {activeTab === 'Attachments' && <AttachmentsTab />}
                            {activeTab === 'Wallet transactions' && (
                                <WalletTransactionsTab id={id} />
                            )}
                            {activeTab === 'Retail Info' && <UpdateCustomerInfoTab id={id} />}
                            {activeTab === 'Business Info' && <BusinessInfoTab id={id} />}
                            {activeTab === 'Account Verification' && (
                                <AccountVerificationTab id={id} />
                            )}
                            {activeTab === "Retail's Addresses" && <CustomerAddressesTab id={id} />}
                            {activeTab === 'Store Images' && (
                                <StoreImagesTab images={CustomersSlice?.customer?.store_images} />
                            )}
                            {activeTab === 'Followed Merchants' && <FollowedVendorsTab id={id} />}
                            {activeTab === 'Bank Details' && <BankDetailsTab id={id} />}
                            {activeTab === 'Account Status History' && (
                                <CustomerAccountStatusHistoryTab id={id} />
                            )}
                            {activeTab === 'Requests' && <CustomerRequestsTab id={id} />}
                        </Tabs>
                    </Card>
                </Col>
            </Row>

            <CreditModal id={id} />

            <ActivationReasonModal
                name="customer"
                id={CustomersSlice?.customer?.id}
                status={CustomersSlice?.customer?.status}
                reasons_option={CustomersSlice?.customer?.reasons}
            />
        </div>
    );
}
