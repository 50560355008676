import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '@components';


import { Helmet } from 'react-helmet';
import WireTransactionListView from './WireTransactionListView';
import WireTransactionsAddView from './WireTransactionsAddView';
import WireTransactionUpdateView from './WireTransactionUpdateView';

export default function WireTransactionModuleRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact path="/wire-transactions/list">
                <Helmet>
                    <title>Wire transaction | Disty Admin Dashboard</title>
                </Helmet>
             <WireTransactionListView/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/wire-transactions/add">
                <Helmet>
                    <title>Add wire transactions | Disty Admin Dashboard</title>
                </Helmet>
               <WireTransactionsAddView/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/wire-transactions/:id">
                <Helmet>
                    <title>Edit wire transaction | Disty Admin Dashboard</title>
                </Helmet>
            <WireTransactionUpdateView/>
            </ProtectedRoute>
        </Switch>
    );
}